// @flow
import type { Action } from '.'
import { UPDATE_SEARCH,
         UPDATE_GROUPS,
         SET_OWN_CLIENTS,
         SET_DISABLED,
         SET_UNVERIFIED,
         UPDATE_TAGS,
         UPDATE_NAME_SEARCH,
         UPDATE_EMAIL_SEARCH
      } from 'actions/users'

type TenantUsersState = {
  tags: number | null,
  search: string | null,
  groups: string | null,
  own_clients: boolean,
  disabled: boolean,
  unverified: boolean,
}

type PublicUsersState = {
  name: string,
  email: string,
}

export type UsersState = {
  tenant: TenantUsersState,
  public: PublicUsersState
}

const initialValueTenant: TenantUsersState = {
  tags: null,
  search:  null,
  groups:  null,
  own_clients: false,
  disabled: false,
  unverified: false,
}

const initialValuePublic: PublicUsersState = {
  name: '',
  email: ''
}

export const initialValue: UsersState = {
  tenant: initialValueTenant,
  public: initialValuePublic,
}

//eslint-disable-next-line
export default function usersReducer (state: UsersState, action: Action) {
  const { payload } = action
  switch (action.type) {

    case UPDATE_TAGS:
      return { ...state, tenant: { ...state.tenant, tags: payload }}

    case UPDATE_SEARCH:
      return { ...state, tenant: {...state.tenant, search: payload }}

    case UPDATE_GROUPS:
      return { ...state, tenant: {...state.tenant, groups: payload} }

    case SET_OWN_CLIENTS:
      return { ...state, tenant: {...state.tenant, own_clients: payload }}

    case SET_DISABLED:
      return { ...state, tenant: {...state.tenant, disabled: payload }}

    case SET_UNVERIFIED:
      return { ...state, tenant: {...state.tenant, unverified: payload }}

    case UPDATE_EMAIL_SEARCH:
      return { ...state, public: {...state.public, email: payload }}

    case UPDATE_NAME_SEARCH:
      return { ...state, public: {...state.public, name: payload }}

    default:
      return state || initialValue

  }
}
