/**
 * @flow
 * @class TextInput
 */

import React, { PureComponent } from 'react'
import type { Element } from 'react'
import { __ } from 'utils/gettext'

type HeadingProps = {
  children: Element<*>,
}


export default class HeaderBlock extends PureComponent<HeadingProps> {

  static displayName = __('Heading')

  render () {
    return <h3 className='heading'>
      { this.props.children }
    </h3>
  }
}
