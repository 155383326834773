/**
 * @flow
 * @class RequiredFieldIndicator
 */
import React, { PureComponent } from 'react'
import Icon from 'components/icons'
import { __ } from 'utils/gettext'


export default class RequiredFieldIndicator extends PureComponent<*> {
  static get DESCRIPTION (): string { return __('Required field') }

  render () {
    return <Icon.Asterisk
      title={ RequiredFieldIndicator.DESCRIPTION }
      className='required-indicator'
    />
  }
}


export class RequiredFieldIndicatorDescription extends PureComponent<*> {
  render() {
    return (
      <sub className='subtle description'>
        <span className='required-indicator-icon'>
          <RequiredFieldIndicator />
        </span>
        <span className='title'>
          { RequiredFieldIndicator.DESCRIPTION }
        </span>
      </sub>
    )
  }
}
