// @flow
import type { Action } from '../reducers'
import api from 'api'
import auth from 'api/auth'
import notifications from 'api/notifications'
import {
  setParticipantFilter,
  clearListFilters as clearCommunicationFilters,
} from './communication'
import { clearFilters as clearFormFilters } from './forms'
import { clearUserSearch as clearUserFilters } from './users'
import {
  clearAlerts,
  updateShowContract,
  clearAnnouncements,
  getAnnouncements,
  getNotifications,
} from './core'
import { deselectClient } from './client'
import { updateTenantSettings } from './tenant'

import { setLocale } from 'utils/gettext'
import { resolveSubdomain } from 'utils/resolve'
import { postGlobalEvent } from 'utils/events'
import {
  PUBLIC_TENANT,
  LOGOUT_EVENT_KEY,
  GROUP_SUPERUSER,
  GROUP_MANAGEMENT,
} from '../constants'
import context from 'context'
import navigator from 'routes/base'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const SET_LOGIN_REQUIRED_STATE = 'SET_LOGIN_REQUIRED_STATE'
export const UPDATE_USER_DATA_FROM_TOKEN = 'UPDATE_USER_FROM_TOKEN'
export const UPDATE_LOGGED_IN_USER = 'UPDATE_USER'
export const UPDATE_SHOW_TERMS = 'UPDATE_SHOW_TERMS'
export const CLEAR_USER_DATA = 'CLEAR_USER'

let _pendoInitialized = false

export const updateDetails = (payload: Object): Object => async (
  dispatch: (Action) => *
) => {
  const requestData = {
    phone_number: payload.phone_number,
    profile: { ...payload, phone_number: undefined },
  }
  const response = await patchUserData(requestData)
  const { data } = response
  const name = data.profile ? data.profile.full_name : null
  if (name && name !== payload.name) data.name = name
  await dispatch(updateLoggedInUserData(data))
  return response
}

export const updateProfilePicture = (picture: string): Function => async (
  dispatch: (Action) => *
) => await dispatch(updateLoggedInUserData({ profile: { picture } }))

//eslint-disable-next-line max-statements, complexity
export const login = (): Function => async (
  dispatch: (Action) => *,
  getState: Function
) => {
  const user_id = auth.data.user_id

  if (user_id !== context.currentUser.id) api.clearCache()

  const updatedUserFromToken = await dispatch(updateUserDataFromToken())

  if (isPublicTenant())
    return await dispatch(
      updateLoggedInUserData({ loginRequired: false, authenticated: true })
    )
  else await dispatch(updateTenantSettings((await api.get('/settings/')).data))

  const { is_staff } = getState().user
  const tenantSettings = getState().tenantSettings

  const user = is_staff
    ? await api.getStaff(user_id)
    : await api.getClient(user_id)

  if (user === null) return updatedUserFromToken

  // check if contract needs to be accepted
  const allowedToAcceptContract = [GROUP_MANAGEMENT, GROUP_SUPERUSER]
  if (
    is_staff &&
    user.group &&
    allowedToAcceptContract.includes(user.group.name)
  )
    await checkContract(dispatch)

  user.name = [user.profile.first_name, user.profile.last_name].join(' ')
  user.loginRequired = false
  user.authenticated = true
  if (user.is_staff)
    await dispatch(setParticipantFilter({ label: user.name, value: user_id }))

  // If the terms have been changed while the user is logged in the user is not prompted
  // to accept the terms again until the next login
  if (!localStorage.getItem('onLogin')) user.profile.show_terms = false
  else localStorage.removeItem('onLogin')

  if (!isPublicTenant()) {
    initializePendo(user, tenantSettings)
    dispatch(getAnnouncements())
    dispatch(getNotifications())
  }

  return await dispatch(updateLoggedInUserData(user))
}

const onLogout = (dispatch: (Action) => *) => {
  postGlobalEvent(LOGOUT_EVENT_KEY)
  api.clearCache()
  setLocale()
  dispatch(clearAlerts())
  dispatch(clearAnnouncements())
  dispatch(deselectClient())
  clearFilters(dispatch)
  dispatch(updateShowContract(false))
  notifications.stopRefreshing()
  auth.clear(false)
}

const clearFilters = (dispatch: (Action) => *) => {
  dispatch(clearCommunicationFilters())
  dispatch(clearFormFilters())
  dispatch(clearUserFilters())
}

export const logoutWithoutRedirect = (): Function => (
  dispatch: (Action) => *
) => {
  onLogout(dispatch)
  dispatch(doLogout())
  dispatch(getAnnouncements())
}

export const logout = (): Function => async (dispatch: (Action) => *) => {
  onLogout(dispatch)
  dispatch(doLogout())
  dispatch(getAnnouncements())
  navigator.navigate('/')
}

export const doLogout = (): Action => ({
  type: LOGOUT,
  payload: null,
})

export const setLoginRequiredState = (payload: boolean): Action => ({
  type: SET_LOGIN_REQUIRED_STATE,
  payload,
})

const updateUserDataFromToken = (): Action => ({
  type: UPDATE_USER_DATA_FROM_TOKEN,
  payload: resolveUserDataFromToken(),
})

export const updateLoggedInUserData = (payload: Object): Action => ({
  type: UPDATE_LOGGED_IN_USER,
  payload,
})

export const updateShowTerms = (payload: Object): Action => ({
  type: UPDATE_SHOW_TERMS,
  payload,
})

export const isPublicTenant = (): boolean =>
  resolveSubdomain() === PUBLIC_TENANT

const patchUserData = (payload: Object) => {
  const id = context.currentUser.id
  const url = context.loggedInAsStaff ? `staff/${id}` : `clients/${id}`
  return api.patch(url, payload)
}

function resolveUserDataFromToken() {
  const attributes = auth.data
  const properties = {
    token: auth.token,
    email: attributes.email,
    id: attributes.user_id,
    is_staff: attributes.is_staff,
    group: attributes.group === 'Admin' ? { id: 1 } : { id: 0}
  }

  return properties
}

async function checkContract(dispatch: Function) {
  const response = await api.get('/contracts/is-confirmed/')

  if (response.ok) return dispatch(updateShowContract(!response.data.confirmed))
  else dispatch(logout())
}

function initializePendo(user: Object, tenant: Object) {
  if (__DEV__) return
  if (user.profile && user.profile.id) {
    if (!_pendoInitialized) {
      // $FlowIgnore
      pendo.initialize({
        visitor: {
          id: `${user.id}@${tenant.tenant_id}`,
          type: user.is_staff ? 'Staff' : 'Client',
          primary_language: user.profile.primary_language,
          created: user.created,
          plan: user.profile.active_subscription?.type || null,
          authorized_user: user.profile.authorized_to_users ? true : false,
        },

        account: {
          id: tenant.tenant_id,
          name: tenant.name,
        },
        excludeAllText: true,
      })
      _pendoInitialized = true
    } else {
      // Identify handle the update of data after initialization
      // required for instance when user logs out from one account
      // into another
      // $FlowIgnore
      pendo.identify({
        visitor: {
          id: `${user.id}@${tenant.tenant_id}`,
          type: user.is_staff ? 'Staff' : 'Client',
          primary_language: user.profile.primary_language,
          created: user.created,
          plan: user.profile.active_subscription?.type || null,
          authorized_user: user.profile.authorized_to_users?.length
            ? true
            : false,
        },

        account: {
          id: tenant.tenant_id,
          name: tenant.name,
        },
        excludeAllText: true,
      })
    }
  }
}
