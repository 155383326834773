import React, { Component } from 'react'
import self from 'autobind-decorator'
import connect from 'bound-connect'
import { Loader } from 'components/generic'
import { GROUP_SUPERUSER, GROUP_ADMIN, SUBSCRIPTION_TYPE_PRO, GROUP_MANAGEMENT } from 'constants'

export type GroupTypes = GROUP_SUPERUSER | GROUP_ADMIN | GROUP_MANAGEMENT
export type PlanTypes = SUBSCRIPTION_TYPE_PRO
export type StatusType = 'loading' | 'authorized' | 'forbidden'
export type PropTypes = {
  isStaff: boolean,
  userId: number | null,
  allowedGroups?: Array<GroupTypes>,
  allowedPlans?: Array<PlanTypes>,
  authorizedComponent: Function,
  forbiddenComponent: Function,
  noloader?: boolean,
}


export default class AuthorizedComponent extends Component {

  @self
  renderAuthorized () {
    return this.props.children ? this.props.children : null
  }

  //eslint-disable-next-line class-methods-use-this
  renderForbidden () {
    return null
  }

  render () {
    const props = this.props
    return <Authorized
      authorizedComponent={ this.renderAuthorized }
      forbiddenComponent={ this.renderForbidden }
      {...props} />
  }

}


@connect
export class Authorized extends Component <PropTypes> {

  constructor (props: PropTypes) {
    super(props)
  }

  static actions = []
  static properties = state => ({
    isStaff:      state.user.is_staff,
    loaded:       state.user.loaded,
    userGroup:    state.user.group ? state.user.group.name : null,
    userPlan:     state.user.profile.active_subscription ? state.user.profile.active_subscription.type : null
  })

  //eslint-disable-next-line complexity
  get status (): StatusType {

    const allowedGroups = this.props.allowedGroups
    const allowedPlans  = this.props.allowedPlans
    const userGroup     = this.props.userGroup
    const userPlan      = this.props.userPlan

    if (!this.props.loaded)
      return 'loading'
    if (allowedGroups && allowedGroups.length > 0
        && this.props.isStaff && allowedGroups.indexOf(userGroup) > -1)
      return 'authorized'
    if (this.props.isStaff && (!allowedGroups || allowedGroups.length === 0) && !allowedPlans)
      return 'authorized'
    if (this.props.isStaff && (allowedPlans && allowedPlans.indexOf(userPlan) > -1))
      return 'authorized'
    return 'forbidden'
  }

  render () {
    switch (this.status) {
      case 'loading':
        return this.props.noloader
          ? null
          : <Loader />
      case 'authorized':
        return this.props.authorizedComponent()
      case 'forbidden':
        return this.props.forbiddenComponent()
    }
  }
}
