// @flow
import React, { Component } from 'react'
import fields from '../base/renderers'
import Icon from 'components/icons'
import Text from 'components/text'
import HelpIcon from 'components/HelpIcon'

import type { ComponentType, Node } from 'react'


type FieldProps = {
  type: string,
  value: *,
  label: string,
  errors?: Array<Error>,
  component?: ComponentType<*>,
  required?: boolean,
  read_only?: boolean,
  forceShow?: boolean,
  labelComponent?: Node,
  id?: string,
  nolabel?: boolean,
  help_text?: string,
  disabled?: boolean,
  translateLabel?: boolean,
  renderValueOnly?: boolean,
  smallLabel?: boolean,
  hideRequiredIndicator?: boolean,
  onKeyDown?: Function
}


export default class Field extends Component<FieldProps> {

  get inputNode (): Node {
    if (this.props.component)
      return fields.renderComponent(this.props)
    return fields.render(this.props)
  }

  get labelText (): string | React$Element<*> {
    if (this.props.labelComponent)
      return <this.props.labelComponent notranslate>
        { this.props.label }
      </this.props.labelComponent>
    if (this.props.translateLabel)
      return <Text>{ this.props.label }</Text>
    return this.props.label
  }

  get errors (): Array<Error> {
    return this.props.errors
      ? this.props.errors.map(toString)
      : []
  }

  render: Function = (): Node => {
    // If we render only the value we have to adjust the element alignment
    const style = this.props.renderValueOnly
      ? { 'alignItems': 'center' }
      : null

    if (!this.props.read_only || this.props.forceShow)
      return <div className='form-field' style={ style }>
        { this.renderLabel() }
        { this.renderInput() }
        { this.renderErrors() }
      </div>
    else
      return null
  }

  submitWithEnter: Function = (event): Function => {
    if (this.props.onKeyDown && event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.props.onKeyDown()
    }
  }

  renderLabel: Function = (): Node => {
    if (this.props.nolabel) return null
    const style = this.props.smallLabel
      ? { 'fontSize': '0.8em', 'fontWeight': 400 }
      : null
    const className = 'label field-label'
    return <label htmlFor={ this.props.id } className={ className } style={ style }>
      { this.labelText }
      <HelpIcon text={ this.props.help_text } />
      { this.renderRequiredIndicator() }
    </label>
  }

  renderInput: Function = (): Node =>
    <div className='field-control' onKeyDown={ this.submitWithEnter }>
      { this.inputNode }
    </div>

  renderErrors: Function = (): Node =>
    <div className='field-errors color-error'>
      { this.errors.map(renderError) }
    </div>

  renderRequiredIndicator: Function = (): Node => {
    if (this.props.hideRequiredIndicator) {
      return null
    }
    return this.props.required && <RequiredIndicator />
  }
}


const RequiredIndicator = () =>
  <Icon.Asterisk className='required-indicator' />


const renderError = (error, n) =>
  <p key={ n }
    className='error'>
    { error }
  </p>


const toString = str =>
  str.toString()
