import { connect } from 'react-redux'

export default function boundConnect(Entity) {
  let mem = {
    actions: a => (mem.actions = a),
    state: a => (mem.state = a),
  }

  const assignState = a => (mem.state = a)
  const assignActions = a => (mem.actions = a)
  const properties = state => assignState(reduceProperties(Entity, state))
  const actions = dispatch => assignActions(reduceActions(Entity, dispatch))


  class Middleware extends Entity {

    static displayName = `${Entity.displayName || Entity.name} middleware`

    constructor(props) {
      super(props)
      Object.defineProperties(this, {
        actions: {
          value: mem.actions,
          writable: false,
          configurable: false,
        },
        properties: {
          value: mem.state,
          writable: false,
          configurable: false,
        },
      })
    }
  }

  const MiddlewareComponent = connect(properties, actions, null, { forwardRef: true })(Middleware)
  MiddlewareComponent.displayName = `Connected ${Middleware.displayName}`

  return MiddlewareComponent
}

const reduceProperties = (Entity, state) => {
  if (typeof Entity.properties === 'function')
    return Entity.properties(state)
  if (typeof Entity.properties === 'undefined')
    return {}
  throw new TypeError(`${Entity.name}'s static \`properties\` property must be of type function | void.`)
}

const reduceActions = (Entity, dispatch) => {
  if (Entity.actions instanceof Array)
    return Entity.actions.reduce((obj, action) => includeAction(obj, action, dispatch), {})
  if (typeof Entity.actions === 'function')
    return Entity.actions(dispatch)
  throw new TypeError(`${Entity.name}'s static \`actions\` property must be a function or an array of action names.`)
}

const includeAction = (actions, action, dispatch) =>
  Object.assign(actions, {
    [action.name]: (...args) => dispatch(action(...args)),
  })
