/**
 * @module Confirmation
 * @flow
 */

import React, { Component } from 'react'

import { confirmable, createConfirmation } from 'react-confirm'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { default as DialogContent } from '@material-ui/core/DialogContent'
import { default as DialogActions } from '@material-ui/core/DialogActions'

import { DEFAULT_PRIMARY_COLOR } from 'constants'

import { PrimaryButton, SecondaryButton, RemoveButton } from 'components/buttons'
import { ToolbarContainer, Toolbar } from 'components/generic'
import { Label, Subtitle } from 'components/text'

type PropTypes = {
  message: string,
  title: string,

  // Optional. Set the confirmation color to tenants custom color.
  customColor?: string,

  // Optional. Set to true to show the confirmation button as an error button. Default is "Accept".
  warning?: boolean,

  // Optional. The label to but on the cancel button. default is "Cancel".
  cancelLabel?: string,

  // Optional. The label to but on the confirm button.
  confirmLabel?: string,

  // Optional. Pass the amount of time in ms (1000*1 = 1 sec) when the dialog should close automatically.
  timeout?: number,

  // From confirmable. Call to close the dialog with promise resolved.
  proceed: Function,

  // From confirmable. Call to close the dialog with promise rejected.
  cancel: Function,

  // From confirmable. Call to only close the dialog.
  dismiss: Function,
}


type StateType = {
  visible: boolean,
  onClose: ?Function,
  timeoutTimer: number,
  theme: ?Function,
}

const TitleComponent = Subtitle
const DISMISS_DELAY = 0
const defaultTheme = createTheme({
  overrides: {
    MuiDialog: {
      root: {
        zIndex: '13000 !important',
      },
    },
  }
})


@confirmable
class ConfirmationDialog extends Component <PropTypes, StateType> {

  countdown: IntervalID

  state = {
    visible: false,
    onClose: null,
    timeoutTimer: 0,
    theme: defaultTheme
  }

  componentDidMount () {
    const color = this.props.customColor ? this.props.customColor : DEFAULT_PRIMARY_COLOR
    this.setState({
      visible: true,
      theme: createTheme({
        overrides: {
          MuiDialog: {
            root: {
              zIndex: '13000 !important',
            },
            paper: {
              borderRadius: '10px',
              borderTop: `10px solid ${color}`
            }
          },
        }
      })
    })
    if (this.props.timeout)
      this.runTimeoutTimer()
  }

  componentWillUnmount () {
    clearInterval(this.countdown)
  }

  get confirmStyle () {
    return this.props.customColor && !this.props.warning
      ? { 'background': this.props.customColor }
      : {}
  }

  get cancelStyle () {
    return this.props.customColor
      ? { 'border': `1px solid ${this.props.customColor}`, 'color': this.props.customColor }
      : {}
  }

  close: Function = (onClose): Function => {
    return event => {
      event.stopPropagation()
      this.setState({
        visible: false,
        onClose,
      }, () => this.handleDidClose())
    }
  }

  closeWithoutEvent = () => {
    if (this.props.timeout) {
      const cancel = document.getElementById('cancel')
      if (cancel)
        cancel.click()
    } else return
  }

  async handleDidClose (): Promise<*> | void {
    // Evaluate the stored handler, e.g. `proceed`, `cancel` or `dismiss` prop fn
    if (this.state.onClose && typeof this.state.onClose === 'function') {
      await this.state.onClose()
      await new Promise(resolve => this.setState({ onClose: null }, resolve))
    }
  }

  runTimeoutTimer () {
    if (this.props.timeout) {
      const timeoutTimeInSeconds = (this.props.timeout - 5000) / 1000
      this.setState({ timeoutTimer: timeoutTimeInSeconds })
    }
    this.countdown = setInterval(() => {
      if (this.state.timeoutTimer && this.state.timeoutTimer <= 0) {
        clearInterval(this.countdown)
        this.closeWithoutEvent()
      } else {
        this.setState((prevState) => ({ timeoutTimer: prevState.timeoutTimer - 1 }))
      }
    }, 1000)
  }

  render(): React$Element<*> {
    const ConfirmButton = this.props.warning ? RemoveButton : PrimaryButton
    const confirmLabel = this.props.confirmLabel ? this.props.confirmLabel : 'Accept'
    const cancelLabel = this.props.cancelLabel ? this.props.cancelLabel : 'Cancel'
    const dismissMethod = this.props.timeout ? this.props.proceed : this.props.dismiss
    return (
      <ThemeProvider theme={ this.state.theme }>
        <Dialog onClose={ this.close(dismissMethod) } aria-labelledby="customized-dialog-title" open={ this.state.visible }>
          <div className="confirmation-dialog">
            <TitleComponent>{ this.props.title }</TitleComponent>
            { this.props.message && <DialogContent dividers>
              <Label>{ this.props.message }</Label>
            </DialogContent> }
            <DialogActions>
              <ToolbarContainer>
                <Toolbar left>
                  <ConfirmButton id='confirm' style={ this.confirmStyle } onClick={ this.close(this.props.proceed) }>
                    { confirmLabel }
                  </ConfirmButton>
                </Toolbar>
                <Toolbar right>
                  <SecondaryButton id='cancel' style={ this.cancelStyle } onClick={ this.close(this.props.cancel) }>
                    { cancelLabel }
                  </SecondaryButton>
                </Toolbar>
              </ToolbarContainer>
            </DialogActions>
          </div>
        </Dialog>
      </ThemeProvider>
    )
  }
}

// create confirm function
const confirm = createConfirmation(ConfirmationDialog, DISMISS_DELAY)

export default function (
  title: string,
  message: string,
  customColor?: string,
  warning?: boolean,
  cancelLabel?: string,
  confirmLabel?: string,
  timeout?: number): Function {
  return confirm({
    title,
    message,
    customColor,
    warning,
    cancelLabel,
    confirmLabel,
    timeout,
  })
}
