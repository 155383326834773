/**
 * @flow
 * @class FilePreview
 */
import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import self from 'autobind-decorator'

import { FaFile as FallbackIcon } from 'react-icons/fa/'
import { FaFileAlt as TextFileIcon } from 'react-icons/fa'
import { FaFileArchive as ArchiveIcon } from 'react-icons/fa'
import { FaFileExcel as SheetsFileIcon } from 'react-icons/fa'
import { FaFileVideo as VideoFileIcon } from 'react-icons/fa'

import { BaseIconComponent } from 'components/icons'
import { Badge } from 'components/generic'
import { SmallText } from 'components/text'
import { onDidResizeWindow } from 'utils/dom'
import { FILE_TYPES } from 'constants'
import { __ } from 'utils/gettext'
import { WithTooltip } from 'former-ui'

import type { ComponentType } from 'react'


// NOTE: bmp :---)
const MOV              = /\.(MOV|mov)(\?.*)?$/gi
const SUPPORTED_VIDEO  = /\.(mp4|webm|ogv|wav|MOV|mov)(\?.*)?$/gi
const MATCH_VIDEO      = /\.(mp4|webm|ogv|3gp|flv|wmv|avi|wav|mpeg)(\?.*)?$/gi
const MATCH_IMAGE      = /\.(jpe?g(?:2000)?|png|gif|bmp|svg)(\?.*)?$/gi
const MATCH_EXCEL      = /\.(xls\w?|xlt|xps|ods|csv)(\?.*)?$/gi
const MATCH_TEXTUAL    = /\.(doc\w?|txt|pdf|ppt\w?|nfo|html)(\?.*)?$/gi
const MATCH_ARCHIVE    = /\.(7?zip|rar\d*)(\?.*)?$/gi

const createTestFn: Function = (re: RegExp) => (url: string) => new RegExp(re).test(url)
export const isSupportedVideo: Function = createTestFn(SUPPORTED_VIDEO)
export const isVideo: Function = createTestFn(MATCH_VIDEO)
export const isImage: Function = createTestFn(MATCH_IMAGE)
export const isSpreadsheet: Function = createTestFn(MATCH_EXCEL)
export const isTextFile: Function = createTestFn(MATCH_TEXTUAL)
export const isArchive: Function = createTestFn(MATCH_ARCHIVE)
export const isMOV: Function = createTestFn(MOV)

const VIDEO_PLAYER_RATIO = 16/9
const DEFAULT_WIDTH = 500
const VIDEO_PLAYER_DEFAULTS = {
  videoPlayerWidth: DEFAULT_WIDTH,
  videoPlayerHeight: DEFAULT_WIDTH / VIDEO_PLAYER_RATIO,
  muted: false,
}

type PropType = {
  url: string,
  type?: string,
  iconSize?: number,
  name?: string,
  notooltip?: boolean,
  withname?: boolean,
  maxPlayerSize?: [number | string, number | string],
  playerStyle?: Object,
  onPlayVideo?: Function,
  onPauseVideo?: Function,
  altText?: string,
  removeFile?: Function,
  noLink?: boolean
}

type stateType = {
  muted: boolean,
  videoPlayerWidth: number,
  videoPlayerHeight: number,
}


export default class FilePreview extends Component<PropType, stateType> {

  static defaultProps: Object = {
    iconSize: 15
  }

  state: stateType = VIDEO_PLAYER_DEFAULTS
  subscription: *

  get type (): string {
    return this.props.type ? this.props.type : this.props.url
  }

  get iconComponent (): ComponentType<*> {
    const type  = this.type
    if (isVideo(type))
      return VideoFileIcon
    if (isSpreadsheet(type))
      return SheetsFileIcon
    if (isTextFile(type))
      return TextFileIcon
    if (isArchive(type))
      return ArchiveIcon
    return FallbackIcon
  }

  get width (): number | string {
    return this.props.maxPlayerSize
      ? this.props.maxPlayerSize[0]
      : this.state.videoPlayerWidth
  }

  get height (): number | string {
    return this.props.maxPlayerSize
      ? this.props.maxPlayerSize[1]
      : this.state.videoPlayerHeight
  }

  @self
  onWindowResize () {
    const windowWidth = window.innerWidth
    const maxWidth = DEFAULT_WIDTH
    let maxWidthRatio = windowWidth <= global.breakpoint.mobile ? 0.8 : 0.7

    const videoPlayerWidth = Math.min(maxWidth, maxWidthRatio * windowWidth)
    const videoPlayerHeight = videoPlayerWidth / VIDEO_PLAYER_RATIO

    this.setState({ videoPlayerWidth, videoPlayerHeight })
  }

  componentDidMount () {
    this.onWindowResize()
    this.subscription = onDidResizeWindow(this.onWindowResize)
  }

  componentWillUnmount () {
    this.subscription && this.subscription.dispose()
  }

  render (): React$Element<*> | null {
    const type = this.type
    if (type === FILE_TYPES.video || isSupportedVideo(type))
      return this.renderVideo()
    if (type === FILE_TYPES.img || isImage(type))
      return this.renderImage()
    if (type === FILE_TYPES.document || isTextFile(type))
      return this.renderBadge()
    return this.renderIcon()
  }

  renderVideo (): React$Element<*> {
    const url: string | Array<Object> = isMOV(this.props.url)
      ? [{
        src: this.props.url,
        type: 'video/mp4'
      }]
      : this.props.url
    return <ReactPlayer
      url={ url }
      width={ this.width }
      height={ this.height }
      onError={ this.onVideoError }
      muted={ this.state.muted }
      volume={ 1 }
      style={ this.props.playerStyle }
      onPlay={ this.props.onPlayVideo }
      onPause={ this.props.onPauseVideo }
      onEnded={ this.props.onPauseVideo }
      config={{
        file: {
          attributes: { preload: 'metadata' }
        }
      }}
      controls />
  }

  renderImage (): React$Element<*> {
    return this.props.notooltip
      ? <img
        style={{ objectFit: 'cover' }}
        src={ this.props.url }
        alt={ this.props.altText || __('Image') } />
      : <WithTooltip text={ this.props.name }>
        <img
          style={{ objectFit: 'cover' }}
          src={ this.props.url }
          alt={ this.props.altText || __('Image') } />
      </WithTooltip>
  }

  renderBadge (): React$Element<*> | null {

    const name = this.props.name
      ? this.props.name
      : 'Unknown attachment'

    const openInNewTab = this.props.noLink
      ? false
      : true

    if (this.props.removeFile)
      return <Badge onRemove={ () => this.props.removeFile() } openInNewTab={ openInNewTab }>
        <a href={ null }
          target='_blank'
          rel='noopener noreferrer' >
          { name }
        </a>
      </Badge>
    else
      return <Badge openInNewTab>
        { name }
      </Badge>
  }

  renderIcon (): React$Element<*> {
    let style = undefined
    if (this.props.withname)
      style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }

    return this.props.notooltip
      ? <span style={ style }>
        <BaseIconComponent
          size={ this.props.iconSize }
          icon={ this.iconComponent } />
        { this.renderName() }
      </span>
      : <WithTooltip text={ this.props.name }>
        <span style={ style }>
          <BaseIconComponent
            size={ this.props.iconSize }
            icon={ this.iconComponent } />
          { this.renderName() }
        </span>
      </WithTooltip>
  }

  renderName (): React$Element<*> | null {
    if (this.props.name && this.props.withname)
      return <SmallText notranslate>{ this.props.name }</SmallText>
    return null
  }

  @self
  onVideoError (err?: *) {

    this.setState({ muted: true })

    // TODO: Proper error handling
    //eslint-disable-next-line no-console
    console.warn('Something went wrong with the video playback:', err)
  }
}
