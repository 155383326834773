/**
 * @flow
 * @class Block
 */
import React, { PureComponent } from 'react'

type BlockProps = {
  children?: *,
}


export default class Block extends PureComponent<BlockProps> {
  render () {
    return <div className='block'>{ this.props.children }</div>
  }
}
