/**
 * @module TextPreviewField
 * @flow
 */

import React, { PureComponent } from 'react'


type ValueType = string

type StringFieldProps = {
  value: ValueType,
  className?: string,
}


export default class StringField extends PureComponent<StringFieldProps> {

  render () {

    return <p className={ `input-preview disabled ${ this.props.className || '' }`}>
      { this.props.value }
    </p>
  }
}
