import StringField from './StringField'


export default class EmailField extends StringField {

  attributes = {
    autoComplete: 'username',
    type: 'email'
  }
}
