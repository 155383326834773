import Fieldset, { BoundField } from './construct'


export function getInitialValues (fields) {
  const values = {}

  // TODO: Nested fields
  for (const [ id, field ] of Object.entries(fields))
    values[id] = field.initial || null

  return values
}


export function getFieldComponents (defs) {
  const fields = {}

  for (let [ id, field ] of Object.entries(defs))
    fields[id] = getFieldComponent(id, field)

  return fields
}


export function getFieldComponent (id, field) {

  const displayName  = `Bound Field ${id}`
  const defaultProps = {
    value:  null,
    errors: null }

  const component    = isFieldset(field)
    ? new Fieldset(field.children)
    : new BoundField(id, field)

  return Object.assign(component, {
    displayName,
    defaultProps })
}


const isFieldset = field =>
  field.type === 'nested object'
