// Node
// @flow
// @class NumericInputField

import React from 'react'
import BaseInputComponent, { getDisabledAttributeValue } from './base'


export type ValueType = number | null

export type OptionsType = {
  slider: boolean,
}

type NumericFieldState = {
  attributes: {
    min: number,
    max: number,
    steps: number,
    disabled: *,
    onChange: Function,
    className: string
  },
  value: string,
}

const SLIDER_DEFAULT_MIN = 0
const SLIDER_DEFAULT_MAX = 100


export default class NumericInputField extends BaseInputComponent<ValueType, OptionsType> {

  state: NumericFieldState = {
    attributes:  {
      min:       0,
      max:       100,
      steps:     100,
      disabled:  getDisabledAttributeValue(this.props),
      onChange:  this.handleChange.bind(this),
      className: 'full-width',
    },
    value: '',
  }

  static defaultValue: number = 0

  // eslint-disable-next-line complexity
  validate (value: number | string): number {

    if (typeof value === 'string')
      value = Number.parseInt(value)
    let min = this.state.attributes.min
    let max = this.state.attributes.max

    if (max && min && value >= min  && value <= max){
      return value
    } else if (max && value >= max){
      return max
    } else if (min && value <= min){
      return min
    }
    return value
  }

  handleChange (event: SyntheticKeyboardEvent<*>) {
    this.update(event.target.value)
  }

  forceSliderMinMax (min: number | null, max: number | null): Object {
    if (this.getOptionValue('slider')){
      if (!is.number(max)) max = SLIDER_DEFAULT_MAX
      if (!is.number(min)) min = SLIDER_DEFAULT_MIN
    }
    return { min: min, max: max }
  }

  // eslint-disable-next-line class-methods-use-this
  calculateSteps (min: number | null, max: number | null): * {
    if (min && max)
      return max - min
    else if (min)
      return 100 - min
    else if (max)
      return max
  }


  componentDidMount () {
    let min = this.getOptionValue('min_value')
    let max = this.getOptionValue('max_value')

    if (isNaN(parseInt(min)) || isNaN(parseInt(max))) {
      let vals = this.forceSliderMinMax(min, max)
      min = vals.min
      max = vals.max
    }

    let steps = 100

    steps = this.calculateSteps(min, max)

    this.setState({
      attributes: {
        min:       min,
        max:       max,
        steps:     steps,
        disabled:  getDisabledAttributeValue(this.props),
        onChange:  this.handleChange.bind(this),
        className: 'full-width',
      },
      value: this.props.value || '',
    })

    this.update(this.validate(this.props.value || this.constructor.defaultValue))
  }

  componentDidUpdate () {
    if (this.props.value && this.state.value !== this.props.value)
      this.setState({ value: this.props.value })
  }

  render () {
    const handleBlur = (e) => {
      this.update(this.validate(e.target.value))
    }

    return this.getOptionValue('slider')
      ? <SliderInput { ...this.state.attributes } value={this.state.value} />
      : <input type='number'
        { ...this.state.attributes }
        value={ this.state.value }
        onBlur={ handleBlur } />
  }

}


export class SliderInput extends React.PureComponent<*, { value: number }> {

  state = {
    value: 0,
  }

  static defaultProps = {
    min:   0,
    max:   100,
    steps: 100,
  }

  static getDerivedStateFromProps (props: *, { value }: *) {
    if (!('value' in props))
      return null

    // Ignore invalid values & frames with no changes
    const candidate = parseFloat(props.value)

    if (isNaN(candidate))
      return null
    if (candidate === value)
      return null

    const step = getSteps(props)
    const halfStep = step.interval / 2

    // Compensate the errors in rounding
    // and limit the value to the bounds defined
    // in the component's props.

    const highPassThreshold = step.min + halfStep
    if (candidate < highPassThreshold)
      return { value: step.min }

    const lowPassThreshold  = step.max - halfStep
    if (candidate > lowPassThreshold)
      return { value: step.max }

    return { value: candidate }
  }

  render () {
    const step = getSteps(this.props)

    return <div className='slider'>
      <div className='slider-container'>
        <output>
          { this.props.min }
        </output>
        <input
          min={ step.min }
          max={ step.max }
          step={ step.interval }
          value={ this.state.value }
          onChange={ this.props.onChange }
          disabled={ this.props.disabled }
          className={ this.props.className }
          type='range'
        />
        <output>
          { this.props.max }
        </output>
      </div>
    </div>
  }
}


function getSteps (props) {
  const minimum = parseFloat(props.min)
  const maximum = parseFloat(props.max)
  const min   = Math.min(minimum, maximum)
  const max   = Math.max(minimum, maximum)
  const steps = parseInt(props.steps)
  const range = max - min

  return { min, max, steps, range, interval: Math.floor(range / steps * 1000) / 1000 }
}
