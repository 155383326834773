// @flow
import load from 'components/LoadableComponent'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteNotFound } from 'views/Error/PageNotFound'

const shared = {
  staffUser: (pk: ID): string => `/management/settings/staff/${pk}`,
  createStaff: '/management/settings/staff/create/',
  listStaff: '/management/settings/staff/',
  tabView: '/management/',
}

export const typeUserOnlineBookingDescriptors = {
  // Settings root
  settings: '/management/settings/',

  createUser: '/management/clients/clients/create/',
  user: (pk: ID): string => `/management/clients/clients/${pk}`,
  importUsers: '/management/clients/import/',
  listClients: '/management/clients/clients/',

  // Settings / Staff
  ...shared,

  // Settings / General Settings
  tenantSettings: '/management/settings/general/',

  // Logs
  logs: '/management/logs/',
  secureAuthenticationLogs: '/management/logs/secure-authentication/',
}

export const descriptorsUserGroupManagement = {
  // Settings / Staff
  ...shared,

  courseDetails: (pk: ID): string =>
    `/management/content/courses/${pk}/details/`,
}

const ComposeUserView = load(() =>
  import('../views/Management/Users/ComposeUser')
)

const MassImport = load(() =>
  import('../views/Management/Users/UserMassImport')
)

const StaffListView = load(() => import('../views/Management/Users/StaffList'))

const ClientsList = load(() => import('../views/Management/Users/ClientsList'))

const RestrictedTabView = load(() =>
  import('../views/RestrictedTenantView/TabView')
)

const CourseView = load(() => import('../views/Management/Courses/CourseView'))

const TenantSettingsView = load(() =>
  import('../views/Management/Settings/Settings')
)

const LogsView = load(() => import('../views/Management/Logs/Logs'))

export const managementRoutes: * = (
  <Switch>
    <Route
      path={descriptorsUserGroupManagement.courseDetails(':id(\\d+)')}
      component={CourseView}
    />
    <Route
      path={descriptorsUserGroupManagement.staffUser(':id(\\d+)')}
      key='edit-user'
      component={ComposeUserView}
    />
    <Route
      path={descriptorsUserGroupManagement.createStaff}
      key='create-user'
      component={ComposeUserView}
    />
    <Route
      exact
      path={descriptorsUserGroupManagement.tabView}
      component={RestrictedTabView}
    />
    <RouteNotFound />
  </Switch>
)

export const onlineBookingUserRoutes: * = (
  <Switch>
    <Route
      path={typeUserOnlineBookingDescriptors.staffUser(':id(\\d+)')}
      key='edit-user'
      component={ComposeUserView}
    />
    <Route
      path={typeUserOnlineBookingDescriptors.createStaff}
      key='create-user'
      component={ComposeUserView}
    />
    <Route
      path={typeUserOnlineBookingDescriptors.createUser}
      component={ComposeUserView}
    />
    <Route
      path={typeUserOnlineBookingDescriptors.importUsers}
      component={MassImport}
    />
    <Route
      path={typeUserOnlineBookingDescriptors.user(':id(\\d+)')}
      component={ComposeUserView}
    />
    <Route
      path={typeUserOnlineBookingDescriptors.tenantSettings}
      component={TenantSettingsView}
    />
    <Route path={typeUserOnlineBookingDescriptors.logs} component={LogsView} />
    <Route
      exact
      path={typeUserOnlineBookingDescriptors.tabView}
      component={RestrictedTabView}
    />
  </Switch>
)
