/**
 * @module BaseComposeView
 * @flow
 */

import api from 'api'
import self from 'autobind-decorator'

import ForbiddenPage from 'views/Error/ForbiddenPage'
import navigator from 'routes/base'
import React, { Fragment, Component } from 'react'
import { Title, ErrorText } from 'components/text'
import { Card, Toolbar, Loader } from 'components/generic'
import { PrimaryButton, CancelButton } from 'components/buttons'
import DeleteButton from 'components/DeleteButton'
import AuthorizedComponent from 'components/AuthorizedComponent'
import FooterSection from 'views/Page/Footer'
import { resolveError } from 'utils/resolve'
import ComposeForm from './ComposeForm'


type El = React$Element<*>

export type FieldType = {
  fullwidth?: boolean,
  singlerow?: boolean,
  floatRight?: boolean,
  name?: string | Array<string>,
  errors?: string,
  props?: Object,
  component?: (number, *) => El,
  onChange?: (Object) => *,
  onSubmit?: (Object) => *,
  parseValue?: Function,
  normal?: boolean,
  renderField?: (content: El) => El,
  renderSection?: (children: { content: El, title: El, footer: El }) => El,
  innerRef?: Function
}

type ComposeViewState = {|
  data: Object | null,
  loading: boolean,
  notFound: boolean,
  notAllowed: boolean,
  errors: Object | null,
|}


export default class BaseComposeView extends Component<*, ComposeViewState> {

  /**
   * The name of the entity (e.g. User or Tag) that the view is for.
   * This is used for the title if it is set. Set to null if a title is not wanted.
   * @type {string|null}
   */
  static entity_name: string | null = ''
  static form_id: string | null = ''
  static strings = {
    add:  'Add',
    edit: 'Edit',
  }


  /**
   * The string that are used in the view
   * @type {Object}
   */
  strings = {}

  /**
   * If set to true the initial data is fetched for the view even when there is no id in the url
   * @type {boolean}
   */
  forceGetInitial: boolean = false

  /**
   * An array of the fields to be displayed in the view
   * @type {Array<FieldType>}
   */
  _fields: Array<FieldType> = []

  get fields () {
    if (this._fields.length) return this._fields
    if (this.props.fields) return this.props.fields
    return []
  }

  set fields (fields) {
    this._fields = fields
  }

  /**
   * Whether to show the view inside a Card component ornot
   * @type {boolean}
   */
  asCard: boolean = true

  /**
   * An optional function that will be called before saving the form if set
   * @type {Function}
   */
  confirmSave: Function | null = null

  /**
   * An optional function that will be called after tdeleting the entity if set
   * @type {Function}
   */
  afterDelete: Function | null = null

  /**
   * An optional url for the cancel button.
   * Default cancel action is to go to the previous page.
   * @type {string}
   */
  cancelURL: string | void = undefined

  composeForm = null

  state = {
    errors:     null,
    data:       null,
    loading:    true,
    notFound:   false,
    notAllowed: false,
  }

  // eslint-disable-next-line class-methods-use-this
  get url (): string {
    throw new Error('Subclasses must implement the url getter')
  }

  // eslint-disable-next-line class-methods-use-this
  get showDelete (): boolean {
    throw new Error('Subclasses must implement the showDelete getter')
  }

  // eslint-disable-next-line class-methods-use-this
  get deleteGroups (): Array<string> {
    return []
  }

  get method (): 'POST' | 'PUT' {
    return this.entry_id ? 'PUT' : 'POST'
  }

  get entry_id (): number | null {
    let id = null
    if (this.props.match)
      id = parseInt(this.props.match.params.id)
    return is.nan(id) ? null : id
  }

  get fieldset () {
    return this.composeForm && this.composeForm.getFieldset()
  }

  addErrorMessage (...args: Array<*>) {
    if (this.props.addErrorMessage)
      this.props.addErrorMessage(args)
    else
      // eslint-disable-next-line no-console
      console.error(args)
  }

  /**
   * Get the entity data from the backend
   * @return {Promise} A promise that returns the response
   */
  async getData (): Promise<*> {
    return api.get(this.url)
  }

  /**
   * Apply the initial entity data to state.data
   */
  async applyInitialData () {
    const data = {}
    if (this.forceGetInitial || this.entry_id) {
      const response = await this.getData()
      if (!response.problem) {
        Object.assign(data, response.data)
      } else if (response.status === 404) {
        this.setState({ notFound: true })
      } else if (response.status === 403) {
        this.setState({ notAllowed: true })
      } else {
        this.setState({ errors: response.data })
        this.addErrorMessage(resolveError(response.status, 'GET'))
      }
    }
    this.setState({ data, loading: false }, this.onDataApplied)
  }

  /**
   * A function for doing things after the initial data has been fetched from the backend
   * @return {void}
   */
  //eslint-disable-next-line
  onDataApplied () {}

  /**
   * This function is called in componentDidMount and instead of overriding componentDidMount
   * this should be used when necessary to do something when component mounts
   * @return {[type]} [description]
   */
  //eslint-disable-next-line
  onDidMount () {}

  async componentDidMount () {
    await this.applyInitialData().then(this.onDidMount())
  }

  @self
  onChange (update: Object) {
    this.setState(update)
    // if (this.props.onChange)
    //   this.props.onChange(this.getFormData())
  }

  /**
   * Get a value for a field
   * @param  {string|Array<string>} field   The name (string) or the path (Array<string>) of the field
   * @param  {Object}               current The data object to look for the field value from
   * @return {*}                            The value of the field or undefiend if not found
   */
  getValue (field?: string | Array<string>, current?: Object = this.state.data || {}) {
    if (!field)
      return
    if (is.string(field))
      return current[field]
    else if (field.length === 1)
      return current[field[0]]
    else
      return this.getValue(field.slice(1), current[field[0]])
  }

  render () {
    return this.renderView()
  }

  renderView () {
    const loaded = this.state.data !== null
    const Component = this.asCard ? Card : 'div'

    if (this.state.notAllowed)
      return this.renderForbidden()
    if (this.state.notFound)
      return this.renderNotFound()

    return <Fragment>
      <Component id={ this.constructor.form_id }>
        { this.renderTitle() }
        { loaded ? this.renderForm() : <Loader />}
        { this.renderFooter() }
      </Component>
      { this.renderExtras() }
    </Fragment>
  }

  /**
   * This renders en error page for when the user has no access rights to the entity
   */
  //eslint-disable-next-line class-methods-use-this
  renderForbidden () {
    return ForbiddenPage({
      position: 'relative',
      top: '100px',
      justifycontent: 'center',
      maxWidth: '325px',
    })
  }

  /**
   * Render a not found text when the entity is not found
   */
  //eslint-disable-next-line class-methods-use-this
  renderNotFound () {
    return <Card><ErrorText>Entity not found</ErrorText></Card>
  }

  /**
   * Render a title element if the entity name is set
   */
  renderTitle () {
    return !this.props.notitle && this.constructor.entity_name !== null && <Title>{ this.entry_id
      ? `${this.constructor.strings.edit} ${this.constructor.entity_name}`
      : `${this.constructor.strings.add} ${this.constructor.entity_name}`
    }</Title>
  }

  /**
   * Render a ComposeForm component
   */
  renderForm () {
    return this.state.loading
      ? <Loader />
      : <ComposeForm
        ref={ ref => this.composeForm = ref }
        id={ this.entry_id }
        url={ this.url }
        data={ this.state.data }
        errors={ this.state.errors }
        method={ this.method }
        strings={ this.strings }
        fields={ this.props.fields || this.fields }
        sections={ this.props.sections }
        renderField={ this.props.renderField }
        renderSection={ this.props.renderSection }
        onChange={ this.onChange }
        deletebutton={ this.showDelete }
        deleteGroups={ this.deleteGroups }
        addSuccessMessage={ this.props.addSuccesMessage }
        addErrorMessage={ this.props.addErrorMessage }
        onReferenceError={ this.onReferenceError }
        mapResolvedFieldset={this.props.mapResolvedFieldset}
      />
  }

  /**
   * A helper function to allow the extending classes to change the data without having to
   * overwrite the whole handleSave function
   * @return {Object} The data to be sent
   */
  getFormData () {
    return this.state.data
  }

  /**
   * Called on save button click. Calls confirmSave if it's set otherwise call handleSave
   * @type {void}
   */
  @self
  async save () {
    if (this.props.onSubmit)
      await this.props.onSubmit(this.getFormData())
    if (this.confirmSave)
      this.confirmSave()
    else
      this.handleSave()
  }

  /**
   * Saves the entity
   */
  @self
  async handleSave (returnResp: boolean) {
    let response = {}
    switch (this.method) {
      case 'POST':
        response = await api.post(this.url, this.getFormData())
        break

      default:
        response = await api.put(this.url, this.getFormData())
    }

    if (response.valid){
      this.onSuccess(response)
      if (returnResp)
        return response
    }
    else
      this.handleError(response)
  }

  handleError (response: *, showErrorMessage: boolean=true) {
    if (response.data)
      this.onChange({ errors: response.data })
    showErrorMessage
      && this.props.addErrorMessage
      && this.props.addErrorMessage(resolveError(response.status))
  }

  onSuccess (response: *) {
    navigator.previous()
    this.props.addSuccessMessage && this.props.addSuccessMessage(this.strings.createSuccessMessage)
  }

  // eslint-disable-next-line
  renderExtras () {
    return null
  }

  /**
   * Render a base footer with save, cancel and delete buttons. Override this if something different is needed
   */
  renderFooter () {
    return <FooterSection>
      { this.renderFooterToolbar()}
    </FooterSection>
  }

  renderFooterToolbar () {
    const disabled = !this.state.data
    return <Toolbar left>

      <PrimaryButton
        disabled={ disabled }
        onClick={ this.save }>
        Save
      </PrimaryButton>

      <CancelButton url={ this.cancelURL } />

      { this.method === 'PUT' && this.showDelete &&
        <AuthorizedComponent allowedGroups={ this.deleteGroups }>
          <DeleteButton
            url={ this.url }
            confirmationTitle={ this.strings.confirmDeleteLabel }
            confirmationLabel={ this.strings.confirmDeleteText }
            successMessage={ this.strings.deleteSuccessMessage }
            afterDelete={ this.afterDelete }
          />
        </AuthorizedComponent> }

    </Toolbar>
  }
}
