// @flow
import React from 'react'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'
import { Header, Icon, Button } from '@nordhealth/react'
import { Button as ButtonWithTranslation } from 'components/designSystemComponents'

import { decreaseStep } from '../../../actions/onlineBooking'
import Text from 'components/text'
import { __ } from 'utils/gettext'

import InstructionText from './InstructionText'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'

type Props = {
  onClick: Function,
  headingText?: string,
  buttonLabel?: string,
  instructionText?: string,
  hideBackButton?: boolean,
}

const MAP_STEP_TO_HEADING = [
  'Service',
  'Location',
  'Date',
  'Therapist',
  'Appointment & time',
  'Summary',
  'Checkout',
]

const BookingHeader = (props: Props): Node => {
  const step = useSelector((state: StateType) => state.onlineBooking.step)
  const heading = props.headingText ? (
    props.headingText
  ) : (
    <span className='n-font-weight-heading'>
      {__(`${MAP_STEP_TO_HEADING[step - 1]}`)}
    </span>
  )

  if (step > 7) return <></>

  return (
    <div className='booking-header-mobile'>
      <Header>
        <div className='content'>
          <span>
            {heading}
            {step > 6 ? '' : ` (${step}/6)`}
          </span>
          <ButtonWithTranslation slot='action' onClick={props.onClick}>
            {props.buttonLabel ? props.buttonLabel : 'Cancel'}
          </ButtonWithTranslation>
        </div>
      </Header>
      <ProgressBar step={step} />
    </div>
  )
}

type ProgressBarProps = {
  step: number,
}

const ProgressBar = ({ step }: ProgressBarProps): Node => {
  const fillerStyle = {
    width: `${(step / 6) * 100}%`,
  }

  if (step <= 6)
    return (
      <div className='progress-bar'>
        <div className='filler' style={fillerStyle}></div>
      </div>
    )
  else return null
}

const DESKTOP_STEP_TO_HEADING = [
  'What service are you looking for?',
  'Where?',
  'When?',
  'Find therapist and available time',
  '',
  'Appointment details',
  'Checkout',
  'Appointment confirmed',
]

const INSTRUCTION_TEXT_TO_HEADER = [
  '',
  '',
  '',
  '',
  '',
  'Look through the appointment details and continue to checkout.',
  'We need a few more details to complete your booking. By registering a user account, you will be able to change your information later.',
  'You will receive a reminder 24 hours before your appointment.',
]

export const BookingHeaderDesktop = (props: Props): Node => {
  const dispatch = useDispatch()
  const step = useSelector((state) => state.onlineBooking.step)

  const heading = props.headingText
    ? props.headingText
    : DESKTOP_STEP_TO_HEADING[step - 1]

  const instructionText = props.instructionText
    ? props.instructionText
    : INSTRUCTION_TEXT_TO_HEADER[step - 1]

  const headerTitle = () => {
    if (!heading) return null
    // Confirmation view on desktop is step 5 in the UI but actual step is 6
    const currentStep = step === 6 ? 5 : step
    return (
      <h2>
        <Text>{heading}</Text>{' '}
        {step < 7 && (
          <span className='step-counter n-color-status-highlight'>{`${currentStep}/5`}</span>
        )}
      </h2>
    )
  }

  // Desktop doesn't have actual step 5 so skip it
  const previous =
    step === 6
      ? () => dispatch(decreaseStep(2))
      : () => dispatch(decreaseStep())

  return (
    <div className='booking-header-desktop'>
      <div className='actions'>
        {!props.hideBackButton && (
          <span
            style={{
              visibility: step > 1 && step < 8 ? 'visible' : 'hidden',
            }}
          >
            <Button onClick={previous}>
              <Icon name='arrow-left' size='s' /> {__('Back')}
            </Button>
          </span>
        )}
        {headerTitle()}
        <span>
          <Button slot='action' onClick={props.onClick}>
            <Icon name='interface-close-small' size='s' />{' '}
            {props.buttonLabel ? __(props.buttonLabel) : __('Cancel')}
          </Button>
        </span>
      </div>
      <InstructionText text={instructionText} />
    </div>
  )
}

export default BookingHeader
