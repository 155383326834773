// @flow
import React, { useEffect, useState } from 'react'
// $FlowIgnore
import { useSelector } from 'react-redux'
import { Spinner } from '@nordhealth/react'

import BookingErrorView from './BookingErrorView'

import withOnlineBookingModal from '../extra/OnlineBookingModal'

import SelectEmployeeDesktopLayout from '../layouts/SelectEmployeeDesktopLayout'
import SelectEmployeeMobileLayout from '../layouts/SelectEmployeeMobileLayout'

import { useFetch } from '../utils/useFetchHook'
import isMobile from 'utils/isMobileHook'

import { ENDPOINT_URLS } from '..'

import type { StateType } from 'store/initialState'
import type { Node } from 'react'

type AvailableEmployeeType = {
  employee_id: number,
  treatment_duration_ids: Array<number>
}

const SelectEmployeeView = (): Node => {
  const isMobileView = isMobile(window)
  const [availableEmployees, setAvailableEmployees] = useState([])
  const allEmployees = useSelector(
    (state: StateType) => state.onlineBooking.all_employees
  )
  const corporateIds = useSelector((state: StateType) =>
    state.onlineBooking.selected_locations?.map((l) => l.id)
  )
  const selectedService = useSelector((state: StateType) =>
    state.onlineBooking.selected_service
  )
  const selectedLocations = useSelector((state: StateType) =>
    state.onlineBooking.selected_locations
  )
  const selectedEmployee = useSelector((state: StateType) =>
    state.onlineBooking.selected_employee
  )
  const onlineBooking = useSelector((state) => state.onlineBooking)

  const date_params = {
    corporates: corporateIds,
    treatments: onlineBooking.selected_service?.id,
    month: onlineBooking.calendar_state.selected_month,
    year: onlineBooking.calendar_state.selected_year,
  }

  const [availableDates, error, loading] = useFetch(
    ENDPOINT_URLS.AVAILABLE_DATES,
    'get',
    date_params,
    [
      onlineBooking.calendar_state.selected_month,
      onlineBooking.calendar_state.selected_year,
      selectedService,
      selectedLocations,
    ],
  )

  const filterEmployeesFromAvailableDates = (filterEmployees?: boolean) => {
    if (!availableDates || availableDates.length === 0) {
      return setAvailableEmployees([])
    }
    const availableEmployeesForCurrentDay = availableDates.find(day => day.date === onlineBooking.calendar_state.selected_date_string)
    if (availableEmployeesForCurrentDay) {
      let employees = []
      if (filterEmployees)
        employees = filterByEmployee(availableEmployeesForCurrentDay.employees)
      else
        employees = filterByServiceOrLocation(availableEmployeesForCurrentDay.employees)
      setAvailableEmployees(employees)
    }
  }

  useEffect(() => {
    filterEmployeesFromAvailableDates()
  }, [availableDates, onlineBooking.calendar_state.selected_date_string])

  useEffect(() => {
    filterEmployeesFromAvailableDates(true)
  }, [selectedEmployee])

  const filterByServiceOrLocation = (availableEmployeesForCurrentDay: Array<AvailableEmployeeType>) => {
    const employees = []
    availableEmployeesForCurrentDay.forEach(availableEmployee => {
      const employeesToFilter = selectedEmployee
        ? [selectedEmployee]
        : allEmployees

      const employee = employeesToFilter.find(emp => emp.id === availableEmployee.employee_id)
      if (employee) {
        employee.treatment_ids = availableEmployee.treatment_duration_ids
        employees.push(employee)
      }
    })

    return employees
  }

  const filterByEmployee = (availableEmployeesForCurrentDay: Array<AvailableEmployeeType>) => {
    let employees = []
    if (selectedEmployee) {
      const availableEmployee = availableEmployeesForCurrentDay.find(emp => emp.employee_id === selectedEmployee.id)
      if (availableEmployee) {
        const employee = allEmployees.find(emp => emp.id === availableEmployee.employee_id)
        if (employee) {
          employee.treatment_ids = availableEmployee.treatment_duration_ids
          employees.push(employee)
        }
      }
    } else {
      employees = filterByServiceOrLocation(availableEmployeesForCurrentDay)
    }

    return employees
  }

  if (error) {
    return <BookingErrorView error={error} />
  }

  if (loading) return <Spinner size='xxl'></Spinner>

  return isMobileView ? (
    <SelectEmployeeMobileLayout
      employees={availableEmployees} />
  ) : (
    <SelectEmployeeDesktopLayout
      employees={availableEmployees}
      availableDates={availableDates}
    />
  )
}

export default (withOnlineBookingModal(SelectEmployeeView): *)
