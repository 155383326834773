// @flow

import React, { Fragment } from 'react'
import Text, { Paragraph, Title } from 'components/text'
import Field from '../../../libs/fieldsets/components/Field'
import { PrimaryButton } from 'components/buttons'
import { Link } from 'react-router-dom'


type TextProps = {
  titleText: string,
  paragraphText: string,
  submitButtonText: string,
  cancelText: string,
}

type PropsType = {
  onCancel: Function,
  hideCancel: boolean,
  value: string,
  onChange: Function,
  onSubmit: Function,
  errors: Array<*>,
  textProps?: TextProps
}

export default function EnterPhoneNumberView(props: PropsType): React$Element<*> {
  const texts: TextProps = {
    titleText: 'Two factor authentication',
    paragraphText: 'Please enter your phone number. We will send verification code to it.',
    submitButtonText: 'Send',
    cancelText: 'Cancel'
  }

  if (props.textProps)
    Object.assign(texts, props.textProps)
  
  return <Fragment>

    <Title>{ texts.titleText }</Title>
    <Paragraph>{ texts.paragraphText }</Paragraph>

    <div style={{ margin: '2rem 0' }}>
      <Field
        id='phone_number'
        label='Phone number'
        type='string'
        value={ props.value }
        onChange={ props.onChange }
        required
        errors={ props.errors }
        translateLabel
      />
    </div>

    <PrimaryButton
      filled
      onClick={ props.onSubmit }
      style={{ width: 'fit-content', margin: 'auto', marginBottom: '2em' }}
    >
      { texts.submitButtonText }
    </PrimaryButton>

    { !props.hideCancel && <Link to="#" onClick={ props.onCancel }>
      <Text>{ texts.cancelText }</Text>
    </Link> }
  </Fragment>
}
