// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'
import endpoints from './endpoints'


const LoginForm = load(() =>
  import('../views/Login/LoginForm'))

const RequestPasswordResetForm = load(() =>
  import('../views/Login/RequestPasswordResetForm'))

const InvalidPasswordResetForm = load(() =>
  import('../views/Login/InvalidPasswordResetLink'))

const ResetPasswordForm = load(() =>
  import('../views/Login/ResetPasswordForm'))

const RegisterClientForm = load(() =>
  import('../views/Register/RegisterClientForm'))

const SecureLoginRedirect = load(() =>
  import('../views/Login/SecureLoginRedirect'))

export const descriptors = {
  login:                                               '/',
  register:                                            '/register/',
  requestPasswordReset:                                '/password-reset/',
  invalidPasswordResetLink:                            '/password-reset/invalid-link',
  resetPassword: (uid: string = '', token: string ) => `/set-password/${uid}/${token}/`,
  secureLoginRedirect:                                 '/secure-login-redirect/'
}


export default <Switch>
  <Route exact path={ descriptors.invalidPasswordResetLink } component={ InvalidPasswordResetForm } />
  <Route path={ descriptors.register } component={ RegisterClientForm }/>
  { endpoints.preLogin.map(endpoint => endpoint) }
  <Route exact path={ descriptors.requestPasswordReset } component={ RequestPasswordResetForm } />
  <Route path={ descriptors.resetPassword(':uid', ':token') } component={ ResetPasswordForm } />
  <Route path={ descriptors.secureLoginRedirect } component={ SecureLoginRedirect } />
  <Route path={ descriptors.login } component={ LoginForm } />
  <RouteNotFound />
</Switch>
