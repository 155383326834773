// @flow
import React from 'react'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Card } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'

import { increaseStep, selectService } from 'actions/onlineBooking'

import BookingErrorView from './BookingErrorView'
import withOnlineBookingModal from '../extra/OnlineBookingModal'

import type { Node } from 'react'
import type { Service } from 'reducers/onlineBooking'

const SelectServiceView = (): Node => {
  const listOfServices = useSelector(
    (state) => state.onlineBooking.all_services
  )

  if (!listOfServices || listOfServices.length === 0)
    return <BookingErrorView errorString='No services were found' />

  return (
    <div className='stack-wrapper'>
      <Stack>
        <ServiceList listOfServices={listOfServices} />
      </Stack>
    </div>
  )
}

const ServiceList = ({
  listOfServices,
}: {
  listOfServices: Service[],
}): Node => {
  return (
    <>
      {listOfServices &&
        listOfServices.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
    </>
  )
}

const ServiceItem = ({ service }: { service: Service }): Node => {
  const dispatch = useDispatch()
  const handleSelection = (e: SyntheticEvent<*>, service: Service) => {
    dispatch(selectService(service))
    dispatch(increaseStep())
  }

  return (
    <Card>
      <div className='card-layout'>
        <span className='card-label'>{service.name}</span>
        <Button
          onClick={(e) => handleSelection(e, service)}
          size='m'
          variant='primary'
        >
          Choose
        </Button>
      </div>
    </Card>
  )
}

export default (withOnlineBookingModal(SelectServiceView): *)
