/**
 * @module DropdownButton
 * @flow
 */
import React, { Component } from 'react'
import self from 'autobind-decorator'
import getClassNames from 'classnames'

import Icons from 'components/icons'
import { SecondaryButton } from 'components/buttons'
import Text from 'components/text'


const DIRECTION_MAP = {
  down: 'down',
  up:   'up',
}

export type ItemType = {
  label: string,
  onClick: Function,
  disabled: boolean,
  index: number,
}

type PropsType = {
  items: Array<ItemType>,
  small?: boolean,
  disabled?: boolean,
  direction?: 'up' | 'down',
}

type StateType = {
  open: boolean,
}


export default class DropdownButton extends Component<PropsType, StateType> {

  static defaultProps = {
    direction: DIRECTION_MAP.down,
  }

  state = {
    open: false,
  }

  componentDidUpdate () {
    switch (this.state.open) {
      case true:
        this.addDocumentEventListener()
        break
      case false:
        this.removeDocumentEventListener()
        break
    }
  }

  componentWillUnmount () {
    this.removeDocumentEventListener()
  }

  @self
  addDocumentEventListener () {
    document.addEventListener('click', this.close)
  }

  @self
  removeDocumentEventListener () {
    document.removeEventListener('click', this.close)
  }

  @self
  close () {
    this.setState({ open: false })
  }

  @self
  toggle (e: SyntheticEvent<*>) {
    e.stopPropagation()
    this.setState({ open: !this.state.open })
  }

  render () {
    return <div className='dropdown-button margin-top'>
      <SecondaryButton
        icon={ Icons.Add }
        onClick={ this.toggle }
        small={ this.props.small } >
        Add
      </SecondaryButton>
      { this.renderMenu() }
    </div>
  }

  renderMenu () {
    const classNames = getClassNames(
      'list bordered',
      this.props.direction,
    )
    return this.state.open
      ? <ul className={ classNames }>
        { this.props.items.map(this.renderItem )}
      </ul>
      : null
  }

  @self
  renderItem (item: ItemType, key: number) {
    const onClick = (e: SyntheticEvent<*>) => {
      if (item.disabled) return
      item.onClick()
      this.toggle(e)
    }
    const className = `item${ item.disabled ? ' disabled' : '' }`
    return <li key={ key } onClick={ onClick } className={ className }>
      <Text>{ item.label }</Text>
    </li>
  }

}
