// @flow
import React, { Fragment } from 'react'
// $FlowIgnore
import { useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { Button, Icon, Spinner } from '@nordhealth/react'

import { useFetch } from '../utils/useFetchHook'
import { getDayTransition, isPastDate } from 'utils/dateUtils'
import isMobile from 'utils/isMobileHook'

import { ENDPOINT_URLS } from '..'

import AppointmentTimeSlots from '../extra/AppointmentTimeSlots'
import DateQuickSelect from '../extra/DateQuickSelect'
import EmptyState from '../extra/EmptyState'

import BookingErrorView from './BookingErrorView'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'
import type {
  Employee,
  OnlineBookingState as OnlineBooking,
  ServiceType,
  LocationWithAvaialbleTimes,
} from 'reducers/onlineBooking'

type Props = {
  employee: Employee,
  setShowDrawer?: Function,
}

const EMPTY_STATE_TITLE = 'There are no bookings available for this day'
const EMPTY_STATE_SUBTITLE = 'Please try selecting another day'
const EMPTY_STATE_PAST_DATE_TITLE = 'Past date is selected'
const EMPTY_STATE_PAST_DATE_SUBTITLE = 'Please selected recent date'

const SelectTimeslotView = (props: Props): Node => {
  const isMobileView = isMobile(window)
  const bookingState = useSelector((state: StateType) => state.onlineBooking)
  const params = getRequestParams(bookingState, props.employee)
  const [timeslots, error, loading] = useFetch(
    ENDPOINT_URLS.TIME_SLOTS,
    'get',
    params,
    [params.start_date]
  )

  if (error) {
    return <BookingErrorView error={error.data} />
  }

  const renderAppointmentTimeSlots = () => {
    if (loading) return <Spinner size='xxl'></Spinner>
    else
      return <AppointmentTimeSlots
        timeslots={timeslots}
        isMobile={isMobileView}
        employee={props.employee}
      />
  }

  const className = !isMobileView ? 'appointment-times-drawer' : ''

  if (
    !loading &&
    (isPastDate(bookingState.available_date?.date) ||
    !timeslotsNotEmpty(timeslots))
  ) {
    const title = isPastDate(bookingState.available_date?.date)
      ? EMPTY_STATE_PAST_DATE_TITLE
      : EMPTY_STATE_TITLE
    const subtitle = isPastDate(bookingState.available_date?.date)
      ? EMPTY_STATE_PAST_DATE_SUBTITLE
      : EMPTY_STATE_SUBTITLE

    return (
      <div className={className}>
        {!isMobileView && (
          <Fragment>
            <TimeSlotHeader
              employee={props.employee}
              setShowDrawer={props.setShowDrawer}
            />
            <Divider className='appointment-times-divider' />
          </Fragment>
        )}
        <DateQuickSelect updateSelectedDate={true} />
        <EmptyState title={title} additionalInfo={subtitle} />
      </div>
    )
  }

  return (
    <div className={className}>
      {!isMobileView && (
        <Fragment>
          <TimeSlotHeader
            employee={props.employee}
            setShowDrawer={props.setShowDrawer}
          />
          <Divider className='appointment-times-divider' />
        </Fragment>
      )}
      {renderAppointmentTimeSlots()}
    </div>
  )
}

const timeslotsNotEmpty = (timeslots: ServiceType[]): boolean => {
  let result = false
  if (timeslots) {
    timeslots.forEach((slot: ServiceType) => {
      slot.location_specific_times.forEach(
        (time: LocationWithAvaialbleTimes) => {
          if (time.available_times.length > 0) result = true
        }
      )
    })
  }
  return result
}

const getRequestParams = (bookingState: OnlineBooking, employee: Employee) => {
  const selectedEmployee = employee || bookingState.selected_employee
  const employee_id = selectedEmployee.id
  const treatment_id = bookingState.selected_service?.id // In Diarium 'parent element' does not include time intervals
  const treatment_ids = bookingState.selected_employee?.treatment_ids // This is child element - includes time interval
  let corporate_ids = []
  bookingState.selected_locations?.forEach((l) => {
    if (selectedEmployee.corporates.includes(l.id)) {
      corporate_ids.push(l.id)
    }
  })
  const start_date = bookingState.available_date?.date
  // $FlowIgnore
  const end_date = getDayTransition(start_date, 'NEXT')

  return {
    treatment_id,
    treatment_ids,
    corporate_ids,
    employee_id,
    start_date,
    end_date,
  }
}

type TimeSlotHeaderProps = {
  employee: Employee,
  setShowDrawer: Function,
}

const TimeSlotHeader = ({ employee, setShowDrawer }: TimeSlotHeaderProps) => {
  const handleClick = () => {
    setShowDrawer()
  }

  const profession = employee.profession || 'Profession was not specified'

  return (
    <div className='timeslot-header'>
      <div className='timeslot-header-content'>
        <span className='n-font-weight-heading'>{employee.name}</span>
        <span className='n-font-size-m'>{profession}</span>
      </div>
      <div className='timeslot-header-actions'>
        <Button slot='action' onClick={handleClick}>
          <Icon name='interface-close' size='s' />
        </Button>
      </div>
    </div>
  )
}

export default SelectTimeslotView
