import self from 'autobind-decorator'
import React from 'react'
import InputField from './InputField'
import RTEditor from '../RTE/RichTextEditor'


export default class RichTextField extends InputField {
  @self
  clear () {
    this.rte.clear()
    super.clear()
  }

  @self
  onChange ({ value }) {
    let text = this.rte.toString()
    if (!text || typeof text === 'string' && text.trim().length < 2) value = null
    this.setState({ value })
  }

  @self
  update (value) {
    this.rte.assignStateFromHTML(value)
    this.rte.flattenEditorContent().then(value => this.onChange({ value }))
  }

  renderFormControl () {
    const updateReference = ref => ref && (this.rte = ref)
    return <RTEditor ref={updateReference} onChange={this.onChange}  id={ this.props.name }/>
  }
}
