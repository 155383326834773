// @flow
import type { Action } from '../reducers'

// Tenant user list actions
export const UPDATE_TAGS = 'UPDATE_USER_TAGS'
export const UPDATE_SEARCH = 'UPDATE_USER_SEARCH'
export const UPDATE_GROUPS = 'UPDATE_GROUP_FILTER'
export const SET_OWN_CLIENTS = 'SET_OWN_CLIENTS'
export const SET_DISABLED = 'SET_DISABLED'
export const SET_UNVERIFIED = 'SET_UNVERIFIED'

export const updateTags = (value: Array<number>): Action => ({
  type: UPDATE_TAGS,
  payload: value
})

export const updateUserSearch = (value: string): Action => ({
  type: UPDATE_SEARCH,
  payload: value ? value : null
})

export const updateUserGroupFilter = (value: string): Action => ({
  type: UPDATE_GROUPS,
  payload: value ? value : null
})

export const clearUserSearch = (): Action => ({
  type: UPDATE_SEARCH,
  payload: null
})

export const setOwnClients = (own_clients: boolean): Action => ({
  type: SET_OWN_CLIENTS,
  payload: own_clients
})

export const setDisabled = (disabled: boolean): Action => ({
  type: SET_DISABLED,
  payload: disabled
})

export const setUnverified = (unverified: boolean): Action => ({
  type: SET_UNVERIFIED,
  payload: unverified
})

// Public user list actions
export const UPDATE_NAME_SEARCH = 'UPDATE_NAME_SEARCH'
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH'

export const updateNameSearch = (value: string): Action => ({
  type: UPDATE_NAME_SEARCH,
  payload: value
})

export const updateEmailSearch = (value: string): Action => ({
  type: UPDATE_EMAIL_SEARCH,
  payload: value
})