// @flow
import { parse as URL } from 'url'
import { parse as parseQuery } from 'querystring'
import merge from 'merge'

import { SuccessResponse, ErrorResponse } from './responses'

import { isGetMethod } from './constants'
import type { MethodType } from './constants'

const primitives = [ 'String', 'Number', 'Function', 'Object', 'Array', 'Boolean', 'Map', 'Set' ]


const getObjectTypeName = value =>
  toString.call(value).slice(8, -1)

const isObjectOfType = type => value =>
  getObjectTypeName(value) === type

const isMap    = isObjectOfType('Map')
const isSet    = isObjectOfType('Set')
const isArray  = isObjectOfType('Array')
const isObject = isObjectOfType('Object')
const isPlainObject = (value: any) => {
  // From: https://github.com/sindresorhus/is-plain-obj/blob/master/index.js

let prototype
  return isObject(value) &&
      (prototype = Object.getPrototypeOf(value), prototype === null ||
      prototype === Object.getPrototypeOf({}))
}

const serializeMap = (data: Map<string, *>) => {
  const result = {}
  for (let [ key, value ] of data.entries())
    Object.assign(result, { [key]: serialize(value) })
  return result
}

const serializeSet = (data: Set<*>) =>
  [ ...data ].map(serialize)


function serialize (payload: * = {}): Object | Array<*> {

  // flow-ignore
  if (isMap(payload))
    return serializeMap(payload)

  // flow-ignore
  else if (isSet(payload))
    return serializeSet(payload)

  // flow-ignore
  if (isPlainObject(payload))
    for (const key in payload)
      Object.assign(payload, { [key]: serialize(payload[key]) })

  return payload
}


function logRequest ({ method, path, url, query, data, payload }) {
  /* eslint-disable no-console */
  console.groupCollapsed("API Request", method, "•··", path)
  console.log('%cOriginal url:           ' + url, 'font-style: italic; color: #888')
  console.log('From URL search params:', query)
  console.log('Data payload:          ',  data)

  const keys = Object.keys(payload)
  const tabledata = keys.reduce((d, key) => ({
    ...d,
    [key]: {
      "From URL Search params": query[key] || null,
      "From function call parameters": data[key] || null,
      "Accepted value": payload[key],
    }
  }), {})

  console.table(tabledata)
  console.groupEnd()
  /* eslint-enable no-console */
}


// eslint-disable-next-line max-statements
export default function resolveRequestArguments (method: MethodType, url: string, data: * = {}) {

  window.url = require('url')

  let query   = {}
  let payload = {}
  const path  = getPathname(url)

  if (isGetMethod(method)) {
    query = resolveQueryParams(url)
    Object.assign(payload, toQueryParams(query, data))
  }
  else
    payload = formatRequestData(data)

  if (__DEV__)
    logRequest({ url, path, method, query, data, payload })

  return [ path, payload, { method }]
}


const getSearchParams = (url: string) =>
  new URLSearchParams(URL(url).search || '')


const resolveQueryParams = (url: string): Object =>
  parseQuery(getSearchParams(url).toString())


const getPathname = (url: string): string =>
  URL(url).pathname.replace(/\b$|^\/*|\/+/g, '/')


export const formatErrorResponse = (response: api.ResponseType<*>) =>
  new ErrorResponse(response)


export const formatSuccessResponse = (response: api.ResponseType<*>) =>
  new SuccessResponse(response)


// eslint-disable-next-line complexity
export function formatRequestData (payload: * = {}): Object | Array<*> | FormData {

  // Pop any keys with a value `null`
  for (let key in payload)
    if (payload[key] === null)
      delete payload[key]

  // TODO: Clean
  // check if the payload has any files
  if (hasFiles(payload)){
    return getFilesPayload(payload)}
  return payload
}


const hasFiles = data => Object
  .values(data)
  .filter(value => value && value.constructor)
  .find(value => {
    const isArrayOfFiles = value instanceof Array && value[0] instanceof File
    const isFile = value instanceof File
    const isSetOfFiles = Array.from(value)[0] instanceof File
    const containsFiles = isArrayOfFiles || isFile || isSetOfFiles
    if (!containsFiles && is.object(value))
      return hasFiles(value)
    return containsFiles
  })


function getFilesPayload (payload) {
  const files = new FormData()

  // eslint-disable-next-line complexity, max-statements
  const append = (key, value, parent?: Object | null) => {

    if (!value)
      return
    if (value instanceof Map)
      throw new Error(`Reducing a Map to a primitive has not been implemented (in \`api/formatter - getFilesPayload\`)`)
    if (value instanceof Set)
      value = Array.from(value)

    // Arrays of files
    if (value instanceof Array && value.length > 0 && value[0] instanceof File) {
      for (let n in value) {
        append(`${key}`, value[n], parent)
      }
      payload[key] = undefined
    }
    // Files
    else if (value instanceof File) {
      files.append(key, value)
      if (parent) {
        parent[key] = undefined
      } else {
        payload[key] = undefined
      }
    } else if (is.object(value)) {
      for (let subKey in value)
        append(subKey, value[subKey], value)
    }
  }

  // parse all the file data into a separate object to be sent with PATCH after the POST/PUT
  for (let key in payload)
    append(key, payload[key], payload)
  return { payload, files }
}

function toQueryParams (...payload: Array<*>) {
  return merge.recursive(...payload)
}


// function isIterable (subject) {
//   // checks for null and undefined
//   if (subject === null)
//     return false
//   return typeof subject[Symbol.iterator] === 'function'
// }
