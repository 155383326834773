/**
 * @flow
 * @class EditorView
 */

import self from 'autobind-decorator'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Checkbox } from 'react-toggle-components'
import Text, { Caption, Subtle } from 'components/text'
import { SuccessIcon, CancelIcon, RemoveIcon } from 'components/icons'
import HelpIcon from 'components/HelpIcon'
import ErrorList from 'react-drf/ErrorList'

import DynamicHeightInput from './DynamicHeightInput'
import ChoicesEditor from './ChoicesEditor'
import FieldOptions from './FieldOptionsEditor'
import IndicatorField from '../views/ExpressionFieldView'
import getComponentType, { TextField, NumericField, ELEMENT_MODEL_NAMES } from '../fields'
import { ButtonWithTooltip } from './WithTooltip'
import { mapFieldToJSON, elementHasOptions } from '../serializer'
import RTEditor from 'components/wysiwyg'

import type { BlockType } from '../editor/Block'
import { __ } from 'utils/gettext'


type FieldEditorState = BlockType

type FieldEditorProps = {
  onUpdate: Function,
  onSubmit: Function,
  onCancel: Function,
  onRemove: Function,
  options: Object | null,
  errors: Array<Object> | null,
  block: BlockType,
}

type PropertyProps = {
  value: *,
  label?: string,
  onChange: Function,
  component?: *,
  children?: *,
  className?: string,
  notranslate?: boolean,
  helpText?: string,
  wrapperComponent?: string,
}

const DECIMALS_DEFAULT = 2


export default class FieldEditor extends Component<FieldEditorProps, FieldEditorState> {

  static get FIELD_PROPERTY_LABELS (): Object {
    return {
      TEXT:               'Header',
      REQUIRED:           'Required',
      INSTRUCTION_TEXT:   'Instruction text',
      FORMULA:            'Formula',
      HORIZONTAL_CHOICES: 'Display choices horizontally',
      DECIMALS:           'Decimals',
    }
  }

  state: FieldEditorState = {
    id: null,
    indicator_identifier: null,
    text: '',
    description: '',
    type: null,
    model: null,
    choices: [],
    options: {},
    required: false,
    expression: null,
    instruction_text: '',
    decimals: this.decimalDefault
  }

  get decimalDefault (): number | void {
    if (this.props.block.model !== ELEMENT_MODEL_NAMES.COMPUTED)
      return undefined
    return this.props.block.decimals || DECIMALS_DEFAULT
  }

  generateUpdater (fieldName: string = '', map: Function = value => value) {
    return (event: Event) => {
      let value = map(event)
      if (value === undefined) return
      let state = { [fieldName]: value }

      this.setState(state, () => this.props.onUpdate({ ...this.state }))
    }
  }

  componentDidMount () {
    this.setState(mapFieldToJSON(this.props.block, false, true))
  }

  @self save () {
    let block = this.props.block.merge(this.state)
    this.props.onSubmit(block)
  }

  @self close () {
    this.props.onCancel()
  }

  @self remove () {
    this.props.onRemove()
  }

  getFieldLabel () {
    let field = getComponentType(this.state)
    return field && field.displayName
  }

  render () {
    return <Fragment>
      <nav className='text toolbar actions'>

        <ButtonWithTooltip
          text={ __('Done') }
          className='success filled'
          onClick={ this.save }
          icon={ SuccessIcon } />

        <ButtonWithTooltip
          text={ __('Cancel') }
          onClick={ this.close }
          icon={ CancelIcon } />

        <ButtonWithTooltip
          text={ __('Delete') }
          className='error'
          onClick={ this.remove }
          icon={ RemoveIcon } />

      </nav>

      <div className='row'>
        <dl className='field-properties'>
          { this.renderField() }
        </dl>
      </div>

    </Fragment>
  }

  //eslint-disable-next-line complexity
  renderField () {

    const PropertyErrors = (props) =>
      this.renderErrors(props.name)
    const onContentChanged =
      this.generateUpdater('text')

    const hasChoices = elementHasOptions(this.props.block)
    switch (this.props.block.model) {

      case ELEMENT_MODEL_NAMES.FIELD:
        return <Fragment>

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.TEXT }
            value={ this.state.text }
            onChange={ onContentChanged } />
          <PropertyErrors name='text' />

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.INSTRUCTION_TEXT }
            value={ this.state.instruction_text }
            onChange={ this.generateUpdater('instruction_text') }/>
          <PropertyErrors name='instruction_text' />

          <Property
            value={ this.state.required }
            component={ Checkbox }
            onChange={ this.generateUpdater('required', () => !this.state.required) }>
            <Text>{ FieldEditor.FIELD_PROPERTY_LABELS.REQUIRED }</Text>
          </Property>
          <PropertyErrors name='required' />

          { this.renderOptions() }

          { hasChoices &&
            <aside className='field-property'>
              <dd>
                <ChoicesEditor
                  value={[ ...this.state.choices ]}
                  onUpdate={ choices => choices && this.setState({ choices }) }
                  onChange={ this.generateUpdater('choices', () => this.state.choices) } />
              </dd>
              <PropertyErrors name='choices' />
            </aside>
          }

          <sub><Subtle>Formula identifier: <span>{  this.state.indicator_identifier }</span></Subtle></sub>

        </Fragment>

      case ELEMENT_MODEL_NAMES.COMPUTED:
        return <Fragment>

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.TEXT }
            value={ this.state.text }
            onChange={ onContentChanged } />
          <PropertyErrors name='name' />

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.INSTRUCTION_TEXT }
            value={ this.state.instruction_text }
            onChange={ this.generateUpdater('instruction_text') } />
          <PropertyErrors name='description' />

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.DECIMALS }
            value={ this.state.decimals }
            component={ NumericField }
            onChange={ this.generateUpdater('decimals') } />
          <PropertyErrors name='decimals' />

          <Property
            label={ FieldEditor.FIELD_PROPERTY_LABELS.FORMULA }
            value={ this.props.block.expression }
            component={ IndicatorField }
            onChange={ this.generateUpdater('expression') }
            helpText={ identicatorFieldInfo() } />
          <PropertyErrors name='expression' />
        </Fragment>

      case 'HelpText':
        return this.state.model
          ? <Fragment>
            <Property
              label={ this.getFieldLabel() }
              value={ this.state.text }
              component={ RTEditor }
              onChange={ onContentChanged }
              wrapperComponent='div'/>
            <PropertyErrors name='text' />
          </Fragment>
          : null

      default:
        return <Fragment>
          <Property
            label={ this.getFieldLabel() }
            value={ this.state.text }
            component={ DynamicHeightInput }
            onChange={ onContentChanged } />
          <PropertyErrors name='text' />
        </Fragment>
    }
  }

  renderErrors (fieldName: string) {
    if (!this.props.errors)
      return null
    return <ErrorList errors={ this.props.errors[fieldName] } />
  }

  renderOptions () {
    if (!this.props.options || !Object.keys(this.props.options).length)
      return null

    const handleChange = this.generateUpdater('options', (option) => ({
      ...this.state.options,
      [ option.key ]: option.value
    }))

    return <FieldOptions
      values={ this.state.options }
      options={ this.props.options }
      onChange={ handleChange }
      errors={ this.props.errors }
    />
  }

}


export const Property = (props: PropertyProps) => {

  const Wrapper = props.wrapperComponent || 'label'

  const {
    component: FieldType,
    label,
    value,
    children,
    onChange,
    className,
    ...properties
  } = props

  return <Wrapper className={ classNames('field-property', className) }>

    { label && <dt>
      <Caption notranslate={ props.notranslate }>
        { label }
        <HelpIcon text={ props.helpText } />
      </Caption>
    </dt> }

    <dd>
      <FieldType { ...properties } value={ value } onChange={ onChange } />
      { children }
      { !label && <HelpIcon text={ props.helpText } isBoolean /> }
    </dd>
  </Wrapper>
}

Property.defaultProps = {
  component: TextField
}

const identicatorFieldInfo =
  () => __('Only numeric, choice and multiple choice fields that are marked as required can be selected for this field.' +
           'To create a formula, use @-sign in the field. Example (F1 + F2 + F3) / 3')
