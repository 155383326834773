import React from 'react'

import Field from './Field'


export default class ChoiceField extends Field {

  static EMPTY_CHOICE = {
    value: undefined,
    display_name: '-',
  }

  constructor (props) {
    super(props)
    this.state.choices = this.getInitialChoices()
  }

  getInitialChoices () {
    let { choices } = this.props
    if (choices.length === 0)
      choices = [ ChoiceField.EMPTY_CHOICE ]
    return choices
  }

  getInitialValue () {
    if (typeof this.props.initial !== 'undefined') return this.props.initial
    let options = this.getInitialChoices()
    if (options.length) return options[0].value
    throw new TypeError(`Could not resolve initial value for a ChoiceField.`)
  }

  hasValue (value) {
    return this.state.choices.find(option => option.value === value) ? true : false
  }

  get optionsCount () {
    return this.state.choices.length
  }

  updateOptions (choices) {
    const hasValue = val => choices.find(option => option.value === val) ? true : false
    let value = this.state.value

    if (!hasValue(value)) value = choices.length ? choices[0].value : ChoiceField.EMPTY_CHOICE.value
    this.setState({ choices, value })
  }

  renderFormControl () {
    const Options = ({ choices }) =>
      choices.map(choice =>
        <option key={choice.value} value={choice.value}>
          {choice.display_name}
        </option>
      )

    return (
      <select
        name={this.name}
        value={this.value}
        onChange={this.onChange}
        disabled={this.disabled}>

        <Options choices={this.state.choices} />

      </select>
    )
  }
}
