// @flow
import type { Action } from '.'
import { UPDATE_SEARCH } from 'actions/tags'

export type TagsState = {
  search: string | null,
}

export const initialValue: TagsState = {
  search: null,
}


export default function tagsReducer (state: TagsState, action: Action) {
  const { payload } = action
  switch (action.type) {

    case UPDATE_SEARCH:
      return { ...state, search: payload }

    default:
      return state || initialValue

  }
}
