/**
 * @module FetchURLField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import React, { PureComponent } from 'react'
import { create } from 'apisauce'

import self from 'autobind-decorator'

import { SecondaryButton } from 'components/buttons'
import { Badge, Loader } from 'components/generic'
import Icons from 'components/icons'
import { __ } from 'utils/gettext'


type ValueType = string

type FieldProps = {
  value: ValueType,
  onChange: Function,
  disabled?: boolean,
  id?: string,
  url: string,
  method?: 'post' | 'get',
  onSuccess?: Function,
  onError?: Function,
  fetchOnMount?: boolean,
  nolabel?: boolean,
  label?: string,
}

type FieldState = {
  loading: boolean
}


export default class FetchURLField extends PureComponent<FieldProps, FieldState> {

  state = {
    loading: false,
  }

  api = create({
    baseURL: this.props.url
  })

  static defaultProps = {
    value: undefined,
    method: 'post',
  }

  componentDidMount () {
    if (!is.string(this.props.url))
      throw new TypeError(
        `The "url" property of FetchURLField is required and must be of type "string" got type "${typeof this.props.url}"`)

    if (this.props.fetchOnMount)
      this.getLink()
  }

  @self
  async getLink () {
    this.setState({ loading: true })
    const method = this.props.method || 'post'
    const response = await this.api[method]('/')
    if (!response.problem)
      this.onSuccess(response.data)
    else
      this.onError(response)
  }

  @self
  onSuccess (data: *) {
    if (this.props.onSuccess)
      this.props.onSuccess(data, this.api, value => {
        this.onChange(value)
      })
    else
      this.onChange(data)
    this.setState({ loading: false })
  }

  @self
  onError (response: *) {
    if (this.props.onError)
      this.props.onError(response)
    else
      // eslint-disable-next-line no-console
      console.error('FetchURLField - error:', response.data)
    this.setState({ loading: false })
  }

  @self
  onClear () {
    this.api.setBaseURL(this.props.url)
    this.onChange()
  }

  @self
  onChange (value?: string) {
    this.props.onChange && this.props.onChange(value)
  }

  render () {
    if (this.state.loading)
      return <Loader />

    const value = Array.isArray(this.props.value) ? this.props.value[0] : this.props.value

    return <div className='url-field'>
      { this.props.value
        ? <div className='value-container'>
          { this.props.nolabel && <label className='label field.label'>{ this.props.label }</label>}
          <Badge onRemove={ this.onClear }>
            <a href={ value }
              target='_blank'
              rel='noopener noreferrer' >
              { value }
            </a>
            <Icons.OpenInNew size={ 12 } ariaLabel={ __('Open link in a new tab') } />
          </Badge>
        </div>
        : !this.props.fetchOnMount && <div className='button-container'>
          { this.props.nolabel && <label className='label field.label'>{ this.props.label }</label>}
          <SecondaryButton small
            onClick={ this.getLink }
            disabled={ this.props.disabled }>
            Add video conference
          </SecondaryButton>
        </div> }
    </div>
  }
}
