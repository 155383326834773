// @flow
import type { Map, Record } from 'immutable'
import type { Action } from '.'
import type { Participant } from '../actions/communication'
import {
  ADD_USER,
  ADD_MESSAGE,
  ADD_MESSAGES,
  SET_LIST_FILTER,
  SET_PARTICIPANT_FILTER,
  CLEAR_LIST_FILTERS,
  REMOVE_LIST_FILTER,
  SET_THREAD,
  SET_ATTACHMENT_FIELD,
  SET_ATTACHMENT_FIELDS,
  REMOVE_ATTACHMENT_FIELD,
  ENABLE_ATTACHMENT_FIELD,
  DISABLE_ATTACHMENT_FIELD,
} from '../actions/communication'
import { LOGOUT } from 'actions/user'

import type User from 'models/User'
import type Message from 'models/Message'
import type { AttachmentFields } from '../actions/communication'

type Filters = {
  participants: Participant
}

export type CommunicationState = {
  users: Array<User>,
  messages: Array<Message>,
  filters: Filters,
  thread: { subject: string | null, participants: Array<Object> | null } | null,
  attachmentFields: AttachmentFields,
}

// eslint-disable-next-line
export default function communicationReducer (state: CommunicationState, action: Action) {

  const update = (updates) =>
    Object.assign({}, state, updates)

  const type = (actionType) =>
    action.type === actionType

  if (type(SET_PARTICIPANT_FILTER)){
    return {...state, filters: {...state.filters, participants: action.payload}}
  }
  // TODO: Refactor this
  if (type(REMOVE_LIST_FILTER)) {
    let prop = action.payload
    if (!prop)
      throw new TypeError(`Filter property is not defined`)
    return update({ filters: state.filters.delete(prop) })
  }

  if (type(CLEAR_LIST_FILTERS))
    return update({ filters: {...state.filters, participants: {} }})


  if (type(ADD_USER)) {
    let user = action.payload
    if (!user.id)
      throw new TypeError(`User id is not defined`)
    return update({ users: [ ...state.users, user ]})
  }

  if (type(ADD_MESSAGE)) {
    let message = action.payload

    // if (!message.type)
    //   throw new TypeError(`Message type is not defined.`)
    return update({ messages: [ ...state.messages, message ]})
  }

  if (type(ADD_MESSAGES)) {
    const messages = addMessages(state.messages, action.payload)
    return update({ messages })
  }

  if (type(SET_THREAD)) {
    if (!action.payload)
      return update({ thread: { participants: []}})
    return update({ thread: action.payload })
  }

  if (type(LOGOUT)) {
    return resetState(state)
  }

  if (type(SET_ATTACHMENT_FIELDS)) {
    return update({ attachmentFields: action.payload })
  }

  if (type(SET_ATTACHMENT_FIELD)) {
    const attachmentFields = state.attachmentFields
    attachmentFields.set(action.payload.field, action.payload.item)
    return update({ attachmentFields })
  }

  if (type(REMOVE_ATTACHMENT_FIELD)) {
    const attachmentFields = state.attachmentFields
    if (attachmentFields.has(action.payload)) {
      attachmentFields.delete(action.payload)
      return update({ attachmentFields })
    }
    return state || {}
  }

  if (type(ENABLE_ATTACHMENT_FIELD)) {
    const attachmentFields = state.attachmentFields
    const item = attachmentFields.get(action.payload)
    if (item) {
      item.disabled = false
      attachmentFields.set(action.payload, item)
      return update({ attachmentFields })
    }
    return state || {}
  }

  if (type(DISABLE_ATTACHMENT_FIELD)) {
    const attachmentFields = state.attachmentFields
    const item = attachmentFields.get(action.payload)
    if (item) {
      item.disabled = true
      attachmentFields.set(action.payload, item)
      return update({ attachmentFields })
    }
    return state || {}
  }

  return state || {}
}

function resetState (state: CommunicationState) {
  return {
    messages: [],
    filters: { participants: {}},
    users: [],
    thread: { participants: []},
    attachmentFields: state.attachmentFields
  }
}

function addMessages (messages, newMessages) {

  for (let message of newMessages) {

    let entry = Object
      .entries(messages)
      .find( ([ , item ]) => item.id === message.id )

    // If the message exists, update the existing.
    if (entry) {
      let [ index, item ] = entry
      message = Object.assign({}, item, message)
      messages.splice(Number(index), 1, message)
    }

    // Otherwise, append the message to the messages list
    else
      messages.push(message)

  }
  return messages
}
