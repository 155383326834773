/**
 * @flow
 * @class MultipleChoiceField
 */

import classNames from 'classnames'
import self from 'autobind-decorator'
import React from 'react'
import Field  from './Field'
import { Checkbox } from '../../react-toggle-components'

import { List } from 'immutable'


type OptionValueType = string | number | null
type OptionType = {
  value: OptionValueType,
  label: string,
  score?: number,
}

type ChoicesType = Array<OptionType>

type ValueType = Set<OptionType | OptionValueType>

type MultipleChoiceFieldProps = {
  name: string,
  value?: ValueType,
  choices?: Array<*>,
}

type StateType = {
  value: Set<number>,
  choices: Array<*>
}


export default class MultipleChoiceField extends Field<OptionType> {

  state = {
    value: new Set(),
    choices: [],
  }

  static defaultValue: ValueType | * = new Set()

  updateState (props: MultipleChoiceFieldProps) {
    let updates = {}
    let value
    let initial
    if (props.value)
      updates.value = toSet(props.value)
    else if (props.initial)
      updates.value = toSet(props.initial)
    else {
      initial = this.getInitialValue('multipleChoice')
      if (initial)
        // The initial is an object, so convert it into a Set
        value = toSet(Object.values(initial))
      if (value)
        updates.value = value
    }
    if ('choices' in props)
      updates.choices = clean(props.choices)

    this.setState(updates)
  }

  @self
  update (choice: OptionValueType, checked: boolean) {
    let value = new Set(this.state.value)
    if (!checked)
      value.delete(choice)
    else
      value.add(choice)
    this.setState({ value }, () =>
      this.props.onChange && this.props.onChange(value, this.props.name, 'multipleChoice'))
  }

  renderFormControl (): React$Element<*> {
    const horizontal = this.getOptionValue('horizontal')
    const className  = classNames('options', 'multiple', { horizontal })

    return <ul className={ className }>
      { this.state.choices.map((choice) => {

        if (!this.props.required && (choice.value === null || choice.value === ''))
          return null

        const checked  = this.state.value.has(choice.value)
        const onChange = () => this.update(choice.value, !checked)

        return <li
          key={ choice.value }
          aria-checked={ checked }
          className='option focusable'
          tabIndex={ this.disabled ? undefined : 0 }
          onKeyPress={ onChange }
          onClick={ onChange }>
          <Checkbox
            type='checkbox'
            name={ 'choice-' + choice.value }
            checked={ checked }
            onChange={ onChange }
            disabled={ this.disabled }
          />
          <label htmlFor={ 'choice-' + choice.value } className='value'>{ choice.label }</label>
        </li>
      })}
    </ul>
  }
}

export function clean (list: Set<OptionType> | List<OptionType> | [] | ChoicesType): ChoicesType {
  if (typeof list.toArray === 'function')
    list = list.toArray()

  if (list instanceof Set)
    list = [ ...list ]

  if (!(list instanceof Array))
    return []

  if (typeof list.map !== 'function')
    throw new TypeError('Invalid choices')

  return list
    .map(mapListToChoice)
}

const toSet = (data) => (data.size || data.length)
  ? new Set(data)
  : new Set()

const mapListToChoice = (choice: OptionType | string, n: number): OptionType => {

  let value, label, score
  if (typeof choice === 'object' && choice.display_name) {
    value = choice.value
    label = choice.display_name
    score = choice.score
  } else if (typeof choice === 'object'){
    label = choice.value
    score = choice.score
  }
  else if (typeof choice === 'string')
    label = choice

  if ([ 'number', 'string' ].indexOf(typeof value) === -1)
    value = n

  return { value, label, score }
}
