/**
 * @flow
 * @namespace core.models.User
 * @summary   User model
 *
 * @description
 *    A description for user objects used in various
 *    sections of the application.
 */
import model from './decorator'


@model
class Profile {
  full_name: Object = { name: 'Full name' }
  last_name: Object = { name: 'Last name', required: true }
  first_name: Object = { name: 'First name' }
  show_terms: Object = { name: 'Show terms' }
  phone_number: Object = { name: 'Phone number' }
  terms_accepted: Object = { name: 'Terms accepted date' }
  primary_language: Object = { name: 'Language' }
  tags: Object = { name: 'Tag', required: false }
  social_security_number: Object = { name: 'Social security number' }
  clients: Object = { name: 'Clients for staff user', required: false }

  toString (): string | null {
    if (this.full_name)
      return this.full_name
    if (this.first_name || this.last_name)
      return [ this.first_name, this.last_name ].join(' ')
    return null
  }
}


@model
export default class User {

  id: Object = {
    name: 'id',
    required: true
  }

  email: Object = {
    name: 'Email address',
    required: false,
  }

  username: Object = {
    name: 'Username or email address',
    required: false,
  }

  status: Object = {
    name: 'Status',
    required: true,
  }

  profile: Object = {
    type: Profile,
    name: 'User profile',
    required: true,
  }

  is_staff: Object = {
    name: 'Is staff',
    required: false
  }

  group: Object = {
    name: 'Group',
    required: false
  }

  tags: Object = {
    name: 'Tags',
    required: false,
  }

  clients: Object = {
    name: 'Clients',
    required: false
  }

  active_subscription: Object = {
    name: 'Active subscription',
    required: false
  }

  get name (): string {
    return this.profile.toString() || ''
  }

  toString (): string {
    let name = this.name.trim()

    if (name)
      return name
    else
      return this.username
  }
}


@model
export class SimpleUser {

  id: Object = { name: 'id' }
  is_staff: Object = { name: 'Is staff' }
  profile: Object = { name: 'Profile' }
  status: Object = { name: 'Status' }

  get name (): string  {
    return this.profile.full_name
  }

  toString (): string {
    return this.name
  }
}
