// @flow
import React from 'react'
// $FlowIgnore
import { useSelector } from 'react-redux'

import BookingErrorView from './BookingErrorView'

import withOnlineBookingModal from '../extra/OnlineBookingModal'

import ConfirmBookingDesktopLayout from '../layouts/ConfirmBookingDesktopLayout'
import ConfirmBookingMobileLayout from '../layouts/ConfirmBookingMobileLayout'

import isMobile from 'utils/isMobileHook'
import { __ } from 'utils/gettext'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'
import type { OnlineBookingState as OnlineBooking } from '../../../reducers/onlineBooking'

type Props = {
  hideConfirmationFooter: boolean,
  displayConfirmationTitle: boolean,
  isSuccessfulBookingView?: boolean,
  showAccountCreatedBanner: boolean
}

const ConfirmationView = (props: Props): Node => {
  const isMobileView = isMobile(window)
  const bookingState: OnlineBooking = useSelector(
    (state: StateType) => state.onlineBooking
  )

  if (!bookingState.available_date || !bookingState.selected_time_slots) {
    return (
      <BookingErrorView errorString={__('Booking information has been refreshed')}/>
    )
  }

  if (isMobileView)
    return (
      <ConfirmBookingMobileLayout
        bookingData={bookingState}
        hideConfirmationFooter={props.hideConfirmationFooter}
        displayConfirmationTitle={props.displayConfirmationTitle}
        isSuccessfulBookingView={props.isSuccessfulBookingView}
        showAccountCreatedBanner={props.showAccountCreatedBanner}
      />
    )
  else
    return (
      <ConfirmBookingDesktopLayout
        bookingData={bookingState}
        isSuccessfulBookingView={props.isSuccessfulBookingView}
        showAccountCreatedBanner={props.showAccountCreatedBanner}
      />
    )
}

export default (withOnlineBookingModal(ConfirmationView): *)
