// @flow
import React, { Component } from 'react'
import Alerts from 'views/Alerts'
import Button from 'components/buttons'
import { RefreshIcon } from 'components/icons'
import { Card, Toolbar } from 'components/generic'
import { Title, Paragraph } from 'components/text'


export default class NetworkError extends Component<{}> {

  handleRetry = () =>
    location.reload()

  render () {
    return <main data-name='error'>

      <Alerts login />

      <Card regular className='page'>
        <Title>
          Network error
        </Title>
        <Paragraph>
          Could not connect to the service. Are you connected to the internet?
        </Paragraph>
        <Toolbar left>
          <Button error large
            icon={ RefreshIcon }
            onClick={ this.handleRetry }>
            Retry
          </Button>
        </Toolbar>
      </Card>

    </main>
  }

}
