// TODO: Refactor to use the `date-fns` package
//       instead of using `moment` to massively
//       reduce the bundle size.

import React from 'react'
import InputField from './InputField'

import moment from 'moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { getLocale } from 'utils/gettext'

import { formatDate } from 'utils/formatters'
import { DATE_FORMAT } from '../../../src/constants'

const DATE_VALUE_FORMAT = 'YYYY-MM-DD'
const parseDate = (str, format?) => format ? moment(str, format).toDate() : moment(str).toDate()
const parseDateToValue = (date: Date | string) => date ? moment(date).format(DATE_VALUE_FORMAT) : undefined
const isValid = (date, format) => moment(date, format, true).isValid()


export default class DateField extends InputField {

  static defaultInitialValue = undefined

  element
  currentValue = undefined

  constructor (props) {
    super(props)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidUpdate (prevProps: *) {
    if (prevProps.value !== this.props.value) {
      if (!this.props.value) this.currentValue = this.props.value
      this.setState({ value: this.props.value })
    }
  }

  //eslint-disable-next-line
  handleDateChange (date) {

    if (!date)
      date = this.element ? this.element.input.value : undefined

    if (date && is.string(date) && !date.length)
      date = undefined

    if (date && !isValid(date, DATE_VALUE_FORMAT)) {
      const splitDate = date.split('.')
      if (splitDate.length > 0) {
        let parsedDate = ''
        for (let i = splitDate.length - 1 ; i >= 0; i--) {
          parsedDate += splitDate[i]
          if (i > 0) parsedDate += '-'
        }
        date = parsedDate
      }
    }

    if (!this.currentValue)
      this.currentValue = date
        ? parseDate(date)
        : date
    this.update(parseDateToValue(date))
  }

  componentDidMount () {
    if (this.value && this.value.length > 0)
      this.handleDateChange(this.value)
    else if (this.getInitialValue())
      this.handleDateChange(this.getInitialValue())
  }

  renderFormControl () {

    // TODO: Localization in a way that makes sense
    const locale = getLocale() || 'fi'

    return <DayPickerInput
      dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: locale }}
      format={ DATE_FORMAT }
      formatDate={ formatDate }
      placeholder='p.k.vvvvv'
      onDayChange={ this.handleDateChange }
      name={ this.name }
      value={ this.currentValue }
      ref={ ref => ref && (this.element = ref) }
      disabled={ this.disabled }
    />
  }
}
