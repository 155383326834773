/**
 * @flow
 * @class EditorView
 */

import self from 'autobind-decorator'
import React, { PureComponent, Fragment } from 'react'
import ErrorList from 'react-drf/ErrorList'
import fields from 'react-drf/fields'
import equal from 'shallowequal'

import { Property } from './FieldEditor'
import Text from 'components/text'


type FieldOptionsProps = {|
  options: Object,
  values: Object,
  errors: Object,
  onChange: Function
|}

type FieldOptionsState = {
  options: Object,
  values: Object
}


export default class FieldOptions extends PureComponent<FieldOptionsProps, FieldOptionsState> {

  static displayName: string = 'Field Options Editor'

  static defaultProps: FieldOptionsProps = {
    options: {},
    values: {},
    errors: {},
    onChange: () => {}
  }

  state: FieldOptionsState = {
    options: {},
    values: {},
  }

  // eslint-disable-next-line complexity, max-statements
  static getDerivedStateFromProps (props: FieldOptionsProps, previousState: FieldOptionsProps): Object {
    const state      = {}
    const hasValues  = props.values ? true : false
    const hasOptions = Object.keys(props).includes('options')

    if (!(hasOptions || hasValues))
      return null

    if (hasValues && !equal(props.values, previousState.values))
      state.values = props.values

    if (hasOptions) {
      const options = previousState.options
      let shouldUpdateOptions = false

      for (let key in props.options) {
        const previousValue = previousState.options[key]
        const nextValue = props.options[key]
        options[key] = Object.assign({}, previousValue, nextValue)

        if (!equal(previousValue, nextValue))
          shouldUpdateOptions = true
      }

      if (shouldUpdateOptions)
        state.options = options
      else if (!hasValues)
        return null
    }

    return state
  }

  @self
  handleChange (key: string, value: Object, option: string) {
    if (value.target)
      value = value.target.value
    this.props.onChange({ option, key, value })
  }

  render (): React$Element<*> {

    const getComponentClassForOption = (option) =>
      fields.get(option.type)

    const PropertyErrors = (props) =>
      this.renderErrors(props.name)

    return <Fragment>

      { Object.keys(this.state.options).map(key => {

        const value     = this.state.values[key]
        const option    = this.state.options[key]
        const component = getComponentClassForOption(option)
        const additionalProps = component ? { component } : {}

        const update = value =>
          this.handleChange(key, value, option)

        let isCheckbox = option.type === 'boolean'

        return  isCheckbox
          ? <Property
            { ...additionalProps }
            key={ key }
            value={ value }
            onChange={ update }
            className='field-attribute'
            helpText={ option.help_text }
            notranslate >
            <Text notranslate>{ option.label }</Text>
          </Property>
          : <Fragment><Property
            { ...additionalProps }
            key={ key }
            label={ option.label }
            value={ value }
            onChange={ update }
            className='field-attribute'
            helpText={ option.help_text }
            notranslate
          />
          <PropertyErrors name={ key } />
          </Fragment>
      }) }
    </Fragment>
  }

  renderErrors (fieldName: string): React$Element<*> | null {
    let errors = this.props.errors
    if (!errors)
      return null
    return <ErrorList errors={ errors.options[fieldName] } />
  }
}
