// @flow
import React, { useRef, useEffect, useState } from 'react'
// $FlowIgnore
import { Wrapper as GoogleMapsWrapper, Status } from '@googlemaps/react-wrapper'
// $FlowIgnore
import { useSelector } from 'react-redux'
import { getLocationBySelectedTimeslot } from '../utils/getLocation'

import type { OnlineBookingState, Location } from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

// TODO: Clean up code

const MapComponent = (props: *) => {
  const ref = useRef()
  const [map, setMap] = useState()
  const state: OnlineBookingState = useSelector(
    (state: StateType) => state.onlineBooking
  )
  useEffect(() => {
    if (!map || !state) return

    const g = new window.google.maps.Geocoder()
    const timeSlotLocationId = state.selected_time_slots?.location_id
    const selectedLocations = state.selected_locations

    const location = getLocationBySelectedTimeslot(
      // $FlowIgnore
      timeSlotLocationId,
      selectedLocations
    )

    const params = {
      address: location[0]?.address.street_address,
    }

    g.geocode(params, (result) => {
      const { lat, lng } = result[0].geometry.location
      const latLng = { lat: lat(), lng: lng() }
      map.panTo(latLng)

      new window.google.maps.Marker({
        position: latLng,
        map,
        title: location[0]?.name,
      })
    })
  }, [state, map])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 14,
        })
      )
    }
  }, [ref, map])

  return (
    <div
      id='map'
      className='google-map'
      onClick={(e) => e.stopPropagation()}
      style={{ height: props.height || '190px' }}
      ref={ref}
    />
  )
}

const renderWrapperStatus = (status: typeof Status) => {
  if (status === Status.FAILURE) return <div>Error loading map</div>
  return <div>Loading</div>
}

const Map = (props: *): * => {
  return (
    <GoogleMapsWrapper
      apiKey={process.env.GOOGLE_MAPS_API_KEY}
      render={renderWrapperStatus}
    >
      <MapComponent {...props} />
    </GoogleMapsWrapper>
  )
}

export default Map
