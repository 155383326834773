// @flow
import React, { useEffect } from 'react'
import { Modal } from '@material-ui/core'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'
import { increaseStep } from '../../../actions/onlineBooking'

import { __ } from 'utils/gettext'

import { Icon } from '@nordhealth/react'
import LoginForm from '../../Login/LoginForm'

import type { Node } from 'react'

type Props = {
  open: boolean,
  setOpen: Function,
  showProceedAsGuest: boolean,
  // Required for the frontpage view in order
  // to remain on it and not to increase the step
  // In case of the transition from the overview to
  // form - we set props to be true in order
  // to not display the modal and make the transition
  forceTransition?: boolean,
  setShowLoginBanner: Function
}

const BookingLoginModal = (props: Props): Node => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    // If user is logged in - we do not want to display modal at
    // confirmation step and we transition to form immediatelly
    if (user.id && props.open && props.forceTransition)
      return dispatch(increaseStep())
  }, [props.open])

  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <div className='booking-small-modal'>
        <div className='booking-small-modal-header'>
          <div className='booking-small-modal-header-text'>{__('Sign in')}</div>
          <div
            className='booking-small-modal-header-close'
            onClick={() => props.setOpen(false)}
          >
            <Icon name='interface-close' size='s' />
          </div>
        </div>
        <div className='login-modal-content'>
          {/* $FlowIgnore */}
          <LoginForm
            isOnlineBooking={true}
            showProceedAsGuest={props.showProceedAsGuest}
            setBookingModalOpen={props.setOpen}
            setShowLoginBanner={props.setShowLoginBanner}
          />
        </div>
      </div>
    </Modal>
  )
}

export default BookingLoginModal
