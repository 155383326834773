import StringField from './StringField'
import ChoiceField from './ChoiceField'
import SingleSelect from './SingleSelect'
import PasswordField from './PasswordField'
import FetchURLField from './FetchURLField'
import AttachmentField from './AttachmentField'
import ListSelectField from './ListSelectField'
import FileSelectField from './FileSelectField'
import TokenSelectField from './TokenSelectField'
import SliderToggleField from './ToggleFieldSlider'
import SearchChoiceField from './SearchChoiceField'
import SelectChoiceField from './SelectChoiceField'
import SelectPlanField from './SelectPlanField'
import SingleToggleField from './SingleToggleField'
import MultilineTextField from './MultilineTextField'
import MultiFieldContainer from './MultiFieldContainer'
import MultiSelectDropdown from './MultiSelectDropdown'
import MultipleChoiceField from './MultipleChoiceField'
import MultipleChoiceModalField from './MultipleChoiceModalField'
import UserSelectField from './UserSelectFied'
import DateTimeLocalField from './DateTimeLocalField'

// FIXME
const FileUploadField = Object.assign(() => null, {
  defaultProps: { value: null }
})

export {
  StringField,
  ChoiceField,
  SingleSelect,
  PasswordField,
  FetchURLField,
  FileUploadField,
  AttachmentField,
  ListSelectField,
  FileSelectField,
  TokenSelectField,
  SliderToggleField,
  SingleToggleField,
  SearchChoiceField,
  SelectChoiceField,
  SelectPlanField,
  MultilineTextField,
  MultiSelectDropdown,
  MultiFieldContainer,
  MultipleChoiceField,
  MultipleChoiceModalField,
  UserSelectField,
  DateTimeLocalField
}
