
// @flow
import React, { PureComponent } from 'react'
import className from 'classnames'
import { listen } from 'utils/dom'
import { createPortal } from 'react-dom'
import { CompositeDisposable } from 'event-kit'
import { Toolbar } from 'components/generic'
import { PrimaryButton } from 'components/buttons'

import type { Node } from 'react'


type OverlayProps = {
  modal?: boolean,
  children: Node,
  className?: string,
  onClose?: Function,
  onOpen?: Function,
  visible?: boolean,
}

type OverlayState = {
  visible: boolean,
  attributes: {
    class?: string,
  },
}

type AttributesType = {
  [key: string]: string
}


export default class Overlay extends PureComponent<OverlayProps, OverlayState> {

  state = {
    visible: false,
    attributes: {},
  }

  element: HTMLDivElement
  subscriptions: CompositeDisposable

  open  = () => this.setState({ visible: true }, () =>
    typeof this.props.onOpen === 'function'
    && this.props.onOpen())

  close = () => this.setState({ visible: false }, () =>
    typeof this.props.onClose === 'function'
    && this.props.onClose())

  constructor (props: OverlayProps) {
    super(props)
    this.element = document.createElement('div')
  }

  static getDerivedStateFromProps (props: OverlayProps, state: OverlayState) {
    const updates    = {}
    const attributes = {}


    if (props.visible !== state.visible)
      updates.visible = props.visible
    if (props.className !== state.attributes.class)
      attributes.class = props.className

    if (Object.keys(attributes).length)
      updates.attributes = attributes
    if (Object.keys(updates).length)
      return updates
    return null
  }

  render () {
    return createPortal(
      this.renderContent(),
      this.element)
  }

  renderContent () {
    if (this.props.modal)
      return <div className='dialog'>
        { this.props.children }

        <Toolbar>
          <PrimaryButton onClick={ this.close }>
            Done
          </PrimaryButton>
        </Toolbar>
      </div>
    return this.props.children
  }

  componentDidMount () {
    if (document.body instanceof HTMLElement)
      document.body.appendChild(this.element)

    this.subscriptions = new CompositeDisposable()
    this.updateAttributes(this.state.attributes)

    if (this.props.modal) {

      const closeOnEscape = (event: KeyboardEvent) => {
        if (!this.state.visible)
          return

        if (event.key === 'Escape') {
          event.preventDefault()
          event.stopImmediatePropagation()
          this.close()
          return false
        }
      }

      this.subscriptions.add(listen('keydown', closeOnEscape))
    }
  }

  componentDidUpdate () {
    this.updateAttributes(this.state.attributes)
  }

  componentWillUnmount () {
    this.element.remove()
    this.subscriptions.dispose()
  }

  updateAttributes (attrs: AttributesType = {}) {

    const classNames        = attrs.class ? attrs.class.split(' ').map(name => name.trim()) : []
    const concatenatedClass = className(`overlay`, ...classNames, {
      open:    this.state.visible,
      modal:   this.props.modal,
      hidden: !this.state.visible,
    })
    attrs = {
      ...attrs,
      class: concatenatedClass
    }

    for (let key in attrs)
      this.element.setAttribute(key, attrs[key])
  }

}
