// @flow
import React from 'react'

import { FullScreenModal } from './OnlineBookingModal'
import PrivacyPolicyView from '../views/PrivacyPolicyView'

import isMobile from 'utils/isMobileHook'

import BookingHeader, { BookingHeaderDesktop } from './BookingHeader'

import type { Node } from 'react'

const PrivacyPolicyModal = (props: *): Node => {
  const isMobileView: boolean = isMobile(window)
  return (
    <FullScreenModal
      visible={props.visible}
      setVisible={props.setVisible}
      zIndex={30}
    >
      {isMobileView ? (
        <BookingHeader
          onClick={() => props.setVisible(false)}
          headingText={'Privacy policy'}
          buttonLabel={'Close'}
        />
      ) : (
        <BookingHeaderDesktop
          onClick={() => props.setVisible(false)}
          headingText={'Privacy policy'}
          buttonLabel={'Close'}
          instructionText={
            'Below you can read about registering for an account and our terms of use.'
          }
        />
      )}
      <div className='main-content'>
        <PrivacyPolicyView closeModal={props.setVisible} />
      </div>
    </FullScreenModal>
  )
}

export default PrivacyPolicyModal
