// @flow
// A helper componet that renders nothing but calls logout

import connect from 'bound-connect'
import { Component } from 'react'
import { logoutWithoutRedirect as logout } from 'actions/user'


@connect
export default class ResetPassWord extends Component<{ logout: Function }> {

  static actions = (dispatch: Function) => ({
    logout:    () => dispatch(logout()),
  })

  componentDidMount () {
    this.props.logout()
  }

  render () { return null }
}
