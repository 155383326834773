// @flow
import React, { Component } from 'react'
import type { Node } from 'react'

type PropTypes = {
  errorCode?: number | string,
  style?: Object,
  errorName: string | Node,
  className: string,
  children: Node,
  footer?: Node,
}


export default class ErrorPage extends Component<PropTypes> {

  static defaultProps = {
    footer: null,
    className: '',
  }

  get code (): number {
    return parseInt(this.props.errorCode)
  }

  render () {

    return <section id='error-page' className={ this.props.className } style={ this.props.style || {} }>

      <header className='error'>
        { this.props.errorCode && <h1 className='code'>{ this.code.toString() }</h1>}
        <sub className='name'>{ this.props.errorName }</sub>
      </header>

      <div className='description'>
        { this.props.children }
      </div>

      { this.props.footer }

    </section>
  }
}
