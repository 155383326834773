export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {string} imageName - name of the image file
 * @param {number} rotation - The angle to rotate the image to
 */
export async function getCroppedImg (imageSrc: *, pixelCrop: Object, imageName: string, rotation: number) {
  const image = rotateImage(rotation, imageSrc)
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As a File
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve({ file: new File([ blob ], imageName), url: canvas.toDataURL() })
    })
  })
}


/**
 * Rotate the image based on the given angle
 * @param  {number} angle The angle of the rotation
 * @param  {string} imageDataUrl The image data url
 * @return {HTMLCanvasElement} The canvas containing the ortated image
 */
//eslint-disable-next-line max-statements
export function rotateImage (angle: number, image: string) {
  const rotate180 = angle % Math.PI === 0
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  const img: Image = new Image()
  img.src = image
  canvas.width = rotate180 ? img.width : img.height
  canvas.height = rotate180 ? img.height : img.width
  const context = canvas.getContext('2d')
  context.save()
  const width = rotate180 ? img.width : img.height
  const height = rotate180 ? img.height : img.width
  context.translate(width / 2, height / 2)
  context.rotate(angle)
  context.drawImage(img, - img.width / 2, - img.height / 2)
  context.restore()
  return canvas
}


/**
 * Get a thumbnail image for the video
 * @param  {string}   path     The path tot he video file
 * @param  {number}   secs     The second from which the thumbnail should be taken. Default is 0
 * @param  {Function} callback The callback function to call after the thumbnail is ready or an error occured
 *                             callback(img, seconds, errors)
 */
export function getVideoThumbnail (path: string, secs?: number = 0, callback: Function) {
  const video = document.createElement('video')
  video.setAttribute('crossOrigin', 'anonymous')
  video.onloadedmetadata = function () {
    this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration)
  }
  video.onseeked = function (e) {
    const canvas = document.createElement('canvas')
    canvas.height = video.videoHeight
    canvas.width = video.videoWidth
    const ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
    const img = new Image()
    img.src = canvas.toDataURL()
    callback(img, this.currentTime, e)
  }
  video.onerror = function (e) {
    callback(undefined, undefined, e)
  }
  video.src = path
}
