/**
 * @flow
 * @author mikakattainen<mika.kattainen@fns.fi>
 */
import React, { Component } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'

import { RegularButton } from 'components/buttons'
import Icons from 'components/icons'

import { PAGE_CONTENT_WIDTH } from 'constants'

import type { Node, Element } from 'react'


Modal.setAppElement('#root')


type PropTypes = {
  visible: boolean,
  width?: string, /* to set modal width (px or %) 500, 500px, 80% */
  height?: string, /* to set modal height (px or %) 400, 400px, 50% */
  onClickAway?: Function,
  children?: *,
  header?: Node | Element<*>,
  footer?: Node | Element<*>,
  headerBorder?: boolean,
  footerBorder?: boolean,
  className?: string,
}

const MAX_WIDTH = PAGE_CONTENT_WIDTH


export default class ModalView extends Component <PropTypes, { margin: string }> {

  static defaultProps: PropTypes = {
    visible: false,
    width: '90%',
    height: '90%',
    headerBorder: true,
    footerBorder: true,
  }

  get width (): string | void {
    if (is.string(this.props.width) && this.props.width.indexOf('%') >= 0) {
      const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      const percentage = Number(this.props.width.split('%')[0]) / 100
      const currentWidth = percentage * viewWidth
      if (currentWidth > MAX_WIDTH)
        return MAX_WIDTH + 'px'
    }
    return this.props.width
  }

  get contentClasses (): string {
    let className = 'modal-content'
    if (this.props.header && this.props.footer)
      className += ' header footer'
    else if (this.props.header)
      className += ' header'
    else if (this.props.footer)
      className += ' footer'
    return className
  }

  render (): React$Element<*> | null {
    const className = this.props.className
      ? 'modal' + ' ' + this.props.className
      : 'modal'
    if (!this.props.visible)
      return null

    return <Modal
      { ...this.props }
      style={{
        content: {...styles.content, width: this.width, height: this.props.height },
        overlay: styles.overlay
      }}
      isOpen={this.props.visible}
      onRequestClose={this.props.onClickAway}
    >
      <div className={ className } onClick={ e => e.stopPropagation() }>
        { this.renderHeader() }
        <div className={ this.contentClasses }>
          { this.props.children }
        </div>
        { this.renderFooter() }
      </div>
    </Modal>
  }

  renderHeader (): React$Element<*> | null {
    const className = classNames(
      'modal-header',
      !this.props.headerBorder && 'no-border')
    if (!this.props.header) return null
    return <div className={ className }>
      { this.props.header }
      <RegularButton
        onClick={ this.props.onClickAway }
        children={ null }
        icon={ Icons.Cancel }
        className='modal-close-button' />
    </div>
  }

  renderFooter (): React$Element<*> | null {
    const className = classNames(
      'modal-footer',
      !this.props.footerBorder && 'no-border')
    if (!this.props.footer) return null
    return <div className={ className }>
      { this.props.footer }
    </div>
  }

}


const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: '1200px',
    maxHeight: '90vh',
    backgroundColor: '#fff',
    overflow: 'visible',
    outline: 'none',
    border: 'none',
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
  }
}