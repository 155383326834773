/**
 * @module trackWidthDecorator
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */
import React, { Component } from 'react'
import self from 'autobind-decorator'
import { observeWindowSizeChanges, isMobile as sizeIsMobile } from 'utils/dom'

export type trackWidthPropsType = {
  isMobile?: boolean,
  elementWidth?: number,
}

type trackWidthStateType = {
  isMobile?: boolean,
  elementWidth: number
}


const trackMobile = (WrappedComponent: *, trackWidth?: boolean = false): Class<*> =>
  class extends Component<*, trackWidthStateType> {

    static displayName = 'Width Tracker'

    element: HTMLDivElement | null = null
    windowResizeSubscription = null
    state = {
      isMobile: sizeIsMobile(),
      elementWidth: 0,
    }

    componentDidMount () {
      this.windowResizeSubscription = observeWindowSizeChanges(this.onWindowSizeChange)
    }

    componentWillUnmount () {
      if (this.windowResizeSubscription)
        this.windowResizeSubscription.dispose()
    }

    generateUniqueId() {
      let S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1)
      }
      return (S4()+S4()+'-'+S4())
    }

    @self
    // eslint-disable-next-line
    onWindowSizeChange () {
      const isMobile = sizeIsMobile()
      let elementWidth = 0

      if (this.element && this.element.children && this.element.children[0])
        elementWidth = this.element.children[0].offsetWidth
      else if (this.element)
        elementWidth = this.element.offsetWidth

      const state: Object = {}

      if (this.state.isMobile !== isMobile)
        state.isMobile = isMobile

      if (trackWidth && this.state.elementWidth !== elementWidth)
        state.elementWidth = elementWidth

      if (Object.entries(state).length)
        this.setState(state)
    }

    render () {
      const uniqId = this.generateUniqueId()
      return <div id={ `track-width-${ uniqId }` } ref={ ref => this.element = ref }>
        <WrappedComponent
          isMobile={ this.state.isMobile }
          elementWidth={ this.state.elementWidth }
          {...this.props} />
      </div>
    }
  }

const trackWidth = (WrappedComponent: *): Function => trackMobile(WrappedComponent, true)

export {
  trackWidth,
}
export default trackMobile
