// @flow
import React, { PureComponent, createRef } from 'react'
import getStyle from 'computed-style'
import self from 'autobind-decorator'

import Icon from 'components/icons'
import { onDidResizeWindow, isMobile } from 'utils/dom'


type ToolbarContainerProps = {
  className?: string,
  children?: Array<React$Element<*>>,
}

type ToolbarProps = {
  children?: Array<React$Element<*>>,
  center?: boolean,
  right?: boolean,
  left?: boolean,
  collapseOnOverflow?: boolean,
  className?: string,
}

type ToolbarState = {
  overflow: boolean,
  availableWidth: number,
  visibleItemsCount: number | null,
  overflowMenuY: number,
  overflowMenuX: number,
  dropdownVisible: boolean,
}

type ContainerElement = {
  offsetLeft: number,
  offsetWidth: number,
  offsetParent: ContainerElement,
  parentElement: ContainerElement,
} & HTMLElement


export class ToolbarContainer extends PureComponent<ToolbarContainerProps> {

  render () {
    let className = 'toolbar-container'
    if (this.props.className)
      className += ' ' + this.props.className
    return <nav className={ className }>
      { this.props.children }
    </nav>
  }
}


export default class Toolbar extends PureComponent<ToolbarProps, ToolbarState> {

  minVisibleItemsCount = 1
  boundaries = []
  itemWidths = []
  toggleElement = createRef()
  root = createRef()
  unsubscribe: Function | null = null
  contentNode: HTMLElement | null
  unresponsive: boolean = true
  subscription: *

  state = {
    overflow: false,
    availableWidth: 0,
    visibleItemsCount: null,
    overflowMenuY: 0,
    overflowMenuX: 0,
    dropdownVisible: false,
  }

  get itemNodes (): Array<HTMLElement> { return this.contentNode ? [ ...this.contentNode.children ].slice(0, 3) : [] }
  get contentNode (): any { return this.containerNode ? this.containerNode.firstElementChild : null }
  set contentNode (value): any { }  // TODO: Webpack 4 setup required this for some reason. Fix properly?
  get containerNode (): HTMLElement { return this.root.current }
  get visibleItems (): Array<React$Element<*>> { return this.items.slice(0, this.visibleItemsCount) }
  get hiddenItems (): Array<React$Element<*>> { return this.items.slice(this.visibleItemsCount) }
  get hasHiddenItems (): boolean { return this.visibleItemsCount < this.items.length
    || this.state.overflowMenuY === 0 && !this.unresponsive }

  get visibleItemsCount (): number {
    const { visibleItemsCount }    = this.state
    const minCount = isMobile() ? this.items.length : this.minVisibleItemsCount

    const count = visibleItemsCount === null
      ? this.items.length
      : visibleItemsCount
    return count < minCount
      ? minCount
      : count
  }

  getItemNodeByIndex (index: number): Element | null {
    const nodes = this.itemNodes

    if (index >= 0 && index < nodes.length)
      return nodes[index]
    return null
  }

  getNodeContentForItemAtIndex (index: number): Element | null {
    const node = this.getItemNodeByIndex(index)
    return node && node.firstElementChild || null
  }

  focusItemAtIndex (index: number) {
    const node = this.getNodeContentForItemAtIndex(index)
    return node && node.focus()
  }

  componentDidMount () {
    this.setState({ visibleItemsCount: this.props.children ? this.props.children.length : 1 })
    if (!this.props.className || this.props.className.indexOf('indice') < 0) {
      setTimeout(() => {
        this.updateNodePositions()
        this.subscription = onDidResizeWindow(this.calculateDimensions)
        this.calculateDimensions()
      })
    } else {
      this.unresponsive = true
    }
  }

  componentWillUnmount () {
    this.subscription && this.subscription.dispose()
  }

  @self
  toggleDropdown () {
    this.setState({ dropdownVisible: !this.state.dropdownVisible })
  }

  updateReference (element: ContainerElement) {
    let nav    = element.parentElement
    if (!nav)
      return

    let parent    = nav.offsetParent
    let offset    = nav.offsetLeft + nav.offsetWidth

    if (!parent)
      return null

    let available = parent.offsetWidth
    let padding   =
      parseFloat(getStyle(parent, 'padding-left')) +
      parseFloat(getStyle(parent, 'padding-right'))

    let availableWidth = available - padding
    this.setState({
      availableWidth,
      overflow: offset > available - padding,
    })
  }

  get items (): Array<React$Element<*>> {
    let { children } = this.props
    if (!(children instanceof Array))
      if (!children)
        return []
      else
        return [ children ]
    return children
  }

  get alignment (): string {
    if (this.props.center === true) return 'center'
    if (this.props.right === true)  return 'right'
    if (this.props.left === true)   return 'left'
    return 'uniform'
  }

  render () {
    let { className } = this.props


    const setStateClass = (ref: HTMLElement | null) => {
      if (!ref)
        return

      let furtherEdge = ref.offsetLeft + ref.offsetWidth
      ref.classList.remove('collapse', 'normal')
      if (this.state.availableWidth < furtherEdge)
        ref.classList.add('collapse')
      else
        ref.classList.add('normal')
    }

    const setReference = ref =>
      ref && this.updateReference(ref)

    const alignment = this.alignment
    const classNames  = [ 'toolbar', `align-${alignment}` ]

    // Append the props.className's value to the class name array
    if (className instanceof Array)
      classNames.splice(0, 0, ...className)
    else if (typeof className === 'string')
      classNames.push(className)

    const renderToolbarItem = (child, n) =>
      child ? <li
        key={n} ref={ setStateClass }
        className='toolbar-item'>
        { child }
      </li> : null

    const style = { ...this.props.style, position: 'relative' }

    return <nav
      className={ classNames.join(' ') }
      ref={ this.root }>
      <ul ref={ setReference } style={ style }>
        { this.visibleItems.map(renderToolbarItem)}

        { this.hasHiddenItems &&
          <li
            onClick={ this.toggleDropdown }
            className='toggle'
            ref={ this.toggleElement }>
            <Icon.More />
          </li> }
      </ul>

      { this.state.dropdownVisible &&
      <ul className='dropdown' style={ { top: this.state.overflowMenuY, left: this.state.overflowMenuX } }>
        { this.hiddenItems.map(renderOverflowItem) }
      </ul> }
    </nav>
  }


  /**
   * Get an array of horizontal positions describing the menu's list items'
   * rightmost coordinates.
   *
   * @method  getBoundariesForVisibleNodes
   */

  getBoundariesForVisibleNodes (): Array<number> {
    return this.itemNodes ? this.itemNodes.map(getNodePosition) : []
  }

  getWidthsForVisibleNodes (): Array<number> {
    return this.itemNodes ? this.itemNodes.map(getNodeWidth) : []
  }

  updateNodePositions () {
    const positions = this.getBoundariesForVisibleNodes()
    this.boundaries.splice(0, positions.length, ...positions)

    const widths = this.getWidthsForVisibleNodes()
    for (let i = 0; i < widths.length; i++) {
      this.itemWidths.push(widths[i])
    }
  }

  @self
  //eslint-disable-next-line
  calculateDimensions () {
    if (!this.containerNode) return
    const containerDimensions = this.containerNode.getBoundingClientRect()
    const boundary            = containerDimensions.right
    const reduceBoundaries    = (count, current) =>
      count + (current <= boundary ? 1 : 0)
    const visibleItemsCount   = this.boundaries.reduce(reduceBoundaries, 1)

    if (visibleItemsCount !== this.visibleItemsCount)
      this.setState({ visibleItemsCount })

    if (this.toggleElement.current) {
      const toggleElementDimensions = this.toggleElement.current.getBoundingClientRect()
      let minWidth = 0
      for (let i = visibleItemsCount; i < this.itemWidths.length; i++) {
        if (this.itemWidths[i] > minWidth)
          minWidth = this.itemWidths[i]
      }
      const overflowMenuY = toggleElementDimensions.height || 1
      const overflowMenuX = containerDimensions.width - minWidth
      this.setState({ overflowMenuY, overflowMenuX })
    }
  }
}


const renderOverflowItem = (item, n) =>
  <li key={n}>{ item }</li>


const getNodePosition = node =>
  node.getBoundingClientRect().right

const getNodeWidth = node =>
  node.getBoundingClientRect().width
