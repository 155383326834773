import TooltipManager from './TooltipManager'

export default new TooltipManager()


if (module.hot) {
  module.hot.accept(() => {
    module.exports.default = new (require('./TooltipManager').default)()
  })
}
