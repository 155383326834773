/**
 * @flow
 * @class UserView
 */
import * as React from 'react'
import connect from 'bound-connect'
import context from 'context'
import { withRouter } from 'react-router-dom'

import { GROUP_SUPERUSER, GROUP_ADMIN, GROUP_LEVEL_ADMIN, GROUP_LEVEL_SUPERUSER, GROUP_MANAGEMENT , SUBSCRIPTION_TYPE_PRO } from 'constants'

import AuthorizedComponent from 'components/AuthorizedComponent'
import { PrimaryButton, MergedButtonGroup } from 'components/buttons'
import DeleteButton from 'components/DeleteButton'
import Dropdown from 'components/Dropdown'
import FAB from 'components/FloatingActionButton'
import { ToolbarContainer, Toolbar, Token } from 'components/generic'
import Icons from 'components/icons'
import ListView from 'components/ListView'
import ListViewFilters, { filtersForListView } from 'components/ListViewFilters/index'
import ProfilePicture from 'components/Picture'
import Text, { Sub, Subtle, ListItemContent } from 'components/text'
import trackWidth from 'components/TrackWidthDecorator'

import { updateUserSearch, updateUserGroupFilter, setOwnClients, setDisabled, setUnverified, updateTags } from 'actions/users'

import User from 'models/User'

import navigator from 'routes/base'
import { descriptors } from 'routes/management'

import type { StateType } from 'store/initialState'


const DELETE_SUCCESS_MESSAGE_LABEL = 'User was deleted successfully'
const CONFIRM_DELETE_TITLE         = 'Delete user'
const CONFIRM_DELETE_LABEL         = 'All data related to the user will be permanently deleted.'
                                      + ' This includes conversations, messages and submitted forms.'
                                      + ' Are you sure you wish to delete the user?'

export type Tag = {
  label: string,
  value: number,
}

export type TagsType = Array<Tag>

export type TagsRequestType = Promise<Array<Tag>>

type UsersListStateType = {
  tagsOptions: Array<Tag>,
  groupsOptions: Array<Tag>,
}


@withRouter
@trackWidth
@connect
export default class UsersList extends React.Component<*, UsersListStateType> {

  // flow-ignore
  static properties (state: StateType): * {
    const { search, tags, groups, own_clients, disabled, unverified } = state.users.tenant

    const filters = [{
      name: 'tags',
      value: tags,
      placeholderText: 'Search with tags',
      dispatch: updateTags,
      dispatchClearValue: null
    }, {
      name: 'search',
      value: search,
      placeholderText: 'Search users',
      dispatch: updateUserSearch,
      dispatchClearValue: null
    }, {
      name: 'groups',
      value: groups,
      dispatch: updateUserGroupFilter,
      placeholderText: 'Search with user group',
      dispatchClearValue: null
    }, {
      name: 'disabled',
      value: disabled,
      displayName: 'Disabled',
      dispatch: setDisabled,
      dispatchClearValue: false
    }, {
      name: 'own_clients',
      value: own_clients,
      displayName: 'Own clients',
      dispatch: setOwnClients,
      dispatchClearValue: false
    }, {
      name: 'unverified',
      value: unverified,
      displayName: 'Unverified',
      dispatch: setUnverified,
      dispatchClearValue: false
    }]
    return {
      filters,
      search,
      own_clients,
      disabled,
      unverified,
      groups,
      tags,
    }
  }

  state: UsersListStateType = {
    tagsOptions:    [],
    groupsOptions:  [],
  }

  tagsRequest: TagsRequestType
  _isMounted: boolean

  static actions (dispatch: Function): * {
    return {
      applyFilter:     value => dispatch(updateUserSearch(value)),
      updateTags:      value => dispatch(updateTags(value)),
    }
  }

  constructor (props: *) {
    super(props)

    this._isMounted = false
  }

  get type (): string {
    return this.props.type
  }

  get url (): string {
    return `/${this.type}/`
  }

  get selectedFilters (): Array<*> {
    return this.props.filters
      .filter(filter => !Array.isArray(filter.value))
  }

  get filters (): Array<*> {
    if (this.type === 'clients') {
      return this.props.filters }
    else if (this.props.filters && this.props.filters.length) {
      return this.props.filters.slice(0, 4)
    }
    return []
  }

  get extraActions (): Array<Object> {
    const isClients = this.type === 'clients'
    const createUserUrl = isClients
      ? descriptors.createUser
      : descriptors.createStaff
    return [{
      label: `Add ${isClients ? 'client' : 'staff'}`,
      onClick: () => navigator.navigate(createUserUrl, {}, {type: this.type})
    }, {
      label: `${isClients ? 'Client' : 'Staff' } mass import`,
      onClick: () => navigator.navigate(descriptors.userMassImport),
      props: {},
      authorized: true,
      allowedGroups: GROUP_LEVEL_ADMIN,
    }]
  }

  get actionsComponent (): React$Element<*> | null {
    return this.props.isMobile
      ? null
      : <PrimaryButton withborders icon={ Icons.Caret } />
  }

  get deleteGroups (): Array<string> {
    return this.type == 'clients'
      ? [...GROUP_LEVEL_ADMIN]
      : [...GROUP_LEVEL_SUPERUSER, GROUP_MANAGEMENT]
  }

  allowDelete: Function = (user: User) => {
    const currentUser = context.currentUser
    if (currentUser.group && this.deleteGroups.includes(currentUser.group.name))
      return true
    else if (this.type === 'clients' && currentUser.is_staff)
      return !!user.profile.employees.find(e => e.id === currentUser.id)
    return false
  }

  setTagsOptions: Function = (tagsOptions, groupsOptions) => this.setState({ tagsOptions, groupsOptions })

  render (): React$Element<*> {
    const wrapperProps = this.type === 'staff'
      ? { allowedGroups: [...GROUP_LEVEL_ADMIN, GROUP_MANAGEMENT] }
      : null
    const Wrapper = this.type === 'staff'
      ? AuthorizedComponent
      : React.Fragment
    const createUserUrl = this.type === 'staff'
      ? descriptors.createStaff
      : descriptors.createUser

    return <section>

      <Wrapper {...wrapperProps} >
        <ToolbarContainer className='header'>
          { this.props.isMobile
            ? <FAB action={ () => navigator.navigate(createUserUrl, {}, {type: this.type}) } />
            : <Toolbar left>
              <MergedButtonGroup>
                <AuthorizedComponent>
                  <PrimaryButton onClick={ () => navigator.navigate(createUserUrl, {}, {type: this.type}) }>
                    {`Add ${this.type === 'clients' ? 'client' : 'staff'}`}
                  </PrimaryButton>
                </AuthorizedComponent>
                { this.type === 'clients' && <AuthorizedComponent allowedGroups={ GROUP_LEVEL_ADMIN }>
                  <Dropdown paddingsmall small bordered
                    items={ this.extraActions }
                    toggleComponent={ this.actionsComponent } />
                </AuthorizedComponent> }
              </MergedButtonGroup>
            </Toolbar> }
          <ListViewFilters
            initOptionsUrl={this.url}
            filters={this.filters}
            className={'listview-filters'}
          />
        </ToolbarContainer>
      </Wrapper>

      <ListView
        className='striped'
        emptyListContent={<Text>No users found</Text>}
        url={ this.url }
        method={ this.type === 'staff' ? 'get' : 'post' }
        mapItemToRow={ this.mapUserToListItem }
        filters={ filtersForListView(this.props.filters) }
        userType={ this.type }
      />
    </section>
  }

  //eslint-disable-next-line complexity, max-statements
  mapUserToListItem: Object = (user: User, component: *, key: *) => {
    const authorized: boolean = canEdit(user)
    const content = getContent(user)

    // TODO: Is it good we rely on user.is_staff? This should be implied from the url the
    // users are fetched from (/api/staff/ vs /api/clients/). Is this too generic? Should
    // we have ClientsList and StaffList components, both extending UsersList?
    const descriptor = this.type === 'clients'
      ? descriptors.user(user.id)
      : descriptors.staffUser(user.id)
    const url: string | null = authorized
      ? descriptor
      : null
    const { active_subscription } = user.profile
    const isProUser: boolean = user.is_staff && active_subscription && active_subscription.type === SUBSCRIPTION_TYPE_PRO
      ? true
      : false
    const isSuperuser: boolean = context.currentUserGroup && context.currentUserGroup.name === GROUP_SUPERUSER
      ? true
      : false

    const actions = this.allowDelete(user)
      ?  [{
        label: 'Delete',
        onClick: () => {},
        component: <DeleteButton
          url={ `/${this.type}/${user.id}/` }
          confirmationTitle={ CONFIRM_DELETE_TITLE }
          confirmationLabel={ CONFIRM_DELETE_LABEL }
          successMessage={ DELETE_SUCCESS_MESSAGE_LABEL }
          listComponent={ component }
          dropdown />,
        props: {},
      }]
      : null

    const dropdown = <Dropdown
      items={ actions || [] }
      small />

    const profilePicWidth = 1
    const actionsWidth = actions ? 1 : 0
    const planBadgeWidth = !user.is_staff ? 1 : 0
    const contentWidth = 12 - (profilePicWidth + actionsWidth + planBadgeWidth)
    const columns = [
      [ profilePicWidth, <ProfilePicture key={ key } className='profile-picture' image={ user.profile.picture } small circle /> ],
      [ contentWidth, content ],
      [ 4, renderTags(user.profile.tags, this.props.updateTags) ]
    ]

    if (isSuperuser && isProUser)
      columns.push([ planBadgeWidth, <Sub key={ key } className='badge' notranslate>PRO</Sub> ])

    if (actionsWidth)
      columns.push([ actionsWidth, actions ? dropdown : null ])
    return {
      id: user.id,
      url: url,
      columns,
    }
  }

  getFilter (key: string): Object {
    return this.props.filters.find(filter => filter.name === key)
  }

}

/**
 * Check the permission for the current user to edit the listed user
 * @param  {User} user The listed user
 * @return {boolean}   True if curren tuser has permission to edit the listed user and false otherwise
 */
export const canEdit = (user: *): boolean => {
  // If the user is not staff (= is a client) all staff members are allowed to edit them
  // Or if the listed user is current user then editing is allowed
  if (!user.is_staff || user.id === context.currentUser.id)
    return true

  // If current user is base level staff then he's not allowed to edit any staff users
  if (!context.currentUserGroup)
    return false

  // If current user is admin user
  if (context.currentUserGroup.name === GROUP_ADMIN) {

    // Admin users are allowed to edit base level staff users
    if (!user.group)
      return true

    // But not admin or superuser users
    else
      return false
  }

  // If current user is super user they are allowed to edit all users
  // If user group is management - allowed to edit staff (User list is not displayed for management)
  if (context.currentUserGroup.name === GROUP_SUPERUSER
      || context.currentUserGroup.name === GROUP_MANAGEMENT)
    return true

  // return false if none of the above matched so that no one can have unwanted access
  return false
}

const STATUS = {
  '0': '',
  '1': 'Unverified',
  '2': 'Verified',
  '3': 'Active',
  '4': 'Disabled',
}

export function getUserStatus(user: User): React.Element<typeof Sub> {
  if (user.is_staff && user.group)
    return <Sub notranslate>{ user.group.display_name }, <Text>{ STATUS[user.status] }</Text>
      <Subtle notranslate> (<Text>Customer number</Text>: { user.id })</Subtle>
    </Sub>

  return <Sub>
    <Text>{ STATUS[user.status] }</Text>
    <Subtle notranslate> (<Text>Customer number</Text>: { user.id })</Subtle>
  </Sub>
}

export function getContent(user: User): React.Element<'header'> {
  let userName = new User(user).toString()

  return <header>
    <ListItemContent notranslate>{ userName }</ListItemContent>
    <sub>{ user.is_staff ? user.email : user.profile.social_security_number }</sub>
    { getUserStatus(user) }
  </header>
}

export const renderTags = (tags: Array<*>, applyFilter: Function): React$Element<*> => <span
  className='block-layout align-right tags-list'>
  { tags.map((tag, index) => renderTag(tag, index, applyFilter)) }
</span>

export const renderTag = (tag: *, index: number, apply: Function): React$Element<*> =>
  <Token
    tiny
    info
    onClick={ event => {
      event.preventDefault()
      event.stopPropagation()
      apply([{ value: tag.id, label: tag.text }])
      return false
    }}
    key={ index }
  >{ tag.text }</Token>