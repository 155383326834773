// @flow

import React, { Fragment } from 'react'
import Text, { Paragraph, Title } from 'components/text'
import { PrimaryButton } from 'components/buttons'
import { Link } from 'react-router-dom'
import PinCodeInput from 'components/PinCodeInput'

type TextProps = {
  titleText: string,
  paragraphText: string,
  paragraphTextParams: Object,
  submitButtonText: string,
  resendCodeText: string,
  cancelText: string,
  noCodeReceivedText: string,
}

type PropsType = {
  onSubmit: Function,
  onCancel: Function,
  onChange: Function,
  hideCancel: boolean,
  createEntry: Function,
  errors: Array<*>,
  lastDigits: string,
  textProps?: TextProps
}

export default function EnterCodeView(props: PropsType): React$Element<*> {
  const texts: TextProps = {
    titleText: 'Two factor authentication',
    paragraphText: 'Enter the verification code, that we sent to phone number that ends with -${lastDigits}.',
    paragraphTextParams: { lastDigits: props.lastDigits },
    submitButtonText: 'Authenticate',
    resendCodeText: 'Resend code',
    cancelText: 'Cancel',
    noCodeReceivedText: 'Didnt receive the sms message? Contact our staff.'
  }

  if (props.textProps)
    Object.assign(texts, props.textProps)

  return <Fragment>
    <Title>{ texts.titleText }</Title>
    <Paragraph params={ texts.paragraphTextParams }>{ texts.paragraphText }</Paragraph>

    <PinCodeInput onChange={ props.onChange } length={ 6 }/>

    { props.errors && <div className='field-errors color-error'>
      { props.errors.map(e => 
        <p key={e} className='error'>{e}</p>)
      }
    </div> }

    <PrimaryButton
      filled
      onClick={ props.onSubmit }
      style={{ width: 'fit-content', margin: 'auto', marginBottom: '2em' }}
    >
      { texts.submitButtonText }
    </PrimaryButton>

    <Link to="#" onClick={ props.createEntry }><Text>{ texts.resendCodeText }</Text></Link>
    {!props.hideCancel && <Link to="#" onClick={ props.onCancel }><Text>{ texts.cancelText }</Text></Link>}
    
    <Paragraph style={{ fontSize: '0.8em' }}>{ texts.noCodeReceivedText }</Paragraph>
  </Fragment>
}
