// @flow
import React, { PureComponent, createElement } from 'react'
import { CompositeDisposable } from 'event-kit'
import parseHTML from 'html-react-parser'
import Truncate from 'react-truncate'
import classNames from 'classnames'
import self from 'autobind-decorator'
import context from 'context'
import { __ } from 'utils/gettext'

type ChildrenType = string | React$Element<*> | Array<string | React$Element<*>>

type TextProperties = {
  children: ChildrenType,
  onClick?: Function,
  className?: string,
  notranslate?: true,
  parseParameters?: Object,
  params?: Object,
  truncateAfterLines?: number,
}

type TranslatedTextState = {
  text: string | null,
}


/**
 * Headings
 */

export const Heading    = createTextComponent('h1', 'header', 'headline')
export const TitleLarge = createTextComponent('h2', 'header', 'title large')
export const Title      = createTextComponent('h2', 'header', 'title')
export const Subtitle   = createTextComponent('h3', 'header', 'subtitle')
export const Caption    = createTextComponent('h4', 'header', 'caption')
export const Bold       = createTextComponent('h5', 'header')


/**
 * Text styles
 */

export const ListItemContent = createTextComponent('span',   'primary-text')
export const PrimaryText     = createTextComponent('p',      'primary-text')
export const SmallText       = createTextComponent('span',   'small-text')
export const Underlined      = createTextComponent('span',   'underlined')
export const ErrorText       = createTextComponent('span',   'error')
export const Paragraph       = createTextComponent('p',      'paragraph')
export const Clickable       = createTextComponent('span',   'clickable')
export const Strong          = createTextComponent('strong', 'strong')
export const Subtle          = createTextComponent('span',   'muted')
export const Italic          = createTextComponent('span',   'italic')
export const Label           = createTextComponent('span',   'label')
export const Sup             = createTextComponent('sup',    'superscript')
export const Sub             = createTextComponent('sub',    'subscript')


export default class RegularText extends PureComponent<TextProperties> {

  static displayName = 'Text'

  @self
  renderText (entry: *, key?: number = 1) {

    if (is.string(entry) || is.object(this.props.params))
      return <TranslatedText key={ key } { ...this.props }>
        { entry }
      </TranslatedText>

    if (is.array(entry))
      return entry
        .filter(item => item)
        .map(this.renderText)

    return entry
  }

  render () {
    const { children } = this.props

    if (this.props.notranslate) {
      return this.props.truncateAfterLines && is.string(children)
        ? <Truncate lines={ this.props.truncateAfterLines } trimWhitespace>{ children }</Truncate>
      : children
    }

    return this.renderText(children, null)
  }
}


export class TranslatedText extends PureComponent<TextProperties, TranslatedTextState> {
  subscriptions: CompositeDisposable

  static displayName = 'Text (translated)'
  state = {
    text: null,
  }

  static getDerivedStateFromProps (props: TextProperties, state: TranslatedTextState) {
    if (props.children === state.text)
      return null
    if (!props.children && !props.params)
      return null

    const text = safeTranslate(props.children, props.params)

    if (!text)
      return null
    return { text }
  }

  get params (): Object | void {
    return this.props.params
  }

  get originalText (): ChildrenType | Object | null | void {
    return this.props.children
  }

  get translatedText (): string | null {
    return this.state.text
  }

  @self
  updateTranslation () {
    if (!this.originalText)
      return

    const text = safeTranslate(this.originalText, this.params)

    if (!text)
      return
    if (this.translatedText !== text)
      this.setState({ text })
  }

  componentDidMount () {
    const languageChangeSubscription =
      context.onLanguageDidChange(this.updateTranslation)

    this.subscriptions = new CompositeDisposable()
    this.subscriptions.add(languageChangeSubscription)

    // Post the text to the server for it to
    // be appended to the language json files.
    if (__DEV__ && this.originalText)
      is.string(this.originalText)
        ? global.tasks.translations.post({ key: this.originalText })
        : global.tasks.translations.post({ key: this.originalText.text || '' })
  }

  componentWillUnmount () {
    this.subscriptions.dispose()
  }

  render () {
    if (!this.translatedText)
      warnAboutUndefinedText()
    return this.props.parseParameters
      ? parseHTML(this.translatedText, {
        replace: this.replaceElement })
      : this.translatedText
  }

  @self
  replaceElement (domNode: Object) {
    if (domNode.attribs && domNode.attribs.id === 'replace' && this.props.parseParameters) {
      const name = domNode.attribs.name
      const data = this.props.parseParameters[name]
      return createElement(data.component, data.props, data.text)
    }
  }
}


function createTextComponent (tag: string, ...classes: Array<string>) {
  const C = (props: TextProperties) => {
    const { children, notranslate, params, parseParameters, truncateAfterLines, ...properties } = props
    properties.className = classNames(...classes, properties.className)
    return createElement(
      tag,
      properties,
      <RegularText
        truncateAfterLines={ truncateAfterLines }
        notranslate={ notranslate }
        params={ params }
        parseParameters={ parseParameters }>
      { children }
    </RegularText>)
  }
  C.displayName = `Text (${tag})`
  return C
}


function safeTranslate (str, params?) {
  try {
    return __(str, params)
  }
  catch (e) {
    if (__DEV__) {
      /* eslint-disable no-console */
      console.groupCollapsed("Could not translate")
      console.error("Payload:", str)
      console.error("Error:", e)
      console.groupEnd()
      /* eslint-enable no-console */
    }
    return null
  }
}


const warnAboutUndefinedText = __DEV__
  ? () => console.error('There is a RegularText component with an undefined text on this page') // eslint-disable-line no-console
  : () => null
