// @flow
import React, { Component } from 'react'
import type { Element } from 'react'
import self from 'autobind-decorator'
import { Card, Loader } from 'components/generic'
import api from 'api'
import EnterPhoneNumberView from './EnterPhoneNumber'
import EnterCodeView from './EnterCode'
import connect from 'bound-connect'
import { addAlert } from 'actions/core'
import context from 'context'

type PropsType = {
  action: string,
  active: boolean,
  children: Element<*>,
  onCancel: Function,
  entryAdditionalData?: Object,
  onAuthenticated: Function,
  addErrorMessage?: Function,
  authenticationRequest: Function,
  preventHeadersDelete?: boolean,
  preventCancel?: boolean,
  enterPhoneNumberProps?: Object,
  enterCodeProps?: Object,
  isOnlineBooking: boolean,
}

type StateType = {
  key: string | null,
  code: string | null,
  phoneNumber: string,
  currentView: 'enter-phonenumber' | 'enter-code' | 'loading',
  errors: Object,
  lastDigits: string,
}

/**
 * Wrapper component for views that possibly require two factor authentication.
 */
@connect
export default class TwoFactorWrapper extends Component<PropsType, StateType> {
  static actions: Function = (dispatch: Function) => ({
    addErrorMessage: (content: string) => dispatch(addAlert('error', content)),
  })

  state: StateType = {
    key: '',
    code: '',
    phoneNumber: '',
    currentView: 'loading',
    errors: {},
    lastDigits: '',
  }

  resetState() {
    this.setState({
      key: '',
      code: '',
      phoneNumber: '',
      currentView: 'loading',
      errors: {},
      lastDigits: '',
    })
  }

  @self
  addErrorMessage(content: string): void {
    if (this.props.addErrorMessage) this.props.addErrorMessage(content)
  }

  @self
  onCancel() {
    api.deleteHeader('2fa-code')
    api.deleteHeader('2fa-key')
    if (this.props.onCancel) this.props.onCancel()
  }

  @self
  handleEntryCreationError(response: *, previousView: *): void {
    if (response.status === 429) {
      this.onCancel()
      return this.addErrorMessage(
        'Too many two factor authentication attempts. Please wait ten minutes and try again.'
      )
    }

    // if (response.status !== 400) {
    //   // Uncommon error, try to resolve it and add error alert
    //   if (this.props.addErrorMessage)
    //     return this.addErrorMessage('An unknown error occured')
    // }

    const { data } = response
    if (data.code === 'require_phone_number') {
      // Missing phone number, don't display errors and directly change to enter
      // phone number view
      return this.setState({ currentView: 'enter-phonenumber' })
    } else if (data.code === '2fa_denied_tenant_not_billable') {
      this.onCancel()
      return this.addErrorMessage('twoFactor.error.2fa_denied_tenant_not_billable')
    } else if (data.code === '2fa_denied_missing_phone_number') {
      this.onCancel()
      if (data.detail === "Can't update phone number for superuser") {
        return this.addErrorMessage
          (
            "twoFactor.error.2fa_denied_missing_phone_number.superuser"
          )
      } else
        return this.addErrorMessage
          (
            "twoFactor.error.2fa_denied_missing_phone_number.staff"
          )
    } else {
      return this.setState({ errors: data, currentView: previousView })
    }
  }

  @self
  async createTwoFactorEntry() {
    const previousView = this.state.currentView
    this.setState({ currentView: 'loading', errors: {} })

    let data = {
      action: this.props.action,
      phone_number: this.state.phoneNumber || undefined,
    }

    if (this.props.entryAdditionalData) {
      Object.assign(data, this.props.entryAdditionalData)
    }

    const response = await api.post('/create-two-factor-entry/', data)

    if (!response.ok) this.handleEntryCreationError(response, previousView)
    else
      this.setState({
        key: response.data['2fa-key'],
        lastDigits: response.data.last_digits,
        currentView: 'enter-code',
      })
  }

  @self
  async registerPhoneNumber(): Promise<*> {
    return await api.get('/register-phone-number/')
  }

  @self
  handleAuthenticationError(response: *): void {
    const { data } = response

    if (data && data.code) {
      if (data.code === 'invalid_2fa_code')
        return this.setState({
          errors: { ...this.state.errors, code: [data.detail] },
        })
      else if (data.code === 'require_2fa') {
        // If we get this error here, we can assume that the 2fa entry is expired
        this.onCancel()

        return this.addErrorMessage(
          'Two factor authentication entry is expired. Please try again.'
        )
      }
    }

    if (response.status === 429 && data.hasOwnProperty('non_field_errors')) {
      return this.setState({
        errors: { ...this.state.errors, code: [data.non_field_errors] },
      })
    }

    this.onCancel()
    return this.addErrorMessage('An unknown error occured')
  }

  @self
  async attemptAuthentication(): Promise<void> {
    api.setHeader('2fa-key', this.state.key)
    api.setHeader('2fa-code', this.state.code)

    const response = await this.props.authenticationRequest()

    if (response && !response.ok)
      return this.handleAuthenticationError(response)

    let registerResponse: * = undefined
    // 2fa successful, save phone number if it was asked
    if (this.state.phoneNumber)
      registerResponse = await this.registerPhoneNumber()

    if (!this.props.preventHeadersDelete) {
      api.deleteHeader('2fa-code')
      api.deleteHeader('2fa-key')
    }

    const getAuthenticatedData = () => {
      let data = {}
      if (response && response.data) Object.assign(data, response.data)
      if (registerResponse && registerResponse.data)
        Object.assign(data, registerResponse.data)
      return data
    }

    const data = getAuthenticatedData()
    this.props.onAuthenticated(data)
  }

  @self
  setInitialPhoneNumber() {
    if (
      this.props.action !== 'update_phone_number' &&
      context.currentUser &&
      context.currentUser.phone_number
    ) {
      this.setState({ phoneNumber: context.currentUser.phone_number })
    }
  }

  componentDidMount() {
    if (this.props.active) {
      this.createTwoFactorEntry()
      this.setInitialPhoneNumber()
    }
  }

  async componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    if (this.props.active && !prevProps.active) {
      await this.resetState()
      await this.createTwoFactorEntry()
      this.setInitialPhoneNumber()
    }
    if (
      this.state.currentView !== 'loading' &&
      this.state.currentView !== prevState.currentView
    ) {
      const cardElem = document.querySelector('.two-factor-form-content')
      if (cardElem) cardElem.focus()
    }
  }

  render(): React$Element<*> {
    if (!this.props.active) return this.props.children

    if (this.props.isOnlineBooking) {
      return (
        <div className='two-factor-form-content-online-booking'>
          {this.renderView()}
        </div>
      )
    }

    return (
      <Card className='two-factor-form-content' tabIndex={0}>
        {this.renderView()}
      </Card>
    )
  }

  @self
  renderView(): React$Element<*> {
    switch (this.state.currentView) {
      case 'enter-code':
        return (
          <EnterCodeView
            createEntry={this.createTwoFactorEntry}
            onSubmit={this.attemptAuthentication}
            onChange={(code) => this.setState({ code })}
            errors={this.state.errors && this.state.errors.code}
            lastDigits={this.state.lastDigits}
            hideCancel={this.props.preventCancel || false}
            onCancel={this.onCancel}
            textProps={this.props.enterCodeProps}
          />
        )

      case 'enter-phonenumber':
        return (
          <EnterPhoneNumberView
            hideCancel={this.props.preventCancel || false}
            errors={this.state.errors && this.state.errors.phone_number}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            onSubmit={this.createTwoFactorEntry}
            onCancel={this.onCancel}
            textProps={this.props.enterPhoneNumberProps}
          />
        )

      default:
        return <Loader />
    }
  }
}
