export const DEFAULT_PRIMARY_COLOR = '#00809A'
export const PAGE_CONTENT_WIDTH = 1200

export const TIME_FORMAT = 'H:mm'
export const DATE_FORMAT = 'D.M.YYYY'
export const DATETIME_FORMAT = 'D.M.YYYY H:mm'
export const SHORT_DATE_FORMAT = 'D.M.'

export const PUBLIC_TENANT = 'public'

export const CLIENT_HEADER_NAME = 'Selected-Client'

export const LOGOUT_EVENT_KEY = 'logout-event'

export const GROUP_SUPERUSER = 'Superuser'
export const GROUP_ADMIN = 'Admin'
export const GROUP_MANAGEMENT = 'Management'
export const GROUP_LEVEL_STAFF = []
export const GROUP_LEVEL_ADMIN = [GROUP_SUPERUSER, GROUP_ADMIN]
export const GROUP_LEVEL_SUPERUSER = [GROUP_SUPERUSER]

export const USER_STATUS_UNVERIFIED = 1
export const USER_STATUS_VERIFIED = 2
export const USER_STATUS_ACTIVE = 3
export const USER_STATUS_DISABLED = 4

export const USER_STATUS = {
  1: 'Unverified',
  2: 'Verified',
  3: 'Active',
  4: 'Disabled',
}

export const FORM_DRAFT_STATUS = 1
export const FORM_PUBLIC_STATUS = 2
export const FORM_ARCHIVED_STATUS = 3
export const FORM_STAFFONLY_STATUS = 5
export const FORM_UNARCHIVE_STATUS = FORM_DRAFT_STATUS

export const SUBMISSION_STATUS_FIRST = 1
export const SUBMISSION_STATUS_MIDDLE = 2
export const SUBMISSION_STATUS_LAST = 3
export const SUBMISSION_STATUS_DRAFT = 4

export const DEFAULT_REDIRECT_URL = '/threads'
export const DEFAULT_RESTRICTED_TENANT_URL = '/management'
export const DEFAULT_PUBLIC_REDIRECT_URL = '/tenants'

// Form status types
// @flow-ignore
export const STATUS_TYPES = Object.assign(
  [
    { key: FORM_DRAFT_STATUS, value: 'Draft' },
    { key: FORM_STAFFONLY_STATUS, value: 'Staff only' },
    { key: FORM_PUBLIC_STATUS, value: 'Public' },
  ],
  {
    DRAFT: FORM_DRAFT_STATUS,
    PUBLIC: FORM_PUBLIC_STATUS,
    ARCHIVED: FORM_ARCHIVED_STATUS,
    STAFF_ONLY: FORM_STAFFONLY_STATUS,
  }
)

export const SUBMISSION_STATUS_TYPES = Object.assign(
  [
    { key: SUBMISSION_STATUS_FIRST, value: 'Start state' },
    { key: SUBMISSION_STATUS_MIDDLE, value: 'Midway assessment' },
    { key: SUBMISSION_STATUS_LAST, value: 'Final state' },
    { key: SUBMISSION_STATUS_DRAFT, value: 'Draft' },
  ],
  {
    START: SUBMISSION_STATUS_FIRST,
    MIDWAY: SUBMISSION_STATUS_MIDDLE,
    FINAL: SUBMISSION_STATUS_LAST,
    DRAFT: SUBMISSION_STATUS_DRAFT,
  }
)

// Subscriptios
export const SUBSCRIPTION_TYPE_ONLINE_BOOKING = 'USER_ONLINE_BOOKING'
export const SUBSCRIPTION_TYPE_STANDARD = 'USER_STANDARD'
export const SUBSCRIPTION_TYPE_PRO = 'USER_PRO'

export const SUBSCRIPTION_GROUP_USER = 'USER'
export const SUBSCRIPTION_GROUP_COURSE = 'COURSE'

export const SUBSCRIPTION_TYPE = Object.assign(
  [
    { key: SUBSCRIPTION_TYPE_STANDARD, value: 'Standard' },
    { key: SUBSCRIPTION_TYPE_PRO, value: 'Pro' },
  ],
  {
    STANDARD: SUBSCRIPTION_TYPE_STANDARD,
    PRO: SUBSCRIPTION_TYPE_PRO,
  }
)

// EVENT TYPES FOR EVENTS USED AROUND THE APP
export const EVENT_TYPES: {
  LIST_UPDATE: string,
} = {
  LIST_UPDATE: 'list_update',
}

/**
 * The different types for FileSelectField
 * @type {Object}
 */
export const FILE_TYPES = {
  img: 'img',
  file: 'file',
  video: 'video',
  document: 'document',
}

export const ALLOWED_FILE_EXTENSIONS =
  '.jpg,.png,.pdf,.mp4,.mov,.avi,.wmv,.docx,.dot,.xlsx,.txt,.jpeg,.rar,.svg,.7z,.csv,.mp3,.pptx,.bm2' +
  ',.doc,.ppt,.odt,.heic,.zip,.html' +
  ',.rtf,.docm,.pages,.txt,.odp,.xls,.htm' +
  ',.key,.xspf,.m4a,.mhtml,.mkv,.prn'

export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const STORAGE_LANGUAGE = 'language'

// TODO: Delete after online booking testing is done
export const EXCLUDE_ONLINE_BOOKING = parseInt(
  process.env.EXCLUDE_ONLINE_BOOKING || 0
)

export const COUNTRY = {
  FINLAND: 'finland',
  NORWAY: 'norway',
}
