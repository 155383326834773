import React, { Component, createRef } from 'react'
import self from 'autobind-decorator'
import { __ } from 'utils/gettext'

const ARIA_LABELS = [
  'First number',
  'Second number',
  'Third number',
  'Fourth number',
  'Fifth number',
  'Sixth number',
]

class PinCodeItem extends Component<*, *> {
  ref = createRef()

  state = { value: '' }
  
  @self
  async setValue(value) {
    await this.setState({ value })
    this.props.updateValue() // build PinCodeInput value from all PinCodeItems
  }

  @self
  async handleChange(event) {
    const { value } = event.target
    await this.setValue(value)
    
    // Value changed to empty, so backspace was pressed, don't focus the next element
    if (!value) return

    // Value is not empty, focus next element
    this.props.focusNext()
  }

  // This handler is needed, because onChange event doesn't get triggered if the value
  // is empty and backspace is pressed, or the input has a value and new value is entered
  @self
  async handleKeyUp(event) {

    // If backspace was pressed, set value to empty at all times and focus previous element
    if (event.key === 'Backspace') {
      await this.setValue('')
      return this.props.focusPrevious()
    }

    // If a value already exists and a numeric value was entered, replace the value
    // and focus next element
    if (this.state.value !== '') {
      if (!isNaN(Number(event.key))) {
        await this.setValue(event.key)
        this.props.focusNext()
      }
    }
  }

  render() {
    return <input
      style={{
        width: '43px',
        maxWidth: '43px',
        borderRadius: '4px',
        padding: 0,
        textAlign: 'center',
        margin: '0 5px'
      }}
      aria-label={__(this.props.ariaLabel)}
      value={this.state.value}
      ref={this.ref}
      inputMode='numeric'
      pattern='[0-9]*'
      maxLength='1'
      onChange={this.handleChange}
      onKeyUp={this.handleKeyUp}
    />
  }
}


export default class PinCodeInput extends Component<*, *> {
  inputRefs = React.createRef()

  state = {
    value: ''
  }

  constructor(props) {
    super(props)
    this.inputRefs.current = []
  }

  componentDidMount() {
    if (!this.props.length)
      return

    this.getItem(0).focus()
  }

  @self
  getItem(idx) {
    if (!this.inputRefs.current || !this.props.length)
      return null

    if (idx < 0 || idx >= this.props.length)
      return null
    
    return this.inputRefs.current[idx].current.ref.current
  }

  @self
  updateValue() {
    const value = this.inputRefs.current.map(input => input.current.state.value).join('')
    this.setState({value})
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  @self
  focusElement(idx) {
    const nextItem = this.getItem(idx)
    if (nextItem)
      nextItem.focus()
  }

  render() {
    if (!this.props.length)
      return null

    if (this.inputRefs.current.length !== this.props.length)
      for (let idx = 0; idx < this.props.length; idx++)
        this.inputRefs.current.push(createRef())

    let inputs = []
    for (let idx = 0; idx < this.props.length; idx++)
      inputs.push(<PinCodeItem 
        ref={this.inputRefs.current[idx]}
        key={idx}
        updateValue={ this.updateValue }
        focusNext={() => this.focusElement(idx + 1)}
        focusPrevious={() => this.focusElement(idx - 1)}
        ariaLabel={ARIA_LABELS[idx]}
      />)

    return <div style={{display: 'flex', width: '100%', justifyContent: 'center', margin: '30px 0'}}>
      {inputs}
    </div>
  }
}