// @flow
import ErrorContainer from 'react-error-boundary'
import React, { Component } from 'react'

import type { Node } from 'react'


export function onerror (error: Error, componentStack: string) {
  if (__DEV__ )
    // eslint-disable-next-line no-console
    console.error("Error message:\n", error.message, "\n\nComponent stack:\n", componentStack, "\n")
}

export type ErrorViewProps = {
  error: any,
  componentStack?: any,
}

const style = {
  position: 'relative',
  maxWidth: '100%',
  zIndex:   1000,
}


export const ErrorComponent = ({ componentStack, error }: ErrorViewProps) => {
  const stack = componentStack
    ? <section className='alert error'>
      <h4>Component stack</h4>
      <pre className='component-stack'>{ beautify(componentStack) }</pre>
    </section>
    : null

  return <div className='development error' style={ style } title={ error }>
    <h1>{ error.name.replace(/([A-Z])/g, (c) => ' ' + c).trim() }</h1>
    <p className='excerpt'>{ error.message }</p>
    <aside className='details'>
      <section className='alert error'>
        <h4>Stack Trace</h4>
        <pre className='error-stack'>{ beautify(error.stack) }</pre>
      </section>
      { stack }
    </aside>
  </div>
}


export default class ErrorView extends Component<{ children: Node }> {
  render () {
    if (!__DEV__)
      return this.props.children

    return <ErrorContainer
      onError={ onerror }
      FallbackComponent={ ErrorComponent }>
      { this.props.children }
    </ErrorContainer>
  }
}


function beautify (stack) {
  return stack
    .split('\n')
    .map(entry => entry.trim())
    .filter(entry => entry)
    .map((entry, key) => <span className='row' key={key}>{entry}</span>)
}
