import React, { Component } from 'react'


type ButtonsType = {
  label: string,
  onClick: Function,
}

type PropsType = {
  buttonComponent: React$Element,
  buttons: Array<ButtonsType>,
  small?: boolean,
}

type StateType = {
  selected: number,
}


export default class SelectButtonGroup extends Component <PropsType, StateType> {

  state = {
    selected: 0
  }

  getOnClick (onClick: Function, key: number) {
    return () => {
      this.setState({ selected: key })
      onClick()
    }
  }

  render () {
    const Button = this.props.buttonComponent
    return <div className='select-button-group'>
      { this.props.buttons.map((button, key) =>
        <Button
          className={ this.state.selected === key ? 'selected' : null }
          key={ key }
          small={ this.props.small }
          onClick={ this.getOnClick(button.onClick, key) } >
          { button.label }
        </Button>
      )}
    </div>
  }
}
