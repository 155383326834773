// @flow
import { Map } from 'immutable'

import TextField from './TextField'
import NumericField from './NumericField'
import SingleChoiceField from './SingleChoiceField'
import MultipleChoiceField from './MultipleChoiceField'

import HeaderBlock from './HeaderBlock'
import IndicatorBlock from './ExpressionBlock'
import InstructionTextBlock from './InstructionTextBlock'

import type { BlockType, BlockKindType } from '../editor/Block'
import { __ } from 'utils/gettext'


const STATIC_ELEMENT_MODELS    = [ 'Subtitle', 'HelpText' ]
const FIELD_TYPES_WITH_CHOICES = [ 'radio', 'checkbox' ]


type FieldType = {
  value: BlockKindType,
  display_name: string,
}

type FieldTypesListType = Array<FieldType>


const FIELD_TYPES: FieldTypesListType = [
  { value: 'radio',    display_name: __("Choice") },
  { value: 'checkbox', display_name: __("Multiple choice") },
  { value: 'text',     display_name: __("Text field") },
  { value: 'number',   display_name: __("Number field") },
]


export const ELEMENT_MODEL_NAMES = {
  COMPUTED: 'Indicator',
  HEADING:  'Subtitle',
  FIELD:    'Field',
  TEXT:     'HelpText',
}


const fields = Map({
  text:     TextField,
  number:   NumericField,
  radio:    SingleChoiceField,
  checkbox: MultipleChoiceField,
})


const elements = Map({

  // TODO: Update the getComponentType to use the elements.Field entry
  [ELEMENT_MODEL_NAMES.FIELD]:    fields,
  [ELEMENT_MODEL_NAMES.HEADING]:  HeaderBlock,
  [ELEMENT_MODEL_NAMES.COMPUTED]: IndicatorBlock,
  [ELEMENT_MODEL_NAMES.TEXT]:     InstructionTextBlock,
})


/**
 * Check if the given string is a valid element model name
 *
 * @method  isValidElementModelName
 * @param   term
 */

export const isValidElementModelName = (term: string): boolean =>
  elements.has(term)


export default function getComponentType (block: BlockType) {

  if (block.model !== 'Field')
    return elements.get(block.model)

  if (typeof block.type === 'string')
    return fields.get(block.type)

  const fieldType = FIELD_TYPES.find(fieldType =>
    fieldType.display_name === block.type)

  if (fieldType === null)
    return null
  return fields.get(fieldType.value)
}


export {
  TextField,
  NumericField,
  SingleChoiceField,
  MultipleChoiceField,
  HeaderBlock,
  InstructionTextBlock,
  FIELD_TYPES,
  FIELD_TYPES_WITH_CHOICES,
  STATIC_ELEMENT_MODELS,
}
