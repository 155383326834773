/**
 * @module CollapsingMenu
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent, createRef } from 'react'
import connect from 'bound-connect'
import { CompositeDisposable } from 'event-kit'
import classNames from 'classnames'
import self from 'autobind-decorator'

import { trackWidth } from 'components/TrackWidthDecorator'
import HelpIcon from 'components/HelpIcon'

import { observeWindowSizeChanges } from 'utils/dom'
import { __ } from 'utils/gettext'


import type { Node } from 'react'


type MenuItemType = {|
  label: string,
  props?: Object,
  onClick: Function,
  allowedPlans: Array<Number>
|}

type PropTypes = {|
  items: Array<MenuItemType>,
  breakpoint?: number,
  overflowIndicatorComponent: Node,
  userPlan: number,
|}

type StateTypes = {
  selected: number,
  collapsed: boolean,
  open: boolean,
}


@trackWidth
@connect
export default class CollapsingMenu extends PureComponent<PropTypes, StateTypes> {

  static BREAKPOINT: * = global.breakpoint.mobile

  static defaultProps: Object = {
    items: [],
    overflowIndicatorComponent: null,
  }

  static actions: Array<*> = []
  static properties: Object = state => ({
    userPlan:  state.user.profile.active_subscription?.type ? state.user.profile.active_subscription.type : null,
  })

  subscriptions: CompositeDisposable
  state: StateTypes = {
    selected:   0,
    collapsed:  false,
    open:       false,
  }

  root: Function = createRef()

  get breakpoint (): number {
    return this.props.breakpoint || CollapsingMenu.BREAKPOINT
  }

  get isCollapsed (): boolean {
    return this.state.collapsed ? true : false
  }

  get isOpen (): boolean {
    return this.isCollapsed && this.state.open
  }

  componentDidMount () {
    this.subscriptions = new CompositeDisposable()
    this.subscriptions.add(
      observeWindowSizeChanges(this.onDidResizeWindow))
  }

  componentWillUnmount () {
    this.subscriptions.dispose()
  }

  componentDidUpdate () {
    switch (this.isOpen) {
      case true:
        this.addDocumentEventListener()
        break
      case false:
        this.removeDocumentEventListener()
        break
    }
  }

  @self
  addDocumentEventListener () {
    document.addEventListener('click', this.closeDropdown)
  }

  @self
  removeDocumentEventListener () {
    document.removeEventListener('click', this.closeDropdown)
  }

  @self
  onDidResizeWindow () {
    const width = window.innerWidth
    const collapsed = width < this.breakpoint
    this.setState({ collapsed, open: false })
  }

  @self
  toggleDropdown () {
    this.setState({ open: !this.state.open })
  }

  @self
  closeDropdown () {
    this.setState({ open: false })
  }

  // If a tab item is restricted it can't be accessed with a standard subscription
  @self
  checkSubscription (plans: Array<Number>) {
    if (this.props.userPlan) {
      if (plans.includes(this.props.userPlan))
        return false
      return true
    }
  }

  render (): React$Element<*> {
    const empty = this.isCollapsed
    const className = classNames('navigation', { empty })

    return <nav
      ref={ this.root }
      className={ className }>

      { !empty &&
        <ul className='menu'>
          { this.props.items.map(this.renderItem) }
        </ul> }

      { empty &&
        <span
          onClick={ this.toggleDropdown }
          className='toggle'>
          { this.props.overflowIndicatorComponent }
        </span> }

      { this.isOpen &&
        <ul className='dropdown'>
          { this.props.items.map(this.renderItem) }
        </ul> }
    </nav>
  }

  @self
  // eslint-disable-next-line class-methods-use-this, complexity
  renderItem (item: MenuItemType, n: number): Node {
    let restricted
    if (item.props.restricted)
      restricted = this.checkSubscription(item.props.allowedPlans)
    const className = classNames('item', item.props ? item.props.className : '')
    const onlyOnCollapsed = item.props ? item.props.collapsed : false
    if (onlyOnCollapsed && !this.state.collapsed)
      return null
    return restricted
      ? <li
        key={ n }
        className={ className + ' disabled' }>
        <a className={ `navigation-link ${className}` }>{ item.label }</a>
        <HelpIcon text={ __('Unlock this feature with a PRO subscription') } setMinWidthAuto={ true } />
      </li>
      : <li
        key={ n }
        onClick={ item.onClick }
        className={ className }>
        <a
          onKeyPress={ item.onClick }
          className={ `navigation-link focusable ${className}` }
          tabIndex={ item.props.className === 'active' ? undefined : 0 }>{ item.label }</a>
      </li>
  }
}
