// @flow
import type { Action } from '.'
import { UPDATE_SEARCH, SET_OWN_GROUPS, UPDATE_TAGS } from 'actions/userGroups'

export type UserGroupsState = {
  tags: number | null,
  search: string | null,
  own_groups: boolean ,
}

export const initialValue: UserGroupsState = {
  tags: null,
  search:  null,
  own_groups:  false,
}

//eslint-disable-next-line
export default function userGroupsReducer (state: UserGroupsState, action: Action): UserGroupsState {
  const { payload } = action
  switch (action.type) {

    case UPDATE_TAGS:
      return { ...state, tags: payload }

    case UPDATE_SEARCH:
      return { ...state, search: payload }

    case SET_OWN_GROUPS:
      return { ...state, own_groups: payload }

    default:
      return state || initialValue

  }
}