// @flow
import getStyle from 'computed-style'
import { Disposable } from 'event-kit'
import throttle from 'throttleit'
import { ResizeSensor } from 'css-element-queries'

type AnyEvent = Event | KeyboardEvent | MouseEvent | WheelEvent


export function isElementWithinVisibleBounds (element: HTMLElement): boolean {
  let bounds = element.getBoundingClientRect()
  return (
    bounds.bottom > 0 &&
    bounds.top < window.innerHeight
  )
}


export function listen (eventName: string, handler: AnyEvent => ?boolean, element: Element | Document = document, phase = false) {
  element.addEventListener(eventName, handler, phase)
  const disposalAction = () =>
    element.removeEventListener(eventName, handler, phase)
  return new Disposable(disposalAction)
}


export function onScroll (handler: Event => *): Disposable {
  return listen('scroll', handler, window)
}


export function isFocusedElementWithin (element: HTMLElement) {
  let iterator = document.activeElement
  while (iterator && (iterator = iterator.parentElement))
    if (iterator === element)
      return true
  return false
}


export function calculateVerticalPaddingForElement (node: HTMLElement) {
  let topPadding    = getStyle(node, 'padding-top')
  let bottomPadding = getStyle(node, 'padding-bottom')

  return Math.round(
    parseFloat(topPadding) +
    parseFloat(bottomPadding))
}


export function calculateHorizontalPaddingForElement (node: HTMLElement) {
  let leftPadding  = getStyle(node, 'padding-left')
  let rightPadding = getStyle(node, 'padding-right')

  return Math.round(
    parseFloat(leftPadding) +
    parseFloat(rightPadding))
}


export function getDocumentLineHeight () {
  const height = getStyle(document.body, 'line-height')
  return Math.round(parseFloat(height))
}

export function onDidResizeWindow (callback: Function) {
  const fn = throttle(callback, null)
  window.addEventListener('resize', fn)
  const disposalAction = () =>
    window.removeEventListener('resize', fn)
  return new Disposable(disposalAction)
}

export function observeWindowSizeChanges (callback: Function) {
  callback(null)
  return onDidResizeWindow(callback)
}

export function observeElementSizeChange (callback: Function, element: HTMLElement) {
  const disposableAction = new ResizeSensor(element, callback)
  return new Disposable(disposableAction)
}

export function isMobile () {
  return window.innerWidth < global.breakpoint.mobile
}

/**
 * Get the font size for the document. This can be used to calculate rem to pixels
 * @return the documents font size
 */
export function getDocumentFontSize () {
  return parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function remToPixels (rem: number): number {
  return rem * getDocumentFontSize()
}
