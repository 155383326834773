// @flow
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { lastActionMiddleware } from './middlewares'

import rootReducer from '../reducers'

import type { StateType } from './initialState'

export default function configureStore(initialState: StateType) {
  const reducer = rootReducer
  const middleware = [thunk, lastActionMiddleware]
  const enhancers = [applyMiddleware(...middleware)]
  const enhancer = compose(...enhancers)
  const store = createStore(reducer, initialState, enhancer)

  return store
}
