import React from 'react'
import is from '@sindresorhus/is'
import 'regenerator-runtime/runtime.js'

const breakpoint = {
  mobile: 768,
  tablet: 1000,
}

const tooltips = {
  delete: 'Delete'
}

Object.assign(global, {
  is,
  tooltips,
  breakpoint,
  whitespace: <span>&nbsp;</span>,
  zE:         undefined,
  nan:        'nan',
  xs:         'xs',
  sm:         'sm',
  md:         'md',
  lg:         'lg',
  xl:         'xl',
})
