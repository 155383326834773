/**
 * @flow
 * @class EditorView
 */

import self from 'autobind-decorator'
import React, { PureComponent, Fragment } from 'react'
import { Emitter } from 'event-kit'
import { List } from 'immutable'
import Symbol from 'es6-symbol'
import classNames from 'classnames'

import connect from 'bound-connect'
import Icon from 'components/icons'
import { RegularButton } from 'components/buttons'
import actions from 'former-ui/actions'

import context from '../editor/EditorContext'
import FieldPreview from '../components/FieldPreview'
import FieldEditor from '../components/FieldEditor'
import Block, {
  FieldBlock,
  HeadingBlock,
  TextBlock,
  IndicatorBlock,
} from '../editor/Block'
import { FIELD_TYPES } from '../fields'
import draggable from '../components/draggable'
import { confirmation } from 'components/generic'
import Text from 'components/text'
import { isEqual } from 'utils/resolve'
import { getLastAction } from 'store/middlewares'

import { REMOVE_BLOCK } from '../actions'

import type { Element } from 'react'
import type { BlockKindType, BlockType, BlockRecordType } from '../editor/Block'

import './EditorView.less'
import { __ } from 'utils/gettext'

const selectedField = Symbol('selected-field')
const signal = Symbol('signal')

type EditorViewProps = {
  editor: Object,
  blocks: List<*>,
  selectedBlock: string | number | null,
  disabled?: boolean,
} & actions

type EditorViewState = {
  typeDropdownVisible: boolean,
  errors: Map<*, *>,
}

interface WithSelectedField {
  [key: Symbol]: any;
}

type FieldKeysType = $Keys<BlockType>

type FieldErrorsType = Array<Error>

type FieldProps = {
  block: BlockRecordType,
  errors: {
    [name: FieldKeysType]: Array<Error>,
  } | null,
  isSelected: boolean,
  disabled?: boolean,
  duplicate: Function,
  deselect: Function,
  select: Function,
  update: Function,
  remove: Function,
  move: Function,
}

export const getEditor = (state) => state.forms.editor

export const getElements = (state) => getEditor(state).get('blocks')

export const getSelectedElement = (state) =>
  getEditor(state).get('selectedBlock')

const reduceEditorAction = (dispatch) => (res, name) =>
  Object.assign(res, { [name]: (...args) => dispatch(actions[name](...args)) })

const generateEditorActions = (dispatch: Function) =>
  Object.keys(actions).reduce(reduceEditorAction(dispatch), {})

@connect
export default class EditorView
  extends PureComponent<EditorViewProps, EditorViewState>
  implements WithSelectedField {
  static ELEMENT_NAMES: Object = {
    COMPUTED: 'Indicator',
    HEADING: 'Heading',
    FIELD: 'Field',
    TEXT: 'Instruction text',
  }

  $key: typeof selectedField
  $value: Element<typeof FormField> | null

  $key: typeof signal
  $value: signal

  state: EditorViewState = {
    typeDropdownVisible: false,
    errors: new Map(),
  }

  _selectedFieldRef = null

  static properties: Object = (state: { forms: { editor: Object } }) => ({
    blocks: getElements(state),
    selectedBlock: getSelectedElement(state),
    color: state.tenantSettings.color,
  })

  static actions = generateEditorActions

  get blocks(): Array<BlockRecordType> {
    return this.props.blocks.toArray()
  }

  get selectedBlock(): string | number | null {
    return this.props.selectedBlock
  }

  UNSAFE_componentWillMount() {
    context.load()
  }

  componentDidMount() {
    this[signal] = new Emitter()
  }

  componentWillUnmount() {
    this[signal].dispose()
    this.props.clear()

    document.removeEventListener('click', this.closeAddFieldDropdown)
  }

  componentDidUpdate(previous: EditorViewProps) {
    const next = this.selectedBlock
    const prev = previous.selectedBlock

    if (prev !== next) {
      if (next === null) this[signal].emit('did-close-block', prev)
      else this[signal].emit('did-open-block', next)
    }
    if (!next) this._selectedFieldRef = null

    switch (this.state.typeDropdownVisible) {
      case true:
        document.addEventListener('click', this.closeAddFieldDropdown)
        break
      case false:
        document.removeEventListener('click', this.closeAddFieldDropdown)
        break
    }

    this.parseErrors(this.props.errors)
  }

  //eslint-disable-next-line complexity
  parseErrors(errorsList: *) {
    if (!errorsList && [...this.state.errors.values()].length) {
      this.setState({ errors: new Map() })
    } else if (
      errorsList &&
      !isEqual(errorsList, [...this.state.errors.values()])
    ) {
      const errors = new Map(
        errorsList.map((error, index) => [this.blocks[index].id, error])
      )
      this.setState({ errors })
    }
  }
  // $FlowIgnore
  appendField = () =>
    this.props.appendBlock(
      new Block({ id: (Math.random() * 100000).toString(32) })
    )
  // $FlowIgnore
  moveField = (from: number, to: number) => this.props.moveBlock(from, to)
  // $FlowIgnore
  removeField = (field: BlockRecordType) => {
    confirmation(
      'Remove field',
      'Are you sure you wish to remove this field?',
      this.props.color
    ).then(
      () => {
        if (field) {
          this.props.removeBlock(field)
          if (field.id === this.props.selectedBlock) {
            this.props.clearSelection()
          }
        }
      },
      () => {}
    )
  }
  // $FlowIgnore
  toggleSelection = (field: BlockType) =>
    field && this.selectedBlock !== field.id
      ? this.props.selectBlock(field.id)
      : this.props.clearSelection()

  @self clearSelection() {
    this.props.clearSelection()
    this._selectedFieldRef = null
  }

  @self selectField(field: BlockRecordType) {
    return this.props.selectBlock(field.get('id'))
  }

  @self deselectField(field: BlockRecordType) {
    if (field && this.selectedBlock === field.get('id')) this.clearSelection()
  }

  @self closeAddFieldDropdown() {
    if (this.state.typeDropdownVisible) {
      this.setState({
        typeDropdownVisible: false,
      })
    }
  }

  @self toggleAddFieldDropdown(event: SyntheticEvent<*>) {
    event.stopPropagation()
    this.setState({
      typeDropdownVisible: !this.state.typeDropdownVisible,
    })
  }

  get nextIdentifier(): number {
    let identifier = 0
    this.blocks.forEach((block) => {
      let blockIdentifier = block.indicator_identifier
        ? Number.parseInt(block.indicator_identifier.slice(1))
        : 0
      if (blockIdentifier > identifier) identifier = blockIdentifier
    })
    return identifier + 1
  }

  appendNewField(appendCallback: Function) {
    if (this.getSelectedField()) this.clearSelection()
    setTimeout(() => {
      appendCallback()
    }, 0)
  }

  addField(type: BlockKindType) {
    const n = this.nextIdentifier
    const element = new FieldBlock(type, n)

    return () => {
      this.setState({ typeDropdownVisible: false }, () => {
        this.appendNewField(() => this.props.appendBlock(element))
      })
    }
  }

  @self addHeadingElement() {
    this.appendNewField(() => this.props.appendBlock(new HeadingBlock()))
  }

  @self addTextElement() {
    this.appendNewField(() => this.props.appendBlock(new TextBlock()))
  }

  @self addIndicatorElement() {
    this.appendNewField(() => this.props.appendBlock(new IndicatorBlock()))
  }

  @self duplicateField(pos: number, field: BlockRecordType) {
    const json = field.toJSON()
    json.indicator_identifier = `F${this.nextIdentifier}`
    let block = new Block({
      ...json,
      id: generateRandomId(),
    })

    this.props.insertBlock(pos, block)
  }

  @self updateField(field: BlockType) {
    this.setState({ typeDropdownVisible: false }, () =>
      this.props.updateBlock(field)
    )
  }

  async saveSelectedField() {
    if (!this._selectedFieldRef) return true

    let submission = new Promise((resolve) => {
      this[signal].once('did-close-block', resolve)
      this._selectedFieldRef.submit(this._selectedFieldRef._unsavedChanges)
    })

    return await submission
  }

  @self
  getSelectedField() {
    return this._selectedFieldRef
  }

  getErrorsForElementAtIndex(index: number): FieldErrorsType | null {
    if (!this.props.errors) return null

    const fieldErrors = this.props.errors[index]

    return fieldErrors && Object.keys(fieldErrors).length ? fieldErrors : null
  }

  getErrorsForElementWithID(id: string): FieldErrorsType | null {
    if (!this.state.errors) return null

    const fieldErrors = this.state.errors.get(id)

    return fieldErrors && Object.keys(fieldErrors).length ? fieldErrors : null
  }

  render() {
    return (
      <Fragment>
        <section className='fields'>
          {this.blocks.map((field, pos) => {
            const move = (offset) => this.moveField(pos, pos + offset)

            const update = (data) =>
              this.updateField(data.set('id', field.get('id')))

            const selected = this.selectedBlock === field.get('id')
            const onReference = (ref) =>
              selected && ref && (this._selectedFieldRef = ref)

            const errors = this.getErrorsForElementWithID(field.get('id'))

            return (
              <FormField
                key={field.get('id')}
                ref={onReference}
                block={field}
                errors={errors}
                update={update}
                remove={() => this.removeField(field)}
                select={() => this.selectField(field)}
                deselect={() => this.deselectField(field)}
                duplicate={() => this.duplicateField(pos, field)}
                isSelected={selected}
                move={move}
                disabled={this.props.disabled}
              />
            )
          })}
        </section>

        {this.props.disabled ? null : (
          <section className='card toolbox'>
            <nav className='add-field button-group'>
              <div
                className='dropdown-button'
                onClick={this.toggleAddFieldDropdown}
              >
                <RegularButton
                  primary
                  className='toggle'
                  onClick={() => {}}
                  icon={Icon.Add}
                >
                  <span className='text'>
                    <Text>{EditorView.ELEMENT_NAMES.FIELD}</Text>
                  </span>
                  <span className='caret'>
                    <Icon.Caret />
                  </span>
                </RegularButton>

                <ul
                  className={
                    'list ' +
                    (this.state.typeDropdownVisible ? 'open' : 'closed')
                  }
                >
                  {FIELD_TYPES.map((type) => (
                    <li key={type.value} onClick={this.addField(type.value)}>
                      {__(type.display_name)}
                    </li>
                  ))}
                </ul>
              </div>

              <RegularButton
                primary
                onClick={this.addHeadingElement}
                icon={Icon.Add}
              >
                {EditorView.ELEMENT_NAMES.HEADING}
              </RegularButton>

              <RegularButton
                primary
                onClick={this.addTextElement}
                icon={Icon.Add}
              >
                {EditorView.ELEMENT_NAMES.TEXT}
              </RegularButton>

              <RegularButton
                primary
                onClick={this.addIndicatorElement}
                icon={Icon.Add}
              >
                {EditorView.ELEMENT_NAMES.COMPUTED}
              </RegularButton>
            </nav>
          </section>
        )}
      </Fragment>
    )
  }
}

@draggable
class FormField extends PureComponent<FieldProps> {
  _unsavedChanges: ?BlockRecordType

  UNSAFE_componentWillReceiveProps(props) {
    // On field deselected
    if (props.isSelected === false && this.props.isSelected === true) {
      if (this._unsavedChanges) {
        this.submit(this._unsavedChanges)
      }
    }
  }

  componentWillUnmount() {
    if (this._unsavedChanges) {
      this.submit(this._unsavedChanges)
    }
  }

  @self
  update(data: BlockRecordType) {
    if (data.toJSON) data = data.toJSON()
    data = this.cleanData(new Block(data))
    this._unsavedChanges = this.props.block.merge(data)
  }

  @self
  select() {
    this.props.select(this)
  }

  @self
  discard() {
    delete this._unsavedChanges
    this.props.deselect()
  }

  @self
  submit(value: BlockRecordType) {
    if (!value && this._unsavedChanges) value = this._unsavedChanges

    delete this._unsavedChanges
    const lastAction = getLastAction()
    if (lastAction && lastAction.type !== REMOVE_BLOCK) {
      if (value) this.props.update(this.cleanData(value))
      else this.props.deselect()
    }
  }

  @self
  remove() {
    this.props.remove()
  }

  // TODO: Refactor
  // eslint-disable-next-line class-methods-use-this
  cleanData(block: BlockRecordType) {
    if (block.choices)
      return block.set(
        'choices',
        block.choices.filter((choice: string | object) => {
          let val = choice.value ? choice.value : choice

          typeof val === 'string' && val.trim()

          return val ? { value: val, score: choice.score } : val
        })
      )
    return block
  }

  render() {
    const block = this.props.block
    const errors = this.props.errors
    const selected = this.props.isSelected
    const className = classNames('field-block', {
      'has-errors': errors,
      selected,
    })

    return (
      <div ref={(ref) => ref && (this.element = ref)} className={className}>
        {this.props.isSelected ? (
          <FieldEditor
            block={block}
            errors={errors}
            options={context.getOptionsByFieldType(block.type, block.model)}
            onUpdate={this.update}
            onSubmit={this.submit}
            onCancel={this.discard}
            onRemove={this.remove}
          />
        ) : (
          <FieldPreview
            block={block}
            onMove={this.onDragStart}
            onSelect={this.select}
            onRemove={this.remove}
            onDuplicate={this.props.duplicate}
            disabled={this.props.disabled}
          />
        )}
      </div>
    )
  }
}

const generateRandomId = (): string => (Math.random() * 100000).toString(32)
