// @flow
import { List } from 'immutable'
import { ADD_TENANTS, UPDATE_SEARCH, UPDATE_SEARCH_BY_ID, UPDATE_LOCKED_FILTER, UPDATE_COUNTRY_FILTER, UPDATE_SEARCH_BY_SCHEMA_NAME } from 'actions/tenants'
import { LOGOUT } from 'actions/user'

import type { Action } from '.'



export type TenantsState = {
  tenants: List<{ id: any, name: string }>,
  search: string | null,
  searchById: string | null,
  searchBySchemaName: string | null,
  locked: boolean
}


export default function tenants (state: TenantsState, action: Action): TenantsState { // eslint-disable-line complexity
  switch (action.type) {

    case LOGOUT:
      return initialValue

    case ADD_TENANTS:
      let tenants = state.tenants
      for (let tenant of action.payload) {
        let index = tenants.findIndex(item => item.id === tenant.id)

        if (index === -1)
          tenants = tenants.push(tenant)
        else
          tenants = tenants.set(index, tenant)
      }
      return { ...state, tenants }

    case UPDATE_SEARCH:
      return { ...state, search: action.payload }

    case UPDATE_SEARCH_BY_ID:
      return { ...state, searchById: action.payload }

    case UPDATE_SEARCH_BY_SCHEMA_NAME:
      return { ...state, searchBySchemaName: action.payload }

    case UPDATE_LOCKED_FILTER:
      return { ...state, locked: action.payload }

    case UPDATE_COUNTRY_FILTER:
      return { ...state, country: action.payload }

    default:
      return state || initialValue
  }
}

export const initialValue: TenantsState = {
  tenants: new List(),
  search: null,
  searchById: null,
  searchBySchemaName: null,
  locked: false,
  country: null
}
