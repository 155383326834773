// @flow
import React from 'react'
// $FlowIgnore
import { useDispatch } from 'react-redux'
import { Button } from '@nordhealth/react'

import withOnlineBookingModal from '../extra/OnlineBookingModal'

import ErrorPage from 'views/Error/ErrorPage'

import { resetBooking } from 'actions/onlineBooking'

import { __ } from 'utils/gettext'

import type { Node } from 'react'

type Error = {
  error: string,
}

type Props = {
  error: Error[],
  errorString?: string,
}

const BookingErrorView = (props: Props): Node => {
  const dispatch = useDispatch()
  return (
    <div>
      <ErrorPage errorName={__('Booking error')}>
        <div>{handleErrors(props.error)}</div>
        {props.errorString && <div>{props.errorString}</div>}
        <Button onClick={() => dispatch(resetBooking())}>
          {__('Back to start')}
        </Button>
      </ErrorPage>
    </div>
  )
}

const handleErrors = (error) => {
  if (Array.isArray(error)) {
    return error.map((err) => {
      if (typeof err === 'object') {
        return <div key={err.error}>{err.error}</div>
      }
      // $FlowIgnore
      return <div key={err}>{err}</div>
    })
  }
}

export default (withOnlineBookingModal(BookingErrorView): *)
