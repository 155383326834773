// @flow

import React, { PureComponent, Component, Fragment, createRef } from 'react'
import connect from 'bound-connect'
import classNames from 'classnames'
import self from 'autobind-decorator'
import context from 'context'
import api from 'api'

import { logout } from 'actions/user'
import navigation from 'routes/base'
import { isPublicApp } from 'routes/index'
import navigator from 'routes/base'
import { __ } from 'utils/gettext'

import { GROUP_MANAGEMENT, SUBSCRIPTION_TYPE_ONLINE_BOOKING } from 'constants'

import Icon from 'components/icons'
import { Label } from 'components/text'
import Button, { SearchButton } from 'components/buttons'
import Dropdown from 'components/Dropdown'
import CollapsingMenu from 'components/layout/CollapsingMenu'
import ProfilePicture from 'components/Picture'
import trackWidth from 'components/TrackWidthDecorator'
import UserSelectionField from 'views/Page/UserSelectionField'
import { ChoiceField } from 'components/fields'
import SideMenu from './SideMenu'
import NotificationMenu from 'components/NotificationMenu'
import { Toolbar } from 'components/generic'

import User from 'models/User'

import type { ComponentType } from 'react'
import type { StateType } from 'store/initialState'

type NavigationRowItemType = {
  icon: ComponentType<*> | null,
  label: string,
  action: string | Function,
  disabled?: boolean,
  collapsed?: boolean,
  restricted?: boolean,
  allowedPlans?: Array<Number>,
}

type NavigationRowProps = {
  items: Array<NavigationRowItemType>,
  url: string,
  disableMenu?: boolean,
}

type NavigationStateType = {
  smallScreen: boolean,
  visible: boolean,
}

class NavigationRow extends Component<NavigationRowProps> {
  render() {
    //eslint-disable-next-line complexity
    const mapEntry = (item, index) => {
      const label = <Label>{item.label}</Label>
      const disabled = this.props.disableMenu || item.disabled ? true : false
      const collapsed = item.collapsed
      // As for management user group navbar is disabled by default
      // Check is set to avoid confusion and omit unnecessary badge
      const restricted = this.props.disableMenu ? false : item.restricted
      const allowedPlans = item.allowedPlans
      const url = this.props.url || window.location.pathname
      const active =
        (!disabled &&
          typeof item.action === 'string' &&
          url.startsWith(item.action)) ||
        (url === '/' && index === 0)

      const className = classNames({
        active,
        disabled,
      })

      const onClick = !disabled
        ? typeof item.action === 'string'
          ? () => navigation.navigate(item.action)
          : item.action
        : () => {}

      return {
        label,
        onClick,
        props: {
          className,
          disabled,
          collapsed,
          restricted,
          allowedPlans,
        },
      }
    }

    return (
      <CollapsingMenu
        items={this.props.items.map(mapEntry)}
        overflowIndicatorComponent={<Icon.Menu />}
      />
    )
  }
}

@trackWidth
@connect
export default class HeadNavigation extends PureComponent<
  *,
  NavigationStateType
> {
  sidemenu: Function = createRef()
  state: NavigationStateType = {
    smallScreen: this.props.isMobile,
    visible: false,
  }

  static actions: Object = (dispatch: Function) => ({
    logout: () => dispatch(logout()),
  })

  static properties(state: StateType) {
    const client =
      state.client && state.client.id ? new User(state.client) : null
    return {
      user: state.user,
      client: client,
      tenantSettings: state.tenantSettings,
    }
  }

  @self
  componentDidUpdate(prevProps: *) {
    const smallScreen = this.props.isMobile
    if (smallScreen !== this.state.smallScreen) this.setState({ smallScreen })
    if (prevProps.client !== this.props.client) {
      this.setState({ visible: false })
    }
  }

  @self
  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  get className(): string {
    return `user-select-container animate-slide left ${
      !this.state.smallScreen || this.state.visible ? 'visible' : 'hidden'
    }`
  }

  get isOnlineBookingSubscription(): boolean {
    const { profile } = this.props.user
    if (profile) {
      return (
        profile.active_subscription.type === SUBSCRIPTION_TYPE_ONLINE_BOOKING
      )
    }
    return false
  }

  get userName(): string | null {
    if (this.props.user.profile.first_name) {
      const fName = this.props.user.profile.first_name
      const lName = this.state.smallScreen
        ? this.props.user.profile.last_name
        : this.props.user.profile.last_name.slice(0, 1) + '.'
      return `${fName} ${lName}`
    }
    if (this.props.user.email) return this.props.user.email
    return null
  }

  get isLoggedIn(): boolean {
    return (
      !this.props.login &&
      (this.props.user.profile.terms_accepted || isPublicApp())
    )
  }

  // Management has limited access => elements in navbar have to be disabled
  get isManagement(): boolean {
    if (!this.props.user.group) {
      return false
    }
    return this.props.user.group.name === GROUP_MANAGEMENT
  }

  get isLoginPage(): boolean {
    return this.props.login
  }

  get LoggedInIcon(): React$Element<*> {
    return this.isLoginPage ? Icon.LockOpen : Icon.Lock
  }

  @self
  toggleSidemenu() {
    if (this.sidemenu) this.sidemenu.current.toggleOpen()
  }

  @self
  // eslint-disable-next-line class-methods-use-this
  changeLanguage(language: string) {
    api.clearCache()
    localStorage.setItem('language', language)
    api.setHeader('accept-language', language)
    window.location.reload()
  }

  //eslint-disable-next-line complexity
  render(): React$Element<*> {
    return (
      <Fragment>
        {/* Focus anchor when navigating to a different view */}
        <div id='empty' tabIndex={0} style={{ width: 1 }}></div>
        {!this.state.smallScreen && <SkipLink login={this.props.login} />}
        {this.isLoggedIn && this.state.smallScreen && (
          <SideMenu
            ref={this.sidemenu}
            items={this.props.items}
            logo={this.props.tenantSettings.logo}
            userName={this.userName}
          />
        )}
        <nav id='navbar' className='navbar container'>
          <div className='inner'>
            <div style={{ display: 'flex' }}>
              {this.isLoggedIn && this.state.smallScreen && (
                <Button
                  onClick={this.toggleSidemenu}
                  icon={() => <Icon.Menu size={30} />}
                />
              )}

              {this.props.tenantSettings.logo && (
                <span
                  className='logo-container'
                  onClick={() => navigator.navigate('/')}
                >
                  <img
                    src={this.props.tenantSettings.logo}
                    className='logo'
                    alt={__('Go to frontpage')}
                  />
                </span>
              )}

              {this.isLoggedIn && !this.state.smallScreen && (
                <NavigationRow
                  url={this.props.url}
                  items={this.props.items}
                  disableMenu={
                    this.isManagement || this.isOnlineBookingSubscription
                  }
                />
              )}
            </div>

            <div style={{ display: 'flex' }}>
              {!this.isLoggedIn && this.renderLanguageChange()}

              <div className={this.className}>
                {!this.isManagement && this.renderSearch()}
              </div>
              {this.resolveSearchIconVisibility() && (
                <span className='search-icon'>
                  <SearchIcon onClick={this.toggle} color='primary' />
                </span>
              )}
              {this.isLoggedIn && !isPublicApp() && (
                <NotificationMenu isMobile={this.state.smallScreen} />
              )}

              {!this.state.smallScreen
                ? this.renderLock()
                : this.renderLockMobile()}
            </div>
          </div>
        </nav>
      </Fragment>
    )
  }

  resolveSearchIconVisibility(): boolean {
    if (!this.state.smallScreen || !this.isLoggedIn) return false
    if (context.loggedInAsStaff) return true
    const authorizedTo = context.currentUser.profile.authorized_to_users
    return Boolean(Array.isArray(authorizedTo) && authorizedTo.length)
  }

  renderSearch(): React$Element<*> | null | void {
    if (!this.isLoggedIn || isPublicApp()) return null
    const authorizedTo = context.currentUser.profile.authorized_to_users
    if (authorizedTo && authorizedTo.length)
      return (
        <UserSelectionField
          className='user-select head-nav'
          placeholder={__('Act on behalf of')}
          ignoreMinimumQuery
        />
      )
    else if (context.loggedInAsStaff)
      return (
        <UserSelectionField className='user-select head-nav' name='head-nav' />
      )
  }

  renderLanguageChange(): React$Element<*> {
    const language = localStorage.getItem('language')
      ? localStorage.getItem('language')
      : 'fi'
    const choices = [
      {
        value: 'fi',
        display_name: 'Suomeksi',
      },
      {
        value: 'en',
        display_name: 'In English',
      },
      {
        value: 'sv',
        display_name: 'På Svenska',
      },
      {
        value: 'no',
        display_name: 'På norsk',
      }
    ]

    return (
      <Toolbar right>
        <div className={'language-select-container'}>
          {!this.state.smallScreen && (
            <Label style={{ paddingRight: '10px' }}>Language</Label>
          )}
          <ChoiceField
            ariaLabel={__('Choose language')}
            choices={choices}
            onChange={(value) => this.changeLanguage(value)}
            value={language}
          />
        </div>
      </Toolbar>
    )
  }

  //eslint-disable-next-line complexity
  renderLock(): React$Element<*> {
    const ToggleIcon = this.state.smallScreen ? Icon.User : Icon.Caret

    const items = [
      {
        icon: Icon.Logout,
        label: 'Logout',
        onClick: this.props.logout,
      },
    ]

    if (!isPublicApp()) {
      const managementItem = {
        icon: Icon.Management,
        label: 'Management',
        divider: true,
        onClick: () => navigation.navigate('/management/'),
      }

      items.splice(
        0,
        0,
        {
          label: this.props.user.profile.full_name,
          divider: true,
          unclickable: true,
          notranslate: true,
        },
        {
          icon: Icon.User,
          label: 'My profile',
          onClick: () => navigation.navigate('/user/'),
        }
      )

      if (context.loggedInAsStaff) items.splice(2, 0, managementItem)
    }

    return this.isLoginPage ? (
      <this.LoggedInIcon size={15} color='#757575' />
    ) : (
      <Dropdown
        items={items}
        disabled={!this.isLoggedIn}
        fixed
        isLoginMenu
        className='login-dropdown'
        toggleComponent={
          <Fragment>
            <ProfilePicture
              className={'profile-picture'}
              image={this.props.user.profile.picture}
              small
              circle
            />
            {this.isLoggedIn && (
              <div className='rotate'>
                <ToggleIcon size={10} />
              </div>
            )}
          </Fragment>
        }
      />
    )
  }

  renderLockMobile(): React$Element<*> {
    return this.isLoginPage ? (
      <this.LoggedInIcon size={15} color='#757575' />
    ) : (
      <this.LoggedInIcon size={15} />
    )
  }
}

function SearchIcon(props: Object) {
  return <SearchButton onClick={props.onClick} />
}

function SkipLink(props: Object) {
  const className = props.login ? 'skip-link login' : 'skip-link'
  const id = props.login ? '#login' : '#page-contents'
  return (
    <a id='skipper' href={id} className={className} tabIndex={0}>
      {__('Skip to content')}
    </a>
  )
}
