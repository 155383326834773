/**
 * @flow
 * @class SingleChoiceField
 */

import classNames from 'classnames'
import React from 'react'
import Field  from './Field'
import { Radio } from '../../react-toggle-components'
import self from 'autobind-decorator'

import { clean } from './MultipleChoiceField'

type ValueType = number | string | null

type OptionType = {
  value: number,
  label: string,
  score: number,
}

type SingleChoiceFieldProps = {
  name: string,
  value?: number,
  choices?: Array<*>,
  required: boolean,
  initial?: number
}

type StateType = {
  value: ValueType,
  choices: Array<OptionType>,
  removingSelection: boolean,
}


export default class SingleChoiceField extends Field<SingleChoiceFieldProps, StateType> {

  static defaultValue: null = null

  state = {
    value: null,
    choices: [],
    removingSelection: false,
  }

  constructor (props: SingleChoiceFieldProps) {
    super(props)
    this.state = {
      ...this.state,
      choices: [],
    }
  }

  @self
  removeSelection (choice: OptionType, event: *) {
    if (this.state.value === choice.value && !this.state.removingSelection) {
      event.stopPropagation()
      this.setState({ removingSelection: true }, () => {
        this.update(null, this.props.name, 'singleChoice')
      })
    }
  }

  renderFormControl (): React$Element<*> {
    const horizontal = this.getOptionValue('horizontal')
    const className  = classNames('options', 'single', { horizontal })

    return <ul className={ className }>
      { this.state.choices.map(choice => {
        if (!this.props.required && (choice.value === null || choice.value === ''))
          return null

        const checked  = choice.value === this.state.value
        const onChange = () => {
          if (!this.state.removingSelection)
            this.update(choice.value, this.props.name, 'singleChoice')
          else
            this.setState({ removingSelection: false })
        }

        return <li
          key={ choice.value }
          aria-checked={ checked }
          className='option focusable'
          tabIndex={ this.disabled ? undefined : 0 }
          onClick={ onChange }
          onKeyPress={ onChange }>
          <Radio
            type='radio'
            name={ 'choice-' + choice.value }
            checked={ checked }
            onChange={ onChange }
            disabled={ this.disabled }
          />
          <label htmlFor={ 'choice-' + choice.value } className='value'>{ choice.label }</label>
        </li>
      })}
    </ul>
  }

  updateState (props: SingleChoiceFieldProps) {
    // If value is not in props, get the initial value from sessionStorage
    const initial = this.getInitialValue('singleChoice')
    let updates = {}

    if ('value' in props)
      updates.value = props.value
    else if (initial)
      updates.value = parseInt(initial)
    else if (!updates.value && props.initial)
      updates.value = props.initial

    if ('choices' in props)
      updates.choices = clean(props.choices)

    this.setState(updates)
  }
}
