// @flow
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'
import AuthorizedRoute from './base/AuthorizedRoute'
import { GROUP_LEVEL_ADMIN, SUBSCRIPTION_TYPE_PRO } from 'constants'

import type { ComponentType, Node } from 'react'

const PreviewFormView = load(() =>
  import('../views/Management/Forms/PreviewForm'))

const ComposeFormView = load(() =>
  import('../views/Management/Forms/ComposeForm'))

const FormsView = load(() =>
  import('../views/Management/Forms/Forms'))

const ExercisesView = load(() =>
  import('../views/Management/Exercises/BaseView'))

const CourseView = load(() =>
  import('../views/Management/Courses/CourseView'))

const CoursesView = load(() =>
  import('../views/Management/Courses/ListCourses'))

const ComposeCourseView = load(() =>
  import('../views/Management/Courses/ComposeCourse'))

const ComposeUserView = load(() =>
  import('../views/Management/Users/ComposeUser'))

const ClientSettings = load(() =>
  import('../views/Management/Users/ClientSettings'))

const ComposeTagView = load(() =>
  import('../views/Public/Tags/ComposeTag'))

const TagsView = load(() =>
  import('../views/Public/Tags/ListTags'))

const TenantSettingsView = load(() =>
  import('../views/Management/Settings/Settings'))

const ManagementHome = load(() =>
  import('../views/Management/Home'))

const BaseView = load(() =>
  import('../views/Management/Exercises/BaseView'))

const ComposeExercise = load(() =>
  import('../views/Management/Exercises/ComposeExercise'))

const ComposeCollection = load(() =>
  import('../views/Management/Exercises/ComposeCollection'))

const PackagesView = load(() =>
  import('../views/Management/Packages/ListPackages'))

const ComposePackage = load(() =>
  import('../views/Management/Packages/ComposePackage'))

const UserMassImport = load(() =>
  import('../views/Management/Users/UserMassImport'))

const ClientsListView = load(() =>
  import('../views/Management/Users/ClientsList'))

const StaffListView = load(() =>
  import('../views/Management/Users/StaffList'))

const ContentSettingsView = load(() =>
  import('../views/Management/Content/ContentSettings'))

const ListExercisesView = load(() =>
  import('../views/Management/Exercises/ListExercises'))

const ListCollectionsView = load(() =>
  import('../views/Management/Exercises/ListCollections'))

const ReportingView = load(() =>
  import('../views/Management/Reporting/Reporting'))

const LogsView = load(() =>
  import('../views/Management/Logs/Logs'))

const ListClientGroupsView = load(() =>
  import('../views/Management/Users/ListClientGroups'))

const ClientGroupView = load(() =>
  import('../views/Management/Users/ClientGroupView'))

const ComposeClientGroupView = load(() =>
  import('../views/Management/Users/ComposeClientGroup'))


export const descriptors = {
  index:                                 '/management/',

  // Management / Clients
  user: (pk: ID): string =>              `/management/clients/clients/${pk}`,
  clients:                               '/management/clients/',
  createUser:                            '/management/clients/clients/create/',
  listClients:                           '/management/clients/clients/',
  userMassImport:                        '/management/clients/import/',

  // Management / Client Groups
  clientGroups:                          '/management/clients/groups/',
  editClientGroup: (pk: ID): string =>   `/management/clients/groups/${pk}`,
  createClientGroup:                     '/management/clients/groups/create/',

  // Settings root
  settings:                              '/management/settings/',

  // Settings / Staff
  staffUser: (pk: ID): string =>         `/management/settings/staff/${pk}`,
  createStaff:                           '/management/settings/staff/create/',
  listStaff:                             '/management/settings/staff/',

  // Settings / Tags
  tags:                                  '/management/settings/tags/',
  tag: (pk: ID): string =>               `/management/settings/tags/${pk}`,
  createTag:                             '/management/settings/tags/create/',

  // Settings / General Settings
  tenantSettings:                        '/management/settings/general/',

  // Settings / Subscripiton
  subscription:                          '/management/settings/subscription/',

  // Content / Base
  content:                               '/management/content/',

  // Content / Forms
  forms:                                 '/management/content/forms/',
  form: (pk: ID): string =>              `/management/content/forms/${pk}`,
  previewForm: (pk: ID): string =>       `/management/content/forms/${pk}/preview/`,
  copyForm: (pk: ID): string =>          `/management/content/forms/${pk}/copy/`,
  createForm:                            '/management/content/forms/create/',

  // Content / Exercises
  exercises:                             '/management/content/exercises/',
  listExercises:                         '/management/content/exercises/exercises/',
  createExercise:                        '/management/content/exercises/exercises/create/',
  exercise: (pk: ID): string =>          `/management/content/exercises/exercises/${pk}`,
  collections:                           '/management/content/exercises/collections/',
  listCollections:                       '/management/content/exercises/collections/',
  createCollection:                      '/management/content/exercises/collections/create/',
  collection: (pk: ID): string =>        `/management/content/exercises/collections/${pk}`,

  // Content / Documents
  documentBank:                          '/management/content/documents/',

  // Content / Courses
  courses:                               '/management/content/courses/',
  courseDetails: (pk: ID): string =>     `/management/content/courses/${pk}/details/`,
  createCourse:                          '/management/content/courses/create/',

  // Content / Packages
  packages:                              '/management/content/packages/',
  package: (pk: ID): string =>           `/management/content/packages/${pk}/`,
  createPackage:                         '/management/content/packages/create/',

  // Reporting
  reporting:                             '/management/reports/',
  reportTool:                            '/management/reports/report-tool/',

  // Logs
  logs:                                  '/management/logs/',
  secureAuthenticationLogs:              '/management/logs/secure-authentication/',

  // API

  // tags
  updateTag: (pk: ID): string =>  `/tags/${pk}`,

  // exercise parts
  exerciseParts:     (exercise: ID): string           => `/exercises/${exercise}/parts/`,
  exercisePart:      (exercise: ID, part: ID): string => `/exercises/${exercise}/parts/${part}/`,
  exercisePartFile:  (exercise: ID, part: ID): string => `/exercises/${exercise}/parts/${part}/file/`,
  exercisePartOrder: (exercise: ID, part: ID): string => `/exercises/${exercise}/parts/${part}/order/`
}

type ViewPropertiesType = {
  fullWidth?: boolean,
  restricted?: boolean,
}

export type ViewType = {
  key: string,
  url: string,
  label: string,
  component: ComponentType<*>,
  groups?: Array<String>,
  plans?: Array<Number>,
  parent?: string,
  properties?: ViewPropertiesType,
  exact?: boolean,
}

export const CoursesLabel = 'Courses'


class ViewCollection {

  views: Array<ViewType>

  constructor (...views: Array<ViewType>) {
    this.views = views
  }

  map<R> (fn: ViewType => R): Array<R> {
    return this.views.map(fn)
  }

  get (key: string): ?ViewType {
    return this.views.find(item => item.key === key)
  }

  get rootViews (): Array<ViewType> {
    return this.views.filter(view => !view.parent)
  }

  get routes (): Array<Node> {
    return this.map(view => {
      const RouteComponent = view.groups || view.plans ? AuthorizedRoute : Route
      const exact = view.exact ? true : false
      return <RouteComponent
        allowedGroups={ view.groups }
        allowedPlans={ view.plans }
        key={ view.key }
        path={ view.url }
        component={ view.component }
        exact={exact}
      />
    })
  }

  toJSON (): Object {
    return this.rootViews.map(view => ({
      url: view.url,
      label: view.label,
      groups: view.groups,
      plans: view.plans,
      properties: view.properties,
    }))
  }

}


export const views: ViewCollection = new ViewCollection(
  {
    key:        'clients',
    url:        descriptors.clients,
    label:      'Clients',
    component:  ClientSettings,
  },

  {
    key:        'settings',
    url:        descriptors.settings,
    label:      'Settings',
    component:  TenantSettingsView,
  },

  {
    key:        'content',
    url:        descriptors.content,
    label:      'Content',
    component:  ContentSettingsView
  },

  {
    key:        'reporting',
    url:        descriptors.reporting,
    label:      'Reporting',
    component:  ReportingView,
    plans:      [ SUBSCRIPTION_TYPE_PRO ],
    properties: { restricted: true },
    groups:     GROUP_LEVEL_ADMIN
  },

  {
    key:        'logs',
    url:        descriptors.logs,
    label:      'Logs',
    component:  LogsView,
    groups:     GROUP_LEVEL_ADMIN
  }
)

export const tagViews: ViewCollection = new ViewCollection(
  {
    key:        'edit-tag',
    parent:     'tags',
    url:        descriptors.tag(':id(\\d+)'),
    label:      'Edit tag',
    component:  ComposeTagView,
  },
  {
    key:        'create-tag',
    parent:     'tags',
    url:        descriptors.createTag,
    label:      'Add tag',
    component:  ComposeTagView,
  },
  {
    key:        'tags',
    parent:     'tags',
    url:        descriptors.tags,
    label:      'Tags',
    component:  TagsView,
  },
)

export const clientViews: ViewCollection = new ViewCollection(
  {
    key:        'edit-user',
    parent:     'clients',
    url:        descriptors.user(':id(\\d+)'),
    label:      'Edit user',
    component:  ComposeUserView,
    exact:      true
  },
  {
    key:        'create-user',
    parent:     'clients',
    url:        descriptors.createUser,
    label:      'Add user',
    component:  ComposeUserView,
  },
  {
    key:        'client-import',
    parent:     'clients',
    url:        descriptors.userMassImport,
    label:      'Clients',
    component:  UserMassImport,
  },
  {
    key:        'list-clients',
    parent:     'clients',
    url:        descriptors.listClients,
    label:      'List clients',
    component:  ClientsListView,
    exact:      true
  },
)

export const clientGroupViews: ViewCollection = new ViewCollection(
  {
    key:        'client-group-details',
    url:        descriptors.editClientGroup(':id(\\d+)'),
    label:      'Client group details',
    component:  ClientGroupView,
  },
  {
    key:        'client-group-create',
    url:        descriptors.createClientGroup,
    label:      'New client group',
    component:  ComposeClientGroupView,
  },
  {
    key:        'client-groups',
    url:        descriptors.clientGroups,
    label:      'Client groups',
    component:  ListClientGroupsView,
  }
)

export const staffViews: ViewCollection = new ViewCollection(
  {
    key:        'edit-user',
    url:        descriptors.staffUser(':id(\\d+)'),
    label:      'Edit user',
    component:  ComposeUserView,
  },
  {
    key:        'create-user',
    url:        descriptors.createStaff,
    label:      'Add user',
    component:  ComposeUserView,
  },
  {
    key:        'list-staff',
    url:        descriptors.listStaff,
    label:      'List staff',
    component:  StaffListView,
  },

)

export const formViews: ViewCollection = new ViewCollection(
  {
    key:        'create-form',
    parent:     'forms',
    url:        descriptors.createForm,
    label:      'Add form',
    component:  ComposeFormView,
    properties: { fullWidth: true }
  },

  {
    key:        'preview-form',
    parent:     'forms',
    url:        descriptors.previewForm(':id(\\d+)'),
    label:      'Preview form',
    component:  PreviewFormView,
  },

  {
    key:        'copy-form',
    parent:     'forms',
    url:        descriptors.copyForm(':id(\\d+)'),
    label:      'Preview form',
    component:  ComposeFormView,
  },

  {
    key:        'edit-form',
    parent:     'forms',
    url:        descriptors.form(':id(\\d+)'),
    label:      'Edit form',
    component:  ComposeFormView,
    groups:     GROUP_LEVEL_ADMIN,
  },
  {
    key:        'forms',
    url:        descriptors.forms,
    label:      'Forms',
    component:  FormsView,
    properties: { fullWidth: true }
  },
)

export const exerciseViews: ViewCollection = new ViewCollection(
  {
    key:        'create-exercise',
    url:        descriptors.createExercise,
    label:      'Add exercise',
    component:  ComposeExercise,
  },
  {
    key:        'edit-exercise',
    url:        descriptors.exercise(':id(\\d+)'),
    label:      'Edit exercise',
    component:  ComposeExercise,
  },
  {
    key:        'exercises',
    url:        descriptors.exercises,
    label:      'Exercises',
    component:  ExercisesView,
  }
)

export const exerciseCollectionViews: ViewCollection = new ViewCollection(
  {
    key:        'edit-collection',
    url:        descriptors.collection(':id(\\d+)'),
    label:      'Edit collection',
    component:  ComposeCollection,
  },

  {
    key:        'create-collection',
    url:        descriptors.createCollection,
    label:      'Add collection',
    component:  ComposeCollection,
  },
  {
    key:        'list-collections',
    url:        descriptors.listCollections,
    label:      'Collections',
    component:  ListCollectionsView,
  },
  {
    key:        'collections',
    url:        descriptors.collections,
    label:      'Exercises',
    component:  BaseView,
  }
)

export const courseViews: ViewCollection = new ViewCollection(
  {
    key:        'course-details',
    parent:     'course',
    url:        descriptors.courseDetails(':id(\\d+)'),
    label:      'Course details',
    component:  CourseView,
  },

  {
    key:        'course-create',
    parent:     'course',
    url:        descriptors.createCourse,
    label:      'New course',
    component:  ComposeCourseView,
  },

  {
    key:        'courses',
    url:        descriptors.courses,
    label:      CoursesLabel,
    component:  CoursesView,
    properties: { fullWidth: true }
  },
)

export const packageViews: ViewCollection = new ViewCollection(
  {
    key:        'create-package',
    parent:     'packages',
    url:        descriptors.createPackage,
    label:      'New Package',
    component:  ComposePackage,
  },

  {
    key:        'edit-package',
    parent:     'packages',
    url:        descriptors.package(':id(\\d+)'),
    label:      'Edit Package',
    component:  ComposePackage,
  },
  {
    key:        'packages',
    url:        descriptors.packages,
    label:      'Packages',
    component:  PackagesView,
  },
)

export default <Switch>
  <Route path={ descriptors.index } component={ ManagementHome } />
  <RouteNotFound />
</Switch>

export const MenuRoutes: React$Element<*> = <Switch>
  <Route exact path='/management/'>
    <Redirect to={ descriptors.clients } />
  </Route>
  { views.routes }
  <RouteNotFound />
</Switch>

export const ClientRoutes: React$Element<*> = <Switch>
  { clientViews.routes }
  <RouteNotFound />
</Switch>

export const ClientGroupRoutes: React$Element<*> = <Switch>
  { clientGroupViews.routes }
  <RouteNotFound />
</Switch>

export const StaffRoutes: React$Element<*> = <Switch>
  { staffViews.routes }
  <RouteNotFound />
</Switch>

export const TagRoutes: React$Element<*> = <Switch>
  { tagViews.routes }
  <RouteNotFound />
</Switch>

export const ExerciseRoutes: React$Element<*> = <Switch>
  { exerciseViews.routes }
  <RouteNotFound />
</Switch>

export const ExerciseCollectionRoutes: React$Element<*> = <Switch>
  { exerciseCollectionViews.routes }
  <RouteNotFound />
</Switch>

export const FormRoutes: React$Element<*> = <Switch>
  { formViews.routes }
  <RouteNotFound />
</Switch>

export const CourseRoutes: React$Element<*> = <Switch>
  { courseViews.routes }
  <RouteNotFound />
</Switch>

export const PackageRoutes: React$Element<*> = <Switch>
  { packageViews.routes }
  <RouteNotFound />
</Switch>