/**
 * @flow
 * @class TextInputField
 */
import React from 'react'
import InputField  from './InputField'
import PreviewField from './TextInputPreview'
import MultilineTextInputField from '../components/DynamicHeightInput'

export type ValueType = string | null

export type OptionsType = {
  multiline: boolean,
}


export default class StringInputField extends InputField<ValueType, OptionsType> {

  static defaultValue: string = ''
  type: string = 'text'

  renderFormControl (): React$Element<*> {
    if (this.props.disabled)
      return <PreviewField
        value={ this.state.value }
        className={ this.getOptionValue('multiline') && 'multiline' }/>
    if (this.getOptionValue('multiline'))
      return this.renderMultilineInput()
    return this.renderSingleLineInput()
  }

  renderMultilineInput (): React$Element<*> {
    return <MultilineTextInputField
      id={ this.name }
      value={ this.state.value }
      onChange={ this.update }
      className='full-width'
      disabled={ this.disabled }
      required={ this.props.required }
    />
  }

  renderSingleLineInput (): React$Element<*> {
    const type = this.props.name === 'username' || this.props.name === 'email'
      ? 'email'
      : this.type
    return <input
      aria-required={ this.props.required ? 'true' : undefined }
      id={ this.name }
      name={ this.name }
      type={ type }
      value={ this.state.value }
      onChange={ this.onChange }
      maxLength={ this.props.max_length }
      { ...this.attributes }
      disabled={ this.disabled }
    />
  }
}
