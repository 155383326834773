// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeCourseView = load(() =>
  import('../../../views/Public/Courses/ComposeCourse'))

const CoursesView = load(() =>
  import('../../../views/Public/Courses/ListCourses'))

const CourseStaffView = load(() =>
  import('../../../views/Public/Courses/CourseStaffList'))


export const descriptors = {
  Courses:                                      `/courses/`,
  Course: (pk: ID) =>                           `/courses/${pk}`,
  createCourse:                                 `/courses/create/`,
  courseStaff: (tenantId: ID, pk: ID) =>        `/courses/${pk}/course-staff/tenant/${tenantId}/`
}


export default <Switch>
  <Route path={ descriptors.createCourse } component={ ComposeCourseView } />
  <Route path={ descriptors.courseStaff(':tenantId(\\d+)', ':id(\\d+)') } component={CourseStaffView} />
  <Route path={ descriptors.Course(':id(\\d+)') } render={ props => <ComposeCourseView key={ props.match.params.id } { ...props } /> } />
  <Route exact path={ descriptors.Courses } component={ CoursesView } />
  <RouteNotFound />
</Switch>
