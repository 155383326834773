// @flow
import { Map, OrderedSet } from 'immutable'

import reducer from './reducer'
import serialize from './serializer'
import Editor from './views'
import DynamicHeightInput from './components/DynamicHeightInput'
import WithTooltip, { ButtonWithTooltip } from './components/WithTooltip'
import Field from './components/FieldDisplay'

const state = Map({
  tags:               new OrderedSet(),
  links:              new OrderedSet(),
  blocks:             new OrderedSet(),
  attachments_staff:  new OrderedSet(),
  attachments_client: new OrderedSet(),
  selectedBlock:  null,
  details:        {
    id:           null,
    name:         '',
    status:       null,
    description:  '',
  },
})

export {
  Editor,
  Field,
  reducer,
  state,
  serialize,
  WithTooltip,
  ButtonWithTooltip,
  DynamicHeightInput,
}

export default module.exports
