/**
 * @namespace src.App
 * @module    App
 * @summary   Application component
 * @flow
 */

import '@nordhealth/components'
import '@nordhealth/css'
import is from '@sindresorhus/is'
import { getAnnouncements, getNotifications } from 'actions/core'
import { updateTenantSettings } from 'actions/tenant'
import { login, logout, logoutWithoutRedirect } from 'actions/user'
import api from 'api'
import announcements from 'api/announcements'
import auth from 'api/auth'
import notifications from 'api/notifications'
import self from 'autobind-decorator'
import connect from 'bound-connect'
import classNames from 'classnames'
import Color from 'color'
import Icon from 'components/icons'
import context from 'context'
import { CompositeDisposable, Disposable } from 'event-kit'
import React, { Component, Fragment, PureComponent } from 'react'
import TagManager from 'react-gtm-module'
import { Route } from 'react-router-dom'
import Router from 'routes/base/Router'
import routes, { isPublicApp } from 'routes/index'
import { setLocale } from 'utils/gettext'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  AttachmentField,
  ChoiceField,
  DateTimeLocalField,
  MultipleChoiceField,
  PasswordField,
  SingleToggleField,
  SliderToggleField,
  StringField,
} from 'components/fields'
import {
  COUNTRY,
  DEFAULT_PRIMARY_COLOR,
  LOGOUT_EVENT_KEY,
  STORAGE_LANGUAGE,
} from 'constants'
import { clearRenderers, registerRenderer } from 'fieldsets'
import type { StateType } from 'store/initialState'
import { subscribeToGlobalEvent } from 'utils/events'

import './App.css'

type AppProperties = RouteProps & {
  onDidUpdateToken: (string) => void,
  userEmail: string,
  userName: string,
  logout: Function,
  loginRequired?: boolean,
  tenantSettings: Object,
  logoutWithoutRedirect: Function,
  updateTenantSettings: Function,
  getAnnouncements: Function,
  getNotifications: Function,
  user: Object,
}

type AppStateType = {
  loaded: boolean,
}

/**
 * The main application component that contains the UI.
 * @class App
 */
@connect // eslint-disable-line
export default class App extends PureComponent<AppProperties, AppStateType> {
  static properties: Object = (state: StateType) => ({
    loginRequired: state.user.loginRequired,
    tenantSettings: state.tenantSettings,
    userName: [
      state.user.profile.first_name,
      state.user.profile.last_name,
    ].join(' '),
    userEmail: state.user.email,
    user: state.user,
  })

  static actions: Function = connectActions
  subscriptions: Function = new CompositeDisposable()
  _pendoInitialized: boolean = false

  state: AppStateType = {
    loaded: false,
  }

  render(): React$Element<*> {
    return this.state.loaded ? (
      <Fragment>
        {__DEV__ && <DevTools />}

        <Router>
          <Route path='/' component={routes} />
        </Router>
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={4000}
          hideProgressBar
          toastStyle={{
            backgroundColor: 'rgba(50, 50, 50, .7)',
            color: '#fff',
          }}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          draggable={false}
        />

        {/* <AuthorizedComponent noloader>
          <AppendHead onLoad={ () => this.zendeskIdentify(true) }>
            <script
              name='zendesk'
              id='ze-snippet'
              src='https://static.zdassets.com/ekr/snippet.js?key=62f5e05f-64da-42a0-9f97-657462ecf0a6'></script>
          </AppendHead>
        </AuthorizedComponent>
        <Wootric /> */}
      </Fragment>
    ) : (
      <div></div>
    )
  }

  async componentDidMount() {
    await this.props.onDidUpdateToken(localStorage.getItem('accessToken') || '')
    this.initDev()
    this.initSubscriptions()

    if (!isPublicApp()) await this.initSettings()
    this.tryRedirectToNewFrontend()
    this.setState({ loaded: true })

    this.props.getAnnouncements()
    announcements.startRefreshing(this.props.getAnnouncements)
    notifications.startRefreshing(this.props.getNotifications)

    if (this.props.tenantSettings.gtm_id) {
      TagManager.initialize({
        gtmId: this.props.tenantSettings.gtm_id,
      })
    }
  }

  async componentDidUpdate(oldProps: AppProperties) {
    if (this.props.tenantSettings.color !== oldProps.tenantSettings.color)
      this.updateColor()

    this.zendeskCheck(oldProps)
  }

  @self
  tryRedirectToNewFrontend() {
    const isNorwegianTenant =
      this.props.tenantSettings.country === COUNTRY.NORWAY
    const isNewFrontendForced =
      this.props.tenantSettings.new_frontend_mode === 'force'

    if (
      this.props.tenantSettings &&
      (isNorwegianTenant || isNewFrontendForced)
    ) {
      if (__DEV__) {
        // This is rather a hacky solution to redirect user after login
        // in case the user has tried to access certain url
        const path = location.pathname + location.search
        window.location.href = `http://${window.location.hostname}:3001/new${path}`
      } else {
        const location = window.location
        const path = location.pathname + location.search
        // Pathname is appended for instance if email with password
        // reset link is sent
        const redirectUrl = `${location.hostname}/new${path}`
        window.location.href = `https://${redirectUrl}`
      }
    }
  }

  zendeskCheck(oldProps: AppProperties) {
    if (
      oldProps.userName !== this.props.userName ||
      oldProps.userEmail !== this.props.userEmail
    )
      this.zendeskIdentify()
  }

  // used for preventing the zendesk script being called endlessly
  // when it fails to load.
  identifyCount: number = 0
  maxZendeskLoadTries: number = 20

  @self
  // eslint-disable-next-line
  zendeskIdentify(init?: boolean) {
    const name = this.props.userName
    const email = this.props.userEmail
    const zE = window.zE

    // eslint-disable-next-line no-undef
    if (zE && zE.identify) {
      if (init) zE.hide
      if (context.loggedInAsStaff && name && email) {
        // eslint-disable-next-line no-undef
        zE.identify({
          name: name,
          email: email,
        })
        // eslint-disable-next-line no-undef
      } else {
        /* flow-ignore */
        // eslint-disable-next-line
        zE('webWidget', 'clear')
        // eslint-disable-next-line no-undef
      }
    } else if (this.identifyCount <= this.maxZendeskLoadTries) {
      this.identifyCount++

      // TODO: figure out a way to call zendeskIdentify after the zE is defined
      // without using timeout
      setTimeout(() => this.zendeskIdentify(init), 500)
    }
  }

  initDev() {
    if (__DEV__) {
      window.language = new Proxy(
        {},
        {
          get: (self, lang) => setLocale(lang),
        }
      )

      const unsetLanguageGlobal = new Disposable(() => delete window.language)

      this.subscriptions.add(unsetLanguageGlobal)
    }
  }

  initSubscriptions() {
    const logoutSubscription = subscribeToGlobalEvent(
      LOGOUT_EVENT_KEY,
      this.logout
    )

    const languageChangeSubscription = context.onLanguageDidChange(() =>
      setLocale(context.currentLocale)
    )

    const observeTokenSubscription = auth.observe(this.props.onDidUpdateToken)

    const fieldRenderersSubscription = registerFieldRenderers()

    this.subscriptions.add(
      logoutSubscription,
      fieldRenderersSubscription,
      languageChangeSubscription,
      observeTokenSubscription
    )
  }

  @self
  getColor(): string {
    const settings = this.props.tenantSettings
    let color = DEFAULT_PRIMARY_COLOR
    if (settings && settings.color) color = settings.color
    if (!is.string(color) || !color.match(/^#([a-f0-9]{6})$/i))
      color = DEFAULT_PRIMARY_COLOR
    return color
  }

  @self
  updateColor() {
    const color = this.getColor()
    const colorLight = Color(color).lighten(0.1)
    const colorDark = Color(color).darken(0.1)
    const root = document.getElementById('root')
    if (root) {
      root.style.setProperty('--color-primary', color)
      root.style.setProperty('--color-primary-light', colorLight.hex())
      root.style.setProperty('--color-primary-dark', colorDark.hex())
    }
    window.zESettings = {
      webWidget: {
        color: {
          theme: color,
          launcherText: '#FFFFFF',
        },
      },
    }
  }

  @self
  //eslint-disable-next-line max-statements
  async initSettings(): Promise<void> {
    try {
      const settings = await api.tenantSettings
      if (!localStorage.getItem(STORAGE_LANGUAGE)) {
        localStorage.setItem(STORAGE_LANGUAGE, settings.language)
        setLocale(settings.language)
      }
      this.props.updateTenantSettings(settings)
      // Set tenant color as the accent color (used in some Design System components)
      const primaryColor = Boolean(settings.color)
        ? settings.color
        : DEFAULT_PRIMARY_COLOR
      document.documentElement.style.setProperty(
        '--n-color-accent',
        primaryColor
      )
    } catch (err) {
      //eslint-disable-next-line no-console
      console.error('Failed to fetch tenant settings:', err)
    }
  }

  componentWillUnmount() {
    this.subscriptions.dispose()
  }

  @self
  logout() {
    if (!this.props.loginRequired) this.props.logoutWithoutRedirect()
  }
}

class DevTools extends Component<{}, { open: boolean }> {
  state = {
    open: false,
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const updateLanguage = ({ target }) =>
      (context.currentLocale = target.textContent)

    const clearCache = api.clearCache

    const { open } = this.state
    const className = classNames('development', 'dev-menu', { open })

    return (
      <aside className={className} aria-hidden='true'>
        <span onClick={this.toggle} className='label'>
          <span className='title'>Development</span>
          <span className='toggle'>
            <Icon.Caret />
          </span>
        </span>

        <main className='tools'>
          <h4>Language</h4>
          <ul className='list plain-list horizontal padding-small'>
            <li className='list-item' onClick={updateLanguage}>
              fi
            </li>
            <li className='list-item' onClick={updateLanguage}>
              sv
            </li>
            <li className='list-item' onClick={updateLanguage}>
              en
            </li>
            <li
              className='list-item'
              onClick={() => {
                throw new Error('error thrown')
              }}
            >
              Error
            </li>
          </ul>

          <h4>Cache</h4>
          <button onClick={clearCache}>Clear</button>
        </main>
      </aside>
    )
  }
}

function connectActions(dispatch: Function) {
  async function onDidUpdateToken(token: string | null) {
    if (process.env.NODE_ENV === 'test') return

    if (token) return dispatch(login())

    return dispatch(logoutWithoutRedirect())
  }

  return {
    onDidUpdateToken,
    logout: () => dispatch(logout()),
    logoutWithoutRedirect: () => dispatch(logoutWithoutRedirect()),
    updateTenantSettings: (settings: Object) =>
      dispatch(updateTenantSettings(settings)),
    getAnnouncements: () => dispatch(getAnnouncements()),
    getNotifications: () => dispatch(getNotifications()),
  }
}

function registerFieldRenderers(): Disposable {
  registerRenderer('string', StringField)
  registerRenderer('date', StringField)
  registerRenderer('datetime', DateTimeLocalField)
  registerRenderer('choice', ChoiceField)
  registerRenderer('slider choice', SliderToggleField)
  registerRenderer('multiple choice', MultipleChoiceField)
  registerRenderer('file upload', AttachmentField)
  registerRenderer('boolean', SingleToggleField)
  registerRenderer('email', StringField)
  registerRenderer('password', PasswordField)

  return new Disposable(() => clearRenderers())
}
