import self from 'autobind-decorator'
import classNames from 'classnames'
import { listen } from 'utils/dom'
import { CompositeDisposable } from 'event-kit'
import React, { PureComponent } from 'react'
import * as selection from './utils'
import { getEditorStateWithAddedSuggestion } from './modifiers'
import { PREFIX } from './constants'
import { getInsertRange } from './utils'


export default class Suggestions extends PureComponent {

  static initialState = {
    selectedIndex: 0,
    searchText:    null,
    entries:       null,
    position:      {
      x: 0,
      y: 0,
    },
  }

  static defaultProps = {
    suggestions: [],
  }

  state = Suggestions.initialState
  elements: Map<string, Element> = new Map()
  currentPosition: { left: number, top: number } = { left: 0, top: 0 }

  keymap = new Map(Object.entries({
    Tab:       this.confirmSelection,
    Enter:     this.confirmSelection,
    ArrowUp:   this.selectPrevious,
    ArrowDown: this.selectNext,
  }))

  @self
  confirmSelection () {
    const index = this.state.selectedIndex
    const entry = this.getEntryByIndex(index)

    if (entry)
      this.handleSelect(entry)
  }

  @self
  selectPrevious () {
    const { entries } = this.state
    if (!entries)
      return

    const lastIndex   = entries.size - 1
    let selectedIndex = this.state.selectedIndex - 1
    if (selectedIndex < 0)
      selectedIndex = lastIndex

    this.scrollTo(selectedIndex)
    this.setState({ selectedIndex })
  }

  @self
  selectNext () {
    const { entries } = this.state
    if (!entries)
      return

    const lastIndex   = entries.size - 1
    let selectedIndex = this.state.selectedIndex + 1
    if (selectedIndex > lastIndex)
      selectedIndex = 0

    this.scrollTo(selectedIndex)
    this.setState({ selectedIndex })
  }

  @self
  scrollTo (selectedIndex: number) {
    const element = this.elements.get(selectedIndex)
    let scrollElement
    if (element && element.parentNode)
      scrollElement = element.parentNode.parentNode
    if (scrollElement &&
        (element.offsetTop > scrollElement.offsetHeight
        || element.offsetTop < scrollElement.scrollTop))
      scrollElement.scrollTop = element.offsetTop
  }

  componentDidMount () {

    const onKeydown = (event) => {
      const shouldCapture = this.visible && this.keymap.has(event.key)
      if (!shouldCapture)
        return

      const action = this.keymap.get(event.key)
      event.preventDefault()
      event.stopImmediatePropagation()
      action()
      return true
    }

    this.subscriptions = new CompositeDisposable()
    this.subscriptions.add(
      this.props.onDidUpdate(this.updateSuggestionsState),
      listen('keydown', onKeydown, document, true)
    )
  }

  componentWillUnmount () {
    this.subscriptions.dispose()
  }

  getMatchingSuggestions (text = null) {
    if (text === null)
      text = this.state.searchText
    if (typeof text !== 'string')
      return null

    const term = text.toLowerCase()
    const filter = item =>  {
      let query = item.label.substr(0, term.length).toLowerCase()
      return query === term
    }

    const results = this.props.suggestions.filter(filter)
    if (results.length === 1 && results[0].length === term.length)
      return null
    return results
  }

  get position () {
    if (this.currentPosition.left === 0 || !this.visible) {
      const co = this.state.position
      if (!co)
        return this.currentPosition = {}
      this.currentPosition = {
        top:  co.y + window.scrollY,
        left: co.x,
      }
    }
    return this.currentPosition
  }

  get visible () {
    return this.state.searchText !== null
  }

  getEntryByIndex (index) {
    const { entries } = this.state
    if (!entries)
      return null
    if (entries.toList)
      return entries.toList().get(index.toString()) || null
    else
      return entries[index] || null
  }

  render () {
    let index       = 0
    let refIndex    = 0
    const visible   = this.visible
    const entries   = this.state.entries
    const className = classNames('overlay', 'suggestions', { visible })

    return <nav
      style={ this.position }
      className={ className }>

      <ol className='suggestions-list'>
        { entries && entries.map((result) => {

          const select    = (event) => this.handleSelect(result, event)
          const selected  = this.state.selectedIndex === index++
          const createRef = ref => ref && this.elements.set(refIndex++, ref)
          const className = classNames({ selected })

          return <li
            key={ result.value }
            className={ className }
            onMouseDown={ select }
            ref={ createRef }>
            { result.label }
          </li>

        })}
      </ol>
    </nav>
  }

  get editorState () {
    return this.props.editorState
  }

  async setEditorState (editorState) {
    await this.props.updateEditorState(editorState)
    await this.updateSuggestionsState()
  }

  async setActiveState (state) {
    if (state === null)
      state = Suggestions.initialState
    return await new Promise(resolve =>
      this.setState(state, resolve))
  }

  @self
  async updateSuggestionsState () {
    const range = selection.getTriggerRange(PREFIX)
    if (!range)
      return await this.setActiveState(null)

    const position      = selection.getCaretCoordinates()
    const searchText    = range.text.slice(PREFIX.length, range.text.length)
    const entries       = this.getMatchingSuggestions(searchText)
    return await this.setActiveState({
      entries,
      position,
      searchText,
    })
  }

  @self
  async handleSelect (entry, event?) {
    if (event)
      event.preventDefault()

    // copy the entry so that the changes made, when nolabel is true, do not affect the original
    const copy = Object.assign({}, entry)
    if (this.props.nolabels) {
      copy.tooltip = copy.label.slice(0)
      copy.label   = copy.value.slice(0)
    }

    let editorState = this.editorState
    const state     = Suggestions.initialState
    const payload   = Object.assign({}, copy, getInsertRange(editorState))
    editorState     = getEditorStateWithAddedSuggestion(editorState, payload)

    await this.setEditorState(editorState)
    await this.setActiveState(state)
    this.props.focus()
  }

}
