
import createError from 'custom-error'
import Symbol from 'es6-symbol'

const errors = Symbol('errors')


export const APIBaseException = createError('APIException')
export const ValidationError = createError('ValidationError', APIBaseException)


export function ErrorResponse (response) {
  return Object.defineProperties(response, {
    valid:  { value: false },
    errors: { value: ValidationErrorSet.create(response) },
  })
}


export function SuccessResponse (response) {
  return Object.defineProperties(response, {
    valid:  { value: true },
    errors: { value: null },
  })
}


class ValidationErrorSet {

  static create (data) {
    return new Proxy(new ValidationErrorSet(data), {
      get: (self, attr) => {
        if (self.constructor.prototype.hasOwnProperty(attr))
          return self[attr].bind(self)
        return self.get(attr)
      }
    })
  }

  constructor (response) {
    this[errors] = recurseErrors(response.data)
  }

  get (...descriptor) {
    let node
    let result = { ...this[errors] }

    while (node = descriptor.shift())
      if (!result[node.toString()])
        return null
      else
        result = result[node.toString()]
    return result
  }

  getAll () {
    let result = { ...this[errors] }
    return Object
      .values(result)
      .reduce((errors, entry) => entry instanceof Array
        ? errors.concat(entry)
        : [ ...errors, entry ], [])
      .filter(err => err)
  }

  toString (...descriptor) {
    if (!descriptor.length)
      descriptor = [ 'non_field_errors' ]

    const errors =
      this.get(...descriptor) ||
      this.getAll()

    if (errors instanceof Array)
      return errors
        .map(error => error.message)
        .join(', ')
    else if (errors instanceof Error)
      return errors.message
    else if (errors)
      return errors.toString()
    return ''
  }

}


const toError = (errorMessage) =>
  new ValidationError(errorMessage)


const recurseErrors = (iteratee) => {
  const errors = {}

  if (iteratee === null)
    return errors

  if (typeof iteratee === 'object') {
    let entries = Object.entries(iteratee)

    for (let [ key, value ] of entries) {
      if (value instanceof Array)
        errors[key] = value.map(recurseErrors)
      else if (typeof value === 'object')
        errors[key] = recurseErrors(value)
      else
        errors[key] = toError(value)
    }
  }

  else
    return toError(iteratee)

  return errors
}
