// @flow
import type { Action } from '../reducers'

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const UPDATE_TAGS = 'UPDATE_TAGS'
export const UPDATE_SEARCH = 'UPDATE_SEARCH'
export const UPDATE_OWN_EXERCISES = 'UPDATE_OWN_EXERCISES'

export const updateCollection = (value: Array<number>): Action => ({
  type: UPDATE_COLLECTION,
  payload: value
})

export const updateTags = (value: Array<number>): Action => ({
  type: UPDATE_TAGS,
  payload: value
})

export const updateSearch = (value: string): Action => ({
  type: UPDATE_SEARCH,
  payload: value ? value : null
})

export const updateOwnExercises = (value: boolean): Action => ({
  type: UPDATE_OWN_EXERCISES,
  payload: value
})