
import { toRepresentation } from 'utils/formatters'

const NAME  = Symbol('Property name')
const VALUE = Symbol('Property value')
const PROPS = Symbol('Properties')


class Property {

  constructor (name, value, details = {}) {

    const map = details.map
      ? details.map
      : value => value

    this[NAME]  = name
    this[VALUE] = map(value)

    for (let [ attr, value ] of Object.entries(details)) {
      if (attr !== 'type')
        Object.defineProperty(this, attr, { value })
      else if (this[VALUE] !== null)
        this[VALUE] = new value(this[VALUE])
    }
  }

  get value () {
    return this[VALUE]
  }

  get name () {
    return this[NAME]
  }

  toJSON () {
    return { [this.name]: this.value }
  }

}


export default function model (constructor) {

  const modelName  = constructor.name
  const meta = { model: modelName }

  const decoratedConstructor = function (values = {}) { // eslint-disable-line max-statements

    const instance = new constructor()
    const properties = Object.entries(instance)
    const instanceProperties = new Map()

    const defineGetter = (key, get) =>
      Object.defineProperty(instance, key, {
        get,
        configurable: false,
        enumerable: false,
      })

    for (let [ attribute, propertyDescription ] of properties) {
      let value = extractValue(values, attribute)
      let prop  = new Property(attribute, value, propertyDescription)

      if (!!propertyDescription.required && !(values && attribute in values))
        throw new ReferenceError(
          `The model \`${modelName}\` has its \`${attribute}\` property marked as required – ` +
          `The ${modelName}'s constructor was called with an invalid following payload\n`+
          `<pre>new ${modelName}(${toRepresentation(values)})</pre>`)

      const getValue = () =>
        prop.value

      instanceProperties.set(attribute, prop)
      defineGetter(attribute, getValue)
    }

    const getProperties = () =>
      instanceProperties

    const reducer = (val, [ key ]) =>
      Object.assign(val, instance[PROPS].get(key).toJSON())

    const serialize = () =>
      Object.assign({}, properties.reduce(reducer, {}), meta)

    const getSerialize = () =>
      serialize

    defineGetter(PROPS, getProperties)
    return defineGetter('toJSON', getSerialize)
  }

  if (constructor.from)
    decoratedConstructor.from = constructor.from
  else

    decoratedConstructor.from = (data) =>
      new decoratedConstructor(data)

  return decoratedConstructor
}


function extractValue (values, attribute) {
  if (values === null)
    return null
  if (typeof values === 'object')
    if (attribute in values)
      return values[attribute]
  return null
}
