import navigator, { next, previous, replace, navigate } from './navigator'

export default navigator

export {
  next,
  replace,
  previous,
  navigate,
}
