// @flow
import React, { Fragment } from 'react'
// $FlowIgnore
import { Card } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'
import DOMPurify from 'isomorphic-dompurify'
import { useDispatch, useSelector } from 'react-redux'

import LocationDetails from '../extra/LocationDetails'

import { increaseStep, selectEmployee } from 'actions/onlineBooking'

import portrait_placeholder from '../../../img/portrait_placeholder.png'

import type {
  Employee, Location, OnlineBookingState as OnlineBooking
} from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

type EmployeeCardProps = {
  employee: Employee,
  locations?: Array<Location>,
  isDesktop?: boolean,
  setShowDrawer?: Function,
  setCurrentEmployee?: Function,
  hideLocation?: boolean,
}

const EmployeeCard = ({
  employee,
  isDesktop,
  setShowDrawer,
  setCurrentEmployee,
  hideLocation,
}: EmployeeCardProps): React$Element<*> => {
  const dispatch = useDispatch()
  const onlineBooking: OnlineBooking = useSelector(
    (state: StateType) => state.onlineBooking
  )

  const img = employee.img_url ? employee.img_url : portrait_placeholder

  const handleClick = (e) => {
    e.stopPropagation()
    if (isDesktop && setShowDrawer && setCurrentEmployee) {
      setCurrentEmployee(employee)
      dispatch(selectEmployee(employee, false))
      setShowDrawer(true)
    } else {
      dispatch(selectEmployee(employee, !isDesktop))
      dispatch(increaseStep())
    }
  }

  const getLocation = () => {
    if (onlineBooking.selected_locations.length === 1) {
      return onlineBooking.selected_locations[0]
    }
    return onlineBooking.selected_locations
  }

  const getSanitizedDescription = () => {
    const clean = DOMPurify.sanitize(employee.description, { ALLOWED_TAGS: ['p', 'b', 'i', 'a', 'br'] })
    const final = convertLinks(clean)
    return final
  }

  const convertLinks = (str: string) =>
    str.split('<a').join('<a target="_blank"')

  const location = getLocation()

  return (
    <Card onClick={handleClick}>
      <div className='employee-card-layout'>
        <div className='employee-image-container'>
          <img src={img} />
        </div>
        <div className='employee-info-container'>
          <div>
            <div className='employee-info-container-name'>{employee.name}</div>
            <div className='n-color-text-weakest employee-info-container-profession'>
              {employee.profession || ''}
            </div>
          </div>
          <div className='n-color-text-weakest employee-info-container-description'>
            {/* Should not be dangerous as it's sanitized with DOMPurify */}
            <div dangerouslySetInnerHTML={{ __html: getSanitizedDescription() || '' }}></div>
          </div>
        </div>
      </div>

      {!hideLocation && (
        <Fragment>
          <hr className='n-divider' style={{ margin: '1em 0' }} />
          <LocationDetails location={location}>
            <Button variant='primary' size='m' onClick={handleClick}>
              Choose
            </Button>
          </LocationDetails>
        </Fragment>
      )}
    </Card>
  )
}

export default EmployeeCard
