/**
 * @module Suggestion
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */
import React, { PureComponent, createRef } from 'react'
import type { Node } from 'react'
import tooltips from 'tooltips'

type SuggestionPropsType = {
  contentState: *,
  entityKey: *,
  children: Node,
  tooltip?: string
}


export default class Suggestion extends PureComponent<SuggestionPropsType> {

  element = createRef()
  tooltip: string | null

  componentDidMount () {
    this.tooltip = this.props.contentState.getEntity(this.props.entityKey).getData().tooltip
    if (this.tooltip)
      tooltips.add(this.element.current, this.tooltip)
  }

  componentWillUnmount () {
    if (this.tooltip)
      tooltips.remove(this.element.current, this.tooltip)
  }

  render () {
    return <span
      className='label suggestion'
      ref={ this.element }
      suppressContentEditableWarning>
      { this.props.children }
    </span>
  }

}
