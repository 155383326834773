// @flow
import self from 'autobind-decorator'
import auth from 'api/auth'
import api from 'api'
import React, { Component, Fragment, createRef } from 'react'
import connect from 'bound-connect'
import { Link } from 'react-router-dom'

import { addAlert } from 'actions/core'

import { PrimaryButton, NavigateButton as NavigateButton } from 'components/buttons'
import { ToolbarContainer, Toolbar, Card, Alert } from 'components/generic'
import Text, { ErrorText, Title, Paragraph } from 'components/text'
import BaseComposeView from 'components/BaseComposeView'
import TwoFactorWrapper from 'components/TwoFactorAuthentication'
import OnlineBookingLogin from './OnlineBookingLogin'

import { isPublicApp } from 'routes/index'

import { formatDate, parseTagsAndLinks } from 'utils/formatters'
import { __ } from 'utils/gettext'

import type { StateType } from 'store/initialState'
import type { TenantSettingsState } from 'reducers/tenant'

type LoginFormState = {
  twoFactorRequired: boolean,
  twoFactorData: Object,
  fieldData: Object,
  loginType: string,
}

type LoginFormProps = {
  login?: Function,
  isOnlineBooking?: boolean,
  showProceedAsGuest?: boolean,
  setBookingModalOpen?: Function,
  user_id: number,
  primary_language: string,
  addErrorMessage: Function,
  tenantSettings: TenantSettingsState,
  location: { state: Object },
  history: { replace: Function },
  setShowLoginBanner?: Function,
}

class BaseLoginForm extends BaseComposeView {
  asCard: boolean = false

  fields = [
    {
      name: 'username',
      fullwidth: true,
      hideRequiredIndicator: true,
      props: {
        onKeyDown: this.onClick,
      }
    },
    {
      name: 'password',
      fullwidth: true,
      type: 'password',
      hideRequiredIndicator: true,
      props: {
        onKeyDown: this.onClick
      }
    }
  ]

  get url(): string {
    return '/login/'
  }

  get showDelete(): boolean {
    return false
  }

  @self
  async onClick() {
    this.setState({ error: null })
    super.handleError({}, false)
    const res = await api.post(this.url, this.getFormData())
    if (res.ok) {
      this.props.onSuccess(res.data)
    } else {
      this.handleError(res)
    }
  }

  handleError(response) {
    if (this.props.onError) {
      if (response.status === 429)
        this.props.onError(
          'Too many attempted logins. Please wait ten minutes and try again.'
        )
      else if (
        response.data &&
        response.data.code &&
        response.data.code === 'require_2fa'
      )
        this.props.onRequireTwoFactor(this.getFormData())
      else if (response.status === 403)
        this.setState({ error: response.errors.toString() })
      else {
        super.handleError(response)
      }
    }
  }

  renderTitle() {
    return (
      <>
        {renderNewFrontEndInfo(this.props.tenantSettings)}
        <Title>Login</Title>
      </>
    )
  }

  renderFooter() {
    return (
      <Fragment>
        {this.state.error && <ErrorText>{this.state.error}</ErrorText>}

        <ToolbarContainer className='login-toolbar'>
          <Toolbar left>
            <PrimaryButton id='login-button' onClick={this.onClick}>
              Login
            </PrimaryButton>
          </Toolbar>
        </ToolbarContainer>
        <div className='sign-up-footer'>
          <Link to='/password-reset/'>
            <Text>Forgot password?</Text>
          </Link>
          {this.props.allowSignUp && (
            <Link to='/register/'>
              <Text>Sign up if you do not yet have an account</Text>
            </Link>
          )}
        </div>
      </Fragment>
    )
  }
}

function renderNewFrontEndInfo(tenantSettings) {
  let url = ''
  if (__DEV__) {
    url = `http://${window.location.hostname}:3001`
  } else {
    const location = window.location
    // Pathname is appended for instance if email with password
    // reset link is sent
    const redirectUrl = `${location.hostname}/new${location.pathname}`
    url = `https://${redirectUrl}`
  }

  const migrationDate = tenantSettings.migration_date
    ? new Date(tenantSettings.migration_date)
    : null

  const infoText = migrationDate
    ? <Text params={{ migrationDate: migrationDate.toLocaleDateString('fi-FI') }}>
        {"The new user interface of Nordhealth Connect will be taken into use on ${migrationDate}. The old user interface cannot be used after that. You can already start using the new user interface by clicking on 'Go to new Connect'"}
      </Text>
    : <Text>We are updating the user interface of Nordhealth Connect. Move to new Connect by clicking 'Go to new Connect'.</Text>

  return (
    <>
      {tenantSettings.new_frontend_mode === 'encourage' && (
        <Alert persistent info>
          { infoText }
          <br />
          <br />
          <NavigateButton onClick={() => { window.location.href = url }}>
            Go to new Connect
          </NavigateButton>
        </Alert>
      )}
    </>
  )
}

class SecureAuthLogin extends Component<*> {
  async redirectToAuthentication() {
    const response = await api.get('/secure-auth/authorization-url/', {
      success_url: '/secure-login-redirect/',
    })
    if (response.ok) {
      window.location.href = response.data.authorization_url
    }
  }

  render() {
    return (
      <div style={{ marginBottom: '2.5rem' }}>
        {renderNewFrontEndInfo(this.props.tenantSettings)}
        <div style={{ marginBottom: '2rem' }}>
          <Paragraph>
            Authenticate to the service with online banking credentials or a
            mobile certificate. Authentication is done in the Telia Sign
            -signing service.
          </Paragraph>
        </div>
        <ToolbarContainer>
          <Toolbar left>
            <PrimaryButton onClick={() => this.redirectToAuthentication()}>
              Proceed to authentication
            </PrimaryButton>
          </Toolbar>
        </ToolbarContainer>
        <div className='sign-up-footer'>
          {this.props.allowSignUp && (
            <Link to='/register/'>
              <Text>Sign up if you do not yet have an account</Text>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

@connect
export default class LoginForm extends Component<
  LoginFormProps,
  LoginFormState
> {
  state: LoginFormState = {
    twoFactorRequired: false,
    twoFactorData: {},
    fieldData: {},
    loginType: 'client',
  }

  static actions(dispatch: Function): Object {
    return {
      addErrorMessage: (content: string, title: string) =>
        dispatch(addAlert('error', content, 1, title)),
    }
  }

  static properties(state: StateType): Object {
    return {
      tenantSettings: state.tenantSettings,
    }
  }

  _form: Function = createRef()

  componentDidMount() {
    if (this.props.tenantSettings.require_client_secure_login)
      if (this.props.location.state !== undefined)
        if (this.props.location.state.authentication_failed) {
          // If secure auth login fails, add an error message and clear location state
          // so the message will be cleared on page refresh.
          this.props.addErrorMessage(
            'Login failed because customer information could not be found. Please contact customer service or your personal employee.',
            'Unfortunately your customer information could not be found.'
          )
          this.props.history.replace({
            state: {},
          })
        }
  }

  @self
  onRequireTwoFactor(fieldData: Object) {
    this.setState({ twoFactorRequired: true, twoFactorData: fieldData })
  }

  get form(): HTMLElement | null {
    return this._form ? this._form.current : null
  }

  get title(): string | null {
    return this.props.tenantSettings.title &&
      this.props.tenantSettings.title.length
      ? this.props.tenantSettings.title
      : null
  }

  get description(): string | null {
    return this.props.tenantSettings.description &&
      this.props.tenantSettings.description.length
      ? this.props.tenantSettings.description
      : null
  }

  get loginForm(): React$Element<*> {
    const {
      allow_sign_up,
      use_secure_auth,
      require_client_secure_login,
    } = this.props.tenantSettings
    if (require_client_secure_login && this.state.loginType === 'client')
      return <SecureAuthLogin tenantSettings={this.props.tenantSettings} allowSignUp={allow_sign_up && use_secure_auth} />
    else if (this.props.isOnlineBooking) {
      return (
        <OnlineBookingLogin
          onError={this.props.addErrorMessage}
          onSuccess={this.onSuccess}
          onRequireTwoFactor={this.onRequireTwoFactor}
          showProceedAsGuest={this.props.showProceedAsGuest}
          setBookingModalOpen={this.props.setBookingModalOpen}
          setShowLoginBanner={this.props.setShowLoginBanner}
        />
      )
    } else
      return (
        <BaseLoginForm
          onSuccess={this.onSuccess}
          onError={this.props.addErrorMessage}
          onRequireTwoFactor={this.onRequireTwoFactor}
          tenantSettings={this.props.tenantSettings}
          allowSignUp={
            !require_client_secure_login && allow_sign_up && use_secure_auth
          }
        />
      )
  }

  get loginFormContainer(): React$Element<*> {
    const { require_client_secure_login } = this.props.tenantSettings

    if (this.props.isOnlineBooking) {
      return <div className='booking-login-form'>{this.loginForm}</div>
    }
    return (
      <div className='page-column'>
        <Card>
          {require_client_secure_login && (
            <Title>{this.titleCase(this.state.loginType)}</Title>
          )}
          {this.loginForm}
        </Card>
      </div>
    )
  }

  @self
  onSuccess(response: *) {
    if (isPublicApp() || (response && response.access && response.refresh)) {
      localStorage.setItem('onLogin', '1')
      if (response && response.suggest_2fa)
        localStorage.setItem('suggest_2fa', '1')
      auth.set(response)
      if (this.props.isOnlineBooking && this.state.twoFactorData) {
        this.setState({ twoFactorRequired: false, twoFactorData: {} })
      }
      return
    }
  }

  titleCase(string: string): string {
    return string[0].toUpperCase() + string.slice(1).toLowerCase()
  }

  get descriptionContainer(): React$Element<*> {
    return !isPublicApp() &&
      this.title &&
      this.description &&
      !this.props.isOnlineBooking ? (
      <div className='page-column'>
        <Card>
          <Title notranslate>{this.title}</Title>
          {parseTagsAndLinks(this.description)}
        </Card>
      </div>
    ) : (
      <></>
    )
  }

  render(): React$Element<*> {
    const { require_client_secure_login } = this.props.tenantSettings

    return (
      <TwoFactorWrapper
        action='login'
        entryAdditionalData={this.state.twoFactorData}
        onCancel={() => this.setState({ twoFactorRequired: false })}
        active={this.state.twoFactorRequired}
        isOnlineBooking={this.props.isOnlineBooking || false}
        authenticationRequest={async () =>
          api.post('/login/', { ...this.state.twoFactorData })
        }
        onAuthenticated={this.onSuccess}
      >
        <Fragment>
          {require_client_secure_login && (
            <div className='login-options'>
              <Link
                to={''}
                className={
                  this.state.loginType === 'client' ? 'active' : undefined
                }
                onClick={() => this.setState({ loginType: 'client' })}
              >
                {__('Clients')}
              </Link>
              <Link
                to={''}
                className={
                  this.state.loginType === 'staff' ? 'active' : undefined
                }
                onClick={() => this.setState({ loginType: 'staff' })}
              >
                {__('Staff')}
              </Link>
            </div>
          )}
          <div className='loginform-content'>
            {this.loginFormContainer}
            {this.descriptionContainer}
          </div>
        </Fragment>
      </TwoFactorWrapper>
    )
  }
}
