// @flow
import type { Action } from '.'
import { Map } from 'immutable'
import { FILTER_DOCUMENTS } from 'actions/documents'

export type DocumentsState = {
  filters: Map<string, Set<number> | string>,
}


export const initialValue: DocumentsState = {
  filters: new Map(),
}


export default function documentsReducer (state: DocumentsState, action: Action): DocumentsState {
  const { payload } = action
  switch (action.type) {

    case FILTER_DOCUMENTS: {
      const filter = payload
      let filters = state.filters
      if (Array.isArray(filter))
        filter.forEach(item => {
          if (item.value === null)
            filters = filters.delete(item.property)
          else
            filters = filters.set(item.property, item.value)
        })
      else if (filter.value === null)
        filters = filters.delete(filter.property)
      else
        filters = filters.set(filter.property, filter.value)

      return { ...state, filters }
    }

    default:
      return state || initialValue

  }
}
