// @flow
import { Record } from 'immutable'
import * as Sentry from '@sentry/react'

import type { Action } from '.'
import type { RecordFactory, RecordInstance, RecordOf } from 'immutable'
import { isPublicApp } from 'routes/index'

import {
  LOGOUT,
  CLEAR_USER_DATA,
  UPDATE_SHOW_TERMS,
  UPDATE_LOGGED_IN_USER,
  UPDATE_USER_DATA_FROM_TOKEN,
  SET_LOGIN_REQUIRED_STATE,
} from 'actions/user'

export type UserProfileType = {
  last_name: string | null,
  first_name: string | null,
  show_terms: boolean,
  primary_language: string | null,
  clients: Array<UserType> | null,
  active_subscription: {
    type: number | null,
    end: string | null,
  },
  social_security_number: string | null,
  terms_accepted: string | null,
  postal_code: string | null,
  city: string | null,
  address: string | null,
}

export type UserType = {
  id: number | null,
  username: string,
  email: string,
  profile: UserProfileType,
  is_staff: boolean,
  phone_number: string | null,
  two_factor_enabled: boolean | null,
  created: string | null,
}

export type UserRecord = UserType & {
  id: number | null,
  type: number | null,
  name: string,
  token: string | null,
  authenticated: boolean | null,
  loginRequired: boolean,
  loaded: boolean,
}

export type UserState = UserRecord
export type UserRecordType = RecordOf<UserRecord>
export type UserInstanceType = RecordInstance<UserRecord>

const userDefaults: UserRecord = {
  id: null,
  type: null,
  username: '',
  name: '',
  email: '',
  token: null,
  authenticated: null,
  loginRequired: true,
  is_staff: false,
  loaded: false,
  group: null,
  status: null,
  phone_number: null,
  two_factor_enabled: false,
  two_factor_forced: false,
  created: null,
  profile: {
    show_terms: !isPublicApp(),
    first_name: null,
    last_name: null,
    primary_language: null,
    clients: null,
    active_subscription: {
      type: null,
      end: null,
    },
    social_security_number: null,
    terms_accepted: null,
    postal_code: null,
    city: null,
    address: null,
  },
}

export const User: RecordFactory<UserRecord> = new Record(userDefaults)

export const initialValue = User()

// eslint-disable-next-line complexity
export default function userReducer(
  user?: UserInstanceType,
  action: Action
): UserState {
  switch (action.type) {
    case LOGOUT:
    case CLEAR_USER_DATA:
      Sentry.configureScope((scope) => scope.setUser(null))
      return user.clear().mergeDeep(initialValue)

    case UPDATE_USER_DATA_FROM_TOKEN:
      return user.mergeDeep(action.payload)

    case UPDATE_LOGGED_IN_USER: {
      Sentry.setUser({
        id: action.payload.id,
      })
      const payload = { ...action.payload, loaded: true }
      return user.mergeDeep(payload)
    }

    case SET_LOGIN_REQUIRED_STATE:
      return user.set('loginRequired', action.payload)

    case UPDATE_SHOW_TERMS:
      const date = action.payload ? null : new Date().toISOString()
      return user.mergeDeep({
        profile: { show_terms: action.payload, terms_accepted: date },
      })

    default:
      return user || User()
  }
}
