// @flow
import React from 'react'

import Text from 'components/text'
import isMobile from 'utils/isMobileHook'

import type { Node } from 'react'

const InstructionText = ({ text }: { text: string }): Node => {
  const isMobileView: boolean = isMobile(window)
  if (isMobileView || !text) return null
  return (
    <div className='help-text'>
      <Text>{ text }</Text>
    </div>
  )
}

export default InstructionText
