// @flow
import React, { Component } from 'react'
import UsersList from './ListUsers'

export default class StaffList extends Component<*> {

  render (): React$Element<*> {
    return <UsersList type='staff' />
  }

}