// @flow

import { combineReducers } from 'redux'
import { reducer as editor } from 'former-ui'
import { List, Map } from 'immutable'
import {
  ADD_FORMS,
  FILTER_FORMS,
  ADD_FORM_SUBMISSIONS,
  FILTER_FORM_SUBMISSIONS,
  ADD_FORM_SUBMISSION_REQUESTS,
  UPDATE_PENDING_ATTACHMENTS_COUNT,
} from 'actions/forms'
import { LOGOUT } from 'actions/user'

import type { Action } from '.'
import type { UserRecord } from './user'


export type FormsState = {
  items: List<{ id: any, name: string }>,
  filters: Map<string, Set<number> | string>,
  submission_filters: Map<string, Set<number> | string>,
  submissions: List<SubmissionType>,
  submission_requests: List<SubmissionRequestType>,
  attachmentsCount: number,
}

export type SubmissionType = {
  id: any,
  version: VersionType,
  user: UserRecord,
  created: string,
  tags: List<*>,
  search: string | null,
}

export type SubmissionRequestType = {
  id: any,
  form: any,
  client: UserRecord,
  due_date: string,
  tags: List<*>,
}

export type VersionType = {
  id: any,
  version_number: any,
  form: Object,
  elements: List<*>,
}


function forms (state: FormsState, action: Action): FormsState {

  switch (action.type) {

    case UPDATE_PENDING_ATTACHMENTS_COUNT: {
      let attachmentsCount = state.attachmentsCount ? state.attachmentsCount += action.payload : action.payload
      return {...state, attachmentsCount}
    }

    case LOGOUT:
      return initialValue

    case ADD_FORMS: {
      let items = state.items
      for (let form of action.payload) {
        let index = items.findIndex(item => item.id === form.id)

        if (index === -1)
          items = items.push(form)
        else
          items = items.set(index, form)
      }
      return { ...state, items }
    }

    case ADD_FORM_SUBMISSIONS: {
      let submissions = state.submissions
      for (let form of action.payload) {
        let index = submissions.findIndex(item => item.id === form.id)

        if (index === -1)
          submissions = submissions.push(form)
        else
          submissions = submissions.set(index, form)
      }
      return { ...state, submissions }
    }

    case FILTER_FORMS: {
      const filter = action.payload
      let filters = state.filters
      if (Array.isArray(filter))
        filter.forEach(item => {
          if (item.value === null)
            filters = filters.delete(item.property)
          else
            filters = filters.set(item.property, item.value)
        })
      else if (filter.value === null)
        filters = filters.delete(filter.property)
      else
        filters = filters.set(filter.property, filter.value)

      return { ...state, filters }
    }

    case FILTER_FORM_SUBMISSIONS: {
      const submission_filter = action.payload
      let submission_filters = state.submission_filters
      if (Array.isArray(submission_filter))
        submission_filter.forEach(item => {
          if (item.value === null)
            submission_filters = submission_filters.delete(item.property)
          else
            submission_filters = submission_filters.set(item.property, item.value)
        })
      if (submission_filter.value === null)
        submission_filters = submission_filters.delete(submission_filter.property)
      else
        submission_filters = submission_filters.set(submission_filter.property, submission_filter.value)

      return { ...state, submission_filters }
    }

    case ADD_FORM_SUBMISSION_REQUESTS: {
      let submission_requests = state.submission_requests
      for (let submission of action.payload) {
        let index = submission_requests.findIndex(item => item.id === submission.id)

        if (index === -1)
          submission_requests = submission_requests.push(submission)
        else
          submission_requests = submission_requests.set(index, submission)
      }
      return { ...state, submission_requests }
    }

    default:
      return state || initialValue
  }
}

export const initialValue: FormsState = {
  attachmentsCount: 0,
  items: new List(),
  submissions: new List(),
  filters: new Map(),
  submission_filters: new Map(),
  submission_requests: new List()
}

// $FlowFixMe
export default combineReducers({
  forms,
  editor,
})
