// @flow
import IdleTimer from 'react-idle-timer'
import connect from 'bound-connect'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'

import Alerts from 'views/Alerts'
import PageNavigation from 'views/Page/HeadNavigation'
import { PageFooter } from 'views/Page/Footer'
import auth, { IDLE_TIMEOUT } from 'api/auth'

import type { Node } from 'react'
import type { AlertsListType } from 'reducers/core'
import type { StateType } from 'store/initialState'


type PropTypes = {
  alerts?: AlertsListType,
  children: Node,
  path?: string,
  url?: string,
}

const MATCH_DIRTY = /(?:[^\w]+(\w)|[^\w]+$)/g


@connect
export default class Base extends Component<PropTypes> {

  static actions = []

  static properties = (state: StateType) => {
    const location = state.core.route[state.core.route.length - 1]
    const pathname = location ? location.pathname : ''

    return {
      alerts:  state.core.alerts,
      url:            pathname,
      path:           pathname.substr(1).replace(MATCH_DIRTY, (_, c) => c ? '-' + c : ''),
    }
  }

  render () {
    const className  = classNames('page-contents', 'content', 'container')

    return <Fragment>

      <PageNavigation
        url={ this.props.url }
        items={ getMenuEntries() } />

      <main
        className={ className }
        data-name={ this.props.path }>

        <Alerts items={ this.props.alerts ? this.props.alerts : [] } />
        { this.props.children }


      </main>

      <PageFooter />

      <IdleTimer
        onIdle={ auth.handleExpiredToken }
        timeout={ IDLE_TIMEOUT }
      />

    </Fragment>
  }
}

const MenuEntries = [
  {
    label:    'Tenants',
    action:   '/tenants',
  },

  {
    label:    'Users',
    action:   '/users',
  },

  // Forms, exercises and courses under library
  {
    label:    'Library',
    action:   '/library',
  },

  // Tags, contracts and announcements under others
  {
    label:    'Others',
    action:   '/others',
  },

  {
    label:     'Logout',
    action:    auth.clear,
    collapsed: 'mobile',
  },
]


function getMenuEntries () {
  return MenuEntries.slice(0)
}
