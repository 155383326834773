/**
 * @flow
 * @class IndicatorBlock
 * @author tuomashatakka
 */

import { PureComponent } from 'react'
import { __ } from 'utils/gettext'
import self from 'autobind-decorator'

import type { Node } from 'react'


type Properties = {
  children: Node,
  element: *,
}


export default class IndicatorBlock extends PureComponent<Properties> {

  static displayName = __('Indicator')

  get displayValue (): string | null {
    if (typeof this.props.element.expression === 'string') {
      return this.props.element.expression
    }
    if (!this.props.element.expression)
      return null
    const displayValue = this.formatDisplayValue(this.props.element.expression.displayValue)
    return displayValue
  }

  get value (): string | null {
    return this.props.element.expression && this.props.element.expression.value || null
  }

  @self
  //eslint-disable-next-line class-methods-use-this
  formatDisplayValue (displayValue: string): string | null {
    if (displayValue.includes('**')) {
      const formattedDisplayValue = displayValue.replace('**', '^')
      return formattedDisplayValue
    }
    return displayValue
  }

  render () {
    return this.displayValue
  }
}
