// @flow
// Reducer used for filtering subscriptions in ListSubscriptions.js
import type { Action } from '.'

import {
  FILTER_BY_USERNAME,
  FILTER_BY_GROUP,
  FILTER_BY_TYPE
} from '../actions/subscriptions'


export type SubscriptionState = {
  type: string,
  group: string,
  user: string, // Filter by username
}

export const initialValue: SubscriptionState = {
  type: '',
  group: '',
  user: ''
}

const subscriptionReducer = (state: SubscriptionState = initialValue, action: Action): SubscriptionState => {
  switch (action.type) {
    case FILTER_BY_USERNAME:
      return { ...state, user: action.payload }
    case FILTER_BY_GROUP:
      return { ...state, group: action.payload }
    case FILTER_BY_TYPE:
      return { ...state, type: action.payload }
    default:
      return state
  }
}

export default subscriptionReducer
