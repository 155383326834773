
import { OrderedSet, Map } from 'immutable'
import {
  APPEND_LINK, UPDATE_ATTACHMENTS, DELETE_ATTACHMENT, UPDATE_TAGS,
  UPDATE_FORM_DETAILS, UPDATE_FORM_ID, UPDATE_FORM_TITLE, FORMAT_FORM_STATE,
  MOVE_BLOCK, APPEND_BLOCK, UPDATE_BLOCK, REMOVE_BLOCK, INSERT_BLOCK,
  CLEAR_BLOCKS, UPDATE_BLOCKS, SELECT_BLOCK, REMOVE_LINK,
} from './actions'

import Block from './editor/Block'

// eslint-disable-next-line complexity, max-statements
export default function editorReducer (state, action) {

  const update = (...args) => {
    let updates = args.pop()
    return state.setIn(args, updates)
  }

  switch (action.type) {

    case FORMAT_FORM_STATE:
      const tags   = state.get('tags')
      const links  = state.get('links')
      const blocks = state.get('blocks')
      const details = action.payload ? action.payload.details : {
        id: null,
        name: '',
        status: null,
        description: '',
        country: null,
      }

      if (action.payload === null) {
        return Map({
          tags:                new OrderedSet(),
          links:               new OrderedSet(),
          blocks:              new OrderedSet(),
          attachments_staff:   new OrderedSet(),
          attachments_client:  new OrderedSet(),
          selectedBlock:  null,
          details:        {
            id:           null,
            name:         '',
            description:  '',
          },
        })
      }


      else {
        state = update('blocks', blocks
          .clear()
          .union(action.payload.elements.map(field => new Block(field))))
        state = update('tags', tags
          .clear()
          .union(action.payload.tags))
        state = update('links', links
          .clear()
          .union(action.payload.links))
        if (action.payload.attachments_staff) {
          const attachments_staff = state.get('attachments_staff') || new OrderedSet()
          const combined = attachments_staff.union(action.payload.attachments_staff)
          state = update('attachments_staff', combined)
        }
        if (action.payload.attachments_client) {
          const attachments_client = state.get('attachments_client') || new OrderedSet()
          const combined = attachments_client.union(action.payload.attachments_client)
          state = update('attachments_client', combined)
        }

      }

      state = update('details', details)
      return state

    case UPDATE_FORM_ID:
      return update('details', {
        id:          action.payload,
        name:        state.get('details').title,
        description: state.get('details').description,
      })

    case UPDATE_FORM_TITLE:
      return update('details', {
        id:          state.get('details').id,
        name:        action.payload,
        description: state.get('details').description,
      })

    case UPDATE_FORM_DETAILS:
      return update('details', {
        ...state.get('details'),
        ...action.payload,
      })


    case SELECT_BLOCK:
      return update('selectedBlock', action.payload)

    case UPDATE_BLOCKS:
      return update('blocks', state.get('blocks').union(action.payload))

    case UPDATE_BLOCK:
      const list   = state.get('blocks').toList()
      const index  = list.findIndex(block => block.id === action.payload.id)
      const merger = block => block.merge(action.payload)

      if (index === -1)
        throw new ReferenceError(`Invalid update action – a block with id ${action.payload.id} does not exist.`)
      state = update('blocks', list.update(index, merger).toOrderedSet())
      return  update('selectedBlock', null)

    case APPEND_BLOCK:
      state = update('blocks', state.get('blocks').add(action.payload))
      return  update('selectedBlock', action.payload.id)

    case MOVE_BLOCK:
      const blist = state.get('blocks').toList()
      const block = blist.get(action.payload.from)
      return update('blocks', blist
        .delete(action.payload.from)
        .insert(action.payload.to, block)
        .toOrderedSet()
      )

    case REMOVE_BLOCK:
      return update('blocks', state.get('blocks').delete(action.payload))

    case INSERT_BLOCK:
      const ilist  = state.get('blocks').toList()
      return update('blocks', ilist
        .insert(action.payload.position, action.payload.block)
        .toOrderedSet()
      )

    case CLEAR_BLOCKS:
      state = update('blocks', state.get('blocks').clear())
      return  update('selectedBlock', null)

    case UPDATE_TAGS:
      return update('tags', state
        .get('tags')
        .clear()
        .union(action.payload))

    case APPEND_LINK:
      return update('links', state
        .get('links')
        .add(action.payload))

    case UPDATE_ATTACHMENTS:
      const attachments = action.payload.attachments
      const type = action.payload.type
      return update(type, state
        .get(type)
        .union(attachments))

    case DELETE_ATTACHMENT:
      const deleted_id = action.payload.id
      const att_type = action.payload.type
      const del_attachments = state.get(att_type).toArray() || []
      for (let i = 0; i < del_attachments.length; i++) {
        if (del_attachments[i].id === deleted_id) {
          del_attachments.splice(i, 1)
          break
        }
      }
      return update(att_type, state
        .get(att_type)
        .clear()
        .union(del_attachments))


    case REMOVE_LINK:
      return update('links', state
        .get('links')
        .delete(action.payload))

    default:
      return state || {}
  }
}
