// @flow
import self from 'autobind-decorator'
import React from 'react'

import Field from './Field'
import HelpIcon from 'components/HelpIcon'

import Toggle from 'react-toggle'
import "react-toggle/style.css"


export default class SliderToggleField extends Field<boolean> {

  getInitialValue () {
    if (typeof this.props.initial !== 'undefined')
      return this.props.initial
    return false
  }

  @self
  onChange () {
    this.update(!this.state.value)
  }

  get infoCircle (): React$Element<*> | null {
    return this.props.helpIcons
      ? <HelpIcon text={ this.props.help_text } />
      : null
  }

  renderFormControl () {

    const style = {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    }

    return <div style={ style }>
      <label className='label'>
        {this.props.label}
        {this.infoCircle}
        {this.validityIndicator}
      </label>

      <Toggle
        className='slider-toggle'
        checked={ this.value }
        disabled={ this.state.disabled }
        onChange={ this.onChange }
        disabled={ this.disabled }
      />
    </div>
  }
}
