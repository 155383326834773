/**
 * @flow
 * @class DynamicHeightInput
 */

import self from 'autobind-decorator'
import React, { Component } from 'react'
import getStyle from 'computed-style'
import TextareaAutosize from 'react-autosize-textarea'

type DynamicHeightInputProps = {
  value?: string,
  disabled?: boolean,
  onChange?: Function,
  className?: string,
}

type DynamicHeightInputState = {
  value: string,
  disabled: boolean,
}


export default class DynamicHeightInput extends Component<DynamicHeightInputProps, DynamicHeightInputState> {

  lineHeight: number = 1

  static MINIMUM_LINES: number = 2

  constructor (props: DynamicHeightInputProps) {
    super(props)
    const value = props.value || ''
    const disabled = props.disabled || false

    this.state = {
      value,
      disabled,
    }
  }

  UNSAFE_componentWillMount () {
    let height = getStyle(document.body, 'line-height')
    this.lineHeight = Math.round(parseFloat(height))
  }

  componentDidMount = () =>
    this.updateState(this.props)

  UNSAFE_componentWillReceiveProps = (props: DynamicHeightInputProps) =>
    this.updateState(props)

  @self
  update (value: string) {
    this.setState({ value }, () =>
      this.props.onChange && this.props.onChange(value))
  }

  get style (): Object {
    return {
      overflow:   'hidden',
      resize:     'none',
      lineHeight: this.lineHeight + 'px',
    }
  }

  render = () =>
    <TextareaAutosize
      disabled={ this.state.disabled }
      value={ this.state.value }
      style={ this.style }
      onChange={ ({ target: { value }}) => this.update(value)}
      className={ this.props.className }
    />

  updateState (props: DynamicHeightInputProps) {
    let updates = {}
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false
    if ('value' in props)
      updates.value = props.value
    if (Object.keys(updates).length)
      this.setState(updates)
  }
}
