/**
 * @flow
 * @memberof  libs
 * @namespace libs.api
 * @module    module:api
 * @summary   API request interface.
 * @description
 *    The exported methods accept either a single argument
 *    [arg: string] url  Endpoint identifier
 *
 *    PUT and POST methods also take a second argument
 *    [data: {}] Payload for the request
 *
 *    All methods return a {@link Promise} that resolves to a `Response` instance.
 *    The promise is resolved in even if the API call was unsuccessful.
 *    Erroneous responses have `false` as their `valid` property's value.
 *    Use that to check if the call was unsuccessful.
 *
 */

import cache from './cache'
import routes from './routes'
import models from './models'
import { url, ALLOWED_METHODS } from './constants'
import request, { onNetworkError, setBaseUrl, getHeaders, setHeader, setHeaders, deleteHeader, onClientError } from './request'

let _request = request

type ApiMethod<Arg> = (...Array<Arg>) => Promise<Response>

export type MethodType<V> = ApiMethod<V>

type APIProxy = {
  url: string,
  get: ApiMethod<[string, {}]>,
  put: ApiMethod<[string, {}]>,
  post: ApiMethod<[string, {}]>,
  patch: ApiMethod<[string, {}]>,
  delete: ApiMethod<[string, {}]>,
  options: ApiMethod<[string, {}]>,
}


function getFunctionForMethod (method) {
  return (...args) => _request(method, ...args)
}


function routeExists (routeName: string) {
  return typeof routes[routeName] === 'function'
}


function getRoute (routeName: string) {
  if (routeExists(routeName))
    // flow-ignore
    return routes[routeName](getProxy())
  return null
}


function clearCache () {
  cache.clear()
}


function getProxy (): APIProxy {

  // flow-ignore
  return new Proxy({}, {

    // eslint-disable-next-line complexity
    get (self, arg: string) {
      const method = arg.toLowerCase()

      switch (arg) {
        case 'url':           return url
        case 'headers':       return getHeaders()
        case 'setHeader':     return setHeader
        case 'setHeaders':    return setHeaders
        case 'deleteHeader':  return deleteHeader
        case 'clearCache':    return clearCache
        case 'onClientError': return onClientError
        case 'onError':       return onNetworkError
        case 'setBaseUrl':    return setBaseUrl
      }

      // If the argument is a http request type,
      // dispatch a http request of said type
      // and return its response.
      if (ALLOWED_METHODS.includes(method))
        return getFunctionForMethod(method)

      // Submodules need to be handled implicitly
      if (arg in models)
        return models[arg]

      // Otherwise, check if a method named
      // argument's value is defined in ./routes.js
      if (routeExists(arg))
        return getRoute(arg)

      if (__DEV__)
        console.warn(`Requested an invalid property \`${arg}\` from the api.`) // eslint-disable-line no-console
    },
  })
}

export default (getProxy(): APIProxy)

// flow-ignore
if (module.hot)
  module.hot.accept('./request', () => {
    _request = require('./request').default
  })
