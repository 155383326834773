// @flow
// @module AcceptContract
// @author mikakattainen<mika.kattainen@fns.fi>
import React, { Component } from 'react'
import connect from 'bound-connect'
import api from 'api'
import self from 'autobind-decorator'

import { Page, Document } from 'react-pdf'
import { pdfjs } from 'react-pdf'

import navigator from 'routes/base'
import { logout } from 'actions/user'
import { updateShowContract } from 'actions/core'

import { Toolbar, ToolbarContainer, Loader } from 'components/generic'
import Button, { PrimaryButton } from 'components/buttons'
import { Subtitle } from 'components/text'
import { trackWidth } from 'components/TrackWidthDecorator'

import {
  GROUP_MANAGEMENT,
  DEFAULT_REDIRECT_URL,
  DEFAULT_RESTRICTED_TENANT_URL,
} from 'constants'

import type { StateType } from 'store/initialState'
import type { trackWidthPropsType } from 'components/TrackWidthDecorator'

type ContractPropsType = {|
  logout: Function,
  updateShowContract: Function,
  user: Object,
|} & trackWidthPropsType

type ContractStateType = {
  contract: ContractType | null,
  pageCount: number,
}

type ContractType = {
  file: string,
  file_name: string,
  id: string,
}

@trackWidth
@connect
export default class AcceptContract extends Component<
  ContractPropsType,
  ContractStateType
> {
  state = {
    contract: null,
    pageCount: 0,
    currentPage: 1,
  }

  static properties(state: StateType) {
    const user = state.user
    return {
      user,
    }
  }

  static actions(dispatch: Function) {
    return {
      updateShowContract: (value: boolean) =>
        dispatch(updateShowContract(value)),
      logout: () => dispatch(logout()),
    }
  }

  constructor(props: ContractPropsType) {
    super(props)
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }

  async componentDidMount() {
    const contract = (await api.get('/contracts/latest/')).data
    this.setState({ contract })
  }

  @self
  onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    this.setState({ pageCount: numPages })
  }

  @self
  logout() {
    this.props.logout()
  }

  render() {
    const { pageCount, contract } = this.state

    if (!contract) return <Loader />

    return (
      <section className='user-agreement-container'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Subtitle>
            Contract<span> ({contract.file_name})</span>
          </Subtitle>
          <a
            href={contract.file}
            download
            target='_blank'
            rel='noopener noreferer'
          >
            <PrimaryButton>Download</PrimaryButton>
          </a>
        </div>
        <div className='pdf-container'>
          <Document
            file={contract.file || null}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {Array.from(new Array(pageCount), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                width={this.props.elementWidth}
                className='pdf-page'
              />
            ))}
          </Document>
        </div>
        <div className='user-agreement-toolbar'>
          <ToolbarContainer>
            <Toolbar left>
              <PrimaryButton onClick={this.onAccept}>
                Accept contract
              </PrimaryButton>
            </Toolbar>
            <Toolbar right>
              <Button onClick={this.logout}>Logout</Button>
            </Toolbar>
          </ToolbarContainer>
        </div>
      </section>
    )
  }

  @self
  onAccept() {
    const data = {
      contract: this.state.contract ? this.state.contract.id : null,
    }
    this.props.updateShowContract(false)
    api.post('/contracts/confirm/', data)

    const redirectUrl =
      this.props.user.group.name === GROUP_MANAGEMENT
        ? DEFAULT_RESTRICTED_TENANT_URL
        : DEFAULT_REDIRECT_URL

    this.props.user.profile.show_terms
      ? navigator.navigate('/terms-of-service/')
      : navigator.navigate(redirectUrl)
  }
}
