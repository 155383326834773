// @flow
import React, { useState } from 'react'

import { Header } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'

import { useSelector } from 'react-redux'

import { getLocationBySelectedTimeslot } from '../utils/getLocation'

import { FullScreenModal } from '../extra/OnlineBookingModal'
import Map from '../extra/Map'
import LocationDetails from '../extra/LocationDetails'

import type { Node } from 'react'
import type {
  OnlineBookingState as OnlineBooking,
  Location,
} from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

type Props = {
  mapHeight?: string,
  mapVisible: boolean,
  setMapVisible: Function,
}

const LocationModal = (props: Props): Node => {
  const onlineBooking: OnlineBooking = useSelector(
    (state: StateType) => state.onlineBooking
  )

  const timeSlotLocationId = onlineBooking.selected_time_slots?.location_id
  const selectedLocations = onlineBooking.selected_locations

  const location = getLocationBySelectedTimeslot(
    // $FlowIgnore
    timeSlotLocationId,
    selectedLocations
  )

  return (
    <FullScreenModal
      visible={props.mapVisible}
      setVisible={props.setMapVisible}
      position='fixed'
    >
      <LocationDetails wrapper={Header} location={location[0]}>
        <Button slot='end' onClick={() => props.setMapVisible(false)}>
          Close
        </Button>
      </LocationDetails>
      <Map height={props.mapHeight || '190px'} />
    </FullScreenModal>
  )
}

export default LocationModal
