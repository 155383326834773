// @flow
import React, { useEffect, useState } from 'react'
// $FlowIgnore
import { useSelector, useDispatch } from 'react-redux'

import { Card, Stack, Select } from '@nordhealth/react'
import Drawer from '@material-ui/core/Drawer'

import {
  selectEmployee,
  selectLocation,
  selectService,
} from 'actions/onlineBooking'

import Text from 'components/text'

import BookingCalendar from '../extra/BookingCalendar'
import EmployeeCard from '../extra/EmployeeCard'
import withOnlineBookingModal from '../extra/OnlineBookingModal'

import SelectTimeslotView from '../views/SelectTimeslotView'

import { __ } from 'utils/gettext'

import type { Node } from 'react'
import type {
  Employee,
  Location,
  OnlineBookingState,
  AvailableDates,
} from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

type Props = {
  employees: Employee[],
  availableDates: AvailableDates[],
}

const BookingMain = ({ availableDates, employees }: Props): Node => {
  const dispatch = useDispatch()
  const [serviceChoices, setServiceChoices] = useState([])
  const [employeeChoices, setEmployeeChoices] = useState([])
  const [locationChoices, setLocationChoices] = useState([])
  const [currentEmployee, setCurrentEmployee] = useState(null)
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const bookingState: OnlineBookingState = useSelector(
    (state: StateType) => state.onlineBooking
  )
  const {
    all_employees,
    all_locations,
    all_services,
    selected_service,
    selected_employee,
  } = bookingState

  useEffect(() => {
    // Add choices to local state so we dont have the default values in redux state
    const serviceChoices = [...all_services]
    setServiceChoices(serviceChoices)

    const employeeChoices = [...all_employees]
    employeeChoices.unshift({ id: 0, name: __('All therapists') })
    setEmployeeChoices(employeeChoices)

    const locationChoices = [...all_locations]
    locationChoices.unshift({ id: 0, name: __('All locations') })
    setLocationChoices(locationChoices)
  }, [])

  const setService = (e) => {
    let selectedService = all_services.find(
      (service) => service.name === e.target.value
    )
    if (!selectedService || selectedService.id === 0) selectedService = null
    dispatch(selectService(selectedService, true))
  }

  const setEmployee = (e) => {
    let selectedEmployee = all_employees.find(
      (employee) => employee.name === e.target.value
    )
    if (!selectedEmployee || selectedEmployee.id === 0) selectedEmployee = null
    dispatch(selectEmployee(selectedEmployee, false))
  }

  const setLocation = (e) => {
    let selectedLocation = locationChoices.find(
      (location) => location.name === e.target.value
    )
    if (!selectedLocation || selectedLocation.id === 0)
      dispatch(selectLocation(all_locations, true))
    // $FlowIgnore
    else if (selectLocation) dispatch(selectLocation([selectedLocation], true))
  }

  const handleCloseDrawer = () => {
    dispatch(selectEmployee(null, false))
    setShowDrawer(false)
  }

  return (
    <>
      <Stack>
        <div className='users-appointments-container'>
          <div id='filters-container'>
            <div className='section-label'>
              <Text>Filters</Text>
            </div>
            <Card>
              <Select
                label={__('Service')}
                onChange={setService}
                value={selected_service?.name.toString()}
                expand
              >
                {serviceChoices.map((service) => (
                  <option key={service.id} value={service.name.toString()}>
                    {service.name}
                  </option>
                ))}
              </Select>
              <Select
                label={__('Location')}
                onChange={setLocation}
                value={getSelectedLocationDefaultValue(
                  bookingState.selected_locations
                )}
                expand
              >
                {locationChoices.map((location) => {
                  return (
                    <option key={location.id} value={location.name.toString()}>
                      {location.name}
                    </option>
                  )
                })}
              </Select>
              <Select
                label={__('Therapist')}
                onChange={setEmployee}
                value={getEmployeeValue(selected_employee)}
                expand
              >
                {employeeChoices.map((employee) => (
                  <option key={employee.id} value={employee.name}>
                    {employee.name}
                  </option>
                ))}
              </Select>
              <BookingCalendar
                label={__('Date')}
                showAsDatePicker={true}
                availableDates={availableDates}
                selectDateOnChange={true}
                expand={true}
              />
            </Card>
          </div>
          <div className='users'>
            <div className='section-label'>
              <Text>Therapist</Text>
            </div>
            <div className='users-list'>
              {employees &&
                employees.length > 0 &&
                employees.map((emp: Employee) => (
                  <EmployeeCard
                    key={emp.id}
                    employee={emp}
                    locations={getEmployeeLocations(
                      emp,
                      bookingState.all_locations
                    )}
                    setShowDrawer={setShowDrawer}
                    setCurrentEmployee={setCurrentEmployee}
                    isDesktop
                  />
                ))}
              {!employees || (employees.length === 0 && <NoResults />)}
            </div>
          </div>

          {currentEmployee && (
            <Drawer
              onClose={handleCloseDrawer}
              open={showDrawer}
              anchor='right'
              PaperProps={{
                elevation: 24,
              }}
            >
              <SelectTimeslotView
                setShowDrawer={handleCloseDrawer}
                employee={currentEmployee}
              />
            </Drawer>
          )}
        </div>
      </Stack>
    </>
  )
}

const getSelectedLocationDefaultValue = (
  selected_locations: Location[]
): string => {
  if (selected_locations.length == 1) return selected_locations[0].name
  else return 'a'
}

const getEmployeeValue = (selected_employee) => {
  if (selected_employee) return selected_employee.name
  else return 'b'
}

const getEmployeeLocations = (employee, all_locations) => {
  const locations = []
  employee.corporates.forEach((loc) => {
    const corporate = all_locations.find((location) => location.id === loc)
    locations.push(corporate)
  })
  return locations
}

const NoResults = () => {
  return (
    <div className='heading-text-container'>
      <h2><Text>There are no available therapeuts with the given parameters.</Text></h2>
    </div>
  )
}

export default (withOnlineBookingModal(BookingMain): *)
