// @no-flow

export function exit () {
  if (document.mozExitFullScreen)
    return document.mozExitFullScreen()
  if (document.webkitExitFullscreen)
    return document.webkitExitFullscreen()
  if (document.exitFullscreen)
    return document.exitFullscreen()
  throw new TypeError(`Fullscreen not available`)
}

export function open (node: HTMLElement) {
  if (!(node instanceof HTMLElement))
    throw new ReferenceError(`Invalid fullscreen target node`)
  if (node.mozRequestFullScreen)
    return node.mozRequestFullScreen()
  if (node.webkitRequestFullScreen)
    return node.webkitRequestFullScreen()
  if (node.requestFullscreen)
    return node.requestFullscreen()
  throw new TypeError(`Fullscreen not available`)
}

export default module.exports
