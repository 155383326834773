/**
 * @class MultilineTextField
 * @flow
 */

import self from 'autobind-decorator'
import React, { Component, createRef } from 'react'
import getStyle from 'computed-style'
import { calculateVerticalPaddingForElement, isMobile } from 'utils/dom'

type PropsType = {
  value?: string,
  disabled?: boolean,
  onChange?: Function,
  className?: string,
  max_length?: number,
  id?: string,
  scrollable?: boolean,
  minimumLines?: number,
  minimumLinesMobile?: number,
  width?: string,
  required?: boolean
}

type StateType = {
  value: string,
  height: number,
  disabled: boolean,
}


export default class MultilineTextField extends Component<PropsType, StateType> {

  static MINIMUM_LINES: number = 5
  static MINIMUM_LINES_MOBILE: number = 3

  lineHeight: number = 0
  minHeight: number = 0
  node: Function = createRef()

  static defaultValue: string = ''

  constructor (props: PropsType) {
    super(props)
    const value     = props.value || ''
    const disabled  = props.disabled ? true : false
    const height    = parseFloat(getStyle(document.body, 'line-height'))
    this.lineHeight = Math.round(height)
    this.state      = {
      disabled,
      height,
      value,
    }
  }

  // eslint-disable-next-line complexity
  static getDerivedStateFromProps (props: PropsType, state: StateType): Object | null {
    const updates = {}

    if ('disabled' in props && props.disabled !== state.disabled)
      updates.disabled = props.disabled ? true : false

    if (props.value !== state.value) {
      updates.value = props.value
      updates.height = 0
    }

    if (Object.keys(updates).length)
      return updates
    return null
  }

  componentDidUpdate () {
    if (this.state.height === 0)
      this.setState({ height: this.contentHeight })
  }

  //eslint-disable-next-line class-methods-use-this
  get minimumLines (): number {
    return isMobile()
      ? this.props.minimumLinesMobile || MultilineTextField.MINIMUM_LINES_MOBILE
      : this.props.minimumLines || MultilineTextField.MINIMUM_LINES
  }

  componentDidMount () {
    this.minHeight
      = calculateVerticalPaddingForElement(this.node.current)
      + this.lineHeight * this.minimumLines
      + 2

    if (this.state.height < this.minHeight)
      this.setState({ height: this.minHeight })
  }

  get contentHeight (): number {
    if (this.node.current)
      return this.node.current.scrollHeight + 2
    return 0
  }

  get textLength (): number {
    return this.state.value.length
  }

  get className (): string {
    return (this.props.className || '') + (this.props.scrollable ? ' scrollable' : '')
  }

  @self
  update (value: string): void {
    if (typeof this.props.onChange === 'function')
      this.props.onChange(value)
  }

  get style (): Object {
    return {
      overflow:     'hidden',
      resize:       'none',
      height:       `${this.state.height}px`,
      minHeight:    `${this.minHeight}px`,
      lineHeight:   `${this.lineHeight}px`,
      width:        this.props.width || '100%',
      marginBottom: 'auto',
    }
  }

  render (): React$Element<*> {
    const onChange = ({ target: { value }}) =>
      this.update(value)

    const maxCounterStyle = {
      position: 'absolute',
      right:  0,
      top: '0.5rem'
    }

    return <div className='multiline-text-input' style={{ width: this.props.width || '100%' }}>
      { this.props.max_length && <div style={ maxCounterStyle }>{ this.renderLengthCounter() }</div> }
      <textarea
        aria-required={ this.props.required || null }
        maxLength={ this.props.max_length ? this.props.max_length : undefined }
        id={ this.props.id }
        ref={ this.node }
        style={ this.style }
        value={ this.state.value }
        disabled={ this.state.disabled }
        onChange={ onChange }
        className={ this.className }
      />
    </div>
  }

  renderLengthCounter (): React$Element<*> {
    const currentLength = this.textLength
    const maxLength     = this.props.max_length
    const lengthNode    = maxLength && currentLength > maxLength
      ? <span className='color-error'>{ currentLength }</span>
      : <span>{ currentLength }</span>

    return <div className='length-counter text small align-right'>
      { lengthNode }/{ maxLength }
    </div>
  }
}
