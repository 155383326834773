/**
 * @flow
 * @class ListSelectField.js
 * @author mikakattainen<mika.kattainen@fnsfi>
 */
import React, { Component } from 'react'
import self from 'autobind-decorator'
import { Container, Draggable } from 'react-smooth-dnd'

import Button from 'components/buttons'
import Icons from 'components/icons'
import { Caption } from 'components/text'


type ItemType = {
  value: number,
  display_name: string,
  selected?: boolean,
}

type ValueType = Array<ItemType>

type FieldProps = {
  options: ValueType,
  value: ValueType,
  onChange?: Function,
  boxtitles?: boolean,
  optionsTitle?: string,
  valueTitle?: string,
  notranslate?: true | void,
}

type FieldState = {
  options: ValueType
}


export default class ListSelectField extends Component <FieldProps, FieldState> {

  static defaultProps = {
    value: [],
    options: [],
  }

  lastClickIndex: number = 0

  constructor (props: FieldProps) {
    super(props)
    this.state = {
      options: props.options
    }
  }

  get optionsTitle (): string {
    return this.props.optionsTitle || 'Options'
  }

  get valueTitle (): string {
    return this.props.valueTitle || 'Selected'
  }

  @self
  update (value: ValueType): void {
    if (typeof this.props.onChange === 'function')
      this.props.onChange(value)
  }

  @self
  onItemClick (index: number, event: MouseEvent) {
    const options = this.state.options
    if (!event.shiftKey) {
      const item = options[index]
      item.selected = !!!item.selected
      this.lastClickIndex = index
    } else {
      const items = index > this.lastClickIndex
        ? options.slice(this.lastClickIndex, index + 1)
        : options.slice(index, this.lastClickIndex + 1)
      items.forEach(item => item.selected = true)
    }
    event.stopPropagation()
    event.preventDefault()
    this.setState({ options })
  }

  @self
  addSelected () {
    const value = this.props.value
    const options = []
    this.state.options.forEach(item => {
      if (item.selected) {
        item.selected = false
        value.push(item)
      } else
        options.push(item)
    })
    this.update(value)
    this.setState({ options })
  }

  @self
  removeValue (index: number) {
    const value = this.props.value
    const options = this.state.options
    const deleted = value.splice(index, 1)

    options.push(deleted[0])
    this.setState({ options })
    this.update(value)
  }

  @self
  onDropToOptions (dropResult: *) {
    if (!dropResult)
      return

    const { removedIndex, addedIndex, payload } = dropResult

    if (is.number(removedIndex) && is.number(addedIndex))
      return

    const value = this.props.value
    const options = this.state.options

    if (is.number(removedIndex)) {
      options.splice(removedIndex, 1)
      this.update(value)
    } else if (is.number(addedIndex)){
      options.splice(addedIndex, 0, payload)
      this.setState({ options })
    }
  }

  @self
  onDropToValues (dropResult: *) {
    const { removedIndex, addedIndex, payload } = dropResult

    if (is.number(removedIndex) && is.number(addedIndex))
      return

    const value = this.props.value
    const options = this.state.options

    if (is.number(removedIndex)) {
      value.splice(removedIndex, 1)
      this.update(value)
    } else if (is.number(addedIndex)){
      value.splice(addedIndex, 0, payload)
      this.setState({ options })
    }
  }

  render () {
    return <div id='list-select-field'>
      <div className='selection'>
        { this.renderCaption() }
        <div className='selection-box'>
          <Container
            groupName='items'
            onDrop={ this.onDropToOptions }
            getChildPayload={ i => this.state.options[i] }
            dragClass='drag-item'>
            { this.state.options.map(this.optionToListitem) }
          </Container>
        </div>
      </div>
      <Button icon={ Icons.Forward } onClick={ this.addSelected } />
      <div className='selection'>
        { this.renderCaption(true) }
        <div className='selection-box'>
          <Container
            groupName='items'
            onDrop={ this.onDropToValues }
            getChildPayload={ i => this.props.value[i] }
            dragClass='drag-item'>
            { this.props.value.map(this.valueToListitem) }
          </Container>
        </div>
      </div>
    </div>
  }

  renderCaption (forValue?: boolean = false) {
    if (!this.props.boxtitles)
      return null

    let notranslate = this.props.notranslate
    let title = this.optionsTitle
    if (forValue) {
      title = this.valueTitle
    }

    return <Caption notranslate={ notranslate }>
      { title }
    </Caption>
  }

  @self
  optionToListitem (item: ItemType, key: number) {
    const className = `list-item ${item.selected ? ' selected' : ''}`

    return <Draggable key={ item.value }>
      <div
        className={ className }
        onClick={ event => this.onItemClick(key, event)} >
        { item.display_name }
      </div>
    </Draggable>
  }

  @self
  valueToListitem (item: ItemType, key: number) {
    return <Draggable key={ item.value }>
      <div
        className='list-item' >
        { item.display_name }
        <Button
          icon={ Icons.Remove }
          onClick={ () => this.removeValue(key) } />
      </div>
    </Draggable>
  }

}
