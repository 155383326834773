//@flow
import React, { Component, Fragment } from 'react'
import api from 'api'
import self from 'autobind-decorator'
import connect from 'bound-connect'
import { Link } from 'react-router-dom'

import navigator from 'routes/base'
import { addAlert } from 'actions/core'
import { resolveError } from 'utils/resolve'
import PageNotFound from 'views/Error/PageNotFound'

import BaseComposeView from 'components/BaseComposeView'
import Text, { Title, Paragraph } from 'components/text'
import { PrimaryButton } from 'components/buttons'
import { ToolbarContainer, Toolbar, Card } from 'components/generic'
import { SelectField } from 'react-drf/fields'

type Element<+C> = React$Element<C>
import type User from 'models/User'
import type { StateType } from 'store/initialState'
import type { TenantSettingsState } from 'reducers/tenant'


type RegisterClientFormProps = {
    tenantSettings?: TenantSettingsState,
    user?: User
}


@connect
export default class RegisterClientForm extends Component<RegisterClientFormProps> {
    static actions: Object = (dispatch: Function) => ({
      addSuccessMessage:    (content: string) => dispatch(addAlert('success', content)),
      addErrorMessage:    (content: string) => dispatch(addAlert('error', content)),
    })

    static properties: Object = (state: StateType) => ({
      tenantSettings: state.tenantSettings,
      user: state.user
    })

    render (): React$Element<*> {
      if (this.props.user && this.props.user.id)
        return navigator.navigate('/user')
      else if (this.props.tenantSettings && !this.props.tenantSettings.allow_sign_up ||
               this.props.tenantSettings && !this.props.tenantSettings.use_secure_auth)
        return <PageNotFound />


      return <div className='loginform-content'>
        <div className='page-column'>
          <RegisterForm />
        </div>
        <div className='page-column'>
          <Card>
            <Title>
              Sign up to service
            </Title>
            <Paragraph>
              If you don't have an account sign up here. Fill in your email, language and select your personnel.
              You will receive an email containing confirmation. From the link you can finish registration and set password by using your online bank account.
            </Paragraph>
          </Card>
        </div>
      </div>
    }
}


type RegisterFormProps = {
    language: string,
}

type RegisterFormState = {
    loading: boolean,
    data: Object
}


@connect
class RegisterForm extends BaseComposeView<RegisterFormProps, RegisterFormState> {

    state: RegisterFormState = {
      loading: false,
      data: {}
    }

    static actions: Object = (dispatch: Function) => ({
      addSuccessMessage:    (content: string) => dispatch(addAlert('success', content)),
      addErrorMessage:    (content: string) => dispatch(addAlert('error', content)),
    })

    static properties: Object = (state: StateType) => ({
      language: state.tenantSettings.language,
    })

    asCard = true

    fields = [
      {
        name: 'email',
        fullwidth: true,
      }, {
        name: ['profile', 'employees'],
        fullwidth: true,
        props: {
          component: this.StaffComponent
        }
      }, {
        name: [ 'profile', 'primary_language' ],
        props: {
          initialValue: localStorage.getItem('language'),
        },
        fullwidth: true,
      },
    ]

    strings = {
      createSuccessMessage: 'Registration saved successfully and you will receive a confirmation link to your email.',
    }


    //eslint-disable-next-line class-methods-use-this
    get url (): string {
      return '/register/'
    }

    //eslint-disable-next-line class-methods-use-this
    get method (): 'POST' {
      return 'POST'
    }

    //eslint-disable-next-line class-methods-use-this
    get showDelete (): boolean {
      return false
    }

    @self
    async submit () {
      this.setState({ loading: true })
      const data = Object.assign({}, this.state.data)
      if (data.profile && data.profile.employees)
        data.profile.employees = data.profile.employees.value
      const response = await api.post(this.url, data)

      if (response.problem) {
        const errors = response.data
        this.props.addErrorMessage(resolveError(response.status))
        this.setState({ errors, loading:false })
        return
      }

      this.props.addSuccessMessage(this.strings.createSuccessMessage)
      navigator.navigate('/')
    }

    renderTitle (): Element<*> {
      return <Title>Sign up</Title>
    }

    renderFooter (): Element<*> {
      let disabled = this.state.loading

      const submitStyles = {
        marginTop: '2rem'
      }

      return <Fragment>
        <div style={ submitStyles }>
          <ToolbarContainer>
            <Toolbar left>
              <PrimaryButton
                onClick={ this.submit }
                disabled={ disabled }>
                Sign up
              </PrimaryButton>
            </Toolbar>
          </ToolbarContainer>
        </div>
        <div className='sign-up-footer'>
          <Link to='/'><Text>If you already have an account, log in</Text></Link>
        </div>
      </Fragment>
    }

    @self
    //eslint-disable-next-line class-methods-use-this
    StaffComponent (props: *) {
      const choices = props.choices.map(choice => {
        return {
          label: choice.display_name,
          value: choice.value
        }
      })
      return <SelectField
        onDidUpdate={(value) => value ? props.onChange(value) : props.onChange}
        value={ props.value }
        options={ choices }
        placeholder={ <Text>Select your personnel</Text> }  />
    }
}
