
import Symbol from 'es6-symbol'
import api from 'api'
import { isValidElementModelName } from '../fields'

const descriptor = Symbol('api-response-data')


function assertion (fn) {

  return (context, methodName) => {

    const className = context.constructor.name
    const method    = context.constructor.prototype[methodName]

    function runAssertion () {
      if (!fn(this, context))
        throw new ReferenceError(`Invalid call for ${className}.${methodName}`)
    }

    function value () {
      runAssertion.apply(this, arguments)
      return method.apply(this, arguments)
    }

    Object.defineProperty(context.constructor.prototype, methodName, { value })
    return context.constructor
  }
}


const assertDescriptorResolved = (self) => self.isDescriptorResolved

const requiresDescriptor = assertion(assertDescriptorResolved)


class EditorContext {

  [descriptor] = null

  get isDescriptorResolved () {
    return this[descriptor] !== null
  }

  async load () {
    if (!this[descriptor]) {
      const candidate = api.FormDescription(await api.options(`forms`))

      // Only descriptor available is for POST action so
      // we'll extract only the first of the resulting descriptors.
      if (candidate)
        this[descriptor] = [ ...candidate ][0]
    }
    return this[descriptor]
  }

  async whenDescriptorResolved (fn) {
    await this.load()
    return fn()
  }

  @requiresDescriptor
  getFieldsForModel (model) {
    const isValid = isValidElementModelName(model)

    if (!isValid)
      return null

    return this[descriptor]
      .getField('version')
      .properties
      .children
      .elements
      .child
      .choices
      .find(entry => entry.model === model)
      .children
  }

  @requiresDescriptor
  getOptionsByFieldType (type, model = 'Field') {
    const modelFields =
      this.getFieldsForModel(model)

    if (!modelFields.options)
      return null

    const filter = entry =>
      entry.type === type

    const combine = (options, choice) =>
      Object.assign(options, choice.children)

    return modelFields
      .options
      .choices
      .filter(filter)
      .reduce(combine, {})
  }

}


const context = new EditorContext()



export default context
