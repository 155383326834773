// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteNotFound } from 'views/Error/PageNotFound'

import load from '../components/LoadableComponent'

const PreviewDetails = load(() =>
  import('../views/User/DetailsView'))

const EditDetails = load(() =>
  import('../views/User/EditDetails'))

const ChangePassword = load(() =>
  import('../views/User/ChangePassword'))


export const descriptors = {
  view:           '/user/',
  edit:           '/user/edit/',
  changePassword: '/user/password/',
}


const routes =
  <Switch>
    <Route exact path={ descriptors.view } component={ PreviewDetails } />
    <Route exact path={ descriptors.edit } component={ EditDetails } />
    <Route exact path={ descriptors.changePassword } component={ ChangePassword } />
    <RouteNotFound />
  </Switch>


export default routes
