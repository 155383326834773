// @flow
import React from 'react'

import { Button, Stack } from '@nordhealth/react'
import { useDispatch } from 'react-redux'
import { resetBooking } from '../../../actions/onlineBooking'
import navigator from 'routes/base'
import withOnlineBookingModal from '../extra/OnlineBookingModal'

import type { Node } from 'react'

const HEADING: string = 'This site has timed out'
const INFORMATION_TEXT: string =
  'You may close this window or use the button below to start the booking from the beginning'
const BUTTON_TEXT: string = 'Go to the start page'

const TimeoutView = (): Node => {
  const dispatch = useDispatch()

  const backToStart = () => {
    dispatch(resetBooking())
  }

  return (
    <div className='timeout-view-container'>
      <Stack alignItems='center' gap='l'>
        <span className='n-typescale-xl n-font-weight-heading'>{HEADING}</span>
        <span className='timeout-view-info-text n-color-text-weaker'>
          {INFORMATION_TEXT}
        </span>
        <Button variant='primary' onClick={backToStart}>
          {BUTTON_TEXT}
        </Button>
      </Stack>
    </div>
  )
}

export default (withOnlineBookingModal(TimeoutView, true): *)
