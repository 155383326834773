/**
 * @flow
 * @class MultiFieldContainer
 * @author mikakattainen<mika.kattainen@fnsfi>
 */

import React, { Component, Fragment } from 'react'
import connect from 'bound-connect'
import self from 'autobind-decorator'

import classNames from 'classnames'
import { SecondaryButton, DeleteButton } from 'components/buttons'
import { Card, ToolbarContainer, Toolbar, confirmation, Alert } from 'components/generic'
import { Container, Draggable } from 'react-smooth-dnd'
import { ButtonWithTooltip } from 'former-ui/components/WithTooltip'
import Icons from 'components/icons'
import __ from 'utils/gettext'

import type { StateType } from 'store/initialState'


type ContainerProps = {
  subFieldComponent: *,
  minFieldCount?: number,
  maxFieldCount?: number,
  fieldCountDescription?: string,
  defaultSubFieldValue?: Object,
  fullWidth?: boolean,
  onChange: Function,
  value: Array<Object>,
  addButtonStr?: string,
  subFieldProps?: Object,
  disabled?: boolean,
  onDelete?: Function,
  comfirmDeleteTitle?: string,
  comfirmDeleteMessage?: string,
  deleteSuccessMessage?: string,
  orderable?: boolean,
  cards?: boolean,
  showAddButton?: boolean,
  onSave?: Function,
  onEdit?: Function,
  onCancel?: Function,
  addErrorMessage?: Function,
  toggleable?: boolean,
  errors?: Array<*>,
  customData?: *,
  color?: string,
  forceUpdate?: boolean,
}

type ContainerState = {}

const NO_DRAG_SELECTOR = 'no-drag'


@connect
export default class MultiFieldContainer extends Component <ContainerProps, ContainerState> {

  static actions: Object = () => {}
  static properties: Object = (state: StateType) => ({
    color: state.tenantSettings.color,
  })

  static defaultProps: Object = {
    minFieldCount: 0,
    defaultSubFieldValue: null,
    value: [],
    addButtonStr: 'Add',
    comfirmDeleteTitle: 'Delete',
    comfirmDeleteMessage: 'Are you sure you want to delete this field?',
    deleteSuccessMessage: 'Field was deleted',
    orderable: false,
    cards: false,
    showAddButton: true,
    customData: undefined
  }

  componentDidMount () {
    if (!this.props.subFieldComponent)
      throw new TypeError('The subFieldComponent of MultiFieldContainer must be defined.')

    if (this.props.value && this.props.value.length < this.props.minFieldCount) {
      const values = this.props.value
      for (let i = this.props.value.length; i < this.props.minFieldCount; i++) {
        values.push(this.props.defaultSubFieldValue)
      }
      this.props.onChange(values)
    }

  }

  @self
  update (values: Array<*>, index?: number, reorder?: { from: number, to: number }) {
    this.props.onChange && this.props.onChange(values, index, reorder)
    // When used with BCV - data change does not cause re-render
    // Thus the forceUpdate: true prop should be passed to the field
    if (this.props.forceUpdate)
      this.forceUpdate()
  }

  @self
  addSubField () {
    if (this.props.maxFieldCount && this.props.value.length >= this.props.maxFieldCount)
      return
    const values = this.props.value
    values.push( this.props.defaultSubFieldValue || {} )
    this.update(values)
  }

  @self
  removeSubField (index: number) {
    if (!this.props.onDelete) return

    if (this.props.cards) {
      confirmation(this.props.comfirmDeleteTitle, this.props.comfirmDeleteMessage, this.props.color).then(
        () => {
          this.props.onDelete(index)
          const value = this.props.value
          value.splice(index, 1)
          this.update(value)
        },
        () => {})
    } else {
      this.props.onDelete(index)
      const value = this.props.value
      value.splice(index, 1)
      this.update(value)
    }
  }

  @self
  onChange (index: number, value: *, sendIndex: boolean) {
    const values = this.props.value
    values[index] = value
    this.update(values, sendIndex ? index : undefined)
  }

  @self
  onDrop (dragResult: *) {
    if (!dragResult)
      return

    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return this.props.value

    const result = this.props.value
    let itemToAdd = payload

    if (removedIndex !== null)
      itemToAdd = result.splice(removedIndex, 1)[0]

    if (addedIndex !== null)
      result.splice(addedIndex, 0, itemToAdd)

    result.forEach((value, order) => value.order = order)

    this.update(result, undefined, { from: removedIndex, to: addedIndex })
  }

  render () {
    return this.props.orderable
      ? this.renderDraggableList()
      : this.renderContainer()
  }

  renderContainer () {
    return <div className='multi-field-container'>
      { this.mapSubFields() }
      { this.props.showAddButton && <SecondaryButton small onClick={ this.addSubField }>{ this.props.addButtonStr }</SecondaryButton>}
    </div>
  }

  mapSubFields (): Array<React$Element<*>> | null {
    let skip: boolean = false
    return this.props.value.map((value, key) => {
      if (this.props.fullWidth)
        return <Fragment
          key={ key }>
          { this.renderSubField(value, key) }
          <hr className='section-divider'/>
        </Fragment>
      else if (!skip){
        skip = true
        return <div
          key={ key }>
          <div className='six columns'>
            { this.renderSubField(value, key) }
          </div>
          { this.props.value.length > key + 1 && <div className='six columns'>
            { this.renderSubField(this.props.value[key + 1], key + 1) }
          </div> }
        </div>
      } else {
        skip = false
        return null
      }
    })
  }

  renderDraggableList () {
    return <div className='multi-field-container fields'>
      <Container
        nonDragAreaSelector={ '.' + NO_DRAG_SELECTOR }
        lockAxis='y'
        onDrop={ this.onDrop }
        style={{ minHeight: '10px' }}>
        { this.mapDraggableFields() }
      </Container>
      { this.renderAddButton() }
    </div>
  }

  renderAddButton () {
    const {
      addButtonStr,
      fieldCountDescription,
      maxFieldCount,
      showAddButton,
    } = this.props

    if (!showAddButton)
      return null

    if (fieldCountDescription && maxFieldCount && this.props.value.length >= maxFieldCount)
      return <Alert persistent info>{ fieldCountDescription }</Alert>

    return <Card style={{ width: 'auto' }}>
      <ToolbarContainer classNme='footer' >
        <Toolbar left>
          <SecondaryButton small onClick={ this.addSubField }>
            { addButtonStr }
          </SecondaryButton>
        </Toolbar>
      </ToolbarContainer>
    </Card>
  }

  mapDraggableFields (): Array<React$Element<*>> {
    return this.props.value.map((value, key) => <Draggable key={ key }>
      { this.renderSubField(value, key) }
      { !this.props.cards && <hr className='section-divider'/>}
    </Draggable>
    )
  }

  onSave (index: number) {
    const value = this.props.value[index]
    if (this.props.onSave)
      this.props.onSave(value, index)
  }

  onEdit (index: number) {
    if (this.props.onEdit)
      this.props.onEdit(index)
  }

  onCancel (index: number) {
    if (this.props.onCancel)
      this.props.onCancel(index)
    else if (this.props.onEdit)
      this.props.onEdit(index)
  }

  @self
  getErrors (index: number) {
    if (!this.props.errors)
      return null
    const fieldErrors = this.props.errors.find(error => error.index === index)
    return fieldErrors ? fieldErrors.errors : null
  }

  renderSubField (value: *, key: number) {
    const Field = this.props.subFieldComponent
    const Component = this.props.cards ? Card : 'div'
    const errors = this.getErrors(key)
    const className = classNames(
      'subfield',
      this.props.cards && 'field-block',
      errors && 'errors',
    )
    return <Component className={ className } >
      <Field
        index={ key }
        customData={this.props.customData}
        onChange={ (value, sendIndex? = true) => this.onChange(key, value, sendIndex) }
        value={ value }
        { ...this.props.subFieldProps }
        disabled={ this.props.disabled }
        nodragselector={ NO_DRAG_SELECTOR }
        errors={ errors } />
      { this.renderToolbar(value, key) }
    </Component>
  }

  renderToolbar (value: *, key: number) {

    if (this.props.cards)
      return this.renderSideBar(value, key)

    return <Toolbar right>
      { this.props.onSave &&
        <SecondaryButton small onClick={ () => this.onSave(key) }>
          Save
        </SecondaryButton> }
      <DeleteButton
        disabled={ !!(this.props.disabled || value.read_only) }
        onClick={ () => this.removeSubField(key) }
        confirmationTitle={ this.props.comfirmDeleteTitle }
        confirmationLabel={ this.props.comfirmDeleteMessage }
        successMessage={ this.props.deleteSuccessMessage }
        small icon>
        Delete
      </DeleteButton>
    </Toolbar>
  }

  renderSideBar (value: *, key: number) {
    return <nav className='text toolbar actions'>

      { this.props.orderable && !value.open && <ButtonWithTooltip
        text={ __('Drag') }
        icon={ Icons.Menu } /> }

      { this.renderSaveButton(value, key) }

      { this.renderCancelButton(value, key)}

      { this.renderEditButton(value, key) }

      <ButtonWithTooltip
        text={ __('Delete') }
        className={ NO_DRAG_SELECTOR }
        className='error'
        onClick={ () => this.removeSubField(key) }
        icon={ Icons.Remove } />
    </nav>
  }

  renderSaveButton (value: *, key: number) {
    if (this.props.toggleable && !value.open)
      return null
    return this.props.onSave && <ButtonWithTooltip
      text={ __('Save') }
      className={ 'success filled ' + NO_DRAG_SELECTOR }
      onClick={ () => this.onSave(key) }
      icon={ Icons.Success } />
  }

  renderEditButton (value: *, key: number) {
    if (this.props.toggleable && value.open)
      return null
    return this.props.onEdit && <ButtonWithTooltip
      text={ __('Edit') }
      className={ NO_DRAG_SELECTOR }
      onClick={ () => this.onEdit(key) }
      icon={ Icons.Edit } />
  }

  renderCancelButton (value: *, key: number) {
    if (this.props.toggleable && !value.open || !this.props.onCancel && !this.props.onEdit)
      return null
    return <ButtonWithTooltip
      text={ __('Cancel') }
      className={ NO_DRAG_SELECTOR }
      onClick={ () => this.onCancel(key) }
      icon={ Icons.Cancel } />
  }
}
