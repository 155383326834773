// @flow

import type { FieldTypesEnum } from '..'
import type { Node } from 'react'
import type { Disposable } from 'event-kit'

import self from 'autobind-decorator'
import { Emitter } from 'event-kit'
import React, { Component } from 'react'
import RequiredIndicator from '../components/Indicator'
import { Subtle } from 'components/text'
import Icon from 'components/icons'
import HelpIcon from 'components/HelpIcon'
import ErrorList from '../ErrorList'

import type { StateType } from 'store/initialState'

export type ErrorsListType = Array<Error> | null
export type LabelType = string | null

export type FieldProperties = {
  name: string,
  type: string,
  required: boolean,
  read_only: boolean,
  errors: ErrorsListType,
  widget?: FieldTypesEnum,
  label?: LabelType,
  labelElement?: *,
  options?: Object,
  fullWidth?: boolean,
  disabled?: boolean,
  noRequiredIndicator?: boolean,
  help_text?: string,
  helpIcons?: boolean,
  isPrinting?: boolean,
  forceHelpIcon?: boolean,
  username?: string,
}

export type StaticFieldState = {
  errors: ErrorsListType,
}

export type ValidationStateEnum = 'success' | 'error' | 'initial'


export default class Field<
  ValueType,
  AdditionalProperties = {},
  Properties:
    FieldProperties &
    AdditionalProperties & {
      initial?: ValueType,
      disabled?: boolean,
      onChange: ValueType => void,
    } = {},
  StateType:
    StaticFieldState & {
      value: ValueType
    } = {}>

  extends Component<Properties, StateType> {

  type: string
  clear: () => void
  update: (value: ValueType, name?: ?string, fieldType?: string, isInitial?: boolean) => void
  emitter: Emitter

  static defaultInitialValue: ValueType

  constructor (props: Properties) {
    super(props)

    if (!this.type)
      this.type = this.props.type

    this.state = {
      value: this.getInitialValue(),
      errors: null,
    }
    this.emitter = new Emitter()
  }

  componentDidMount () {
    this.updateState(this.props)
  }

  static getDerivedStateFromProps (props: *): Function {
    return handleNewProperties(props)
  }

  @self
  updateState (props: *) {
    this.setState(handleNewProperties(props))
  }

  @self
  handleChange (event: Event) {
    this.props.onChange(event)
  }

  getInitialValue (): ValueType {
    return this.props.initial || this.constructor.defaultInitialValue
  }

  get options (): Map<*, *> {
    if (typeof this.props.options === 'object' && this.props.options !== null)
      return new Map(Object.entries(this.props.options))

    return new Map()
  }

  @self
  getOptionValue (key: $Keys<Options>, fallbackValue = null): $Values<Options> {
    const options = this.options
    return options.has(key)
      ? options.get(key)
      : fallbackValue
  }

  getValidationState (): ValidationStateEnum {
    if (this.state.errors === null)
      return 'initial'
    else if (this.state.errors.length)
      return 'error'

    return 'success'
  }

  get validityIndicator (): ?React$Element<*> {
    let state = this.getValidationState()

    if (this.props.required && !this.props.noRequiredIndicator)
      return <RequiredIndicator />

    if (state === 'success')
      return <Icon.SuccessFilled />

    if (state === 'error')
      return <Icon.Error />

    return null
  }

  get readonly (): boolean {
    return toBoolean(this.props.read_only)
  }

  get name (): string {
    return this.props.name
  }

  get value (): ValueType | null {
    if (typeof this.state.value === 'string' && this.state.value === '')
      return null

    return this.state.value
  }

  get disabled (): boolean {
    return this.props.disabled || false
  }

  @self
  update (value: ValueType, name: ?string, fieldType?: string, isInitial?: boolean) {
    this.setState({ value })
    if (typeof this.props.onChange === 'function') {
      if (!name)
        name = this.props.name
      if (!fieldType && this.props.type === 'string')
        fieldType = 'text'
      this.props.onChange(value, name, fieldType, isInitial)
    }

    this.emitter.emit('did-change', value)
  }

  @self
  clear () {
    let initialValue = this.getInitialValue()
    this.update(initialValue)
    this.emitter.emit('did-reset', initialValue)
  }

  @self
  onChange (event: SyntheticInputEvent<*> | null) {
    const type = (event.target.type === 'range' || event.target.steps)
      ? 'numeric'
      : 'text'
    if (!event || !event.target || typeof event.target.value === 'undefined')
      throw new ReferenceError('Got invalid event target when calling Field.prototype.onChange')
    this.update(event.target.value, this.props.name, type)
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  renderFormControl (value: ValueType): ?Node {
    throw new ReferenceError(
      'Subclasses of Field must implement a renderFormControl method for rendering the input element.'
    )
  }

  get controlLabelElement (): * {
    return this.props.labelElement || Label
  }

  renderControlLabel (): Node {
    if (!this.props.label || this.type === 'slider choice')
      return null

    return (
      <this.controlLabelElement notranslate id={this.name}>
        {this.props.label}
        {(this.props.forceHelpIcon || this.props.helpIcons) && <HelpIcon text={ this.props.help_text } />}
        {this.validityIndicator}
      </this.controlLabelElement>
    )
  }

  renderHelpText (): Node {
    if (!this.props.help_text || this.props.helpIcons || this.props.forceHelpIcon)
      return null

    return <Subtle notranslate>
      {this.props.help_text}
    </Subtle>
  }

  renderHelpBlock (): Node {
    if (!(this.state.errors && this.state.errors.length))
      return null

    return <ErrorList errors={this.state.errors} />
  }

  render (): Node {
    if (this.readonly)
      return null

    let className = 'form-field field ' + this.getValidationState()
    if (this.props.required) className += ' required'
    if (this.props.disabled) className += ' disabled'
    if (this.props.fullWidth) className += ' full-width'


    let role
    if (this.props.type === 'radio choice')
      role = 'radiogroup'
    else if (this.props.type === 'checkbox choice')
      role = 'group'
    else
      role = undefined

    // aria-required should normally be added to input elements but this doesn't work
    // with a group of radio buttons so add it to the surrounding div in single choice elements
    const ariaRequired = this.props.required && (this.props.type === 'radio choice' || this.props.type === 'checkbox choice')
      ? 'true'
      : undefined
    const ariaLabelledby = this.props.type === 'radio choice' || this.props.type === 'checkbox choice'
      ? this.props.name + '-label'
      : undefined

    return (
      <div
        className={className}
        role={ role }
        aria-required={ ariaRequired }
        aria-labelledby={ ariaLabelledby }>
        {this.renderControlLabel()}
        {this.renderHelpText()}
        {this.renderFormControl(this.state.value)}
        {this.renderHelpBlock()}
      </div>
    )
  }

  toJSON (): { [string]: ValueType } {
    if (this.readonly)
      return {}

    return {
      [this.name]: this.value,
    }
  }

  @self
  onDidClear (fn: Function): Disposable {
    return this.emitter.on('did-reset', fn)
  }

  @self
  onDidChange (fn: Function): Disposable {
    return this.emitter.on('did-change', fn)
  }
}

const Label = (props) =>
  <label className='label' htmlFor={props.id}>{props.children}</label>

const handleNewProperties = (props: FieldProperties) => {
  const updates: StateType = {}

  // Value
  if ('value' in props)
    updates.value = props.value

  // Errors
  if (props.errors instanceof Array)
    updates.errors = props.errors
  else if (typeof props.errors === 'undefined')
    updates.errors = null

  // Dispatch the update if changes occur
  if (Object.keys(updates).length)
    return updates

  return null
}

const toBoolean = (value: any): boolean =>
  !!(!isNaN(parseInt(value)) ? parseInt(value) : value)
