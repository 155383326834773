// @flow
import type { Action } from '.'
import { UPDATE_COLLECTION, UPDATE_TAGS, UPDATE_SEARCH, UPDATE_OWN_EXERCISES } from 'actions/exercises'

export type ExercisesState = {
  collection: number | null,
  tags: number | null,
  search: string | null,
  own_exercises: boolean | null,
}

export const initialValue: ExercisesState = {
  collection:  null,
  tags: null,
  search: null,
  own_exercises: null,
}


export default function exercisesReducer (state: ExercisesState, action: Action) {
  const { payload } = action
  switch (action.type) {

    case UPDATE_COLLECTION:
      return { ...state, collection: payload }

    case UPDATE_TAGS:
      return { ...state, tags: payload }

    case UPDATE_SEARCH:
      return { ...state, search: payload }

    case UPDATE_OWN_EXERCISES:
      return { ...state, own_exercises: payload }

    default:
      return state || initialValue

  }
}
