// @flow

import React, { Component } from 'react'
import self from 'autobind-decorator'
import Sidebar from 'react-sidebar'
import is from '@sindresorhus/is'

import { Badge } from 'components/generic'
import Text from 'components/text'
import navigator from 'routes/base'

import Icon from 'components/icons'


type ItemType = {
  label: string,
  action: string | Function,
  collapsed?: string,
  restricted?: boolean
}

type SideMenuProps = {
  items: Array<ItemType>,
  logo?: string,
  userName: string
}

type SideMenuState = {
  open: boolean
}


export default class SideMenuComponent extends Component <SideMenuProps, SideMenuState> {

  state: SideMenuState = {
    open: false,
  }

  @self
  toggleOpen () {
    this.setState({ open: !this.state.open })
  }

  renderContent (): React$Element<*> {

    const items = this.props.items
    const menuItems = []
    const managementItems = []
    const logoutItem = []

    items.forEach((item, index) => {
      if (index === items.length - 1)
        logoutItem.push(item)
      else if (item.collapsed)
        managementItems.push(item)
      else
        menuItems.push(item)
    })

    return <div className='sidemenu-content'>
      <div className='user-info'>
        <div className='close-icon' onClick={ this.toggleOpen } tabIndex={ 0 } onKeyPress={ this.toggleOpen }><Icon.Cancel/></div>
        { this.props.logo && <img className='logo' onClick={ () =>  navigator.navigate('/') } src={ this.props.logo } alt='' />}
        { this.props.userName }
      </div>
      { menuItems.length ? <div className='menu-items'>
        <ul className='menu'>
          { menuItems.map(this.renderMenuItem) }
        </ul>
      </div> : null }
      { managementItems.length ? <div className='management'>
        <ul className='menu'>
          { managementItems.map(this.renderMenuItem) }
        </ul>
      </div> : null }
      { logoutItem.length ? <div className='logout'>
        <ul className='menu'>
          { logoutItem.map(this.renderMenuItem) }
        </ul>
      </div> : null }
    </div>
  }

  @self
  itemAction (action: string | Function, restricted: boolean) {
    if (restricted)
      return
    if (is.string(action))
      this.navigate(action)
    else if (typeof action === 'function')
      action()
    else
      throw new TypeError(`The type of side menu items action should be "string" or "function". Recieved ${typeof action}.`)
  }

  @self
  renderMenuItem (item: ItemType, key: number): React$Element<*> {
    const restricted = item.restricted
      ? item.restricted
      : false
    return <li
      key={ key }
      className={ resolveItemClassNames(item, key) }
      onClick={ () => this.itemAction(item.action, restricted) }>
      <a
        id={ 'menu-item-' + key }
        tabIndex={ 0 }
        className='focusable'
        onKeyPress={ () => this.itemAction(item.action, restricted) }>
        <Text>{ item.label }</Text></a>
      { restricted && <Badge>{ 'PRO' }</Badge> }
    </li>
  }

  navigate (url: string) {
    navigator.navigate(url)
    this.toggleOpen()
  }

  render (): React$Element<*> | null {
    if (this.state.open)
      return <Sidebar
        sidebar={ this.renderContent() }
        open={ this.state.open }
        onSetOpen={ this.toggleOpen }
        styles={{ sidebar: { background: 'white' }}}
        rootClassName='sidemenu'
        sidebarClassName='sidemenu-bar'
        overlayClassName='sidemenu-overlay' >
        { '' }
      </Sidebar>
    else
      return null
  }
}

function resolveItemClassNames (item: ItemType, index: number): string {
  const url = window.location.pathname
  if (url.startsWith(item.action) || url === '/' && index === 0)
    return 'item active'
  if (item.restricted)
    return 'item disabled'
  return 'item'
}
