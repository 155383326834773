// @flow
import React from 'react'

import { Button, Card, Dropdown, DropdownItem, Icon } from '@nordhealth/react'
import { confirmation } from 'components/generic'
import { formatDate } from 'utils/formatters'
import { __ } from 'utils/gettext'

import type { Node } from 'react'
import useAppointments from '../AppointmentsContext'

export type Appointment = {
  id: number,
  employee: {
    id: number,
    firstname: string,
    lastname: string,
  },
  service: {
    id: number,
    name: string,
    description: string,
    name: string,
  },
  location: {
    id: number,
    name: string,
    address: {
      streetAddress: string,
      postalCode: string,
      postOffice: string,
    },
  },
  start: string,
  end: string,
  cancellation_token: string | null,
}

type Props = {
  isMobile: boolean,
} & Appointment

const AppointmentCard = (props: Props): Node => {
  return (
    <Card>
      <div className='appointment-card'>
        <div className='appointment-card-content'>
          <div className='appointment-card-general-info'>
            <CardSlot title='Date' value={getDate(props.start)} />
            <CardSlot
              title='Time'
              value={getTimeInterval(props.start, props.end)}
            />
            <CardSlot title='Service' value={props.service.name} />
            <CardSlot
              title='Therapist'
              value={`${props.employee.firstname} ${props.employee.lastname}`}
            />
            <CardSlot title='Location' value={props.location.name} />
          </div>
        </div>
        <ActionsDropdown
          isMobile={props.isMobile}
          cancellation_token={props.cancellation_token}
          id={props.id}
        />
      </div>
    </Card>
  )
}

type CardSlotProps = {
  title: string,
  value: string,
}

const CardSlot = ({ title, value }: CardSlotProps): Node => {
  return (
    <div className='card-slot'>
      <div className='card-slot-title n-font-weight-heading'>{__(title)}</div>
      <div className='card-slot-subtitle'>{value}</div>
    </div>
  )
}

const getDate = (start: string): string => {
  // start is in format YYYY-MM-DD HH:mm:SS
  const date = start.split('T')[0]
  const formatted = formatDate(date, 'DD/MM/YYYY')
  return formatted
}
const getTimeInterval = (start: string, end: string): string => {
  const startTime = start.split('T')[1]
  const endTime = end.split('T')[1]
  const startTimeWithoutSeconds = startTime.substring(0, 5)
  const endTimeWithoutSeconds = endTime.substring(0, 5)
  return `${startTimeWithoutSeconds} - ${endTimeWithoutSeconds}`
}

type ActionDropdownProps = {
  isMobile: boolean,
  cancellation_token: string | null,
  id: number,
}

const ActionsDropdown = (props: ActionDropdownProps): Node => {
  const { onCancel, showPast } = useAppointments()
  const item = props.cancellation_token ? (
    <DropdownItem
      onClick={() => {
        confirmation(
          'Cancel appointment',
          'Are you sure you want to cancel appointment?'
        ).then(
          /* $FlowIgnore */
          () => onCancel(props.id, props.cancellation_token)
        )
      }}
    >
      {__('Cancel')}
    </DropdownItem>
  ) : (
    <DropdownItem>{__('Appointment cannot be cancelled')}</DropdownItem>
  )

  return (
    !showPast && (
      <div>
        <Dropdown
          expand={props.isMobile}
          align={props.isMobile ? 'start' : 'end'}
        >
          <Button slot='toggle' variant='primary' expand={props.isMobile}>
            <div className='dropdown-button-content'>
              <Icon name='interface-menu-small' />
              {props.isMobile && <span> {__('Options')}</span>}
            </div>
          </Button>
          {/* TODO: Functionality TBD
        <DropdownItem>Copy</DropdownItem>
        <DropdownItem>Reschedule</DropdownItem>
        <DropdownItem>Pay</DropdownItem> */}
          {item}
        </Dropdown>
      </div>
    )
  )
}

export default AppointmentCard
