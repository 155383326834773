// @flow
import React, { Component, Fragment } from 'react'
import connect from 'bound-connect'

import routes from 'routes/login'
import Alerts from '../Alerts'

import type { StateType } from 'store/initialState'
import HeadNavigation from 'views/Page/HeadNavigation'
import { __ } from 'utils/gettext'
import LockedTenantView from './LockedTenantView'


type loginProps = {
  loginRequired: boolean,
  isTenantLocked: boolean
}


@connect
export default class Login extends Component<loginProps, {}> {

  static properties: Object = (state: StateType) => ({
    loginRequired: state.user.loginRequired,
    isTenantLocked: state.tenantSettings.locked
  })

  static actions: Object= () => ({})

  get srTitle (): string {
    switch (location.pathname) {
      case '/':
        return __('Login')
      case '/register/':
        return __('Register')
      case '/password-reset/':
        return __('Password reset')
    }

    return __('Login')
  }


  render (): React$Element<*> | null {
    if (this.props.isTenantLocked) {
      return <Fragment>
        <HeadNavigation
          url={ '' }
          items={ [] }
          login />
        <main id='login' data-name='login'>
          <LockedTenantView />
        </main>
      </Fragment>
    }

    if (this.props.loginRequired === false)
      return null

    return <Fragment>
      <HeadNavigation
        url={ '' }
        items={ [] }
        login />

      <main id='login' data-name='login'>
        <div className='login-alerts'>
          <Alerts login items={ new Map() }/>
        </div>
        <div className='page-contents'>
          <h1 className='sr-only'>{ this.srTitle }</h1>
          { routes }
        </div>
      </main>
    </Fragment>
  }

}
