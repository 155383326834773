import React, { Component, Fragment } from 'react'

import Icon from 'components/icons'
import Dropdown from 'components/Dropdown'
import Text, { SmallText } from 'components/text'
import { Token } from 'components/generic'
import Button from 'components/buttons'


type ChoiceType = {
  label: string,
  value: Array<number>,
  selected?: boolean,
}

type PropType = {
  choices: Array<ChoiceType>,
  onChange: Function,
  buttonText: string,
  label?: string,
  modal?: boolean,
}

type StateType = {}


export default class MultiSelectDropdownField extends Component <PropType, StateType> {

  select (index: number) {
    const choices = this.props.choices
    choices[index].selected = !choices[index].selected
    this.props.onChange(choices)
  }

  get label () {
    if (!this.props.label) return null
    return <label className='label field-label'>
      <SmallText>{ this.props.label || this.props.buttonText }</SmallText>
    </label>
  }

  render () {

    const choices = this.props.choices.map((choice, key) => ({
      label: 'Delete',
      onClick: () => this.select(key),
      component: <Option choice={ choice } />,
      className: 'multi-select-dropdown-item',
    }))

    return <Fragment>
      { this.label }
      <div id='multi-select-dropdown' className='field-control'>
        <Dropdown
          toggleComponent={ <SelectComponent
            count={ this.props.choices.filter(choice => choice.selected).length }
            text={ this.props.buttonText } /> }
          items={ choices|| [] }
          hideOnSelect={ false }
          modal={ this.props.modal }
          paddingsmall
          className='multi-select-dropdown' />
      </div>
    </Fragment>
  }
}


const SelectComponent = props =>
  <Button notranslate>
    <Text>{ props.text }</Text>
    { props.count ? <Token primary tiny>{ props.count }</Token> : null }
    <Icon.Caret size={ 14 } />
  </Button>

const Option = props =>
  <div className={ props.choice.selected ? 'selected' : null }>
    { props.choice.label }
    { props.choice.selected && <Icon.Success size={ 12 } className='primary' /> }
  </div>
