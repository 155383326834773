// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteNotFound } from 'views/Error/PageNotFound'

import load from '../components/LoadableComponent'

const TabView = load(() =>
  import('../views/Scheduling/TabView'))

const EditEventview = load(() =>
  import('../views/Scheduling/EventDetails'))

const ConfirmSignatureView = load(() =>
  import('../views/Documents/ConfirmSignatureView'))


export const descriptors = {
  scheduling:                       '/scheduling/',
  eventDetails: (id: ID): string => `/scheduling/events/${id}`,
  confirmSignature: (event_id: ID, id: number): string => `/scheduling/events/${event_id}/document/${id}/confirm-signature/`,

  // API
  copyEvents:          '/events/copy/',
  listEvents:          '/events/',
  createCourseEvent:   '/events/course/',
  createExerciseEvent: '/events/exercise/',
  createFormEvent:     '/events/form/',
  createMessageEvent:  '/events/message/',
  createDocumentEvent: '/events/document/',

  updateEvent: (pk: ID): string =>  `/events/${pk}/`,
  confirmEvent: (pk: ID): string => `/events/${pk}/confirm/`,
}


export default <Switch>
  <Route exact path={ descriptors.scheduling } component={ TabView } />
  <Route exact path={ descriptors.eventDetails(':id(\\d+)') } component={ EditEventview } />
  <Route exact path={ descriptors.confirmSignature(':event_id(\\d+)', ':id(\\d+)') } component={ ConfirmSignatureView } />
  <RouteNotFound />
</Switch>
