// @flow
import type {
  Action
} from '../reducers'

export const ADD_FORMS = 'ADD_FORMS'
export const FILTER_FORMS = 'FILTER_FORMS'
export const ADD_FORM_SUBMISSIONS = 'ADD_FORM_SUBMISSIONS'
export const FILTER_FORM_SUBMISSIONS = 'FILTER_FORM_SUBMISSIONS'
export const ADD_FORM_SUBMISSION_REQUESTS = 'ADD_FORM_SUBMISSION_REQUESTS'
export const UPDATE_PENDING_ATTACHMENTS_COUNT = 'UPDATE_PENDING_ATTACHMENTS_COUNT'
export const UPDATE_TAGS = 'UPDATE_FORM_SUBMISSIONS_TAGS'

export const APPEND_FIELD = 'APPEND_FIELD'
export const REMOVE_FIELD = 'REMOVE_FIELD'


export const pendingAttachments = () => (dispatch: Action => * , getState: Function) => {
  const count = getState().forms.forms.attachmentsCount
  return count != undefined && count != 0
}

export const updatePendingAttachments = (count: number): Action => ({
  type: UPDATE_PENDING_ATTACHMENTS_COUNT,
  payload: count
})

export const appendField = (payload: Object): Action => ({
  type: APPEND_FIELD,
  payload
})

export const addForms = (items: Array < Object > ): Action => ({
  type: ADD_FORMS,
  payload: items
})

export const addFormSubmissions = (items: Array < Object > ): Action => ({
  type: ADD_FORM_SUBMISSIONS,
  payload: items
})

export const filterFormsByTag = (tag: string): Action => ({
  type: FILTER_FORMS,
  payload: {
    value: tag,
    property: 'tag',
  }
})

export const filterFormsBySearch = (search: string): Action => ({
  type: FILTER_FORMS,
  payload: {
    value: search,
    property: 'search'
  }
})

export const clearFilters = (): Action => ({
  type: FILTER_FORMS,
  payload: [{
    value: [],
    property: 'tag',
  }, {
    value: null,
    property: 'search',
  }]
})

export const filterFormSubmissionsByUserId = (user_id: number): Action => ({
  type: FILTER_FORM_SUBMISSIONS,
  payload: {
    value: user_id,
    property: 'user_id',
  }
})

export const filterFormSubmissionsByTags = (tags: Array < Object > ): Action => ({
  type: FILTER_FORM_SUBMISSIONS,
  payload: {
    value: tags,
    property: 'tags',
  }
})

export const filterFormSubmissionsBySearch = (search: string): Action => ({
  type: FILTER_FORM_SUBMISSIONS,
  payload: {
    value: search,
    property: 'search'
  }
})

export const filterFormSubmissionsByEmployee = (employee: number): Action => ({
  type: FILTER_FORM_SUBMISSIONS,
  payload: {
    value: employee,
    property: 'employee'
  }
})

export const clearSubmissionFilter = (key: * ): Action => ({
  type: FILTER_FORM_SUBMISSIONS,
  payload: [{
    value: [],
    property: 'tags',
  }, {
    value: null,
    property: 'search',
  }]
})


export const addSubmissionRequests = (items: Array < Object > ): Action => ({
  type: ADD_FORM_SUBMISSION_REQUESTS,
  payload: items
})
