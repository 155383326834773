// @flow
import is from '@sindresorhus/is'
import { isPublicTenant } from 'actions/user'
import announcements from 'api/announcements'
import notifications from 'api/notifications'
import type { Action } from '../reducers'
import type { Location } from '../reducers/core'



// Token/auth actions
export const LOGIN  = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// Alert actions
export const ADD_ALERT = 'ADD_ALERT'
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'
export const REMOVE_ALERT = 'REMOVE_ALERTS'
export const CLEAR_ALERTS = 'CLEAR_ALERTS'
export const CLEAR_ANNOUNCEMENTS = 'CLEAR_ANNOUNCEMENTS'
export const UPDATE_SHOW_CONTRACT = 'UPDATE_SHOW_CONTRACT'
export const CLEAR_ERROR_ALERTS = 'CLEAR_ERROR_ALERTS'

// Router actions
export const POP_ROUTE = 'POP_ROUTE'
export const PUSH_ROUTE = 'PUSH_ROUTE'

// Wootric actions
export const INIT_WOOTRIC = 'INIT_WOOTRIC'

// Notification actions
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'


/*----------ANNOUNCEMENTS----------*/

export const getAnnouncements = (): Function => async (dispatch: Action => *) => {
  if (!isPublicTenant()) {
    const a = await announcements.list()
    a.forEach(announcement => {
      dispatch(addAnnouncement(announcement))
    })
  }
}

export const addAnnouncement = (content: any): Action => ({
  type: ADD_ALERT,
  payload: {
    style: content.type,
    content,
    isAnnouncement: true
  },
})

export const clearAnnouncements = (): Action => ({
  type: CLEAR_ANNOUNCEMENTS,
  payload: null,
})


/*----------ALERTS----------*/
export const addAlert = (
  style: string,
  content: string | React$Element<*>,
  deleteAfterNavigations?: number,
  title?: string,
  notranslate?: boolean
): Action => ({
  type: ADD_ALERT,
  payload: {
    style,
    content,
    deleteAfter: deleteAfterNavigations && !is.nan(deleteAfterNavigations)
      ? deleteAfterNavigations
      : style === 'success'
        ? 2
        : 1,
    title,
    notranslate
  },
})

export const removeAlert = (id: number): Action => ({
  type: REMOVE_ALERT,
  payload: id,
})

export const clearAlerts = (): Action => ({
  type: CLEAR_ALERTS,
  payload: null,
})

export const clearErrorAlerts = (): Action => ({
  type: CLEAR_ERROR_ALERTS,
  payload: null,
})


/*----------ROUTES----------*/
export const pushRoute = (route: Location): Action => ({
  type: PUSH_ROUTE,
  payload: route
})

export const popRoute = (): Action => ({
  type: POP_ROUTE,
  payload: null,
})


/*----------CONTRACTS----------*/
export const updateShowContract = (value: boolean): Action => ({
  type: UPDATE_SHOW_CONTRACT,
  payload: value,
})


/*----------WOOTRIC-----------*/
export const initWootric = (): Action => ({
  type: INIT_WOOTRIC,
  payload: { showSurvey: true },
})


/*----------In-App-Notifications-----------*/
export const getNotifications = (): Function => async (dispatch: Action => *) => {
  const a = await notifications.list()
  dispatch(updateNotifications(a))
}

export const updateNotifications = (content: any): Action => ({
  type: UPDATE_NOTIFICATIONS,
  payload: content
})