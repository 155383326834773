// @flow
import self from 'autobind-decorator'
import React, { Component, Fragment } from 'react'
import { SlideDown } from 'react-slidedown'
import Dropzone from 'react-dropzone'
import mime from 'mime-types'
import classNames from 'classnames'

import { FILE_TYPES } from 'constants'
import { isEqual } from 'utils/resolve'

import { FilePreview } from 'components/generic'
import { SecondaryButton, RemoveButton } from 'components/buttons'
import ImageCropper, { PICTURE_TYPES } from './ImageCropper'
import Text from 'components/text'
import { Link } from 'components/generic'
import Icons from 'components/icons'
import Dropdown from 'components/Dropdown'

import type { DropdownItemType } from 'components/Dropdown'

type InitialType = { url: string | null, file: File | null }

type FileFieldProps = {
  value: File | string,
  onChange: Function,
  disabled?: boolean,
  id?: string | number,
  allowedFiles?: string,
  buttonLabel?: string,
  fileType?: 'img' | 'file' | 'video',
  maxPlayerSize?: [number, number],
  editableImg?: boolean,
  initial: InitialType,
  deleteUrl: string,
  onError: Function,
  pictureType: 'profile' | 'exercise',
  placeholder?: boolean,
  dropdownItems?: Array<DropdownItemType>,
  fullWidth?: boolean,
  noLink?: boolean
}

type FileFieldStateType = {
  editing: boolean,
  previewSrc: string | Blob | null,
  previewType: string | null,
}

const ALLOWED_FILES_LIST  = [ 'jpg', 'jpeg', 'png', 'svg' ]
const ALLOWED_FILES = '.' + ALLOWED_FILES_LIST.join(', .')


export default class FileSelectField extends Component<FileFieldProps, FileFieldStateType> {

  static defaultProps = {
    allowedFiles: ALLOWED_FILES,
    buttonLabel: 'Select file',
    placeholder: false,
  }

  prevImage: * = null
  rotation: number = 0
  input: HTMLInputElement | null = null
  state = {
    editing: false,
    previewSrc: null,
    previewType: null,
  }

  get selectedClasses () {
    return classNames(
      'selected',
      this.props.placeholder ? null : ' small'
    )
  }

  componentDidMount () {
    if (this.props.value)
      this.updatePreviewData()
  }

  componentDidUpdate (oldProps: FileFieldProps) {
    if (oldProps.value !== this.props.value)
      this.updatePreviewData()
  }

  updatePreviewData () {
    let type = null
    let previewSrc = ''

    if (is.string(this.props.value)) {
      previewSrc = this.props.value
    } else if (this.props.value instanceof Blob) {
      previewSrc = URL.createObjectURL(this.props.value)
      type = resolveFileType(this.props.value)
    }
    const previewType = type || this.props.fileType

    this.setState({ previewSrc, previewType })

  }

  @self
  onChange (event: SyntheticInputEvent<*> | { target: { files: Array<File> }}) {
    const file = event.target.files[0]
    const type = resolveFileType(file)
    this.selectFile(file)
    if (this.props.editableImg && type === FILE_TYPES.img && !this.state.editing) {
      this.prevImage = this.props.value
      this.setState({ editing: true })
    }
  }

  @self
  onEditComplete (file: { file: File, url: string}) {
    this.setState({ editing: false })
    this.selectFile(file.file)
  }

  @self
  updateRotation (angle: number) {
    this.rotation += angle
  }

  @self
  onCancel () {
    this.selectFile(this.prevImage)
    this.setState({ editing: false })
  }

  @self
  toggleEdit () {
    this.setState({ editing: !this.state.editing })
  }

  @self
  handleDrop (dropped: Array<File>) {
    dropped.forEach(file => {
      if (resolveFileType(file))
        return this.onChange({ target: { files: [ file ]}})
    })
  }

  @self
  fileSelect () {
    if (this.input) this.input.click()
  }

  render () {
    const updateReference = ref => ref && (this.input = ref)
    const className = classNames(
      'select-file-field',
      this.props.placeholder && 'no-border',
    )
    const fullWidth = this.props.fullWidth && !this.props.value
      ? { width: '100%'}
      : undefined

    return <div className={ className }>
      <Dropzone
        onDrop={this.handleDrop}
        style={{ width: '100%', height: '100%', border: 'none' }}
        multiple={ false }
        disableClick >
        <div className='field file-input'>
          <div className='selection' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <SlideDown transitionOnAppear={ false } style={ fullWidth }>
              <div className={ this.selectedClasses }>
                { this.props.placeholder && !this.props.noDropdown && this.renderDropdown() }
                { this.renderPreview() }
              </div>
            </SlideDown>

            <div className='padded-children'>
              <input

                // This element is not visible in the UI.
                // Instead, the button below is used.
                id={ this.props.id }
                type='file'
                ref={ updateReference }
                onChange={ this.onChange }
                disabled={ this.props.disabled }
                accept={ this.props.allowedFiles }/>

              { !this.props.placeholder &&
                <Fragment>
                  <RemoveButton small onClick={ this.removeFile }>Remove</RemoveButton>
                  <SecondaryButton
                    onClick={ this.fileSelect }
                    id={ this.props.id }
                    small >
                    { this.props.buttonLabel }
                  </SecondaryButton>
                </Fragment> }
            </div>
          </div>
        </div>
      </Dropzone>
      { this.state.editing && this.props.value && <ImageCropper
        onChange={ this.onEditComplete }
        onCancel={ this.onCancel }
        toggleEdit={ this.toggleEdit }
        initial={ this.props.value }
        edit={ this.state.editing }
        updateRotation={ this.updateRotation }
        pictureType={ PICTURE_TYPES.rect }/> }
    </div>
  }

  @self
  renderPreview (): React$Element<*> | null {
    if (!this.props.value || this.props.value === '')
      return this.props.placeholder
        ? this.renderDragAndDrop()
        : null

    return <FilePreview
      url={ this.state.previewSrc }
      type={ this.state.previewType }
      maxPlayerSize={ this.props.maxPlayerSize }
      name={ this.props.value.name ? this.props.value.name : undefined }
      removeFile={ this.removeFile }
      notooltip
      noLink={ this.props.noLink } />

  }

  renderDragAndDrop (): React$Element<*> {
    const fullWidth = this.props.fullWidth && !this.props.value
      ? { width: '100%'}
      : undefined
    return <div className='drag-drop' onClick={ this.fileSelect } style={ fullWidth }>
      <div className='icon-container'><Icons.Download size={ 26 } className='download-icon'/></div>
      <div className='text-container'>
        <p>
          <Text
            params={{ 'select a file': '<span id="replace" name="select a file"></span>' }}
            parseParameters={{
              'select a file': {
                component: Link,
                text: 'select a file',
              }
            }}>
            { 'Drag and drop a file here or ${select a file}' }
          </Text>
        </p>
      </div>
     </div>
  }

  renderDropdown () {
    const className = classNames(
      'dropdown-container',
      'file-select-dropdown',
      !this.props.value || this.props.value === ''
        ? 'invisible'
        : null
    )

    const actions = this.props.dropdownItems || []
    actions.push({
      label: 'Remove',
      onClick: this.removeFile
    })

    const toggleComponent = <div className='toggle'>
      <Icons.Edit size={ 15 }/>
    </div>

    return <Dropdown
      toggleComponent={ toggleComponent }
      items={ actions }
      className={ className }
      style={{ padding: 0 }}
      small paddingsmall />
  }

  @self
  selectFile (file: File | null) {
    this.props.onChange(file)
    this._clearInput()
  }

  @self
  async removeFile () {
    this.selectFile(null)
  }

  @self
  _clearInput () {
    if (this.input)
      this.input.value = ''
  }
}


function resolveFileType (file: File | string): string | null {
  let mimeType: string = ''
  if (file.name)
    mimeType = mime.contentType(file.name)
  else if (is.string(file))
    mimeType = mime.contentType(file)

  if (mimeType)
    if (mimeType.startsWith('image'))
      return FILE_TYPES.img
    if (mimeType.startsWith('video'))
      return FILE_TYPES.video
    if (mimeType.startsWith('application'))
      return FILE_TYPES.document

  return null
}
