// @flow
import React, { Component, Fragment } from 'react'
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import self from 'autobind-decorator'

import { replaceAll } from 'utils/strings'
import { getLengthOfSelectedText } from './utils'
import Button from 'components/buttons'
import Text, { Italic, Underlined, Strong, ErrorText } from 'components/text'

type PropsType = {
  onChange: Function,
  value?: string,
  disabled?: boolean,
  id?: string | number,
  charLimit: number,
}

type StateType = {
  editorState: EditorState,
  showError: boolean
}

type ButtonType = {
  style: string,
  tooltip: string,
  content: React$Element<*>,
}

type ButtonListType = Array<ButtonType>

const ALLOWED_STYLES = {
  underline: 'underline',
  italic: 'italic',
  bold: 'bold',
  delete: 'delete',
  backspace: 'backspace',
  // UL: 'unordered-list-item',
  // OL: 'ordered-list-item'
}

const TO_HTML_STYLES = {
  defaultBlockTag: 'p',
}

const ERROR_PASTED_TEXT: string = 'Text you are trying to paste is exceeding character limit'



export default class RichTextEditor extends Component<PropsType, StateType> {

  editor: Editor
  constructor(props: PropsType) {
    super(props)
    this.state = {
      editorState: EditorState.createWithContent(ContentState.createFromText(this.props.value || '')),
      showError: false
    }
  }

  static defaultProps: { charLimit: number} = {
    charLimit: 4095
  }

  @self
  onChange (editorState: EditorState): void {
    this.setState({ editorState })
    let html
    try {
      html = replaceAll(stateToHTML(editorState.getCurrentContent(), TO_HTML_STYLES), /\r?\n|\r/, '')
    } catch (err) {
      //eslint-disable-next-line no-console
      console.error('Failed to convert value to html: ', err)
      html = editorState.getCurrentContent().getPlainText()
    }
    this.props.onChange(html)
  }

  componentDidMount () {
    if (this.props.value && this.props.value !== '') {
      const contentState = stateFromHTML(this.props.value || '')
      this.setState({
        editorState: EditorState.createWithContent(contentState)
      })
    }
  }

  @self
  handleKeyCommand (command: string) {
    const commandAllowed = ALLOWED_STYLES[command]
    if (!commandAllowed) return
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
    this.onChange(newState || this.state.editorState)
  }

  @self
  onStyleSelect (style: string) {
    const newState = RichUtils.toggleInlineStyle(this.state.editorState, style)
    this.onChange(newState)
  }

  get charCount(): number {
    const count = this.state.editorState
                    .getCurrentContent()
                    .getPlainText()
                    .length
    return count
  }

  // @self
  // onBlockSelect (block: string) {
  //   const newState = RichUtils.toggleBlockType(this.state.editorState, block)
  //   this.onChange(newState)
  // }

  render (): React$Element<*> {
    const className = `wysiwyg-container${this.props.disabled ? ' disabled' : ''}`
    return <div className={ className }>
      { this.renderToolbar() }
      <div className='editor'>
        <Editor
          id={ this.props.id }
          ref={ref => ref && (this.editor = ref)}
          editorState={ this.state.editorState }
          onChange={ this.onChange }
          value={ this.props.value || '' }
          handleKeyCommand={this.handleKeyCommand}
          readOnly={ this.props.disabled }
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}/>
      </div>
    </div>
  }

  @self
  // $FlowIgnore
  _handleBeforeInput(): 'handled' | 'not-handled' {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText(this.state.editorState);
    if (currentContentLength - selectedTextLength > this.props.charLimit - 1) {
      return 'handled'
    }
  }

  @self
  // $FlowIgnore
  _handlePastedText(pastedText: string): 'handled' | 'not-handled' {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText(this.state.editorState);

    if (currentContentLength + pastedText.length - selectedTextLength > this.props.charLimit) {
      this.handleErrorMessage()
      return 'handled';
    }
  }

  @self
  isStyleActive (style: string): boolean {
    const { editorState } = this.state
    const inlineStyle = editorState.getCurrentInlineStyle()
    return inlineStyle.has(style)
  }

  @self
  handleErrorMessage() {
    this.setState({
      showError: true
    }, () => setTimeout(() => {
      this.setState({
        showError: false
      })
    }, 2000))
  }

  // @self
  // isBlockActive (style: string): boolean {
  //   const { editorState } = this.state
  //   const content = editorState.getCurrentContent()
  //   return content.has(style)
  // }

  // getCurrentBlock(editorState) {
  //   const currentSelection = editorState.getSelection()
  //   const blockKey = currentSelection.getStartKey()
  //   return(editorState.getCurrentContent().getBlockForKey(blockKey))
  // }

  @self
  renderToolbar (): ?React$Element<*> {
    if (this.props.disabled) return null
    return <Fragment>
      { this.renderInlineStyleButtons() }
      { this.errorMessage() }
      { this.renderCounter() }
      {/* { this.renderBlockButtons() } */}
    </Fragment>
  }

  renderCounter() {
    const count = this.state.editorState
                    .getCurrentContent()
                    .getPlainText()
                    .length

    const content = <span style={{ position: 'absolute', right: 0 }}>
      {this.charCount}/{this.props.charLimit}
    </span>
    return this.state.showError ? null : content
  }

  errorMessage() {
    const content = <ErrorText style={{ marginLeft: '0.5rem '}}>{ERROR_PASTED_TEXT}</ErrorText>
    return this.state.showError ? content : null
  }

  renderInlineStyleButtons (): React$Element<*> {
    return <div className='rte-controls toolbar'>
      { TOOLBAR_BUTTONS.map((button: ButtonType, key: number) => <span
        key={ key }
        onMouseDown={ event => {
          event.preventDefault()
          event.stopPropagation()
          this.onStyleSelect(button.style)
        }}>
        <Button tooltip={ button.tooltip } small active={ this.isStyleActive(button.style) }>
          { button.content }
        </Button>
      </span> )}
    </div>
  }

  // renderBlockButtons (): React$Element<*> {
  //   const { editorState } = this.state
  //   console.log(this.getCurrentBlock(editorState).toObject())
  //   const selection = editorState.getSelection()
  //   const blockType = editorState
  //     .getCurrentContent()
  //     .getBlockForKey(selection.getStartKey())
  //     .getType()

  //   return <div className="rte-controls toolbar">
  //     { TOOLBAR_BLOCK_BUTTONS.map((button, key) => <span
  //       key={ key }
  //       onMouseDown={ event => {
  //         event.preventDefault()
  //         event.stopPropagation()
  //         this.onBlockSelect(button.style)
  //       }}>
  //       <Button tooltip={ button.tooltip } small active={ button.style === blockType }>
  //         { button.content }
  //       </Button>
  //     </span>)}
  //   </div>
  // }
}

const TOOLBAR_BUTTONS: ButtonListType = [
  {
    style: ALLOWED_STYLES.bold.toUpperCase(),
    tooltip: 'Bold',
    content: <Strong notranslate>B</Strong>,
  },
  {
    style: ALLOWED_STYLES.italic.toUpperCase(),
    tooltip: 'Italic',
    content: <Italic notranslate>I</Italic>,
  },
  {
    style: ALLOWED_STYLES.underline.toUpperCase(),
    tooltip: 'Underline',
    content: <Underlined notranslate>U</Underlined>,
  },
]

// const TOOLBAR_BLOCK_BUTTONS: Array<*> = [
//   {
//     style: ALLOWED_STYLES.UL,
//     tooltip: 'Unordered list',
//     content: <Text notranslate>UL</Text>
//   },
//   {
//     style: ALLOWED_STYLES.OL,
//     tooltip: 'Ordered list',
//     content: <Text notranslate>OL</Text>
//   }
// ]

