const obtainToken            = method('post', 'token',  [ 'email', 'password' ], ({ data }) => data.token)
const refreshToken           = method('post', 'token/refresh', [ 'refresh' ], ({ data }) => data)
const getTokens              = method('post', 'token/from-disposable', [ 'token' ], ({ data }) => data)
const verifyToken            = method('post', 'token/verify', [ 'token' ], data => data)

const startThread            = method('post', 'threads/init')

const listHandlers           = property('options', 'threads', ({ data }) => data.filters)
const tagsList               = property('get', 'tags', ({ data }) => data.reduce(reduceTagsToObject, {}))
const clients                = property('get', 'clients', ({ data }) => data)
const tenantSettings         = property('get', 'settings/', ({ data }) => data)


const getStaff = api => async (id) => {
  const response = await api.get(`staff/${id}`)
  return response.valid ? response.data : null
}


const getClient = api => async (id) => {
  const response = await api.get(`clients/${id}`)
  return response.valid ? response.data : null
}


const searchClients = api => async search => {
  const page_size = 5
  const response  = await api.post(`clients`, { search, page_size })
  return response.data.results
}


const searchStaff = api => async search => {
  const page_size = 5
  const response  = await api.post(`staff`, { search, page_size })
  return response.data.results
}


const replyToThread = api => async (id: number, data: Object) =>
  await api.post(`/threads/${id}/post/`, data)


const reduceTagsToObject    = (tags, tag) =>
  Object.assign(tags, { [tag.id]: tag.text })


function property (method, route, parse = (response) => response) {
  return async api => {
    const response = await api[method](route)
    return parse(response)
  }
}


function method (method, route, args = []) {

  const onSuccess   = typeof arguments[3] === 'function'
    ? arguments[3]
    : data => data

  const onException = typeof arguments[4] === 'function'
    ? arguments[4]
    : errors => errors

  const handleResponse = response => response.valid
    ? onSuccess(response)
    : onException(response)

  const definedParameters = args.length > 0

  return api => async (...values) => {

    let data = {}
    if (definedParameters && args.length !== values.length)
      throw new RangeError(
        `Invalid number of parameters passed for the \`${route}\` request. ` +
        `Said route requires ${args.length} arguments (${args.join(', ')}), ` +
        `whilst the values ${values.map(val => JSON.stringify(val)).join(', ')} were provided.`)

    // Single data parameter
    if (!definedParameters)
      data = values[0]

    else
      data = args.reduce((params, key) => ({ ...params, [key]: values.shift() }), {})

    const response = await api[method](route, data)
    // if (!response.valid)
    //   throw new Error(response.errors)
    return handleResponse(response)
  }
}


export default {
  obtainToken,
  refreshToken,
  startThread,
  listHandlers,
  tagsList,
  clients,
  getStaff,
  getClient,
  searchClients,
  searchStaff,
  replyToThread,
  tenantSettings,
  getTokens,
  verifyToken,
}
