import React from 'react'
import { RICH_TEXT_LENGTH_BOUNDARY, isTextual } from '../constants'

import Required from '../components/Indicator'

import ChoiceField from './ChoiceField'
import EmailField from './EmailField'
import FileField from './FileField'
import InputField from './InputField'
import NestedField from './NestedField'
import NumericField from './NumericField'
import PasswordField from './PasswordField'
import RichTextField from './RichTextField'
import StringField from './StringField'
import SingleToggleField from './ToggleField'
import SelectField from './SelectField'
import DateField from './DateField'
import SingleChoiceField from './SingleChoiceField'
import MultiChoiceField from './MultipleChoiceField'
import SliderToggleField from './ToggleFieldSlider'
import DateRangeFields from './DateRangeFields'

export {
  ChoiceField,
  EmailField,
  FileField,
  InputField,
  NestedField,
  NumericField,
  PasswordField,
  RichTextField,
  StringField,
  SingleToggleField,
  SelectField,
  SingleChoiceField,
  MultiChoiceField,
  SliderToggleField,
  DateField,
  DateRangeFields,

  // Fields not mapped to any keyword
  Required,
}

const FIELD_TYPES = [
  [ 'password',         PasswordField ],
  [ 'text',             StringField ],
  [ 'string',           StringField ],
  [ 'longtext',         RichTextField ],
  [ 'integer',          NumericField ],
  [ 'decimal',          NumericField ],
  [ 'email',            EmailField ],
  [ 'choice',           ChoiceField ],
  [ 'nested object',    NestedField ],
  [ 'file upload',      FileField ],
  [ 'field',            NestedField ],
  [ 'boolean',          SingleToggleField ],
  [ 'date',             DateField ],
  [ 'select',           SelectField ],
  [ 'multiple choice',  SelectField ],
  [ 'radio choice',     SingleChoiceField ],
  [ 'checkbox choice',  MultiChoiceField ],
  [ 'slider choice',    SliderToggleField ],
  [ 'image upload',     FileField ],
]


const fieldTypeMap = new Map(FIELD_TYPES)


export default fieldTypeMap


export function getFieldComponentType (field) {
  if (field.max_length >= RICH_TEXT_LENGTH_BOUNDARY && isTextual(field))
    field.type = 'longtext'
  if (!fieldTypeMap.has(field.type)) {
    throw new TypeError(
      `Invalid field type \`${field.type}\` provided to getFieldComponentByType.\n` +
      `The type has to be one of ${[ ...fieldTypeMap.keys() ].join(', ')}.\n`
    )
  }
  return fieldTypeMap.get(field.type)
}


export function getFieldComponent (responseFieldData, mountedCallback = () => {}) {
  const ComponentClass = getFieldComponentType(responseFieldData)
  const references = new Map()
  const component = <ComponentClass
    ref={ ref => {
      if (!ref)
        return
      references.set('ref', ref)
      mountedCallback(ref)
    }}
    { ...responseFieldData }
  />

  return Object.defineProperties(
    { component, references },
    { reference: { get: () => references.get('ref') }}
  )
}
