// @flow
import React from 'react'
import { Modal } from '@material-ui/core'
import { Button, Icon } from '@nordhealth/react'

import Text from 'components/text'

import { __ } from 'utils/gettext'

import type { Node } from 'react'

type Props = {
  open: boolean,
  onCancel: Function,
  onConfirm: Function,
  step: number
}

const ExitBookingModal = (props: Props): Node => {
  // In SuccessfulBooking don't warn about losing filled information
  const label = props.step === 8
    ? __('Exit booking and return to my appointments?')
    : __('All information you have filled in will be deleted')

  return (
    <Modal open={props.open} onClose={() => props.onCancel(false)}>
      <div className='booking-small-modal'>
        <div className='booking-small-modal-header'>
          <div className='booking-small-modal-header-text'>
            <Text>Are you sure?</Text>
          </div>
          <div
            className='booking-small-modal-header-close'
            onClick={() => props.onCancel(false)}
          >
            <Icon name='interface-close' size='s' />
          </div>
        </div>
        <div className='exit-modal-content'>
          <div className='label n-font-size-l'>{label}</div>
          <div className='buttons-container'>
            <Button className='confirm-exit' variant='primary' expand onClick={() => props.onConfirm()}>
              {__('Yes')}
            </Button>
            <Button className='cancel-exit' expand onClick={() => props.onCancel(false)}>
              {__('Cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ExitBookingModal
