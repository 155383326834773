// @flow
import React, { useState } from 'react'
// $FlowIgnore
import { Banner, Card, Stack } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'
import { useDispatch, useSelector } from 'react-redux'

import BookingLoginModal from '../extra/BookingLoginModal'
import ConfirmationDetails from '../extra/ConfirmationDetails'
import EmployeeCard from '../extra/EmployeeCard'
import LocationDetails from '../extra/LocationDetails'
import Map from '../extra/Map'

import { __ } from 'utils/gettext'
import { getLocationBySelectedTimeslot } from '../utils/getLocation'

import { resetBooking } from 'actions/onlineBooking'
import type { Node } from 'react'
import navigator from 'routes/base'
import type { OnlineBookingState as OnlineBooking } from '../../../reducers/onlineBooking'

type Props = {
  bookingData: OnlineBooking,
  isSuccessfulBookingView?: boolean,
  showAccountCreatedBanner?: boolean,
}

const ConfirmBookingDesktopLayout = (props: Props): Node => {
  if (props.isSuccessfulBookingView)
    return (
      <SuccessfulBookingView
        bookingData={props.bookingData}
        showAccountCreatedBanner={props.showAccountCreatedBanner}
      />
    )
  else return <ConfirmBookingView bookingData={props.bookingData} />
}

const ConfirmBookingView = (props: Props): Node => {
  const [open, setOpen] = useState<boolean>(false)
  // If login is successful, we'll redirect to the next page and show success banner there
  const [showLoginFailedBanner, setShowLoginFailedBanner] = useState<?string>(
    null
  )
  const {
    selected_employee,
    selected_locations,
    selected_time_slots,
  } = props.bookingData
  const location =
    selected_time_slots &&
    getLocationBySelectedTimeslot(
      selected_time_slots.location_id,
      selected_locations
    )

  const renderLoginFailedBanner = () => {
    const message = 'Login failed.'
    return <Banner variant={'danger'}>{message}</Banner>
  }

  const showMap = location !== null && location[0] && location[0].address.street_address !== ''

  return (
    <>
      {showLoginFailedBanner && (
        <div className='banner-container'>{renderLoginFailedBanner()}</div>
      )}
      <div>
        <div className='confirmation-container'>
          <div className='confirmation-details'>
            <Card>
              <ConfirmationDetails bookingData={props.bookingData} />
            </Card>
          </div>
          <div className='confirmation-information'>
            {selected_employee && (
              <EmployeeCard employee={selected_employee} hideLocation />
            )}
            {showMap && (
              <Card>
                <div className='map-container'>
                  <Map />
                </div>
                {/* $FlowIgnore */}
                <LocationDetails location={location[0]} size={'l'} />
              </Card>
            )}
            {/* TODO: Hidden for now. */}
            {/* <div style={{ visibility: 'hidden' }}>
                  <Card>
                    <p className='n-font-size-l n-font-weight-strong'>
                      Practical information
                    </p>
                    <p className='n-color-text-weak n-font-size-s'>{LOREM}</p>
                  </Card>
                </div> */}
          </div>
        </div>
        <div className='confirmation-continue'>
          <Button
            expand
            variant='primary'
            size='l'
            onClick={() => setOpen(true)}
          >
            Continue
          </Button>
        </div>
      </div>
      <BookingLoginModal
        open={open}
        setOpen={setOpen}
        showProceedAsGuest={true}
        setShowLoginBanner={setShowLoginFailedBanner}
        forceTransition
        secondLogin
      />
    </>
  )
}

type SuccessfulBookingProps = {
  bookingData: Object,
  showAccountCreatedBanner?: boolean,
}

const SuccessfulBookingView = (props: SuccessfulBookingProps) => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.id)
  const isLoggedIn = Boolean(userId)

  const handleBackToStart = () => {
    // If user is logged in, we clear the state
    // But do not navigate to the landing page
    dispatch(resetBooking(!isLoggedIn))
    if (isLoggedIn) {
      navigator.navigate('/my-appointments')
    }
  }

  const renderAccountCreatedBanner = () => {
    const message =
      'Account created successfully. Please check your email for setting a password for your new Nordhealth Connect account.'
    return <Banner variant={'success'}>{__(message)}</Banner>
  }

  const label = isLoggedIn ? 'Go to my appointments' : 'Back to start'

  return (
    <div className='successful-booking-container'>
      {props.showAccountCreatedBanner && (
        <div className='banner-container'>{renderAccountCreatedBanner()}</div>
      )}
      <Card>
        <Stack gap='l'>
          <ConfirmationDetails bookingData={props.bookingData} withMap />
        </Stack>
      </Card>
      <div className='footer'>
        <Button expand variant='primary' size='l' onClick={handleBackToStart}>
          {label}
        </Button>
      </div>
    </div>
  )
}

const LOREM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean condimentum sapien nisl, at sagittis elit cursus et. Morbi eget placerat sem. Pellentesque sed consequat nisi. Vestibulum finibus blandit arcu, vitae cursus eros ultrices eu. Aliquam et dolor euismod, suscipit mi in, interdum urna. Praesent quis metus nec dui finibus gravida a eu purus. Quisque molestie lectus in ante pellentesque viverra.'

export default ConfirmBookingDesktopLayout
