// @flow
import type { Action } from '../reducers'
import type {
  Service,
  Location,
  AvailableDates,
  Employee,
  SelectedTimeSlot,
  InitialData,
} from '../reducers/onlineBooking'
import navigator from 'routes/base'

import { BOOKING_STEPS_URLS, BOOKING_STEPS_MAP } from '../views/OnlineBooking'

export const setInitialData = (initData: InitialData): Action => {
  return {
    payload: initData,
    type: SET_INITIAL_DATA,
  }
}

export const selectService = (
  service: ?Service,
  isDesktop?: boolean
): Action => {
  if (!isDesktop) navigator.navigate(BOOKING_STEPS_URLS.LOCATIONS)
  return {
    payload: service,
    type: SELECT_SERVICE,
  }
}

export const selectLocation = (
  locations: Array<Location>,
  isDesktop?: boolean,
  locationSkipped?: boolean
): Action => {
  if (!isDesktop) navigator.navigate(BOOKING_STEPS_URLS.AVAILABLE_DATES)
  return {
    payload: {
      locations: locations,
      locationSkipped: locationSkipped || false,
    },
    type: SELECT_LOCATION,
  }
}

export const selectAllLocations = (locations: Array<Location>): Action => {
  navigator.navigate(BOOKING_STEPS_URLS.AVAILABLE_DATES)
  return {
    payload: locations,
    type: SELECT_ALL_LOCATIONS,
  }
}

export const selectDate = (
  date: AvailableDates,
  increaseStep: number = 1
): Action => {
  navigator.navigate(BOOKING_STEPS_URLS.EMPLOYEES)
  return {
    payload: {
      date,
      increaseStep,
    },
    type: SELECT_DATE,
  }
}

export const updateSelectedDate = (dateString: string): Action => {
  return {
    payload: dateString,
    type: UPDATE_DATE,
  }
}

export const selectEmployee = (
  employee: ?Employee,
  navigateToBooking: boolean = true
): Action => {
  if (navigateToBooking) {
    navigator.navigate(BOOKING_STEPS_URLS.TIME_SLOTS)
  }
  return {
    payload: employee,
    type: SELECT_EMPLOYEE,
  }
}

export const selectTimeslot = (timeslot: SelectedTimeSlot | null): Action => {
  return {
    payload: timeslot,
    type: SELECT_TIMESLOT,
  }
}

export const setCachedData = (bookingData: Object): Action => {
  navigator.navigate(BOOKING_STEPS_URLS.PERSONAL_DETAILS)
  return {
    payload: bookingData,
    type: SET_CACHED_DATA,
  }
}

export const increaseStep = (amount: number = 1, routeProps: Object = {}): Function => {
  return (dispatch: Function, getState: Function) => {
    const state = getState()
    const nextStep = state.onlineBooking.step + amount
    if (BOOKING_STEPS_MAP.has(nextStep))
      navigator.navigate(
        BOOKING_STEPS_MAP.get(nextStep),
        {},
        routeProps
      )
    dispatch({
      payload: amount,
      type: INCREASE_STEP,
    })
  }
}

export const decreaseStep = (amount: number = 1): Function => {
  // Amount is required for the decrease from confirmation view
  // to the employee selection view on desktop, because
  // Employee view on desktop has 2 views combined ()

  return (dispatch: Function, getState: Function) => {
    const state = getState()
    const step = state.onlineBooking.step
    if (step === 0) return
    if (step === 1) {
      navigator.navigate('/online-booking/')
    } else {
      if (BOOKING_STEPS_MAP.has(step - amount))
        navigator.navigate(BOOKING_STEPS_MAP.get(step - amount))
    }
    dispatch({
      payload: amount,
      type: DECREASE_STEP,
    })
  }
}

export const handleCalendarChange = (date: string): Action => {
  const [year, month, day] = date.split('-')
  return {
    type: HANDLE_CALENDAR_CHANGE,
    payload: {
      selected_date_string: date,
      selected_year: year,
      selected_month: month,
      selected_day: day,
    },
  }
}

export const handleYearChange = (year: string): Function => {
  return (dispatch: Function, getState: Function) => {
    const state = getState()
    const { selected_month } = state.onlineBooking.calendar_state
    dispatch({
      type: HANDLE_YEAR_SELECTION,
      payload: {
        selected_year: year,
        selected_date_string: `${year}-${selected_month}-01`,
        selected_month: selected_month,
        selected_day: '01',
      },
    })
  }
}

// When on calendar we are on december month and we click next month, we should handle year switch
// Same thing happens backwards
export const yearTransition = (direction: 'NEXT' | 'PREVIOUS'): Function => {
  return (dispatch: Function, getState: Function) => {
    const state = getState()
    const { selected_year } = state.onlineBooking.calendar_state
    const int_year = parseInt(selected_year)
    const newYear = direction === 'NEXT' ? int_year + 1 : int_year - 1
    const month = direction === 'NEXT' ? '01' : '12'
    dispatch({
      type: YEAR_TRANSITION,
      payload: {
        selected_year: `${newYear}`,
        selected_date_string: `${newYear}-${month}-01`,
        selected_month: month,
        selected_day: '01',
      },
    })
  }
}

export const resetBooking = (navigateToStart: boolean = true): Action => {
  if (navigateToStart) {
    navigator.navigate(BOOKING_STEPS_URLS.FRONTPAGE)
  }
  return {
    type: RESET_BOOKING,
    payload: null,
  }
}

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SELECT_SERVICE = 'SELECT_SERVICE'
export const SELECT_LOCATION = 'SELECT_LOCATION'
export const SELECT_ALL_LOCATIONS = 'SELECT_ALL_LOCATIONS'
export const SELECT_DATE = 'SELECT_DATE'
export const UPDATE_DATE = 'UPDATE_DATE'
export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE'
export const SELECT_TIMESLOT = 'SELECT_TIMESLOT'
export const INCREASE_STEP = 'INCREASE_STEP'
export const DECREASE_STEP = 'DECREASE_STEP'
export const HANDLE_CALENDAR_CHANGE = 'HANDLE_CALENDAR_CHANGE'
export const HANDLE_YEAR_SELECTION = 'HANDLE_YEAR_SELECTION'
export const YEAR_TRANSITION = 'YEAR_TRANSITION'
export const SET_CACHED_DATA = 'SET_CACHED_DATA'
export const RESET_BOOKING = 'RESET_BOOKING'
