// @flow
import React from 'react'
import BookingErrorView from '../views/BookingErrorView'
import type { Node } from 'react'

class ErrorBoundary extends React.Component<*, *> {
  constructor(props: *) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): Object {
    return { hasError: true, error: error }
  }

  render(): Node {
    if (this.state.hasError) {
      return <BookingErrorView errorString={this.state.error.message} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
