// @flow
import self from 'autobind-decorator'
import tooltips from 'tooltips'
import getStyle from 'computed-style'


export type OffsetType = {
  x: number,
  y: number
}


export default function draggable (Component) {


  class DraggableComponent extends Component {
    start: { x: number, y: number }
    height: number
    positionOffset: number
    element: HTMLElement

    @self
    onDragStart (event: MouseEvent) {
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.onDragEnd)
      this.start = {
        x: event.pageX,
        y: event.pageY,
      }
      this.height = getOffsetHeight(this.element)
      this.positionOffset = 0
      tooltips.hideAll()
    }

    @self
    onDrag (event: MouseEvent) {
      if (!event.buttons)
        return this.onDragEnd()
      let translation = event.pageY - this.start.y
      this.positionOffset = shiftSiblingElements(this.element, event.pageY + this.height / 2, this.height)
      shiftElement(this.element, translation)
      this.element.classList.add('move')
      tooltips.hideAll()
    }

    @self
    onDragEnd () {
      document.removeEventListener('mousemove', this.onDrag)
      document.removeEventListener('mouseup', this.onDragEnd)

      // Reset the positions
      const parent = this.element.parentElement
      const nodes  = parent ? parent.children : []
      for (let element of nodes)
        resetElementShift(element)

      this.element.classList.remove('move')
      this.props.move(this.positionOffset)
    }
  }

  DraggableComponent.displayName = `Draggable ${Component.displayName || Component.name}`

  return DraggableComponent
}


const offsets = new WeakMap()


const getElementOffset = (element: HTMLElement): OffsetType => {
  let offset: OffsetType = {
    x: element.offsetLeft,
    y: element.offsetTop,
  }

  if (offsets.has(element))
    offset.y += offsets.get(element)

  while (element = element.offsetParent) {
    offset.x += element.offsetLeft
    offset.y += element.offsetTop
  }
  return offset
}


const getElementCenterPosition = (element: HTMLElement): OffsetType => {
  let offset = getElementOffset(element)
  let { width, height } = element.getBoundingClientRect()

  return {
    x: parseFloat(offset.x + width / 2),
    y: parseFloat(offset.y + height / 2),
  }
}


const getOffsetHeight = (element: HTMLElement): number => {
  let height       = parseFloat(element.getBoundingClientRect().height)
  let marginTop    = parseFloat(getStyle(element, 'margin-top'))
  let marginBottom = parseFloat(getStyle(element, 'margin-bottom'))

  return height + marginTop + marginBottom
}


const resetElementShift = (element: HTMLElement) =>
  shiftElement(element, 0)


const shiftElement = (element: HTMLElement, shiftAmount: number) => {
  element.style.setProperty('transform', `translateY(${shiftAmount}px)`)
  if (shiftAmount === 0)
    offsets.delete(element)
  else
    offsets.set(element, shiftAmount)
}


const shiftSiblingElements = (node: HTMLElement, position: number, shiftAmount: number): number => {
  let element
  let positionOffset = 0

  const shift = (amount) => {
    let verticalCenter = getElementCenterPosition(element).y
    let condition = amount < 0
      ? verticalCenter < position
      : verticalCenter > position
    if (condition) {
      shiftElement(element, amount)
      if (amount < 0) positionOffset++
      else positionOffset--
    }
    else
      resetElementShift(element)
  }

  // Shift the upper elements
  element = node
  while (element = element.previousSibling)
    shift(shiftAmount)

  // Short-circuit if any upper elements are shifted
  if (positionOffset < 0)
    return positionOffset

  // Shift the lower elements
  element = node
  while (element = element.nextSibling)
    shift(-shiftAmount)

  return positionOffset
}
