// @flow
import React from 'react'

import { formatDate } from 'utils/formatters'
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'
import { getDateString, getDayTransition } from 'utils/dateUtils'

import { handleCalendarChange, updateSelectedDate } from 'actions/onlineBooking'
import type { CalendarState } from 'reducers/onlineBooking'

import type { StateType } from 'store/initialState'
import type { Node } from 'react'

// This prop is required in order to trigger refetch of the data
// in the SelectTimeslot view. Technically this could be a default
// Behaviour, however we might reuse this component somewhere in the future
// Thus it is kept that way for the time being
type Props = {
  updateSelectedDate: boolean,
}

const DateQuickSelect = (props: Props): Node => {
  const dispatch = useDispatch()
  const calendarState: CalendarState = useSelector(
    (state: StateType) => state.onlineBooking.calendar_state
  )

  const decrease = () => {
    const newDateString = handleDayTransition(calendarState, 'PREVIOUS')
    dispatch(handleCalendarChange(newDateString))
    if (props.updateSelectedDate) dispatch(updateSelectedDate(newDateString))
  }

  const increase = () => {
    const newDateString = handleDayTransition(calendarState, 'NEXT')
    dispatch(handleCalendarChange(newDateString))
    if (props.updateSelectedDate) dispatch(updateSelectedDate(newDateString))
  }

  return (
    <div className='date-quick-select-container'>
      <nord-icon size='s' name='arrow-left-small' onClick={decrease} />
      {formatDate(calendarState.selected_date_string, 'D. MMMM YYYY')}
      <nord-icon size='s' name='arrow-right-small' onClick={increase} />
    </div>
  )
}

const handleDayTransition = (
  calendarState: CalendarState,
  action: 'PREVIOUS' | 'NEXT'
): string => {
  const { selected_date_string } = calendarState
  const dateString = getDayTransition(selected_date_string, action)
  return dateString
}

export default DateQuickSelect
