/**
 * @flow
 * @author mikakattainen<mika.kattainen@fns.fi>
 */
import React, { Fragment } from 'react'

import routes from 'routes/public/exercises/'


const ExerciseContainer = () =>
  <Fragment>
    { routes }
  </Fragment>


export default ExerciseContainer
