// @flow

import { Record } from 'immutable'


export const ADD_USER         = 'ADD_USER'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'

export const SET_LIST_FILTER    = 'SET_THREADS_LIST_FILTER'
export const SET_PARTICIPANT_FILTER = ' SET_PARTICIPANT_FILTER'
export const CLEAR_LIST_FILTERS = 'CLEAR_THREADS_LIST_FILTERS'
export const REMOVE_LIST_FILTER = 'REMOVE_THREADS_LIST_FILTER'

export const ADD_MESSAGE      = 'ADD_MESSAGE'
export const ADD_MESSAGES     = 'ADD_MESSAGES'
export const UPDATE_MESSAGE   = 'UPDATE_MESSAGE'
export const REMOVE_MESSAGE   = 'REMOVE_MESSAGE'

export const SET_THREAD = 'SET_THREAD'

export const SET_ATTACHMENT_FIELD     = 'SET_ATTACHMENT_FIELD'
export const SET_ATTACHMENT_FIELDS    = 'SET_ATTACHMENT_FIELDS'
export const REMOVE_ATTACHMENT_FIELD  = 'REMOVE_ATTACHMENT_FIELD'
export const ENABLE_ATTACHMENT_FIELD  = 'ENABLE_ATTACHMENT_FIELD'
export const DISABLE_ATTACHMENT_FIELD = 'DISABLE_ATTACHMENT_FIELD'

import type User from 'models/User'
import type Message from 'models/Message'
import type { ItemType } from 'components/DropdownButton'
import type { Action } from '../reducers'

export type AttachmentFieldTypes = 'attachments' | 'courses' | 'forms' | 'viivi_url'
export type AttachmentFields = Map<AttachmentFieldTypes, ItemType>
export type Participant = {
  label: string,
  value: any,
}

// const FilterRecord = Record({ property: null, value: '' })

export function addUser (user: User): Action {
  return {
    type: ADD_USER,
    payload: user,
  }
}

export function updateUser (user: User): Action {
  return {
    type: UPDATE_USER_DATA,
    payload: user,
  }
}

export function setParticipantFilter (participant: Participant): Action {
  return {
    type: SET_PARTICIPANT_FILTER,
    payload: participant
  }
}

export function clearListFilters (): Action {
  return {
    type: CLEAR_LIST_FILTERS,
    payload: null,
  }
}

export function removeListFilter (property: string): Action {
  return {
    type: REMOVE_LIST_FILTER,
    payload: property,
  }
}

export function addMessage (message: Message): Action {
  return {
    type: ADD_MESSAGE,
    payload: message,
  }
}

export function addMessages (...messages: Array<Message>): Action {
  return {
    type: ADD_MESSAGES,
    payload: messages,
  }
}

export function updateMessage (message: Message): Action {
  return {
    type: UPDATE_MESSAGE,
    payload: message,
  }
}

export function removeMessage (id: Message): Action {
  return {
    type: REMOVE_MESSAGE,
    payload: id,
  }
}

export function setThread (thread: { subject: string | null, recipient: * | null }): Action {
  return {
    type: SET_THREAD,
    payload: thread,
  }
}

export function setAttachmentFields (fields: Map<AttachmentFieldTypes, ItemType>): Action {
  return {
    type: SET_ATTACHMENT_FIELDS,
    payload: fields,
  }
}

export function setAttachmentField (field: { field: AttachmentFieldTypes, item: ItemType }): Action {
  return {
    type: SET_ATTACHMENT_FIELD,
    payload: field,
  }
}

export function enableAttachmentField (field: AttachmentFieldTypes): Action {
  return {
    type: ENABLE_ATTACHMENT_FIELD,
    payload: field,
  }
}

export function disableAttachmentField (field: AttachmentFieldTypes): Action {
  return {
    type: DISABLE_ATTACHMENT_FIELD,
    payload: field,
  }
}

export function removeAttachmentField (field: AttachmentFieldTypes): Action {
  return {
    type: REMOVE_ATTACHMENT_FIELD,
    payload: field,
  }
}
