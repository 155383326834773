// @flow
import React, { Fragment } from 'react'
import style from '../style'
import BaseToggleComponent from './Base'
import { CHECKBOX_PATH } from '../constants'

import connect from 'bound-connect'
import type { StateType } from 'store/initialState'


@connect
export default class Checkbox extends BaseToggleComponent {

  static PATH          = CHECKBOX_PATH
  static style         = style.checkbox
  static displayName   = 'Checkbox Select Field Entry'
  static typeAttribute = 'checkbox'

  static actions = []
  static properties = (state: StateType) => ({
    primaryColor:  state.tenantSettings.color,
  })

  // eslint-disable-next-line class-methods-use-this
  renderMarkerComponent () {
    return <Fragment>
      <path d='m 4 5 l 5 5' />
      <path d='m 9 5 l -5 5' />
    </Fragment>
  }

}
