// @flow
import Fieldset, { Deferred } from './construct'

export type FieldDefinition  = Object

export type FieldDefinitions = { [key: string]: FieldDefinition }

export default Fieldset
export {
  Deferred
}
