/**
 * @class  Pagination
 * @module components.List
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent } from 'react'
import type { Node } from 'react'
import classNames from 'classnames'
import { ToolbarContainer, Toolbar } from 'components/generic'
import { BackIcon, NextIcon, LastIcon, FirstIcon } from 'components/icons'
import Button from 'components/buttons'


type PaginationProps = {|
  count: number,
  current: number,
  onChange: number => *,
|}


export default class Pagination extends PureComponent<PaginationProps> {

  static VISIBLE_INDICE_MAX_COUNT = 5

  render () {
    return null
  }

  get totalPageCount (): number {
    return parseInt(this.props.count)
  }

  get lastIndex (): number {
    return this.totalPageCount + 1
  }

  get visiblePageCount (): number {
    return parseInt(Math.min(this.props.count, Pagination.VISIBLE_INDICE_MAX_COUNT))
  }

  get currentPage (): number {
    const page = parseInt(this.props.current)
    if (is.nan(page))
      return 1
    return page
  }

  get startIndex (): number {
    const maximum   = Pagination.VISIBLE_INDICE_MAX_COUNT
    const deviation = Math.floor(maximum / 2)
    const current   = this.currentPage
    const count     = this.visiblePageCount
    const last      = this.lastIndex
    const base      = current - deviation
    const high      = current + deviation

    if (base < 1)
      return 1
    if (high >= last)
      return last - count
    return base
  }

  get endIndex (): number {
    return this.startIndex + this.visiblePageCount
  }

  get visibleIndice (): Range {
    const from = this.startIndex
    const to   = from + this.visiblePageCount
    return new Range(from, to)
  }

  forEachVisibleIndex (render: number => Node): Array<Node> {
    return this.visibleIndice.map(render)
  }

  getPageChangeHandler (page: number) {
    if (page < 1 || page > this.totalPageCount) // || page === this.currentPage
      throw new RangeError(`Invalid pagination page ${page}`)
    return () => this.props.onChange(page)
  }

  render () {

    const Previous = () =>
      <span className='previous'>
        { this.renderPreviousPageButton() }
      </span>

    const Next = () =>
      <span className='next'>
        { this.renderNextPageButton() }
      </span>

    const Last = () =>
      <span className='last'>
        { this.renderLastPageButton() }
      </span>

    const First = () =>
      <span className='first'>
        { this.renderFirstPageButton() }
      </span>

    const PageIndex = page => {
      const active    = page === this.currentPage
      const className = classNames('page-index', { active })
      const onClick   = !active
        ? this.getPageChangeHandler(page)
        : undefined

      return <span
        key={ page }
        className={ className }
        onClick={ onClick } >
        { page }
      </span>
    }

    // Hide single page paginations
    if (this.totalPageCount < 2)
      return null

    return <ToolbarContainer className='pagination'>

      <Toolbar left>
        <First />
        <Previous />
      </Toolbar>

      <Toolbar center className='indice'>
        { this.forEachVisibleIndex(PageIndex) }
      </Toolbar>

      <Toolbar right>
        <Next />
        <Last />
      </Toolbar>

    </ToolbarContainer>
  }

  renderPreviousPageButton () {
    const page     = this.currentPage - 1
    const disabled = page < 1
    const onClick  = !disabled
      ? this.getPageChangeHandler(page)
      : undefined

    return <Button regular
      icon={ BackIcon }
      onClick={ onClick }
      disabled={ disabled }
      primary
    />
  }

  renderNextPageButton () {
    const page     = this.currentPage + 1
    const disabled = page > this.totalPageCount
    const onClick  = !disabled
      ? this.getPageChangeHandler(page)
      : undefined

    return <Button regular
      icon={ NextIcon }
      onClick={ onClick }
      disabled={ disabled }
      primary
    />
  }

  renderLastPageButton () {
    const page = this.totalPageCount
    const disabled = page > this.totalPageCount
    const onClick = !disabled
      ? this.getPageChangeHandler(page)
      : undefined

    return <Button regular
      icon={ LastIcon }
      onClick={ onClick }
      disabled={ disabled }
      primary
    />
  }

  renderFirstPageButton () {
    const page = 1
    const disabled = page > this.totalPageCount || page < 1
    const onClick = !disabled
      ? this.getPageChangeHandler(page)
      : undefined

    return <Button regular
      icon={ FirstIcon }
      onClick={ onClick }
      disabled={ disabled }
      primary
    />
  }

}


class Range {

  entries: Array<number>

  constructor (from, to) {
    this.entries = Array(to - from)
      .fill(from)
      .map((base, n) => base + n)
  }

  map<T> (fn: number => T): Array<T> {
    return this.entries.map(fn)
  }
}
