// @flow
import type { Action } from '.'
import { SELECT_CLIENT } from 'actions/client'

export type ClientState = {
  id: number | null,
  email: string | null,
  username: string | null,
  is_staff: boolean,
  phone_number: string | null,
  profile: {
    first_name: string | null,
    last_name: string | null,
    full_name: string | null,
    primary_language: string | null,
    picture: File | null,
    alerts_enabled: boolean,
    sms_enabled: boolean,
    terms_accepted: string | null,
    show_terms: boolean,
    social_security_number: string | null,
  }
}


export default function clientReducer (state: ClientState, action: Action) {
  const { payload } = action
  switch (action.type) {

  case SELECT_CLIENT:
    return payload === null ? {} : payload

  default:
    return state || {}

  }
}
