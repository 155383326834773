
export const UPDATE_FORM_DETAILS    = 'UPDATE_FORM_DETAILS'
export const UPDATE_FORM_ID         = 'UPDATE_FORM_ID'
export const UPDATE_FORM_TITLE      = 'UPDATE_FORM_TITLE'
export const FORMAT_FORM_STATE      = 'FORMAT_FORM_STATE'

export const SELECT_BLOCK           = 'SELECT_BLOCK'
export const MOVE_BLOCK             = 'MOVE_BLOCK'
export const APPEND_BLOCK           = 'APPEND_BLOCK'
export const UPDATE_BLOCK           = 'UPDATE_BLOCK'
export const REMOVE_BLOCK           = 'REMOVE_BLOCK'
export const INSERT_BLOCK           = 'INSERT_BLOCK'
export const UPDATE_BLOCKS          = 'UPDATE_BLOCKS'
export const CLEAR_BLOCKS           = 'CLEAR_BLOCKS'

export const UPDATE_TAGS            = 'UPDATE_TAGS'
export const APPEND_LINK            = 'APPEND_LINK'
export const UPDATE_ATTACHMENTS     = 'UPDATE_ATTACHMENTS'
export const DELETE_ATTACHMENT      = 'DELETE_ATTACHMENT'

export const REMOVE_LINK            = 'REMOVE_LINK'

// Selecting of blocks
export const selectBlock            = (id) => ({ type: SELECT_BLOCK, payload: id })
export const clearSelection         = ()   => ({ type: SELECT_BLOCK, payload: null })

// Block manipulation
export const moveBlock              = (from, to) => ({ type: MOVE_BLOCK,            payload: { from, to }})
export const appendBlock            = (payload)  => ({ type: APPEND_BLOCK,          payload })
export const updateBlock            = (payload)  => ({ type: UPDATE_BLOCK,          payload })
export const removeBlock            = (payload)  => ({ type: REMOVE_BLOCK,          payload })
export const clearBlocks            = (payload)  => ({ type: CLEAR_BLOCKS,          payload })
export const updateBlocks           = (payload)  => ({ type: UPDATE_BLOCKS,         payload })
export const insertBlock            = (position, block)  => ({ type: INSERT_BLOCK,  payload: { position, block }})

// Form details' mainpulation
export const updateFormEditorID     = (payload) => ({ type: UPDATE_FORM_ID,         payload })
export const updateFormEditorTitle  = (payload) => ({ type: UPDATE_FORM_TITLE,      payload })
export const updateFormEditorData   = (payload) => ({ type: UPDATE_FORM_DETAILS,    payload })
export const formatFormEditorState  = (payload) => ({ type: FORMAT_FORM_STATE,      payload })
export const clearFormEditorState   = ()        => ({ type: FORMAT_FORM_STATE,      payload: null })


export const updateTags             = (payload) => ({ type: UPDATE_TAGS,            payload })
export const addLinkToForm          = (payload) => ({ type: APPEND_LINK,            payload })
export const updateFormAttachments  = (payload) => ({ type: UPDATE_ATTACHMENTS,     payload })
export const deleteFormAttachment   = (payload) => ({ type: DELETE_ATTACHMENT,      payload })

export const removeLinkFromForm     = (payload) => ({ type: REMOVE_LINK,            payload })


// This module's default exports will be
// assigned to the `Editor` instance by
// calling
//
//   Object.assign(editor, module.exports.default)
//
// So any exports that are added to the object
// below may be called by calling the editor's
// respective method, e.g. editor.selectBlock(id)
export default {

  selectBlock,
  clearSelection,

  appendBlock,
  removeBlock,
  clearBlocks,
  updateBlock,
  updateBlocks,
  insertBlock,
  moveBlock,

  clear:  clearFormEditorState,
  format: formatFormEditorState,

  updateTags,
  addLink:            addLinkToForm,
  updateAttachments:  updateFormAttachments,
  deleteAttachment:   deleteFormAttachment,
}
