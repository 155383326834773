// @flow
import React, { PureComponent } from 'react'
import self from 'autobind-decorator'
import getClassNames from 'classnames'

import HelpIcon from 'components/HelpIcon'

import Toggle from 'react-toggle'
import 'react-toggle/style.css'

type SliderProps = {
  onChange: Function,
  value: boolean,
  label: string,
  disabled: boolean,
  helpIcons?: boolean,
  help_text?: string,
  id: string,
  style?: Object,
  hideLabel?: boolean,
  className?: string,
}


export default class SliderToggleField extends PureComponent<SliderProps> {

  componentDidMount () {
    // Set react-toggle hidden input tabindexes to -1 to skip them in keyboard focus
    const elements = document.getElementsByClassName('react-toggle-screenreader-only')
    for (let item of elements) {
      if (item)
        item.setAttribute('tabindex', '-1')
    }
  }

  @self
  onChange () {
    if (!this.props.disabled && this.props.onChange)
      this.props.onChange(!this.props.value)
  }

  get infoCircle (): React$Element<*> | null {
    return this.props.helpIcons
      ? <HelpIcon text={ this.props.help_text } />
      : null
  }

  render (): React$Element<*> {
    const disabled = this.props.disabled || false
    const style = this.props.style || {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    }

    const containerClassNames = getClassNames(
      'focusable',
      this.props.className
    )

    return <div style={ style } className={containerClassNames} tabIndex={ 0 } onKeyPress={ this.onChange }>
      { this.renderLabel() }
      <Toggle
        id={ this.props.id }
        className='slider-toggle'
        checked={ this.props.value }
        disabled={ disabled }
        onChange={ this.onChange }
      />
    </div>
  }

  renderLabel(): React$Element<*> | null {
    if (this.props.hideLabel) return null
    return <label
      className='label'
      htmlFor={ this.props.id }
      style={{ paddingRight: '0.5rem' }}>
      { this.props.label }
      { this.infoCircle }
    </label>
  }
}
