
import React, { Fragment } from 'react'

import routes from 'routes/public/others'


const OthersContainer = () =>
  <Fragment>
    { routes }
  </Fragment>


export default OthersContainer
