// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


export const descriptors = {
  list:                                           '/forms',
  view: (pk: ID): string                       => `/forms/${pk}/`,
  fill: (pk: ID): string                       => `/forms/${pk}/fill/`,
  submission: (pk: ID, sid: ID): string        => `/forms/${pk}/submission/${sid}`,
  delete_submission: (pk: ID, sid: ID): string => `/forms/${pk}/submissions/${sid}`,
  submissions:                                    '/forms/submissions/',
  form_submissions:  (pk: ID): string          => `/forms/${pk}/submissions/`,
  reports:                                        '/forms/reports/'
}


const ListSubmissionsView = load(() =>
  import('../views/Forms/Submissions/ListSubmissions'))

const FormSubmissionView = load(() =>
  import('../views/Forms/Forms/PreviewForm'))

const FormView = load(() =>
  import('../views/Forms/Forms/Form'))

const ListFormsView = load(() =>
  import('../views/Forms/Forms/FormsList'))

const FormReportView = load(() =>
  import('../views/Forms/Reports/FormReport'))


export default (<Switch>
  <Route path={ descriptors.fill(':id(\\d+)') } component={ FormView } />
  <Route path={ descriptors.submission(':id(\\d+)', ':submission_id(\\d+)') } component={ FormSubmissionView } />
  <Route path={ descriptors.view(':id(\\d+)') } component={ ListSubmissionsView } />
  <Route exact path={ descriptors.list } component={ ListFormsView } />
  <Route path={ descriptors.reports } component={ FormReportView } />
  <RouteNotFound />
</Switch>: React$Element<*>)
