// @flow

import React, { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { Manager, Reference, Popper } from 'react-popper'

import Icon from 'components/icons'
import self from 'autobind-decorator'
import Text, { Label } from 'components/text'
import AuthorizedComponent from 'components/AuthorizedComponent'
import { __ } from 'utils/gettext'

import Divider from '@material-ui/core/Divider'

import type { ComponentType, Node } from 'react'

export type DropdownItemType = {
  icon?: ComponentType<*>,
  label: string,
  onClick?: Function,
  component?: *,
  authorized?: boolean,
  allowedGroups?: Array<string>,
  props?: Object,
  className?: string,
  unclickable?: boolean,
  notranslate?: boolean,
  divider: boolean
}

export type DropdownProps = {
  position?: string, /* see https://popper.js.org/popper-documentation.html#Popper.placements */
  toggleComponent?: Node | Function,
  items: Array<DropdownItemType> | null,
  open?: boolean,
  small?: boolean,
  disabled?: boolean,
  paddingsmall?: boolean,
  bordered?: boolean,
  className?: string,
  tabdropdown?: boolean,
  hideOnSelect?: boolean,
  arrow?: boolean,
  modal?: boolean,
  style?: Object,
  isLoginMenu?: boolean,
  offset?: string | Array<string>, /* '25' = 25px to the right, ['25, 25'] = 25px to the right and down */
  preventOverflow?: boolean,
  menuStyle?: Object,
  buttonStyle?: Object,
  onMenuOpen?: Function,
  onMenuClose?: Function,
}

export type DropdownState = {
  open: boolean,
}


export default class Dropdown extends PureComponent<DropdownProps, DropdownState> {

  static getDerivedStateFromProps (props: DropdownProps, state: DropdownState): ?Object {
    if (state.open !== props.open)
      return { open: state.open }
    return null
  }

  static defaultProps: Object = {
    hideOnSelect: true,
    position: 'bottom-end',
    arrow: true,
  }

  scheduleUpdate: Function | null = null
  cancelClose: boolean = false
  state: DropdownState = {
    open: false,
    focusedItemIndex: 0,
  }

  componentDidUpdate () {
    switch (this.state.open) {
      case true:
        this.addDocumentEventListener()
        break
      case false:
        this.removeDocumentEventListener()
        break
    }
  }

  componentWillUnmount () {
    this.removeDocumentEventListener()
  }

  handleKeyDownEvent: Function = (event) => {
    const current = document.activeElement.id
    const index = parseInt(current.charAt(current.length - 1))
    if (event && event.type === 'keydown' && event.keyCode === 27)
      this.close()
    if (event && event.type === 'keydown' && event.keyCode === 40) {
      this.focusNextItem(current, index)
    } else if (event && event.type === 'keydown' && event.keyCode === 38) {
      this.focusPreviousItem(current, index)
    }
  }

  handleClickEvent: Function = (event) => {
    // If the menu was opened by clicking the option from the command menu ignore the simulated click
    if (event.target.tagName.toLowerCase() === 'nord-command-menu')
      return
    if (event && event.type === 'click' && this.state.open)
      this.close()
  }

  close: Function = () => {
    return !this.cancelClose ? this.update(false) : this.cancelClose = false
  }

  focusNextItem: Function = (current: string, index: number) => {
    const next = current.replace(/.$/, String(index + 1))
    if (next) {
      const nextElement = document.getElementById(next)
      if (nextElement)
        nextElement.focus()
    }
  }

  focusPreviousItem: Function = (current: string, index: number) => {
    const previous = current.replace(/.$/, String(index - 1))
    if (previous) {
      const previousElement = document.getElementById(previous)
      if (previousElement)
        previousElement.focus()
    }
  }

  update: Function = (open: boolean, event: *) => {
    // If the dropdown is opened with a keypress, manually focus the first element
    // because it has been repositioned in the DOM tree with React.Portal and cannot be
    // accessed with tabIndex
    if (event)
      event.persist()
    this.setState({ open }, () => {
      if (event) {
        const item = this.props.isLoginMenu
          ? 'active login item-1'
          : 'active item-0'
        const element = document.getElementById(item)
        if (element)
          element.focus({ preventScroll: true })
      }
    })
  }

  toggle: Function = (event: Event) => {
    if (this.props.disabled) return
    event.stopPropagation()
    if (!this.state.open) {
      if (this.scheduleUpdate)
        this.scheduleUpdate()
      if (this.props.onMenuOpen)
        this.props.onMenuOpen()
    } else if (this.props.onMenuClose) {
      this.props.onMenuClose()
    }
    this.update(!this.state.open, event)
  }

  @self
  addDocumentEventListener () {
    document.addEventListener('click', this.handleClickEvent)
    document.addEventListener('keydown', this.handleKeyDownEvent)
  }

  @self
  removeDocumentEventListener () {
    document.removeEventListener('click', this.handleClickEvent)
    document.removeEventListener('keydown', this.handleKeyDownEvent)
  }

  get classesFromProps (): Array<string | void | null> {
    return [
      this.props.paddingsmall && this.state.open ? 'padding-small' : null,
      this.props.bordered ? 'bordered'  : null,
      this.props.tabdropdown ? 'tab-dropdown' : null,
      this.props.className,
    ]
  }

  // eslint-disable-next-line complexity
  get caretClasses (): string {
    if (this.props.arrow && !this.props.small) {
      switch (this.props.position) {
        case 'bottom':
          return 'caret'
        case 'bottom-start':
          return 'caret top-left'
        case 'bottom-end':
          return 'caret top-right'
        default:
          return 'caret'
      }
    }

    return ''
  }

  get className (): string {
    const open = this.state.open
    return classNames(
      'dropdown',
      !this.props.small ? 'large' : 'small',
      { open },
      ...this.classesFromProps,
      this.caretClasses,
    )
  }

  get arrow (): boolean {
    return !!this.props.arrow
  }

  get offset (): Object {
    if (this.props.offset)
      return { enabled: true, offset: this.props.offset}
    else
      return { enabled: false }
  }

  @self
  onMenuItemClick () {
    if (!this.props.hideOnSelect)
      this.cancelClose = true
  }

  getToggleComponent (): Function | null {
    if (!this.props.toggleComponent)
      return null
    if (typeof this.props.toggleComponent === 'function')
      return this.props.toggleComponent()
    return this.props.toggleComponent
  }

  render (): React$Element<*> | null {
    const className = this.className
    const toggleComponent = this.getToggleComponent()
    const ariaLabel = this.props.isLoginMenu
      ? __('Login menu')
      : undefined
    const preventOverflow = this.props.preventOverflow
      ? true
      : false
    let querySelector = document.querySelector('#root')

    if (this.props.modal && !document.querySelector('.modal'))
      return null

    if (this.props.modal)
      if (document.querySelector('.select-exercise-modal'))
        querySelector = document.querySelector('.select-exercise-modal')
      else
        querySelector = document.querySelector('.modal')

    return <Manager positionFixed>
      <Reference>
        {({ ref }) =>
          <span className={ className } ref={ ref } style={ this.props.style }>
            <span
              role='button'
              className='focusable'
              aria-label={ ariaLabel }
              onClick={ this.toggle }
              onKeyPress={ this.toggle }
              tabIndex={ 0 }
              style={ this.props.buttonStyle ? this.props.buttonStyle : undefined}
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded={ this.state.open ? 'true' : 'false' }>
              { toggleComponent
                ? toggleComponent
                : <div className='toggle'>
                  <Icon.More size={ 14 }/>
                </div> }
            </span>
          </span> }
      </Reference>
      { createPortal(
        <Popper
          placement={ this.props.position }
          modifiers={{
            preventOverflow: { enabled: preventOverflow },
            hide: { enabled: false },
            offset: this.offset
          }}>
          {
            ({ ref, style, placement, scheduleUpdate }) => {
              this.scheduleUpdate = scheduleUpdate
              return <div
                ref={ ref }
                style={{ ...style, ...this.props.style, height: 0, zIndex: 10000 }}
                data-placement={ placement }
                className={ className }>
                <ul
                  className='menu'
                  onClick={ this.onMenuItemClick }
                  onKeyPress={ this.onMenuItemClick }
                  style={ this.props.menuStyle || undefined }>
                  { this.props.items
                    ? this.props.items.map(this.renderItem)
                    : <li><Text>Nothing to list</Text></li> }
                </ul>
              </div>
            }
          }
        </Popper>,

        querySelector

      )}
    </Manager>
  }

  @self
  //eslint-disable-next-line class-methods-use-this
  renderItem (item: DropdownItemType, key: number): React$Element<*> {
    const Component = item.component
    const Icon      = item.icon
    const onClick   = (event: SyntheticEvent<*>) => {
      if (item.props && item.props.stopPropagation){
        event.stopPropagation()
      }
      if (item.onClick) {
        item.onClick()
      }
    }

    const className =  item.unclickable
      ? classNames('item-unclickable', item.className)
      : classNames('item', item.className, 'focusable-with-margin')
    const Wrapper = item.authorized
      ? AuthorizedComponent
      : Fragment
    const wrapperProps = item.authorized && item.allowedGroups
      ? { allowedGroups: item.allowedGroups, key }
      : { key }
    const noTranslation = item.notranslate
      ? true
      : false
    const tabIndex = item.unclickable
      ? undefined
      : 0

    const itemId = this.props.isLoginMenu
      ? `login item-${key}`
      : `item-${key}`

    const active = this.state.open
      ? 'active '
      : ''

    return <Wrapper { ...wrapperProps }>
      <li
        key={ key }
        id={ active + itemId }
        onClick={ onClick || undefined }
        onKeyPress={ onClick }
        className={ className }
        tabIndex={ this.state.open ? tabIndex : undefined }>
        <div className='item-div'>
          { Component
            ? Component
            : <Fragment>
              { Icon && <span className='icon'><Icon size={ 14 }/></span>}
              <span className='title'><Label notranslate={ noTranslation }>{ item.label }</Label></span>
            </Fragment>}
        </div>
      </li>
      { item.divider && <Divider /> }
    </Wrapper>
  }
}
