/**
 * @flow
 * @class ProgressLine
 * @summary A simple visualization component for a view with multiple steps.
 * @description
 * Props: steps (default: 3, min: 1, max: 8): the number of steps the view has
 *        width (default: 400px): desired width of the progress line in px
 *        currentStep (mandatory): the index of the current step.
 *                                 This is used to color the previous steps accordingly.
 *        stepLabels (optional): an array of strings describing each step.
 */
import React, { Component } from 'react'
import connect from 'bound-connect'

import { DEFAULT_PRIMARY_COLOR } from 'constants'

import type { StateType } from 'store/initialState'


type PLPropsTypes = {
  steps: number,
  width?: number,
  currentStep: number,
  color: string,
  stepLabels?: Array<string>
}

type PLStateTypes = {
  steps: Array<*>,
  currentStep: number,
}


@connect
export default class ProgressLine extends Component<PLPropsTypes, PLStateTypes> {

  static defaultProps = {
    steps: 3,
    width: 400,
    currentStep: 1
  }

  static actions = []
  static properties = (state: StateType) => ({
    color: state.tenantSettings.color,
  })

  state = {
    steps: [],
    currentStep: 1
  }

  get upcomingStepStyle () {
    const color = this.props.color || DEFAULT_PRIMARY_COLOR
    return {
      border: `2px solid ${color}`
    }
  }

  get currentStepStyle () {
    const color = this.props.color || DEFAULT_PRIMARY_COLOR
    return { 
      border: `2px solid ${color}`,
      background: `${color}`
    }
  }

  componentDidMount () {
    this.mapStepsToState()
  }

  componentDidUpdate (prevProps: *) {
    if (prevProps.currentStep !== this.props.currentStep &&
        this.props.currentStep <= this.state.steps.length)
      this.mapStepsToState()
  }

  mapStepsToState () {
    if (this.props.steps >= 1 && this.props.steps <= 8) {
      let steps = []
      for (let i = 0; i < this.props.steps; i++) {
        if (i <= this.props.currentStep - 1)
          steps.push({
            className: 'step',
            id: i,
            style: this.currentStepStyle,
            label: this.props.stepLabels ? this.props.stepLabels[i] : null })
        else
          steps.push({
            className: 'step',
            id: i,
            style: this.upcomingStepStyle,
            label: this.props.stepLabels ? this.props.stepLabels[i] : null })
      }
      this.setState({ steps, currentStep: this.props.currentStep })
    }
  }

  render () {
    const width = this.props.width
    const color = this.props.color || DEFAULT_PRIMARY_COLOR
    return (
      <div className='pl-container'>
        <div className='progress' style={{ borderBottom: `2px solid ${color}` }}>
          <div className='percent'></div>
        </div>
        <div className='steps' style={{ width }}>
        { this.state.steps.map((step, index) =>
          <div
            className={ step.className }
            id={ step.id }
            key={ index }
            style={ step.style }><span className='small-text' notranslate='true'>{ step.label }</span></div>) }
        </div>
      </div>
    )
  }
}
