/**
 * @module DateRangeFields
 * @flow
 * @summary A combination of two DayPickerInput fields and a double calendar to choose a date range with
 * @description
 * When added to another component, both inputs have to be handled with one 'onChange' function.
 * The component returns a 'dateObject' with the date and the type of the field, i.e.:
 * { date: parsedDate, type: 'from' }
 * Currently only supports selecting a date from the calendar.
 * For DayPickerInput API, see https://react-day-picker.js.org/api/DayPickerInput
 * TODO: Better localization with date-fns?
 */

import React from 'react'
import InputField from './InputField'

import moment from 'moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { getLocale } from 'utils/gettext'

import { formatDate } from 'react-day-picker/moment'
import { DATE_FORMAT } from '../../../src/constants'

const DATE_VALUE_FORMAT = 'YYYY-MM-DD'
const parseDateToValue = (date: Date | string) => date ? moment(date).format(DATE_VALUE_FORMAT) : undefined


export default class DateRangeFields extends InputField {

  constructor (props: Object) {
    super(props)
    this.handleFromDateChange = this.handleFromDateChange.bind(this)
    this.handleToDateChange = this.handleToDateChange.bind(this)
    this.state = {
      from: undefined,
      to: undefined,
      fromPlaceholder: '',
      toPlaceholder: '',
      todayButtonLabel: '',
    }
  }

  componentDidMount () {
    const locale = getLocale() || 'fi'
    const fromPlaceholder = this.translateFromDatePlaceholder(locale)
    const toPlaceholder = this.translateToDatePlaceholder(locale)
    const todayButtonLabel = this.translateTodayButtonLabel(locale)
    this.props.defaultFromDate && this.handleDateChange(this.props.defaultFromDate, 'from')
    this.props.defaultToDate && this.handleDateChange(this.props.defaultToDate, 'to')
    this.setState({
      fromPlaceholder,
      toPlaceholder,
      todayButtonLabel
    })
  }

  translateFromDatePlaceholder: Function = (locale: string): string => {
    if (locale === 'fi')
      return 'Alkupvm'
    else if (locale === 'en')
      return 'From'
    else
      return 'Från'
  }

  translateToDatePlaceholder: Function = (locale: string): string => {
    if (locale === 'fi')
      return 'Loppupvm'
    else if (locale === 'en')
      return 'To'
    else
      return 'Till'
  }

  translateTodayButtonLabel: Function = (locale: string): string => {
    if (locale === 'fi')
      return 'Tänään'
    else if (locale === 'en')
      return 'Today'
    else
      return 'I dag'
  }

  showFromMonth () {
    const { from, to } = this.state
    if ((from && to) && moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }
  }

  handleFromDateChange: Function = (date?: string): Function | void => this.handleDateChange(date, 'from')

  handleToDateChange: Function = (date?: string): Function | void => this.handleDateChange(date, 'to')

  handleDateChange: Function = (date, type): Function | void => {
    if (!date)
      date = this.element ? this.element.input.value : undefined

    if (date && is.string(date) && !date.length)
      date = undefined

    if (date && type === 'to') {
      this.update({ date: parseDateToValue(date), type: 'to' })
      this.setState({ to: date }, this.showFromMonth)
    } else if (date && type === 'from') {
      this.update({ date: parseDateToValue(date), type: 'from' })
      this.setState({ from: date })
    }
  }

  render (): React$Element<*> {
    const locale = getLocale() || 'fi'

    const { from, to } = this.state
    const modifiers = { start: from, end: to }
    return (
      <div className='InputFromTo'>
        <DayPickerInput
          value={ from }
          placeholder={ this.state.fromPlaceholder }
          format={ DATE_FORMAT }
          formatDate={ formatDate }
          dayPickerProps={{
            selectedDays: [ from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            localeUtils: MomentLocaleUtils, locale: locale,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={ this.handleFromDateChange }
        />{' '}
        —{' '}
        <span className='InputFromTo-to'>
          <DayPickerInput
            ref={ el => this.to = el }
            value={ to }
            placeholder={ this.state.toPlaceholder }
            format={ DATE_FORMAT }
            formatDate={ formatDate }
            dayPickerProps={{
              selectedDays: [ from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
              todayButton: this.state.todayButtonLabel,
              localeUtils: MomentLocaleUtils, locale: locale
            }}
            onDayChange={ this.handleToDateChange }
          />
        </span>
      </div>
    )
  }
}
