// @flow
import { create } from 'apisauce'
import { Emitter } from 'event-kit'

// import auth from './auth'
import resolveRequestArguments, { formatSuccessResponse, formatErrorResponse } from './formatters'
import { url as baseURL, getDomainUrl, ALLOWED_METHODS, isPostMethod, SIGNATURE_EXPIRED } from './constants'
import type { MethodType } from './constants'
import cache from './cache'
import qs from 'qs'


let resolve = resolveRequestArguments


const events = new Emitter()
const headers = {
  'DTS-DOMAIN-URL': getDomainUrl()
}

const FILE_URLS = {
  threads: {
    url: (url, id) => `messages/${id}/attachments/create/`,
    method: 'post'
  },
  users: {
    url: (url, id, method) => method === 'post'
      ? `${url}${id}/profile/picture/`
      : `${url}profile/picture/`,
    method: 'patch'
  },
  default: {
    url: (url, id, method) => method === 'post'
      ? `${url}${id}/attachments/create/`
      : `${url}attachments/create/`,
    method: 'post'
  }
}


const decorateResponse = response => response.ok
  ? formatSuccessResponse(response)
  : formatErrorResponse(response)


const captureNetworkErrors = response => response.problem === 'NETWORK_ERROR'
  ? events.emit('error', response)
  : response


/**
 * The api instance that dispatches the requests.
 * Uses an Axios instance internally.
 *
 * @const api
 * @private
 */

const api = registerParsers(
  create({
    paramsSerializer: params => qs.stringify(params, { indices: false }),
    baseURL,
    headers,
    withCredentials: true  // TODO: This does not need to be global.
  })
)


function registerParsers (api) {

  api.addResponseTransform(decorateResponse)

  // Handle network errors
  api.addResponseTransform(captureNetworkErrors)

  return api
}

type argType = {
  payload: *,
  calback: *,
} | *


/**
 * Make a request to the {@link api} endpoint `url`.
 * @method makeRequest
 */
//eslint-disable-next-line complexity, max-statements
export default async function makeRequest (method: MethodType, url: string, data: * = {}, tryTokenRefresh?: boolean = true): Promise<api.ResponseType<*>> {

  method = method.toLowerCase()
  assertMethod(method)

  const args: argType = resolve(method, url, data)
  const request       = isPostMethod(method) ? api.post : api.get

  const files        = args[1].files
  if (files) args[1] = args[1].payload

  // TODO: Cleanup
  if (method === 'options') {
    const cached = cache.getCachedResponse(args[0], method)
    if (cached !== null)
      return cached
  }

  const response =  await request(...args)
  const status = response.status
  const problem  = response.problem

  if (is.number(status) && status === 0) {
    response.problem = 'Request canceled'
    response.data    = {}
  }

  if (status === 401 && tryTokenRefresh && url !== 'token/refresh') {
    await require('./auth').default.refresh()
    return makeRequest(method, url, data, false)
  }

  if (problem && response.data && response.data.detail === SIGNATURE_EXPIRED) {
    require('./auth').default.clear()
  }
  if (method === 'options' && response.status === 404)
    events.emit('404', response)

  // TODO: Cleanup
  if (method === 'options' && !problem)
    cache.storeResponse(response)

  // TODO: Cleanup
  // If there were no issues with sending the data and there are files to send then
  // post the files to '/{model}/{modelId}/attachments/create'
  if (!response.problem && files) {
    const url: string    = response.config.url.split('/api/')[1]
    const id: number     = response.data.id

    // TODO: Make this smarter and better since this is pretty dumb
    let method
    let queryUrl
    if (url.indexOf('threads') > -1) {
      queryUrl = FILE_URLS.threads.url(url, id)
      method = FILE_URLS.threads.method
    } else if (url.indexOf('clients') > -1
              || url.indexOf('staff') > -1
              || url.indexOf('user') > -1) {
      queryUrl = FILE_URLS.users.url(url, id, response.config.method)
      method = FILE_URLS.users.method
    } else {
      queryUrl = FILE_URLS.default.url(url, id, response.config.method)
      method = FILE_URLS.default.method
    }

    const attachmentResponse = await api[method](queryUrl, files)
    if (attachmentResponse.problem)
      return attachmentResponse
  }

  return response
}


/**
 * Set the base url of the api object
 * @param {string} url [OPTIONAL] If given the value is set as the base url, otherwise the default baseURL is used
 */
export const setBaseUrl = (url?: string) => {
  if (is.string(url)) {
    api.setBaseURL(url)
  } else {
    api.setBaseURL(baseURL)
  }
}


/**
 * Set the headers to be sent with the api calls
 * @param {Array<Object> | Object} headers List of or a single header object to be added
 */

export const setHeaders = (newHeaders: Object) => {
  Object.assign(headers, newHeaders)
  api.setHeaders(headers)
}

export const setHeader = (name: string, value: string | number) => {
  Object.assign(headers, { [name]: value })
  api.setHeader(name, value)
}

export const deleteHeader = (name: string) => {
  delete headers[name]
  api.deleteHeader(name)
}

export const getHeaders = () =>
  headers



/**
 * Assert a string is a valid api request method
 * @private
 * @method  assertMethod
 * @param   {String} method
 */

function assertMethod (method) {
  if (typeof method !== 'string')
    throw new TypeError(
      `Method is of invalid type ${typeof method}. Expected a string.`)

  if (!ALLOWED_METHODS.includes(method.toLowerCase()))
    throw new TypeError(
      `Invalid method ${method.toLowerCase()}. Method should be one of\n` +
      `\t${ALLOWED_METHODS.join(',\n\t')}\n`)
}


/**
 * Add a callback function to handle network errors in api calls
 * @public
 * @method  onNetworkError
 * @param   {Function}      fn Callback function that is dispatched upon a network error response.
 * @return  {Disposable}       A disposable instance, that upon disposal, unbinds the given handler.
 */

export function onNetworkError (fn: Function) {
  return events.on('error', fn)
}


/**
 * Add a callback function to handle client errors in api calls
 * @public
 * @method  onClientError
 * @param   {Function}      fn Callback function that is dispatched upon a client error response.
 * @return  {Disposable}       A disposable instance, that upon disposal, unbinds the given handler.
 */

export function onClientError (fn: Function) {
  return events.on('404', fn)
}



if (module.hot)
  module.hot.accept('./formatters', () => {
    resolve = require('./formatters').default
    require('./auth').default.updateApiHeaders()
  })
