// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeUser = load(() =>
  import('../../../views/Public/Users/ComposeUser'))

const UsersView = load(() =>
  import('../../../views/Public/Users/ListUsers'))


export const descriptors = {
  users:            `/users/`,
  createUser:       `/users/create/`,
  user: (pk: ID) => `/users/${pk}`,
}


export default <Switch>
  <Route path={ descriptors.createUser } component={ ComposeUser } />
  <Route path={ descriptors.user(':id(\\d+)') } component={ ComposeUser } />
  <Route exact path={ descriptors.users } component={ UsersView } />
  <RouteNotFound />
</Switch>
