// @flow
import type { Action } from '.'
import {
  SET_INITIAL_DATA,
  SELECT_SERVICE,
  SELECT_LOCATION,
  SELECT_ALL_LOCATIONS,
  SELECT_DATE,
  UPDATE_DATE,
  SELECT_EMPLOYEE,
  SELECT_TIMESLOT,
  INCREASE_STEP,
  DECREASE_STEP,
  HANDLE_CALENDAR_CHANGE,
  HANDLE_YEAR_SELECTION,
  YEAR_TRANSITION,
  SET_CACHED_DATA,
  RESET_BOOKING,
} from '../actions/onlineBooking'

const getDefaultCalendarStateValues = () => {
  const date_string = new Date().toISOString().split('T')[0]
  const [year, month, day] = date_string.split('-')
  return { year, month, day, date_string }
}

const { date_string, year, month, day } = getDefaultCalendarStateValues()

export const initialValue: OnlineBookingState = {
  all_services: [],
  selected_service: null,
  all_locations: [],
  selected_locations: [],
  available_date: null,
  all_employees: [],
  selected_employee: null,
  selected_time_slots: null,
  calendar_state: {
    selected_date_string: date_string,
    selected_month: month,
    selected_day: day,
    selected_year: year,
  },
  step: 0,
  locationSkipped: false,
}

export default function onlineBookingReducer(
  state: OnlineBookingState = initialValue,
  action: Action
): OnlineBookingState {
  switch (action.type) {
    case SET_INITIAL_DATA: {
      const { all_employees, all_locations, all_services } = action.payload
      return { ...state, all_employees, all_locations, all_services }
    }
    case SELECT_SERVICE:
      return {
        ...state,
        selected_service: action.payload,
      }
    case SELECT_LOCATION: {
      const { locations, locationSkipped } = action.payload
      return {
        ...state,
        selected_locations: locations,
        locationSkipped: locationSkipped,
      }
    }
    case SELECT_ALL_LOCATIONS:
      return {
        ...state,
        selected_locations: action.payload,
      }
    case SELECT_DATE:
      return {
        ...state,
        available_date: action.payload.date,
        step: state.step + action.payload.increaseStep,
      }
    case UPDATE_DATE:
      return {
        ...state,
        // $FlowIgnore
        available_date: {
          ...state.available_date,
          date: action.payload,
        },
      }
    case SELECT_EMPLOYEE:
      return {
        ...state,
        selected_employee: action.payload,
      }
    case SELECT_TIMESLOT:
      return {
        ...state,
        selected_time_slots: action.payload,
        step: state.step,
      }
    case INCREASE_STEP:
      return { ...state, step: state.step + action.payload }
    case DECREASE_STEP:
      return { ...state, step: state.step - action.payload }
    case HANDLE_CALENDAR_CHANGE:
      return { ...state, calendar_state: action.payload }
    case HANDLE_YEAR_SELECTION:
      return {
        ...state,
        calendar_state: action.payload,
      }
    case YEAR_TRANSITION:
      return {
        ...state,
        calendar_state: action.payload,
      }
    case SET_CACHED_DATA: {
      const {
        employee,
        selected_time_slots,
        available_date,
        service,
        locations,
      } = action.payload
      return {
        ...state,
        employee,
        selected_time_slots,
        available_date,
        service,
        locations,
        step: 7,
      }
    }
    case RESET_BOOKING:
      return { ...initialValue }
    default:
      return state
  }
}

export type OnlineBookingState = {
  step: number,
  all_services: Array<Service>,
  selected_service: ?Service,
  all_locations: Array<Location>,
  selected_locations: Array<Location>,
  available_date: AvailableDates | null,
  all_employees: Array<Employee>,
  selected_employee: ?Employee,
  selected_time_slots: SelectedTimeSlot | null,
  calendar_state: CalendarState,
  locationSkipped: boolean,
}

export type InitialData = {
  all_employees: Array<Employee>,
  all_locations: Array<Location>,
  all_services: Array<Service>,
}

export type Service = {
  id: number,
  name: string,
}

export type Location = {
  id: number,
  name: string,
  address: {
    postal_code: string,
    post_office: string,
    street_address: string,
  },
}

// List of employee ids is required to filter out
// required employees at the next step
export type AvailableDates = {
  date: string,
  employees: number[],
  treatment_duration_id: number[],
}

export type CalendarState = {
  selected_date_string: string,
  selected_year: string,
  selected_month: string,
  selected_day: string,
}

export type Employee = {
  id: number,
  name: string,
  img_url?: string,
  description?: string,
  profession?: string,
  corporates: Array<number>,
  treatment_ids: Array<number>,
}

export type LocationWithAvaialbleTimes = {
  location_id: number,
  available_times: string[],
}

export type ServiceType = {
  id: number,
  name: string,
  location_specific_times: LocationWithAvaialbleTimes[],
  description: string | null,
  onlinename: string | null,
  onlinedescription: string | null,
}

export type SelectedTimeSlot = {
  treatment_id: number,
  treatment_name: string,
  time: string,
  location_id: number,
}
