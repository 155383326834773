// @flow
import React from 'react'

import Field from './Field'


export type ValueType = number

export type OptionsType = {
  slider: boolean,
}

type NumericFieldProps = {
  value: number | string
}

type NumericFieldState = {
  disabled: boolean,
}

type NumericFieldAttributes = {|
  min: number,
  max: number,
  steps: number,
  onChange: Function,
  className: string,
  value?: string | number,
  disabled?: string | boolean
|}

type SliderState = { value: number }

const SLIDER_DEFAULT_MIN = 0
const SLIDER_DEFAULT_MAX = 100


const getDisabledAttributeValue = (state: NumericFieldState): 'disabled' | false =>
  state.disabled
    ? 'disabled'
    : false


export default class NumericField extends Field<NumericFieldProps> {

  type: string = this.getOptionValue('slider') ? 'number' : 'slider'
  static defaultValue: string = ''

  attributes: NumericFieldAttributes = {
    min:       0,
    max:       100,
    steps:     100,
    onChange:  this.onChange,
    className: 'full-width',
  }

  forceSliderMinMax (min: number | string, max: number | string): Object {
    if (this.getOptionValue('slider')){
      if (max == null) max = SLIDER_DEFAULT_MAX
      if (min == null || min.length === 0) min = SLIDER_DEFAULT_MIN
    }
    return { min: min, max: max }
  }

  calculateSteps: Function = (min: number, max: number): number | void => {
    if (min && max)
      return max - min
    else if (min)
      return 100 - min
    else if (max)
      return max
  }

  componentDidMount () {

    let min = this.getOptionValue('min_value')
    let max = this.getOptionValue('max_value')

    let vals = this.forceSliderMinMax(min, max)
    min = vals.min
    max = vals.max

    let steps = 100

    steps = this.calculateSteps(min, max)
    if (isNaN(steps))
      steps = 100

    this.attributes = {
      min:       min,
      max:       max,
      steps:     steps,
      value:     this.props.value,
      disabled:  getDisabledAttributeValue(this.props),
      onChange:  this.onChange,
      className: this.getOptionValue('slider') ? 'full-width' : '',
    }

    this.update(this.state.value || this.constructor.defaultValue, null, 'numeric', true)
  }

  renderFormControl (): React$Element<*> {

    const handleBlur = (e) => {
      const number = e.target.value ? Number(e.target.value) : e.target.value
      const value = isNaN(number) ? e.target.value : number
      this.update(value, this.name, 'numeric', false)
    }

    return this.getOptionValue('slider')
      ? <SliderInput
        { ...this.attributes }
        value={ this.state.value }
        isPrinting={ this.props.isPrinting }
        update={ this.update }
        name={ this.props.name }
        required={ this.props.required } />
      : <input type='text'
        { ...this.attributes }
        aria-required={ this.props.required ? 'true' : undefined }
        id={this.name}
        onBlur={ handleBlur }
        value={ this.state.value }
        disabled={ this.disabled } />
  }

  toJSON (): { [string]: ValueType } {
    if (this.readonly) return {}
    return {
      [this.name]: this.value === '' ? null : this.value,
    }
  }

}


export class SliderInput extends React.PureComponent<*, SliderState> {

  state: SliderState = {
    value: 0,
  }

  static defaultProps: Object = {
    min:   0,
    max:   100,
    steps: 100,
  }

  static getDerivedStateFromProps (props: *, { value }) {
    if (!props.value)
      return null

    // Ignore invalid values & frames with no changes
    const candidate = parseFloat(props.value)

    if (isNaN(candidate))
      return null
    if (candidate === value)
      return null

    const step = getSteps(props)
    const halfStep = step.interval / 2

    // Compensate the errors in rounding
    // and limit the value to the bounds defined
    // in the component's props.

    const highPassThreshold = step.min + halfStep
    if (candidate < highPassThreshold)
      return { value: step.min }

    const lowPassThreshold  = step.max - halfStep
    if (candidate > lowPassThreshold)
      return { value: step.max }

    return { value: candidate }
  }

  componentDidUpdate () {
    const check = this.props.value.length || typeof(this.props.value) === 'number'
    if (!check) {

      // if the value is not set or is empty string
      // default to minimum value if it's larger than 0
      // or else to 0
      let value = this.props.min ? Number(this.props.min) : 0
      if (value > this.props.min)
        value = this.props.min
      this.props.update(value, this.name, 'numeric', true)
    }
  }

  render (): React$Element<*> {
    const step = getSteps(this.props)
    const className = `slider${ this.props.isPrinting ? ' print' : '' }`

    return <div className={ className }>
      <div className='slider-container'>
        <output>
          { this.props.min }
        </output>
        <input
          aria-required={ this.props.required ? 'true' : undefined }
          id={ this.props.name }
          min={ step.min }
          max={ step.max }
          step={ step.interval }
          value={ this.state.value }
          onChange={ this.props.onChange }
          onClick={ this.props.onChange }
          disabled={ this.props.disabled }
          className={ this.props.className }
          type='range'
        />
        <output>
          { this.props.max }
        </output>
      </div>
      <output>
        { this.state.value }
      </output>
    </div>
  }
}


function getSteps (props) {
  const minimum = parseFloat(props.min)
  const maximum = parseFloat(props.max)
  const min     = Math.min(minimum, maximum)
  const max     = Math.max(minimum, maximum)
  const steps   = parseInt(props.steps)
  const range   = max - min

  return { min, max, steps, range, interval: Math.floor(range / steps * 1000) / 1000 }
}
