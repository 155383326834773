// @flow
import React, { Component, Fragment } from 'react'
import api from 'api'
import connect from 'bound-connect'

import { EVENT_TYPES } from 'constants'

import { selectClient } from 'actions/client'

import Dropdown from 'components/Dropdown'
import Icon from 'components/icons'
import { Bold, SmallText } from 'components/text'

import type NotificationType from 'models/Notification'
import type UserType from 'models/User'

import navigator from 'routes/base'

import type { StateType } from 'store/initialState'

import * as EventHandler from 'utils/events'
import { formatRelativeDate } from 'utils/formatters'
import __ from 'utils/gettext'
import getItemBody from 'utils/notifications'


type NotificationsState = {
  notifications: Array<*>,
  unread: Array<*>
}

type ConnectedActions = {
  updateSelectedClient?: Function
}

@connect
export default class NotificationMenu extends Component<*, NotificationsState> {

  static actions: ConnectedActions = (dispatch: Function) => ({
    updateSelectedClient: (client: UserType | null) => {
      dispatch(selectClient(client))
      EventHandler.postEvent(EVENT_TYPES.LIST_UPDATE, client)
    }
  })
  static properties: Object = (state: StateType) => ({
    notifications: state.core.notifications,
    selectedClient: state.client
  })

  state: NotificationsState = {
    notifications: [],
    unread: [],
  }

  componentDidMount () {
    this.getNotifications()
  }

  componentDidUpdate (prevProps: *) {
    if (prevProps.notifications !== this.props.notifications)
      this.getNotifications()
  }

  getNotifications () {
    const notifications = this.props.notifications || []
    let unread = []
    if (notifications.length > 0)
      notifications.forEach(n => {
        if (!n.seen)
          unread.push(n.id)
      })
    this.setState({ notifications, unread })
  }

  get items (): Array<*> {
    const items = []
    if (this.state.notifications.length > 0)
      this.state.notifications.map(item => {
        let notification = {
          id: item.id,
          seen: item.seen,
          component: this.itemComponent(item),
          divider: true,
          notranslate: true,
          onClick: () => this.redirectToItem(item)
        }
        items.push(notification)
      })
    else
      items.push({ component: this.emptyItemComponent() })
    return items
  }

  get menuStyle (): Object {
    if (this.props.isMobile)
      return {
        overflow: 'auto',
        width: '100vw',
      }
    else
      return {
        overflow: 'auto'
      }
  }

  async redirectToItem (item: NotificationType) {
    // Boolean that will help sending user to ListView or Details page
    // Depending on if notification is grouped or not
    const isGroupedNotification = item.related_notifications_count > 1

    if (item.client)
      this.props.updateSelectedClient(item.client)
    let notifications = [...this.state.notifications]
    let el = notifications.find(n => n.id === item.id)
    if (el)
      el.seen = true
    this.setState({ notifications })

    // Required to redirect to submissios tab
    const params = isGroupedNotification && item.type === 3 ? {tab: 'submissions'} : null
    navigator.navigate(this.createURL(item.type, item.object_id, item.form_id, isGroupedNotification), params)
  }

  async markNotificationsAsSeen () {
    if (this.state.unread.length > 0) {
      await api.patch('/in-app-notifications/mark-seen/', {'unseen_ids': this.state.unread})
      this.setState({ unread: [] })
    }
  }

  createURL: Function = (type: number, oid: number, form_id: number, isGroupedNotification: boolean): ?string => {
    switch (type) {
      case 1:
        return `/threads/${oid}`
      case 2:
        return '/scheduling/'
      case 3:
        return isGroupedNotification ? '/forms/' : `/forms/${form_id}/submission/${oid}`
      case 4:
        return '/documents/'
      case 5:
        return isGroupedNotification ? '/courses/' : `/courses/${oid}`
    }
  }

  onMenuOpen: Function = () => {
    return this.markNotificationsAsSeen()
  }

  itemComponent: Function = (item): React$Element<*> => {
    return <div className='notification-container'>
      <span className='unread'>
        { !item.seen && <span className='unread-marker'></span> }
      </span>
      <div className='notification-item'>
        <span className='notification-body' dangerouslySetInnerHTML={{ __html: getItemBody(item)}}></span>
        <span className='notification-footer' notranslate='true'>{ formatRelativeDate(item.created, true) }</span>
      </div>
    </div>
  }

  emptyItemComponent: Function = (): React$Element<*> => {
    return <div className='notification-container'>
      <div className='notification-item'>
        <Bold><SmallText>You do not have any new notifications.</SmallText></Bold>
      </div>
    </div>
  }

  renderBadge (): ?React$Element<*> {
    if (this.state.unread.length === 0)
      return null
    const size = this.props.isMobile
      ? 'tiny'
      : 'small'

    const Badge = <span className={`badge ${size} notification`}>{ this.state.unread.length }</span>
    return Badge
  }

  render (): React$Element<*> {
    const iconSize = this.props.isMobile
      ? 16
      : 20
    const offset = this.props.isMobile
      ? '0'
      : '70'
    const preventOverflow = this.props.isMobile
      ? true
      : false
    const ariaLabel = this.state.unread.length > 0
      ? __('Notification menu. ${unread} unread notifications.', { unread: this.state.unread.length.toString() })
      : __('Notification menu')

    return <Fragment>
      <Dropdown
        toggleComponent={
          <div className='notification-menu' id='notification-menu' aria-label={ ariaLabel }>
            <Icon.Notifications color={'#666666'} size={ iconSize } />
            { this.renderBadge() }
          </div> }
        items={ this.items }
        buttonStyle={{ paddingRight: '8px' }}
        hideOnSelect={ true }
        offset={ offset }
        menuStyle={ this.menuStyle }
        preventOverflow={ preventOverflow }
        onMenuOpen={ this.onMenuOpen }
        className='notifications' />
    </Fragment>
  }
}
