/**
 * @flow
 */

import React, { PureComponent, Fragment } from 'react'
import Text from 'components/text'

type DateTimeLocalProps = {
  value: string,
  onChange: Function,
  disabled?: boolean,
  id: string,
}

type DateTimeLocalState = {
  currentValue: string,
  timezone: string
}

class DateTimeLocalField extends PureComponent<DateTimeLocalProps, DateTimeLocalState> {

  state: DateTimeLocalState = {
    currentValue: '',
    timezone: ''
  }

  componentDidMount() {
    // this.props is in format fo yyyy-mm-ddThh:mm:ssZ
    // In order to be correctly displayed in input field it should be in format
    // yyyy-mm-ddThh:mm
    // formattedStringFromProps handles this issue
    const initialValue = !this.props.value ? this.inputInitialValue : this.formattedStringFromProps
    this.setState({currentValue: initialValue, timezone: getTimeZone() })
  }

  render (): React$Element<*> {
    const onChange = event => {
      const newValue: string = event.target.value
      this.setState({ currentValue: newValue})
      this.props.onChange(`${newValue}${this.state.timezone}`)
    }

    return <input
      id={ this.props.id }
      onChange={ onChange }
      type="datetime-local"
      value={this.state.currentValue }
    />

  }

  get formattedStringFromProps(): string {
    const { value } = this.props
    return includeLocalTimezoneToIsoString(value)
  }

  get inputInitialValue(): string {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    now = now.toISOString().slice(0,16);
    return now
  }

}

// Following function converts date received from the server
// which is in format of yyyy-mm-ddThh:mm:ssZ with the UTC Timezone
// into format yyyy-mm-ddThh:mm with local timezone
// So that the time received from the server would be correctly diplayed in <input />
function includeLocalTimezoneToIsoString(dateFromServer: string): string {
  const utcDate: Date = new Date(Date.parse(dateFromServer))
  const isoWithTimezone = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000)).toISOString().slice(0,16)
  return isoWithTimezone
}

// Required for the onChange method of the input element itself,
// So that correct time will be recieved by the backend
// This timezone is appended to the value selected by <input />
function getTimeZone() {
  const offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}

export default DateTimeLocalField