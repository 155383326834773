// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { removeAlert } from 'actions/core'
import { Caption, SmallText } from 'components/text'
import Icon from 'components/icons'
import { __ } from 'utils/gettext'
import { resolveClass } from 'utils/resolve'

export type AlertLevel = 'info' | 'success' | 'warning' | 'error'

export type AlertProperties = {
  id: number,
  level: AlertLevel,
  key: string,
  text?: string,
  children?: React$Element<*>,
  persistent?: boolean,
  dismiss: Function,
  content?: string,
  style?: string,
  params: Object,
  notranslate?: boolean,
  title?: string,
  contentStyle?: Object,
  customDismiss?: Function,
}

type AlertContentProperties = {
  text: string | React$Element<*> | null,
  params: Object,
  notranslate: boolean,
  title?: string,
  contentStyle?: Object,
}

const CloseIcon = ({ action }: { action: (Event) => * }): React$Element<*> => (
  <span
    className='close focusable'
    onClick={action}
    onKeyPress={action}
    aria-label={__('Close alert')}
    tabIndex={0}
  >
    <Icon.Cancel />
  </span>
)

const Content = (props: AlertContentProperties): React$Element<*> => (
  <div className='content' style={props.contentStyle}>
    {props.title && (
      <Caption style={{ padding: '0 0 0.25em 0' }}>{props.title}</Caption>
    )}
    <SmallText notranslate={props.notranslate} params={props.params}>
      {props.text}
    </SmallText>
  </div>
)

class Alert extends Component<AlertProperties> {
  alertClass: string = ''
  className: string = ''
  icon: React$Element<*> | null

  constructor(props: AlertProperties) {
    super(props)
    if (!props.persistent)
      this.icon = <CloseIcon action={() => this.dismiss()} />
  }

  componentDidMount() {
    if (!this.props.persistent) document.getElementById('alert').focus()
  }

  dismiss() {
    this.props.dismiss(this.props.id)
    if (this.props.customDismiss) this.props.customDismiss()
  }

  resolveAlertClass() {
    let level = this.props.level
    let alertClass = resolveClass(this.props)
    if (alertClass.length === 0) alertClass = level
    if (alertClass.length === 0) alertClass = 'success'
    return `alert ${alertClass} focusable`
  }

  render() {
    return (
      <div
        id='alert'
        className={this.resolveAlertClass()}
        style={this.props.style}
        tabIndex={0}
      >
        <Content
          notranslate={this.props.notranslate || false}
          text={this.props.text || this.props.children || null}
          params={this.props.params}
          title={this.props.title}
          contentStyle={this.props.contentStyle}
        />
        {this.icon}
      </div>
    )
  }
}

const connectActions = (dispatch) => ({
  dismiss: (id) => dispatch(removeAlert(id)),
})

export default connect(null, connectActions)(Alert)
