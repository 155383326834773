//@flow

/**
 * Replace all found instances of a string within a string
 * @param  {string} text        The string to replace from
 * @param  {string} toReplace   The string to replace
 * @param  {string} replaceWith The string to replace with
 * @return {string}             The string with all instances replaced
 */
export function replaceAll (text: string, toReplace: string, replaceWith: string) {
  return text.replace(new RegExp(toReplace, 'g'), replaceWith)
}
