/* eslint-disable no-console */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
import api from 'api'

const UPDATE_INTERVAL   = 15000

export default function register (addUpdateAlert?: Function) {
  try {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
      navigator.serviceWorker
        .register('/service-worker.js', { scope: '/' })
        .then(registration => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {

                  // At this point, the old content will have been purged and
                  // the fresh content will have been added to the cache.
                  // It's the perfect time to display a "New content is
                  // available; please refresh." message in your web app.
                  if (addUpdateAlert) {
                    api.clearCache()
                    addUpdateAlert()
                  }
                }
              }
            }
          }
        })
        .catch(error => {
          console.error('Error during service worker registration:', error)
        })
      navigator.serviceWorker.ready.then(startServiceWorkerUpdater)
      navigator.serviceWorker.onerror = onError
    }
  } catch (e) {
    console.warning('Browser does not support service workers.')
  }
}

function startServiceWorkerUpdater (serviceWorkerRegistration) {
  if (serviceWorkerRegistration)
    setInterval(() => updateServiceWorker(serviceWorkerRegistration), UPDATE_INTERVAL)
}

function updateServiceWorker (serviceWorkerRegistration) {
  serviceWorkerRegistration.update()
    .catch(onError)
}

export function unregister (): Promise<*> {
  if ('serviceWorker' in navigator)
    return navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
  return Promise.resolve(() => null)
}

function onError (err?: *) {
  //eslint-disable-next-line no-console
  console.warn('Failed to update service worker. The server might be down or an update in progress.', err || '')
}
