/**
 * @module PasswordField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import React from 'react'
import InputField  from './InputField'
import self from 'autobind-decorator'

import Icon from 'components/icons'

export type ValueType = string | null

export type OptionsType = {
  hidden: boolean,
}


export default class PasswordField extends InputField<ValueType, OptionsType> {

  state = {
    hidden: true,
  }

  @self
  toggleHidden () {
    this.setState({ hidden: !this.state.hidden })
  }

  renderFormControl () {

    return <div className='password-field'>
      <input
        id={ this.name }
        type={ this.state.hidden ? 'password' : 'text' }
        value={ this.props.value }
        onChange={ this.onChange }
        disabled={ this.disabled }
      />
    </div>
  }
}
