// @flow
/* eslint-disable no-console */
import Symbol from 'es6-symbol'
import navigator from 'routes/base'

interface SymbolKeyInterface {
  [key: Symbol]: any;
}

type CancelableInterfaceType<R> = {
  promise: Promise<R>,
  cancel: () => void,
}

const query = Symbol('Promise instance')


export default function makeCancelable<ResolveValue: *> (promise: Promise<ResolveValue>): CancelableInterfaceType<ResolveValue> {

  let hasCanceled_ = false
  const url = navigator.url.slice(0)

  const onCancel = () => {
    console.warn(`A promise was cancelled in "${url}"`)
  }

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? onCancel() : resolve(val),
      error => hasCanceled_ ? onCancel() : reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel () {
      hasCanceled_ = true
    },
  }
}


export class CancelablePromise<ResolveValue> implements SymbolKeyInterface {
  $key: query
  $value: Promise<ResolveValue>

  then:   (Function) => Promise<ResolveValue>
  catch:  (Function) => Promise<ResolveValue>
  cancel: () => void

  static await (promise: Promise<ResolveValue>): CancelablePromise<ResolveValue> {
    const instance = new CancelablePromise(promise)
    return instance
  }

  constructor (promise: Promise<ResolveValue>) {
    const cancelable = makeCancelable(promise)
    this[query] = cancelable.promise
    this.cancel = cancelable.cancel
    this.then   = this[query].then.bind(this[query])
    this.catch  = this[query].catch.bind(this[query])
  }
}
