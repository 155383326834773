/* eslint-disable block-padding/functions, no-console */

import { Disposable } from 'event-kit'
import EventEmitter from 'events'

const EVENT_EMITTER = new EventEmitter()
const CHANNELS = new Map()

/**
 * Post an event
 * @param  {string} eventName Name of the event
 * @param  {any}    data      Data to be received on event trigger
 * @return {void}
 */
export function postEvent(eventName: string, data?: *) {
  EVENT_EMITTER.emit(eventName, data || '')
}

/**
 * Start listening to an event
 * @param  {string}   eventName The name of the event to listen to
 * @param  {Function} callBack  The callback function to be called when the event is posted
 * @return {void}
 */
export function subscribeToEvent(eventName: string, callBack: Function) {
  EVENT_EMITTER.addListener(eventName, callBack)
}

/**
 * Stop listening to an event
 * @param  {string}   eventName The name of th event to stop listening to
 * @param  {Function} callBack  The callback funtion of the event listener
 * @return {void}
 */
export function unsubscribeFromEvent(eventName: string, callBack: Function) {
  EVENT_EMITTER.removeListener(eventName, callBack)
}

/**
 * Return the listener count for an event
 * @param  {string} eventName Name of the event you want the subscribtion count for
 * @return {number}           The count of the subscribtions for the given event name
 */
export function getSubscriptionCount(eventName: string): number {
  return EVENT_EMITTER.listenerCount(eventName) || 0
}

/**
 * Post an event that can be listened to in all tabs running Nordhealth Connect
 * @param  {string} eventName Name of the event to post
 * @param  {string} value     An optional value to be passed to the listener callback
 * @return {void}
 */
export function postGlobalEvent(eventName: string, value?: string) {
  // Chrome, FireFox, Opera
  if (typeof BroadcastChannel !== 'undefined') {
    try {
      const channel = getOrCreateChannel(eventName)
      channel.postMessage(value || '')
    } catch (err) {
      console.warn('Tried to post a message on a closed channel: ', err)
    }
  }

  // IE, Safari, etc.
  else {
    if (value) localStorage.setItem(eventName, value)
    else if (localStorage.getItem(eventName)) localStorage.removeItem(eventName)
    else localStorage.setItem(eventName, eventName)
  }
}

/**
 * Start litening on a global event
 * @param  {string}     eventName Name of the event to listen to
 * @param  {Function}   callBack  The callback function to be called when the event is triggered
 * @return {Disposable}           A disposable for unsubscribing the bound listener
 */
export function subscribeToGlobalEvent(eventName: string, callBack: Function) {
  if (typeof BroadcastChannel !== 'undefined') {
    const channel = getOrCreateChannel(eventName)
    channel.onmessage = (e) => callBack(e.data)
    return new Disposable(() => {
      removeChannel(eventName)
      channel.close()
    })
  } else
    return addListener(window, 'storage', (event) => {
      if (event.key === eventName) callBack(event.value)
    })
}

function getOrCreateChannel(name: string) {
  if (!CHANNELS.has(name)) {
    const channel = new BroadcastChannel(name)
    CHANNELS.set(name, channel)
  }
  return CHANNELS.get(name)
}

function removeChannel(name: string) {
  if (!CHANNELS.has(name)) CHANNELS.delete(name)
}

/**
 * Helper method for binding disposable listeners
 *
 * @method  addListener
 * @param   {Element|Document|Window} el
 * @param   {String}                  eventName
 * @param   {Function}                handler
 * @param   {boolean}                 [pre=false]
 */

export function addListener(
  el: Element | Document | Window,
  eventName: string,
  handler: Function,
  pre: boolean = false
) {
  let callback = handler.bind(el)
  el.addEventListener(eventName, callback, pre)
  return new Disposable(() => el.removeEventListener(eventName, callback, pre))
}
