// @flow
import React, { useState } from 'react'
import Modal from 'react-modal'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'

import BookingHeader, { BookingHeaderDesktop } from './BookingHeader'
import ExitBookingModal from './ExitBookingModal'

import navigator from 'routes/base'

import { resetBooking } from 'actions/onlineBooking'
import { logoutWithoutRedirect } from '../../../actions/user'
import isMobile from 'utils/isMobileHook'

import type { Node } from 'react'

type FullScreenModalProps = {
  children: *,
  setVisible: Function,
  visible: boolean,
  position?: 'absolute' | 'fixed',
  zIndex?: number,
}

export const FullScreenModal = ({
  children,
  setVisible,
  visible,
  zIndex,
  position = 'absolute',
}: FullScreenModalProps): * => {
  const styles = getModalStyles(zIndex)
  return (
    <Modal
      style={{
        content: { ...styles.content, position },
        overlay: styles.overlay,
      }}
      isOpen={visible}
      onClickAway={() => setVisible(false)}
    >
      {children}
    </Modal>
  )
}

const withOnlineBookingModal = (
  WrappedComponent: *,
  hideNavBar: boolean = false
): Function => {
  const modalWrapper = (props): Node => {
    const dispatch = useDispatch()
    const step = useSelector((state) => state.onlineBooking.step)
    const isMobileView: boolean = isMobile(window)
    const [visible, setVisible] = useState(true)
    const [exitModalVisible, setExitModalVisible] = useState<boolean>(false)

    const exitBooking = () => {
      if (step < 8) {
        dispatch(resetBooking(false))
        setExitModalVisible(false)
        setVisible(false)
        navigator.navigate('/')
      } else {
        dispatch(resetBooking(false))
        setExitModalVisible(false)
        setVisible(false)
        // Navigate to my appointments if logged in
        navigator.navigate('/my-appointments/')
      }
    }

    return (
      <FullScreenModal visible={visible} setVisible={setVisible}>
        <section id='online-booking'>
          {!hideNavBar &&
            (isMobileView ? (
              <BookingHeader onClick={() => setExitModalVisible(true)} />
            ) : (
              <BookingHeaderDesktop onClick={() => setExitModalVisible(true)} />
            ))}
          <div className='main-content'>
            <WrappedComponent {...props} />
          </div>
          <ResetBookingButton />
          <LogoutButton />
        </section>
        <ExitBookingModal
          open={exitModalVisible}
          onConfirm={exitBooking}
          onCancel={setExitModalVisible}
          step={step}
        />
      </FullScreenModal>
    )
  }

  return modalWrapper
}

const ResetBookingButton = (): null | React$Element<'button'> => {
  if (__DEV__) {
    const dispatch = useDispatch()
    return (
      <button
        className='floating-action-button primary filled'
        onClick={() => dispatch(resetBooking())}
      >
        <span className='small-text'>Reset</span>
      </button>
    )
  } else return null
}

const LogoutButton = (): null | React$Element<'button'> => {
  if (__DEV__) {
    const dispatch = useDispatch()
    return (
      <button
        className='floating-action-button primary filled'
        style={{'bottom': '8rem'}}
        onClick={() => dispatch(logoutWithoutRedirect())}
      >
        <span className='small-text'>Logout</span>
      </button>
    )
  } else return null
}

const getModalStyles = (zIndex) => {
  const isMobileView: boolean = isMobile(window)
  return {
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // Sometimes we have two modals on top of each other so we need a way to have different z-index
      zIndex: zIndex ? zIndex : 23,
      backgroundColor: isMobileView ? '#fff' : '#f5f5f5',
    },
    content: {
      width: '100%',
      backgroundColor: isMobileView ? '#fff' : '#f5f5f5',
      overflow: 'visible',
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      border: 'none',
    },
  }
}

export default withOnlineBookingModal
