/**
 * @module buttons
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import Button, * as button from 'components/generic/Button'
import { navigate, replace, previous } from 'routes/base'
import history from 'store/history'
import DeleteButton from './DeleteButton'
import DropdownButton from './DropdownButton'
import SelectButtonGroup from './ButtonSelectGroup'

export default Button
export const   BackButton           = button.BackButton
export const   EditButton           = button.EditButton
export const   SearchButton         = button.SearchButton
export const   CancelButton         = button.CancelButton
export const   RemoveButton         = button.RemoveButton
export const   RegularButton        = button.RegularButton
export const   PrimaryButton        = button.PrimaryButton
export const   NavigateButton       = button.NavigateButton
export const   SecondaryButton      = button.SecondaryButton
export const   BackToListButton     = button.BackToListButton
export const   MergedButtonGroup    = button.MergedButtonGroup

export {
  SelectButtonGroup,
  DropdownButton,
  DeleteButton,
}


RegularButton.defaultProps = {
  onClick () { return navigate(this.url) },
  children: 'Add',
}

CancelButton.defaultProps = {
  onClick: urlOrPrevious,
  children: 'Cancel',
}

BackButton.defaultProps = {
  onClick: urlOrPrevious,
  children: 'Back',
}

EditButton.defaultProps = {
  onClick () { return navigate(this.url) },
  children: 'Edit',
}

NavigateButton.defaultProps = {
  onClick () { return navigate(this.url) }
}

BackToListButton.defaultProps = {
  children: 'Back',
}


function urlOrPrevious () {
  if (this.url)
    return replace(this.url)

  if (history.length > 1) {
    return previous()
  }
  else
    return navigate('/threads/')
}
