import React, { Fragment } from 'react'

import routes from 'routes/management'
import { Heading } from 'components/text'


export default function ManagementContainer() {
  return <Fragment>
    <Heading key='title'>
      Management
    </Heading>
    { routes }
  </Fragment>
}
