// @flow
import api from 'api'
import auth, { IDLE_TIMEOUT } from 'api/auth'
import connect from 'bound-connect'
import classNames from 'classnames'
import context from 'context'
import React, { Component, Fragment } from 'react'
import IdleTimer from 'react-idle-timer'

import { logout } from 'actions/user'

import { __ } from 'utils/gettext'

import Alerts from 'views/Alerts'
import { PageFooter } from 'views/Page/Footer'
import PageNavigation from 'views/Page/HeadNavigation'

import CommandMenu from 'components/generic/CommandMenu'
import TimeoutWarning from 'components/TimeoutWarning'
import TwoFactorWrapper from 'components/TwoFactorAuthentication'

import {
  DEFAULT_PRIMARY_COLOR,
  EXCLUDE_ONLINE_BOOKING,
  SUBSCRIPTION_TYPE_PRO,
} from 'constants'

import type { Node } from 'react'
import type { AlertsListType } from 'reducers/core'
import type { StateType } from 'store/initialState'

type PropTypes = {
  alerts?: AlertsListType,
  showCourses?: boolean | null,
  children: Node,
  path?: string,
  url?: string,
  user?: *,
  userPlan?: number,
  userLanguage: string,
  logout?: Function,
  showMyAppointments: boolean,
  tenantColor: string,
}

type StateTypes = {
  rendered: boolean,
  loaded: boolean,
  restricted?: boolean,
  showTwoFactor: boolean,
  menuEntries: Array<*>,
}

type ConnectedActions = {
  logout: Function,
}

const MATCH_DIRTY = /(?:[^\w]+(\w)|[^\w]+$)/g

@connect
export default class Base extends Component<PropTypes, StateTypes> {
  state: StateTypes = {
    rendered: false,
    loaded: false,
    restricted: false,
    showTwoFactor: false,
    menuEntries: [],
  }

  static actions: Object = (dispatch: Function): ConnectedActions => ({
    logout: () => dispatch(logout()),
  })

  static properties: Object = (state: StateType) => {
    const pathname = location.pathname
    return {
      alerts: state.core.alerts,
      url: pathname,
      path: pathname
        .substr(1)
        .replace(MATCH_DIRTY, (_, c) => (c ? '-' + c : '')),
      user: state.user,
      showCourses: state.tenantSettings.show_courses,
      showMyAppointments: state.tenantSettings.show_my_appointments,
      userPlan: state.user.profile.active_subscription?.type
        ? state.user.profile.active_subscription.type
        : null,
      userLanguage: state.user.profile.primary_language,
      tenantColor: state.tenantSettings.color
        ? state.tenantSettings.color
        : DEFAULT_PRIMARY_COLOR,
    }
  }

  componentDidMount() {
    if (
      localStorage.getItem('suggest_2fa') &&
      !localStorage.getItem('2fa_suggestion_skipped')
    ) {
      localStorage.removeItem('suggest_2fa')
      this.setState({ showTwoFactor: true })
    }

    const restricted = this.checkForRouteRestrictions()
    const menuEntries = this.setMenuEntriesToState(restricted)

    this.setState({
      loaded: true,
      rendered: true,
      restricted,
      menuEntries,
    })

    // Change the HTML lang attribute to user language to prevent Google Chrome translation prompts
    const userLang = this.props.userLanguage
    document.documentElement.lang = userLang
    if (this.props.path) {
      const path = this.titleCase(this.props.path).split('-')[0]
      document.title = __(path) + ' - Nordhealth Connect'
    }
  }

  componentDidUpdate(prevProps: *) {
    if (this.props.path && prevProps.path !== this.props.path) {
      const path = this.titleCase(this.props.path).split('-')[0]
      document.title = __(path) + ' - Nordhealth Connect'
    }
  }

  componentWillUnmount() {
    document.title = __('Login') + ' - Nordhealth Connect'
  }

  checkForRouteRestrictions(): boolean {
    if (this.props.userPlan === SUBSCRIPTION_TYPE_PRO) return false
    else return true
  }

  setMenuEntriesToState: Function = (restricted: boolean) => {
    const showCourses: boolean = this.props.showCourses || false
    const showMyAppointments: boolean = this.props.showMyAppointments || false

    let menuEntries = []

    if (context.loggedInAsStaff)
      menuEntries = getMenuEntriesForStaff(showCourses, restricted)
    else
      menuEntries = getMenuEntriesForClient(
        showCourses,
        restricted,
        showMyAppointments
      )

    return menuEntries
  }

  titleCase(string: string): string {
    return string[0].toUpperCase() + string.slice(1).toLowerCase()
  }

  isMainView(path: string): boolean {
    switch (path) {
      case 'threads':
        return true
      case 'scheduling':
        return true
      case 'courses':
        return true
      case 'forms':
        return true
      default:
        return false
    }
  }

  logout: Function = () => {
    if (this.props.logout) this.props.logout()
    auth.handleExpiredToken()
  }

  render(): React$Element<*> | null {
    let currentTab
    if (this.props.path && this.isMainView(this.props.path))
      currentTab = this.titleCase(this.props.path).split('-')[0]
    const isStaff = context.loggedInAsStaff
    const userClass = {
      'user-staff': isStaff,
      'user-client': !isStaff,
    }
    const className: string = classNames(
      'page-contents',
      'content',
      'container',
      userClass
    )

    return this.state.loaded ? (
      <Fragment>
        <PageNavigation url={this.props.url} items={this.state.menuEntries} />

        {this.state.rendered && (
          <main
            id='page-contents'
            className={className}
            data-name={this.props.path}
          >
            <Alerts items={this.props.alerts || new Map()} />
            {currentTab && <h1 className='sr-only'>{__(currentTab)}</h1>}
            <TwoFactorWrapper
              active={this.state.showTwoFactor}
              action='toggle_2fa'
              authenticationRequest={async () =>
                await api.put(`/users/${context.currentUser.id}/toggle-2fa/`, {
                  two_factor_enabled: true,
                })
              }
              enterPhoneNumberProps={{
                cancelText: 'Skip',
              }}
              onCancel={() => {
                localStorage.setItem('2fa_suggestion_skipped', '1')
                this.setState({ showTwoFactor: false })
              }}
              onAuthenticated={() => this.setState({ showTwoFactor: false })}
            >
              {this.props.children}
            </TwoFactorWrapper>
          </main>
        )}

        <PageFooter />

        <IdleTimer onIdle={this.logout} timeout={IDLE_TIMEOUT} />

        <CommandMenu />

        <TimeoutWarning />
      </Fragment>
    ) : null
  }
}

const baseMenuEntries = [
  {
    label: 'Threads',
    action: '/threads',
  },

  {
    label: 'Forms',
    action: '/forms',
  },

  {
    label: 'Documents',
    action: '/documents',
  },

  {
    label: 'My profile',
    action: '/user',
    collapsed: 'mobile',
  },

  {
    label: 'Logout',
    action: auth.clear,
    collapsed: 'mobile',
  },
]

function getMenuEntriesForClient(
  showCourses: boolean | null,
  restricted: boolean,
  showMyAppointments: boolean
) {
  const items = baseMenuEntries.slice(0)

  if (showMyAppointments && !EXCLUDE_ONLINE_BOOKING) {
    items.splice(0, 0, {
      label: 'Appointments',
      action: '/my-appointments/',
    })
  }

  const timelineIndex = showMyAppointments ? 2 : 1
  items.splice(timelineIndex, 0, {
    label: 'Timeline',
    action: '/scheduling/',
    restricted: false,
  })

  const coursesIndex = showMyAppointments ? 4 : 3
  if (showCourses) {
    items.splice(coursesIndex, 0, {
      label: 'Courses',
      action: '/courses',
    })
  }

  return items
}

function getMenuEntriesForStaff(showCourses, restricted) {
  const items = baseMenuEntries.slice(0)

  items.splice(1, 0, {
    label: 'Calendar',
    action: '/scheduling/',
    restricted: restricted,
    allowedPlans: [SUBSCRIPTION_TYPE_PRO],
  })

  if (showCourses) {
    items.splice(3, 0, {
      label: 'Courses',
      action: '/courses',
    })
  }

  const managementIndex = showCourses ? 5 : 4
  items.splice(managementIndex, 0, {
    label: 'Management',
    action: '/management/',
    collapsed: 'mobile',
  })

  return items
}
