// @flow

import { Record } from 'immutable'

import { ELEMENT_MODEL_NAMES } from '../fields'

import type { RecordFactory, RecordInstance, RecordOf } from 'immutable'


// Type definitions

export type BlockKindType = 'radio' | 'checkbox' | 'text' | 'number' | null

export type BlockModelType = 'Field' | 'Subtitle' | 'HelpText' | 'Indicator' | null

export type BlockType = {|
  id: string | number | null,
  indicator_identifier: string | null,
  description: string,
  model: BlockModelType,
  type: BlockKindType,
  text: string,
  name?: string,
  decimals: number | null,
  choices: Array<ChoiceType>,
  options: { [key: string]: any },
  required: boolean,
  instruction_text: string,
  expression: ExpressionType,
|}

export type BlockRecordType = RecordOf<BlockType>
export type BlockInstanceType = RecordInstance<BlockType>
export type ExpressionType = {
  value: string,
  displayValue: string,
} | string | null
export type ChoiceType = {
  text?: string,
  value?: string,
  score?: number
}

// END Type definitions


export const blockDefaults: BlockType = {
  id:                   null,
  indicator_identifier: null,
  instruction_text:     '',
  description:          '',
  text:                 '',
  type:                 null,
  model:                null,
  expression:           null,
  required:             false,
  choices:              [],
  options:              {},
  decimals:             null,
}


const Block: RecordFactory<BlockType> = new Record(blockDefaults)


export function HeadingBlock (): BlockInstanceType {
  return new Block({
    id:    generateRandomId(),
    model: ELEMENT_MODEL_NAMES.HEADING,
  })
}


export function TextBlock (): BlockInstanceType {
  return new Block({
    id:    generateRandomId(),
    model: ELEMENT_MODEL_NAMES.TEXT,
  })
}


export function IndicatorBlock (): BlockInstanceType {
  return new Block({
    id:    generateRandomId(),
    model: ELEMENT_MODEL_NAMES.COMPUTED,
  })
}


export function FieldBlock (type: BlockKindType, index: number): BlockInstanceType {
  return new Block({
    type,
    model:                ELEMENT_MODEL_NAMES.FIELD,
    id:                   generateRandomId(),
    indicator_identifier: generateIdentifier(index),
  })
}


export default Block


const generateIdentifier = (index: number): string =>
  'F' + index.toString()


const generateRandomId = (): string =>
  (Math.random() * 100000).toString(32)
