
import React from 'react'
import Field from './Field'
import Input from './base'


export default class InputField extends Field<string, { max_length: number }> {

  static defaultInitialValue = ''

  attributes = {}

  renderFormControl () {
    return <Input
      id={this.name}
      name={ this.name }
      type={ this.type }
      value={ this.value }
      onChange={ this.onChange }
      maxLength={ this.props.max_length }
      attributes={ this.attributes }
      disabled={ this.disabled }
    />
  }
}
