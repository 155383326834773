/**
 * @flow
 * @class Block
 */
import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import self from 'autobind-decorator'
import { SlideDown } from 'react-slidedown'

import { Badge } from 'components/generic'
import Icon from 'components/icons'
import * as EventHandler from 'utils/events'

type FilterType = {
  displayName: string,
  key?: string,
  name?: string,
}

type FilterkProps = {
  children: React$Element<*> | Array<React$Element<*>>,
  filters: Array<FilterType>,
  onRemove?: Function,
  className?: string,
}

type FilterState = {
  open: boolean
}

export const ON_REMOVE_FILTERS_EVENT = 'onRemoveFilters'


const host: {
  node: HTMLElement | null,
} = {
  node: null,
}

const applyReference = ref =>
  host.node = ref


export default class Filters extends Component<FilterkProps, FilterState> {

  connected: boolean = false
  element: HTMLElement = document.createElement('div')
  state: FilterState = {
    open: false
  }

  @self
  toggle () {
    if (host.node && !this.connected) {
      host.node.appendChild(this.element)
      this.connected = true
    }
    this.setState({ open: !this.state.open })
  }

  @self
  onRemove (filter: FilterType) {
    EventHandler.postEvent(ON_REMOVE_FILTERS_EVENT, null)
    if (this.props.onRemove)
      this.props.onRemove(filter)
  }

  render (): React$Element<*> {
    return <div className='list-filters'>
      <div className={`filters-container${this.state.open ? ' open' : ''}`}>
        <div className='filters'>{ this.props.filters.map(this.renderFilter) }</div>
        <div className='filter-button focusable' tabIndex={ 0 } onClick={ this.toggle } onKeyPress={ this.toggle }>
          <Icon.Filters size={ 12 }/>
        </div>
      </div>
      { this.createPortal() }
    </div>
  }

  @self
  renderFilter (filter: FilterType, key: number): React$Element<*> | null {
    if (!filter.displayName) return null
    let displayName = filter.displayName
    if (filter.name)
      displayName = filter.name + ': ' + displayName
    return <Badge regular key={ key } onRemove={ () => this.onRemove(filter) }>{ displayName }</Badge>
  }

  @self
  createPortal (): React$Element<*> {
    return createPortal(this.renderFilterFields(), this.element)
  }

  @self
  renderFilterFields (): React$Element<*> {
    const className = this.props.className
      ? `filters-block ${this.props.className}`
      : 'filters-block'
    return <SlideDown>
      { this.state.open &&
        <div className={ className }>{ this.props.children }</div> }
    </SlideDown>
  }
}


export class FilterFieldContainer extends Component<*> {

  componentWillUnmount () {
    host.node = null
  }

  render (): React$Element<*> {
    return <section
      ref={ applyReference }
      className='list-filter-fields'
    />
  }

}
