/**
 * @flow
 * @class SingleChoiceField
 */

import React from 'react'
import BaseInputComponent  from './base'
import { Radio } from 'react-toggle-components'

import { clean } from './MultipleChoiceField'
import ChoicePreview from '../components/ChoicePreview'

type ValueType = number | null

type OptionsType = {}

type OptionType = {
  value: number,
  label: string,
  score: number,
}

type SingleChoiceFieldProps = {
  name: string,
  value?: number,
  choices?: Array<*>,
  disabled?: boolean,
  onChange?: Function,
}

type StateType = {
  value: number,
  choices: Array<OptionType>,
  disabled: boolean,
}


export default class SingleChoiceField extends BaseInputComponent<ValueType, OptionsType> {

  static defaultValue = null

  state: StateType

  constructor (props: Props) {
    super(props)
    this.state = {
      ...this.state,
      choices: [],
    }
  }

  render () {
    return <dl className='options single'>
      { this.state.choices.map(choice =>
        <label key={ choice.value } className='option'>
          <dd>
            <Radio
              name={ this.props.name }
              checked={ choice.value === this.state.value }
              disabled={ this.state.disabled ? 'disabled' : false }
              onChange={ () => this.update(choice.value) }
            />
          </dd>
          <dt>
            <ChoicePreview choice={ choice }></ChoicePreview>
          </dt>
        </label>
      )}
    </dl>
  }

  updateState (props: SingleChoiceFieldProps) {
    let updates = {}

    if ('value' in props)
      updates.value = props.value
    if ('choices' in props)
      updates.choices = clean(props.choices)
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false

    this.setState(updates)
  }
}
