import React from 'react'
import { Token } from 'components/generic'

export default (props) => <div className='choice-preview-container'>
  <span className='value'>{ props.choice.label }</span>
  { hasScore(props.choice.score) && <Token text={ props.choice.score } info tiny /> }
</div>

function hasScore (score: number | null): boolean {
  if (is.number(score))
    return true
  return false
}
