import React from 'react'
import Symbol from 'es6-symbol'
import { Loader } from 'components/generic'
import { getFieldComponent } from './resolve'

const components = Symbol('Rendered components')

const PendingField = Object.assign(() =>
  <Loader />, { fields: getProxyFor({}) })


export default function getProxyFor (data) {
  if (!data[components])
    data[components] = new Map()

  return new Proxy(data, {
    get (src, field_id) {

      if (src[components].has(field_id))
        return src[components].get(field_id)

      if (field_id in src) {
        src[components].set(field_id, getFieldComponent(field_id, src[field_id]))
        return src[components].get(field_id)
      }

      return PendingField
    }
  })
}
