
import React, { Fragment } from 'react'

import routes from 'routes/public/users'


const UsersContainer = () =>

  <Fragment>
    { routes }
  </Fragment>


export default UsersContainer
