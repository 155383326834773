/**
 * @module ContentTooltip
 * @flow
 * @summary An easy-to-use Popper tooltip wrapper for displaying multiple types of content as children.
 * @description
 * Props: reference (mandatory): reference of the parent element
 *        placement (optional, default: 'bottom'): Placement of the Tooltip. See options below in placement typing.
 */
import React, { PureComponent } from 'react'
import { createPortal } from 'react-dom'
import { Manager, Popper } from 'react-popper'
import self from 'autobind-decorator'

export type TooltipPropTypes = {
  children: React$Element<*>,
  containerId?: string,
  reference?: HTMLElement,
  placement?: 'auto' | 'auto-start' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' |
              'right-end' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left-start' | 'left' | 'left-end'
}


export default class ContentTooltip extends PureComponent <TooltipPropTypes, *> {

  popperModifiers: Object = {
    preventOverflow: { enabled: true },
    hide: { enabled: false },
  }

  get containerElement (): HTMLElement {
    const element = document.getElementById(this.props.containerId || '')
    return element || document.getElementsByTagName('BODY')[0]
  }

  @self
  renderTooltip ({ ref, style, placement, scheduleUpdate, arrowProps }: *): Function | React$Element<*> {
    if (scheduleUpdate)
      scheduleUpdate()

    if (style.transform && style.transform.startsWith('translate3d(0px'))
      return null

    return <div
      ref={ ref }
      style={{ ...style }}
      data-placement={ placement }>
      { this.props.children }
      <div ref={arrowProps.ref} style={arrowProps.style} />
    </div>
  }

  renderPopper (): React$Element<*> {
    return <Popper
      referenceElement={ this.props.reference }
      placement={ this.props.placement ? this.props.placement : 'bottom' }
      modifiers={ this.popperModifiers }>
      { this.renderTooltip }
    </Popper>
  }

  renderPortal (): Function {
    if (this.props.containerId)
      return createPortal(this.renderPopper(), this.containerElement )
    else
      return this.renderPopper()
  }

  render (): React$Element<*> {
    return <Manager positionFixed>
      { this.renderPortal() }
    </Manager>
  }
}