// @flow
import type { Action } from '.'
import announcements from 'api/announcements'
import navigator from 'routes/base'
import {
  ADD_ALERT,
  REMOVE_ALERT,
  CLEAR_ALERTS,
  CLEAR_ANNOUNCEMENTS,
  UPDATE_SHOW_CONTRACT,
  CLEAR_ERROR_ALERTS,
  PUSH_ROUTE,
  POP_ROUTE,
  INIT_WOOTRIC,
  UPDATE_NOTIFICATIONS
} from 'actions/core'

type GlobalStateEnum = 'primary' | 'info' | 'success' | 'warning' | 'error'

export type IsAuthenticatedType = true | false | null

export type Alert = {
  id: any,
  content: Node | React$Element<*> | string,
  style?: GlobalStateEnum,
  deleteAfter: number,
  isAnnouncement: boolean,
  title?: string,
}

export type Location = {
  pathname: string,
  search: string,
  hash: string,
  key: string,
  state?: {},
}

export type AlertsListType = Map<string, Alert>

export type CoreState = {
  alerts: AlertsListType,
  route: Array<Location>,
  showContract: boolean,
  showSurvey: boolean,
  notifications: Array<*>,
}

let alertId = 0

// eslint-disable-next-line complexity
export default function coreReducer (state: CoreState, action: Action) {

  const update = (updates: Object) =>
    Object.assign({ updated: new Date() }, state, updates)

  switch (action.type) {

    case ADD_ALERT:
      const alert = action.payload.isAnnouncement
        ? { ...action.payload, id: action.payload.content.id, content: action.payload.content.text}
        : { ...action.payload, id: alertId++ }
      const newAlerts = new Map(state.alerts)
      const newKey = alert.isAnnouncement ? `announcements/${alert.id}` : navigator.url
      newAlerts.set(newKey, alert)
      return update({ alerts: newAlerts })

    case REMOVE_ALERT:
      const alerts = new Map(state.alerts)
      let key: string = ''
      alerts.forEach((value, itemKey) => {
        if (value.id === action.payload)
          key = itemKey
      })
      announcements.dismiss(alerts.get(key))
      alerts.delete(key)
      return update({ alerts })

    case CLEAR_ALERTS:
      const currentAlerts = new Map(state.alerts)
      currentAlerts.forEach((alert, key) => {
        if (!alert.isAnnouncement)
          alert.deleteAfter = alert.deleteAfter - 1
        currentAlerts.set(key, alert)
        if (alert.deleteAfter === 0)
          currentAlerts.delete(key)
      })

      return update({ alerts: currentAlerts })

    case CLEAR_ERROR_ALERTS:
      const alertsMap = new Map(state.alerts)
      if (alertsMap.size === 0)
        return update({ alerts: new Map() })
      alertsMap.forEach((value, itemKey) => {
        if (value.style === 'error')
          alertsMap.delete(itemKey)
      })
      return update({ alerts: alertsMap })

    case CLEAR_ANNOUNCEMENTS:
      const announcementsMap = new Map(state.alerts)
      if (announcementsMap.size === 0)
        return update({ alerts: new Map() })
      announcementsMap.forEach((value, itemKey) => {
        if (itemKey.includes('announcements/'))
          announcementsMap.delete(itemKey)
      })
      return update({ alerts: announcementsMap })

    case PUSH_ROUTE:
      return update({ route: [ ...state.route, action.payload ]})

    case POP_ROUTE:
      return update({ route: [ ...state.route.slice(0, state.route.length - 1) ]})

    case UPDATE_SHOW_CONTRACT:
      return update({ showContract: action.payload })

    case INIT_WOOTRIC:
      return update(action.payload)

    case UPDATE_NOTIFICATIONS:
      return update({ ...state, notifications: action.payload})

    default:
      return state || {}
  }
}
