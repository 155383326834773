// @flow

import self from 'autobind-decorator'
import connect from 'bound-connect'
import React, { Component, PureComponent } from 'react'
import { CompositeDisposable, Disposable } from 'event-kit'
import { Switch, Route, withRouter } from 'react-router-dom'
import api from 'api'
import context from 'context'

import ToS from 'views/ToS/Accept'
import Contract from 'views/Contract/Accept'
import Base from 'views/Base'
import User from 'views/User'
import Login from 'views/Login'
import Forms from 'views/Forms'
import Messages from 'views/Messages'
import Scheduling from 'views/Scheduling'
import Management from 'views/Management'
import RegisterClientForm from 'views/Register/RegisterClientForm'
import NetworkError from 'views/Error/NetworkError'
import Safe from 'components/generic/ErrorView'
import Documents from 'views/Documents'
import OnlineBooking from 'views/OnlineBooking'

import PublicBase from 'views/Public/Base'
import PublicForms from 'views/Public/Forms'
import PublicCourses from 'views/Public/Courses'
import PublicUsers from 'views/Public/Users'
import PublicExercises from 'views/Public/Exercises'
import PublicTags from 'views/Public/Tags'
import PublicOthers from 'views/Public/Others'
import PublicAnnouncements from 'views/Public/Announcements'
import PublicPackages from 'views/Public/Packages'
import PublicLibrary from 'views/Public/Library'
import Tenants from 'views/Public/Tenants/'
import Contracts from 'views/Public/Contracts/'
import RestrictedTenant from 'views/RestrictedTenantView'
import MyAppointments from 'views/MyAppointments'

import { pushRoute, popRoute, clearAlerts, addAlert } from '../actions/core'
import { setCachedData } from '../actions/onlineBooking'
import {
  RouteNotFound,
  CapturePageNotFound,
  ApiUrlNotFound,
} from 'views/Error/PageNotFound'
import AuthorizedRoute from './base/AuthorizedRoute'
import { resolveSubdomain } from 'utils/resolve'
import { PUBLIC_TENANT } from '../constants'
import {
  DEFAULT_REDIRECT_URL,
  DEFAULT_PUBLIC_REDIRECT_URL,
  GROUP_SUPERUSER,
  SUBSCRIPTION_TYPE_PRO,
  GROUP_MANAGEMENT,
  DEFAULT_RESTRICTED_TENANT_URL,
  EXCLUDE_ONLINE_BOOKING,
  SUBSCRIPTION_TYPE_ONLINE_BOOKING,
} from 'constants'
import { ENDPOINT_URLS } from '../views/OnlineBooking'
import ResetPassWord from './components/ResetPassWord'
import ShowCourses from './components/ShowCourses'
import load from 'components/LoadableComponent'

import endpoints from './endpoints/'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'

const ToS_URL = '/terms-of-service/'
const CONTRACT_URL = '/contract/'
const PASSWORD_RESET = '/password-reset/invalid-link/'

const BOOKING_TIMEOUT_ALERT_TEXT =
  'You have exceeded timelimit and will have to go throught the process again'

const InvalidPasswordResetForm = load(() =>
  import('../views/Login/InvalidPasswordResetLink')
)

type PropTypes = RouteProps & {
  children: Node,
  loginRequired: boolean,
  popRoute: Function,
  pushRoute: Function,
  clearAlerts: Function,
  showTerms: boolean,
  showContract?: boolean,
  addAlert: Function,
  setCachedData: Function,
  termsAccepted: string | null,
}

type RouteStateType = {
  offline: boolean,
  apiUrlNotFound: boolean,
}

function safe(Entity) {
  class SafeComponent extends Component<*> {
    static displayName = `Safe ${Entity.displayName || Entity.name}`

    render() {
      return (
        <Safe>
          <Entity {...this.props} />
        </Safe>
      )
    }
  }

  return SafeComponent
}

// Client users should always have access to the scheduling page. Staff users need PRO plan to access it.
function schedulingRouteByUserType() {
  const isStaff = context.loggedInAsStaff
  if (isStaff)
    return (
      <AuthorizedRoute
        path='/scheduling/'
        component={safe(Scheduling)}
        allowedPlans={[SUBSCRIPTION_TYPE_PRO]}
      />
    )
  else return <Route path='/scheduling/' component={safe(Scheduling)} />
}

export const descriptors = {
  resetPassword: (uid: string = '', token: string): string =>
    `/set-password/${uid}/${token}/`,
}

export const isPublicApp = (): boolean => resolveSubdomain() === PUBLIC_TENANT

export const routes = (
  <CapturePageNotFound>
    <Switch>
      <Base>
        <Switch>
          {endpoints.postLogin.map((endpoint) => endpoint)}
          <Route path='/user/' component={safe(User)} />
          <Route path='/forms/' component={safe(Forms)} />
          {/* TODO: for testing purposes threads are substituted with OnlineBooking Component */}
          {!EXCLUDE_ONLINE_BOOKING && (
            <Route path='/online-booking/' component={safe(OnlineBooking)} />
          )}
          {!EXCLUDE_ONLINE_BOOKING && (
            <Route path='/my-appointments/' component={safe(MyAppointments)} />
          )}
          <Route path='/threads/' component={safe(Messages)} />
          {/* <Route path='/threads/'        component={ safe(Messages) } /> */}
          {schedulingRouteByUserType()}
          <Route path='/courses/' component={safe(ShowCourses)} />
          <Route path='/documents/' component={safe(Documents)} />
          <Route path={ToS_URL} component={safe(ToS)} />
          <Route path={PASSWORD_RESET} component={InvalidPasswordResetForm} />
          <AuthorizedRoute path='/management/' component={safe(Management)} />
          <Route
            path='/password-reset/invalid-link/'
            component={InvalidPasswordResetForm}
          />
          <Route
            path={descriptors.resetPassword(':uid', ':token')}
            component={safe(ResetPassWord)}
          />
          <AuthorizedRoute
            path={CONTRACT_URL}
            component={safe(Contract)}
            allowedGroups={GROUP_SUPERUSER}
          />
          <Route path='/register' component={safe(RegisterClientForm)} />
          <RouteNotFound />
        </Switch>
      </Base>
    </Switch>
  </CapturePageNotFound>
)

export const restrictedTenantRoutes = (
  <CapturePageNotFound>
    <Switch>
      <Base>
        <Switch>
          <Route path='/management/' component={safe(RestrictedTenant)} />
          <Route path='/user/' component={safe(User)} />
          <Route path={ToS_URL} component={safe(ToS)} />
          <AuthorizedRoute
            path={CONTRACT_URL}
            component={safe(Contract)}
            allowedGroups={GROUP_SUPERUSER}
          />
          <RouteNotFound />
        </Switch>
      </Base>
    </Switch>
  </CapturePageNotFound>
)

export const publicRoutes = (
  <CapturePageNotFound>
    <Switch>
      <PublicBase>
        <Switch>
          <Route path='/exercises/' component={safe(PublicExercises)} />
          <Route path='/contracts/' component={safe(Contracts)} />
          <Route path='/tenants/' component={safe(Tenants)} />
          <Route path='/forms/' component={safe(PublicForms)} />
          <Route path='/courses/' component={safe(PublicCourses)} />
          <Route path='/users/' component={safe(PublicUsers)} />
          <Route path='/tags/' component={safe(PublicTags)} />
          <Route path='/others/' component={safe(PublicOthers)} />
          <Route path='/announcements/' component={safe(PublicAnnouncements)} />
          <Route path='/packages/' component={safe(PublicPackages)} />
          <Route path='/library/' component={safe(PublicLibrary)} />
          <RouteNotFound page='BASE ROUTES' />
        </Switch>
      </PublicBase>
    </Switch>
  </CapturePageNotFound>
)

@withRouter
@connect
export default class BaseRoutes extends PureComponent<
  PropTypes,
  RouteStateType
> {
  subscriptions: CompositeDisposable = new CompositeDisposable()

  state = {
    offline: false,
    apiUrlNotFound: false,
  }

  _cacheCheckCalled: boolean = false

  static properties(state: StateType) {
    const route = state.core.route
    const userGroup = state.user.group ? state.user.group.name : null
    return {
      route,
      authenticated: state.user.get('authenticated'),
      loginRequired: state.user.get('loginRequired'),
      currentLocation: route && route.length && route[route.length - 1],
      previousLocation: route && route.length > 1 && route[route.length - 2],
      showTerms: state.user.profile.show_terms,
      showContract: state.core.showContract,
      userGroup,
      termsAccepted: state.user.profile.terms_accepted,
      subscriptionType: state.user.profile.active_subscription?.type,
    }
  }

  static actions(dispatch: Function) {
    return {
      popRoute: () => dispatch(popRoute()),
      pushRoute: (route: string) => dispatch(pushRoute(route)),
      clearAlerts: () => dispatch(clearAlerts()),
      addAlert: (style: string, content: string) =>
        dispatch(addAlert(style, content)),
      setCachedData: (data: Object) => dispatch(setCachedData(data)),
    }
  }

  componentDidMount() {
    const dispose = this.props.history.listen(this.handleRouteUpdate)
    const subscription = new Disposable(dispose)

    const apiExceptSubscription = api.onError((err) => {
      __DEV__ && console.warn('api err', err) // eslint-disable-line no-console
      this.setState({ offline: true })
    })

    const apiClientErrorSubscription = api.onClientError((err) => {
      __DEV__ && console.warn('api err', err) // eslint-disable-line no-console
      this.setState({ apiUrlNotFound: true })
    })

    this.subscriptions.add(
      subscription,
      apiExceptSubscription,
      apiClientErrorSubscription
    )
  }

  componentWillUnmount() {
    this.subscriptions.dispose()
  }

  @self
  handleAlerts() {
    this.props.clearAlerts()
  }

  @self
  //eslint-disable-next-line complexity
  handleRouteUpdate(
    location: { key: string, pathname: string },
    action: 'PUSH' | 'POP' | 'REPLACE'
  ) {
    // clear alerts on route update
    if (action !== 'REPLACE') this.handleAlerts()

    // handle showing and hiding zendesk
    handleZendesk(location)

    // React-router uses PUSH action only when the user opens a location he/she has not opened before.
    // Otherwise the received action equals to POP,
    // e.g. one navigating forward by pressing the browser's forward button yields a location change with action POP.
    // This behavior in location changes is normalized so that
    //  - PUSH means forward; and
    //  - POP means backward
    if (this.props.previousLocation.key !== location.key && action === 'POP')
      action = 'PUSH'

    if (action === 'PUSH') this.props.pushRoute(location)
    else if (action === 'POP') this.props.popRoute(location)
    else if (action !== 'REPLACE')
      throw new TypeError(
        `Invalid action \`${action}\` provided to the handleRouteUpdate function.`
      )
  }

  render() {
    if (isPublicApp()) return this.renderPublicApp()
    return this.renderBaseApp()
  }

  renderBaseApp() {
    // Allow to use online booking without logging in
    if (
      !EXCLUDE_ONLINE_BOOKING &&
      this.props.history.location.pathname.startsWith('/online-booking')
    )
      return <Route path='/online-booking' component={OnlineBooking} />

    if (this.state.offline) return <Route path='/' component={NetworkError} />

    if (this.state.apiUrlNotFound)
      return <Route path='/' component={ApiUrlNotFound} />

    if (this.props.loginRequired) return <Route path='/' component={Login} />

    this.handleRedirect()
    // as Managers and user with subscription type Online Booking
    // have limited access - we want to user "restricted version" of
    // frontend
    return this.props.userGroup === GROUP_MANAGEMENT ||
      this.props.subscriptionType === SUBSCRIPTION_TYPE_ONLINE_BOOKING
      ? restrictedTenantRoutes
      : routes
  }

  // eslint-disable-next-line complexity
  async handleRedirect() {
    // If a new contract needs to be accepted redirect to CONTRACT_URL
    if (
      this.props.showContract &&
      this.props.history.location.pathname !== CONTRACT_URL
    )
      this.props.history.replace(CONTRACT_URL)

    // If the terms need to be accepted always redirect to ToS_URL
    if (
      !this.props.showContract &&
      !this.props.termsAccepted &&
      this.props.history.location.pathname !== ToS_URL
    )
      this.props.history.replace(ToS_URL)
    // If user is in group Management -> redirect him to management page
    else if (
      (this.props.userGroup === GROUP_MANAGEMENT ||
        this.props.subscriptionType === SUBSCRIPTION_TYPE_ONLINE_BOOKING) &&
      this.props.location.pathname === '/'
    )
      this.props.history.replace(DEFAULT_RESTRICTED_TENANT_URL)
    else if (this.props.location.pathname === '/')
      this.props.history.replace(DEFAULT_REDIRECT_URL)
  }

  renderPublicApp() {
    if (this.state.offline) return <Route path='/' component={NetworkError} />

    if (this.props.loginRequired) return <Route path='/' component={Login} />

    // Set the default redirect uri
    if (this.props.location.pathname === '/')
      this.props.history.replace(DEFAULT_PUBLIC_REDIRECT_URL)

    return publicRoutes
  }
}

function handleZendesk(location: { key: string, pathname: string }) {
  if (window.zE && location.pathname) {
    if (location.pathname.startsWith('/management/')) {
      window.zE('webWidget', 'show')
    } else {
      window.zE('webWidget', 'hide')
    }
  }
}
