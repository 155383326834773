/**
 * @module UserSelectionFieldView
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import { deselectClient, selectClient } from 'actions/client'
import api from 'api'
import self from 'autobind-decorator'
import connect from 'bound-connect'
import { Loader } from 'components/generic'
import Text from 'components/text'
import { EVENT_TYPES, GROUP_LEVEL_ADMIN } from 'constants'
import context from 'context'
import User from 'models/User'
import React, { createRef, PureComponent } from 'react'
import { SelectField } from 'react-drf/fields'
import { components } from 'react-select'
import navigator from 'routes/base'
import { descriptors } from 'routes/management'
import * as EventHandler from 'utils/events'
import { CancelablePromise } from 'utils/promise'

import type UserType from 'models/User'

type OptionType = {|
  value: number,
  label: string,
  type?: string,
|}

type Props = {
  updateSelectedClient?: Function,
  value?: OptionType | null,
  placeholder?: string | Object,
  onChange?: Function,
  initial?: Object,
  client?: OptionType,
  formField?: boolean,
  choices?: Array<OptionType>,
  filter?: Function,
  userType?: string,
  valueAsUser?: boolean,
  ignoreMinimumQuery?: boolean,
  className?: string,
  includeUserGroups?: boolean,
  onUserSelect?: Function,
  name?: string,
}

type StateType = {
  value?: ?OptionType,
  noResultsText: React$Element<*>,
  options: Array<OptionType>,
  loading: boolean,
  results: Array<UserType>,
}

type UserSelectionType = {|
  current: UserSelectionFieldView | null,
|}

const MINIMUM_QUERY_LENGTH = 3
const QUERY_INTERVAL = 300
const DEFAULT_NO_RESULTS_TEXT = <Text>No results</Text>

const userSelectionInput: UserSelectionType = {
  current: null,
}

@connect
export default class UserSelectionFieldView extends PureComponent<
  Props,
  StateType
> {
  currentQuery: string = ''
  _isMounted: boolean
  _queryUsersPromise: Promise<*> | null
  _queryUserGroupsPromise: Promise<*> | null
  _cancelableUserQueryPromise: CancelablePromise<*>
  _cancelableUserGroupQueryPromise: CancelablePromise<*>
  field: Function = createRef()

  constructor(props: *) {
    super(props)
    this._isMounted = false
  }

  state: StateType = {
    value: null,
    noResultsText: DEFAULT_NO_RESULTS_TEXT,
    loading: false,
    options: [],
    results: [],
  }

  static actions: Function = (dispatch: Function) => ({
    updateSelectedClient: (client: UserType | null) => {
      if (!client) dispatch(deselectClient())
      else dispatch(selectClient(client))
      EventHandler.postEvent(EVENT_TYPES.LIST_UPDATE, client)
    },
  })

  static properties({ client }: *): Object {
    return {
      client,
    }
  }

  async componentDidMount() {
    this._isMounted = true
    this.setNoResultsText('')
    if (!this.props.formField) {
      userSelectionInput.current = this
    } else if (this.props.initial && this.props.initial.id) {
      const user = new User(this.props.initial)
      const value = user.id
      const label = getDisplayName(user)
      this.setState({ value: { value, label } })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    if (!this.props.formField) userSelectionInput.current = null
    if (this._cancelableUserQueryPromise)
      this._cancelableUserQueryPromise.cancel()
    if (this._cancelableUserGroupQueryPromise)
      this._cancelableUserGroupQueryPromise.cancel()
  }

  get placeholder(): * {
    return this.props.placeholder || <Text>Select client</Text>
  }

  @self
  onChange(value: OptionType) {
    if (this.props.formField) {
      this.setState({ value })
      if (this.props.onChange)
        if (this.props.valueAsUser) {
          const user = this.state.results.find(
            (user) => user.id === value.value
          )
          this.props.onChange(user || null)
        } else this.props.onChange(value ? value.value : null)
    } else {
      if (!value) {
        this.props.updateSelectedClient(undefined)
      } else {
        const user = this.state.results.find((user) => user.id === value.value)
        if (this.props.onUserSelect) this.props.onUserSelect(user, value.type)
        else this.props.updateSelectedClient(user)
      }
    }
  }

  @self
  filter() {
    if (this.props.filter) return this.props.filter
    return () => true
  }

  get value(): * {
    return this.props.formField
      ? this.state.value || null
      : mapClientToOption(this.props.client)
  }

  @self
  query(query: string) {
    if (this.currentQuery === query && this._isMounted) {
      let users = []
      let groups = []
      this._queryUsersPromise = queryUsers(
        query,
        this.props.userType,
        this.props.ignoreMinimumQuery
      )
      if (this._queryUsersPromise) {
        this._cancelableUserQueryPromise = new CancelablePromise(
          this._queryUsersPromise
        )
        this._cancelableUserQueryPromise.then((clients) => {
          if (clients) users = clients
          this._queryUserGroupsPromise = queryUserGroups(
            query,
            this.props.ignoreMinimumQuery,
            this.props.includeUserGroups,
            isCurrentUserAdmin(context.currentUserGroup)
          )
          if (this._queryUserGroupsPromise) {
            this._cancelableUserGroupQueryPromise = new CancelablePromise(
              this._queryUserGroupsPromise
            )
            this._cancelableUserGroupQueryPromise.then((userGroups) => {
              if (userGroups) groups = userGroups
              this.handleQuery(query, users, groups)
            })
          }
        })
      }
    }
  }

  handleQuery: Function = (query, users, groups): Function => {
    const userOptions = users.map(mapClientToOption)
    const userGroupOptions = groups.map(mapClientGroupToOption)
    const options = userOptions.length > 0 ? userOptions : userGroupOptions
    const results = users.concat(groups)
    queryIsValid(query, this.props.ignoreMinimumQuery)
      ? this.setState({
          options,
          noResultsText: DEFAULT_NO_RESULTS_TEXT,
          results,
        })
      : this.setState({ options, results })
  }

  @self
  onKeyDown(query: string) {
    this.currentQuery = query
    this.setNoResultsText(query)
    setTimeout(() => this.query(query), QUERY_INTERVAL)
  }

  // Customly defined component that will make it possible to navigate to client's details from the navbar
  @self
  SingleValue({ children, ...props }: any, any: any): any {
    return (
      <span onClick={this.redirectToClientManagement}>
        <components.SingleValue {...props}>{children}</components.SingleValue>
      </span>
    )
  }

  @self
  redirectToClientManagement() {
    const { value } = this.value
    const userManagementUrl = descriptors.user(value)
    navigator.navigate(
      userManagementUrl,
      {},
      {
        navigateToDetails: true,
        detailsUrl: userManagementUrl,
        type: 'clients',
      }
    )
  }

  get selectProps(): Object {
    return {
      components: {
        SingleValue: this.SingleValue,
      },
    }
  }

  render(): React$Element<*> {
    const authorizedToUsers = context.currentUser.profile.authorized_to_users
    let choices
    if (
      this.currentQuery ||
      (authorizedToUsers && authorizedToUsers.length > 0)
    )
      choices = this.state.options
    else choices = this.props.choices ? this.props.choices : []

    const className = this.props.className
      ? this.props.className
      : 'user-select'

    return (
      <div className={className}>
        <SelectField
          value={this.value}
          ref={this.field}
          filter={this.filter()}
          options={choices}
          placeholder={this.placeholder}
          onDidUpdate={this.onChange}
          noResultsText={this.state.noResultsText || DEFAULT_NO_RESULTS_TEXT}
          onKeyDown={this.onKeyDown}
          cache={false}
          minWidth='23rem'
          selectProps={this.selectProps}
          name={this.props.name}
        />
      </div>
    )
  }

  @self
  setNoResultsText(query: string) {
    let noResultsText = <Loader />
    if (!queryIsValid(query, this.props.ignoreMinimumQuery)) {
      const length = query
        ? MINIMUM_QUERY_LENGTH - query.length
        : MINIMUM_QUERY_LENGTH
      noResultsText = (
        <Text params={{ length }}>{'Type ${length} more character(s)'}</Text>
      )
    }
    if (this.props.ignoreMinimumQuery) this.query(query)
    else this.setState({ noResultsText })
  }
}

export function focusUserSelectionField() {
  if (userSelectionInput.current)
    userSelectionInput.current.field.current.focus()
}

async function queryUsers(
  query,
  userType,
  ignoreMinQuery
): Promise<Array<UserType>> {
  // Remove excess whitespace from the query
  if (query) query = query.trim().replace(/\s+/g, ' ')
  if (!queryIsValid(query, ignoreMinQuery)) return []

  let clients
  if (userType == 'staff') clients = await api.searchStaff(query)
  else clients = await api.searchClients(query)

  return clients
}

async function queryUserGroups(
  query,
  ignoreMinQuery,
  includeUserGroups,
  userIsAdmin
): Promise<?Array<*>> {
  if (includeUserGroups && userIsAdmin) {
    if (query) query = query.trim().replace(/\s+/g, ' ')
    if (!queryIsValid(query, ignoreMinQuery)) return []

    const userGroups = await api.get('/users/groups/')

    return userGroups.data
  }
}

function mapClientToOption(client: *): OptionType | null {
  if (!client.id) return null
  const user = new User(client)
  return {
    value: user.id,
    label: getDisplayName(user),
    type: 'user',
  }
}

function mapClientGroupToOption(group: *): OptionType | null {
  if (!group.id) return null
  return {
    value: group.id,
    label: group.title,
    type: 'group',
  }
}

const getDisplayName = (user: User) => {
  const socialSecurityNumnber =
    user.profile.social_security_number &&
    /\S/.test(user.profile.social_security_number)
      ? ` (${user.profile.social_security_number})`
      : ''
  return `${user.profile.last_name} ${user.profile.first_name}${socialSecurityNumnber}`
}

const queryIsValid = (query, ignoreLength = false) =>
  ignoreLength || (query && query.length >= MINIMUM_QUERY_LENGTH)

export function isCurrentUserAdmin(userGroup: Object): boolean {
  return userGroup && GROUP_LEVEL_ADMIN.includes(userGroup.name)
}
