// @flow
import self from 'autobind-decorator'
import React from 'react'

import Field from './Field'

import { Checkbox } from 'react-toggle-components'


export default class SingleToggleField extends Field<boolean> {

  type = 'checkbox'

  getInitialValue () {
    if (typeof this.props.initial !== 'undefined')
      return this.props.initial
    return false
  }

  @self
  onChange () {
    this.update(!this.state.value)
  }

  renderFormControl () {

    return <Checkbox
      color='#302f42'
      checked={ this.value }
      disabled={ this.state.disabled ? 'disabled' : false }
      onChange={ this.onChange }
      disabled={ this.disabled }
    />
  }
}
