// @flow
import self from 'autobind-decorator'
import React, { PureComponent, Fragment, type Node } from 'react'
import connect from 'bound-connect'
import { withRouter } from 'react-router-dom'

import { resolveSearchParam } from 'utils/resolve'
import { __ } from 'utils/gettext'

import { Title } from 'components/text'
import Dropdown from 'components/Dropdown'
import Icons from 'components/icons'
import { trackWidth } from 'components/TrackWidthDecorator'
import HelpIcon from 'components/HelpIcon'

import type { trackWidthPropsType } from 'components/TrackWidthDecorator'


type TabType = {
  label: string,
  content?: Node,
  before?: Node,
  url?: string,
  width?: number,
  isDropdown?: boolean,
  plans?: Array<Number>,
  badge?: Node,
}

type DropdownTabType = Object

type StateType = {
  current: number,
  tabItems: Array<TabType | DropdownTabType>,
  dropdownItems: Array<TabType>,
}

type PropTypes = {
  name?: string,
  items: Array<TabType>,
  values?: Array<any>,
  onChange?: number => void,
  children?: Node,
  userPlan?: number,
} & RouteProps
& trackWidthPropsType

type TabProps = {
  active: boolean,
  children: string | React$Element<*>,
  onSelect: (value: any) => void,
  items?: Array<*>,
  restricted?: boolean,
  badge?: Node,
} & trackWidthPropsType

const DD_TAB_MIN_WIDTH = 45

const DropdownTab = (props: TabProps) =>
  <li
    onClick={ () => {} }
    className={ props.active ? 'active tab' : 'tab' }>
    <Dropdown
      items={ props.items || [] }
      toggleComponent={ <Icons.MoreHrz /> }
      tabdropdown
    />
  </li>


const Tab = (props: TabProps) => {
  if (props.restricted)
    return <li
      className={ 'tab disabled' }>
      <Title>
        { props.children }
      </Title>
      { !props.isMobile && <HelpIcon text={ __('Unlock this feature with a PRO subscription') } /> }
    </li>
  else
    return <li
      onClick={ props.onSelect }
      className={ props.active ? 'active tab' : 'tab' }>
      <Title
        className={ props.active ? 'active' : 'focusable' }
        onKeyPress={ props.onSelect }
        tabIndex={ props.active ? undefined : 0 }>
        { props.children }
      </Title>
      { !props.isMobile && props.badge }
    </li>
}


@withRouter
@trackWidth
@connect
export default class TabView extends PureComponent<PropTypes, StateType> {

  tabs_container: HTMLElement | null = null
  dropdownTab = {
    label: 'More',
    isDropdown: true,
    content: [],
    width: 150
  }

  static actions = []
  static properties = state => ({
    userPlan:  state.user.profile.active_subscription?.type ? state.user.profile.active_subscription.type : null,
  })

  constructor (props: PropTypes) {
    super(props)
    this.state = {
      current: this.getActiveIndex(),
      tabItems: this.props.items,
      dropdownItems: [],
    }
  }

  get tabItemsWidth (): number {
    let width = 0
    this.state.tabItems.forEach(item => {
      if (item.width && !item.isDropdown) width += item.width
    })
    if (this.state.dropdownItems.length && width > this.props.elementWidth)
      width += DD_TAB_MIN_WIDTH

    return width
  }

  componentDidMount () {
    this.onChange(this.state.current, this.props.items[this.getActiveIndex()])

    if (this.tabs_container) {
      const items = this.state.tabItems
      items.forEach((item, index) => {
        item.width = this.tabs_container.children.item(index).offsetWidth - 2
      })
      this.setState({ tabItems: items })
    }
  }

  componentDidUpdate (prevProps: PropTypes) {
    if (prevProps.location.search !== this.props.location.search)
      this.setState({ current: this.getActiveIndex() })

    if (!prevProps.elementWidth || prevProps.elementWidth > this.props.elementWidth)
      this.hideExcessTabs()
    else if (prevProps.elementWidth < this.props.elementWidth)
      this.showExcessTabs()
  }

  hideExcessTabs () {
    const tabItems = [ ...this.state.tabItems ]
    if (tabItems[tabItems.length - 1].isDropdown)
      tabItems.splice(tabItems.length - 1, 1)
    const dropdownItems = [ ...this.state.dropdownItems ]
    const tabsWidth = this.tabItemsWidth
    let count = 0
    if (tabsWidth > this.props.elementWidth) {
      count = getExcessCount(tabItems, tabsWidth, this.props.elementWidth - this.dropdownTab.width) // Take dropdownTab.width into account to always display the dropdown
      dropdownItems.splice(0, 0, ...tabItems.splice(tabItems.length - count))
    } else
      return

    this.dropdownTab.content = dropdownItems

    if (dropdownItems.length)
      tabItems.push(this.dropdownTab)

    this.setState({ tabItems, dropdownItems })
  }

  showExcessTabs () {
    const tabItems = [ ...this.state.tabItems ]
    if (tabItems[tabItems.length - 1].isDropdown)
      tabItems.splice(tabItems.length - 1, 1)
    const dropdownItems = [ ...this.state.dropdownItems ]
    const tabsWidth = this.tabItemsWidth
    let count = 0
    if (this.props.elementWidth > tabsWidth) {
      count = getAddCount(dropdownItems, tabsWidth, this.props.elementWidth)
      tabItems.push(...dropdownItems.splice(0, count))
    } else
      return

    this.dropdownTab.content = dropdownItems

    if (dropdownItems.length)
      tabItems.push(this.dropdownTab)

    this.setState({ tabItems, dropdownItems })
  }

  render () {
    return <Fragment>

      { this.renderBefore() }

      <div className='tabs-container'>
        <ul className='tabs' ref={ ref => this.tabs_container = ref}>

          { this.state.tabItems.map((tab: TabType, n: number) =>
            tab.isDropdown
              ? <DropdownTab
                key={ n }
                active={ this.dropdownActive() }
                onSelect={ () => this.onChange(n, tab, true) }
                items={ this.tabsToDropdownItems(n) } >
                { tab.label }
              </DropdownTab>
              : <Tab
                key={ n }
                active={ this.isActiveTab(n) }
                onSelect={ () => this.onChange(n, tab, true) }
                restricted={ this.checkSubscription(tab) }
                isMobile={ this.props.isMobile }
                badge={ tab.badge || null }>
                { tab.label }
              </Tab>
          )}
        </ul>
      </div>

      { this.renderContent() }
      { this.props.children }

    </Fragment>
  }

  tabsToDropdownItems (n: number) {
    const items = []
    this.state.dropdownItems.forEach(
      (tab, index) => items.push({
        label: tab.label,
        onClick: () => this.onChange(index, tab, true, true),
        className: this.isActiveTab(n + index) ? 'active' : undefined,
        props: {},
      })
    )
    return items
  }

  checkSubscription (tab: TabType) {
    if (!tab.plans)
      return false
    const plans = tab.plans
    const userPlan = this.props.userPlan ? this.props.userPlan : null
    if (plans && userPlan) {
      if (plans.includes(userPlan))
        return false
      return true
    }
  }

  getActiveTab () {
    return this.props.items[this.state.current]
  }

  isActiveTab (n: number): boolean {
    return this.getActiveIndex() === n
  }

  dropdownActive (): boolean {
    if (this.state.current >= this.state.tabItems.length - 1)
      return true
    return false
  }

  getActiveIndex () {
    const search = resolveSearchParam(this.props.history.location, 'tab') || this.props.match.params.tab
    const url = window.location.pathname
    for (let i = 0; i < this.props.items.length; i++) {
      const tab = this.props.items[i]
      if (search === strToParam(tab.label)) {
        return i
      } else if (tab.url && url.startsWith(tab.url)) {
        return i
      }
    }
    return 0
  }

  renderBefore () {
    const tab = this.getActiveTab()
    return tab && tab.before || null
  }

  renderContent () {
    const tab = this.getActiveTab()
    return tab ? tab.content : null
  }

  get isValidUrl (): boolean {
    let isValid = true
    const pathname = this.props.history.location.pathname
    const found = this.props.items.find(tab => tab.url === pathname)
    if (found)
      return isValid
    if (this.props.items)
      this.props.items.forEach(tab => {
        if (tab.url && pathname.startsWith(tab.url))
          isValid = false
      })
    return isValid
  }

  @self
  //eslint-disable-next-line complexity
  onChange (current: number, tab?: TabType, force?: boolean, dropdown?: boolean) {
    const callback = () =>
      typeof this.props.onChange === 'function' &&
      this.props.onChange(current)
    this.setState({ current }, callback)
    if (tab && tab.url) {
      if (force || this.isValidUrl)
        this.props.history.replace( {
          pathname: tab.url,
          state: this.props.history.location.state
        })
    } else {
      let search = this.getSearch(current, force, dropdown)
      this.props.history.replace( {
        pathname: this.props.history.location.pathname,
        search,
        state: this.props.history.location.state
      } )
    }
  }

  getSearch (current: number, force?: boolean, dropdown?: boolean): string {
    if (dropdown) {
      return this.props.history.location.search.indexOf('page') > -1 && !force
        ? `?tab=${strToParam(this.state.dropdownItems[current].label)}&page=${resolveSearchParam(this.props.history.location, 'page')}`
        : `?tab=${strToParam(this.state.dropdownItems[current].label)}`
    } else {
      return this.props.history.location.search.indexOf('page') > -1 && !force
        ? `?tab=${strToParam(this.state.tabItems[current].label)}&page=${resolveSearchParam(this.props.history.location, 'page')}`
        : `?tab=${strToParam(this.state.tabItems[current].label)}`
    }
  }
}

function getExcessCount (tabItems: Array<TabType>, tabsWidth: number, containerWidth: number) {
  let count = 0

  for (let item of tabItems) {
    if (item.width) {
      tabsWidth -= item.width
      count++
      if (tabsWidth <= containerWidth)
        break
    }
  }
  return count
}

function getAddCount (dropdownitems: Array<TabType>, tabsWidth: number, containerWidth: number) {
  let count = 0

  for (let item of dropdownitems) {
    if (item.width) {
      tabsWidth += item.width
      count++
      if (tabsWidth > containerWidth){
        count--
        tabsWidth -= item.width
        break
      }
      else if (tabsWidth === containerWidth)
        break
    }
  }
  return count
}

const strToParam = (str) => str.toLowerCase()
  .replace(/å|ä/gi, 'a')
  .replace(/([\s\n\t\b]+)/, '_')
  .replace('ö', 'o')
