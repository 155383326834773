// @flow
import { Map as ImmutableMap } from 'immutable'
import { state as editor } from 'former-ui'
import { initialValue as forms } from 'reducers/forms'
import { initialValue as user } from 'reducers/user'
import { initialValue as users } from 'reducers/users'
import { initialValue as tenantSettings } from 'reducers/tenant'
import { initialValue as exercises } from 'reducers/exercises'
import { initialValue as courses } from 'reducers/courses'
import { initialValue as packages } from 'reducers/packages'
import { initialValue as tags } from 'reducers/tags'
import { initialValue as userGroups } from 'reducers/userGroups'
import { initialValue as documents } from 'reducers/documents'
import { initialValue as subscriptions } from 'reducers/subscriptions'
import { initialValue as onlineBooking } from 'reducers/onlineBooking'

import typeof { state as EditorState } from 'former-ui'
import type { CoreState } from 'reducers/core'
import type { UserRecordType } from 'reducers/user'
import type { UsersState } from 'reducers/users'
import type { FormsState } from 'reducers/forms'
import type { ClientState } from 'reducers/client'
import type { CommunicationState } from 'reducers/communication'
import type { TenantSettingsState } from 'reducers/tenant'
import type { ExercisesState } from 'reducers/exercises'
import type { CoursesState } from 'reducers/courses'
import type { PackagesState } from 'reducers/packages'
import type { TagsState } from 'reducers/tags'
import type { UserGroupsState } from 'reducers/userGroups'
import type { DocumentsState } from 'reducers/documents'
import type { SubscriptionState } from 'reducers/subscriptions'
import type { OnlineBookingState } from 'reducers/onlineBooking'


export type StateType = {
  user: UserRecordType,
  users: UsersState,
  core: CoreState,
  client: ClientState,
  forms: {
    forms: FormsState,
    editor: EditorState,
  },
  communication: CommunicationState,
  tenantSettings: TenantSettingsState,
  exercises: ExercisesState,
  courses: CoursesState,
  packages: PackagesState,
  tags: TagsState,
  userGroups: UserGroupsState,
  documents: DocumentsState,
  subscriptions: SubscriptionState,
  onlineBooking: OnlineBookingState
}


const communication: CommunicationState = {
  filters: new ImmutableMap(),
  messages: [],
  users: [],
  query: '',
  thread: { participants: []},
  attachmentFields: new Map(),
}

const core: CoreState = {
  alerts: new Map(),
  route:         [],
  showContract:  false,
}

const client: ClientState = {
  id: null,
  email: null,
  username: null,
  is_staff: false,
  phone_number: null,
  profile: {
    first_name: null,
    last_name: null,
    full_name: null,
    primary_language: null,
    picture: null,
    alerts_enabled: false,
    sms_enabled: false,
    terms_accepted: null,
    show_terms: false,
    social_security_number: null,
  }
}

const state: StateType = {
  user,
  users,
  core,
  client,
  communication,
  forms: { forms, editor },
  tenantSettings,
  exercises,
  courses,
  packages,
  tags,
  userGroups,
  documents,
  subscriptions,
  onlineBooking
}


export default state
