/**
 * @module ChoiceField
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import connect from 'bound-connect'
import { Bold, SmallText } from 'components/text'
import { DEFAULT_PRIMARY_COLOR } from 'constants'
import React, { PureComponent } from 'react'
import { styles } from 'react-drf/fields/SelectField'
import Select from 'react-select'
import { isEqual } from 'utils/resolve'

import type { StateType } from 'store/initialState'

type ValueType = string | number | null

export type ChoiceType = {
  value: ValueType,
  display_name?: string,
  label?: string,
}

type ChoiceFieldProps = {
  value: ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  readonlyChoice?: ChoiceType,
  read_only?: boolean,
  label?: string,
  id?: string | number,
  disabled?: boolean,
  initialValue?: ValueType,
  empty_label?: string | React$Element<*>,
  primaryColor?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  renderValueOnly?: boolean,
}

type ChoiceFieldStateType = {
  choices: Array<ChoiceType>,
  placeholder: string,
}

@connect
export default class ChoiceField extends PureComponent<
  ChoiceFieldProps,
  ChoiceFieldStateType
> {
  static defaultProps: Object = {
    value: null,
    choices: [],
  }

  state: ChoiceFieldStateType = {
    choices: [],
    placeholder: '----',
  }

  static actions: Array<*> = []
  static properties: Object = (state: StateType) => ({
    primaryColor: state.tenantSettings.color,
  })

  get shouldUpdateValue(): boolean {
    return this.props.choices &&
      this.props.choices.length &&
      !this.props.value &&
      this.props.choices[0].value
      ? true
      : false
  }

  styles(): Object {
    // Custom styles for keyboard focus
    let customStyles = {
      ...styles,
      control: (styles, { isFocused }) => {
        const color = '#2181F1'
        return {
          ...styles,
          boxShadow: isFocused ? 0 : 0,
          border: isFocused ? `2px solid ${color}` : 'transparent',
        }
      },
      option: (styles, { isFocused }) => {
        const color = this.props.primaryColor
          ? this.props.primaryColor
          : DEFAULT_PRIMARY_COLOR
        return {
          ...styles,
          backgroundColor: isFocused ? color : '#fff',
          color: isFocused ? '#fff' : color,
          whiteSpace: this.props.preventOptionTruncation ? 'normal': 'nowrap',
        }
      },
    }
    return customStyles
  }

  componentDidMount() {
    this.setChoices()
    if (this.props.initialValue) this.props.onChange(this.props.initialValue)
  }

  componentDidUpdate(oldProps: ChoiceFieldProps) {
    if (!isEqual(oldProps.choices, this.props.choices)) this.setChoices()
  }

  // eslint-disable-next-line complexity
  setChoices() {
    if (this.shouldUpdateValue) {
      this.props.onChange(this.props.choices[0].value)
    }

    let choices = this.props.choices

    if (choices && this.props.empty_label) {
      for (let i = 0; i < choices.length; i++) {
        if (choices[i].value === '') {
          choices[i].display_name = this.props.empty_label
          choices[i].label = this.props.empty_label
          break
        }
      }
    }

    if ((!choices || !choices.length) && this.props.readonlyChoice)
      choices = [this.props.readonlyChoice]
    choices = this.addLabels(choices)

    this.setState({ choices })
  }

  addLabels(choices: Array<ChoiceType>) {
    if (choices.length)
      return choices.map((choice) => {
        if (!choice.value || (is.string(choice.value) && !choice.value.length))
          this.setState({ placeholder: choice.display_name || choice.label })
        choice.label = choice.display_name
        return choice
      })
    return choices
  }

  renderValueOnly() {
    const value = this.state.choices.find((c) => c.value === this.props.value)
    if (value)
      return (
        <SmallText>
          <Bold notranslate>{value.label || '-'}</Bold>
        </SmallText>
      )

    return (
      <SmallText>
        <Bold notranslate> - </Bold>
      </SmallText>
    )
  }

  renderSelect() {
    const onChange = (choice: ChoiceType) => this.props.onChange(choice.value)

    return (
      <Select
        styles={this.styles()}
        aria-label={this.props.ariaLabel || null}
        aria-labelledby={this.props.ariaLabelledBy || null}
        value={this.state.choices.find((c) => c.value === this.props.value)}
        options={this.state.choices}
        onChange={onChange}
        isSearchable={false}
        isClearable={false}
        inputProps={{ id: this.props.id }}
        className='Select choice-field'
        classNamePrefix='Select'
        isDisabled={this.props.disabled}
        placeholder={this.state.placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    )
  }

  render(): React$Element<*> {
    if (this.props.renderValueOnly) return this.renderValueOnly()
    else return this.renderSelect()
  }
}
