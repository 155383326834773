// @flow
import api from 'api'
import context from 'context'

import { isPublicTenant } from 'actions/user'


const TIMEOUT = 2 * 60 * 1000
let interval = null

async function list(): ?Promise<?Array<any>> {
  if (context.currentUser.id && !isPublicTenant()) {
    const response = await api.get('/in-app-notifications/')
    if (!response.ok)
      return []
    return response.data
  }
}

function startRefreshing(callback: Function): void {
  interval = setInterval(() => { callback() }, TIMEOUT)
}

function stopRefreshing() {
  if (interval)
    clearInterval(interval)
}

export default {
  list,
  startRefreshing,
  stopRefreshing
}
