/**
 * @flow
 * @author mikakattainen<mika.kattainen@fns.fi>
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const BaseView = load(() =>
  import('views/Public/Exercises/BaseView'))

const ComposeExercise = load(() =>
  import('views/Public/Exercises/ComposeExercise'))

const ExercisesView = load(() =>
  import('views/Public/Exercises/ListExercises'))

const ComposeCollection = load(() =>
  import('views/Public/Exercises/ComposeCollection'))

const CollectionsView = load(() =>
  import('views/Public/Exercises/ListCollections'))


export const descriptors = {

  // Exercise
  exercises:            '/exercises/',
  createExercise:       '/exercises/create/',
  exercise: (pk: ID) => `/exercises/${pk}/`,
  filterExercises:      '/exercises/filter/',

  // Collection
  collections:                   '/exercises/collections/',
  createCollection:              '/exercises/collections/create/',
  collection:        (pk: ID) => `/exercises/collections/${pk}/`,
  picture:           (pk: ID) => `/exercises/${pk}/picture/`,
  tenantCollections: (pk: ID) => `/tenants/${pk}/collections/`,
  copyCollections:   (pk: ID) => `/tenants/${pk}/collections/copy/`,

  // Part
  parts:     (exercise_id: ID)         => `exercises/${exercise_id}/parts/`,
  partFile:  (exercise_id: ID, pk: ID) => `exercises/${exercise_id}/parts/${pk}/file/`,
  copyFile:  (exercise_id: ID, pk: ID) => `exercises/${exercise_id}/parts/${pk}/copy-file/`,
  partOrder: (exercise_id: ID, pk: ID) => `exercises/${exercise_id}/parts/${pk}/order/`,
}


export default <Switch>
  <Route path={ descriptors.createExercise } component={ ComposeExercise } />
  <Route path={ descriptors.exercise(':id(\\d+)') } component={ ComposeExercise } />
  <Route exact path={ descriptors.exercises } component={ BaseView } />
  <Route path={ descriptors.createCollection } component={ ComposeCollection } />
  <Route path={ descriptors.collection(':id(\\d+)') } component={ ComposeCollection } />
  <Route path={ descriptors.collections } component={ CollectionsView } />
  <RouteNotFound />
</Switch>
