

const _ = t => t
const TEXT_BLOCK_CLS_PREFIX = 'text-block-'
export const HTML_DEFINITIONS = {
  rootTag: 'section',
  textBlockClsPrefix: TEXT_BLOCK_CLS_PREFIX,
  sectionCls: TEXT_BLOCK_CLS_PREFIX + 'section',
  contentCls: 'text-content'
}

export const HTML_REPLACE_MAP = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;' }

export const TYPE_PROPERTIES = new Map(Object.entries({
  'blockquote': {
    className: 'blockquote',
  },
  'header-one': {
    className: 'h1 header-one',
  },
  'header-two': {
    className: 'h2 header-two',
  },
  'header-three': {
    className: 'h3 header-three',
  },
  'header-four': {
    className: 'h4 header-four',
  },
  'left': {
    element: 'div',
    className: 'align-left text-left',
  },
  'right': {
    element: 'div',
    className: 'align-right text-right',
  },
  'center': {
    element: 'div',
    className: 'align-center text-center',
  },
  'ordered-list-item': {
    className: 'list-item ordered',
  },
  'unordered-list-item': {
    className: 'list-item unordered',
  },
  'unstyled': {
    className: 'default unstyled',
  },
}))

export const BLOCK_TYPES = [
  {
    label: _('Heading'),
    style: 'header-two',
    tag: 'h2',
  },
  {
    label: _('Title'),
    style: 'header-three',
    tag: 'h3',
  },
  {
    label: _('Subtitle'),
    style: 'header-four',
    tag: 'h4',
  },
  {
    icon: 'retweet',
    label: _('Quote'),
    style: 'blockquote',
    tag: 'blockquote',
    hideLabel: true,
  },
  {
    icon: 'list',
    label: _('List'),
    style: 'unordered-list-item',
    tag: 'li',
    hideLabel: true,
  },
  {
    icon: 'th-list',
    label: _('Numbered list'),
    style: 'ordered-list-item',
    tag: 'li',
    hideLabel: true,
  },
  {
    icon: 'align-left',
    label: _('Left'),
    style: 'left',
    tag: 'div',
    hideLabel: true,
  },
  {
    icon: 'align-center',
    label: _('Center'),
    style: 'center',
    tag: 'div',
    hideLabel: true,
  },
  {
    icon: 'align-right',
    label: _('Right'),
    style: 'right',
    tag: 'div',
    hideLabel: true,
  },
  {
    label: _('Normal'),
    style: 'unstyled',
    tag: 'p'
  },
]

export const INLINE_STYLES = [
  { label: 'B', style: 'BOLD', icon: 'bold', tag: 'strong', hideLabel: true },
  { label: 'I', style: 'ITALIC', icon: 'italic', tag: 'em', hideLabel: true },
  { label: 'U', style: 'UNDERLINE', icon: 'underline', tag: 'ins', hideLabel: true },
]

const EDITOR_CONTROLS = [
  { label: 'Fullscreen', icon: 'arrowsAlt', hideLabel: true }
]

const TYPES = BLOCK_TYPES.filter(item => item.style.startsWith('header') || item.style === 'unstyled')
const ALIGN = BLOCK_TYPES.filter(item => ['left', 'center', 'right'].indexOf(item.style) > -1)
const LISTS = BLOCK_TYPES.filter(item => item.style.search('-list') > -1)
const QUOTE = BLOCK_TYPES.filter(item => item.style === 'blockquote')

export const STYLE_MENU = [
  {
    type: 'inline',
    items: INLINE_STYLES
  },
  {
    type: 'block',
    items: [ TYPES, ALIGN, LISTS, QUOTE ]
  },
  {
    type: 'editor',
    items: EDITOR_CONTROLS
  }
]

export const STYLES = {
  LEFT:   {
    display:   'block',
    textAlign: 'left'
  },
  RIGHT:  {
    display:   'block',
    textAlign: 'right'
  },
  CENTER: {
    display:   'block',
    textAlign: 'center'
  },
}

export const BLOCK_TYPES_MAP = new Map(Object.entries({
  'left':   TYPE_PROPERTIES.get('left'),
  'center': TYPE_PROPERTIES.get('center'),
  'right':  TYPE_PROPERTIES.get('right'),
}))
