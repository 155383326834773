// @flow
import api from 'api'

const TIMEOUT = 10 * 60 * 1000
const LOCAL_STORAGE_ITEM = 'announcements'

function getFromLocalStorage (): Array<number> {
  const storageItems = localStorage.getItem(LOCAL_STORAGE_ITEM)
  if (!storageItems)
    return []
  return JSON.parse(storageItems)
}

function addToLocalStorage (announcement: {id: number}): void {
  const newItems = [...getFromLocalStorage(), announcement.id]
  localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify(newItems))
}

function filterDismissed(announcements: Array<any>): Array<any> {
  return announcements.filter(a => !getFromLocalStorage().includes(a.id))
}

function dismiss(alert: any): void {
  if (alert && alert.isAnnouncement)
    addToLocalStorage(alert)
}

async function list(): Promise<Array<any>> {
  const response = await api.get('/announcements/active/')
  if (!response.ok)
    return []
  return filterDismissed(response.data)
}

function startRefreshing(callback: Function): void {
  setInterval(() => { callback() }, TIMEOUT)
}

export default {
  dismiss,
  list,
  startRefreshing,
}
