// @flow

const url_base        = process.env.API_URL
const url_media       = process.env.MEDIA_URL

export const GET_METHODS     = [ 'get', 'options' ]
export const POST_METHODS    = [ 'put', 'post', 'patch', 'delete' ]
export const ALLOWED_METHODS = [ ...GET_METHODS, ...POST_METHODS ]


export const isPostMethod = (method: api.MethodType) =>
  POST_METHODS.includes(method.toLowerCase())


export const isGetMethod = (method: api.MethodType) =>
  GET_METHODS.includes(method.toLowerCase())


/**
 * Get the subdomain from the url
 * @return {string} The subdomain
 */

export const getDomainUrl = () => typeof window === 'object' && window.location
  ? window.location.hostname
  : ''


/**
 * The base URL address for api calls.
 *
 * @const baseURL
 * @private
 */

export const url = `${url_base}/api`


/**
 * The media URL address for api calls.
 *
 * @const mediaURL
 * @private
 */

export const mediaURL = `${url_media}`


/**
 * The error received when the JWT token has expired
 * @const SIGNATURE_EXPIRED
 */
export const SIGNATURE_EXPIRED = 'Signature has expired.'
