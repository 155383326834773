// @flow
import React from 'react'

import { Stack, EmptyState } from '@nordhealth/react'
import AppointmentCard from './AppointmentCard'

import { formatDate } from 'utils/formatters'
import { __ } from 'utils/gettext'

import isMobileHook from 'utils/isMobileHook'

import type { Node } from 'react'
import type { Appointment } from './AppointmentCard'
import { future_appointments } from '../test_data'

type Props = {
  pastAppointments: Appointment[],
  upcomingAppointments: Appointment[],
  showPast: boolean,
}

const AppointmentList = (props: Props): Node => {
  const isMobile = isMobileHook(window, true)
  const groupedAppointments = groupByDate(
    props.showPast ? props.pastAppointments : props.upcomingAppointments
  )
  const datesOfAppointments = Object.keys(groupedAppointments)

  if (!datesOfAppointments.length) {
    return (
      <EmptyState>
        <h2>
          {__(
            `You don't have any ${
              props.showPast ? 'past' : 'upcoming'
            } appointments`
          )}
        </h2>
        <p>
          {__('You can book a new appointment by clicking ')}
          <a href='/online-booking'>{__('here')}</a>.
        </p>
      </EmptyState>
    )
  }

  return (
    <div>
      {datesOfAppointments.map((date: string) => {
        const appointments = groupedAppointments[date]
        return (
          <AppointmentSublist
            key={date}
            date={date}
            appointments={appointments}
            isMobile={isMobile}
          />
        )
      })}
    </div>
  )
}

type SublistProps = {
  date: string,
  appointments: Appointment[],
  isMobile: boolean,
}

// Sublist displays both the heading, i.e date and the appointment related to that date
const AppointmentSublist = (props: SublistProps): Node => {
  const isToday =
    new Date().setHours(0, 0, 0, 0) ===
    new Date(props.date).setHours(0, 0, 0, 0)
      ? __('Today')
      : null

  return (
    <div>
      <h3 className='sublist-heading'>
        {isToday || formatDate(props.date, 'D. MMMM YYYY')}
      </h3>
      <Stack>
        {props.appointments.map((appointment: Appointment) => {
          return (
            <AppointmentCard
              cancellation_token={appointment.cancellation_token}
              start={appointment.start}
              location={appointment.location}
              end={appointment.end}
              id={appointment.id}
              service={appointment.service}
              employee={appointment.employee}
              key={appointment.id}
              isMobile={props.isMobile}
            />
          )
        })}
      </Stack>
    </div>
  )
}

type GroupedAppointments = {
  [string]: Appointment[],
}

const groupByDate = (appointments: Appointment[]): GroupedAppointments => {
  let groupedAppointments = {}
  appointments.forEach((appointment) => {
    const date = getDate(appointment)
    if (!groupedAppointments.hasOwnProperty(date)) {
      groupedAppointments[date] = []
    }
    groupedAppointments[date].push(appointment)
  })
  return groupedAppointments
}

const getDate = (appointment: Appointment): string => {
  const [date, time] = appointment.start.split(' ')
  return date
}

export default AppointmentList
