// @flow
import self from 'autobind-decorator'
import React, { Component } from 'react'
import { Badge } from 'components/generic'

import Field from './Field'
import { FilePreview as Preview } from 'components/generic'
import { __ } from 'utils/gettext'

import type { Node } from 'react'

type AttachmentGroupType = Set<File> | Event | null

type AttachmentFieldProps = {
  name: string,
  type: string,
  maxFiles: number,
  buttonLabel?: string,
  onChange: AttachmentGroupType => *,
  disabled?: boolean,
  asLinks?: boolean,
}

type AttachmentFieldStateType = {
  attachments: AttachmentGroupType,
}


export default class FileField extends Field<AttachmentGroupType> {

  field: AttachmentField

  getInitialValue () { // eslint-disable-line class-methods-use-this
    return null
  }

  @self
  clear () {
    if (!this.field)
      throw new Error(`wth`)
    this.field.clearAttachments()
    super.clear()
  }

  @self
  onChange (value: AttachmentGroupType | Event) {
    this.update(value)
  }

  renderFormControl (): Node {
    return <AttachmentField
      maxFiles={ this.getOptionValue('maxFilesCount') || 1 }
      name={ this.name }
      type={ this.type }
      onChange={ this.onChange }
      buttonLabel={ __('Add attachment') }
      ref={ ref => ref && (this.field = ref) }
      disabled={ this.disabled }
      asLinks={ this.props.asLinks }
    />
  }
}


class AttachmentField extends Component<AttachmentFieldProps, AttachmentFieldStateType> {

  input: Element

  state = {
    attachments: new Set()
  }

  @self
  async onDrop (event: SyntheticDragEvent<*>) {
    await this.addAttachments(...event.dataTransfer.files)
    this._clearInput()
  }

  @self
  async onChange (event: SyntheticInputEvent<*>) {
    await this.addAttachments(...event.target.files)
    this._clearInput()
  }

  render () {
    const updateReference = ref => ref && (this.input = ref)
    const onDrop = withDefaultPrevented(this.onDrop)
    const onDrag = withDefaultPrevented()

    const onDragStart = (e) => {
      e.persist()
    }

    const onDragOver = (e) => {
      e.persist()
    }

    return <div className='file-field'>

      { this.props.asLinks
        ? this.renderAttachmentsAsLinks()
        : this.renderAttachments() }

      <label
        onDrop={ onDrop }
        onDragStart={ onDragStart }
        onDragEnd={ onDrag }
        onDragOver={ onDragOver }
        className='field file-input'>

        <input
          // This element is not visible in the UI.
          // Instead, the button below is used.
          type='file'
          ref={ updateReference }
          onChange={ this.onChange }
          disabled={ this.props.disabled }/>

        <span className='button primary outlined'>
          <span>{ this.props.buttonLabel || __('Attachment') }</span>
        </span>

      </label>

    </div>
  }

  renderAttachments (): React$Element<*> | null {
    if (!this.state.attachments)
      return null

    const attachments = [ ...this.state.attachments ]
    const renderAttachment = ( attachment, key ) =>
      <li
        key={key}
        className='attachment-preview'
        onClick={ () => this.removeAttachment(attachment) }>
        <Attachment source={URL.createObjectURL(attachment)} type={attachment.type} />
        <h4>{attachment.name}</h4>
      </li>

    return <aside
      onClick={ event => event.preventDefault() }
      className='attachments-list'>
      <ul>
        { attachments.map(renderAttachment) }
      </ul>
    </aside>
  }

  renderAttachmentsAsLinks (): React$Element<*> | null {
    if (!this.state.attachments)
      return null

    const attachments = [ ...this.state.attachments ]

    const renderAttachment = ( attachment, key ) =>
      <Badge onRemove={ () => this.removeAttachment(attachment) }>
        <a key={key} className='link linked-item-entry' href={ URL.createObjectURL(attachment) } target='_blank' rel='noopener noreferrer'>
          { attachment.name }
        </a>
      </Badge>


    return <div className='full-width margin-top-none'>
      { attachments.map(renderAttachment) }
    </div>
  }

  async addAttachments (...files: Array<File>) {
    let update = ({ attachments }) => {
      if (!attachments)
        attachments = new Set()
      for (let file of files)
        if (attachments.size < this.props.maxFiles)
          attachments.add(file)
      return { attachments }
    }
    await new Promise(resolve => this.setState(update, resolve))
    this.props.onChange(this.state.attachments)
  }

  async removeAttachment (file: File) {
    let update = ({ attachments }) => {
      if (!attachments)
        return { attachments: null }
      attachments.delete(file)
      if (!attachments.size)
        attachments = null
      return { attachments }
    }
    await new Promise(resolve => this.setState(update, resolve))
    this.props.onChange(this.state.attachments)
  }

  @self
  async clearAttachments () {
    const update = {
      attachments: null
    }
    await new Promise(resolve => this.setState(update, resolve))
    this.props.onChange(this.state.attachments)
  }

  @self
  _clearInput () {
    if (this.input)
      // flow-ignore: Is always an input element
      this.input.value = ''
  }
}


// NOTE: bmp :---)
const MATCH_IMAGE = /^image\/([^/]+)/


const isImage = (url: ?string) =>
  new RegExp(MATCH_IMAGE.source).test(url || '')


const Attachment = ({ source, type }: { source?: string, type?: string }) =>
  <Preview url={ source } type={ type ? '.' + type.split('/')[type.split('/').length - 1] : undefined } />


const withDefaultPrevented =
  (fn?: SyntheticDragEvent<*> => *) =>
    (event: SyntheticDragEvent<*>): boolean => {

      event.preventDefault && event.preventDefault()
      if (typeof fn === 'function')
        fn(event)
      return false
    }
