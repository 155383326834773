/**
 * @flow
 */
import React, { Component } from 'react'
import { Radio } from 'react-toggle-components'

import classNames from 'classnames'


class SingleSelect extends Component <*> {

  get className () {
    return classNames(
      'options',
      'single',
      this.props.horizontal && 'horizontal',
      this.props.compact && 'compact',
    )
  }

  render () {
    return <dl className={ this.className }>
      { this.props.choices.map((choice, n) => {
        const label = choice.display_name ? choice.display_name : choice
        const key = choice.value ? choice.value : n
        return <label key={ key } className='option'>
          <dd>
            <Radio
              name={ label }
              disabled={ false }
              checked={ is.number(this.props.value)
                ? this.props.value === key
                : this.props.selection === key }
              onChange={ this.props.onChange
                ? () => this.props.onChange(key)
                : () => this.props.updateStatus(key) }
            />
          </dd>
          <dt>{ label }</dt>
        </label>
      })}
    </dl>
  }

}

export default SingleSelect
