// @flow
import React, { useEffect, useState } from 'react'
// $FlowIgnore
import { Banner, Card, Spinner, Stack } from '@nordhealth/react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/designSystemComponents'
import Text from 'components/text'
import {
  increaseStep,
  resetBooking,
  setInitialData,
} from '../../../actions/onlineBooking'

import navigator from 'routes/base'
import { __ } from 'utils/gettext'
import { ENDPOINT_URLS } from '..'
import navisec_banner from '../../../img/navisec_banner.png'
import BookingLoginModal from '../extra/BookingLoginModal'
import withOnlineBookingModal from '../extra/OnlineBookingModal'
import { useFetch } from '../utils/useFetchHook'

import type { Node } from 'react'

const BookingFrontPage = (): Node => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [isBookingAllowed, setIsBookingAllowed] = useState<boolean>(true)
  const [showLoginBanner, setShowLoginBanner] = useState<boolean>(false)
  const [loginStatus, setLoginStatus] = useState<?string>(null)

  const tenantSettings = useSelector((state) => state.tenantSettings)
  const userId = useSelector((state) => state.user.id)
  const [data, error, loading] = useFetch(
    ENDPOINT_URLS.INITIAL_DATA,
    'get',
    {},
    [userId]
  )

  useEffect(() => {
    dispatch(resetBooking())
  }, [])

  useEffect(() => {
    if (data) {
      dispatch(setInitialData(data))
    }
  }, [data])

  useEffect(() => {
    if (userId && !isBookingAllowed) {
      setIsBookingAllowed(true)
    }
  }, [userId])

  if (error && error.status === 401 && isBookingAllowed === true) {
    setIsBookingAllowed(false)
  }
  const startBooking = (): void => {
    dispatch(increaseStep())
  }

  const getCardContent = (): Node => {
    if (isBookingAllowed) {
      return (
        <div className='card-layout'>
          <span className='card-label'>
            <Text>Find appointment</Text>
          </span>
          <Button size='m' variant='primary' onClick={startBooking}>
            Choose
          </Button>
        </div>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Text>You should login to use the service</Text>
        </div>
      )
    }
  }

  const toggleBanner = (status) => {
    setLoginStatus(status)
    setShowLoginBanner(true)
  }

  const showLoginStatusBanner = () => {
    const message =
      loginStatus === 'success'
        ? __('Logged in successfully')
        : __('Login failed')
    return <Banner variant={loginStatus}>{message}</Banner>
  }

  if (loading) return <Spinner size='xxl'></Spinner>

  return (
    <div>
      <div className='booking-frontpage-header'>
        <span
          className='logo-container booking-frontpage-header-item'
          onClick={() => navigator.navigate('/')}
        >
          <img
            src={tenantSettings.logo || navisec_banner}
            className='logo'
            alt={__('Go to frontpage')}
          />
        </span>
        {showLoginBanner && (
          <div className='banner-container'>{showLoginStatusBanner()}</div>
        )}
        {!userId && (
          <Button
            variant='primary'
            size='l'
            className='booking-frontpage-header-item'
            onClick={() => setOpen(true)}
          >
            Sign in
          </Button>
        )}
      </div>
      <hr className='divider' />
      <div className='booking-frontpage-introduction'>
        <div className='heading-text-container'>
          <h2 className='n-color-text'>
            <Text>Online booking</Text>
          </h2>
        </div>
        <div className='heading-text-container'>
          <div className='n-color-text-weaker n-font-size-xl'>
            <Text>
              Find your therapist and appointment by starting the booking below
            </Text>
          </div>
        </div>
        <div className='stack-wrapper'>
          <Stack>
            <Card>{getCardContent()}</Card>
          </Stack>
        </div>
      </div>

      <BookingLoginModal
        open={open}
        setOpen={setOpen}
        showProceedAsGuest={false}
        setShowLoginBanner={toggleBanner}
      />
    </div>
  )
}

export default (withOnlineBookingModal(BookingFrontPage, true): *)
