// @flow
import React, { useState } from 'react'
import { Stack, Icon } from '@nordhealth/react'
import Divider from '@material-ui/core/Divider'

import { formatDate } from 'utils/formatters'
import Map from './Map'

import LocationModal from './LocationModal'
import { __ } from 'utils/gettext'

import { getLocationBySelectedTimeslot } from '../utils/getLocation'

import type { Node } from 'react'
import type {
  OnlineBookingState as OnlineBooking,
  SelectedTimeSlot,
  Location,
} from '../../../reducers/onlineBooking'

type Props = {
  bookingData: OnlineBooking,
  isMobile?: boolean,
  displayConfirmationTitle?: boolean,
  withMap?: boolean,
}

const ConfirmationDetails = (props: Props): Node => {
  const {
    available_date,
    selected_time_slots,
    selected_service,
    selected_employee,
    all_locations,
  } = props.bookingData

  const date = available_date && formatDate(available_date.date, 'MMMM D, YYYY')
  const time = selected_time_slots && selected_time_slots.time
  const dateAndTimeItem = date && time && `${date} - ${time}`
  const serviceName = selected_service?.name
  const employee = selected_employee?.name
  const profession = selected_employee?.profession || 'No profession specified'
  const appointmentType = selected_time_slots?.treatment_name
  const selected_location =
    selected_time_slots &&
    getLocationBySelectedTimeslot(
      selected_time_slots.location_id,
      all_locations
    )[0]
  let location = selected_location

  if (location) {
    const { street_address, post_office, postal_code } = location.address
    const addressText = street_address
      ? `${street_address}, ${postal_code} ${post_office}`
      : 'No address specified'

    location = {
      name: location.name,
      address: addressText,
    }
  }

  return props.isMobile ? (
    <MobileLayout
      dateAndTime={dateAndTimeItem}
      location={location}
      service={serviceName}
      employee={employee}
      appointment={appointmentType}
      displayConfirmationTitle={props.displayConfirmationTitle}
      selected_location={selected_location}
    />
  ) : (
    <DesktopLayout
      date={date}
      time={time}
      appointment={appointmentType}
      location={location}
      employee={employee}
      profession={profession}
      withMap={props.withMap}
    />
  )
}

const MobileLayout = (props: *) => {
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  return (
    <>
      <Stack gap='m'>
        {props.displayConfirmationTitle && <ConfirmationTitle />}
        <ConfirmationItem title={'Service'} item={props.service} />
        <Divider />
        <ConfirmationItem title={'Date and time'} item={props.dateAndTime} />
        <Divider />
        <ConfirmationItem
          title={'Location'}
          item={props.location && props.location.name}
          subtext={props.location && props.location.address}
          onClick={() => setMapVisible(true)}
          isMobile={true}
        />
        <Divider />
        <ConfirmationItem title={'Therapist'} item={props.employee} />
        <Divider />
        <ConfirmationItem title={'Appointment type'} item={props.appointment} />
      </Stack>
      <LocationModal
        mapHeight='1000px'
        mapVisible={mapVisible}
        setMapVisible={setMapVisible}
      />
    </>
  )
}

const DesktopLayout = (props: *) => {
  const showMap = props.location && props.location.address !== 'No address specified'
  return (
    <Stack gap='m'>
      {props.withMap && showMap && (
        <div className='map-container'>
          <Map />
        </div>
      )}
      <ConfirmationItem title={'Date'} item={props.date} />
      <Divider />
      <ConfirmationItem title={'Time'} item={props.time} />
      <Divider />
      <ConfirmationItem title={'Appointment type'} item={props.appointment} />
      <Divider />
      <ConfirmationItem
        title={'Location'}
        item={props.location && props.location.name}
        subtext={props.location && props.location.address}
      />
      <Divider />
      <ConfirmationItem
        title={'Therapist'}
        item={props.employee}
        subtext={props.profession}
      />
    </Stack>
  )
}

const ConfirmationItem = ({
  title,
  item,
  subtext,
  onClick,
  isMobile,
}: {
  title: ?string,
  item: ?string,
  subtext?: ?string,
  onClick?: Function,
  isMobile?: boolean,
}) => {
  const handleClick = () => {
    if (onClick) onClick()
  }
  return (
    <div className='confirmation-item' onClick={handleClick}>
      <span className='title n-font-weight-strong n-color-text-weaker'>
        {__(title)}
        {title === 'Location' && isMobile ? (
          <Icon name='interface-new-window-small' size='s' />
        ) : null}
      </span>
      <span className='item n-font-weight-strong'>{item}</span>
      {subtext && (
        <span className='n-color-text-weak n-font-size-s'>{subtext}</span>
      )}
    </div>
  )
}

const getLocation = (
  allLocations,
  selectedTimeSlot: SelectedTimeSlot
): Location => {
  const location = allLocations.filter(
    (loc) => loc.id === selectedTimeSlot.location_id
  )[0]
  return location
}

const ConfirmationTitle = () => {
  return (
    <>
      <div className='confirmation-item'>
        <span className='n-typescale-xxl n-font-weight-heading'>
          Booking confirmation
        </span>
      </div>
      <Divider />
    </>
  )
}

export default ConfirmationDetails
