import type { Action } from '../reducers'

export const UPDATE_TAGS = 'UPDATE_USER_TAGS'
export const UPDATE_SEARCH = 'UPDATE_USER_SEARCH'
export const SET_OWN_GROUPS = 'UPDATE_GROUP_FILTER'

export const updateTags = (value: Array<number>): Action => ({
  type: UPDATE_TAGS,
  payload: value
})

export const updateSearch = (value: string): Action => ({
  type: UPDATE_SEARCH,
  payload: value ? value : null
})

export const setOwnGroups = (own_clients: number): Action => ({
  type: SET_OWN_GROUPS,
  payload: own_clients
})