// @flow
import React, { Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Stack, Card, Icon as NordIcon } from '@nordhealth/react'

import { increaseStep, selectEmployee, selectTimeslot } from 'actions/onlineBooking'

import ConfirmationFooter from '../extra/ConfirmationFooter'
import DateQuickSelect from '../extra/DateQuickSelect'

import EmptyState from './EmptyState'
import Switch from 'components/Switch'

import type { Node } from 'react'
import type { OptionObject as Option } from 'components/Switch'
import type {
  Employee,
  LocationWithAvaialbleTimes,
  ServiceType,
} from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

const EMPTY_STATE_TITLE = 'No times available for this clinic'

type TimeSlotsProps = {
  timeslots: Array<ServiceType>,
  isMobile?: boolean,
  employee?: Employee,
}

const AppointmentTimeSlots = ({
  timeslots,
  isMobile,
  employee,
}: TimeSlotsProps): Node => {
  const dispatch = useDispatch()
  const bookingState = useSelector((state: StateType) => state.onlineBooking)

  // Controls dropdown
  const [activeCard, setActiveCard] = useState<number | null>(null)

  // Highlights selected timeslot when it is clicked on
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  // Options required for the switch component
  const options = getOptions(timeslots, bookingState.all_locations)
  const [selectedLocation, setSelectedLocation] = useState<number>(
    getDefaultOption(options)
  )

  const handleCardSelection = (id) => {
    setSelectedItem(null)
    dispatch(selectTimeslot(null))
    if (!activeCard || activeCard !== id) {
      return setActiveCard(id)
    }
    return setActiveCard(null)
  }

  const createCardComponent = (slot: ServiceType) => {
    return (
      <Card key={slot.name}>
        <TimeslotHeading
          slot={slot}
          activeCard={activeCard}
          handleCardSelection={handleCardSelection}
        />
        <DropdownContainer
          slot={slot}
          activeCard={activeCard}
          dispatch={dispatch}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectedLocation={selectedLocation}
        />
      </Card>
    )
  }

  const mapTimeIntervals = () => {
    return <Stack>{timeslots && timeslots.map(createCardComponent)}</Stack>
  }

  const renderSwitch = () => {
    if (options.length === 1) return null

    return (
      <div style={{ textAlign: 'center' }}>
        <Switch
          options={options}
          selectedOption={selectedLocation}
          setSelectedOption={setSelectedLocation}
        />
      </div>
    )
  }

  // SelectTimeslotView is not a separate view on Desktop so we skip step 5
  const navigateToBookingConfirmation = () => {
    dispatch(selectEmployee(employee, false))
    dispatch(increaseStep(2))
  }

  return (
    <Fragment>
      {isMobile && <DateQuickSelect updateSelectedDate={true} />}
      {renderSwitch()}
      {mapTimeIntervals()}
      {bookingState.selected_time_slots && (
        <ConfirmationFooter
          position={isMobile ? 'fixed' : 'absolute'}
          label='Choose appointment'
          onConfirm={!isMobile ? navigateToBookingConfirmation : null}
        />
      )}
    </Fragment>
  )
}

const Icon = ({ slot, activeCard }: IconProps): Node => {
  const isArrowUp = slot.id === activeCard

  return (
    <NordIcon size='s' name={isArrowUp ? 'arrow-up' : 'arrow-down'}></NordIcon>
  )
}

const TimeslotHeading = ({
  slot,
  activeCard,
  handleCardSelection,
}: TimeSlotsHeadingProps) => {
  return (
    <div
      className='timeslot-heading'
      onClick={() => handleCardSelection(slot.id)}
    >
      <span>
        <div className='n-font-weight-heading'>{slot.name}</div>
        <div className='n-color-text-weakest'>{slot.onlinedescription}</div>
      </span>
      <Icon slot={slot} activeCard={activeCard} />
    </div>
  )
}

const DropdownContainer = ({
  slot,
  activeCard,
  dispatch,
  selectedItem,
  setSelectedItem,
  selectedLocation,
}: DropdownContainerProps): Node => {
  if (slot && slot.location_specific_times.length > 0) {
    const selectedLocationTimes = slot.location_specific_times.find(
      (times) => times.location_id === selectedLocation
    )
    if (selectedLocationTimes) {
      return (
        <div
          key={slot.id}
          className='timeslot-dropdown'
          style={{
            display: activeCard === slot.id ? 'block' : 'none',
          }}
        >
          {mapLocationsWithTimeslots(
            selectedLocationTimes,
            slot,
            dispatch,
            selectedItem,
            setSelectedItem
          )}
        </div>
      )
    }
    return null
  }
  return null
}

const mapLocationsWithTimeslots = (
  location: LocationWithAvaialbleTimes,
  slot,
  dispatch,
  selectedItem,
  setSelectedItem
) => {
  const selected_locations = useSelector(
    (state) => state.onlineBooking.selected_locations
  )

  const emptyState = location.available_times.length === 0

  const dropdownContent = () => {
    if (!emptyState) {
      return (
        <div className='timeslot-dropdown-slots'>
          {location.available_times.map((time) =>
            mapSlots(
              time,
              slot.name,
              slot.id,
              dispatch,
              selectedItem,
              setSelectedItem,
              location.location_id
            )
          )}
        </div>
      )
    }
    return <EmptyState additionalInfo={EMPTY_STATE_TITLE} />
  }

  return <div key={location.location_id}>{dropdownContent()}</div>
}

const mapSlots = (
  time,
  name,
  treatment_id,
  dispatch,
  selectedItem,
  setSelectedItem,
  location_id
) => {
  const handleSelection = (time, treatment_name, treatment_id, key) => {
    const selectedTimeslot = { time, treatment_id, treatment_name, location_id }
    setSelectedItem(key)
    dispatch(selectTimeslot(selectedTimeslot))
  }

  const key = `${name}${time}`

  return (
    <span
      key={key}
      className={`timeslot-dropdown-item n-border ${
        key === selectedItem ? 'timeslot-dropdown-item-selected' : ''
      }`}
      onClick={() => handleSelection(time, name, treatment_id, key)}
    >
      {time}
    </span>
  )
}

const getOptions = (timeslots: ServiceType[], allLocations): Option[] => {
  const options = []
  const { location_specific_times } = timeslots[0]
  location_specific_times.forEach((times) => {
    const location = allLocations.find((loc) => loc.id === times.location_id)
    options.push({ key: location.id, name: location.name })
  })
  return options
}

const getDefaultOption = (options: Option[]): any => {
  return options[0].key
}

const getLocation = (allLocations, currentLocation): string => {
  const location = allLocations.filter(
    (loc) => loc.id === currentLocation.location_id
  )
  return location[0].name
}

type IconProps = {
  slot: Object,
  activeCard: ?number,
}
type DropdownContainerProps = {
  slot: ServiceType,
  activeCard: ?number,
  dispatch: Function,
  selectedItem: ?string,
  setSelectedItem: Function,
  selectedLocation: number,
}

type TimeSlotsHeadingProps = {
  slot: Object,
  activeCard: ?number,
  handleCardSelection: Function,
}

export default AppointmentTimeSlots
