import self from 'autobind-decorator'
import { CompositeDisposable, Disposable, Emitter } from 'event-kit'

import { updateDetails, isPublicTenant } from 'actions/user'
import compare from 'shallowequal'
import Symbol from 'es6-symbol'

const dispatch = Symbol('dispatch')

class ApplicationContext {
  state = {}
  emitter = new Emitter()
  subscriptions = new CompositeDisposable()

  constructor() {
    this.subscriptions.add(this.emitter)
  }

  observe(store) {
    const unsubscribe = store.subscribe(() => this.update(store.getState()))
    this[dispatch] = (action) => store.dispatch(action)
    this.update(store.getState())
    this.subscriptions.add(
      new Disposable(() => (this._dispatch = null)),
      new Disposable(unsubscribe)
    )
  }

  dispatch(action) {
    if (!this[dispatch])
      throw new ReferenceError(
        `ApplicationContext's observe method must be called ` +
          `with the redux store as an argument` +
          `prior to any action dispatch calls.`
      )
    this[dispatch](action)
  }

  update(newState) {
    this.state = newState
    this.emitter.emit('did-update', this)
  }

  dispose() {
    this.subscriptions.dispose()
  }

  @self
  onDidUpdate(fn) {
    return this.emitter.on('did-update', fn)
  }

  @self
  onLanguageDidChange(fn) {
    return this.emitter.on(
      'did-update',
      onlyChangesIn('user.profile.primary_language', fn)
    )
  }

  @self
  onDidChangeLocation(fn) {
    return this.emitter.on('did-update', onlyChangesIn('core.route', fn))
  }

  get currentLocale() {
    if (!this.state.user?.id || isPublicTenant()) {
      return localStorage.getItem('language') || null
    }
    return this.state.user.profile.primary_language
  }

  set currentLocale(primary_language) {
    this.dispatch(updateDetails({ primary_language }))
  }

  get loggedInAsStaff() {
    if (!this.state.user) return false
    return this.state.user.is_staff
  }

  get loggedInAsClient() {
    if (!this.state.user) return false
    return !this.state.user.is_staff
  }

  get currentUserGroup() {
    if (!this.state.user) return null
    return this.state.user.group
  }

  get currentUser() {
    if (!this.state.user) return null
    return this.state.user
  }

  get selectedClient() {
    if (!this.state.client.id) return null
    return this.state.client
  }
}

export default new ApplicationContext()

function onlyChangesIn(selector, fn) {
  let previousValue
  selector = selector.split('.')

  return (ctx) => {
    let newValue = ctx.state
    for (let fragment of selector) newValue = newValue[fragment]
    if (compare(newValue, previousValue)) return null

    previousValue = newValue
    return fn(ctx)
  }
}
