/**
 * @module LockedTenantView
 * @flow
 */

import Text, { Title } from 'components/text'
import React, { Component } from 'react'
import placeholder_image from '../../img/tenant_locked.png'

import { withRouter } from 'react-router-dom'

@withRouter
class LockedTenantView extends Component<*, *> {
  render() {
    return (
      <div className='locked-page-container'>
        <div className='text-container'>
          <Title>This company's Nordhealth Connect -service is closed</Title>
          <Text>
            The company you’re looking for is no longer using our Nordhealth
            Connect -service. We suggest reaching out to them directly via their
            website
          </Text>
        </div>
        <div className='img-container'>
          <img src={placeholder_image} />
        </div>
      </div>
    )
  }
}

export default LockedTenantView
