// @flow
// @flow-runtime
import Suggestion from './SuggestionComponent'
import { ENTITY_LABEL } from './constants'


const entityIsSuggestion = (entity) =>
  entity.getType() === ENTITY_LABEL


const findSuggestionEntities = (contentBlock: *, callback: *, contentState: *) => {
  const find = (character) => {
    const entityKey = character.getEntity()
    if (entityKey === null)
      return false

    const entity = contentState.getEntity(entityKey)
    return entityIsSuggestion(entity)
  }

  contentBlock.findEntityRanges(find, callback)

}


export default {
  strategy:  findSuggestionEntities,
  component: Suggestion,
}
