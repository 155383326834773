/**
 * @module TokenSelectField
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent } from 'react'
import { SelectField } from 'react-drf/fields'
import { __ } from 'utils/gettext'

export type ChoiceType = {|
  value: number,
  display_name: string,
|}

type ValueType = string

type TokenSelectFieldProps = {
  name: string,
  value: ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  disabled?: boolean,
  placeholder?: string,
  noResultsText?: string,
  autoInit?: boolean,
}


export default class TokenSelectField extends PureComponent<TokenSelectFieldProps> {

  static NO_RESULTS_LABEL = __('No results')

  static defaultProps = {
    value: '',
    choices: [],
    autoInit: false,
  }

  componentDidMount () {

    // if there is only one choice then select it automatically if
    if (this.props.autoInit && this.props.choices.length === 1)
      this.props.onChange(mapChoiceToOption(this.props.choices[0]).value)
  }

  render () {
    const onChange = (option) =>
      this.props.onChange(option ? option.value : null)

    return <SelectField
      name={ this.props.name }
      value={ this.props.value }
      options={ this.props.choices.map(mapChoiceToOption) }
      disabled={ this.props.disabled }
      onDidUpdate={ onChange }
      placeholder={ this.props.placeholder }
      noResultsText={ this.props.noResultsText || TokenSelectField.NO_RESULTS_LABEL }
    />
  }
}


const mapChoiceToOption = (choice) => ({
  value: choice.value,
  label: choice.display_name,
})
