/**
 * @flow
 * @module Messages
 */

import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'

import routes from 'routes/communication'
import { PrimaryButton } from 'components/buttons'
import type { RouterProperties } from 'types/index'


export const url = {
  threads: {
    list: '/threads/',
    post: '/threads/init/',
  },
}

export const NewThreadButton = () =>
  <PrimaryButton url={ url.threads.post }>
    Add conversation
  </PrimaryButton>


@withRouter
export default class Messages extends Component<RouterProperties> {

  render () {

    return (
      <Fragment>
        { routes }
      </Fragment>
    )
  }
}
