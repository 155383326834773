/**
 * @module BaseComposeView
 * @flow
 */
import self from 'autobind-decorator'
import getFieldset from 'fieldsets'
import React, { Fragment, Component } from 'react'
import { Card, Loader } from 'components/generic'

import type { FieldType } from './BaseComposeView'


type SectionType = {
  title: React$Element<*>,
  fields: Array<FieldType>,
  footer?: React$Element<*>,
  props?: Object
}

type SectionParams = {
  Title: React$Component<*>,
  Fields: React$Component<*>,
  Footer: React$Component<*>,
  visibile?: Boolean,
  props?: Object
}

type ComposeFormProps = {
  url: string,
  data: Object | null,
  errors: Object | null,
  fields: Array<FieldType>,
  method: string,
  strings: Object,
  onChange: Function,
  deleteGroups: Array<string>,
  deletebutton?: boolean,
  onReferenceError?: Function,
  sections?: Array<SectionType>,
  renderField?: FieldType => React$Element<*>,
  renderSection?: (SectionParams) => React$Element<*>,
}

type ComposeFormState = {
  fieldset: *,
  fields: Array<FieldType>,
  sections: Array<SectionType>,
}


export default class ComposeForm extends Component<ComposeFormProps, ComposeFormState> {

  state = {
    fieldset: null,
    fields: [],
  }

  constructor (props: *) {
    super(props)
    const data = this.resolveData(this.props.data || {})
    this.props.onChange({ data })
  }

  componentDidMount () {
    this.resolveFieldset()
  }

  getFieldset () {
    return this.state.fieldset
  }

  /**
   * Resolves the initial data based on field properties
   * @param  {Object} data The data to resolve
   * @return {Object}      The resolved data
   */
  resolveData (data: Object): Object {
    for (let field of this.props.fields) {
      if (field.parseValue || field.props && field.props.initialValue) {
        const value = this.resolveValue(field, field.props && field.props.initialValue, data)
        const updater = this.createUpdater(field.name, data)
        data = Object.assign(data, updater(value))
      }
    }
    return data
  }

  /**
   * Resolve the field set from the given url
   * @return {Promise} An empty promise
   */
  async resolveFieldset () {
    const url       = this.props.url
    const method    = this.props.method
    let fieldset    = await getFieldset(url, method, false, this.props.onReferenceError, this.props.fields)

    if (this.props.mapResolvedFieldset)
      fieldset = await this.props.mapResolvedFieldset(fieldset)

    const fields = []
    let key = 0
    for (let field of this.props.fields) {
      field.key = key++
      if (this.getComponent(field.name || '', fieldset.fields) || field.component)
        fields.push(field)
    }

    const resolveSections = () => {
      const renderSection = (section, key) => {
        const fieldData = this.state.fields.filter(field => section.fields.includes(field.name))
        const Fields = () => this.renderFields(fieldData)
        const Title = () => section.title || null
        const Footer = () => section.footer || null
        const props = section.props || null

        return { ...section, key, components: { Title, Fields, Footer, props },
        }
      }

      if (this.props.sections) {
        const sections = this.props.sections.map(renderSection)
        this.setState({ sections })
      }
    }

    this.setState({ fieldset, fields }, resolveSections)
  }

  render (): React$Element<*> {

    if (this.state.fieldset === null)
      return <Loader />

    // If sections prop is given, render each section as a card
    if (this.state.sections) {
      const renderSection = (section, key) => {

        return <Fragment key={ key }>
          {this.renderSection({ ...section.components, visibile: section.visible || true })}
        </Fragment>
      }

      return this.state.sections.map(renderSection)
    }

    const Fieldset = this.state.fieldset

    return <Fragment>

      { this.state.fields.length // This is true if fields array is given as a prop
        ? this.renderFields()
        : <Fieldset
          errors={ this.props.errors  }
          value={ this.props.data }
          onChange={ data => this.props.onChange({ data })}
        /> }
    </Fragment>
  }

  /**
   * Render the fields
   * @return {void} Udefiend
   */
  renderFields (fields: Array<FieldType>): React$Element<*> {
    let skip = false

    if (!fields)
      fields = this.state.fields

    const renderFn = this.props.renderField
      ? this.renderCustom
      : (field: FieldType) => {
        if (typeof field.component === 'function' && !field.normal) {
          skip = false
          return field.component(field.key, this.resolveValue(field))
        }
        if (skip && !field.fullwidth) {
          skip = false
          return null
        }
        if (field.singlerow) {
          return this.renderRow(field.key, false, true)
        }
        if (field.fullwidth) {
          return this.renderRow(field.key, true, false)
        }
        if (!skip && !field.fullwidth) {
          skip = true
          return this.renderRow(field.key, false, false)
        }
      }

    return fields.map(renderFn)
  }

  renderSection ({ Title, Fields, Footer, props, visibile }: SectionParams, index: number) {
    if (this.props.renderSection)
      return this.props.renderSection({ Title, Fields, Footer, props, visibile })
    return <Card key={ index }>
      <Title key={ `section.${index}.title` } />
      <Fields key={ `section.${index}.fields` } />
      <Footer key={ `section.${index}.footer` } />
    </Card>
  }

  /**
   * Get the field, component and initial value for the field in the specifie dindex
   * @param  {number} index           The index of the field to get the data for
   * @param  {boolean} skipFullWidth  Whether to skip a field if it is set to be full width. If true the component is set to undefined
   * @return {Object}                 An object with the field, component and initial value of a field
   */
  //eslint-disable-next-line complexity
  getFieldData (index: number, skipFullWidth?: boolean = false) {
    const field = this.state.fields.find(entry => entry.key === index) || undefined
    let Component = field
      ? this.getComponent(field.name)
      : undefined
    if (skipFullWidth && field && field.fullwidth)
      Component = undefined
    const fieldInitialValue = field

    return { field, Component, fieldInitialValue }
  }

  @self
  renderCustom (descriptor: FieldType) {
    const { Component, field } = this.getFieldData(descriptor.key)
    const renderedField = this.renderField(Component, field)
    return <Fragment key={ descriptor.key }>
      { this.props.renderField(renderedField) }
    </Fragment>
  }

  /**
   * Render a single row. Whether it's one full width field or two fields next to each other
   * @param  {number|string} key       The key to set to the rendered component
   * @param  {boolean}       fullwidth Specifies that the field should take the whole row
   * @param  {boolean}       singlerow When set to true the field takes a single row even if it's only half width
   * @return {*}                       The rendered component
   */
  renderRow (key: number, fullwidth: boolean = false, singlerow: boolean = false) {
    const field1 = this.getFieldData(key)

    if (singlerow)
      return this.renderSingleRow(key, field1.Component, field1.field)
    else if (fullwidth)
      return this.renderFullWidth(key, field1.Component, field1.field)
    else {
      const field2 = this.getFieldData(key + 1, true)
      return this.renderTwoColumns(key, field1.Component, field1.field,
        field2.Component, field2.field)
    }
  }

  /**
   * Renders a single field that takes up half a row
   * @param  {number|string} key       The key for the component
   * @param  {*}             Component The component to render
   * @param  {FieldType}     field     The field object that contains the name and errors for the field
   * @return {*}                       The rendered component
   */
  renderSingleRow (key: number, Component?: *, field?: FieldType) {
    return <div key={ key } className='row'>
      <div className='six columns' style={ field.floatRight ? {float: 'right'} : null }>
        { this.renderField(Component, field) }
      </div>
    </div>
  }

  /**
   * Render a row that takes the full width of the row
   * @param  {number|string} key       The key for the component
   * @param  {*}             Component The component to render
   * @param  {FieldType}     field     The field object that contains the name and errors for the field
   * @return {*}                       The rendered component
   */
  renderFullWidth (key: number, Component?: *, field?: FieldType) {
    return <div key={ key } className='row'>
      { this.renderField(Component, field) }
    </div>
  }


  /**
   * Render two fields on a single row
   * @param  {number|string} key        The key for the component
   * @param  {*}             Component  The component to render
   * @param  {FieldType}     field      The field object that contains the name and errors for the field
   * @param  {*}             Component2 The 2nd component to render
   * @param  {FieldType}     field2     The 2nd field object that contains the name and errors for the 2nd field
   * @return {*}                        The rendered component
   */
  //eslint-disable-next-line complexity
  renderTwoColumns (key: number, Component?: *,
    field?: FieldType, Component2?: *, field2?: FieldType) {
    return <div key={ key } className='row'>
      { field && Component && !field.normal
        ? <div className='six columns'>
          { this.renderField(Component, field) }
        </div>
        : field && field.normal && typeof field.component === 'function'
          ? field.component(key, this.resolveValue(field))
          : null
      }
      { field2 && Component2 && !field2.normal
        ? <div className='six columns'>
          { this.renderField(Component2, field2) }
        </div>
        : field2 && field2.normal && typeof field2.component === 'function'
          ? field2.component(key, this.resolveValue(field2))
          : null
      }
    </div>
  }

  /**
   * Resolves the fields component. Iterative function.
   * @param  {string|Array<string>} field   The name (string) or the path (Array<string>) of the field
   * @param  {Object}               current The Object where the field component needs to be found from
   * @return {Reac$Component|null}          The fields component or null if nothing was found
   */
  getComponent (field: string | Array<string>, current?: Object = this.state.fieldset.fields) {
    if (!field) return null
    if (is.string(field))
      return current[field]
    else if (field.length === 1)
      return current[field[0]]
    else if (field.length && current[field[0]])
      return this.getComponent(field.slice(1), current[field[0]].fields)
    return null
  }

  /**
   * Creates an updater function for a field
   * @param  {string|Array<string>} field    The name (string) or the path (Array<string>) of the field
   * @param  {*}                    baseData The initial value of the field or undefined
   * @return {Function}                      The updater function
   */
  createUpdater (field: string | Array<string> | undefined, baseData?: *) {
    return (value: *) => {
      const data = this.setValueToData(value, field, baseData)
      this.props.onChange({ data })
    }
  }

  /**
   * Updates the field value to the data object
   * @param {*}                     value The current value of the field
   * @param {string|Array<string>}  field The name (string) or the path (Array<string>) of the field
   * @param {Object|null|undefined} data  The data object where the value is updated to
   * @return {Object}                     The data with the updated field value
   */
  setValueToData (value: *, field: string | Array<string>, data?: Object | null = this.props.data) {
    if (!data) return
    if (is.string(field))
      data[field] = value
    else if (field.length === 1)
      data[field[0]] = value
    else {
      if (!data[field[0]])
        data[field[0]] = {}
      this.setValueToData(value, field.slice(1), data[field[0]])
    }
    return data
  }

  /**
   * Resolves the value for the given field
   * @param {string|Array<string>}  field        The name (string) or the path (Array<string>) of the field
   * @param  {*}                    initialValue The initial value of the field
   * @param  {*}                    data         The data object where the value is searched from
   * @return {*}                                 The value of the field if found or otherwise the initial value or undefined
   */
  resolveValue (field: FieldType, initialValue?: *, data?: *) {
    const value = this.getFieldValue(field.name, data)
    if (!value)
      return initialValue
    if (field.parseValue)
      return field.parseValue(value)
    return value
  }

  /**
   * Gets a fields value from the data object
   * @param {string|Array<string>}  field   The name (string) or the path (Array<string>) of the field
   * @param  {*}                    current The data object where the value is searched from
   * @return {*}                            The value of the field or undefined if not found
   */
  getFieldValue (field?: string | Array<string>, current?: Object = this.props.data) {
    if (!field || !current)
      return
    if (is.string(field))
      return current[field]
    else if (field.length === 1)
      return current[field[0]]
    else
      return this.getFieldValue(field.slice(1), current[field[0]])
  }

  /**
   * Gets the errors for a field
   * @param  {string|Array<string>}  field   The name (string) or the path (Array<string>) of the field
   * @param  {Object}                current The object where to look for the erros from
   * @return {string}                        The error of the field or null if no erros found
   */
  getErrors (field: string | Array<string>, current?: Object | null = this.props.errors) {
    if (!current) return null
    if (is.string(field))
      return current[field]
    else if (field.length === 1)
      return current[field[0]]
    else
      return this.getFieldValue(field.slice(1), current[field[0]])
  }

  renderField (Component, field) {
    if (!Component || !field)
      return null
    return <Component
      inputRef={ field.props?.innerRef || null }
      key={ field.name }
      onChange={ this.createUpdater(field.errors || field.name) }
      value={ this.resolveValue(field) }
      errors={ this.getErrors(field.errors || field.name) }
      { ...(field.props || {}) }
    />
  }
}
