// @flow
import type { Action } from '.'
import { UPDATE_SEARCH } from 'actions/packages'

export type PackagesState = {
  search: string | null,
}

export const initialValue: PackagesState = {
  search: null,
}


export default function packagesReducer (state: PackagesState, action: Action) {
  const { payload } = action
  switch (action.type) {

    case UPDATE_SEARCH:
      return { ...state, search: payload }

    default:
      return state || initialValue

  }
}
