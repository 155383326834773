/**
 * @flow
 * @class Card
 */
import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { resolveClass } from 'utils/resolve'

import type { Node } from 'react'


type BlockProps = {
  className?: string,
  elementId?: string,
  children: Node,
  id?: number,
  style?: Object,
  tabIndex?: number,
}


export default class Card extends PureComponent<BlockProps> {

  render () {

    let className = classNames(resolveClass(this.props, 'card'), this.props.className)

    return <div style={ this.props.style } className={ className } id={ this.props.id } tabIndex={ this.props.tabIndex }>
      {this.props.children}
    </div>
  }

}
