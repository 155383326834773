// @flow

import React from 'react'

import portrait_placeholder from '../img/portrait_placeholder.png'

import { __ } from 'utils/gettext'
import { resolveClass } from 'utils/resolve'


type PicturePropsType = {
  image?: *,
  small?: boolean,
  className?: string,
  circle?: boolean,
}


export default function Picture (props: PicturePropsType): React$Element<*> {
  const image = props.image
    ? props.image
    : portrait_placeholder
  const className = resolveClass(props)
  return <div className='image-container'>
    <img
      className={ className }
      src={ image }
      alt={ __('Users profile picture') } />
  </div>
}
