/**
 * @flow
 * @class DynamicHeightInput
 */

import self from 'autobind-decorator'
import React, { Component } from 'react'
import { calculateVerticalPaddingForElement, getDocumentLineHeight } from 'utils/dom'

type DynamicHeightInputProps = {
  id?: string,
  value?: string,
  disabled?: boolean,
  onChange?: Function,
  className?: string,
  required: boolean,
}

type DynamicHeightInputState = {
  value: string,
  lines: number,
  disabled: boolean,
}


export default class DynamicHeightInput extends Component<DynamicHeightInputProps, DynamicHeightInputState> {

  lineHeight: number = 1
  padding: number = 0

  static MINIMUM_LINES: number = 2

  constructor (props: DynamicHeightInputProps) {
    super(props)
    let value = props.value || ''
    let disabled = props.disabled || false

    this.state = {
      value,
      disabled,
      lines: getLinesCount(value),
    }

    this.updateLineHeight()
  }

  componentDidMount () {
    this.updateState(this.props)
  }

  UNSAFE_componentWillReceiveProps (props: DynamicHeightInputProps) {
    this.updateState(props)
  }

  updatePadding (node: Element) {
    this.padding = calculateVerticalPaddingForElement(node)
  }

  updateLineHeight () {
    this.lineHeight = getDocumentLineHeight()
  }

  @self
  update (value: string) {
    let lines = getLinesCount(value)
    this.setState({ value, lines }, () =>
      this.props.onChange && this.props.onChange(value))
  }

  get style (): Object {
    const height  = this.state.lines * this.lineHeight
    let minHeight = DynamicHeightInput.MINIMUM_LINES * this.lineHeight + this.padding

    return {
      overflow:   'hidden',
      resize:     'none',
      height:     height + this.padding + 'px',
      minHeight:  minHeight + 'px',
      lineHeight: this.lineHeight + 'px',
    }
  }

  render (): React$Element<*> {
    return <textarea
      aria-required={ this.props.required ? 'true' : undefined }
      id={ this.props.id }
      disabled={ this.state.disabled }
      value={ this.state.value }
      style={ this.style }
      ref={ ref => ref && this.updatePadding(ref) }
      onChange={ ({ target: { value }}) => this.update(value)}
      className={ this.props.className }
    />
  }

  updateState (props: DynamicHeightInputProps) {
    let updates = {}
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false
    if ('value' in props)
      updates.value = props.value
    if (typeof props.value === 'string')
      updates.lines = getLinesCount(props.value)
    if (Object.keys(updates).length)
      this.setState(updates)
  }
}

const getLinesCount = (text: string): number =>
  (text.match(/\n/g) || []).length + 1
