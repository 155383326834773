// @flow
import React, { useEffect } from 'react'
import { useFetch } from '../utils/useFetchHook'
// $FlowIgnore
import { useSelector } from 'react-redux'
import { ENDPOINT_URLS } from '../index'
import BookingErrorView from './BookingErrorView'

import isMobile from 'utils/isMobileHook'

import BookingCalendar from '../extra/BookingCalendar'
import withOnlineBookingModal from '../extra/OnlineBookingModal'

import { Spinner } from '@nordhealth/react'

import type { StateType } from 'store/initialState'
import type { OnlineBookingState as OnlineBooking } from 'reducers/onlineBooking'
import type { Node } from 'react'

const SelectAvailableDatesView = (): Node => {
  const isMobileView = isMobile(window)
  const onlineBooking: OnlineBooking = useSelector(
    (state: StateType) => state.onlineBooking
  )

  let params = {
    corporates: onlineBooking.selected_locations?.map((l) => l.id),
    treatments: onlineBooking.selected_service?.id,
    month: onlineBooking.calendar_state.selected_month,
    year: onlineBooking.calendar_state.selected_year,
  }

  useEffect(() => {
    const corporates = onlineBooking.selected_locations.map((l) => l.id)
    params = {
      corporates: corporates,
      treatments: onlineBooking.selected_service?.id,
      month: onlineBooking.calendar_state.selected_month,
      year: onlineBooking.calendar_state.selected_year,
    }
  }, [
    onlineBooking.calendar_state.selected_month,
    onlineBooking.calendar_state.selected_year,
    onlineBooking.selected_locations,
  ])

  const [
    availableDates,
    error,
    loading,
  ] = useFetch(ENDPOINT_URLS.AVAILABLE_DATES, 'get', params, [
    onlineBooking.calendar_state.selected_month,
    onlineBooking.calendar_state.selected_year,
  ])

  if (loading) return <Spinner size='xxl'></Spinner>

  if (error) {
    return <BookingErrorView error={error.data} />
  }

  return (
    <div className='booking-calendar'>
      {availableDates && (
        <BookingCalendar
          submit={isMobileView ? true : false}
          availableDates={availableDates}
          selectDateOnChange={isMobileView ? false : true}
          increaseStep
        />
      )}
    </div>
  )
}

export default (withOnlineBookingModal(SelectAvailableDatesView): *)
