// @flow
import { Card, Input as NordInput, Spinner, Stack } from '@nordhealth/react'
import api from 'api'
import { Button } from 'components/designSystemComponents'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import { __ } from 'utils/gettext'
import { useFetch } from '../utils/useFetchHook'
import useForm from '../utils/useForm'

import BookingErrorView from '../views/BookingErrorView'

import { EMAIL_VALIDATION_REGEX } from '../../../constants'

import type { Node } from 'react'
type Props = {
  url: string,
  submitButtonText: string,
  onSuccess?: Function,
  onError?: Function,
  dataToAppendToPayload: Object,
  className?: string,
  asCard?: boolean,
  renderExtras?: Function,
  onSave?: Function,
  defaultValues?: Object,
  submitDisabled?: boolean,
}

const ConstructForm = (props: Props): Node => {
  const shouldRequireEmail = (fetchedFields, onSave = props.onSave) => {
    // In case the checkbox for creating an account is set ->
    // We independently from what backend send us set the email field to
    // Be required in order to simplify further handling of the function calls
    if (!fetchedFields) return []
    return fetchedFields.map((field) => {
      if (field.type === 'email' && onSave) {
        return { ...field, required: true }
      }
      return field
    })
  }

  const defaultValues = props.defaultValues || {}

  const [fields, error, loading] = useFetch(
    props.url,
    'options',
    {},
    [props.onSave],
    false,
    shouldRequireEmail
  )

  // Comparison by the length of the array won't result in
  // unnecessary state update for the useForm hook
  const updateFormState = fields ? fields.length : 0

  const [formState, setFormState] = useForm(fields, defaultValues, [
    props.url,
    updateFormState,
  ])

  const [responseErrors, setResponseErrors] = useState<Object>({})
  const [checkOnSubmit, setCheckOnSubmit] = useState<number>(0)
  const [onSaveSucceeded, setOnSaveSucceeded] = useState<boolean>(false)
  const [forceLoading, setForceLoading] = useState<boolean>(false)

  // This can be commented out for easier testing
  if (
    props.dataToAppendToPayload &&
    Object.values(props.dataToAppendToPayload).includes(undefined)
  )
    return <BookingErrorView errorString={'Booking data missing'} />

  const onSuccess = (res: any, formState) => {
    // TODO: Add default handler?
    if (props.onSuccess) return props.onSuccess(res, formState)
  }

  const onError = (res: any) => {
    const validationErrors = formatResponseErrors(res.data)
    setResponseErrors(validationErrors)
    if (props.onError) return props.onError(res)
  }

  const handleSubmit = async (e: SyntheticEvent<*>) => {
    e.preventDefault()
    setForceLoading(true)
    checkFieldsForErrors()
    if (hasEmptyRequiredFields(fields, formState)) {
      setForceLoading(false)
      return
    }
    let payload = { ...formState }

    if (props.onSave && !onSaveSucceeded) {
      const res = await props.onSave(payload)
      if (!res.ok) {
        setForceLoading(false)
        return onError(res)
      } else {
        setOnSaveSucceeded(true)
      }
    }

    payload = appendBookingDataToPayload(payload, props.dataToAppendToPayload)
    const res = await api.post(props.url, payload)
    if (res.ok) {
      onSuccess(res, payload)
    } else {
      onError(res)
    }
    setForceLoading(false)
  }

  // The counter that is passed to the use effect of the input
  // component that will force to call field validation on
  // Individual level
  const checkFieldsForErrors = (): void => {
    setCheckOnSubmit((prevCount) => prevCount + 1)
  }

  const Wrapper = props.asCard ? Card : Fragment

  const expand = props.asCard ? false : true

  if (error) return <BookingErrorView error={error.data} />

  if (loading || forceLoading) return <Spinner size='xxl'></Spinner>

  return (
    <Fragment>
      <Wrapper>
        <div className={props.className ? props.className : undefined}>
          <form onSubmit={handleSubmit}>
            <Stack gap='l'>
              {fields &&
                fields.map((field: Field) => {
                  return (
                    // $FlowIgnore
                    <Input
                      key={field.name}
                      field={field}
                      formState={formState}
                      setFormState={setFormState}
                      checkOnSubmit={checkOnSubmit}
                      responseErrors={responseErrors}
                    />
                  )
                })}
            </Stack>
          </form>
          {props.renderExtras && props.renderExtras()}
        </div>
      </Wrapper>
      <div className='confirm'>
        <Button
          type='submit'
          size='l'
          expand={expand}
          onClick={handleSubmit}
          variant='primary'
          disabled={props.submitDisabled}
        >
          {props.submitButtonText}
        </Button>
      </div>
    </Fragment>
  )
}

type Field = {
  name: string,
  label: string,
  length: number,
  required: boolean,
  type: string,
  error: Array<Object>,
}

const appendBookingDataToPayload = (payload: Object, data: Object) => {
  return { ...payload, ...data }
}

type InputProps = {
  formState: Object,
  setFormState: Function,
  field: Field,
  checkOnSubmit: number,
  responseErrors: Object,
}

const Input = ({
  formState,
  setFormState,
  field,
  checkOnSubmit,
  responseErrors,
}: InputProps): Node => {
  const value = formState[field.name]
  const [error, setError] = useState<*>()
  const ref = useRef<null | (HTMLInputElement & { changeCallback: Function })>(
    null
  )
  const valid = Boolean(value)
  const _mounted = useRef(false)

  const onInput = (e: SyntheticEvent<*>) => {
    const input = e.target
    setFormState({
      ...formState,
      // $FlowIgnore
      [input.name]: input.value,
    })
  }

  const checkFieldValue = (e) => {
    if (e.target) {
      const { value, required } = e.target
      // Don't validate unrequired fields
      if (!value && !required) return
      if (!value && required) setError(__('This field is required'))
      if (Boolean(value) && field.type === 'email') {
        if (!checkEmailValue(value)) setError(__('Incorrect email'))
      }
    }
  }

  useEffect(() => {
    if (_mounted.current) {
      const val = formState[field.name]
      if (!Boolean(val) && field.required) {
        setError(__('This field is required'))
      }
    }
  }, [checkOnSubmit])

  useEffect(() => {
    if (!responseErrors) return
    if (responseErrors.hasOwnProperty(field.name)) {
      setError(responseErrors[field.name])
    } else {
      setError(undefined)
    }
  }, [responseErrors])

  const checkEmailValue = (emailInput): boolean => {
    return EMAIL_VALIDATION_REGEX.test(emailInput)
  }

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('focus', () => setError(undefined))
      // $FlowIgnore
      ref.current.addEventListener('blur', checkFieldValue)
      _mounted.current = true
    }

    return () => {
      if (ref && ref.current) {
        // $FlowIgnore
        ref.current.removeEventListener('focus', null)
        // $FlowIgnore
        ref.current.removeEventListener('blue', null)
        _mounted.current = false
      }
    }
  }, [])

  useEffect(() => {
    if (valid) {
      setError(undefined)
    }
  }, [valid])

  const translatedLabel = Object.prototype.hasOwnProperty.call(
    DIARIUM_FIELDS_MAP,
    field.label
  )
    ? __(DIARIUM_FIELDS_MAP[field.label])
    : field.label

  const label = field.required ? translatedLabel + ' *' : translatedLabel

  return (
    <NordInput
      label={label}
      type={field.type}
      name={field.name}
      required={field.required}
      error={error}
      onInput={onInput}
      ref={ref}
      expand={true}
      value={formState[field.name]}
    />
  )
}

// TODO: Currently this formatting is too hardcoded and
// specific to response returned from Diarium
// In futer maybe it would have been a better idea to
// return at least majority of errors from backend in same format
// However, if deviation of format is not significant -
// formatting could be done by frontend only.

// Object should be in format: fieldName: fieldError
const formatResponseErrors = (responseData: any): Object => {
  if (Array.isArray(responseData)) {
    const error = responseData[0]
    if (error.hasOwnProperty('validationErrors')) {
      return { ...error.validationErrors }
    }
    return error
  }
}

const hasEmptyRequiredFields = (fields, formState) => {
  let isEmpty = false

  const requiredFieldNames = fields
    .filter((field) => field.required)
    .map((field) => field.name)

  requiredFieldNames.forEach((name) => {
    if (formState[name] === '' || !formState[name]) {
      isEmpty = true
    }
  })
  return isEmpty
}

// Diarium returns fields in finnish so map appropriate translations
// for the 'hard coded' fields. Any custom fields can't be translated
// but we can expect these fields to always be there
export const DIARIUM_FIELDS_MAP = {
  Sähköpostiosoite: 'Email',
  Etunimi: 'First name',
  Sukunimi: 'Last name',
  Henkilötunnus: 'Social security number',
  Puhelinnumero: 'Phone number',
  Katuosoite: 'Street address',
  Postinumero: 'Postal code',
  Postitoimipaikka: 'City',
}

export default ConstructForm
