// @flow
// @flow-runtime

type FormDescriptionParameters = {
  method: api.MethodType,
  fields: api.ActionsType,
}

type FieldDescriptionParameters = {
  key: string,
  field: api.FieldType
}


const DEFAULT_PARAMETERS: FormDescriptionParameters = {
  method: 'POST',
  fields: {},
}


class FieldDescription<ValueType = string> {
  _value: ValueType
  properties: api.FieldType

  constructor (data: FieldDescriptionParameters) {
    this.properties = { ...data.field, key: data.key }
  }

  get key (): string {
    return this.properties.key
  }

  get value (): ValueType {
    return this._value
  }

  update (newValue: ValueType) {
    this._value = newValue
  }

  isRequired (): boolean {
    return this.properties.required || false
  }

  isReadOnly (): boolean {
    return this.properties.read_only || false
  }

  static matches (key: string): (field: FieldDescription<any>) => boolean {
    return (field: FieldDescription<any>): boolean => field.key === key
  }
}


class FormDescription {

  method: api.MethodType
  fields: Set<FieldDescription<any>>

  static resolve (response: api.ResponseType): FormDescription | null {
    const descriptors = FormDescription.resolveAll(response)
    return descriptors && descriptors.size
      ? [ ...descriptors ][0]
      : null
  }

  static resolveAll (response: api.ResponseType): Set<FormDescription> | null {
    const descriptors = new Set()
    if (response.problem)
      return null

    for (let [ method, fields ] of Object.entries(response.data.actions))
      if (typeof fields === 'object' && fields)
        descriptors.add(new FormDescription({ method, fields }))
      else
        throw new TypeError(`Invalid response data for ${method} action`)

    return descriptors
  }

  constructor (data: FormDescriptionParameters = DEFAULT_PARAMETERS) {
    this.method = data.method
    this.fields = reduceActionsToFields(data.fields)
  }

  getField (key: string): FieldDescription<any> | null {
    const match = FieldDescription.matches(key)
    return Array
      .from(this.fields)
      .find(match) || null
  }

}


function reduceActionsToFields (actionsData: api.ActionsType): Set<FieldDescription<any>> {
  const collection = new Set()
  for (let [ key, field ] of Object.entries(actionsData))
    collection.add(new FieldDescription({ key, field }))
  return collection
}


export default (responseOrData: api.ResponseType | Object): Set<FormDescription> | null => {
  if (!responseOrData)
    return null

  if (responseOrData instanceof FormDescription)
    return responseOrData

  if (responseOrData.method)
    return new Set([ new FormDescription(responseOrData) ])

  return FormDescription.resolveAll(responseOrData)
}
