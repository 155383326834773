// @flow
/* eslint-disable class-methods-use-this */
import history from 'store/history'
import qs from 'querystring'

export const replace: Function             = history.replace.bind(history)
export const previous: Function            = history.goBack.bind(history)
export const next: Function                = history.goForward.bind(history)
export const onDidChangeLocation: Function = history.onDidChangeLocation

export function backToList (pathname: string, search?: string) {
  history.replace({
    pathname,
    search
  })
}

export function navigate (pathname: string, params: ?Object = null, state: Object = {}): Function {
  const search = `?${qs.stringify(params)}`
  // Reset focus to root element on navigation
  let focusable = document.querySelectorAll('#empty', '[tabindex]:not([tabindex="-1"])')
  if (focusable && focusable.length > 0)
    focusable[0].focus()
  if (is.string(pathname) && pathname.startsWith('?'))
    return history.push(pathname)
  return history.push({
    pathname,
    search,
    state
  })
}

export function openURL (url: string, noFocus?: boolean = false) {
  if (!is.string(url))
    return
  var win = window.open(url, '_blank')
  if (!noFocus && win && typeof win.focus === 'function')
    win.focus()
}


export function matchLocation (url: string): Function {
  const current = navigator.url
  return current.startsWith(url)
}


class Navigator {
  next                = next
  openURL             = openURL
  replace             = replace
  navigate            = navigate
  previous            = previous
  backToList          = backToList
  onDidChangeLocation = onDidChangeLocation

  set url (url: string): Function {
    return navigate(url)
  }

  get url () {
    return history.location.pathname
  }

  set query (params: *): Function {
    return navigate('?' + qs.stringify(params))
  }

  get query () {
    return history.query
  }

  get location () {
    return history.currentLocation
  }
}

const navigator = new Navigator()

export default navigator
