// @flow
// Endpoints are routes and views that do not display a page but are used e.g. for redirecting
// See external_auth for an example
import external_auth from './external-auth'

// All endpoints that should be accessible whether the user is or isn't logged in should be put here
const generalEndpoints = [
  external_auth,
]

export default {
  preLogin: [
    ...generalEndpoints
  ],
  postLogin: [
    ...generalEndpoints
  ],
}
