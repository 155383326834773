// @flow
import type { Action } from '../reducers'
import type { TenantSettingsState } from '../reducers/tenant'

export const UPDATE_TENANT_SETTINGS = 'UPDATE_TENANT_SETTINGS'


export const updateTenantSettings = (settings: TenantSettingsState): Action => ({
  type: UPDATE_TENANT_SETTINGS,
  payload: settings
})
