/**
 * @flow
 * @class BaseInput
 */

import self from 'autobind-decorator'
import React, { PureComponent } from 'react'

type BaseInputProps = {
  type: string,
  name: string,
  value: any,
  onChange: Function,
  attributes?: Object,
  className?: string,
  disabled?: boolean
}

export default class BaseInput<V, Props: BaseInputProps & { value: V }> extends PureComponent<Props> {

  constructor (props: Props) {
    super (props)
    if (typeof this.props.onChange !== 'function')
      throw new TypeError(`Invalid onChange handler in BaseInput component`)
  }

  @self
  handleChange (event: Event) {
    this.props.onChange(event)
  }

  get attributes (): * {
    return this.props.attributes || {}
  }

  render () {
    return <input
      id={this.props.name}
      onChange={ this.handleChange }
      value={ this.props.value || '' }
      type={ this.props.type }
      name={ this.props.name }
      className={ this.props.className }
      disabled={ this.props.disabled }
      { ...this.attributes }
    />
  }
}
