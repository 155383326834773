/**
 * @module fieldsets
 * @name   Fieldsets
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import Form from './components/Form'
import Fieldset, { Deferred } from './base'
import constructFieldsetFromApiResponse from './base/constructFromApiResponse'
import { registerRenderer, clearRenderers } from './base/renderers'


export default Object.assign(

  constructFieldsetFromApiResponse,

  {
    registerRenderer,
    clearRenderers,
    Fieldset,
    Deferred,
    Form,
  }
)

export {
  registerRenderer,
  clearRenderers,
  Fieldset,
  Deferred,
  Form,
}
