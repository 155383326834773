
import React, { Fragment } from 'react'

import routes from 'routes/public/announcements'


export default () =>
  <Fragment>
    { routes }
  </Fragment>
