import React, { Component, createRef } from 'react'

import tooltips from 'tooltips'
import { Badge } from 'components/generic'

import type { List } from 'immutable'


type LinkType = {
  id?:         number,
  url:         string,
  description: string,
}

type LinksProps = {
  links:       List<LinkType> | Array<LinkType>,
  onDelete?:   Function,
  tooltip?:    boolean,
}


export default class Links extends Component<LinksProps> {

  onDelete (link: LinkType) {
    if (this.props.onDelete)
      return () => this.props.onDelete(link)
    return null
  }

  render () {
    let index: number = 0
    if (this.hasLinks)
      return <div className='full-width'>
        { this.props.links.map(link => <Link
          key={ index++ }
          url={ link.url }
          tooltip={ this.props.tooltip }
          description={ link.description }
          onDelete={ this.onDelete(link) } />
        )}
      </div>
    return null
  }

  get hasLinks (): boolean {
    return this.props.links && (parseInt(this.props.links.size) > 0 || this.props.links.length > 0)
  }
}


type LinkProps = {
  url: string,
  description: string,
  onDelete: Function,
  tooltip?: boolean,
}


export class Link extends Component<LinkProps> {

  element = createRef()

  get tooltip (): string {
    return this.props.url
  }

  componentDidMount () {
    if (this.props.tooltip)
      tooltips.add(this.element.current, this.tooltip)
  }

  componentWillUnmount () {
    if (this.props.tooltip)
      tooltips.remove(this.element.current, this.tooltip)
  }

  render () {
    return <Badge onRemove={ this.props.onDelete } >
      <a
        ref={ this.element }
        className='link linked-item-entry'
        href={ this.props.url }
        target='_blank'
        rel='noopener noreferrer' >
        { this.props.description }
      </a>
    </Badge>
  }

}
