/**
 * @module SelectPlanField
 * @author Mikko Maja<mikko.maja@fns.fi>
 * @flow
 */

import React, { Component, Fragment } from 'react'
import api from 'api'
import self from 'autobind-decorator'
import connect from 'bound-connect'
import { formatDate } from 'utils/formatters'
import { __ } from 'utils/gettext'
import { resolveError } from 'utils/resolve'
import { addAlert } from 'actions/core'

import trackMobile from 'components/TrackWidthDecorator'

import { CancelButton, PrimaryButton } from 'components/buttons'
import { Alert, ToolbarContainer, Toolbar } from 'components/generic'
import { ChoiceField } from 'components/fields'
import Text, { Sub, Title, Paragraph, Strong } from 'components/text'
import Modal from 'views/Page/Modal'

import type { StateType } from 'store/initialState'
import {
  SUBSCRIPTION_TYPE_STANDARD,
  SUBSCRIPTION_TYPE_PRO,
  SUBSCRIPTION_TYPE_ONLINE_BOOKING,
} from 'constants'

import type { Node } from 'react'

export type PropTypes = {
  userId: number | null,
  type: string | null,
  addSuccessMessage: Function,
  addErrorMessage: Function,
  state: StateType,
  isMobile?: boolean,
  onlineBookingEnabled: boolean,
}

type SubscriptionOption = {
  display_name: string,
  value: string,
}
export type StateTypes = {
  loading: boolean,
  currentUserType: string | null,
  currentSubscriptionGroup: string | null,
  endDate: string,
  isDowngraded: boolean,
  modalSize: { w: string, h: string },
  visible: boolean,
  userDisabled: boolean,
  selectedOptionValue: string,
  availableSubscriptionOptions: SubscriptionOption[],
}

const MAX_DATE = '9999-12-31'

const SUBSCRIPTION_CHOICES: SubscriptionOption[] = [
  { display_name: 'Online booking', value: SUBSCRIPTION_TYPE_ONLINE_BOOKING },
  { display_name: 'Standard', value: SUBSCRIPTION_TYPE_STANDARD },
  { display_name: 'Pro', value: SUBSCRIPTION_TYPE_PRO },
]

const SUBSCRIPTION_VALUES = {
  [SUBSCRIPTION_TYPE_ONLINE_BOOKING]: 0,
  [SUBSCRIPTION_TYPE_STANDARD]: 10,
  [SUBSCRIPTION_TYPE_PRO]: 20,
}

@trackMobile
@connect
export default class SelectPlanField extends Component<PropTypes, StateTypes> {
  static actions: * = (dispatch: Function) => ({
    addSuccessMessage: (content: string) =>
      dispatch(addAlert('success', content)),
    addErrorMessage: (content: string) => dispatch(addAlert('error', content)),
  })

  static properties: * = (state: StateType) => ({
    onlineBookingEnabled: state.tenantSettings.use_online_booking,
  })

  modalMobileSize: Object = {
    w: '100%',
    h: '100%',
  }

  modalSize: Object = {
    w: '600px',
    h: '510px',
  }

  state: StateTypes = {
    loading: true,
    currentUserType: null,
    currentSubscriptionGroup: null,
    endDate: '',
    isDowngraded: false,
    modalSize: this.props.isMobile ? this.modalMobileSize : this.modalSize,
    visible: false,
    userDisabled: false,
    selectedOptionValue: '',
    availableSubscriptionOptions: [],
  }

  async componentDidMount() {
    this.getCurrentPlan()
    this.setState({ loading: false })
  }

  getSelectedOption() {
    // Filter out already selected subscription
    let filteredOptions = SUBSCRIPTION_CHOICES.filter(
      (option) => option.value !== this.state.currentUserType
    )
    if (!this.props.onlineBookingEnabled) {
      filteredOptions = filteredOptions.filter(
        (option) => option.value !== SUBSCRIPTION_TYPE_ONLINE_BOOKING
      )
    }
    const selectedOptionValue = filteredOptions[0].value
    this.setState({
      selectedOptionValue,
      availableSubscriptionOptions: filteredOptions,
    })
  }

  // eslint-disable-next-line complexity
  async getCurrentPlan() {
    if (this.props.userId) {
      const response = await api.get(`/staff/${this.props.userId}/`)
      if (!response.data.profile.active_subscription) return
      const currentSubscriptionGroup =
        response.data.profile.active_subscription.group
      const currentUserType = response.data.profile.active_subscription?.type
      const endDate = response.data.profile.active_subscription.end
      const isDowngraded = endDate === MAX_DATE ? false : true
      const userDisabled = response.data.status === 3 ? false : true
      if (currentSubscriptionGroup && currentUserType && endDate) {
        this.setState(
          {
            currentSubscriptionGroup,
            currentUserType,
            endDate,
            isDowngraded,
            userDisabled,
          },
          () => {
            this.getSelectedOption()
          }
        )
      }
    }
  }

  async updateUserPlan(value: string) {
    if (this.props.userId) {
      this.setState({ loading: true })
      const updatedPlan = this.state.selectedOptionValue || value
      if (
        SUBSCRIPTION_VALUES[updatedPlan] >
        // $FlowIgnore
        SUBSCRIPTION_VALUES[this.state.currentUserType]
      ) {
        this.setState({ currentUserType: updatedPlan })
      }
      const type = { type: updatedPlan }
      const response = await api.post(
        // $FlowIgnore
        `/staff/${this.props.userId}/subscribe/`,
        type
      )
      if (response.ok) {
        this.setState({ loading: false })
        this.props.addSuccessMessage('The users plan was updated successfully!')
        this.onCancel()
        return
      }
      this.setState({ loading: false })
      this.props.addErrorMessage(resolveError(response.status))
      this.onCancel()
    }
  }

  getPlanStr(): string {
    if (!this.state.currentUserType) return 'No active plan'
    const currentUserPlanStr =
      this.state.currentUserType === SUBSCRIPTION_TYPE_STANDARD
        ? 'Standard'
        : this.state.currentUserType === SUBSCRIPTION_TYPE_PRO
        ? 'PRO'
        : 'Online booking'
    return currentUserPlanStr
  }

  get endDate(): string {
    const endDate = new Date(this.state.endDate)
    return formatDate(endDate, 'DD.MM.YYYY')
  }

  get changePlanButtonDisabled(): boolean {
    return !this.state.currentUserType
  }

  @self
  onCancel() {
    this.setState({ visible: false })
  }

  @self
  renderPlanChangeInfo(): Node {
    const supportLink = (
      <a
        href='https://navisechealth.fi'
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        navisechealth.fi{' '}
      </a>
    )
    const supportEmailLink = (
      <a href='mailto:health@navisec.fi' target='_top'>
        {' '}
        health@navisec.fi{' '}
      </a>
    )
    return (
      <div className='change-plan-modal'>
        <Paragraph>
          If you’re sure you would like to change this user's subscription,
          select plan and click "Change plan" below. The upgrade will be
          effective immediately.<span notranslate='true'>&nbsp;</span>
        </Paragraph>

        <Alert persistent info>
          If you later want to downgrade this user's plan, the changes will take
          effect at the beginning of the next cycle.
        </Alert>

        <Paragraph className='details'>
          {'More details of features and pricing:'}
          {supportLink}
          {'or'}
          {supportEmailLink}
        </Paragraph>

        <Paragraph>
          <Text>Select plan</Text>
        </Paragraph>

        <div className='choice-field'>
          <ChoiceField
            value={this.state.selectedOptionValue}
            choices={this.state.availableSubscriptionOptions}
            onChange={this.onChoiceChange}
          />
        </div>
      </div>
    )
  }

  renderModal(): Node | void {
    const titleText = 'Change plan'
    if (!this.state.loading) {
      return (
        <Modal
          width={this.state.modalSize.w}
          height={this.state.modalSize.h}
          effect='fadeInUp'
          onClickAway={this.onCancel}
          visible={this.state.visible}
          header={<Title>{titleText}</Title>}
        >
          <div style={{ margin: '0 20px' }}>
            {this.renderPlanChangeInfo()}
            {this.renderModalFooter()}
          </div>
        </Modal>
      )
    }
  }

  renderModalFooter(): Node {
    let confirmText = 'Change plan'
    let plan = this.state.selectedOptionValue
    return (
      <div className={!this.props.isMobile && 'change-plan-modal-footer'}>
        <ToolbarContainer>
          <Toolbar left>
            <PrimaryButton onClick={() => this.updateUserPlan(plan)}>
              {confirmText}
            </PrimaryButton>
            <CancelButton onClick={this.onCancel} />
          </Toolbar>
        </ToolbarContainer>
      </div>
    )
  }

  @self
  onChoiceChange(value: string) {
    this.setState({ selectedOptionValue: value, visible: true })
  }

  renderField(): Node {
    return (
      <>
        <div className='row'>
          <Sub>
            Current plan
            <span notranslate='true'>
              :<Strong notranslate> {this.getPlanStr()}</Strong>
            </span>
          </Sub>
        </div>
        <div className='row'>
          <PrimaryButton
            onClick={() => this.setState({ visible: true })}
            disabled={this.changePlanButtonDisabled}
          >
            Change plan
          </PrimaryButton>
          {this.renderModal()}
        </div>
      </>
    )
  }

  render(): Node {
    if (this.props.userId) {
      return (
        <Fragment>
          <div className='six columns highlight'>
            <label className='label field-label'>
              <Text>Plan</Text>
            </label>
            {this.renderField()}
          </div>
        </Fragment>
      )
    } else return null
  }
}
