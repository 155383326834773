/**
 * @flow
 * @class ListView
 */

import self from 'autobind-decorator'
import React, { PureComponent } from 'react'
import classNames from 'classnames'
import api from 'api'
import is from '@sindresorhus/is'
import { withRouter } from 'react-router-dom'

import navigator from 'routes/base'

import * as EventHandler from 'utils/events'
import { isEqual } from 'utils/resolve'
import { CancelablePromise } from 'utils/promise'

import { EVENT_TYPES } from 'constants'

import Pagination from 'components/List/Pagination'
import { Loader } from 'components/generic'
import ListItem from 'components/List/ListRow'
import type { RowProps } from 'components/List/ListRow'
import GridView from 'components/GridView'
import Text from 'components/text'

import type { Node } from 'react'

type ItemsListType = Array<RowProps>

type ResponsePaginationDataType = {
  next: string | null,
  previous: string | null,
}

type ResponseDataType = ResponsePaginationDataType & {
  count: number,
  results: ItemsListType,
}

type CacheEntryType = {
  items: ItemsListType,
  currentPage: number,
  responseData: ResponsePaginationDataType,
}

type GridViewPropsType = {
  mapToGridItem: Function,
  itemWidth: string,
  maxWidth: string,
  minWidth: string,
}

export type FilterValueType = Array<string | number> | string | number

export type FiltersType = Array<{
  key: number,
  name: string,
  value: FilterValueType,
}> | Object

export type ListViewProps = {
  url: string,
  items: ItemsListType,
  mapItemToRow: Function,
  mapUpdatedItemsToRow?: Function,
  fetchData?: Function,
  action?: Function,
  className?: string,
  paddingClassName?: string,
  emptyListContent?: Node,
  filters?: FiltersType,
  setListComponent?: Function,
  payload?: string,
  onLoad?: Function,
  gridview?: boolean,
  gridviewProps?: GridViewPropsType,
  rowsPerPage?: number,
  method?: string,
  userType?: String,
  setRef?: Function,
} & RouteProps

type CachedDataMapType = Map<string, CacheEntryType>

type ListViewState = {
  count: number,
  items: ItemsListType,
  filters: Array<FiltersType>,
  responseData: ResponsePaginationDataType,
  loading: boolean,
  currentPage: number,
}

type PageRequest = CancelablePromise<api.ResponseType<*>> | null


@withRouter
export default class ListView extends PureComponent<ListViewProps, ListViewState> {

  requests: {
    next: PageRequest,
    current: PageRequest,
    previous: PageRequest,
  } = {
    next: null,
    current: null,
    previous: null,
  }

  static MAX_ROWS_PER_PAGE: number = 10
  static defaultProps: Object = {
    className: '',
    paddingClassName: 'padding-small',
    emptyListContent: 'No items to list',
  }

  itemsByPage: CachedDataMapType = new Map()
  currentTab: string | null = null
  _isMounted: boolean
  state: ListViewState = {
    filters: [],
    items: [],
    count: 0,
    loading: true,
    currentPage: 1,
    responseData: {
      next: null,
      previous: null,
    },
  }

  constructor (props: ListViewProps) {
    super(props)
    if (props.gridview && !props.gridviewProps)
      throw new TypeError(`ListView: gridviewProps can not be of type ${typeof props.gridviewProps} if gridview props is set to true.`)
    this._isMounted = false
  }

  get maxRowsPerPage (): number {
    return this.props.rowsPerPage || ListView.MAX_ROWS_PER_PAGE
  }

  get rowCount (): number {
    return Math.max(this.state.items.length, this.maxRowsPerPage)
  }

  get pageCount (): number {
    const { count } = this.state
    if (is.number(count))
      return Math.ceil(count / this.rowCount)
    return 1
  }

  componentDidMount () {
    this._isMounted = true
    EventHandler.subscribeToEvent(EVENT_TYPES.LIST_UPDATE, this.refreshList)
    if (this.props.url && typeof this.props.url === 'string') {
      const page = parseInt(navigator.query.page)
      const currentPage = is.nan(page) ? 1 : page
      this.fetchData(this.props.url, currentPage)
    }
    else if (is.array(this.props.items)) {
      const count = this.props.items.length
      this.setState({
        count,
        items: this.props.items,
        loading: false
      })
    }

    if (this.props.setListComponent)
      this.props.setListComponent(this)

    if (this.props.setRef) this.props.setRef(this)
  }

  componentWillUnmount () {
    EventHandler.unsubscribeFromEvent(EVENT_TYPES.LIST_UPDATE, this.refreshList)
    const cancel = req =>
      req !== null && req.cancel()

    Object
      .values(this.requests)
      .forEach(cancel)

    this._isMounted = false

    if (this.props.setRef) this.props.setRef(undefined)
  }


  componentDidUpdate (prevProps: ListViewProps) {
    const filtersChanged = !isEqual(prevProps.filters, this.props.filters)
    const urlChanged = this.props.url !== prevProps.url
    const tabChanged = this.tabHasChanged()

    if (filtersChanged || tabChanged)
      this.invalidateCache()

    if (!prevProps.items && this.props.items) {
      this.setState({ items: this.props.items, loading: false })
    }

    if ( urlChanged || filtersChanged || tabChanged) {
      this.fetchData(this.props.url, this.state.currentPage)
    }
  }

  @self
  tabHasChanged (): boolean {
    const tab = navigator.query.tab || this.props.match.params.tab
    if (!this.currentTab)
      this.currentTab = tab
    else if (tab !== this.currentTab) {
      this.currentTab = tab
      return true
    }
    return false
  }

  get method (): string {
    // On some rare cases the list is retrieved with some other method than get
    // for example when the request parameters contain sensitive information it is
    // better to use POST method. Pass custom HTTP request method in ListView props
    // if needed.
    return this.props.method || 'get'
  }

  get filters (): Object {
    return toObject(this.props.filters, 'name', 'value')
  }

  @self
  refreshList () {
    this.invalidateCache()
    this.fetchData(this.props.url || '', this.state.currentPage)
  }

  invalidateCache () {
    this.itemsByPage = new Map()
  }

  hasCachedDataForPage (page: string): boolean {
    return this.itemsByPage.has(page)
  }

  async applyCachedData (currentPage: string): Promise<*> {

    if (!this.hasCachedDataForPage(currentPage))
      return false

    const cached = this.itemsByPage.get(currentPage)

    await new Promise(resolve => this.setState(cached, resolve))
    this.prefetchData()
    return true
  }

  async fetchData (url: string, page: number) {
    const hasCached = await this.applyCachedData(`${url}-${page}`)

    if (!hasCached && this._isMounted)
      this.setState({ loading: true }, () => {
        this.requests.current = fetchPage(url, page, this.filters, this.props.payload, this.maxRowsPerPage, this.method)
        this.init(page)
      })
  }

  applyData (currentPage: number, data: ResponseDataType): Promise<*> {
    const { results: items, count, ...responseData } = data
    let state = {
      loading: false,
      responseData,
      currentPage,
      count,
      items,
    }

    this.storeCachedData(`${this.props.url}-${currentPage}`, {
      responseData,
      currentPage,
      items,
    })

    return new Promise(resolve => this.setState(state, resolve))
  }

  storeCachedData (index: string, data: CacheEntryType) {
    this.itemsByPage.set(index, data)
  }

  // eslint-disable-next-line max-statements, complexity
  prefetchData () {
    const page          = this.state.currentPage
    const nextUrl       = this.nextPageUrl
    const previousUrl   = this.previousPageUrl
    const nextIndex     = page + 1
    const previousIndex = page - 1

    const onSuccess = (currentPage, response) => {
      const { results: items, ...responseData } = response.data

      this.storeCachedData(`${this.props.url}-${currentPage}`, {
        responseData,
        currentPage,
        items,
      })
    }

    const onError           = () => null
    const onNextSuccess     = res => onSuccess(nextIndex, res)
    const onPreviousSuccess = res => onSuccess(previousIndex, res)


    if (previousIndex > 0 && previousUrl && !this.hasCachedDataForPage(previousIndex)) {
      this.requests.previous  = fetchPage(previousUrl, previousIndex, {}, {}, this.maxRowsPerPage, this.method)
      this.requests.previous
        .then(onPreviousSuccess)
        .catch(onError)

    }
    if (nextIndex <= this.pageCount && nextUrl && !this.hasCachedDataForPage(nextIndex)) {
      this.requests.next = fetchPage(nextUrl, nextIndex, {}, {}, this.maxRowsPerPage, this.method)
      this.requests.next
        .then(onNextSuccess)
        .catch(onError)
    }
  }

  init (currentPage: number) {

    const onSuccess = async response => {
      const { data, status } = response

      if (status === 404)
        if (currentPage === 1)
          throw new Error(`Invalid url in list view ${this.props.url}`)
        else
          return await this.fetchData(this.props.url, 1)

      await this.applyData(currentPage, data)
      // this.prefetchData()

      if (typeof this.props.onLoad === 'function')
        this.props.onLoad()

      if (typeof this.props.action === 'function')
        return await this.props.action(data.results)
    }

    const onRejected = err =>
      !err.isCanceled &&
      this.setState({ loading: false }, () => onError(err))

    this.requests.current && this.requests.current
      .then(onSuccess)
      .catch(onRejected)
  }

  // eslint-disable-next-line complexity
  render (): React$Element<*> {

    const className = classNames(
      'list unselectable',
      this.props.paddingClassName,
      this.props.className)

    let ListPage
    let items = this.state.items

    if (!items || !items.length)
      ListPage = () => this.renderEmpty()
    else if (!this.props.gridview)
      ListPage = () => this.renderPage()
    else
      ListPage = ({ items = []}) =>
        <GridView { ...this.props.gridviewProps } items={ items } />

    if (this.state.loading)
      return <Loader />
    else {
      return <section className={ className }>

        <ListPage items={ this.state.items } />

        <Pagination
          count={ this.pageCount }
          current={ this.state.currentPage }
          onChange={ this.toPage } />

      </section>
    }
  }

  renderPage (): Node {
    if (!this.state.loading && this.state.items)
      return this.state.items.map(this.renderRow)
    return null
  }

  @self
  onListRowClick (pathname: string) {
    const userType = this.props.userType
      ? this.props.userType
      : null
    if (pathname)
      navigator.navigate(pathname, null, { listSearchParams: this.props.history.location.search, type: userType })
  }

  @self
  renderRow (item: RowProps, key: number): Node {
    return <ListItem
      key={ key }
      onClick={ this.onListRowClick }
      refreshList={ this.refreshList }
      mapItemToRow={ this.props.mapItemToRow }
      mapUpdatedItemsToRow={ this.props.mapUpdatedItemsToRow || undefined }
      fetchData={ this.props.fetchData || undefined }
      item={ item }
    />
  }

  get nextPageUrl (): string | null {

    // return `${this.props.url}?page=${this.state.currentPage}`
    if (!this.state.responseData)
      return null

    if (!this.state.responseData.next)
      return null

    return this.state.responseData.next.split('/api/')[1]
  }

  get previousPageUrl (): string | null {

    // return `${this.props.url}?page=${this.state.currentPage}`
    if (!this.state.responseData)
      return null

    if (!this.state.responseData.previous)
      return null

    return this.state.responseData.previous.split('/api/')[1]
  }

  @self
  nextPage () {
    const page = this.state.currentPage + 1
    const url  = this.nextPageUrl

    if (url !== null)
      this.fetchData(url, page)
  }

  @self
  previousPage () {
    const page = this.state.currentPage - 1
    const url  = this.previousPageUrl

    if (url !== null)
      this.fetchData(url, page)
  }

  @self
  toPage (pageNumber: number) {
    if (pageNumber < 0 || pageNumber > this.pageCount)
      return

    switch (pageNumber) {
      case this.state.currentPage - 1:
        this.previousPage()
        break

      case this.state.currentPage + 1:
        this.nextPage()
        break

      default:
        this.fetchData(this.props.url, pageNumber)
    }

    this.saveCurrentPage(pageNumber)
  }

  saveCurrentPage (page: number) {
    this.setState({ currentPage: page }, () =>
      navigator.query = buildSearchParams(page))
  }

  renderEmpty (): Node {
    const errors    = this.state.responseData && this.state.responseData.detail
    const content   = errors || this.props.emptyListContent || null
    const className = classNames('empty-list-content', { 'color-error': errors })

    return <span className={ className }>
      <Text>{ content }</Text>
    </span>
  }

}


function buildSearchParams (page: number): Object {
  let searchParams = navigator.query
  if ( searchParams )
    searchParams.page = page
  else
    searchParams = { page }
  return searchParams
}


function toObject (arr = [], keyAttr = 'key', valAttr = 'value') {
  const data = {}

  if (!arr)
    return {}

  if (!is.array(arr))
    return arr

  for (let entry of arr) {
    const key = entry[keyAttr]
    const val = entry[valAttr]
    data[key] = val
  }
  return data
}


function fetchPage (url: string | null, page: number | null = null, filters: Object = {},
  payload: Object = {}, rowsPerPage: number, method: string): CancelablePromise<api.ResponseType<*>> {
  if (url === null)
    return new CancelablePromise(Promise.reject())

  const page_size = rowsPerPage
  const params    = { page, page_size, ...filters, ...payload }
  const request   = api[method](url, params)
  return new CancelablePromise(request)
}

const onError = (err) => {
  throw new Error(`Failed to fetch data from server: ${err}`)
}
