// @flow

import self from 'autobind-decorator'
import React, { Component } from 'react'
import is from '@sindresorhus/is'

import Icon from 'components/icons'
import { PrimaryButton } from 'components/buttons'
import __ from 'utils/gettext'

export type FilterType = {
  name: string,
  value: string | null,
}

export type FiltersType = Array<FilterType>

type Props = {
  search: string,
  onChange: Function,
  placeholder?: string,
}

type State = {
  value: string
}


export default class SearchInput extends Component<Props, State> {

  element: HTMLInputElement | null = null
  state: State = {
    value: this.props.search
  }

  get placeholder (): string {
    return this.props.placeholder ? __(this.props.placeholder) : ''
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.search !== this.props.search)
      this.setState({ value: this.props.search })
  }

  @self
  onKeyPress (event: *) {
    if (event.key === 'Enter' && event.target.value && this.props.onChange)
      this.props.onChange(event.target.value)
  }

  @self
  onChange (event: *) {
    const value = event.target.value
    this.setState({ value })
    if (is.string(value) && value.length === 0 && this.props.onChange)
      this.props.onChange(value)
  }

  @self
  apply () {
    if (this.element && this.props.onChange)
      this.props.onChange(this.element.value)
  }

  @self
  clear () {
    if (this.props.onChange)
      this.props.onChange('')
  }

  render (): React$Element<*> {
    const inputProps = {
      ref: ref => ref ? this.element = ref : this.element = null,
      value: this.state.value,
      onKeyPress: this.onKeyPress,
      onChange: this.onChange,
      placeholder: this.placeholder
    }

    return <div className='form-search'>
      <input { ...inputProps }  />
      <PrimaryButton
        ariaLabel={ __('Search forms') }
        onClick={ () => this.apply() }
        icon={ Icon.Search } />
    </div>
  }

}
