// @flow
import autobind from 'autobind-decorator'
import React, { Component } from 'react'
// import * as icons from 'react-icons/lib/fa'
import { STYLE_MENU } from './definitions'

import type RichTextEditor from './RichTextEditor'

export type StyleType = {
  style: {},
  label: string,
}

type ControlItem = {
  label: string,
  icon: string,
  iconset: string,
  className?: string,
  style?: {},
}

export type ControlsPropsType = {
  onToggle: () => {},
  onInlineStyleToggled: () => {},
  onBlockTypeChanged: () => {},
  editorState: any,
  editor: React$Element<*>,
  items: Array<ControlItem>,
  visibilityState: boolean,
}

type EditorControlsPropsType = ControlsPropsType & {
  editor: RichTextEditor,
}

type ButtonProps = ControlItem & {
  active: boolean,
  onToggle: Function,
}

export class StyleButton extends Component<ButtonProps> {
  static BASE_CLASS_NAMES = ['button', 'default']

  static ACTIVE_CLASS_NAME = 'active info'

  static style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  @autobind
  onToggle(e: Object): null {
    e.preventDefault()
    return this.props.onToggle(this.props.style)
  }

  get icon(): React$Element<*> | null {

    // HACK
    return null

    let { icon } = this.props

    if (!icon) return null
    return <Icon name={icon} />
  }

  get label(): string {
    return this.props.label
  }

  get className(): string {
    let { active, className } = this.props
    let classes = [...StyleButton.BASE_CLASS_NAMES]

    if (active === true) classes.push(StyleButton.ACTIVE_CLASS_NAME)
    if (className instanceof Array) classes = classes.concat(className)

    return classes.join(' ')
  }

  render(): React$Element<*> {
    return (
      <span style={StyleButton.style} className={this.className} onMouseDown={this.onToggle}>
        {this.icon}
        {this.label}
      </span>
    )
  }
}

const Controls = (props: ControlsPropsType): React$Element<*> => {
  let { editorState, onInlineStyleToggled, onBlockTypeChanged } = props
  let menu = STYLE_MENU
  let inline = menu.find(item => item.type === 'inline')
  let editor = menu.find(item => item.type === 'editor')
  let block = menu.find(item => item.type === 'block')

  return (
    <div className={'rte-controls toolbar ' + (props.visibilityState ? 'visible' : 'hidden')}>
      <InlineStyleControls editorState={editorState} onToggle={onInlineStyleToggled} items={inline.items} />

      {/* <BlockStyleControls
      editorState={editorState}
      onToggle={onBlockTypeChanged}
      items={block.items}
    />

    <EditorControls
      editor={props.editor}
      items={editor.items}
    /> */}
    </div>
  )
}

export default Controls

export const BlockStyleControls = (props: ControlsPropsType): React$Element<*> => {
  const { editorState, onToggle, items } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  const isActive = (type: string): boolean => type === blockType
  const getButton = (type: StyleType) => getButtonForType(type, onToggle, isActive)
  const getGroup = (styles: Array<StyleType>, n: Number) => (
    <section key={n} className="group button-group">
      {styles.map(getButton)}
    </section>
  )

  return <div className="rte-block-type-controls button-toolbar">{items.map(getGroup)}</div>
}

export const InlineStyleControls = (props: ControlsPropsType): React$Element<*> => {
  const isActive = (type: string): boolean => currentStyle.has(type)
  const getButton = (type: StyleType) => getButtonForType(type, onToggle, isActive)

  const { editorState, onToggle, items } = props
  const currentStyle = editorState.getCurrentInlineStyle()

  return <div className="rte-inline-style-controls button-group">{items.map(getButton)}</div>
}

export const EditorControls = (props: EditorControlsPropsType): React$Element<*> => {
  const isActive = (): boolean => (document.fullscreenElement ? true : false)
  const getButton = (type: StyleType) => getButtonForType(type, toggleFullscreen, isActive)
  const toggleFullscreen = () => props.editor.toggleFullscreen()

  return <div className="rte-editor-controls button-group">{props.items.map(getButton)}</div>
}

/**
 * Helper function for displaying button groups
 * @method getButtonForType
 * @param  {StyleType} item    A type item
 * @param  {Function} handler  onClick handler
 * @param  {Function} isActive A function that will return a boolean value
 * @return {Array}             List of buttons
 */

const getButtonForType = (item: StyleType, handler: () => {}, isActive: () => {}): React$Element<*> => (
  <StyleButton
    key={item.label}
    icon={item.icon}
    iconset={item.iconset}
    label={!item.hideLabel ? item.label : ''}
    style={item.style}
    onToggle={handler}
    active={isActive(item.style)}
    className={item.className}
  />
)

export const Icon = (props: { name: string }) => {
  // const name = formatIconName(props.name)
  // const IconComponent = icons[name]
  // if (IconComponent) return <IconComponent />
  return null
}

export function formatIconName(name) {
  const firstLetter = name[0].toUpperCase()
  const body = name.substr(1)
  const iconset = 'Fa'

  const clean = text => text.replace(/(?:[^\w]+(\w))/g, (_, c) => c.toUpperCase())

  return iconset + firstLetter + clean(body)
}
