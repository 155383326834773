// @flow
import { Calendar, DatePicker } from '@nordhealth/react'
import React, { useEffect, useRef, useState } from 'react'
import ConfirmationFooter from './ConfirmationFooter'

import { useDispatch, useSelector } from 'react-redux'

import {
  handleCalendarChange,
  handleYearChange,
  selectDate,
} from 'actions/onlineBooking'

import type { Node } from 'react'
import type { AvailableDates } from 'reducers/onlineBooking'
import type { StateType } from 'store/initialState'

import { getDateString } from 'utils/dateUtils'

type Props = {
  availableDates: AvailableDates[],
  submit?: boolean,
  selectDateOnChange?: boolean,
  showAsDatePicker?: boolean,
  label?: string,
  increaseStep?: boolean,
  expand?: boolean,
}

type CalendarRefObject = {
  current: {
    handleMonthSelect: (e: SyntheticEvent<*>) => void,
    handleNextMonthClick: (e: SyntheticEvent<*>) => void,
    handlePreviousMonthClick: (e: SyntheticEvent<*>) => void,
    handleYearSelect: (e: SyntheticEvent<*>) => void,
  } | null,
}

const BookingCalendar = (props: Props): Node => {
  const calendarRef: CalendarRefObject = useRef(null)
  const dispatch = useDispatch()
  const calendarState = useSelector(
    (state: StateType) => state.onlineBooking.calendar_state
  )
  // availableTimes holds converted times that will be used in calendar component
  // for comparison if the date is avaialble or not.
  const [availableTimes, setAvailableTimes] = useState<number[] | null>(null)

  // This array is held in order to select correct object with required data when
  // date is picked and confirm button is pressed
  const [availableDateStrings, setavailableDateStringss] = useState<
    AvailableDates[] | null
  >(null)

  useEffect(() => {
    const { availableDates } = props
    const { dates, times } = constructAvailableDateTimes(
      availableDates,
      calendarState.selected_month,
      calendarState.selected_year
    )
    setAvailableTimes(times)
    setavailableDateStringss(dates)
  }, [props.availableDates])

  useEffect(() => {
    /* As it is impossible to check via native event handler which month is selected on change =>
      We have to append to the native method the handlers or own state changes that will trigger
      refetch of the avaialble dates based on the month/ year selected */
    if (calendarRef.current) {
      // If we use date picker, then the calendar is nested within it
      const calendar = props.showAsDatePicker
        ? calendarRef.current.calendar
        : calendarRef.current

      // Auxilary save of native methods as they will be overwritten
      const nativeNextMonthMehtod = calendar.handleNextMonthClick
      const nativePreviousMonthMehtod = calendar.handlePreviousMonthClick
      const nativeMonthSelect = calendar.handleMonthSelect
      const nativeYearSelect = calendar.handleYearSelect

      // Custom implementations
      // Right arrow is clicked
      const updateNextMonthSelectedMethod = (e: SyntheticEvent<*>) => {
        nativeNextMonthMehtod(e)
        handleMonthChange(calendarState.selected_date_string, NEXT, dispatch)
      }

      // Left arrow is clicked
      const updatePreviousMonthSelectedMethod = (e: SyntheticEvent<*>) => {
        nativePreviousMonthMehtod(e)
        handleMonthChange(
          calendarState.selected_date_string,
          PREVIOUS,
          dispatch
        )
      }

      // Specific month from the dropdown is selected
      const updateMonthSelect = (e: SyntheticEvent<*>) => {
        nativeMonthSelect(e)
        handleMonthChange(
          calendarState.selected_date_string,
          SELECT,
          dispatch,
          // $FlowIgnore
          e.target.value
        )
      }

      // Specific year from the dropdown is selected
      const updateYearSelect = (e: SyntheticEvent<*>) => {
        nativeYearSelect(e)
        // $FlowIgnore
        dispatch(handleYearChange(e.target.value))
      }

      // Yet again, if it is a date picker, then calendar is nested
      if (props.showAsDatePicker) {
        calendarRef.current.calendar.handleNextMonthClick = updateNextMonthSelectedMethod
        calendarRef.current.calendar.handlePreviousMonthClick = updatePreviousMonthSelectedMethod
        calendarRef.current.calendar.handleMonthSelect = updateMonthSelect
        calendarRef.current.calendar.handleYearSelect = updateYearSelect
      } else {
        calendarRef.current.handleNextMonthClick = updateNextMonthSelectedMethod
        calendarRef.current.handlePreviousMonthClick = updatePreviousMonthSelectedMethod
        calendarRef.current.handleMonthSelect = updateMonthSelect
        calendarRef.current.handleYearSelect = updateYearSelect
      }
    }
  }, [])

  const onChange = (e) => {
    dispatch(handleCalendarChange(e.target.value))
    if (props.selectDateOnChange) {
      const stepAmount = props.increaseStep ? 1 : 0
      submit(e, stepAmount)
    }
  }

  /* increaseStep - by default we make a transition to the next view,
     however, as in SelectEmployeeView (desktop) the calendar is also
     present and we can change dates there on the go - we should avoid
     increasing step */
  const submit = (e, increaseStep = 1) => {
    if (availableDateStrings) {
      // $FlowIgnore
      const toSelect: AvailableDates = availableDateStrings.find(
        (date) => date.date === calendarRef.current.value
      )
      dispatch(selectDate(toSelect, increaseStep))
    }
  }

  useEffect(() => {
    if (calendarRef && calendarRef.current) {
      // $FlowIgnore
      calendarRef.current.addEventListener('change', onChange)
    }

    return () => {
      if (calendarRef && calendarRef.current) {
        // $FlowIgnore
        calendarRef.current.removeEventListener('change', onChange)
      }
    }
  })

  const disabledDate = (d: Date): boolean => {
    return !availableTimes?.includes(d.getTime())
  }

  const displaySubmitFooter = (): boolean => {
    if (availableDateStrings) {
      const { selected_date_string } = calendarState
      const index = availableDateStrings.findIndex(
        (dateObj) => dateObj.date === selected_date_string
      )
      return index !== -1
    }
    return false
  }

  const Component = props.showAsDatePicker ? DatePicker : Calendar

  return (
    <>
      <Component
        ref={calendarRef}
        value={calendarState.selected_date_string}
        isDateDisabled={disabledDate}
        label={props.label}
        expand={props.showAsDatePicker && props.expand}
      ></Component>
      {props.submit && displaySubmitFooter() && (
        <ConfirmationFooter position='fixed' onConfirm={submit} />
      )}
    </>
  )
}

const constructAvailableDateTimes = (
  res: any[],
  month: string,
  year: string
): { dates: AvailableDates[], times: number[] } => {
  let dates = []
  let times = []
  res.forEach((obj: AvailableDates) => {
    const time = new Date(obj.date).setHours(0, 0, 0)

    const availableDate: AvailableDates = {
      date: obj.date,
      employees: obj.employees,
      treatment_duration_id: obj.treatment_duration_id,
    }

    dates.push(availableDate)
    times.push(time)
  })
  return { dates, times }
}

const handleMonthChange = (
  dateString: string,
  action: 'NEXT' | 'PREVIOUS' | 'SELECT',
  dispatch: Function,
  month?: number | null = null
): Function => {
  const date = new Date(dateString)
  console.log(date)
  date.setDate(1)
  if (action === NEXT) {
    date.setMonth(date.getMonth() + 1)
  } else if (action === PREVIOUS) {
    date.setMonth(date.getMonth() - 1)
  } else if (action === SELECT && month) {
    date.setMonth(month)
  }
  const newDateString = getDateString(date)
  return dispatch(handleCalendarChange(newDateString))
}

const NEXT = 'NEXT'
const PREVIOUS = 'PREVIOUS'
const SELECT = 'SELECT'

export default BookingCalendar
