// @flow
// a helper component for showing or hiding the courses view based on the show_courses tenant setting

import React, { Component } from 'react'
import connect from 'bound-connect'

import Courses from 'views/Courses'
import PageNotFound from 'views/Error/PageNotFound'
import { isPublicApp } from '../index'

import type { StateType } from 'store/initialState'

type CoursesPropsType = {
  showCourses?: boolean,
}


@connect
export default class ShowCourses extends Component<CoursesPropsType>{

  static actions = []
  static properties = (state: StateType) => ({
    showCourses: state.tenantSettings.show_courses,
  })

  render () {
    if (isPublicApp())
      return <PageNotFound />

    return this.props.showCourses
      ? <Courses />
      : <PageNotFound />
  }
}
