// @flow
import React, { Fragment, useEffect } from 'react'
import { Button, Card } from '@nordhealth/react'

import isMobile from 'utils/isMobileHook'
import { __ } from 'utils/gettext'

import type { Node } from 'react'

const PrivacyPolicyView = (props: *): Node => {
  const isMobileView = isMobile(window)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Wrapper = isMobileView ? Fragment : Card
  const buttonSize = isMobileView ? 'm' : 'l'

  return (
    <Fragment>
      <Wrapper>
        <div className='privacy-policy-container'>
          <div className='title n-font-weight-heading'>
            Page is in progress
          </div>
          <p>
            Quisque eget turpis in ligula eleifend sodales. Praesent pellentesque ante vel turpis ornare volutpat.
            Ut dui ligula, dapibus ac nulla ut, varius varius massa. Proin a odio eu sapien cursus ornare. Fusce id justo molestie,
            lacinia eros sit amet, ullamcorper dui.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Pellentesque vel elit id nisl malesuada mattis. Suspendisse dapibus ultrices eros, eget efficitur mi facilisis id.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Mauris tempor est vitae ex ullamcorper congue pretium quis nunc. Aenean sed lacus at odio congue aliquet.
            Cras non massa suscipit, lobortis lacus a, aliquam leo. Duis sollicitudin erat et lacinia blandit.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec venenatis venenatis sem lacinia cursus.
            Mauris facilisis lobortis ligula sed eleifend.
          </p>
          <p>
            Pellentesque vitae dolor lacus. Proin consectetur risus in ipsum molestie congue.
            Integer neque risus, semper ut metus eu, laoreet gravida tellus. Cras semper leo in bibendum ultricies.
            Fusce nec dui eu neque sodales rhoncus. Aliquam at sapien quam. Aenean eget enim nec erat feugiat egestas.
            Praesent lacinia, purus non elementum ullamcorper, sapien nulla iaculis nisi, suscipit pretium nisi sem in odio.
            Donec elit leo, convallis quis egestas in, ultricies nec nunc.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Duis fermentum convallis arcu. Aenean nec dictum mi. Donec eu mattis lorem, in lobortis ipsum.
            Mauris tincidunt quam eu est ultrices imperdiet. Integer posuere orci nibh, a lobortis ipsum lacinia id.
            Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Interdum et malesuada fames ac ante ipsum primis in faucibus.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Pellentesque vel elit id nisl malesuada mattis. Suspendisse dapibus ultrices eros, eget efficitur mi facilisis id.
            Mauris tempor est vitae ex ullamcorper congue pretium quis nunc. Aenean sed lacus at odio congue aliquet.
            Cras non massa suscipit, lobortis lacus a, aliquam leo. Duis sollicitudin erat et lacinia blandit.
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec venenatis venenatis sem lacinia cursus.
            Mauris facilisis lobortis ligula sed eleifend. Integer non dui dui. Phasellus in semper erat.
            Quisque orci magna, egestas non metus a, accumsan sagittis eros.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Quisque semper tortor et odio suscipit posuere. Etiam suscipit maximus massa, non iaculis ipsum varius et.
            Aenean gravida mi ut pulvinar accumsan. Maecenas luctus urna vitae est dignissim, vitae auctor velit lacinia.
            Nunc ullamcorper sit amet dui et placerat. Integer ornare auctor ante, a dapibus massa facilisis quis.
          </p>
          <p>
            Ut viverra turpis sagittis elit condimentum, nec faucibus ante dignissim. Cras luctus nisl quis felis commodo,
            at aliquet quam semper. Sed sagittis enim eu urna facilisis, in pharetra erat lobortis. In ornare enim tortor,
            ac vestibulum tortor laoreet quis. Pellentesque congue odio elit, sed interdum ligula euismod ut.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam porttitor sodales erat sed dictum.
            Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Morbi id sapien id odio tristique sagittis porta vel arcu. Sed aliquam iaculis massa.
            Mauris nec eros faucibus, tempor lectus sed, ornare sapien. Ut viverra turpis sagittis elit condimentum,
            nec faucibus ante dignissim. Cras luctus nisl quis felis commodo, at aliquet quam semper.
          </p>
          <p>
            Nam ac nisi ac nisi consequat imperdiet vitae in mi. Praesent sed vulputate magna, et luctus massa.
            Nulla mauris purus, pulvinar ut erat eu, luctus sollicitudin purus.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Quisque eget turpis in ligula eleifend sodales. Praesent pellentesque ante vel turpis ornare volutpat.
            Ut dui ligula, dapibus ac nulla ut, varius varius massa. Proin a odio eu sapien cursus ornare.
            Fusce id justo molestie, lacinia eros sit amet, ullamcorper dui. Aliquam ut accumsan libero.
            Pellentesque id libero eget mauris pellentesque pharetra. Vivamus nec laoreet odio, nec ornare lorem.
          </p>
          <p>
            Quisque eget turpis in ligula eleifend sodales. Praesent pellentesque ante vel turpis ornare volutpat.
            Ut dui ligula, dapibus ac nulla ut, varius varius massa.
          </p>
          <div className='heading n-font-weight-heading'>
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
          </div>
          <p>
            Vivamus nec laoreet odio, nec ornare lorem.
          </p>
        </div>
      </Wrapper>
      <div className='confirm'>
        <Button
          variant='primary'
          size={buttonSize}
          onClick={() => {
            props.closeModal(false)
          }}
        >
          {__('Back to checkout')}
        </Button>
      </div>
    </Fragment>
  )
}

export default PrivacyPolicyView
