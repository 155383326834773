// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeContract = load(() =>
  import('../../../views/Public/Contracts/ComposeContract'))

const ContractsView = load(() =>
  import('../../../views/Public/Contracts/ListContracts'))


export const descriptors = {
  contracts:      `/contracts/`,
  createContract: `/contracts/create/`,
}


export default <Switch>
  <Route path={ descriptors.createContract } component={ ComposeContract } />
  <Route path={ descriptors.contracts } component={ ContractsView } />
  <RouteNotFound />
</Switch>
