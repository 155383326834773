// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


export const descriptors = {
  list:    ()                 => `/courses/`,
  details: (id: ID)           => `/courses/${id}/`,
  part:    (id: ID, part: ID) => `/courses/${id}/sections/${part}`,
}


const CoursesView = load(() =>
  import('views/Courses/Courses'))

const CourseDetailsView = load(() =>
  import('views/Courses/CourseDetails'))


export default <Switch>
  <Route exact path={ descriptors.list() } component={ CoursesView } />
  <Route exact path={ descriptors.details(':id(\\d+)') } component={ CourseDetailsView } />
  <RouteNotFound />
</Switch>
