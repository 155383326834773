// @flow
const transitionDuration = 120
const strokeLinejoin     = 'round'
const strokeLinecap      = 'round'
const vectorEffect       = 'non-scaling-stroke'
const transition         = `all ${transitionDuration}ms`
const fill               = 'transparent'

const svg: Object = {
  height:   'auto',
  display:  'block',
  maxWidth: 'none',
  madgine: 0,
}

const input: Object = {
  display: 'none',
}

const container: Object = {
  maxHeight: 'none',
  maxWidth:  'none',
  display:   'block',
  cursor:    'pointer',
  height:    'auto',
  width:     'auto',
  margin:    0,
  padding:   0,
  flexGrow:  0,
}

const togglable = (strokeWidth: number): Object => ({
  strokeWidth,
  strokeLinejoin,
  strokeLinecap,
  vectorEffect,
  transition,
  fill,
})

export default {
  radio:    togglable(1),
  checkbox: togglable(1.4),
  container,
  input,
  svg,
}
