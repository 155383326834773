// @flow
import React, { useEffect, useState } from 'react'
import withOnlineBookingModal from '../extra/OnlineBookingModal'
import ConfirmationView from './ConfirmationView'

const SuccessfulBooking = (props: RouteProps) => {
  const [showAccountCreatedBanner, setShowAccountCreatedBanner] = useState<boolean>(false)

  useEffect(() => {
    const accountCreated = (props.history.location.state && props.history.location.state.accountCreated) || false
    if (accountCreated)
      setShowAccountCreatedBanner(true)
  }, [])

  return (
    <ConfirmationView
      displayConfirmationTitle={true}
      hideConfirmationFooter={true}
      isSuccessfulBookingView={true}
      showAccountCreatedBanner={showAccountCreatedBanner}
    />
  )
}

export default (withOnlineBookingModal(SuccessfulBooking, true): *)
