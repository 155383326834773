// @flow
import React, { Component } from 'react'
import self from 'autobind-decorator'
import connect from 'bound-connect'

import api from 'api'
import navigator from 'routes/base'
import { addAlert } from 'actions/core'
import { resolveError } from 'utils/resolve'

import { confirmation } from 'components/generic'
import { RemoveButton } from 'components/buttons'
import Text from 'components/text'

import type { StateType } from 'store/initialState'

type PropsType = {
  url: string,
  confirmationTitle: string,
  confirmationLabel: string,
  buttonLabel?: string,
  successMessage: string,
  addErrorMessage: Function,
  addSuccessMessage: Function,
  listComponent?: *,
  dropdown?: boolean,
  small?: boolean,
  disabled?: boolean,
  onClick?: Function,
  messageDuration?: number,
  afterDelete?: Function,
  navigateToPrevious?: boolean,
  payload?: Object,
  style?: Object,
  color?: string,
}

const DELETE_LABEL = global.tooltips.delete


@connect
export default class DeleteButton extends Component<PropsType, StateType> {

  static actions: Object = (dispatch: Function) => ({
    addSuccessMessage:  (content: string, duration: number) => dispatch(addAlert('success', content, duration)),
    addErrorMessage:    (content: string, duration: number) => dispatch(addAlert('error', content, duration)),
  })
  static properties: Object = (state: StateType) => ({
    color: state.tenantSettings.color,
  })

  static defaultProps: Object = {
    messageDuration: 2,
    payload: {},
    navigateToPrevious: true,
  }

  @self
  async delete (): Function {
    if (this.props.onClick)
      return this.props.onClick()
    const response = await api.delete(this.props.url, this.props.payload)
    if (response.problem) {
      this.props.addErrorMessage(resolveError(response.satus, 'Delete'), this.props.messageDuration)
    } else {
      this.props.addSuccessMessage(this.props.successMessage, this.props.messageDuration)
      this.props.afterDelete && this.props.afterDelete()
      if (this.props.navigateToPrevious)
        this.props.listComponent
          ? this.props.listComponent.props.refreshList()
          : navigator.previous()

    }
  }

  @self
  confirmDelete (event: SyntheticEvent<*>) {
    event.stopPropagation()
    confirmation(this.props.confirmationTitle, this.props.confirmationLabel, this.props.color).then(
      () => this.delete(),
      () => {})
  }

  render (): React$Element<*> {

    if (this.props.dropdown)
      return <div className='dropdown-item-small' onClick={ this.confirmDelete }><Text>{ DELETE_LABEL }</Text></div>

    return this.props.listComponent
      ? <RemoveButton
        style={ this.props.style }
        disabled={ this.props.disabled }
        small={ this.props.small }
        tooltip={ DELETE_LABEL} onClick={ this.confirmDelete }/>
      : <RemoveButton
        style={ this.props.style }
        disabled={ this.props.disabled }
        small={ this.props.small }
        onClick={ this.confirmDelete }>
        { this.props.buttonLabel || DELETE_LABEL }
      </RemoveButton>

  }

}
