
import { PREFIX } from './constants'


const CaretCoordinates = {
  x: 0,
  y: 0,
}


export function getSelectionRange () {
  const selection = window.getSelection()
  if (selection.rangeCount === 0)
    return null
  return selection.getRangeAt(0)
}


export function getTriggerRange (term) {
  const range = getSelectionRange()
  const text  = range && range.startContainer.textContent.substring(0, range.startOffset)
  if (!text || /\s+$/.test(text))
    return null

  const start = text.lastIndexOf(term)
  if (start === -1)
    return null

  const end = range.startOffset
  return {
    end,
    start,
    text:  text.substring(start),
  }
}


export function getInsertRange (editorState) {
  const selection = editorState.getSelection()
  const content   = editorState.getCurrentContent()
  const anchorKey = selection.getAnchorKey()
  const end       = selection.getAnchorOffset()
  const block     = content.getBlockForKey(anchorKey)
  const text      = block.getText()
  const start     = text.substring(0, end).lastIndexOf(PREFIX)

  return {
    start,
    end,
  }
}


function updateCaretCoordinates (range) {
  if (!range)
    return CaretCoordinates

  // A fix for Safari and other webkit based browsers (not chrome) where the left and top values
  // always seem to return 0, resulting in wrong placement for the suggestions list
  range = range.cloneRange()
  const pos = range.startContainer.length - 1 > 0 ? range.startContainer.length - 1 : 0
  range.setStart(range.startContainer, pos)
  let { left: x, top: y }  = range.getBoundingClientRect()
  x += 15
  return Object.assign(CaretCoordinates, { x, y })
}


export function getCaretCoordinates () {
  const range = getSelectionRange()
  return updateCaretCoordinates(range)
}
