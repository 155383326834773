
import { EditorState, Modifier, SelectionState } from 'draft-js'
import { ENTITY_LABEL, PREFIX, INSERT_ACTION_LABEL } from './constants'

const textForEntry = entry =>
  `${PREFIX}${entry.label}`

export function moveSelectionToEnd (editorState) {
  const content = editorState.getCurrentContent()
  const blockMap = content.getBlockMap()

  const key = blockMap.last().getKey()
  const length = blockMap.last().getLength()

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  })

  return EditorState.acceptSelection(editorState, selection)
}


export function getEditorStateWithAddedSpace (editorState, end?) {
  if (end)
    editorState = EditorState.moveFocusToEnd(editorState)
  let updatedContentState = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), " ")
  const updatedEditorState = EditorState.push(
    editorState,
    updatedContentState,
    INSERT_ACTION_LABEL)

  return EditorState.forceSelection(
    updatedEditorState,
    updatedContentState.getSelectionAfter())
}

// eslint-disable-next-line import/prefer-default-export
export function getEditorStateWithAddedSuggestion (editorState, entry, nospace?) {

  const position = Object.assign({
    anchorOffset:  entry.start,
    focusOffset:   entry.end,
  }, entry.key ? {
    anchorKey:     entry.key,
    focusKey:      entry.key,
  } : {})

  const contentState     = editorState.getCurrentContent()
  const currentSelection = editorState.getSelection()
  const selection        = currentSelection.merge(position)

  const contentStateWithEntity = contentState.createEntity(
    ENTITY_LABEL, 'IMMUTABLE', entry)

  const entityKey = contentStateWithEntity
    .getLastCreatedEntityKey()

  const newContentState = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    textForEntry(entry),
    null,
    entityKey)

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    INSERT_ACTION_LABEL)

  if (nospace)
    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter())

  // Insert space after the suggestion block
  return getEditorStateWithAddedSpace(newEditorState)
}


function findFirstPositionForSuggestion (editorState, entry, oldIndex) {
  const blocks = editorState.getCurrentContent().getBlocksAsArray()

  for (const block of blocks) {

    // only take the remaining part of the text since search returns the first index
    const text     = block.getText().slice(oldIndex + 1)
    const key      = block.getKey()

    // add prev index so that the resulting index is not from the sliced text
    const regex = new RegExp('\\b' + entry.value + '\\b', 'i')
    const index    = oldIndex + 1 + text.search(regex)

    // prevent the same index being found several times when nolabel is true
    const found    = oldIndex === index

    if (index > -1 && !found)
      return {
        start: index,
        end:   index + entry.value.length,
        key:   key,
      }
  }

  return null
}

//eslint-disable-next-line max-statements
export function getEditorStateWithSuggestionsReplaced (editorState, suggestions = [], nolabels?) {
  for (const suggestion of suggestions) {
    let position
    let oldIndex = -1
    while (position !== null) {
      position = findFirstPositionForSuggestion(editorState, suggestion, oldIndex)

      // if position is found then update the editor state
      if (position) {
        oldIndex = position.start + 1
        const entry = { ...suggestion, ...position }
        //eslint-disable-next-line max-depth
        if (nolabels) {
          entry.tooltip = entry.label.slice(0)
          entry.label = entry.value.slice(0)
        }
        editorState = getEditorStateWithAddedSuggestion(editorState, entry, true)
      }
    }
  }
  editorState = moveSelectionToEnd(editorState)
  return editorState
}
