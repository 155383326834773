// @flow
import { FIELD_TYPES_WITH_CHOICES, STATIC_ELEMENT_MODELS } from './fields'

import type { BlockType, ExpressionType } from './editor/Block'
import typeof { state as StateType } from '.'

type FilenameType = string
type LinkType = {
  url: string,
  description: string,
}

type FormEditorStateApiResponseType = {|
  id: number,
  name: string,
  status: 1 | 2 | 3,
  description: string,
  tags?: Array<string>,
  links?: Array<LinkType>,
  elements?: Array<BlockType>,
  attachments_staff?: Array<FilenameType>,
  attachments_client?: Array<FilenameType>,
  version: Object,
  modified: string,
  modified_by: Object,
  restricted: boolean,
  allow_to_edit_submission: boolean
|}


// FIXME
// type EditorType = Map<{
//   details: Object,
//   blocks: OrderedSet<BlockType>,
//   links: OrderedSet<{ url: string, description: string }>,
//   tags: OrderedSet<number>,
// }>


/**
 * Deserialize a backend's response for the form's data request.
 *
 * @method  deserialize
 * @param   data        Api response data
 */

export function deserialize (data: FormEditorStateApiResponseType): * {
  const elements = data.version.elements.map(deserializeElement)
  return {
    details: {
      id:                 data.id,
      name:               data.name,
      status:             data.status || 1,
      description:        data.description,
      country:            data.country,
    },
    tags:                 data.tags || [],
    links:                data.links || [],
    version:              data.version.id,
    elements:             elements,
    attachments_staff:    data.attachments_staff || [],
    attachments_client:   data.attachments_client || [],
    modified:             data.modified,
    modified_by:          data.modified_by,
    restricted:           data.restricted,
    allow_to_edit_submission: data.allow_to_edit_submission
  }
}

export default function serialize (editor: StateType) {

  const details             = editor.get('details')

  const tags                = editor.get('tags').toArray()
  const links               = editor.get('links').toArray()
  const blocks              = editor.get('blocks').toArray()
  const attachments_staff   = editor.get('attachments_staff').toArray()
  const attachments_client  = editor.get('attachments_client').toArray()

  return {
    id:                 details.id,
    name:               details.name,
    status:             details.status || 1,
    description:        details.description,
    country:            details.country,
    tags:               tags,
    links:              links,
    version:            { elements: blocks.map( block => mapFieldToJSON(block)) },
    attachments_staff:  attachments_staff,
    attachments_client: attachments_client,
  }
}


/**
 * Map a single field to json
 * @param  {BlockType} block            The field to be mapped
 * @param  {Boolean} expandChoices      Whether the choices of choice and multichoice fields should be exapnded
 * @param  {Boolean} expressionAsObject When set to true return an object with value and displayValue otherwise return the value as string
 * @return {Object}                     Return an object with the fields properties
 */
export function mapFieldToJSON (block: BlockType, expandChoices?: boolean, expressionAsObject?: boolean): * {
  switch (block.model) {

    case 'Field':
      return serializeField(block, expandChoices !== false)

    case 'Indicator':
      return serializeField(block, false, expressionAsObject)

    default:
      return serializeElement(block)

  }
}

const serializeField = (element: BlockType, expandChoices: boolean = true, expressionAsObject?: boolean = false): * => ({
  id:                   element.id,
  indicator_identifier: element.indicator_identifier,
  text:                 element.text,
  name:                 element.text,
  type:                 element.type,
  model:                element.model,
  options:              serializeOptions(element.options),
  required:             element.required,
  instruction_text:     element.instruction_text,
  description:          element.instruction_text,
  decimals:             element.decimals,
  choices:              expandChoices
    ? element.choices.map(expandChoice)
    : element.choices,
  expression:           !expressionAsObject
    ? serializeIndicator(element.expression)
    : element.expression
})

const deserializeElement = (element, id) => {

  // construct the displayValue of the indicator element
  if (element.model === 'Indicator') {
    element.expression = deserializeExpression(element.expression)
  }
  return {
    ...element,
    text: element.text || element.name,
    instruction_text: element.instruction_text || element.description,
    choices: (element.choices || []).map(deserializeChoice),
    id,
  }
}

const serializeElement = (element: BlockType) => ({
  text:    element.text,
  model:   element.model,
  options: serializeOptions(element.options),
})

const serializeOptions = (options: Object = {}): Object =>
  options || {}

const serializeIndicator = (expression?: ExpressionType): string | null =>
  expression && !(typeof expression === 'string')
    ? expression.value
    : null

const deserializeExpression = (expression: string): ExpressionType =>
  ({ value: expression, displayValue: expression })

const deserializeChoice = choice => {
  if (typeof choice.score === 'number')
    return {
      value: choice.text,
      score: choice.score,
    }
  return choice.text
}

const expandChoice = choice => {
  if (typeof choice === 'string')
    return { text: choice }
  return {
    text:  choice.value,
    score: choice.score
  }
}

export const elementHasOptions = (element: BlockType): boolean =>
  FIELD_TYPES_WITH_CHOICES.includes(element.type)

export const elementIsStatic = (element: BlockType): boolean =>
  STATIC_ELEMENT_MODELS.includes(element.model)

export const elementIsField = (element: BlockType): boolean =>
  element.model === 'Field'

export const resolveElementName = (element: BlockType): string =>
  element.text
