import React from 'react'
import loadable from '@loadable/component'
import { Loader } from 'components/generic'

const defaults = {
  fallback: <Loader/>,
  timeout: 10,
  delay:   200,
}

export default function loadComponent (loader, opts = {}) {
  return loadable(loader, Object.assign({}, defaults, opts))
}
