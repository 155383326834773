/**
 * @flow
 * @summary Helper functions for In-app-notifications
 */

import __ from 'utils/gettext'

import type NotificationType from 'models/Notification'

export default function getItemBody(item: NotificationType): string {
  switch (item.type) {
    case 1: // thread
      if (item.client) {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'User <strong>${client}</strong> has <strong>${count}</strong> new messages in the thread <strong>${thread}</strong>',
            {
              client: item.client?.profile?.full_name,
              thread: item.content_object.subject,
              count: related_notifications_count,
            }
          )
        }
        return __(
          'User <strong>${client}</strong> has a new message in the thread <strong>${thread}</strong>',
          {
            client: item.client?.profile?.full_name,
            thread: item.content_object.subject,
          }
        )
      } else {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'You have <strong>${count}</strong> new messages in the thread <strong>${thread}</strong>',
            {
              thread: item.content_object.subject,
              count: related_notifications_count,
            }
          )
        }
        return __(
          'You have a new message in the thread <strong>${thread}</strong>',
          { thread: item.content_object.subject }
        )
      }

    case 2: // event
      if (item.client) {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'User <strong>${client}</strong> has <strong>${count}</strong> new events on the timeline',
            {
              client: item.client?.profile?.full_name,
              count: related_notifications_count,
            }
          )
        }
        return __(
          'User <strong>${client}</strong> has a new event on the timeline',
          { client: item.client?.profile?.full_name }
        )
      } else {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'You have <strong>${count}</strong> new events on the timeline',
            { count: related_notifications_count }
          )
        }
        return __('You have a new event on the timeline')
      }

    case 3: // form submission
      if (
        item.client &&
        item.content_object &&
        item.content_object.filled_by &&
        item.client.id !== item.content_object.filled_by.id
      ) {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            '<strong>${filled_by}</strong> has filled <strong>${count}</strong> forms on behalf of <strong>${client}</strong>',
            {
              filled_by: item.content_object.filled_by.profile.full_name,
              client: item.client.profile.full_name,
              count: related_notifications_count,
            }
          )
        }
        return __(
          '<strong>${filled_by}</strong> has filled the form <strong>${form}</strong> on behalf of <strong>${client}</strong>',
          {
            filled_by: item.content_object.filled_by.profile.full_name,
            form: item.content_object.form.name,
            client: item.client.profile.full_name,
          }
        )
      } else {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            '<strong>${client}</strong> has filled <strong>${count}</strong> forms',
            {
              client: item.client?.profile?.full_name,
              count: related_notifications_count,
            }
          )
        }
        return __(
          '<strong>${client}</strong> has filled the form <strong>${form}</strong>',
          {
            client: item.client?.profile?.full_name,
            form: item.content_object.form.name,
          }
        )
      }

    case 4: // document distribution
      if (item.client) {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'User <strong>${client}</strong> has been added <strong>${count}</strong> new documents',
            {
              count: related_notifications_count,
              client: item.client?.profile?.full_name,
            }
          )
        }
        return __(
          'User <strong>${client}</strong> has been added a new document <strong>${document}</strong>',
          {
            client: item.client?.profile?.full_name,
            document: item.content_object.name,
          }
        )
      } else {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'You have been added <strong>${count}</strong> new documents',
            { count: related_notifications_count }
          )
        }
        return __(
          'You have been added a new document <strong>${document}</strong>',
          { document: item.content_object.name }
        )
      }

    case 5: // course access
      if (item.client) {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'User <strong>${client}</strong> has been added to <strong>${count}</strong> courses',
            {
              client: item.client?.profile?.full_name,
              count: related_notifications_count,
            }
          )
        }
        return __(
          'User <strong>${client}</strong> has been added to the course <strong>${course}</strong>',
          {
            client: item.client?.profile?.full_name,
            course: item.content_object.name,
          }
        )
      } else if (item.user && item.user.is_staff)
        return __(
          'You have been subscribed to the course <strong>${course}</strong>',
          { course: item.content_object.name }
        )
      else {
        const { related_notifications_count } = item
        if (related_notifications_count > 1) {
          return __(
            'You have been added to <strong>${count}</strong> courses',
            { count: related_notifications_count }
          )
        }
        return __(
          'You have been added to the course <strong>${course}</strong>',
          { course: item.content_object.name }
        )
      }
    default:
      return ''
  }
}
