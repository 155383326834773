/**
 * @flow
 * @author mikakattainen<mika.kattainen@fns.fi>
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeTag = load(() =>
  import('views/Public/Tags/ComposeTag'))

const TagsView = load(() =>
  import('views/Public/Tags/ListTags'))


export const descriptors = {
  tags:              `/tags/`,
  createTag:         `/tags/create/`,
  tag: (pk: ID) =>   `/tags/${pk}`,
  tagGroup:          `/tags/public/`,
}


export default <Switch>
  <Route path={ descriptors.createTag } component={ ComposeTag } />
  <Route path={ descriptors.tag(':id(\\d+)') } component={ ComposeTag } />
  <Route path={ descriptors.tagGroup } component={ TagsView } />
  <Route exact path={ descriptors.tags } component={ TagsView } />
  <RouteNotFound />
</Switch>
