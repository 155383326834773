/**
 * @module views.Page.Footer
 * @class PageFooter
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 *
 * @description
 * Any contents rendered within a FooterContents component
 * will be appended to the page footer as a separate section.
 *
 * @example
 * <FooterSection>
 *   <Toolbar left>
 *     <BackToThreadsListButton />
 *   </Toolbar>
 * </FooterSection>
 */

import React, { PureComponent } from 'react'
import { createPortal } from 'react-dom'
import type { Node } from 'react'


type FooterContentsProps = {
  children: Node,
  fixed?: boolean,
}


const host: {
  node: HTMLElement | null,
} = {
  node: null,
}

const applyReference = ref =>
  host.node = ref


export default class FooterContents extends PureComponent<FooterContentsProps> {

  element: HTMLElement = document.createElement('section')

  componentDidMount () {
    if (host.node === null)
      throw new ReferenceError(`Cannot append contents to the page footer before it has been mounted`)
    if (this.props.fixed)
      this.element.className = 'fixed'
    host.node.appendChild(this.element)
  }

  componentWillUnmount () {
    this.element.remove()
  }

  render () {
    return createPortal(this.renderContent(), this.element)
  }

  renderContent (): Node {
    return this.props.children
  }
}


export class PageFooter extends PureComponent<{}> {

  render () {
    return <footer
      ref={ applyReference }
      className='page-footer footer container'
    />
  }

}
