// @flow
import React, { Fragment, useState } from 'react'
// $FlowIgnore
import { Banner } from '@nordhealth/react'
import { useDispatch, useSelector } from 'react-redux'

import BookingLoginModal from '../extra/BookingLoginModal'
import ConfirmationFooter from '../extra/ConfirmationFooter'

import ConfirmationDetails from '../extra/ConfirmationDetails'

import { resetBooking } from 'actions/onlineBooking'

import type { Node } from 'react'
import type { OnlineBookingState as OnlineBooking } from '../../../reducers/onlineBooking'

import navigator from 'routes/base'

type Props = {
  bookingData: OnlineBooking,
  hideConfirmationFooter?: boolean,
  isSuccessfulBookingView?: boolean,
  showAccountCreatedBanner?: boolean,
}

const ConfirmBookingMobileLayout = (props: Props): Node => {
  const [open, setOpen] = useState<boolean>(false)
  const [showLoginFailedBanner, setShowLoginFailedBanner] = useState<?string>(
    null
  )

  const step = useSelector((state) => state.onlineBooking.step)

  const renderLoginFailedBanner = () => {
    const message = 'Login failed.'
    return <Banner variant={'danger'}>{message}</Banner>
  }

  const renderAccountCreatedBanner = () => {
    const message =
      'Account created successfully. Please check your email for setting a password for your new Nordhealth Connect account.'
    return <Banner variant={'success'}>{message}</Banner>
  }

  return (
    <Fragment>
      <Fragment>
        {showLoginFailedBanner && (
          <div className='banner-container'>{renderLoginFailedBanner()}</div>
        )}
      </Fragment>
      <Fragment>
        {props.showAccountCreatedBanner && (
          <div className='banner-container'>{renderAccountCreatedBanner()}</div>
        )}
      </Fragment>
      <ConfirmationDetails
        displayConfirmationTitle={step !== 6}
        bookingData={props.bookingData}
        isMobile={true}
      />
      {!props.hideConfirmationFooter && (
        <CustomConfirmationFooter setOpen={setOpen} />
      )}
      {props.isSuccessfulBookingView && <SuccessfulBookingFooter />}
      <BookingLoginModal
        open={open}
        setOpen={setOpen}
        showProceedAsGuest={true}
        setShowLoginBanner={setShowLoginFailedBanner}
        forceTransition
        secondLogin
      />
    </Fragment>
  )
}

const SuccessfulBookingFooter = (): Node => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.id)
  const isLoggedIn = Boolean(userId)
  const handleBackToStart = () => {
    // If user is logged in, we clear the state
    // But do not navigate to the landing page
    dispatch(resetBooking(!isLoggedIn))
    if (isLoggedIn) {
      navigator.navigate('/my-appointments')
    }
  }

  const label = isLoggedIn ? 'Go to my appointments' : 'Back to start'

  return <ConfirmationFooter label={label} onConfirm={handleBackToStart} />
}

const CustomConfirmationFooter = ({ setOpen }: { setOpen: Function }) => {
  const handleProceed = () => setOpen(true)
  return <ConfirmationFooter onConfirm={handleProceed} />
}

export default ConfirmBookingMobileLayout
