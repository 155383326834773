// @flow
import self from 'autobind-decorator'
import React, { Component, Fragment } from 'react'

import connect from 'bound-connect'
import Alert from 'components/generic/Alert'
import type { Alert as AlertType } from 'reducers/core'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { removeAlert } from 'actions/core'
import { isEqual } from 'utils/resolve'

import type { StateType } from 'store/initialState'


type AlertsProps = {
  items: Map<string, AlertType>,
  login?: boolean,
  dismiss?: Function,
  title?: string,
  notranslate?: boolean
}

type AlertsState = {
  detached: boolean,
  height: number,
}


@connect
export default class Alerts extends Component<AlertsProps, AlertsState> {

  element: Element
  y: number

  static actions (dispatch: Function): Object {
    return {
      dismiss: (id: number) => dispatch(removeAlert(id)),
    }
  }

  static properties: Object = (state: StateType) => ({
    items: state.core.alerts || new Map()
  })

  state: AlertsState = {
    detached: false,
    height: 0,
  }

  attach () {
    this.setState({
      detached: false,
      height: 0,
    })
  }

  detach () {
    this.setState({
      detached: true,
      height: this.element.getBoundingClientRect().height
    })
  }

  @self
  onPageScroll () {
    let scroll_y = window.scrollY

    if (this.y < scroll_y)
      this.detach()
    else
      this.attach()
  }

  componentDidMount () {
    // Clear alerts on login, but not logout
    const alerts = [ ...this.props.items.values() ]
    if (alerts && !this.props.login)
      alerts.forEach(alert => {
        this.dismiss(alert.id)
      })
    this.y = calculateVerticalOffset(this.element)
    window.addEventListener('scroll', this.onPageScroll)
    this.onPageScroll()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onPageScroll)
  }

  shouldComponentUpdate (props: AlertsProps, state: AlertsState): boolean {
    if (!isEqual(this.props.items, props.items))
      return true
    return state.detached !== this.state.detached
  }

  dismiss (id: number) {
    this.props.dismiss && this.props.dismiss(id)
  }

  render (): React$Element<*> {
    return <Fragment>
      { this.renderAlerts(this.state.detached) }
    </Fragment>
  }

  renderAlerts (detached: boolean): React$Element<*> {
    const updateReference = ref => ref && (this.element = ref)
    let className = 'alerts' + (detached ? ' detached' : ' static')
    if (this.props.login)
      className += ' login'

    const alerts = [ ...this.props.items.values() ]
    const Wrapper = detached ? Fragment : SlideDown
    const wrapperProps = detached ? {} : { transitionOnAppear: false }
    const mappedAlerts = alerts.map(item => {
      if (item.isAnnouncement || item.notranslate)
        return <Alert
          notranslate
          key={ item.id }
          id={ item.id }
          text={ item.content }
          level={ item.style }
        />
      return <Alert
        key={ item.id }
        id={ item.id }
        text={ item.content }
        level={ item.style }
        title={ item.title }
      />
    })

    return <Fragment>
      <div
        ref={ updateReference }
        className={className}>
        <Wrapper { ...wrapperProps }>
          { mappedAlerts }
        </Wrapper>
      </div>
      { this.getPlaceholder() }
    </Fragment>
  }

  getPlaceholder (): ?React$Element<*> {
    return this.state.detached
      ? <div
        key='alerts-placeholder'
        style={{ height: this.state.height }} />
      : null
  }

}


function calculateVerticalOffset (element: Element) {
  if (!(element instanceof HTMLElement))
    return 0

  let y = element.offsetTop

  while (element === element.offsetParent)
    y += element.offsetTop
  return y
}
