// @flow
import React from 'react'
import { Stack } from '@nordhealth/react'

import EmployeeCard from '../extra/EmployeeCard'

import type { Employee } from 'reducers/onlineBooking'
import type { Node } from 'react'

const SelectEmployeeMobileLayout = ({ employees }: { employees: Employee[] }): Node => {
  return (
    <Stack>
      <EmployeeList employees={employees} />
    </Stack>
  )
}

type EmployeeListProps = {
  employees: Employee[],
  isDesktop?: boolean,
  setShowDrawer?: Function,
}

const EmployeeList = ({
  employees,
  isDesktop = false,
  setShowDrawer,
}: EmployeeListProps): Node => {
  return (
    <>
      {employees &&
        employees.map((emp: Employee) => (
          <EmployeeCard
            key={emp.id}
            isDesktop={isDesktop}
            employee={emp}
            setShowDrawer={setShowDrawer}
          />
        ))}
    </>
  )
}

export default SelectEmployeeMobileLayout
