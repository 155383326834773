// @flow
import React from 'react'
import ErrorPage from './ErrorPage'
import navigator from 'routes/base'
import { Paragraph } from 'components/text'
import { SimpleLink } from 'components/generic'
import __ from 'utils/gettext'


export default function ForbiddenPage (style?: Object) {
  return <ErrorPage
    errorCode={ 403 }
    errorName={ __('Forbidden') }
    style={ style }>
    <ForbiddenPageContents />
  </ErrorPage>
}

const ForbiddenPageContents = () => <section>
  <Paragraph>{ 'Sorry. You are not allowed to access this page.' }</Paragraph>
  <Paragraph
    params={{
      here: '<span id="replace" name="here"></span>'
    }}
    parseParameters={{
      here: {
        component: SimpleLink,
        text: 'here',
        props: { strong: true, onClick: () => navigator.previous() },
      }
    }}>
    { 'You can get back to where you came from by clicking ${here}.' }
  </Paragraph>
  <Paragraph
    params={{
      here: '<span id="replace" name="here"></span>'
    }}
    parseParameters={{
      here: {
        component: SimpleLink,
        text: 'here',
        props: { strong: true, onClick: () => navigator.navigate('/threads/') },
      }
    }}>
    { 'Or you can navigate to the home page from ${here}.' }
  </Paragraph>
</section>
