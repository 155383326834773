/**
 * @module SelectChoiceField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import React, { PureComponent, Component } from 'react'
import self from 'autobind-decorator'
import Select from 'react-select'
import { SlideDown } from 'react-slidedown'
import { SecondaryButton } from 'components/buttons'
import { UserSelectField } from 'components/fields'
import { Badge } from 'components/generic'
import Icons from 'components/icons'
import { __ } from 'utils/gettext'
import { isEqual } from 'utils/resolve'

import UserSelectionField from 'views/Page/UserSelectionField'
import { styles } from 'react-drf/fields/SelectField'

export type ChoiceType = {|
  value: number,
  display_name: string,
  label?: string,
|}

type ValueType = Array<ChoiceType> | null

type FieldPropsType = {
  value:    ValueType,
  choices:  Array<ChoiceType>,
  onChange: Function,
  initial?: Array<ChoiceType> | number | string,
  buttonLabel?: string,
  search?: boolean,
  placeholder?: string,
  constructUrl: Function,
  clear?: boolean,
  filter?: Function,
  multiple?: boolean,
  nolabel?: boolean,
  id?: string | number,
  label?: string,
  choicesFromProps?: boolean,
  showAddButton?: boolean,
  stacked?: boolean,
}

type FieldStateType = {
  value:     Array<ChoiceType>,
  choices:   Array<ChoiceType>,
  open:      boolean,
}


export default class SelectChoiceField extends PureComponent<FieldPropsType, FieldStateType> {

  static defaultProps = {
    choices: [],
    multiple: true,
    showAddButton: true,
    stacked: false,
  }

  _opening: boolean = true
  state = {
    value: [],
    choices: [],
    open: false,
  }

  componentDidUpdate () {
    if (this.props.clear && !this.props.value)
      this.clearSelection(this.onChange)
  }

  //eslint-disable-next-line complexity
  componentDidMount () {
    if (typeof this.props.constructUrl !== 'function')
      throw new TypeError(`The "constructUrl" property of SelectChoiceField must be
                            of type "Function" not "${typeof this.props.constructUrl}"`)

    const choices = this.props.choices.slice(0)
    let   value   = this.props.value || []
    const initial = this.props.initial

    let open = false
    if (!this.props.multiple && (!value || Array.isArray(value)))
      open = true

    if (initial && (!value || !value.length)) {

      this.props.choices.forEach( (choice, index) => {
        if (typeof initial === 'number'
            || typeof initial === 'string') {
          const initialValue = Number.parseInt(`${initial}`)
          if (choice.value === initialValue)
            value.push(...choices.splice(index, 1))
        }
        else if (Array.isArray(initial)) {
          const initialChoice = initial.find( initial => choice.value === initial.value )
          if (initialChoice)
            value.push(...choices.splice(index, 1))
        }
        else {
          throw new TypeError(`SelectChoiceField: The type "${typeof this.props.initial}" of props.initial
                               is not supported. Expected either "number" or "Array<ChoiceType>".`)
        }
      })

    }

    if (value && !Array.isArray(value))
      value = this.idToValue(Number(value))
    this.setState({ value, choices, open }, this.onChange)
  }

  idToValue (id: number): Array<ChoiceType> | null {
    for (let choice of this.props.choices)
      if (choice.value === id)
        return [ choice ]
    return null
  }

  @self
  //eslint-disable-next-line complexity
  onSelection (option: *) {
    let value  = this.state.value
    const choices = this.props.choicesFromProps ? this.props.choices : this.state.choices
    const selection = !isNaN(option) ? option : option.value
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].value === selection) {
        if (!this.props.multiple) {
          choices.push(...value)
          value = choices.splice(i, 1)
        }
        else
          value.push(...choices.splice(i, 1))
        break
      }
    }
    this.setState({ value, choices }, this.onChange)
    if (value && value.length)
      this.toggle()
  }

  @self
  onRemoveSelection (option: ChoiceType) {
    const value  = this.state.value
    const choices = this.state.choices
    let open = this.state.open
    for (let i = 0; i < value.length; i++) {
      if (value[i].value === option.value) {
        const removed = value.splice(i, 1)[0]
        if (!removed.label)
          removed.label = removed.display_name
        choices.push(removed)
        break
      }
    }
    if (!this.props.multiple)
      open = true
    this.setState({ value, choices, open }, this.onChange)
  }

  clearSelection (callback?: Function) {
    const open = !this.props.multiple ? true : this.state.open
    this.setState({ value: [], open }, callback)
  }

  @self
  onChange () {
    const values = this.state.value.map(value => value && value.value)
    this.props.onChange(values)
  }

  @self
  toggle () {
    this.setState({ open: !this.state.open })
  }

  render () {
    if (this.props.stacked && this.props.multiple)
      return this.renderStacked()
    else if (this.props.multiple)
      return this.renderMulti()
     return this.renderSingle()
  }

  renderMulti () {
    return <div className='select-choice-field'>
      <SlideDown>
        <div className='selection'>
          <div className='selected'>
            { this.state.value.map(this.valueToLink) }
          </div>

          <div>
            { !this.state.open
              ? <SecondaryButton small onClick={ this.toggle }>{ this.props.buttonLabel || 'Add new form' }</SecondaryButton>
              : <SecondaryButton small onClick={ this.toggle }>Cancel</SecondaryButton> }
          </div>
        </div>
        <div className='selector'>
          { this.props.search
            ? this.renderSearch()
            : this.renderSelect() }
        </div>
      </SlideDown>
    </div>
  }

  renderSingle () {
    const className= `selected${ this.props.nolabel && !this.props.multiple ? ' single-line' : '' }`
    return <div className='select-choice-field'>
      <SlideDown>
        <div className='selection'>
          <div className={ className }>
            { this.props.nolabel &&
              <label className='label field-label' htmlFor={ this.props.id }>{ this.props.label }</label> }
            { this.state.value.map(this.valueToLink) }
          </div>
        </div>
        <div className='selector'>
          { this.props.search
            ? this.renderSearch()
            : this.renderSelect() }
        </div>
      </SlideDown>
    </div>
  }

  renderStacked () {
    return <div className='select-choice-field'>
      <SlideDown>
        <div className='selection'>
          <div className='selected single-line'>
            { this.props.nolabel &&
              <label className='label field-label' htmlFor={ this.props.id }>{ this.props.label }</label> }
            <div className='values-container'>
              { this.state.value.map(this.valueToLink) }
            </div>
          </div>
        </div>
        { this.props.showAddButton && <div className='selector'>
            { this.props.search
              ? this.renderSearch()
              : this.renderSelect() }
          </div> }
      </SlideDown>
    </div>
  }

  renderSearch () {
    if (!this.state.open) return null
    return <UserSelectField
      value={ null }
      choices={ this.props.choices }
      onDidUpdate={ choices => this.onSelection(choices[0]) }
      formField
      placeholder={ this.props.placeholder }
      minWidth='25rem' />
  }

  renderSelect () {
    if (!this.state.open && !this.props.stacked) return null
    const choices = this.props.choicesFromProps ? this.props.choices : this.state.choices
    return <SelectField
      value={ '' }
      choices={ choices }
      onChange={ this.onSelection } />
  }

  @self
  valueToLink (option: ChoiceType, key: number) {

    const onRemove = event => {
      event.stopPropagation()
      this.onRemoveSelection(option)
    }

    return option && <Badge onRemove={ onRemove } key={ key }>
      <a href={ this.props.constructUrl(option) }
        target='_blank'
        rel='noopener noreferrer' >
        { option.display_name }
      </a>
      <Icons.OpenInNew size={ 12 } ariaLabel={ __('Open link in a new tab') } />
    </Badge>
  }
}

type OptionType = {
  value: string,
  label: string,
}

type ChoiceFieldProps = {
  value: ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  placeholder?: string,
}

type ChoiceFieldState = {
  options: Array<OptionType>
}


class SelectField extends Component<ChoiceFieldProps, ChoiceFieldState> {

  static defaultProps = {
    value:   null,
    choices: [],
  }

  state = {
    options: []
  }

  componentDidMount () {
    this.setState({ options: this.sortedChoices })
  }

  componentDidUpdate (oldProps: ChoiceFieldProps) {
    if (!isEqual(this.props.choices, oldProps.choices))
      this.setState({ options: this.sortedChoices })
  }

  get sortedChoices (): Array<OptionType> {
    let options: Array<Object> = this.props.choices || []
    if (options[0] && !options[0].label)
      options = options.map(option => {
        option.label = option.display_name
        return option
      })

    return options.sort((choiceA, choiceB) => {
      if (choiceA.label > choiceB.label)
        return 1
      if (choiceB.label > choiceA.label)
        return -1
      return 0
    })
  }

  render () {
    const onChange = option =>
      this.props.onChange(Number(option.value))

    return <Select
      styles={styles}
      value={ this.props.value }
      options={ this.state.options }
      onChange={ onChange }
      searchable={ false }
      clearable={ false }
      className='Select choice-field'
      classNamePrefix='Select'
      components={{ IndicatorSeparator: () => null }}
      placeholder={ this.props.placeholder || '' } />
  }
}
