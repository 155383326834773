// @flow
import React, { useEffect } from 'react'
// $FlowFixMe
import { Card, Icon, Stack } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'
import { useDispatch, useSelector } from 'react-redux'

import {
  increaseStep,
  selectAllLocations,
  selectLocation,
} from 'actions/onlineBooking'
import type { Location } from 'reducers/onlineBooking'

import withOnlineBookingModal from '../extra/OnlineBookingModal'
import BookingErrorView from './BookingErrorView'

import type { Node } from 'react'

const SelectLocationView = (): Node => {
  const dispatch = useDispatch()
  const listOfLocations = useSelector(
    (state) => state.onlineBooking.all_locations
  )

  useEffect(() => {
    if (listOfLocations && listOfLocations.length === 1) {
      dispatch(selectLocation(listOfLocations, true))
      dispatch(increaseStep())
    }
  }, [])

  if (!listOfLocations || listOfLocations.length === 0) {
    return <BookingErrorView errorString='No locations were found' />
  }

  return (
    <div className='stack-wrapper'>
      <Stack>
        <LocationsList listOfLocations={listOfLocations} />
      </Stack>
      <div style={skipButtonContainer}>
        <Button
          variant='primary'
          onClick={() => {
            dispatch(selectAllLocations(listOfLocations || []))
            dispatch(increaseStep())
          }}
        >
          Skip this step
        </Button>
      </div>
    </div>
  )
}

const LocationsList = ({
  listOfLocations,
}: {
  listOfLocations: Location[],
}): Node => {
  return (
    <>
      {listOfLocations &&
        listOfLocations.map((location: Location) => (
          <LocationItem key={location.name} location={location} />
        ))}
    </>
  )
}

const LocationItem = ({ location }: { location: Location }): Node => {
  const dispatch = useDispatch()

  const { street_address, post_office, postal_code } = location.address

  const addressText = street_address
    ? `${street_address}, ${postal_code} ${post_office}`
    : 'No address specified'

  return (
    <Card>
      <div className='card-layout'>
        <span className='location-layout'>
          <span className='location-layout-name'>{location.name}</span>
          <span className='n-color-text-weakest location-layout-address'>
            <Icon size='s' name='generic-location'></Icon>{' '}
            <span className='location-layout-address-text'>{addressText}</span>
          </span>
        </span>
        <Button
          onClick={() => {
            dispatch(selectLocation([location]))
            dispatch(increaseStep())
          }}
          variant='primary'
          size='m'
        >
          Choose
        </Button>
      </div>
    </Card>
  )
}

const skipButtonContainer = {
  paddingTop: 16, // Set according to the stack gap size='m'
  textAlign: 'center',
}

export default (withOnlineBookingModal(SelectLocationView): *)
