// @flow
import React, { useState, useEffect } from 'react'
// $FlowIgnore
import { useSelector } from 'react-redux'
import PageNotFound from 'views/Error/PageNotFound'
import api from 'api'

import AppointmentHeader from './components/AppointmentHeader'
import AppointmentList from './components/AppointmentList'
import { AppointmentsProvider } from './AppointmentsContext'
import type { Appointment } from './components/AppointmentCard'

import { isPastDate } from 'utils/dateUtils'

import { Spinner, EmptyState, Banner } from '@nordhealth/react'

import { __ } from 'utils/gettext'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'
import type { OptionObject as Option } from 'components/Switch'
import type User from 'models/User'

import { future_appointments, past_appointments } from './test_data'
import useAppointments from './AppointmentsContext'

export const APPOINTMENT_URLS = {
  APPOINTMENTS: '/online-booking/appointments',
  CANCEL_APPOINTMENT: '/online-booking/cancel-appointment',
}

const options: Option[] = [
  { key: true, name: 'Past' },
  { key: false, name: 'Upcoming' },
]

const MyAppointments = (): Node => {
  const [loading, setLoading] = useState<boolean>(false)
  const [pastAppointments, setPastAppointments] = useState<Appointment[]>([])
  const {
    upcomingAppointments,
    setUpcomingAppointments,
    banner,
    showPast,
    setShowPast,
  } = useAppointments()

  const showMyAppointments = useSelector(
    (state: StateType) => state.tenantSettings.show_my_appointments
  )

  const user: User = useSelector((state: StateType) => state.user)

  const userSsn = useSelector(
    (state: StateType) => state.user.profile.social_security_number
  )

  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true)
      const res = await api.get(APPOINTMENT_URLS.APPOINTMENTS)
      if (res.ok) {
        const [past, upcoming] = filterAppointmentByDate(res.data)
        setPastAppointments(past)
        setUpcomingAppointments(upcoming)
      }
      setLoading(false)
    }
    fetchAppointments()
  }, [])

  if (loading) return <Spinner size='xxl'></Spinner>

  if (user.is_staff) return <PageNotFound />

  if (!showMyAppointments || !userSsn)
    return (
      <div>
        <EmptyState>
          <h1>{__('Information is missing')}</h1>
          <p>
            {__(
              'We need some more information about you before we can show your appointments. Please contact your clinic or therapist.'
            )}
          </p>
        </EmptyState>
      </div>
    )

  return (
    <div>
      {banner && <Banner variant={banner.variant}>{banner.text}</Banner>}
      <AppointmentHeader
        options={options}
        showPast={showPast}
        setShowPast={setShowPast}
      />
      <AppointmentList
        upcomingAppointments={upcomingAppointments}
        pastAppointments={pastAppointments}
        showPast={showPast}
      />
    </div>
  )
}

const AppointmentsWrapper = (): Node => {
  return (
    <AppointmentsProvider>
      <MyAppointments />
    </AppointmentsProvider>
  )
}

const filterAppointmentByDate = (appointments: Appointment[]) => {
  let past = []
  let upcoming = []
  appointments.forEach((appointment: Appointment) => {
    const { start } = appointment
    const [date, time] = start.split(' ')
    if (isPastDate(date)) {
      past.push(appointment)
    } else {
      upcoming.push(appointment)
    }
  })

  const sorting = (a, b, direction: 'ASC' | 'DEC') => {
    const [a_date, a_time] = a.start.split(' ')
    const [b_date, b_time] = b.start.split(' ')
    const date1 = new Date(a_date)
    const date2 = new Date(b_date)

    return direction === 'ASC' ? date1 - date2 : date2 - date1
  }

  upcoming.sort((a, b) => sorting(a, b, 'ASC'))
  past.sort((a, b) => sorting(a, b, 'DEC'))

  return [past, upcoming]
}

export default AppointmentsWrapper
