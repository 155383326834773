// @flow
import React, { PureComponent } from 'react'
import { resolveClass } from 'utils/resolve'

import {
  MdAdd as AddIcon,
  MdDelete as RemoveIcon,
  MdCheck as SuccessIcon,
  MdCheckCircle as FilledSuccessIcon,
  MdChevronLeft as BackIcon,
  MdChevronRight as NextIcon,
  MdLastPage as LastIcon,
  MdFirstPage as FirstIcon,
  MdRefresh as RefreshIcon,
  MdClose as CancelIcon,
  MdArchive as ArchiveIcon,
  MdUnarchive as UnarchiveIcon,
  MdStar as StarIcon,
  MdHelp as HelpIcon,
  MdHelpOutline as HelpIconOutline,
  MdVisibilityOff as VisibilityOffIcon,
  MdVisibility as VisibilityIcon,
  MdDoneAll as MessageReadIcon,
  MdDone as CheckmarkIcon,
  MdCloudDownload as DownloadIcon,
  MdBlock,
  MdError as Error,
  MdErrorOutline as ErrorOutline,
  MdLink as LinkIcon,
  MdEdit as EditIcon,
  MdReorder as DragIcon,
  MdPowerSettingsNew as LogoutIcon,
  MdContentCopy as DuplicateIcon,
  MdSettings as ManagementIcon,
  MdSupervisorAccount as ActOnBehalfIcon,
  MdPerson as UserIcon,
  MdMoreVert as MoreIcon,
  MdMoreHoriz as MoreIconHrz,
  MdMenu as MenuIcon,
  MdSearch as SearchIcon,
  MdLockOutline as LockIcon,
  MdLockOpen as LockIconOpen,
  MdViewList as ShowListIcon,
  MdViewModule as ShowGridIcon,
  MdHistory as HistoryIcon,
  MdOpenInNew as OpenInNew,
  MdNotifications as Notifications,
  MdPeople as UserGroup,
  MdRotateLeft as RotateLeft,
  MdRotateRight as RotateRight,
  MdArrowForward as ForwardIcon,
  MdArrowDropDown as ToggleArrow,
  MdKeyboardArrowDown as CaretDown,
  MdKeyboardArrowRight as CaretRight,
  MdKeyboardArrowUp as CaretUp,
  MdArrowDownward as ArrowDown
} from 'react-icons/md'

import { IoMedical as AsteriskIcon } from 'react-icons/io5'

import {
  FaSlidersH as FiltersIcon,
  FaFileAlt as FileText,
  FaFile as FileGeneral,
  FaRegFilePdf as FilePdf
} from 'react-icons/fa'

import type { ComponentType } from 'react'

type BaseIconProps = {
  icon: ComponentType<*>,
  size?: number,
  color?: string,
  ariaLabel?: string,
} & StatePropsType

type IconProps = {|
  size: number,
  color: string,
|}


export class BaseIconComponent extends PureComponent<BaseIconProps> {
  render (): React$Element<*> {
    const className     = resolveClass(this.props, 'svg')
    const IconComponent = this.props.icon
    const fontSize      = this.props.size
    const color         = this.props.color
    const ariaLabel     = this.props.ariaLabel ? this.props.ariaLabel : null
    const style = { fontSize, color, }

    return <span className={ className } aria-label={ ariaLabel }>
      <IconComponent style={ style } />
    </span>
  }
}

const icon = iconComponentClass => {
  const C = (props: IconProps) => <BaseIconComponent
    icon={ iconComponentClass }
    { ...props }
  />

  C.displayName = `${iconComponentClass.displayName || iconComponentClass.name.replace(/icon|md/gi, '')} Icon`

  return C
}


export {

  AddIcon,
  BackIcon,
  NextIcon,
  LastIcon,
  FirstIcon,
  RotateLeft,
  RotateRight,
  ForwardIcon,
  RefreshIcon,
  RemoveIcon,
  SuccessIcon,
  CancelIcon,
  FilledSuccessIcon,
  ArchiveIcon,
  MdBlock,
  Error,
  ErrorOutline,

  MoreIcon,
  MenuIcon,
  HistoryIcon,
  FiltersIcon,
  ShowListIcon,
  ShowGridIcon,

  LinkIcon,
  EditIcon,
  DragIcon,
  DuplicateIcon,
  VisibilityOffIcon,
  VisibilityIcon,
  MessageReadIcon,
  CheckmarkIcon,
  ToggleArrow,
  SearchIcon,
  CaretDown,
  CaretRight,
  CaretUp,
  HelpIcon,
  HelpIconOutline,
  DownloadIcon,

  ActOnBehalfIcon,
  ManagementIcon,
  LockIconOpen,
  LogoutIcon,
  UserIcon,
  LockIcon,
  OpenInNew,
  Notifications,
}



const icons: Object = {
  // Common
  Add:            icon(AddIcon),
  Back:           icon(BackIcon),
  Next:           icon(NextIcon),
  Last:           icon(LastIcon),
  First:          icon(FirstIcon),
  Forward:        icon(ForwardIcon),
  Refresh:        icon(RefreshIcon),
  Remove:         icon(RemoveIcon),
  Success:        icon(SuccessIcon),
  RotateLeft:     icon(RotateLeft),
  RotateRight:    icon(RotateRight),
  SuccessFilled:  icon(FilledSuccessIcon),
  Cancel:         icon(CancelIcon),
  Archive:        icon(ArchiveIcon),
  Unarchive:      icon(UnarchiveIcon),
  Block:          icon(MdBlock),
  Error:          icon(Error),
  ErrorOutline:   icon(ErrorOutline),

  More:           icon(MoreIcon),
  Menu:           icon(MenuIcon),
  Filters:        icon(FiltersIcon),
  ShowList:       icon(ShowListIcon),
  ShowGrid:       icon(ShowGridIcon),
  History:        icon(HistoryIcon),
  MoreHrz:        icon(MoreIconHrz),

  Help:           icon(HelpIcon),
  HelpOutline:    icon(HelpIconOutline),
  Link:           icon(LinkIcon),
  Star:           icon(StarIcon),
  Lock:           icon(LockIcon),
  Search:         icon(SearchIcon),
  LockOpen:       icon(LockIconOpen),
  Asterisk:       icon(AsteriskIcon),
  ArrowDown:      icon(ArrowDown),
  Visibility:     icon(VisibilityIcon),
  VisiibilityOff: icon(VisibilityOffIcon),
  MessageRead:    icon(MessageReadIcon),
  Checkmark:      icon(CheckmarkIcon),
  Download:       icon(DownloadIcon),
  OpenInNew:      icon(OpenInNew),
  FileText:       icon(FileText),
  FileGeneral:    icon(FileGeneral),
  FilePdf:        icon(FilePdf),
  Notifications:  icon(Notifications),
  UserGroup:      icon(UserGroup),

  // Editor icons
  Edit:           icon(EditIcon),
  Drag:           icon(DragIcon),
  Duplicate:      icon(DuplicateIcon),
  Caret:          icon(CaretDown),
  CaretRight:     icon(CaretRight),
  CaretUp:        icon(CaretUp),

  // View symbols
  ActOnBehalf:    icon(ActOnBehalfIcon),
  Management:     icon(ManagementIcon),
  Logout:         icon(LogoutIcon),
  User:           icon(UserIcon),
}

export default icons