/**
 * @module SingleToggleField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import React, { PureComponent } from 'react'
import { Checkbox } from 'react-toggle-components'


type ValueType = boolean | null

type ChoiceFieldProps = {
  display_name: string,
  value: ValueType,
  onChange: Function,
  disabled?: boolean,
}


export default class SingleToggleField extends PureComponent<ChoiceFieldProps> {

  static defaultProps = {
    value:   null,
  }

  render () {
    const onChange = value =>
      this.props.onChange(value)

    return <label>
      <Checkbox
        checked={ this.props.value }
        disabled={ !!this.props.disabled }
        onChange={ onChange } />
    </label>
  }
}
