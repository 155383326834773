// @flow

import self from 'autobind-decorator'
import React, { createRef } from 'react'
import connect from 'bound-connect'
import Select, { components } from 'react-select'
import Async from 'react-select/async'
import { CancelablePromise } from 'utils/promise'

import { DEFAULT_PRIMARY_COLOR } from 'constants'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'

import Field from './Field'

type Item = {
  label: string,
  value: number,
}

type ValueType = Array<Item>

type OptionsReturnType = ValueType | Promise<ValueType>
type LoadOptionsReturnType = { options: ValueType } | Promise<{ options: ValueType }>

type Props = {
  name?: string,
  value?: Item | number | string | null,
  multiple?: boolean,
  placeholder?: string,
  noResultsText?: string,
  options?: ValueType | (string) => OptionsReturnType,
  loadOptions?: (string) => LoadOptionsReturnType,
  onDidUpdate?: (ValueType) => void,
  onDidClear?: (ValueType) => void,
  filter?: (Object, string) => boolean,
  cache?: boolean,
  onKeyDown?: Function,
  minWidth?: string,
  selectProps: Object,
  nolabel?: boolean,
  customUpdate?: (value: ValueType) => ValueType,
  selectProps?: Object
}

type State = {
  inputValue: string,
  value: ValueType | null,
  options: ValueType,
}


@connect
export default class SelectField extends Field<Props, State> {

  static NO_RESULTS: string = 'No results'
  static actions: Array<*> = []
  static properties: Object = (state: StateType) => ({
    primaryColor:  state.tenantSettings.color,
  })

  _cancelableUpdatePromise: CancelablePromise<*>
  _getUpdatesPromise: Promise<*> | null

  state = {
    inputValue: '',
    value: null,
    options: [],
    errors: null
  }

  field = createRef()

  componentDidMount () {
    this.performUpdates(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value
      || prevProps.options !== this.props.options)
      this.performUpdates(this.props)
  }

  componentWillUnmount () {
    if (this._cancelableUpdatePromise)
      this._cancelableUpdatePromise.cancel()
  }

  isMulti () {
    return this.props.multiple || this.props.type === 'multiple choice' || false
  }

  render () {
    let className = 'field ' + this.getValidationState()
    if (this.props.required) className += ' required'

    let controlLabel = this.renderControlLabel()

    return controlLabel !== null
      ? <label className={className} htmlFor={ this.props.name }><span></span>
        { controlLabel }
        { this.renderSelect() }
      </label>
      : <label className={className} htmlFor={ this.props.name }><div></div>
        { this.renderSelect() }
      </label>
  }

  get component (): * {
    return  Array.isArray(this.props.options)
      ? Select
      : Async
  }

  get options (): Array<Object> | void {
    return Array.isArray(this.props.options)
      ? this.props.options
      : undefined
  }

  renderSelect () {
    let customStyles = {
      ...styles,
      option: (styles, { isFocused }) => {
        const color = this.props.primaryColor
          ? this.props.primaryColor
          : DEFAULT_PRIMARY_COLOR
        return {
          ...styles,
          backgroundColor: isFocused ? color : '#fff',
          color: isFocused ? '#fff' : color
        }
      },
    }
    const isMulti = this.isMulti()
    const Component = this.component
    window.ref = this
    const minWidth = this.props.minWidth

    const showIndicators = Array.isArray(this.value) ? this.value.length > 0 : !!this.value

    // override react-select default indicators (dropdown arrow, ) with custom logic
    let replacedComponents = {
      IndicatorsContainer: (props) =>
        props.selectProps.isLoading || showIndicators ? <components.IndicatorsContainer {...props}/> : null,
      IndicatorSeparator: () => null,
      DropdownIndicator: () => null,
    }

    // if custom components are given in props, merge them with default replaced components
    if (this.props.selectProps && this.props.selectProps.components)
      replacedComponents = { ...replacedComponents, ...this.props.selectProps.components }

    // merge all props and merged components
    const additionalProps = {...this.props.selectProps, components: replacedComponents}

    return <div style={{ width: '100%', minWidth }}>
      <Component
        aria-label={ this.props.ariaLabel || null }
        aria-labelledby={ this.props.ariaLabelledBy || null }
        ref={ this.field }
        isMulti={ isMulti }
        name={ this.name }
        value={ this.value }
        styles={customStyles}
        onChange={ this.update }
        placeholder={ this.props.placeholder || null }
        isSearchable
        isClearable
        noOptionsMessage={ () => this.props.noResultsText || SelectField.NO_RESULTS }
        filterOption={ this.props.filter || Component.filterOption }
        options={ this.options }
        defaultOptions
        loadOptions={ this.loadOptions }
        isDisabled={ this.disabled }
        ignoreAccents={ false }
        cacheOptions={ this.props.cahce }
        onInputChange={ this.onInputChange }
        id={ this.props.name }
        instanceId={ this.props.name }
        className='Select'
        classNamePrefix='Select'
        {...additionalProps}
      />
    </div>
  }

  focus () {
    this.field.current.focus()
  }

  @self
  onInputChange(inputValue) {
    if (this.props.onKeyDown)
      return this.props.onKeyDown(inputValue)
    this.setState({ inputValue })
  }

  @self
  async loadOptions (value: string) {
    if (typeof this.props.loadOptions === 'function')
      return await this.props.loadOptions(value)
    if (this.props.options instanceof Promise)
      return await this.props.options
    if (typeof this.props.options === 'function')
      return await this.props.options(value)
    if (this.props.choices) {
      let mapped = mapChoicesToOptions(this.props.choices)

      if (value) // filter choices depending on input
        mapped = mapped.filter(choice => choice.label.toLowerCase().includes(value.toLowerCase()))

      return mapped
    }
    return this.state.options
  }

  @self
  // eslint-disable-next-line class-methods-use-this
  renderValue (option: Item): Node {
    return <span>{ option.label }</span>

    // <span className='icon-container'><RemoveIcon></RemoveIcon></span>
  }

  @self
  async update (value: ValueType) {
    if (!value) value = []
    value = spliceDefaultValues(value)
    if (this.props.customUpdate)
      value = await this.props.customUpdate(this.state.value, value)
    this.setState({ value }, () => {
      if (this.props.onDidUpdate)
        this.props.onDidUpdate(value)
    })
  }

  clear () {
    this.setState({ value: []}, () => {
      if (this.props.onDidClear)
        this.props.onDidClear([])
    })
  }

  // eslint-disable-next-line class-methods-use-this
  get type (): string {
    return 'select-multiple'
  }

  get value (): ValueType | Item | null {
    const { value } = this.state
    const isArray = Array.isArray(value)
    const hasNumberOption = isArray && value.find(option => typeof option === 'number')
    const hasStringOption = isArray && value.find(option => typeof option === 'string')

    // react-select expects options to be an object that has keys label and value
    // if value has option(s) that are of type number or string
    // then find the matching option from props
    if (hasNumberOption || hasStringOption) {
      return resolveValue(
        this.state.value,
        this.state.options || mapChoicesToOptions(this.props.choices)
      )
    }

    if (value?.name) return {...value, label: value.name }
    else {
      if (value && this.state.options && this.state.options.find(e=>e.value == value && e.label != '----')) return this.state.options.find(e=>e.value == value)
      else return value
    }
  }

  get name (): string | void {
    return this.props.name
  }

  // eslint-disable-next-line complexity
  toJSON (): { [name: string]: ValueType} {
    let rv
    if (this.name && this.value && this.value.value &&  this.props.choices)
      rv = { [this.name]: this.value.value }
    else if (this.name && this.value)
      rv = { [this.name ]: this.value.map(item => item.value || item) }
    else if (this.name)
      rv = { [this.name]: []}
    if (rv) {
      return rv
    }
    throw new TypeError('Trying to serialize a SelectField that is missing the name property.')
  }

  performUpdates (props: Props) {
    this._getUpdatesPromise = this.getUpdates(props)
    this._cancelableUpdatePromise = new CancelablePromise(this._getUpdatesPromise)
    this._cancelableUpdatePromise.then(updates => {
      if (Object.keys(updates).length) {
        this.setState(updates)
      }
    })
  }

  // eslint-disable-next-line complexity
  async getUpdates (props: Props) {
    const updates = {}

    if ('options' in props) {
      if (props.options instanceof Promise)
        updates.options = await props.options
      else if (typeof props.options === 'function')
        updates.options = await props.options(null)
      else if (props.options instanceof Array)
        updates.options = props.options
      else
        updates.options = this.state.options
    } else if ('choices' in props)
      updates.options = mapChoicesToOptions(props.choices)

    if ('initial' in props)
      updates.value = resolveValue(props.initial, updates.options)

    if ('value' in props)
      updates.value = resolveValue(props.value, updates.options)

    return updates
  }

}

function resolveValue (value: Item | number | string | null, options): Item | null {
  if (Array.isArray(value)) // if value is array, do recursive resolveValue for each item
    return value.map(v => resolveValue(v, options))
  if (typeof value === 'number')
    return options.find(option => parseInt(option.value) === value) || null
  if (typeof value === 'string')
    return options.find(option => option.label === value) || null
  return value
}


function mapChoicesToOptions (choices) {
  return choices.map( choice => ({
    value: choice.value,
    label: choice.display_name,
  }))
}

function spliceDefaultValues(value) {
  if (Array.isArray(value)) {
    const defaultValues = value.filter(value => value.value === '')
    if (defaultValues && defaultValues.length > 0) {
      const idx = value.indexOf(defaultValues[0])
      value.splice(idx, 1)
    }
  }

  return value
}

// below object is used to reset default styles of react-select
// so that they don't override styles defined in less files
export const styles = {
  container: () => {},
  control: () => {},
  valueContainer: () => {},
  placeholder: () => {},
  input: (): Object => ({ margin: '0 4px', flex: '1 0 6rem' }),
  singleValue: (): Object => ({ zIndex: 2 }),
  multiValue: () => {},
  multiValueLabel: () => {},
  multiValueRemove: () => {},
  menu: () => {},
  option: () => {},
}


