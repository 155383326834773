//@flow

import store from '../store'
import { addAlert, clearAlerts } from 'actions/core'

/**
 * Adds a alert to the alerts list
 * @param  {string} type                      The type of the alert (info, error, success, warning)
 * @param  {string|React$Element<*>} content  The content of the alert. Either a string or a react element
 * @param  {number} duration                  How many route changes the alert should stay visible
 * @return {void}                             No return value
 */
export function addNewAlert (type: 'info' | 'error' | 'success' | 'warning', content: string | React$Element<*>, duration: number) {
  try {
    store.dispatch(addAlert(type, content, duration))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Failed to show alert:', err)
  }
}

/**
 * Clear all alerts
 * @return {void}
 */
export function clearAllAlerts () {
  store.dispatch(clearAlerts())
}
