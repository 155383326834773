import React, { Component } from 'react'
import connect from 'bound-connect'
import api from 'api'
import self from 'autobind-decorator'

import auth from 'api/auth'
import navigator from 'routes/base'
import { updateShowTerms } from 'actions/user'
import { Toolbar, ToolbarContainer, Loader } from 'components/generic'
import Button, { PrimaryButton } from 'components/buttons'
import { Subtitle, Paragraph } from 'components/text'

import type { StateType } from 'store/initialState'


type ToSPropsType = {}

type ToSStateType = {
  terms: string,
  loaded: boolean,
}


@connect
export default class AcceptTermsOfService extends Component<ToSPropsType, ToSStateType> {

  state = {
    terms: '',
    loaded: false,
  }

  static properties (state: StateType) {
    const user = state.user
    return {
      user,
    }
  }

  static actions (dispatch: Function) {
    return {
      updateShowTerms: (value: boolean) => dispatch(updateShowTerms(value))
    }
  }

  async componentDidMount () {
    const { terms } = (await api.get('/terms/')).data
    this.setState({ terms, loaded: true })
  }

  render () {
    return this.state.loaded
      ? <section className='user-agreement-container'>
        <Subtitle>Terms of service</Subtitle>
        <div className='user-agreement'><Paragraph notranslate>{ this.state.terms || '' }</Paragraph></div>
        <div className='user-agreement-toolbar'>
          <ToolbarContainer>
            { <Toolbar disabled={ this.props.user.profile.show_terms } left>
              <PrimaryButton onClick={ this.onAccept }>I accept</PrimaryButton>
            </Toolbar> }
            <Toolbar right>
              <Button onClick={ auth.clear }>Logout</Button>
            </Toolbar>
          </ToolbarContainer>
        </div>
      </section>
      : <Loader />
  }

  @self
  onAccept () {
    this.props.updateShowTerms(false)
    api.post('/terms/', { accept: true })
    navigator.navigate('/threads/')
  }
}
