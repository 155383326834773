import React, { Fragment } from 'react'

import { useSelector } from 'react-redux'

import TabView from 'components/TabView'
import HeadNavigation from 'views/Page/HeadNavigation'
import { Heading } from 'components/text'
import StaffList from '../Management/Users/StaffList'
import { StaffRoutes } from 'routes/management'
import {
  managementRoutes,
  onlineBookingUserRoutes,
} from 'routes/restrictedTenant'

import { SUBSCRIPTION_TYPE_ONLINE_BOOKING, GROUP_MANAGEMENT } from 'constants'

const RestrictedTenant = () => {
  const user = useSelector((state) => state.user)
  let routes

  if (
    user.profile.active_subscription?.type === SUBSCRIPTION_TYPE_ONLINE_BOOKING
  ) {
    routes = onlineBookingUserRoutes
  }

  if (user.group && user.group.name === GROUP_MANAGEMENT) {
    routes = managementRoutes
  }

  return (
    <Fragment>
      <Heading>Management</Heading>
      {routes}
    </Fragment>
  )
}

export default RestrictedTenant
