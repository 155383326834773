// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


export const descriptors: Object = {
  list: '/documents/',
  confirmSignature: (id: ID) => `/documents/distributions/${id}/confirm-signature/`,
}

const DocumentsView = load(() =>
  import('views/Documents/ListDocuments'))


const ConfirmSignatureView = load(() =>
  import('views/Documents/ConfirmSignatureView'))

export default <Switch>
  <Route exact path={ descriptors.list } component={ DocumentsView } />
  <Route exact path={ descriptors.confirmSignature(':id(\\d+)') } component={ ConfirmSignatureView } />
  <RouteNotFound />
</Switch>