/**
 * @name   FieldSet
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { Component, Fragment } from 'react'
import equal from 'shallowequal'

type PropTypes = {
  value?: Object,
  onChange?: Function,
}

type StateType = {
  value: Object,
  errors: Object | null,
}


export default class FieldSet extends Component<PropTypes, StateType> {

  state = {
    value: {},
    errors: {},
  }


  /**
   * An object containing the React component classes for the fields.
   *
   * @static
   * @property fields
   */


  static fields: Object = {}

  /**
   * List of the fields' identifiers.
   * Use the `keys` getter to access this property.
   *
   * @static
   * @property fieldNames
   */

  static fieldNames: Array<string> = []

  /**
   * A function that returns true if all the required fields have a value
   */
  //eslint-disable-next-line complexity
  static canSubmit (values: Object): boolean {
    if (this.fieldNames)
      for (let fieldName of this.fieldNames ) {
        const field = this.fields[fieldName]
        try {

          // this fails if field is a class and the catch is entered
          const isRequired = field().props.required
          //eslint-disable-next-line
          if (isRequired && !values[fieldName] && values.hasOwnProperty(fieldName)) {
            return false
          }
        } catch (err) {
          return field.canSubmit(values)
        }
      }
    return true
  }

  static getDerivedStateFromProps (props: PropTypes, state: StateType) {
    const updates = {}

    if (props.errors)
      updates.errors = props.errors
    else if (state.errors)
      updates.errors = null

    if (props.value && !equal(props.value, state.value))
      updates.value = props.value

    if (Object.keys(updates).length)
      return updates
    return null
  }

  componentDidMount () {
    this.onChange()
  }

  render () {
    const renderField = id => {
      const Field    = this.fields[id]
      const value    = this.getFieldValue(id)
      const errors   = this.getFieldErrors(id)
      const onChange = value => this.setFieldValue(id, value)

      return <Field
        key={ id }
        value={ value }
        errors={ errors }
        onChange={ onChange }
      />
    }

    return <Fragment>
      { this.keys.map(renderField) }
    </Fragment>
  }

  get keys (): Array<string> {
    return this.constructor.fieldNames
  }

  get fields (): Object {
    return this.constructor.fields
  }

  setFieldValue (field_id: string, value: Object | string): void {
    const payload = {
      ...this.state.value,
      [field_id]: value,
    }
    this.onChange(payload)
  }

  toJSON = (): Object =>
    ({ ...this.state.value })

  getFieldErrors = (field_id: string): Object | null =>
    this.state.errors &&
    this.state.errors[field_id] ||
    null

  getFieldValue = (field_id: string): Object | string | null =>
    this.state.value[field_id]

  onChange = (data?: *) =>
    this.props.onChange &&
    this.props.onChange(data || this.toJSON())

}
