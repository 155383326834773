// @flow
import moment from 'moment'
import formatISO from 'date-fns/formatISO'

export const getDateString = (date: Date): string => {
  const isoStringWithoutTimezone = formatISO(date)
  const [dateString, timezone] = isoStringWithoutTimezone.split('T')
  return dateString
}

export const getDayTransition = (
  date: string,
  action: 'NEXT' | 'PREVIOUS'
): string => {
  // Example: 2022-03-31 => 22-04-01 in case of action === NEXT
  const dateObject = new Date(date)
  dateObject.setDate(
    action === 'PREVIOUS' ? dateObject.getDate() - 1 : dateObject.getDate() + 1
  )
  return getDateString(dateObject)
}

export const isPastDate = (date: string): boolean => {
  const today = new Date().setHours(0, 0, 0, 0)
  const dateObject = new Date(date).setHours(0, 0, 0, 0)
  return dateObject < today
}
