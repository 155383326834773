/**
 * @module MultipleChoiceField
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import self from 'autobind-decorator'
import React, { PureComponent } from 'react'
import { Checkbox } from 'react-toggle-components'


export type ValueType = string | number | null

export type ChoiceType = {
  value: ValueType,
  display_name: string,
}

export type ChoiceFieldProps = {
  value: Array<ValueType> | ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  disabled?: boolean,
}

export type ChoiceFieldState = {
  value: Array<ValueType>,
}


export default class MultipleChoiceField extends PureComponent<ChoiceFieldProps, ChoiceFieldState> {

  static TOGGLE_ALL_VALUE = ''

  static defaultProps = {
    value:   [],
    choices: [],
  }

  state = {
    value: [],
  }

  static getDerivedStateFromProps (props: ChoiceFieldProps) {
    const updates = {}

    if ([ 'string', 'number' ].includes(typeof props.value))
      updates.value = [ parseInt(props.value) ]

    if (Object.keys(updates).length)
      return updates
    return null
  }

  render () {
    const disabled = this.props.disabled

    return <dl className='options multiple'>

      { this.props.choices.map((choice) => {
        const value    = choice.value
        const label    = choice.display_name
        const checked  = this.isChoiceSelected(choice)
        const onChange = () => this.toggle(choice.value)

        return <label key={ value } className='option'>
          <dd>
            <Checkbox
              checked={ checked }
              disabled={ disabled }
              onChange={ onChange } />
          </dd>
          <dt>
            <span className='value'>
              { label }
            </span>
          </dt>
        </label>
      })}
    </dl>
  }

  get allChoiceValues (): Array<number> {
    return this.props.choices
      .map(choice => choice.value)
      .filter(choice => is.number(choice))
  }

  get selectedChoices (): Array<ChoiceType> {
    const filter = choice =>
      this.state.value.includes(choice.value)
    return this.props.choices.filter(filter)
  }

  get hasSelectAllChoice (): boolean {
    const isSelectAllChoice = choice =>
      choice.value === MultipleChoiceField.TOGGLE_ALL_VALUE
    return this.props.choices.find(isSelectAllChoice) !== null
  }

  get hasAllChoicesSelected (): boolean {
    return this.state.value.length >= this.choicesCount
  }

  get choicesCount (): number {
    let count = this.props.choices.length
    if (this.hasSelectAllChoice)
      count--
    return count
  }

  isChoiceSelected (choice: ChoiceType) {
    if (choice.value === MultipleChoiceField.TOGGLE_ALL_VALUE)
      return this.hasAllChoicesSelected
    return this.state.value.includes(choice.value)
  }

  @self
  toggle (value: ValueType) {
    if (value === MultipleChoiceField.TOGGLE_ALL_VALUE)
      return this.toggleAll()

    const values = [ ...this.state.value ]
    const index  = values.indexOf(value)

    if (index > -1)
      values.splice(index, 1)
    else
      values.push(value)
    this.update(values)
  }

  toggleAll () {
    return this.hasAllChoicesSelected
      ? this.clear()
      : this.selectAll()
  }

  selectAll () {
    this.update(this.allChoiceValues)
  }

  clear () {
    this.update()
  }

  update (value: Array<ValueType> = []): Promise<void> {
    return new Promise(resolve =>
      this.setState({ value }, async () => {
        await this.props.onChange(value)
        resolve()
      })
    )
  }

}
