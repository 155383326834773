// @flow
import React, { useMemo } from 'react'
// $FlowFixMe missing type definitions for react-redux?
import { useDispatch } from 'react-redux'
import context from 'context'

import { GROUP_MANAGEMENT } from 'constants'

import { CommandMenu as DesignSystemCommandMenu } from '@nordhealth/react'
import navigator from 'routes/base'
import { descriptors as managementDescriptor } from 'routes/management'
import { descriptors as communicationDescriptior } from 'routes/communication'
import { descriptors as formDescriptor } from 'routes/forms'
import { descriptors as userDescriptor } from 'routes/user'
import { logout } from 'actions/user'

import { __ } from 'utils/gettext'

import type { Node } from 'react'

// Known problematic hotkeys:
// Firefox: Alt+D, Alt+E, Alt+F, Alt+S, Alt+B, Alt+T
// Chrome: Alt+D, Alt+E, Alt+F
// Opera: Alt+D, Alt-F
// MacOS/ Safari: Options + A, Options + Y

const CommandMenu = (): Node => {
  const dispatch = useDispatch()
  if (
    context.currentUser &&
    context.currentUserGroup &&
    context.currentUserGroup.name === GROUP_MANAGEMENT
  )
    return null

  const menuCommands = useMemo(
    () =>
      context.loggedInAsStaff
        ? staffMenuCommands(dispatch)
        : clientMenuCommands(dispatch),
    []
  )

  return (
    <DesignSystemCommandMenu
      open={false}
      commands={menuCommands}
      placeholder={__('Type a command or search...')}
    >
      <CommandMenuFooter />
    </DesignSystemCommandMenu>
  )
}

const CommandMenuFooter = (): Node => {
  return (
    <div slot='footer'>
      <slot name='footer'>
        <div className='n-modal-footer' slot='footer'>
          <span className='n-help'>
            <nord-icon
              label='Arrow keys'
              name='keyboard-arrow-up-down'
              size='m'
            ></nord-icon>
            {__('Navigate')}
          </span>
          <span className='n-help'>
            <nord-icon
              label='Enter key'
              name='keyboard-return'
              size='m'
            ></nord-icon>
            {__('Select')}
          </span>
          <span className='n-help'>{__('Esc to dismiss')}</span>
        </div>
      </slot>
    </div>
  )
}

const clientMenuCommands = (dispatch) => {
  return [
    {
      id: 'messages',
      title: __('Open messages'),
      keywords: 'command open messages threads',
      shortcut: 'Alt+KeyM',
      section: __('Sections'),
      handler: () => {
        navigator.navigate('/threads/')
      },
    },
    {
      id: 'forms',
      title: __('Open forms'),
      keywords: 'command open forms',
      shortcut: 'Alt+KeyL',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(formDescriptor.list)
      },
    },
    {
      id: 'submissions',
      title: __('Open submissions'),
      keywords: 'command open submissions',
      shortcut: 'Alt+KeyO',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(formDescriptor.list, { tab: 'submissions' }, null)
      },
    },
    {
      id: 'profile',
      title: __('Open my profile'),
      keywords: 'command open my profile',
      shortcut: 'Alt+KeyP',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(userDescriptor.view)
      },
    },
    {
      id: 'logout',
      title: __('Logout'),
      keywords: 'log out sign logout',
      shortcut: 'Alt+KeyQ',
      section: __('Commands'),
      handler: () => {
        dispatch(logout())
      },
    },
  ]
}

const staffMenuCommands = (dispatch) => {
  return [
    {
      id: 'add client',
      title: __('Add new client'),
      keywords: 'command new create add client',
      shortcut: 'Alt+KeyC',
      section: __('Commands'),
      handler: () => {
        navigator.navigate(managementDescriptor.createUser, null, {
          type: 'clients',
          subtabInnerViewState: { type: 'clients' },
          navigateToSubtabInnerView: true,
          subtabInnerViewUrl: managementDescriptor.createUser,
        })
      },
    },
    {
      id: 'init thread',
      title: __('Add conversation'),
      keywords: 'command new create thread conversation',
      shortcut: 'Alt+KeyK',
      section: __('Commands'),
      handler: () => {
        navigator.navigate(communicationDescriptior.init())
      },
    },
    {
      id: 'search client',
      title: __('Search clients'),
      keywords: 'command search clients',
      shortcut: 'Alt+Shift',
      section: __('Commands'),
      handler: () => {
        const userSearch = document.querySelector('#react-select-head-nav-input')
        if (userSearch) {
          userSearch.click()
          userSearch.focus()
        }
      },
    },
    {
      id: 'logout',
      title: __('Logout'),
      keywords: 'command log sign out logout',
      shortcut: 'Alt+KeyQ',
      section: __('Commands'),
      handler: () => {
        dispatch(logout())
      },
    },
    {
      id: 'open notifications',
      title: __('Open notifications'),
      keywords: 'section open notifications',
      shortcut: 'Alt+KeyN',
      section: __('Sections'),
      handler: () => {
        const notificationMenu = document.querySelector('#notification-menu')
        if (notificationMenu) notificationMenu.click()
      },
    },
    {
      id: 'open forms',
      title: __('Open forms'),
      keywords: 'section open forms',
      shortcut: 'Alt+KeyL',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(formDescriptor.list)
      },
    },
    {
      id: 'open submissions',
      title: __('Open submissions'),
      keywords: 'section open submissions',
      shortcut: 'Alt+KeyO',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(formDescriptor.list, { tab: 'submissions' }, null)
      },
    },
    {
      id: 'open management',
      title: __('Open management'),
      keywords: 'section open management',
      shortcut: 'Alt+KeyM',
      section: __('Sections'),
      handler: () => {
        navigator.navigate(managementDescriptor.index)
      },
    },
  ]
}

export default CommandMenu
