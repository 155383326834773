import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposePackage = load(() =>
  import('views/Public/Packages/ComposePackage'))

const PackageListView = load(() =>
  import('views/Public/Packages/ListPackages'))


export const descriptors = {
  packages:                     `/packages/`,
  createPackage:                `/packages/create/`,
  package: (pk: ID) =>          `/packages/${pk}`,
}


export default <Switch>
  <Route path={ descriptors.createPackage } component={ ComposePackage } />
  <Route path={ descriptors.package(':id(\\d+)') } component={ ComposePackage } />
  <Route exact path={ descriptors.packages } component={ PackageListView } />
  <RouteNotFound />
</Switch>
