/**
 * @module TimeoutWarning
 * @flow
 * @summary A confirmation dialog component to warn the user about being idle too long.
 */
import connect from 'bound-connect'
import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'

import { logout } from 'actions/user'
import auth from 'api/auth'
import { confirmation } from 'components/generic'
import Text from 'components/text'
import { DEFAULT_PRIMARY_COLOR } from 'constants'

import type { StateType } from 'store/initialState'

const MINUTE = 60000

type TimeoutTextStateType = {
  timer: number | null,
}

@connect
export default class TimeoutWarning extends Component<*> {
  idleTimer: React$Element<*> | null

  static actions: Object = (dispatch: Function) => ({
    logout: () => dispatch(logout()),
  })
  static properties: Object = (state: StateType) => ({
    color: state.tenantSettings.color,
  })

  renderTimeoutWarning: Function = () => {
    const warningTitle = 'Do you want to stay logged in?'
    const confirmLabel = 'Continue'
    const cancelLabel = 'Logout'
    const customColor = this.props.color
      ? this.props.color
      : DEFAULT_PRIMARY_COLOR
    confirmation(
      warningTitle,
      <TimeoutText timer={MINUTE * 5} />,
      customColor,
      false,
      cancelLabel,
      confirmLabel,
      MINUTE * 5
    ).then(
      () => {
        if (this.idleTimer) {
          this.idleTimer.reset()
        }
      },
      () => this.logout()
    )
  }

  logout: Function = () => {
    this.props.logout()
    auth.handleExpiredToken()
  }

  render(): React$Element<*> {
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref
        }}
        onIdle={this.renderTimeoutWarning}
        timeout={MINUTE * 25}
        stopOnIdle={true}
      />
    )
  }
}

class TimeoutText extends Component<*, TimeoutTextStateType> {
  countdown: IntervalID

  state = {
    timer: 0,
  }

  componentDidMount() {
    if (this.props.timer) {
      const timeLeftInMinutes = this.props.timer / 60000
      this.setState({ timer: timeLeftInMinutes })
    }

    this.countdown = setInterval(() => {
      if (this.state.timer && this.state.timer === 1) {
        return
      } else {
        this.setState((prevState) => ({ timer: prevState.timer - 1 }))
      }
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.countdown)
  }

  render() {
    return (
      <Text params={{ timer: this.state.timer }}>
        {
          "If you won't continue using Nordhealth Connect, you will be logged out automatically in ${timer} minutes."
        }
      </Text>
    )
  }
}
