/**
 * @flow
 * @class CoursesView
 */
import React, { Component } from 'react'
import self from 'autobind-decorator'

import { observeWindowSizeChanges } from 'utils/dom'
import { isEqual } from 'utils/resolve'

type GridViewPropsType = {
  items: Array<Object>,
  mapToGridItem: Function,
  itemWidth: string,
  maxWidth: string,
  minWidth: string,
}
type GridViewStateType = {
  maxWidth?: string,
}


export default class GridView extends Component<GridViewPropsType, GridViewStateType> {

  windowResizeSubscription = null
  state: GridViewStateType = {
    maxWidth: undefined,
  }

  componentDidMount () {
    this.windowResizeSubscription = observeWindowSizeChanges(this.calculateItemWidth)
  }

  componentDidUpdate (oldProps: GridViewPropsType) {
    if (!isEqual(oldProps, this.props))
      this.calculateItemWidth()
  }

  componentWillUnmount () {
    if (this.windowResizeSubscription)
      this.windowResizeSubscription.dispose()
  }

  @self
  calculateItemWidth () {
    const itemsCount = this.props.items.length
    if (!itemsCount)
      return

    if (itemsCount < 3) {
      this.setState({ maxWidth: this.props.maxWidth })
    } else {
      const first = document.getElementById('item1')
      const maxWidth = first ? `${first.clientWidth}px` : undefined
      this.setState({ maxWidth })
    }
  }

  render () {
    return this.props.items && this.props.items.length
      ? <div id='grid-view'>
        { this.props.items.map(this.mapItemToGridItem)}
      </div>
      : null
  }

  @self
  mapItemToGridItem ( item: Object, key: number) {
    const forceWidth = this.props.items.length < 3
    const flexBasis = this.props.itemWidth
    const minWidth = this.props.minWidth
    let maxWidth = this.state.maxWidth
    if (key === 0 && !forceWidth) maxWidth = '100%'
    const style = { maxWidth, flexBasis, minWidth }
    return <div
      id={ `item${key+1}` }
      style={ style }
      className='grid-item'
      key={ key }>
      { this.props.mapToGridItem(item, key) }
    </div>
  }
}
