// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeFormView = load(() =>
  import('../../../views/Public/Forms/ComposeForm'))

const FormsView = load(() =>
  import('../../../views/Public/Forms/ListForms'))


export const descriptors = {
  forms:                        `/forms/`,
  form: (pk: ID) =>             `/forms/${pk}`,
  createForm:                   `/forms/create/`,
}


export default <Switch>
  <Route path={ descriptors.createForm } component={ ComposeFormView } />
  <Route path={ descriptors.form(':id(\\d+)') } component={ ComposeFormView } />
  <Route exact path={ descriptors.forms } component={ FormsView } />
  <RouteNotFound />
</Switch>
