// @flow
import self from 'autobind-decorator'
import React, { Component } from 'react'
import { SlideDown } from 'react-slidedown'
import DropZone from 'react-dropzone'
import connect from 'bound-connect'
import getClassNames from 'classnames'

import { addAlert } from 'actions/core'
import { updatePendingAttachments } from 'actions/forms'
import api from 'api'
import HelpIcon from 'components/HelpIcon'
import Icons from 'components/icons'
import { SecondaryButton } from 'components/buttons'
import { Badge, Loader } from 'components/generic'
import Text from 'components/text'
import { __ } from 'utils/gettext'


type ChildType = {
  help_text: string,
  max_file_size: number,
  read_only: boolean,
  required: boolean,
  type: string,
}
type AttachmentType = {
  file: File,
  id: number | null,
  uploaded: boolean,
  error: string | null,
}
type AttachmentGroupType = Map<string, AttachmentType>
type ValueType = Array<number | null> | null

type AttachmentFieldProps = {
  name: string,
  type: string,
  buttonLabel?: string,
  onChange: ValueType => *,
  disabled?: boolean,
  asPreviews?: boolean,
  options?: *,
  value?: ValueType,
  clear?: boolean,
  max_length?: number,
  addAlert?: Function,
  incrementPendingAttachments?: Function,
  decrementPendingAttachments?: Function,
  child: ChildType,
  id?: string | number,
  nolabel?: boolean,
  multi?: boolean,
  allowedFiles?: string,
  autoOpen?: boolean,
  stacked?: boolean,
  renderAddButton?: boolean,
  help_text?: string,
  label?: string,
  shouldRemoveAttachementsField?: Function
}

type AttachmentFieldStateType = {
  attachments: AttachmentGroupType,
}


@connect
export default class AttachmentField extends Component<AttachmentFieldProps, AttachmentFieldStateType> {

  static defaultProps: Object = {
    multi: true,
    autoOpen: false,
    stacked: false,
    renderAddButton: true,
  }

  static actions: Object = (dispatch: Function) => ({
    addAlert: (message: string | React$Element<*>, type?: string = 'error') => dispatch(addAlert(type, message, 1)),
    incrementPendingAttachments: () => dispatch(updatePendingAttachments(1)),
    decrementPendingAttachments: () => dispatch(updatePendingAttachments(-1)),
  })

  input: HTMLInputElement

  removeFieldTimeoutId = null
  timeoutCalled = false

  state: AttachmentFieldStateType = {
    attachments: new Map(),
  }

  componentDidMount () {
    if (this.props.autoOpen){
      this.fileSelect()
      /* There is no event for capturing that the "cancel" button was pressed.
       Only when the focus is back on browser window we can assume that
       it was clicked. In reality it is one of the two: either upload or cancel.
       Thus we will have to handle this event accordingly */
      window.addEventListener('focus', this.handleFocus);
    }
  }

  // This method will set a small delay to check, if the file
  // was actually uploaded. File input required small amount of time
  // to trigger onChange method. If after this small delay of 500ms
  // and nothing was captured in onChange handler - we can conclude
  // that cancel button was pressed and we enable attachment field again.
  @self
  handleFocus() {
    if (!this.timeoutCalled) {
      this.timeoutCalled = true
      this.removeFieldTimeoutId = this.removeFieldTimeout()
    }
  }

  componentDidUpdate (oldProps: AttachmentFieldProps) {
    if (this.props.clear && !this.props.value && oldProps.value)
      this.clearAttachments()

    if (!oldProps.autoOpen && this.props.autoOpen)
      this.fileSelect()
  }

  componentWillUnmount() {
    clearTimeout(this.removeFieldTimeoutId)
    this.removeFieldTimeoutId = null
    this.timeoutCalled = false
    window.removeEventListener('focus', this.handleFocus);
  }

  @self
  onDrop (dropped: FileList) {
    this.addAttachments(this.parseTooBigFiles(...dropped))
  }

  @self
  onChange (event: SyntheticInputEvent<*>) {
    /* As explained above, if after sometime the onChange is triggered
       we cancel the removal of the field */
    clearTimeout(this.removeFieldTimeoutId)
    this.removeFieldTimeoutId = null
    this.timeoutCalled = false

    this.addAttachments(this.parseTooBigFiles(...event.target.files))
  }

  @self
  parseTooBigFiles (...filesToCheck: Array<File>): Array<File> {
    if (!this.props.child || !this.props.child.max_file_size)
      return filesToCheck

    let files: Array<File> = []

    filesToCheck.forEach( file => {
      if (file.size <= this.props.child.max_file_size)
        files.push(file)
      else if (this.props.addAlert){
        this.props.addAlert(<Text params={{
          file: file.name,
          maxSize: this.maxFileSize
        }}>{ 'File ${file} is larger than the maximum allowed file size ${maxSize}' }</Text>)
      }
    })

    return files
  }

  get maxFileSize (): string {
    return this.props.child.max_file_size / (1024 * 1024) + 'MB'
  }

  get attachmentsToValues (): Array<number | null> {
    const ids = []
    for (let attachment of this.state.attachments.values())
      if (attachment.id)
        ids.push(attachment.id)
    return ids
  }

  get infoCircle (): React$Element<*> | null {
    return this.props.help_text
      ? <HelpIcon text={ this.props.help_text } />
      : null
  }

  postAttachments () {
    [ ...this.state.attachments.values() ].forEach((attachment) => {
      if (!attachment.uploaded)
        this.postAttachment(attachment.file)
    })
  }

  //eslint-disable-next-line max-statements, complexity
  async postAttachment (file: File | null) {
    this.props.onChange(this.attachmentsToValues)
    const attachments = this.state.attachments
    if (!file || !attachments) return
    const fileData = new FormData()
    fileData.append('file', file)
    if (this.props.incrementPendingAttachments)
      this.props.incrementPendingAttachments()
    const response = await api.post('/attachments/', fileData)
    const attachment = attachments.get(file.name)
    if (this.props.decrementPendingAttachments)
      this.props.decrementPendingAttachments()
    if (attachment) {
      attachment.uploaded = true
      if (!response.problem) {
        const { id } = response.data.file
        attachment.id = id
      } else {
        const error = response.data.file
          ? response.data.file[0]
          : response.problem + ''
        this.props.addAlert && this.props.addAlert(<span>{file.name}: {error}</span>)
        attachment.error = error
      }
      attachments.set(file.name, attachment)
      this.setState({ attachments }, () => {
        this.props.onChange(this.attachmentsToValues)
      })
    }
  }

  addAttachments (files: Array<File>) {
    //eslint-disable-next-line complexity
    let update = (attachments) => {
      if (!attachments)
        attachments = new Map()
      for (let i = 0; i < files.length; i++) {
        if (attachments.has(files[i].name))
          break
        if (this.props.max_length === 1) {
          attachments = new Map()
          attachments.set(files[i].name, { file: files[i], id: null, uploaded: false, error: null })
          return { attachments }
        } else if (!this.props.max_length || attachments.size < this.props.max_length)
          attachments.set(files[i].name, { file: files[i], id: null, uploaded: false, error: null })
      }
      return { attachments }
    }

    this.setState(update(this.state.attachments), () => {
      this.postAttachments()
    })
    this._clearInput()
  }

  removeAttachment (file: string) {
    const attachments = this.state.attachments
    attachments.delete(file)
    this.setState({ attachments }, () => {
      this.props.onChange(this.attachmentsToValues)
    })
  }

  @self
  fileSelect () {
    if (this.input) {
      this.input.click()
    }
  }

  @self
  removeField() {
    if (this.props.shouldRemoveAttachementsField){
      this.props.shouldRemoveAttachementsField()
    }
  }

  @self
  removeFieldTimeout() {
    return setTimeout(this.removeField, 500)
  }

  get container (): * {
    return this.props.multi && !this.props.stacked ? SlideDown : 'div'
  }

  render (): React$Element<*> {
    const className = getClassNames(
      'select-choice-field',
      this.props.nolabel ? 'single-line' : undefined,
    )
    const Component = this.container

    return <div className={ className }>
      <DropZone
        onDrop={this.onDrop}
        style={{ width: 'auto', height: 'auto', border: 'none' }}
        inputProps={{ 'aria-label': 'Attachment dropzone', 'tabIndex': -1 }}
        disableClick >
        <Component className='attachment-field'>
          { this.props.nolabel && <label htmlFor={ this.props.id } className='label field-label'>{ this.props.label }{ this.infoCircle }</label> }
          <div className='selection'>
            <div className='selected'>
              { this.renderAttachments() }
            </div>
            { this.renderInput() }
          </div>
        </Component>
      </DropZone>
    </div>
  }

  renderInput (): React$Element<*> | null {
    const updateReference = ref => ref && (this.input = ref)

    if (this.props.multi || !this.state.attachments || !this.state.attachments.size)
      return <span className='field file-input'>
        <div className={ this.props.stacked ? 'stacked' : undefined }>
          <input
            // This element is not visible in the UI.
            // Instead, the button below is used.
            id={ 'invisible-attachments-input' }
            aria-label={ 'Attachments input' }
            tabIndex={ -1 }
            type='file'
            ref={ updateReference }
            onChange={ this.onChange }
            disabled={ this.props.disabled }
            accept={ this.props.allowedFiles }
          />

          { this.props.renderAddButton && this.renderAddButton() }
        </div>
      </span>

    return null
  }

  renderAddButton (): React$Element<*> {
    return <SecondaryButton
      notranslate={ !!this.props.buttonLabel }
      onClick={ this.fileSelect }
      id={ 'add-attachment-button' }
      small >
      { this.props.buttonLabel || 'Add attachment' }
      <Icons.OpenInNew size={ 10 } ariaLabel={ __('Open file browser') } />
    </SecondaryButton>
  }

  renderAttachments (): React$Element<*> | null {
    if (!this.state.attachments)
      return null

    const attachments = [ ...this.state.attachments.values() ]
    const className = getClassNames(
      'full-width',
      this.props.stacked ? 'stacked' : undefined,
    )
    const renderAttachment = ( attachment, key ) =>
      <Badge key={ key } onRemove={ () => this.removeAttachment(attachment.file.name) } error={ !!attachment.error }>
        { !attachment.uploaded && <div className='file-upload-loader'><Loader /></div> }
        <a className='link linked-item-entry'
          href={ attachment.file ? URL.createObjectURL(attachment.file) : '' }
          target='_blank'
          rel='noopener noreferrer'>
          { !attachment.error ? attachment.file.name : <Text>Upload failed</Text> }
        </a>
        <Icons.OpenInNew size={ 12 } ariaLabel={ __('Open link in a new tab') } />
      </Badge>

    return <span className={ className }>
      { attachments.map(renderAttachment) }
    </span>
  }

  @self
  clearAttachments () {
    const update = { attachments: new Map() }
    this.setState(update, () => {
      this.props.onChange(this.attachmentsToValues)
    })
  }

  @self
  _clearInput () {
    if (this.input)

      // flow-ignore: Is always an input element
      this.input.value = ''
  }
}
