
import React, { Component, createRef } from 'react'
import { HelpIcon } from 'components/icons'
import tooltips from 'tooltips'
import { __ } from 'utils/gettext'


export default class HelpText extends Component<HelpTextProps> {

  element = createRef()

  componentDidMount () {
    if (this.props.text)
      tooltips.add(this.element.current, this.props.text)
  }

  componentWillUnmount () {
    if (this.props.text)
      tooltips.remove(this.element.current, this.props.text)
  }

  render () {
    const minWidthFix = this.props.setMinWidthAuto
    if (!this.props.text)
      return null

    const className = 'help-icon' + (minWidthFix ? ' minwidth-fix' : '')

    return <span ref={ this.element } className={ className } ><HelpIcon /></span>
  }

}
