// @flow

import React, { createContext, useContext, useState } from 'react'

import api from 'api'

import type { Node } from 'react'
import type { Appointment } from './components/AppointmentCard'

import { APPOINTMENT_URLS } from './index'
import { __ } from 'utils/gettext'

type AppointmentsContextProps = {
  upcomingAppointments: Appointment[],
  setUpcomingAppointments: Function,
  onCancel: (id: number, cancellation_token: string) => Promise<void>,
  banner: { text: string, variant: string } | null,
  setBanner: Function,
  showPast: boolean,
  setShowPast: Function,
}

const AppointmentsContext = createContext<null | AppointmentsContextProps>(null)

export const AppointmentsProvider = ({
  children,
}: {
  children: Node,
}): Node => {
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    Appointment[]
  >([])
  const [error, setError] = useState<string | null>(null)
  const [showPast, setShowPast] = useState<boolean>(false)
  const [banner, setBanner] = useState<{
    text: string,
    variant: string,
  } | null>(null)

  const onCancel = async (id: number, cancellation_token: string) => {
    const res = await api.delete(APPOINTMENT_URLS.CANCEL_APPOINTMENT, {
      cancellation_token,
      id,
    })
    if (res.status === 200) {
      const updatedAppointments = upcomingAppointments.filter(
        (appointment) => appointment.id !== id
      )
      setUpcomingAppointments(updatedAppointments)
      setBanner({
        text: __('Appointment was successfully cancelled'),
        variant: 'success',
      })
    } else {
      // We translate the error from server as well because technically there is only 1
      // error that can be returen: cancellation time has expired
      const errorMsg =
        __(res.data[0].error) || __('Was unable to cancel appointment')
      setBanner({
        text: errorMsg,
        variant: 'danger',
      })
    }
  }

  return (
    <AppointmentsContext.Provider
      value={{
        upcomingAppointments,
        setUpcomingAppointments,
        onCancel,
        banner,
        setBanner,
        showPast,
        setShowPast,
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  )
}

const useAppointments = (): AppointmentsContextProps => {
  const appointments = useContext(AppointmentsContext)
  if (!appointments)
    throw new Error('useAppointments must be used within AppointmentsProvider')
  return appointments
}

export default useAppointments
