/**
 * @flow
 * @class TextInput
 */

import React, { PureComponent } from 'react'
import type { Element } from 'react'
import { __ } from 'utils/gettext'

type InstructionTextProps = {
  children: Element<*>,
}


export default class InstructionTextBlock extends PureComponent<InstructionTextProps> {

  static displayName = __('Instruction text')

  get content (): string {
    let content = this.props.children
    return content.toString()
  }

  updateContent (element: HTMLElement) {
    element.innerHTML = this.content
  }

  render () {
    return <p
      className='instructions'
      ref={ ref => ref && this.updateContent(ref) }
    />
  }
}
