// @flow
import React from 'react'
import { getFieldComponentType } from './fields'
import type { FieldProperties } from './fields/Field'


export default class FieldDescription {
  initialProps: FieldProperties
  properties: Map<string, *> = new Map()

  constructor (fieldProps: FieldProperties) {
    this.initialProps = fieldProps
  }

  get props (): FieldProperties {
    return { ...this.initialProps, ...toObject(this.properties) }
  }

  set (key: string, value: *) {
    this.properties.set(key, value)
  }

  update (props: { [string]: * } = {}) {
    for (let [ key, value ] of Object.entries(props)) this.properties.set(key, value)
  }

  get component (): React$ComponentType<*> {
    let props = this.props

    if ([ 'function', 'object' ].indexOf(typeof props.type) > -1)
      return props.type

    return getFieldComponentType(props)
  }

  toDescriptor () {
    const props = this.props
    const ComponentClass = this.component
    const assignReference = reference =>
      Object.defineProperty(fieldDescriptor, 'reference', { value: reference })

    let component = ComponentClass && ComponentClass.prototype.render
      ? <ComponentClass ref={ref => ref && assignReference(ref)} {...props} />
      : <ComponentClass {...props} />

    let fieldDescriptor = {
      source: this,
      reference: null,
      component,
    }
    return fieldDescriptor
  }
}


function toObject (obj = {}) {
  if (!obj)
    return {}

  let ret = {}
  let entries = typeof obj.entries === 'function'
    ? obj.entries()
    : Object.entries(obj)

  for (let [ key, value ] of entries)
    ret[key] = value

  return ret
}
