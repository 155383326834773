// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


export const descriptors = {
  list:              () => `/tenants/`,
  create:            () => `/tenants/create/`,
  deletedTenant:     (pk) => `/tenants/deleted/${pk}/`,
  edit:              (pk: ID) => `/tenants/${pk}/`,
  createUser:        (pk: ID) => `/tenants/${pk}/users/create/`,
  editUser:          (pk: ID, user_pk: ID) => `/tenants/${pk}/users/${user_pk}/`,
  listCredentials:   (pk: ID) => `/tenants/${pk}/credentials/`,
  createCredentials: (pk: ID) => `/tenants/${pk}/credentials/create/`,
  editCredentials:   (pk: ID, cred_pk: ID) => `/tenants/${pk}/credentials/${cred_pk}/`,
  videoLogDetail:    (pk: ID, vc_pk: ID) => `/tenants/${pk}/video-log/${vc_pk}/`,
}


const ListView = load(() =>
  import('views/Public/Tenants/ListTenants'))

const ComposeView = load(() =>
  import('views/Public/Tenants/ComposeTenant'))

const EditTenantView = load(() =>
  import('views/Public/Tenants/EditTenant'))

const ComposeUserView = load(() =>
  import('views/Public/Tenants/ComposeUser'))

const ComposeCredentialsView = load(() =>
  import('views/Public/Tenants/ComposeCredentials'))

const TenantListTabView = load(() =>
  import('views/Public/Tenants/TenantListTabView'))

const DeletedTenantView = load(() =>
  import('views/Public/Tenants/DeletedTenant'))

const VideoLogDetailView = load(() =>
  import('views/Public/Tenants/VideoLogDetail'))


export default <Switch>
  <Route path={ descriptors.createCredentials(':id(\\d+)') } component={ ComposeCredentialsView } />
  <Route path={ descriptors.editCredentials(':id(\\d+)', ':credentialsId') } component={ ComposeCredentialsView } />
  <Route path={ descriptors.deletedTenant(':id(\\d+)')} component={ DeletedTenantView }/>
  <Route exact path={ descriptors.list() } component={ TenantListTabView  } />
  <Route path={ descriptors.createUser(':id(\\d+)') } component={ ComposeUserView } />
  <Route path={ descriptors.editUser(':id(\\d+)', ':user_id(\\d+)') } component={ ComposeUserView } />
  <Route path={ descriptors.create() } component={ ComposeView } />
  <Route path={ descriptors.videoLogDetail(':id(\\d+)', ':vc_pk(\\d+)') } component={ VideoLogDetailView } />
  <Route path={ descriptors.edit(':id(\\d+)') } component={ EditTenantView } />
  <RouteNotFound />
</Switch>
