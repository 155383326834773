/**
 * @module IndicatorFieldView
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import self from 'autobind-decorator'
import React, { PureComponent } from 'react'
import Editor from 'react-drf/RTE'
import connect from 'bound-connect'

import { getElements } from './Editor'
import { elementIsField } from '../serializer'

import type { List } from 'immutable'

type SuggestionType = {
  value: *,
  label: string,
}

type Props = {|
  suggestions: List<SuggestionType>,
  onChange?: Function,
  disabled?: boolean,
  value?: string | null,
  displayValue?: string | null,
|}

type State = {|
  value: string | null,
  disabled: boolean,
  displayValue: string | null,
|}


const choicesHaveValues = (field: *) => {
  if (!field.choices)
    return true
  for (let choice of field.choices) {
    if (!is.number(choice.score))
      return false
  }
  return true
}

const getFieldElements = (state: *) =>
  getElements(state).filter(elementIsField)

export const getFieldNames = (state: *) => {
  let suggestions = []
  getFieldElements(state).forEach(field => {
    if (field.required && field.type !== 'text' && choicesHaveValues(field))
      suggestions.push( {
        value: field.indicator_identifier,
        label: `${field.indicator_identifier}: ${field.text}`,
      })
  })
  return suggestions
}


@connect
export default class IndicatorFieldView extends PureComponent<Props, State> {

  static defaultValue = null
  static actions      = []
  static properties   = (state: *) => {
    return {
      suggestions: getFieldNames(state)
    }
  }

  // eslint-disable-next-line complexity
  static getDerivedStateFromProps (props: Props, state: State) {
    const updates = {}

    // Overwrite the value in the state if provided via props.
    // If props.value === `null`, the defaultValue will be
    // assigned to state.value
    if ('value' in props)
      if (props.value === null)
        updates.value = IndicatorFieldView.defaultValue
      else if (typeof props.value === 'object' && props.value.value !== state.value)
        Object.assign(updates, props.value)
      else if (typeof props.value === 'string' && props.value !== state.value)
        Object.assign(updates, { value: props.value })


    // Update the component's disabled state if provided via props.
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false

    // Only perform updates if necessary
    return updates
  }



  state = {
    value: null,
    disabled: false,
    displayValue: null,
  }

  @self
  update ({
    value: displayValue,
    target: editor }: { value: string | null, target: Editor }) {

    const value = editor.toString()
    const state = { value, displayValue }

    if (typeof this.props.onChange === 'function')
      this.props.onChange({ value, displayValue })
    else
      this.setState(state)
  }

  render () {
    return <Editor
      format='text'
      value={ this.state.value }
      onChange={ this.update }
      suggestions={ this.props.suggestions }
      nolabels
    />
  }

}
