// @flow
import React from 'react'

import FieldError from './components/FieldError'
import { __ } from 'utils/gettext'


export type ErrorListProps = {
  errors: Array<Error> | null
}


const ErrorList = ({ errors }: ErrorListProps) => {
  if (!errors)
    return null

  const renderError = (error, key) =>
    <FieldError
      key={key}>
      { error }
    </FieldError>

  __DEV__ && console.warn("Errors", errors)
  return reduceErrorData(errors).map(renderError)
}


// eslint-disable-next-line complexity
function reduceErrorData (errorData): Array<string> {

  let errors = []
  if (errorData instanceof Error)
    errorData = errorData.message
  else if (typeof errorData === 'object')
    errorData = Object.values(errorData)

  if (!errorData)
    return []
  else if (errorData instanceof Array)
    for (let error of errorData)
      errors = errors.concat(reduceErrorData(error))
  else if (typeof errorData === 'string')
    errors.push(errorData)
  else if (typeof errorData.toString === 'function')
    errors.push(errorData.toString())
  else
    errors.push(__("Unknown error"))
  return errors
}


export default ErrorList
