// @flow
import type { Action } from '../reducers'

export const ADD_TENANTS = 'ADD_TENANTS'
export const UPDATE_SEARCH = 'UPDATE_SEARCH'
export const UPDATE_SEARCH_BY_ID = 'UPDATE_SEARCH_BY_ID'
export const UPDATE_SEARCH_BY_SCHEMA_NAME = 'UPDATE_SEARCH_BY_SCHEMA_NAME'
export const UPDATE_LOCKED_FILTER = 'UPDATE_LOCKED_FILTER'
export const UPDATE_COUNTRY_FILTER = 'UPDATE_COUNTRY_FILTER'

export const addTenants = (tenants: Array<Object>): Action => ({
  type: ADD_TENANTS,
  payload: tenants
})


export const updateSearch = (value: string): Action => ({
  type: UPDATE_SEARCH,
  payload: value ? value : null
})

export const updateSearchById = (value: string): Action => ({
  type: UPDATE_SEARCH_BY_ID,
  payload: value
})

export const updateSearchBySchemaName = (value: string): Action => ({
  type: UPDATE_SEARCH_BY_SCHEMA_NAME,
  payload: value
})


export const updateLockedFilter = (value: boolean): Action => ({
  type: UPDATE_LOCKED_FILTER,
  payload: value
})

export const updateCountryFilter = (value: boolean): Action => ({
  type: UPDATE_COUNTRY_FILTER,
  payload: value
})
