// @flow
import React, { useState, useEffect } from 'react'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'
import { Input, Stack } from '@nordhealth/react'
import { Button } from 'components/designSystemComponents'

import useForm from '../OnlineBooking/utils/useForm'
import { ENDPOINT_URLS } from '../OnlineBooking'
import { DIARIUM_FIELDS_MAP } from '../OnlineBooking/extra/ConstructFormFromApi'

import { increaseStep } from 'actions/onlineBooking'
import { __ } from 'utils/gettext'

import { updateLoggedInUserData } from '../../actions/user'

// TODO: change to design system when ready
import { toast } from 'react-toastify'

import api from 'api'

import type { Node } from 'react'
import type { UserRecordType } from 'reducers/user'

// TODO: Probably this could be further used when design system upgrade takes place

type Props = {
  onRequireTwoFactor: Function,
  showProceedAsGuest: Function,
  onSuccess: Function,
  onError: Function,
  setBookingModalOpen: Function,
  setShowLoginBanner?: Function,
}

const OnlineBookingLogin = (props: Props): Node => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [usernameError, setUsernameError] = useState(undefined)
  const [passwordError, setPasswordError] = useState(undefined)
  // If for specific tenant address, postal code or city
  // Is required to be filled in the form and the user has not
  // set those values to the profile - we will ask him to update those
  // fields. We start off by fetching all the fields from diarium side.
  const [fieldsToUpdate, setFieldsToUpdate] = useState<Object[]>([])
  // Needed for dynamic construction of the form from the fields that should be updated
  const [formState, setFormState] = useForm(fieldsToUpdate, {}, [
    fieldsToUpdate,
  ])

  const getFormData = () => {
    return { username, password }
  }

  const validateData = (): boolean => {
    let isValid = true
    if (username === '') {
      setUsernameError('This field cannot be empty')
      isValid = false
    }
    if (password === '') {
      setPasswordError('This field cannot be empty')
      isValid = false
    }
    return isValid
  }

  const handleLogin = async () => {
    if (!validateData()) return

    const res = await api.post('/login/', getFormData())
    if (res.ok) {
      props.onSuccess(res.data)
      if (props.setShowLoginBanner && !props.showProceedAsGuest)
        props.setShowLoginBanner('success')
      return
    }
    if (props.setShowLoginBanner && res.data.code !== 'require_2fa') {
      props.setShowLoginBanner('danger')
      props.setBookingModalOpen(false)
    }
    return handleError(res)
  }

  const proceed = () => {
    props.setBookingModalOpen(false)
    // props.showProceedAsGuest means that we are not on the frontpage
    // And after the user did log in - we want to proceed with the booking flow
    // Otherwise we are located at the front page of the online booking
    // And we simply close the modal, but do not make any transitions
    if (props.showProceedAsGuest) {
      dispatch(increaseStep(1, { userLoggedIn: true }))
    }
  }

  useEffect(() => {
    // When we user logs in - we check if he has required fields to
    // be filled
    if (user.profile.id) {
      const handleFields = async () => {
        const fields = await api.options(ENDPOINT_URLS.CONFIRM)
        const requiredFields = getRequiredFields(fields.data)
        const fieldsToUpdate = getFieldsToUpdate(user, requiredFields)
        if (!fieldsToUpdate.length) {
          proceed()
        }
        setFieldsToUpdate(fieldsToUpdate)
      }
      handleFields()
    }
  }, [user.id, user.id && user.profile.id])

  const handleError = (response) => {
    if (props.onError) {
      if (response.status === 429) {
        props.onError(
          'Too many attempted logins. Please wait ten minutes and try again.'
        )
      } else if (
        response.data &&
        response.data.code &&
        response.data.code === 'require_2fa'
      ) {
        props.onRequireTwoFactor(getFormData())
      }
    }
  }

  const onGuestCheckout = () => {
    dispatch(increaseStep())
  }

  const loginForm = () => (
    <>
      <Input
        label={__('Username')}
        type='text'
        value={username}
        error={usernameError}
        expand
        onChange={(e) => setUsername(e.target.value)}
      ></Input>
      <Input
        label={__('Password')}
        type='password'
        value={password}
        error={passwordError}
        expand
        onChange={(e) => setPassword(e.target.value)}
      ></Input>
      <hr className='divider' />
      <div>
        <Stack>
          <Button variant='primary' expand onClick={handleLogin}>
            Sign in
          </Button>
          {props.showProceedAsGuest && (
            <Button expand onClick={onGuestCheckout}>
              Guest checkout
            </Button>
          )}
        </Stack>
      </div>
    </>
  )

  return !fieldsToUpdate.length ? (
    loginForm()
  ) : (
    <UpdateProfileData
      proceed={proceed}
      fields={fieldsToUpdate}
      formState={formState}
      setFormState={setFormState}
    />
  )
}

// Possible fields that should be updated
// mapping in format:
// Key - Diarium name
// Value - navisec name
const PROFILE_FIELDS_TO_UPDATE = {
  streetAddress: 'address',
  postalCode: 'postal_code',
  postOffice: 'city',
}

// Getting fields that are required in PMS and navisec
const getRequiredFields = (allFields): Object[] => {
  if (!allFields) return []
  const toUpdate = Object.keys(PROFILE_FIELDS_TO_UPDATE)
  const required = allFields.filter(
    (field) => field.required && toUpdate.includes(field.name)
  )
  return required
}

// Out of filtered fields above we get the ones that have empty values
// in user profile
const getFieldsToUpdate = (user: UserRecordType, requiredFields: Object[]) => {
  let toUpdateInUserProfile = []
  if (!requiredFields.length) return []
  const { profile } = user
  requiredFields.forEach((field) => {
    const navisecProfileFieldName = PROFILE_FIELDS_TO_UPDATE[field.name]
    const profileValue = profile[navisecProfileFieldName]
    if (profileValue === '' || profileValue === null) {
      const fieldToUpdate = {
        ...field,
        // Update name field to be the same as in navisec
        // To allow dynamic formation of the for and payload
        name: navisecProfileFieldName,
      }
      toUpdateInUserProfile.push(fieldToUpdate)
    }
  })
  return toUpdateInUserProfile
}

type UpdateProfileDataProps = {
  fields: Object[],
  formState: Object,
  setFormState: Function,
  proceed: Function,
}

const UpdateProfileData = (props: UpdateProfileDataProps) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const handleUpdate = async () => {
    if (!validateEmptyInput()) {
      const errorText = __('Please fill in all fields')
      toast.error(errorText)
      return
    }
    const payload = {
      profile: { ...props.formState },
    }

    const url = `/clients/${user.id}`
    const res = await api.patch(url, payload)

    if (res.ok) {
      const confirmationText = __('Information was updated successfully')
      dispatch(updateLoggedInUserData(res.data))
      toast.success(confirmationText)
      props.proceed()
    }
  }

  const validateEmptyInput = () => {
    let noEmptyFields = true
    const fieldNames = Object.keys(props.formState)
    fieldNames.forEach((name) => {
      if (props.formState[name] === '') {
        noEmptyFields = false
      }
    })
    return noEmptyFields
  }

  const onInput = (e: SyntheticEvent<*>) => {
    const input = e.target
    props.setFormState({
      ...props.formState,
      // $FlowIgnore
      [input.name]: input.value,
    })
  }

  return (
    <>
      <p
        style={{ textAlign: 'center', color: '#23211d' }}
        className='n-font-size-s'
      >
        {__('Please update required fields before booking can be completed')}
      </p>
      {props.fields.map((field) => {
        const translatedLabel = Object.prototype.hasOwnProperty.call(DIARIUM_FIELDS_MAP, field.label)
          ? __(DIARIUM_FIELDS_MAP[field.label])
          : field.label
        return (
          <Input
            key={field.name}
            label={translatedLabel}
            name={field.name}
            value={props.formState[field.name]}
            onInput={onInput}
            expand
          ></Input>
        )
      })}
      <hr className='divider' />
      <Stack>
        <Button variant='primary' expand onClick={handleUpdate}>
          {'Update profile data'}
        </Button>
      </Stack>
    </>
  )
}

export default OnlineBookingLogin
