// @flow
import React, { Fragment, useEffect, useState } from 'react'
import api from 'api'
// $FlowIgnore
import { useSelector, useDispatch } from 'react-redux'
import { Banner, Checkbox, Icon as NordIcon } from '@nordhealth/react'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'

import { increaseStep } from 'actions/onlineBooking'

import isMobile from 'utils/isMobileHook'

import { ENDPOINT_URLS } from '..'

import { __ } from 'utils/gettext'

import ConstructForm from '../extra/ConstructFormFromApi'
import withOnlineBookingModal from '../extra/OnlineBookingModal'
import PrivacyPolicyModal from '../extra/PrivacyPolicyModal'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'
import type { OnlineBookingState as OnlineBooking } from 'reducers/onlineBooking'
import type { UserType as User } from 'reducers/user'

const PersonalDetailsView = (props: RouteProps): Node => {
  const isMobileView = isMobile(window)
  const dispatch = useDispatch()
  const onlineBooking: OnlineBooking = useSelector(
    (state: StateType) => state.onlineBooking
  )
  const tenantSettings = useSelector((state: StateType) => state.tenantSettings)
  const user: User = useSelector((state: StateType) => state.user)
  const defaultValues = user.id ? getDefaultValuesForFormFields(user) : {}
  const profileFieldsToUpdate = user.id ? getFieldsToUpdate(user) : {}
  const [createAccountChecked, setCreateAccountChecked] = useState<boolean>(
    false
  )
  const [acceptTermsChecked, setAcceptTermsChecked] = useState<boolean>(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string | null>(null)
  const [showLoginBanner, setShowLoginBanner] = useState<boolean>(false)
  const dataToAppendToPayload = selectRequiredDataForBooking(onlineBooking)

  useEffect(() => {
    const userLoggedIn =
      (props.history.location.state &&
        props.history.location.state.userLoggedIn) ||
      false
    if (userLoggedIn) setShowLoginBanner(true)
  }, [])

  const onSuccess = async (res, formState) => {
    // Update, if needed, address, city and postal code
    if (user && user.id && Object.keys(profileFieldsToUpdate).length) {
      const url = `/clients/${user.id}`
      let payload = getNewFieldsValues(profileFieldsToUpdate, formState)
      if (Object.keys(payload)) {
        payload = {
          profile: { ...payload },
        }
        // We really do not need to handle response in any way.
        // data from the booking-confirmation was successful to begin with
        const res = await api.patch(url, payload)
      }
    }
    const accountCreated = createAccountChecked ? { accountCreated: true } : {}
    if (isMobileView) dispatch(increaseStep(1, accountCreated))
    else dispatch(increaseStep(1, accountCreated))
  }

  const onError = (res: any) => {
    const text = formatErrorText(res.data)
    setAlertText(text)
  }

  const allowSignUp =
    !user.id && tenantSettings.allow_sign_up && tenantSettings.use_secure_auth

  const handleSaveNewUser = async (userDetails) => {
    const data = getUserDetailsForRegistration(userDetails)
    return await api.post('/register/', data)
  }

  const renderLoginStatusBanner = () => {
    const message =
      'Logged in successfully. Please check that your information below is up to date.'
    return <Banner variant={'success'}>{message}</Banner>
  }

  const renderFormExtras = () => {
    return (
      <Fragment>
        <div className='personal-details-form-footer'>
          <Divider />
          {allowSignUp && (
            <div className='signup'>
              <Checkbox
                label={__('Save my information and register account')}
                checked={createAccountChecked}
                onChange={(e) => setCreateAccountChecked(e.target.checked)}
              />
            </div>
          )}
          <div className='accept-terms'>
            <Checkbox
              label={__('I have read and accept all terms') + '*'}
              checked={acceptTermsChecked}
              onChange={(e) => setAcceptTermsChecked(e.target.checked)}
            />
          </div>
          <span className='privacy-policy'>
            {!isMobileView && <NordIcon size='m' name={'interface-help-2'} />}
            <span>
              <Link to='#' onClick={() => setShowPrivacyPolicy(true)}>
                {__('Read more about register and our privacy policy')}
              </Link>
            </span>
          </span>
        </div>
        <PrivacyPolicyModal
          visible={showPrivacyPolicy}
          setVisible={setShowPrivacyPolicy}
        />
      </Fragment>
    )
  }

  if (isMobileView)
    return (
      <>
        {showLoginBanner && !alertText && (
          <div className='banner-container'>{renderLoginStatusBanner()}</div>
        )}
        <ConstructForm
          url={ENDPOINT_URLS.CONFIRM}
          submitButtonText={'Confirm and book appointment'}
          onSuccess={onSuccess}
          dataToAppendToPayload={dataToAppendToPayload}
          renderExtras={renderFormExtras}
          onSave={createAccountChecked && handleSaveNewUser}
          onError={onError}
          defaultValues={defaultValues}
          submitDisabled={!acceptTermsChecked}
        />
      </>
    )
  else
    return (
      <>
        {showLoginBanner && !alertText && (
          <div className='banner-container'>{renderLoginStatusBanner()}</div>
        )}
        {alertText && (
          <div className='banner-container'>
            <Banner variant={'danger'}>{alertText}</Banner>
          </div>
        )}
        <div className='personal-details-container'>
          <ConstructForm
            url={ENDPOINT_URLS.CONFIRM}
            submitButtonText={'Confirm and book appointment'}
            onSuccess={onSuccess}
            dataToAppendToPayload={dataToAppendToPayload}
            className='personal-details-form'
            renderExtras={renderFormExtras}
            onSave={createAccountChecked && handleSaveNewUser}
            onError={onError}
            defaultValues={defaultValues}
            submitDisabled={!acceptTermsChecked}
            asCard
          />
        </div>
      </>
    )
}

const selectRequiredDataForBooking = (onlineBooking: OnlineBooking) => {
  const date = onlineBooking.available_date?.date
  // $FlowIgnore
  const time = onlineBooking.selected_time_slots?.time
  const therapistId = onlineBooking.selected_employee?.id
  const locationId = onlineBooking.selected_time_slots?.location_id
  const treatmentId = onlineBooking.selected_time_slots?.treatment_id
  return {
    date,
    therapistId,
    locationId,
    treatmentId,
    time,
  }
}

const getDefaultValuesForFormFields = (user: User) => {
  const emailAddress = user.email
  const firstname = user.profile.first_name
  const lastname = user.profile.last_name
  const streetAddress = user.profile.address || ''
  const postalCode = user.profile.postal_code || ''
  const postOffice = user.profile.city || ''
  const socialSecurityNumber = user.profile.social_security_number || ''
  const phoneNumber = user.phone_number || ''
  return {
    emailAddress,
    firstname,
    lastname,
    streetAddress,
    postalCode,
    socialSecurityNumber,
    postOffice,
    phoneNumber,
  }
}

// Since the online booking project has started - we have added new fields:
// address, city and postal_code. Not every user has it. If user doesn't -
// we want to take those values that are not filled and use form data to
// update them to the user profile.
// As navisec has snake case convention in the backend and diarium camel case:
// We store the required (i.e. has to be updated) field names as object in format: { diariumName: navisecName }
const getFieldsToUpdate = (user: User) => {
  let requireUpdate = {}
  if (!user.profile.address) {
    requireUpdate = { streetAddress: 'address', ...requireUpdate }
  }
  if (!user.profile.postal_code) {
    // $FlowIgnore
    requireUpdate = { postalCode: 'postal_code', ...requireUpdate }
  }
  if (!user.profile.city) {
    // $FlowIgnore
    requireUpdate = { postOffice: 'city', ...requireUpdate }
  }
  return requireUpdate
}

const getNewFieldsValues = (fields, formState) => {
  // Getting keys to retreive the values from the form state
  // of the fields that we want to update. Result is in format:
  // { navisecFieldName: value }
  let result = {}
  const diariumFormKeys = Object.keys(fields)
  diariumFormKeys.forEach((key) => {
    const formValue = formState[key]
    if (formValue !== '') {
      // $FlowIgnore
      const navisecProfileKey: 'address' | 'postal_code' | 'city' = fields[key]
      result = {
        // $FlowIgnore
        ...result,
        // $FlowIgnore
        [navisecProfileKey]: formValue,
      }
    }
  })
  return result
}

const formatErrorText = (err: Object): string => {
  // This is quite hardcoded functionality
  // In future must be more dynamic
  let errors = ''
  if (Object.prototype.hasOwnProperty.call(err, 'email')) {
    let emailErrors = 'Email: '
    if (Array.isArray(err.email)) {
      err.email.forEach((error) => (emailErrors += `${error} `))
    }
    errors += `${emailErrors}\n`
  }
  return errors
}

const getUserDetailsForRegistration = (userDetails: Object): Object => {
  const {
    emailAddress,
    firstname,
    lastname,
    postalCode,
    streetAddress,
  } = userDetails

  const data = {
    email: emailAddress,
    profile: {
      first_name: firstname,
      last_name: lastname,
      postal_code: postalCode || '',
      address: streetAddress || '',
    },
    booking_registration: true,
  }
  return data
}

export default (withOnlineBookingModal(PersonalDetailsView): *)
