// @flow
import { UPDATE_TENANT_SETTINGS } from 'actions/tenant'
import type { Action } from '.'

export type TenantSettingsState = {
  id: ?number,
  allow_sign_up: boolean,
  color: ?string,
  description: ?string,
  gtm_id: ?string,
  language: ?string,
  logo: ?string,
  message_clean: ?number,
  name: ?string,
  override_viivi: boolean,
  own_clients_only: boolean,
  own_threads_only: boolean,
  register_email_text: ?string,
  require_client_secure_login: boolean,
  require_client_two_factor?: boolean,
  require_ssn: boolean,
  require_staff_two_factor?: boolean,
  show_courses: boolean,
  show_ssn: boolean,
  sms_enabled?: boolean,
  sms_notifications_enabled?: boolean,
  sms_title?: ?string,
  staff_default_plan: ?string,
  tenant_id: ?number,
  terms: ?string,
  terms_modified: ?string,
  title: ?string,
  two_factor_enabled?: boolean,
  use_ssn: boolean,
  use_secure_auth: boolean,
  locked?: boolean,
  require_login_for_online_booking: boolean,
  use_online_booking: boolean,
  show_my_appointments: boolean,
  country: 'finland' | 'norway',
}

export const initialValue: TenantSettingsState = {
  id: null,
  allow_sign_up: false,
  color: '',
  description: '',
  gtm_id: null,
  language: null,
  logo: null,
  message_clean: null,
  name: null,
  override_viivi: false,
  own_clients_only: false,
  own_threads_only: false,
  register_email_text: null,
  require_client_secure_login: false,
  require_client_two_factor: false,
  require_ssn: false,
  require_staff_two_factor: false,
  show_courses: false,
  show_ssn: false,
  sms_enabled: false,
  sms_notifications_enabled: false,
  sms_title: null,
  staff_default_plan: null,
  tenant_id: null,
  terms: '',
  terms_modified: null,
  title: '',
  two_factor_enabled: false,
  use_ssn: false,
  use_secure_auth: false,
  locked: false,
  require_login_for_online_booking: false,
  use_online_booking: false,
  show_my_appointments: false,
  country: 'finland',
}

export default function tenantReducer(
  state: TenantSettingsState,
  action: Action
): Object {
  const { payload } = action
  switch (action.type) {
    case UPDATE_TENANT_SETTINGS:
      return Object.assign({}, state, payload)

    default:
      return state || {}
  }
}
