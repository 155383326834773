// @flow
import React from 'react'
import type { Location } from '../../../reducers/onlineBooking'

type Props = {
  location: Location | Location[],
  children?: *,
  onClick?: Function,
  wrapper?: *,
  size?: string,
}

type WrapperProps = {
  component?: *,
  children?: *,
}

const Wrapper = ({ component: Component, ...props }: WrapperProps) =>
  Component ? (
    <Component {...props} />
  ) : (
    <div
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    />
  )

function LocationDetails({
  location,
  onClick,
  children,
  wrapper,
  size,
}: Props): * {
  let locationName = ''
  let streetAddress = ''
  let postalCode = ''
  let postOffice = ''

  if (Array.isArray(location) && location.length > 1) {
    let firstName = true
    location.forEach((loc) => {
      if (!firstName) {
        locationName += `, ${loc.name}`
      } else {
        locationName += `${loc.name}`
        firstName = false
      }
    })
  } else if (!Array.isArray(location)) {
    const {
      name,
      address: { post_office, postal_code, street_address },
    } = location
    locationName = name
    streetAddress = street_address
    postalCode = postal_code
    postOffice = post_office
  }

  function handleClick() {
    onClick && onClick()
  }

  const locationNameClassNames =
    size === 'l'
      ? 'n-font-size-l n-font-weight-strong'
      : 'n-color-text-weaker n-font-weight-strong'

  return (
    <Wrapper component={wrapper} onClick={handleClick}>
      <div>
        <p className={locationNameClassNames}>{locationName}</p>
        {!Array.isArray(location) && (
          <p className='n-color-text-weak'>
            {streetAddress}, {postalCode} {postOffice}
          </p>
        )}
      </div>
      {children && children}
    </Wrapper>
  )
}

export default LocationDetails
