// @flow
import React, { useState } from 'react'

import isMobileHook from 'utils/isMobileHook'

import { Button, Icon } from '@nordhealth/react'

import Switch from 'components/Switch'

import navigator from 'routes/base'

import { BOOKING_STEPS_URLS } from '../../OnlineBooking'

import { __ } from 'utils/gettext'

import type { OptionObject as Option } from 'components/Switch'
import type { Node } from 'react'

type Props = {
  options: Option[],
  showPast: boolean,
  setShowPast: Function,
}

const AppointmentHeader = (props: Props): Node => {
  const navigateToBooking = () => {
    navigator.navigate(BOOKING_STEPS_URLS.FRONTPAGE)
  }

  const isMobile = isMobileHook(window)
  return (
    <div className='appointment-header'>
      <Switch
        options={props.options}
        selectedOption={props.showPast}
        setSelectedOption={props.setShowPast}
        translateOptions
      />
      <Button variant='primary' onClick={navigateToBooking}>
        <div className='button-content'>
          <Icon name='interface-add' />{' '}
          {!isMobile && (
            <span className='button-content-text'>
              {' '}
              {__('New appointment')}
            </span>
          )}
        </div>
      </Button>
    </div>
  )
}

export default AppointmentHeader
