// @flow
import self from 'autobind-decorator'
import React,  { PureComponent } from 'react'

import { defaultProps } from '../constants'
import style from '../style'
import { DEFAULT_PRIMARY_COLOR } from 'constants'

import type { Node } from 'react'


type WithBaseProps = {|
  toggle: SyntheticMouseEvent<*> => void,
  disabled: boolean,
  checked: boolean,
  width: string,
  type: string,
  name: string,
  children: Node,
  className: string,
  focused: boolean,
  toggleFocus: Function,
  label?: string,
|}


type BaseToggleComponentPropTypes = {|
  type: string,
  label: Node,
  name?: string,
  children: Node,
  checked?: boolean,
  disabled?: boolean,
  value?: string,
  color?: string,
  size?: string | number,
  className?: string,
  disabledColor?: string,
  highlightColor?: string,
  onChange: boolean => void,
  primaryColor: string,
|}


class WithBase extends PureComponent<WithBaseProps> {

  get focused (): boolean {
    return this.props.focused
  }

  toggleFocus () {
    if (this.props.toggleFocus)
      this.props.toggleFocus( !this.props.focused )
  }

  render () {
    const labelComponent = this.props.label || null
    const svgStyle: Object = Object.assign({}, style.svg, {
      width: this.props.width,
    })

    const hiddenInput = <input
      style={ style.input }
      type={ this.props.type }
      name={ this.props.name }
      checked={ this.props.checked }
      disabled={ this.props.disabled }
      onChange={ this.props.toggle }
      onFocus={ this.toggleFocus.bind(this) }
      onBlur={ this.toggleFocus.bind(this) }
      id={ this.props.name }
    />

    const sprite = <svg
      style={ svgStyle }
      viewBox='0 0 14 14'
      className='checkbox sprite'>
      { this.props.children }
    </svg>

    return <span
      style={ style.container }
      className={ this.props.className }>
      { hiddenInput }
      { sprite }
      { labelComponent }
    </span>

  }
}


export default class BaseToggleComponent extends PureComponent<BaseToggleComponentPropTypes, { focused: boolean }> {

  static PATH: string
  static style: Object
  static typeAttribute: string

  static displayName = 'Togglable Input Component'
  static defaultProps: BaseToggleComponentPropTypes = defaultProps

  state = { focused: false }

  get focusColor (): string {
    if (this.props.primaryColor && this.props.primaryColor.length)
      return this.props.primaryColor
    return DEFAULT_PRIMARY_COLOR
  }

  renderMarkerComponent: () => Node

  get checked (): boolean {
    return bool('checked' in this.props
      ? this.props.checked
      : this.props.value)
  }

  get disabled (): string | boolean {
    return this.props.disabled ? 'disabled' : false
  }

  get size (): string {
    if (typeof this.props.size === 'number')
      return `${this.props.size}em`

    // flow-ignore
    return this.props.size
  }


  @self toggle () {
    this.props.onChange(!this.checked)
  }

  get activeColor (): string | void {
    if (!this.disabled && this.checked)
      return this.props.highlightColor
    return 'transparent'
  }


  get color (): string | void {
    if (this.disabled)
      return this.props.disabledColor
    if (this.checked)
      return this.props.highlightColor
    return this.props.color
  }

  render (): Node {
    const baseStyle = {
      ...this.constructor.style,
      stroke: this.state.focused ? this.focusColor : this.color,
    }

    const markerStyle = {
      ...this.constructor.style,
      stroke: this.checked ? this.props.highlightColor : 'transparent'
    }

    return <WithBase
      { ...this.props }
      type={ this.constructor.typeAttribute }
      name={ this.props.name }
      label={ this.props.children }
      toggle={ this.toggle }
      checked={ this.checked }
      disabled={ this.disabled }
      focused={ this.state.focused }
      toggleFocus={ focused => this.setState({ focused })}>

      <g style={ baseStyle }>
        { this.renderBaseComponent() }
      </g>

      <g style={ markerStyle }>
        { this.renderMarkerComponent() }
      </g>

    </WithBase>
  }

  renderBaseComponent (): Node {
    return <path d={ this.constructor.PATH } />
  }

}


const bool = (val): boolean =>
  val ? true : false
