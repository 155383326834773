// @flow
import React, { Component } from 'react'
import Icon from 'components/icons'
import self from 'autobind-decorator'

type ChoiceProps = {
  name: number,
  value: string | null,
  score: number | null,
  focused: boolean,
  onTab: Function,
  onBlur?: Function,
  onFocus: Function,
  onRemove: Function,
  onChange: Object => Promise<*>,
}

type ChoiceState = {
  value: string | number | null,
  score: string | number | null,
}


export default class Choice extends Component<ChoiceProps, ChoiceState> {

  state = {
    value: '',
    score: null,
  }

  static defaultValue = ''

  element: HTMLInputElement
  scoreElement: HTMLInputElement
  activeElement: HTMLInputElement

  shouldComponentUpdate (props: ChoiceProps) {
    if (props.name !== this.props.name)
      return true
    if (props.value !== this.state.value)
      return true
    if (props.score !== this.state.score)
      return true
    return false
  }

  componentDidMount () {
    this.update(this.props)
  }

  UNSAFE_componentWillReceiveProps (props: ChoiceProps) {
    this.update(props)
  }

  setNativeNodeFocus (focused: boolean) {
    if (focused)
      this.focus()
    else
      this.blur()
  }

  @self
  focus () {
    if (!isNaN(this.props.name))
      this.props.onFocus(this.props.name)
    if (this.activeElement && this.activeElement !== document.activeElement)
      this.activeElement.focus()
  }

  blur () {
    if (this.activeElement && this.activeElement === document.activeElement)
      this.activeElement.blur()
  }

  @self
  onClick (element: HTMLInputElement) {
    this.activeElement = element
    this.focus()
  }

  // eslint-disable-next-line complexity
  update (props: ChoiceProps) {
    const updates = {}

    if (props.value !== this.state.value)
      if (props.value === null)
        updates.value = this.constructor.defaultValue
      else if (typeof props.value === 'string')
        updates.value = props.value
      else
        updates.value = props.value.value
    if (is.number(props.score) && props.score !== this.state.score)
      updates.score = props.score

    if (Object.keys(updates).length)
      this.setState(updates)
    this.setNativeNodeFocus(props.focused)
  }

  handleChange (value: string | number, type?: string) {
    if (type === 'score') {
      let score = value
      if (typeof value === 'string')
        score = Number.parseInt(value)
      if (isNaN(score)) score = null
      this.setState({ score: score })
      this.props.onChange({ value: this.state.value, score })
    } else {
      this.setState({ value })
      this.props.onChange({ value, score: this.state.score })
    }
  }

  onKeyDown (event: KeyboardEvent) {
    const { key } = event
    if (key === 'Tab' && this.activeElement === this.element) {
      event.preventDefault()
      this.activeElement = this.scoreElement
    }
    this.focus()
  }

  render () {

    return <div className='table-row option'>

      <div className='table-data-80'>
        <input
          type='text'
          ref={ ref => ref && (this.element = ref) }
          value={ this.state.value }
          onBlur={ this.props.onBlur }
          onFocus={ this.focus }
          onChange={ (e) => this.handleChange(e.target.value) }
          onKeyDown={ (event) => this.onKeyDown(event) }
          onClick={ () => this.onClick(this.element) }
        />
      </div>

      <div className='table-data-15'>
        <input
          type='number'
          ref={ ref => ref && (this.scoreElement = ref) }
          value={ typeof this.state.score === 'number' ? this.state.score : '' }
          className='score'
          onBlur={ this.props.onBlur }
          onFocus={ this.focus }
          onChange={ (e) => this.handleChange(e.target.value, 'score') }
          onKeyDown={ onKey('Tab', this.props.onTab) }
          onClick={ () => this.onClick(this.scoreElement) }
        />
      </div>

      <div className='table-data-5'>
        <span
          onClick={ this.props.onRemove }
          className='button error remove-option small text'>
          <Icon.Remove />
        </span>
      </div>

    </div>
  }

}


function onKey (keyDescriptor: string, fn: Event => boolean) {

  let keys = keyDescriptor.split('+').map(key => key.trim())

  let ctrlRequired  = keys.includes('Ctrl')
  let shiftRequired = keys.includes('Shift')

  return (event: KeyboardEvent) => {
    let { key, shiftKey, ctrlKey } = event
    let shiftMatch = shiftRequired === shiftKey
    let ctrlMatch  = ctrlRequired  === ctrlKey
    let keyMatch   = keys.includes(key)

    return shiftMatch && ctrlMatch && keyMatch && fn(event)
  }
}
