// @flow
import type { Location } from 'reducers/onlineBooking'

// When location step is skipped - the only way to
// get proper location is by filtering it by the location
// id that is attached to certain time slot

export const getLocationBySelectedTimeslot = (
  timeSlotLocationId: number,
  allLocations: Location[]
): Location[] => {
  return allLocations.filter(
    (location: Location) => location.id === timeSlotLocationId
  )
}
