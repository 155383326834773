// @flow
import { BLOCK_TYPES, HTML_REPLACE_MAP, INLINE_STYLES, TYPE_PROPERTIES, HTML_DEFINITIONS } from './definitions'
import { convertFromHTML, ContentState, CompositeDecorator, EditorState } from 'draft-js'
import type { ContentBlock, DraftDecorator } from 'draft-js'
import type { Element } from 'react'
import Stream from 'stream'

type BlockType = {
  getText: Function,
  getType: Function,
}

/**
 * Generates a html section node formatted according to the given block's
 * type.
 * @method renderBlock
 * @param  {Object}    block DraftJS block that will be rendered to HTML
 * @param  {string}    tmpl  Optional template that is inserted inside the block
 * @return {string}          Returns a string containing the html for a
 *                           RTE text section
 */

export function renderBlock(block: any, tmpl: string = ''): string {
  const typ = block.getType()
  const { tag, style } = BLOCK_TYPES.find(item => item.style === typ)

  // Make an array of the block text and replace special characters with entities.
  const content = applyInlineTagsLexer(block)

  const html = !tmpl || tmpl.length === 0 ? content : tmpl.replace('{content}', content)

  const rootTag = HTML_DEFINITIONS.rootTag
  const sectionCls = HTML_DEFINITIONS.sectionCls
  const styleCls = HTML_DEFINITIONS.textBlockClsPrefix + style
  const contentCls = HTML_DEFINITIONS.contentCls

  return `<${rootTag} class='${sectionCls} ${styleCls}'>
    <${tag}>
      <span class='${contentCls}'>
        ${html}
      </span>
    </${tag}>
  </${rootTag}>`
}

/**
 * Returns a map containing renderers for the stateToHTML's options
 * @method getBlockRenderers
 * @return {Object}          A map for each row in BLOCK_TYPES
 */

export function getBlockRenderers(): {} {
  let out = {}
  let addRenderer = item => (out[item.style] = getRenderer(item))
  BLOCK_TYPES.forEach(addRenderer)
  return out
}

const getRenderer = item => block => renderBlock(block, '', item)

// eslint-disable-next-line max-statements
export async function render(...blocks: Array<ContentBlock>) {

  let delimiter = '\n'
  let renderers = getBlockRenderers()
  let stream = []
  let source = new Stream()
  let destination = new Stream()

  destination.writable = true
  destination.write = ({ id, content }) => stream[id] = content
  source.readable = true
  source.pipe(destination)

  let response = new Promise(resolve => {
    const finish = () => resolve(stream.join(delimiter))
    destination.end = finish
  })

  const writeBlock = async (block, n) => {
    let applyRenderer = renderers[block.type]
    source.emit('data', {
      id: n,
      content: applyRenderer(block),
    })
    if (n === blocks.length - 1) source.emit('end')
  }

  blocks.forEach(writeBlock)
  return await response
}

export function getProperties (block: BlockType): Map {
  let blockType = block.getType()
  let properties = TYPE_PROPERTIES.get(blockType)
  return properties
}

export function getBlockStyle (block: BlockType): string {
  let { className } = getProperties(block)
  return `${className || 'default unstyled'} text-content`
}

const getBlockCharacters = block =>
  block.getText().split('')

const replaceSpecialChars = (c: string): string =>
  HTML_REPLACE_MAP.hasOwnProperty(c) ? HTML_REPLACE_MAP[c] : c

const startTag = tagName =>
  `<${tagName}>`

const endTag = tagName =>
  `</${tagName}>`

// decoratorsList = [
//   {
//     strategy: findLinkEntities,
//     component: Link,
//   },
//   {
//     strategy: findImageEntities,
//     component: Image,
//   },
// ]


/**
 * Create an EditorState instance from a string of html
 *
 * @method  htmlToEditorState
 * @param   {string}                html
 * @param   {Array<DraftDecorator>} decorators An array of decorator definitions for the created editor state
 * @return  {EditorState}                      The newly created EditorState instance
 */

export function htmlToEditorState (html: string | null = null, ...decorators: Array<DraftDecorator>): EditorState {

  const empty = () =>
    EditorState.createEmpty(decorator)

  // Create a composite out of the given decorator definitions
  const decorator = new CompositeDecorator(decorators)
  if (html === null)
    return empty()

  // Parse the given html string into DraftJS™️ blocks
  const blocks = convertFromHTML(html)
  if (!blocks || !blocks.contentBlocks)
    return empty()

  const contentState = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)
  return EditorState.createWithContent(contentState, decorator)
}

// FIXME: Split
// eslint-disable-next-line max-statements, complexity
function applyInlineTagsLexer(block: ContentBlock): string {
  const getOpenTags = () => openedTags.slice(0).reverse()
  const characters = getBlockCharacters(block).map(replaceSpecialChars)
  const openedTags = []
  let content = ''

  for (const i in characters) {
    const inlineStyleOrderedSet = block.getInlineStyleAt(i)

    // Close all the opened tags that need to be closed.
    const reversed = getOpenTags()
    for (const tag of reversed) {
      const styleObj = INLINE_STYLES.find(obj => obj.tag === tag)
      if (!inlineStyleOrderedSet.has(styleObj.style)) {

        // Close all tags that should be closed before the tag that needs to be closed.
        // eslint-disable-next-line max-depth
        while (styleObj.tag !== openedTags[openedTags.length - 1]) {
          content += endTag(openedTags[openedTags.length - 1])
          openedTags.pop()
        }

        // Close the actual tag that needs to be closed
        content += endTag(styleObj.tag)
        openedTags.pop()
      }
    }

    // Open tags if text has inline styling.
    for (const styleObj of INLINE_STYLES) {
      if (inlineStyleOrderedSet.has(styleObj.style) && openedTags.indexOf(styleObj.tag) === -1) {
        content += startTag(styleObj.tag)
        openedTags.push(styleObj.tag)
      }
    }
    content += characters[i]
  }

  // Append any unclosed tags' closing tag to the output
  const reducer = (txt, tag) => txt + endTag(tag)
  return content + getOpenTags().reduce(reducer, '')
}
