/**
 * @flow
 * @class UserView
 */
import React, { Component } from 'react'
import { range } from 'lodash'
import connect from 'bound-connect'
import ReactDataGrid from 'react-data-grid'

import type { StateType } from 'store/initialState'


type DataGridStateTypes = {
  rows: Array <*>,
  topLeft: Object,
  botRight: Object,
  language: string
}

type UpdateRowTypes = {
  fromRow: number,
  toRow: number,
  action: string,
  updated: Object
}

export type RowType = {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  social_security_number: string,
  staff_email: string,
  use_secure_auth: null | string,
}

const columnsEN = [
  { key: 'id', name: '#', editable: false, width: 40 },
  { key: 'first_name', name: 'First name', editable: true, resizable: true },
  { key: 'last_name', name: 'Last name', editable: true, resizable: true },
  { key: 'email', name: 'Email', editable: true, resizable: true },
  { key: 'phone_number', name: 'Phone number', editable: true, resizable: true },
  { key: 'social_security_number', name: 'SSN', editable: true, resizable: true },
  { key: 'staff_email', name: 'Staff email', editable: true, resizable: true },
  { key: 'use_secure_auth', name: 'Use secure auth?', editable: true, resizable: true },
  { key: 'client_groups', name: 'Client groups', editable: true, resizable: true },
  { key: 'metadata', name: 'Metadata', editable: true, resizable: true }
]

const columnsFI = [
  { key: 'id', name: '#', editable: false, width: 40 },
  { key: 'first_name', name: 'Etunimi', editable: true, resizable: true },
  { key: 'last_name', name: 'Sukunimi', editable: true, resizable: true },
  { key: 'email', name: 'Sähköposti', editable: true, resizable: true },
  { key: 'phone_number', name: 'Puhelinnumero', editable: true, resizable: true },
  { key: 'social_security_number', name: 'Henkilötunnus', editable: true, resizable: true },
  { key: 'staff_email', name: 'Henkilökunnan sähköposti', editable: true, resizable: true },
  { key: 'use_secure_auth', name: 'Vahva tunnistautuminen?', editable: true, resizable: true },
  { key: 'client_groups', name: 'Asiakasryhmät', editable: true, resizable: true },
  { key: 'metadata', name: 'Metadata', editable: true, resizable: true }
]

let columns = []

const initialRows = Array.from(Array(100).keys(), (_, x) => (
  { id: x, first_name: null, last_name: null, email: null, phone_number: null, social_security_number: null,
    staff_email: null, use_secure_auth: null }
))

const defaultParsePaste = str => str.split(/\r\n|\n|\r/)
                                    .map(row => row.split('\t'))


@connect
export default class DataGrid extends Component<DataGridStateTypes, *> {

  constructor (props: *) {
    super(props)
    this.state = {
      rows: initialRows,
      topLeft: {},
      botRight: {},
    }

    // Copy paste event handler
    document.addEventListener('copy', this.handleCopy)
    document.addEventListener('paste', this.handlePaste)
  }

  static actions: Array<*> = []
  static properties: Object = (state: StateType) => ({
    language: state.user.profile.primary_language,
  })

  componentWillUnmount () {
    this.removeAllListeners()
  }

  componentDidMount () {
    if (this.props.language)
      columns = this.props.language === 'en' ? columnsEN : columnsFI
    else
      columns = columnsEN

    // Remove the first row to start numbering from 1 instead of 0
    let rows = [ ...this.state.rows ]
    if (rows[0].id === 0)
      rows.shift()
    this.setState({ rows })
  }

  removeAllListeners: Function = () => {
    document.removeEventListener('copy', this.handleCopy)
    document.removeEventListener('paste', this.handlePaste)
  }

  rowGetter: Function = (i: number) => {
    const { rows } = this.state
    return rows[i]
  }

  updateRows: Function = (startIdx: number, newRows: Array<*>) => {
    this.setState((state) => {
      const rows = state.rows.slice()
      for (let i = 0; i < newRows.length; i++) {
        if (startIdx + i < rows.length) {
          rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] }
        }
      }
      return { rows }
    })
  }

  getRowData: Function = () => {
    // TODO: How to properly check row is not an empty row?
    const rows = [ ...this.state.rows ]
    let cleanedRows = []
    rows.forEach(row => {
      if (row.first_name || row.last_name || row.email)
        cleanedRows.push(row)
    })

    return cleanedRows
  }

  handleCopy: Function = (e: Object) => {
    e.preventDefault()
    const { topLeft, botRight } = this.state

    // Loop through each row
    const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(

      // Loop through each column
      rowIdx => columns.slice(topLeft.colIdx, botRight.colIdx + 1).map(

        // Grab the row values and make a text string
        col => this.rowGetter(rowIdx)[col.key], )
        .join('\t'), )
      .join('\n')
    e.clipboardData.setData('text/plain', text)
  }

  handlePaste: Function = (e: Object) => {
    e.preventDefault()
    const { topLeft } = this.state

    const newRows = []
    const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'))

    pasteData.forEach((row) => {
      const rowData = {}

      // Merge the values from pasting and the keys from the columns
      columns.slice(topLeft.colIdx, topLeft.colIdx + row.length)
        .forEach((col, j) => {

          // Create the key-value pair for the row
          rowData[col.key] = row[j]
        })

      // Push the new row to the changes
      newRows.push(rowData)
    })

    this.updateRows(topLeft.rowIdx, newRows)
  }

  onGridRowsUpdated: Function = ({ fromRow, toRow, updated, action }: UpdateRowTypes) => {
    if (action !== 'COPY_PASTE') {
      this.setState((state) => {
        const rows = state.rows.slice()
        for (let i = fromRow; i <= toRow; i++) {
          rows[i] = { ...rows[i], ...updated }
        }
        return { rows }
      })
    }
  }

  setSelection: Function = (args) => {
    this.setState({
      topLeft: {
        rowIdx: args.topLeft.rowIdx,
        colIdx: args.topLeft.idx,
      },
      botRight: {
        rowIdx: args.bottomRight.rowIdx,
        colIdx: args.bottomRight.idx,
      },
    })
  }

  render (): React$Element<*> {
    const { rows } = this.state
    return (
      <div>
        <ReactDataGrid
          columns={ columns }
          rowGetter={ i => rows[i] }
          rowsCount={ rows.length }
          onGridRowsUpdated={ this.onGridRowsUpdated }
          enableCellSelect={ true }
          minColumnWidth={ 25 }
          headerRowHeight={ 45 }
          minHeight={ 700 }
          onCellSelected={s => this.setSelection({topLeft: s, bottomRight: s})}
        />
      </div>
    )
  }
}
