// @flow

import React from 'react'
import classNames from 'classnames'
import __ from 'utils/gettext'
import { resolveClass } from 'utils/resolve'
import Icon from 'components/icons'

import type { Node } from 'react'
import type { StateType } from 'utils/resolve'

export type BadgeProperties = {
  text?: Node,
  style?: Object,
  level: StateType,
  onClick?: Function,
  children?: Node,
  onRemove?: Function,
  openInNewTab?: boolean,
}

export const Badge = (props: BadgeProperties): React$Element<*> =>
  <div
    onClick={ props.onClick }
    className={ classNames(resolveClass(props, 'badge'), props.onClick && 'has-action') }
    style={ props.style }>
    { props.children || props.text }
    { props.openInNewTab && <Icon.OpenInNew size={ 12 } ariaLabel={ __('Open link in a new tab') } /> }
    { props.onRemove &&
      <span
        aria-label={ __('Delete') }
        tabIndex={ 0 }
        className='focusable'
        onKeyPress={ props.onRemove }
        onClick={ props.onRemove }><Icon.Cancel size={ 10 }/></span> }
  </div>

export const Token = (props: BadgeProperties): React$Element<*> =>
  <span
    onClick={ props.onClick }
    className={ classNames(resolveClass(props, 'token'), props.onClick && 'has-action') }
    style={ props.style }>
    { props.children || props.text }
    { props.onRemove && <span onClick={ props.onRemove }><Icon.Cancel size={ 10 }/></span> }
  </span>

export const getBadge = (text: Node, level: StateType = 'primary'): Function =>
  () => <Badge level={ level } text={ text } />

export default Badge
