import api from 'api'
import { useEffect, useState } from 'react'
import { CancelablePromise } from 'utils/promise'

export const useFetch = (
  url,
  method,
  params = {},
  dependencies = [],
  disableFetch = false,
  callBack = null
) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    let cancelable_promise
    const fetch = async () => {
      setLoading(true)
      setError(null)
      cancelable_promise = new CancelablePromise(api[method](url, params))
      cancelable_promise.then((res) => {
        if (!res.problem) {
          if (callBack) {
            setData(callBack(res.data))
          } else {
            setData(res.data)
          }
        } else {
          setError(res)
        }
        setLoading(false)
      })
    }
    !disableFetch && fetch()
    return () => cancelable_promise && cancelable_promise.cancel()
  }, [url, ...dependencies])
  return [data, error, loading]
}
