import prop from 'prop-types'

const f1 = 2.75
const f2 = 2.25

export const CHECKBOX_PATH = `
  m  2  2
  h  9  c  1  0  1  1  1  1
  v  9  c  0  1 -1  1 -1  1
  h -9  c -1  0 -1 -1 -1 -1
  v -9  c  0 -1  1 -1  1 -1
  z`


export const RADIO_PATH = `
  m  2 6.64029
  c  0     -${f1}  ${f2} -5      5 -5
  c  ${f1}  0      5      ${f2}  5  5
  c  0      ${f1} -${f2}  5     -5  5
  c -${f1}  0     -5     -${f2} -5 -5
  z`


export const propTypes = {
  name:      prop.string,
  value:     prop.bool,
  checked:   prop.bool,
  disabled:  prop.bool,
  onChange:  prop.func.isRequired,
  className: prop.string,
  children:  prop.children,

  width:          prop.oneOf([ prop.string, prop.number ]),
  color:          prop.string,
  disabledColor:  prop.string,
  highlightColor: prop.string,
}


export const defaultProps = {
  value: false,
  width: '1em',
  color: 'currentColor',
  disabledColor:  '#757082',
  highlightColor: '#4a4655',
}
