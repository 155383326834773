import Field from './Field'
import applyFieldsInterface from '../fields-interface'


export default class NestedField extends Field {

  constructor (props) {
    const getFields = () =>
      props.children || {}
    super (...arguments)
    applyFieldsInterface(this, getFields)
  }

  render () {
    return this.fieldComponents
  }

  get value () {
    return this.fieldData
  }
}
