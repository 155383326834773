// @flow
import api from 'api'
import cache from 'api/cache'
import Fieldset from './index'


export default constructFieldsetFromApiResponse

type FieldsetType = *
type FieldsetResultType = Map<api.MethodType, FieldsetType> | FieldsetType


export async function fetchFieldsData (url: string, params: Object = {}, noCache?: boolean, onReferenceError?: Function): Promise<Map<api.MethodType, Object> | null> {
  if (noCache)
    cache.removeCachedResponse(url, 'options')
  const response = await api.options(url, params)

  if (!response.data.actions)
    if (onReferenceError) {
      onReferenceError(response)
      return null
    }
    else
      throw new ReferenceError(`The options request's response should contain a property named \`actions\`.`)
  const actions  = Object.entries(response.data.actions)
  return new Map(actions)
}


async function constructFieldsetFromApiResponse (url: string, dataOrMethod: Object | string = 'PUT', noCache?: boolean, onReferenceError?: Function, fieldFromProps?: Object | null = null): Promise<FieldsetResultType> {
  const selector  = typeof dataOrMethod === 'string' ? dataOrMethod.toUpperCase() : null
  const data      = typeof dataOrMethod === 'object' ? dataOrMethod : {}
  let groups    = await fetchFieldsData(url, data, noCache, onReferenceError)

  // This block allows to change the type of field that will differ from the one specified by the backend
  // For example common use-case maybe a password field. In backend however - it is declared as 'string',
  // Thus the frontend will render incorrect component.
  if (fieldFromProps) {
    let backendFields = groups.get(selector)

    fieldFromProps.forEach(field => {
      const fieldName      = field.name

      if (field.type && fieldName && backendFields[fieldName]) {
        const fieldTypeToSet = field.type
        backendFields[fieldName].type = fieldTypeToSet
      }
      if (field.hideRequiredIndicator && fieldName) {
        backendFields[fieldName].hideRequiredIndicator = true
      }
    })

  }
  return groups && toFieldsets(selector, groups)
}


export function select (selector: string | null, groups: Map<string, Object>) {
  if (selector !== null)
    return groups.get(selector)

  const result = new Map()

  for (const [ method, fields ] of groups)
    result.set(method, fields)
  return result

}


export function toFieldsets (selector: string | null, groups: Map<string, Object>) {

  // Return a single fieldset
  if (selector !== null) {
    if (groups.has(selector))
      return new Fieldset(groups.get(selector))
    throw new Error('Suplied selector "' + selector + '" not found in response')
  }

  // Return all fieldsets
  const fieldsets = new Map()

  for (const [ method, fields ] of groups)
    fieldsets.set(method, new Fieldset(fields))
  return fieldsets
}
