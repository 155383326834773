// @flow
import React from 'react'
import Field from '../components/Field'
import FieldSet from '../components/FieldSet'
import { select, fetchFieldsData } from './constructFromApiResponse'
import { getFieldComponents, getInitialValues } from './resolve'
import getProxyFor from './deferrable'

import type { FieldDefinitions } from '.'


export default function Fieldset (definitions: FieldDefinitions): * {
  const fieldNames = Object.keys(definitions)
  const fields     = getFieldComponents(definitions)
  const value      = getInitialValues(definitions)
  const errors     = null


  return class Fields extends FieldSet {
    static fieldNames: Array<string>  = fieldNames
    static fields: Object             = fields
    state: Object                     = { value, errors }
  }
}


// TODO: Generally not usable yet
export function Deferred (url: string, method: string = 'PUT') {
  const references      = new Set()
  const fieldsData      = {}
  const fields          = getProxyFor(fieldsData)


  const loadDefinitions = async () => {
    const response      = await fetchFieldsData(url)
    const defs          = select(method, response)
    const fieldNames    = Object.keys(defs)

    Object.assign(fieldsData, defs)

    references.forEach(updateFieldNames.bind(null, fieldNames))
    references.clear()
  }

  const updateFieldNames = (fieldNames, reference) => {
    reference.constructor.fieldNames = fieldNames
    reference.forceUpdate()
  }

  loadDefinitions()

  return class DeferredFieldset extends FieldSet {
    static fieldNames = []
    static fields = fields
    constructor (props: *) {
      super(props)
      references.add(this)
    }
  }
}


export function BoundField (id: number | string, field: *) {
  return function (props: *) {
    return <Field
      { ...field }
      { ...props }
      id={ id }
    />
  }
}
