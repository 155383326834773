// @flow
import React from 'react'
import type { Node, ComponentType } from 'react'


export type FieldProps = {
  value: *,
  errors: Array<Error> | null,
  onChange: Function,
  disabled?: boolean,
  id?: string,
}


class FieldsManager {
  static fallbackComponent = inputFallback
  renderers = new Map()
  clear     = () => this.renderers.clear() || this
  register  = (type, component) => this.renderers.set(type, decorateStatics(component)) || this
  has       = (type: string) => this.renderers.has(type)
  get       = (type: string) => this.has(type)
    ? this.renderers.get(type)
    : FieldsManager.fallbackComponent

  render (properties: Object) {
    const component = this.get(properties.type)
    return this.renderComponent({ ...properties, component })
  }

  // eslint-disable-next-line class-methods-use-this
  renderComponent (properties: Object) {
    const Component = properties.component
    const value = properties.value !== null
      ? properties.value
      : Component.defaultValue
    const props = { ...properties, value }
    return <Component { ...props } disabled={ (properties.forceShow && properties.read_only) || props.disabled }/>
  }
}


const fields = new FieldsManager()
export default fields


export function registerRenderer (type: string, render: ComponentType<FieldProps>): FieldsManager {
  fields.register(type, render)
  return fields
}


export function clearRenderers (): FieldsManager {
  fields.clear()
  return fields
}


function decorateStatics (Component) {
  return Object.assign(Component, {
    displayName:  getName(Component),
    defaultProps: getDefaults(Component),
    defaultValue: getDefaultValue(Component),
  })
}

const getName = C =>
  C.displayName || C.name

const getDefaults = C =>
  C.defaultProps || {}

const getDefaultValue = C => {
  if ('defaultValue' in C)
    return C.defaultValue
  const defaults = getDefaults(C)
  if ('value' in defaults)
    return defaults.value
  return null
}


function inputFallback (props: FieldProps): Node {
  const handleChange = (event: SyntheticKeyboardEvent<*>) =>
    typeof props.onChange === 'function'
    && props.onChange(event.target.value)

  const getValueOrDefault = (props: FieldProps) =>
    props.value ? props.value : props.type === "integer" ? 0 : ''

  return <input
    type='text'
    id={ props.id }
    name={ props.id }
    value={ getValueOrDefault(props) }
    onChange={ handleChange }
    disabled={ props.disabled }
  />
}
