// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


export const descriptors = {
  list:        ()       => `/threads/`,
  init:        ()       => `/threads/init/`,
  view:        (pk: ID) => `/threads/${pk}/`,
  threadEdit:  (pk: ID) => `/threads/${pk}/edit`,
  post:        (pk: ID) => `/threads/${pk}/post`,
  participants:(pk: ID) => `/threads/${pk}/participants/`,
}


const ThreadView = load(() =>
  import('views/Messages/ThreadView'))

const ThreadEditView = load(() =>
  import('views/Messages/ThreadEditView'))

const ThreadsListView = load(() =>
  import('views/Messages/ListView'))

const ThreadCreateView = load(() =>
  import('views/Messages/InitThreadView'))


export default <Switch>
  <Route exact path={ descriptors.list() } component={ ThreadsListView } />
  <Route path={ descriptors.init() } component={ ThreadCreateView } />
  <Route path={ descriptors.threadEdit(':id(\\d+)') } component={ ThreadEditView } />
  <Route path={ descriptors.view(':id(\\d+)') } component={ ThreadView } />
  <RouteNotFound />
</Switch>
