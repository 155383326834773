// @flow
import { combineReducers } from 'redux'
import communication from './communication'
import forms from './forms'
import core from './core'
import user from './user'
import users from './users'
import client from './client'
import tenants from './tenants'
import tenantSettings from './tenant'
import exercises from './exercises'
import courses from './courses'
import packages from './packages'
import tags from './tags'
import userGroups from './userGroups'
import documents from './documents'
import subscriptions from './subscriptions'
import onlineBooking from './onlineBooking'

// import { routerStateReducer as router } from 'redux-react-router'


export type Action = {
  +type: string,
  +payload: any,
  label?: string,
}


export default combineReducers({
  communication,
  forms,
  user,
  users,
  core,
  client,
  tenants,
  tenantSettings,
  exercises,
  courses,
  packages,
  tags,
  userGroups,
  documents,
  subscriptions,
  onlineBooking
})
