// @flow
import type TooltipManager from './TooltipManager'

import { CompositeDisposable, Disposable } from 'event-kit'

type ClientRectType = {
  top: number,
  left: number,
  right: number,
  bottom: number,
}

export default class Tooltip {

  _shouldShow: boolean
  _open_timeout: any
  _close_timeout: any

  subscriptions: CompositeDisposable
  tooltipManager: TooltipManager
  tooltipElement: HTMLElement
  element: HTMLElement
  visible: boolean
  item: HTMLElement

  static OPEN_DELAY: number = 100
  static CLOSE_DURATION: number = 1500
  static CLOSE_DELAY_ON_TOUCH_EVENTS: number = 2000
  static VISIBILITY_PROPERTY = 'opacity'

  constructor (tooltipManager: TooltipManager, ref: HTMLElement) {
    this.element        = ref
    this.tooltipManager = tooltipManager
    this.subscriptions  = new CompositeDisposable()
    this.subscriptions.add(
      addListener(ref, 'touchstart', this.requestShow.bind(this)),
      addListener(ref, 'mouseenter', this.requestShow.bind(this)),
      addListener(ref, 'mouseleave', this.hide.bind(this)))
  }

  get text (): string {
    return this.tooltipManager.getContentFor(this.element)
  }

  get tooltipElement (): HTMLElement {
    if (!this.item) {
      const containerElement = document.createElement('div')
      const textElement = document.createElement('article')
      containerElement.appendChild(textElement)
      containerElement.setAttribute('class', 'closed tooltip')
      containerElement.addEventListener('transitionend', (event) => {
        if (event.propertyName === Tooltip.VISIBILITY_PROPERTY)
          if (this._shouldShow)
            this.visible = true
          else {
            this.visible = false
            containerElement.remove()
          }
      })
      textElement.innerHTML = this.text
      this.item = containerElement
    }
    return this.item
  }

  updatePosition () {
    let [ location, y, x ] = this.calculateTooltipPosition()
    this.tooltipElement.setAttribute('style', `left: ${x}px; top: ${y}px`)
    this.tooltipElement.classList.remove('top', 'left', 'right', 'bottom')
    this.tooltipElement.classList.add(location)
  }

  calculateTooltipPosition (): [ string, number, number ] {
    return getClosestEdge(this.element.getBoundingClientRect())
  }

  requestShow (event: MouseEvent | TouchEvent) {
    const hide = () => this.hide()
    const show = () => this.show()
    const delay = event.touches ? Tooltip.OPEN_DELAY : 0

    clearTimeout(this._open_timeout)
    clearTimeout(this._close_timeout)

    this.updatePosition()
    this._shouldShow = true
    this._open_timeout = setTimeout(show, delay)
    if (event.touches)
      this._close_timeout = setTimeout(hide, Tooltip.CLOSE_DELAY_ON_TOUCH_EVENTS)
  }

  show () {
    if (!this._shouldShow)
      return this.hide()

    if (document.body instanceof HTMLElement)
      document.body.appendChild(this.tooltipElement)

    setTimeout(() => {
      this.tooltipElement.classList.remove('closed')
      this.tooltipElement.classList.add('open')
    }, 40)
  }

  hide (immediate: boolean = false) {
    this._shouldShow = false
    clearTimeout(this._open_timeout)
    clearTimeout(this._close_timeout)
    this.tooltipElement.classList.remove('open')
    this.tooltipElement.classList.add('closed')

    if (immediate === true)
      this.item.remove()
  }

  dispose () {
    if (this.item)
      this.item.remove()
    this.subscriptions.dispose()
    this.tooltipManager.contents.delete(this.element)
    this.tooltipManager.tooltips.delete(this.element)
  }
}


function addListener (el, eventName, handler, pre = false) {
  let callback = handler.bind(el)
  el.addEventListener(eventName, callback, pre)
  return new Disposable(() => el.removeEventListener(eventName, callback, pre))
}


// eslint-disable-next-line complexity
function getClosestEdge (bounds: ClientRectType) {

  let offset = {
    top: bounds.top,
    left: bounds.left,
    right: window.innerWidth - bounds.right,
    bottom: window.innerHeight - bounds.bottom,
  }

  let edge = 'top'
  if (offset.bottom > offset[edge])
    edge = 'bottom'
  if (offset.left > offset[edge])
    edge = 'left'
  if (offset.right > offset[edge])
    edge = 'right'

  let y = (bounds.top + bounds.bottom) / 2
  let x = (bounds.left + bounds.right) / 2

  switch (edge) {
    case 'top':
      y = bounds.top
      break
    case 'left':
      x = bounds.left
      break
    case 'right':
      x = bounds.right
      break
    case 'bottom':
      y = bounds.bottom
      break
  }
  return [ edge, y, x ]
}
