// @noflow
import React, { PureComponent } from 'react'
import self from 'autobind-decorator'

import Choice from './Choice'
import { Caption } from 'components/text'
import { isFocusedElementWithin } from 'utils/dom'
import { __ } from 'utils/gettext'


type ChoicesEditorProps = {
  value: string,
  onUpdate: Function,
  onChange: Function,
}


export default class ChoicesEditor extends PureComponent<ChoicesEditorProps> {
  state = {
    focus: null,
    choices: [],
  }

  componentDidMount () {
    if (this.props.value !== this.state.value)
      this.setState({ choices: this.props.value })
  }

  UNSAFE_componentWillReceiveProps (props) {
    if ('value' in props && props.value !== this.state.value)
      this.setState({ choices: props.value })
  }

  updateChoices (choices) {
    return new Promise(resolve => {

      call(this.props.onUpdate, choices)
      this.setState({ choices }, () => {

        call(this.props.onChange, choices)
        resolve()
      })
    })
  }

  @self
  append (value = { value: '', score: null }) {
    if (typeof value.value !== 'string')
      value.value = ''
    return this.updateChoices([ ...this.state.choices, value ])
  }

  update (index, value) {
    let choices = [ ...this.state.choices ]
    choices.splice(index, 1, value)
    return this.updateChoices(choices)
  }

  remove (value) {
    let index = this.state.choices.indexOf(value)
    return this.removeAtIndex(index)
  }

  removeAtIndex (index) {
    let choices = [ ...this.state.choices ]
    choices.splice(index, 1)
    return this.updateChoices(choices)
  }

  removeLast () {
    return this.removeAtIndex(this.state.choices.length - 1)
  }

  // eslint-disable-next-line complexity
  handleTab (index) {
    let choices = this.state.choices
    let value   = choices[index] || null
    if (index === choices.length - 1){
      if (typeof value === 'object')
        return !value.value.trim().length
          ? this.removeLast().then(this.focusLast)
          : this.append().then(this.focusLast)
      if (typeof value === 'string')
        return value.trim().length
          ? this.removeLast().then(this.focusLast)
          : this.append().then(this.focusLast)
    }
    return null
  }

  @self
  focus (index) {
    this.setState({ focus: index })
  }

  @self
  focusLast () {
    this.focus(this.state.choices.length - 1)
  }

  render () {
    const setReference = ref =>
      ref && (this.containerElement = ref)

    return <div
      ref={ setReference }
      className='field-options' >


      <div className='table'>
        {  this.state.choices.length > 0 &&
            <div className='table-row'>
              <div className='table-data-80'>
                <Caption>Choice</Caption>
              </div>
              <div className='table-data-15'>
                <Caption>Value</Caption>
              </div>
              <div className='table-data-5'>
              </div>
            </div> }

        { this.state.choices.map((choice, key) => {

          const focused =
            this.state.focus === key &&
            isFocusedElementWithin(this.containerElement)

          const onTab = (event) => {
            if (this.handleTab(key) !== null) {
              event.preventDefault()
              return false
            }
          }

          const focus = () =>
            requestAnimationFrame(() => this.focus(key))

          const onRemove = () => this
            .removeAtIndex(key)
            .then(focus)

          const onChange = value => this
            .update(key, value)
            .then(focus)

          return <Choice key={ key }
            name={ key }
            value={ choice.value ? choice.value : choice }
            score= { is.number(choice.score) ? choice.score : null }
            focused={ focused }
            onTab={ onTab }
            onRemove={ onRemove }
            onChange={ onChange }
            onFocus={ this.focus }
          />
        })}
      </div>

      <nav className='toolbar'>
        <button
          className='small text'
          onClick={ () => this.append().then(this.focusLast) }>
          { __('Add choice') }
        </button>
      </nav>

    </div>
  }
}


const call = (fn: Function, ...args: Array<*>) =>
  typeof fn === 'function' && fn(...args)
