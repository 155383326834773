import { useState, useEffect } from 'react'

const isMobile = (window, isTablet = false) => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', null)
    }
  }, [])

  return width <= (isTablet ? 1000 : 768)
}

export default isMobile
