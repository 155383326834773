import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import ForbiddenPage from 'views/Error/ForbiddenPage'
import { Authorized } from 'components/AuthorizedComponent'


export default class AuthorizedRoute extends Component {

  render () {
    const props = this.props
    return <Authorized
      authorizedComponent={ () => <Route { ...props } /> }
      forbiddenComponent={ () => <ForbiddenPage /> }
      {...props} />
  }

}
