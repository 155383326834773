/**
 * @module Button
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent, createRef } from 'react'
import is from '@sindresorhus/is'
import self from 'autobind-decorator'
import navigator from 'routes/base'
import tooltips from 'tooltips'
import classNames from 'classnames'
import { Label } from 'components/text'
import Icon from 'components/icons'
import { resolveClass } from 'utils/resolve'
import __ from 'utils/gettext'

import type { ComponentType, Node } from 'react'


type Props = StatePropsType & {
  icon?: ComponentType<*>,
  children?: string,
  tooltip?: string,
  onClick?: SyntheticMouseEvent<*> => *,
  onEnter?: SyntheticMouseEvent<*> => *,
  onLeave?: SyntheticMouseEvent<*> => *,
  className?: string,
  notranslate?: boolean,
  iconColor?: string,
  success?: boolean,
  submit?: boolean,
  style?: Object,
  url?: string,
  state?: Object,
  id?: string | number,
  withborders?: boolean,
  ariaLabel?: string,
}


export default class Button extends PureComponent<Props> {

  element: Function = createRef()
  tooltip: string | null = this.props.tooltip ? __(this.props.tooltip) : null

  componentDidMount () {
    if (this.tooltip)
      tooltips.add(this.element.current, this.tooltip)
  }

  componentWillUnmount () {
    if (this.tooltip)
      tooltips.remove(this.element.current, this.tooltip)
  }

  get icon (): Node {
    if (!this.props.icon)
      return null
    const Icon = this.props.icon
    return <span className='icon'>
      <Icon />
    </span>
  }

  get content (): Node {
    if (!this.props.children)
      return null
    return <span className='content'>
      <Label notranslate={ this.props.notranslate }>
        { this.props.children }
      </Label>
    </span>
  }

  get className (): string {
    return classNames(
      resolveClass(this.props),
      this.props.iconColor,
      !this.props.children && 'icon-only',
      this.props.withborders && 'with-borders')
  }

  get disabled (): boolean {
    return this.props.disabled === true
  }

  get type (): 'button' | 'submit' | 'reset' {
    if (typeof this.props.onClick === 'function')
      return 'button'
    if (this.props.submit === true)
      return 'submit'
    return 'button'
  }

  get ariaLabel (): string | null {
    if (this.props.ariaLabel)
      return this.props.ariaLabel
    else
      return null
  }

  @self
  onClick (event: SyntheticMouseEvent<*>): Function | void {
    if (this.disabled)
      return
    if (typeof this.props.onClick === 'function') {
      event.preventDefault()
      return this.props.onClick(event)
    }
    if (this.props.url){
      event.preventDefault()
      return navigator.navigate(this.props.url)
    }
  }

  @self
  onEnter (event: SyntheticMouseEvent<*>): Function | void {
    if (this.disabled)
      return
    if (typeof this.props.onEnter === 'function')
      return this.props.onEnter(event)
  }

  @self
  onLeave (event: SyntheticMouseEvent<*>): Function | void {
    if (this.disabled)
      return
    if (typeof this.props.onLeave === 'function')
      return this.props.onLeave(event)
  }

  render (): React$Element<*> {
    return <button
      aria-label={ this.ariaLabel }
      role='button'
      ref={ this.element }
      type={ this.type }
      disabled={ this.disabled }
      className={ this.className }
      onClick={ this.onClick }
      onMouseOut={ this.onLeave }
      onMouseOver={ this.onEnter }
      style={ this.props.style }
      id={ this.props.id }>
      { this.icon }
      { this.content }
    </button>
  }
}

export const PrimaryButton = (
  props: {|
    children?: *,
    url?: string,
    onClick?: Function,
    className?: string,
    small?: boolean,
    icon?: *,
    ariaLabel?: string,
    style?: Object,
    disabled?: boolean,
    id?: string,
    withborders?: boolean |}
): React$Element<*> => <Button regular primary filled {...props} submit />

export const SecondaryButton = (
  props: {| children?: *, id?: string, style?: Object, notranslate?: boolean, className?: string, disabled?: boolean, onClick?: Function, small?: boolean, icon?: ComponentType<*> |}
): React$Element<*> => <Button regular primary {...props} />

export const RegularButton = (
  props: {| children?: *, icon?: *, onClick?: Function, className?: string, ariaLabel?: string, |}
): React$Element<*> => <Button regular {...props} />

export const BackButton = (
  props: {| children?: *, onClick?: Function |}
): React$Element<*> => <Button regular {...props} />

export const RemoveButton = (
  props: {| children?: *, id?: string, disabled?: boolean, onClick?: Function, small?: boolean, tooltip?: string, style?: Object |}
): React$Element<*> => <Button regular error {...props} />

export const SearchButton = (props: {| onClick?: Function |}): React$Element<*> =>
  <Button
    regular {...props}
    icon={ Icon.Search } />

export const EditButton = (
  props: {| children?: string, onClick?: Function |}
): React$Element<*> => <Button regular {...props} />

export const CancelButton = (
  props: {| children?: string, onClick?: Function, className?: string, small?: boolean, url?: string |}
): React$Element<*> => <Button regular filled {...props} />

export const NavigateButton = (
  props: {| children?: string, onClick?: Function, url?: string |}
): React$Element<*> => <Button regular filled {...props} />

export const BackToListButton = (props: {| children?: string, state?: Object, url?: string |}): React$Element<*> => {
  if (!is.string(props.url))
    throw new TypeError(`The "url" prop of BackToListButton must be of type "string". Got "${typeof props.url}".`)
  const search = props.state ? props.state.listSearchParams : '?'
  return <Button regular {...props} onClick={ () => navigator.backToList(props.url, search) }/>
}

export const MergedButtonGroup = (props: { children: * }): React$Element<*> => {
  if (!props.children)
    throw new TypeError('The ButtonGroup component must have children components')
  return <div className='merged-button-group'>
    { props.children }
  </div>
}
