// @flow
import { useState, useEffect } from 'react'

const useForm = (
  fields: Object[],
  defaultValues: Object,
  dependencies: Array<*>
): Array<*> => {
  const [formState, setFormState] = useState<Object>({})

  useEffect(() => {
    let state = formBaseState(fields)
    state = mergeDefaultValuesToState(state, defaultValues)
    setFormState(state)
  }, [...dependencies])
  return [formState, setFormState]
}

const formBaseState = (fields: Array<Object>) => {
  if (!fields) return {}
  let state = {}
  fields.forEach((field) => {
    state[field.name] = ''
  })
  return state
}

const mergeDefaultValuesToState = (
  state: Object,
  defaultValues: Object
): Object => {
  const keys = Object.keys(defaultValues)
  keys.forEach((key) => {
    if (state.hasOwnProperty(key)) {
      state = { ...state, [key]: defaultValues[key] }
    }
  })
  return state
}

export default useForm
