/**
 * @flow
 * @memberof  libs.former-ui
 * @namespace libs.former-ui.components
 *
 * @class     FieldPreview
 * @summary   Form composer's element block at its non-active state.
 *
 * The component for displaying the preview of how the form element
 * will look like when the form is presented for populating.
 */


import React, { PureComponent, Fragment } from 'react'
import { Required } from 'react-drf/fields'
import { displayName } from 'utils/formatters'
import { DuplicateIcon, RemoveIcon, DragIcon, EditIcon } from 'components/icons'
import { Subtle } from 'components/text'

import getComponentType from '../fields'
import { ButtonWithTooltip } from './WithTooltip'

import type { BlockType } from '../editor/Block'
import type { Element } from 'react'


type FieldProps = {
  block: BlockType,
  onDuplicate: Function,
  onSelect: Function,
  onRemove: Function,
  onMove: Function,
  disabled?: boolean,
}


const elementIsField = (block) =>
  block.model === 'Field'

const elementIsFieldOrIndicator = (block) =>
  [ 'Field', 'Indicator' ].includes(block.model)


export default class FieldPreview extends PureComponent<FieldProps> {

  element: HTMLElement

  get field (): Element<*> | null {
    const element    = this.props.block
    const FieldClass = getComponentType(element)

    if (!FieldClass)
      throw new ReferenceError(
        `Invalid form element: \n(` +
        `type = ${displayName(this.props.block.type)}, \n` +
        `model = ${displayName(this.props.block.model)})`
      )

    const props = {
      name: element.text,
      choices: element.choices,
      disabled: true,
      ...{ options: element.options },
    }
    if (!elementIsField(element))
      Object.assign(props, {
        children: element.text,
        element: element,
      })

    return <FieldClass { ...props } />
  }

  render () {

    const updateFieldElementReference = ref =>
      ref && (this.element = ref)

    const text = this.props.block.text
    const indicator = this.props.block.indicator_identifier
    return <Fragment>

      { this.props.disabled
        ? null
        : <nav className='text toolbar actions'>
          <ButtonWithTooltip
            text='Vaihda paikkaa'
            attributes={{ action: 'drag' }}
            onMouseDown={ this.props.onMove }
            icon={ DragIcon } />

          <ButtonWithTooltip
            text='Muokkaa'
            attributes={{ action: 'edit' }}
            onClick={ this.props.onSelect }
            icon={ EditIcon } />

          <ButtonWithTooltip
            text='Kopioi'
            attributes={{ action: 'duplicate' }}
            onClick={ this.props.onDuplicate }
            icon={ DuplicateIcon } />

          <ButtonWithTooltip
            text='Poista'
            className='error'
            attributes={{ action: 'remove' }}
            onClick={ this.props.onRemove }
            icon={ RemoveIcon } />
        </nav> }

      <header className='field-preview-header'>

        {/* FIXME */}
        { elementIsFieldOrIndicator(this.props.block) && <Fragment>
          <h5 className='title'>
            { text }
            { this.props.block.required && <Required /> }
          </h5>

          <div className='description'>
            { this.props.block.instruction_text }
          </div>

        </Fragment> }

      </header>

      <main className='field' ref={ updateFieldElementReference }>
        { this.field }
      </main>

      { indicator && <sub><Subtle>Formula identifier: <span>{  indicator }</span></Subtle></sub> }

    </Fragment>
  }
}
