// @flow

// flow-ignore
import '../styles/index.less'

import * as Sentry from '@sentry/react'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { addAlert } from 'actions/core'
import { Clickable, Paragraph } from 'components/text'
import { initI18n } from 'utils/gettext'
import registerServiceWorker from 'utils/registerServiceWorker'
import context from './context'
import store from './store'
import { CaptureConsole } from '@sentry/integrations'

const refreshPage = () => {
  window.location.reload()
}

const updateAvailableMessage = (
  <Paragraph>
    A new version of Nordhealth Connect is available.{' '}
    <Clickable tabIndex={0} onKeyPress={refreshPage} onClick={refreshPage}>
      Please refresh the page.
    </Clickable>
  </Paragraph>
)

const addUpdateAlert = () =>
  store.dispatch(addAlert('info', updateAvailableMessage, 10))

const getApplication = () => require('./App')

const host = document.getElementById('root')

function mount(element, ComponentClass) {
  if (process.env.SENTRY_ENV)
    Sentry.init({
      dsn: 'https://0fbce05b3afc614adab2a73f6aa34831@o1395833.ingest.sentry.io/4506535384514560',
      integrations: [
        new CaptureConsole({
          levels: ['error']
        })
      ],
      environment: process.env.SENTRY_ENV,
      release: `navisec-health-${process.env.NH_VERSION}`,
    })

  element = document.getElementById('root')

  if (element === null)
    throw new TypeError(
      'The element on which the Nordhealth Connect app is tried to mount upon does not exist.'
    )

  // register a service worker
  registerServiceWorker(addUpdateAlert)

  context.observe(store)

  return render(
    <Provider store={store}>
      <ComponentClass />
    </Provider>,
    element
  )
}

initI18n()
mount(host, getApplication().default)

// flow-ignore
if (module.hot) module.hot.accept()
