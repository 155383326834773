// @flow
import Symbol from 'es6-symbol'

import FieldDescription from './FieldDescription'
import type { FormProps } from '.'
import type Field, { FieldProperties } from './fields/Field'


export default function applyFieldsInterface (
  self: { props: FormProps },
  getFields: Function
): typeof self & {
  fieldInstances: Array<Field<FieldProperties>>,
  fieldComponents: Array<React$Element<*>>,
  fieldData: Array<*>,
} {
  const getCachedFields = (force = false) => {
    if (!force && self[fld])
      return self[fld]

    const { initial, errors, onChange, labelElement, fullWidth, disabled, helpIcons, username } = self.props

    self[fld] = createFields(getFields(), { initial, errors, onChange, labelElement, fullWidth, disabled, helpIcons, username })

    return self[fld]
  }

  const getFieldData = () => {
    const reducer = (data, field) => field
      ? Object.assign(data, field.toJSON())
      : data

    return getFieldInstances().reduce(reducer, {})
  }

  const getFieldComponents = () => getCachedFields(true)
    .map(field => field.component)

  const getFieldInstances = () => getCachedFields()
    .map(field => field.reference)
    .filter(field => field !== null)

  return Object.defineProperties(self, {
    fieldComponents: { get: () => getFieldComponents() },
    fieldInstances: { get: () => getFieldInstances() },
    fieldData: { get: () => getFieldData() },
  })
}

function createFields (data, details = {}) {

  /**
   * Get the property `cat` from the details object; and
   * if the details[cat] is a Map, return its value for
   * the key `key`. If no property `cat` exists in the
   * details, returns undefined
   *
   * @method extractDetail
   * @return The value for the found property; or undefined
   */

  const extractDetail = (cat: string, key: string) => {
    if (!details || !details[cat]) return
    let property = details[cat]
    if (property instanceof Map) return extractMapProperty(property, key)
    if (typeof property === 'object') return extractObjectProperty(property, key)
    return property
  }

  const extractMapProperty = (map, key) => (map.has(key) ? map.get(key) : undefined)

  const extractObjectProperty = (obj, key) => {
    if (key in obj) return obj[key]
    else if (key === 'onChange') return obj
    return undefined
  }

  const createFieldComponent = (key, index) => {
    let field = new FieldDescription(data[key])
    field.update({
      key: index,
      name: key,
      errors: extractDetail('errors', key),
      widget: extractDetail('widgets', key),
      initial: extractDetail('initial', key),
      onChange: extractDetail('onChange', key),
      disabled: extractDetail('disabled', key),
      fullWidth: extractDetail('fullWidth', key),
      helpIcons: extractDetail('helpIcons', key),
      labelElement: extractDetail('labelElement', key),
      username: extractDetail('username', key)
    })

    return field.toDescriptor()
  }
  return Object.keys(data).map(createFieldComponent)
}

const fld = Symbol('field-components')
