/* eslint-disable class-methods-use-this */
import { url as base_url } from './constants'

/**
 * @namespace
 */

const store = {
  options: new Map()
}


/**
 * @class
 * @name APIResponseCache
 */

class APIResponseCache {

  constructor () {
    this.load()
  }

  getCachedResponse (url, method) {
    assertMethodAllowed(method)
    const src = store[method]
    if (src.has(url)) {
      const value = src.get(url)
      return value
    }
    return null
  }

  storeResponse (response) {

    const url    = response.config.url.replace(base_url, '')
    const method = response.config.method
    assertMethodAllowed(method)
    assertRequestSuccessful(response)

    const dst = store[method]
    dst.set(url, response)
    this.save()
  }

  removeCachedResponse (url: string, method: string) {
    store[method].delete(url)
  }

  removeCachedResponses (startsWith: string, method: string) {
    for (let key of store[method].keys()) {
      if (key.startsWith(startsWith))
        store[method].delete(key)
    }
  }

  clear () {
    Object
      .values(store)
      .forEach(map => map.clear())
    this.save()
  }

  save () {
    assertSessionStorageAvailable()
    const raw = serializeStore()
    for (let [ method, data ] of Object.entries(raw))
      sessionStorage.setItem(`api-${method}`, JSON.stringify(data))
  }

  load () {
    assertSessionStorageAvailable()
    const raw = {}
    for (let method of Object.keys(store))
      raw[method] = JSON.parse(sessionStorage.getItem(`api-${method}`) || '{}')
    deserializeStore(raw)
  }

}

export default new APIResponseCache()


function serializeStore () {
  const result = {}
  for (let [ method, data ] of Object.entries(store)) {
    result[method] = {}
    for (let [ key, value ] of data.entries())
      result[method][key] = value
  }
  return result
}


function deserializeStore (raw) {
  for (let [ method, data ] of Object.entries(raw)) {
    for (let [ url, entry ] of Object.entries(data))
      store[method].set(url, entry)
  }
}


function assertMethodAllowed (method) {
  if (__DEV__ && !(method in store))
    throw new TypeError(`Invalid cache retrieval request (method = ${method})`)
}

function assertRequestSuccessful (response) {
  if (!response.valid)
    throw new ReferenceError(`Trying to cache response data for a failed request.`)
}

function assertSessionStorageAvailable () {
  if (__DEV__ && !window.sessionStorage)
    throw new Error(`Session storage not available`)
}
