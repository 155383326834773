/**
 * @flow
 * @class MultipleChoiceField
 */

import self from 'autobind-decorator'
import React from 'react'
import BaseInputComponent  from './base'
import { Checkbox } from 'react-toggle-components'

import ChoicePreview from '../components/ChoicePreview'
import { List } from 'immutable'


type OptionValueType = string | number | null
type OptionType = {
  value: OptionValueType,
  label: string,
  score?: number,
}

type ChoicesType = Array<OptionType>

type ValueType = Set<OptionType | OptionValueType>

type MultipleChoiceFieldProps = {
  name: string,
  value?: ValueType,
  choices?: Array<*>,
  disabled?: boolean,
  onChange?: Function,
}

type MultipleChoiceFieldState = {
  value: ValueType,
  choices: ChoicesType,
  disabled: boolean,
}


export default class MultipleChoiceField extends BaseInputComponent<ValueType, OptionsType> {

  state: MultipleChoiceFieldState = {
    value: new Set(),
    choices: [],
    disabled: false,
  }

  static defaultValue: ValueType | * = new Set()

  updateState (props: MultipleChoiceFieldProps) {
    let updates = {}

    if (props.value)
      updates.value = toSet(props.value)
    if ('choices' in props)
      updates.choices = clean(props.choices)
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false

    this.setState(updates)
  }

  @self
  update (choice: OptionValueType, checked: boolean) {
    let value = new Set(this.state.value)
    if (!checked)
      value.delete(choice)
    else
      value.add(choice)
    this.setState({ value }, () =>
      this.props.onChange && this.props.onChange(value))
  }

  render () {
    return <dl className='options multiple'>
      { this.state.choices.map((choice) => {
        let checked = this.state.value.has(choice.value)
        return <label key={ choice.value } className='option'>
          <dd>
            <Checkbox
              checked={ checked }
              disabled={ this.state.disabled ? 'disabled' : false }
              onChange={ () => this.update(choice.value, !checked) }
            />
          </dd>
          <dt>
            <ChoicePreview choice={ choice }></ChoicePreview>
          </dt>
        </label>
      })}
    </dl>
  }
}

export function clean (list: Set<OptionType> | List<OptionType> | [] | ChoicesType): ChoicesType {
  if (typeof list.toArray === 'function')
    list = list.toArray()

  if (list instanceof Set)
    list = [ ...list ]

  if (!(list instanceof Array))
    return []

  if (typeof list.map !== 'function')
    throw new TypeError(`Invalid choices`)

  return list
    .map(mapListToChoice)
}

const toSet = (data) => (data.size || data.length)
  ? new Set(data)
  : new Set()

const mapListToChoice = (choice: OptionType | string, n: number): OptionType => {

  let value, label, score
  if (typeof choice === 'object' && choice.label) {
    value = choice.value
    label = choice.label
    score = choice.score
  } else if (typeof choice === 'object'){
    label = choice.value
    score = choice.score
  }
  else if (typeof choice === 'string')
    label = choice

  if ([ 'number', 'string' ].indexOf(typeof value) === -1)
    value = n

  return { value, label, score }
}
