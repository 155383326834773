import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const ComposeAnnouncement = load(() =>
  import('views/Public/Announcements/ComposeAnnouncement'))

const AnnouncementsView = load(() =>
  import('views/Public/Announcements/ListAnnouncements'))


export const descriptors = {
  announcements:                `/announcements/`,
  createAnnouncement:           `/announcements/create/`,
  announcement: (pk: ID) =>     `/announcements/${pk}`,
}


export default <Switch>
  <Route path={ descriptors.createAnnouncement } component={ ComposeAnnouncement } />
  <Route path={ descriptors.announcement(':id(\\d+)') } component={ ComposeAnnouncement } />
  <Route exact path={ descriptors.announcements } component={ AnnouncementsView } />
  <RouteNotFound />
</Switch>
