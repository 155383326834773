/**
 * @module PasswordField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import React, { PureComponent } from 'react'
import self from 'autobind-decorator'

import Icon from 'components/icons'


type ValueType = string

type PasswordFieldProps = {
  value: ValueType,
  onChange: Function,
  disabled?: boolean,
  id?: string,
}

type PasswordFieldState = {
  hidden: boolean,
}


export default class StringField extends PureComponent<PasswordFieldProps, PasswordFieldState> {

  state = {
    hidden: true,
  }

  static defaultProps = {
    value: '',
  }

  @self
  toggleHidden () {
    this.setState({ hidden: !this.state.hidden })
  }

  render () {
    const onChange = event =>
      this.props.onChange && this.props.onChange(event.target.value)

    return <div className='password-field'>
      <input
        id={ this.props.id }
        type={ this.state.hidden ? 'password' : 'text' }
        value={ this.props.value }
        onChange={ onChange }
        disabled={ this.props.disabled }
      />
      <div className={`visibility-icon${!this.state.hidden ? ' visible' : ''}`} onClick={ this.toggleHidden }>
        { this.state.hidden ? <Icon.VisiibilityOff /> : <Icon.Visibility /> }
      </div>
    </div>
  }
}
