// @flow
import type { Action } from '../reducers'

export const UPDATE_SEARCH = 'UPDATE_TAGS_SEARCH'


export const updateSearch = (value: string): Action => ({
  type: UPDATE_SEARCH,
  payload: value ? value : null
})
