/**
 * @flow
 * @class EditorView
 */

import React, { PureComponent } from 'react'
import { Required } from 'react-drf/fields'
import getComponentType from '../fields'
import { toRepresentation } from 'utils/formatters'

import type { BlockType } from '../editor/Block'


type FieldProps = {
  block: BlockType,
  value?: any,
  disabled?: boolean,
  onChange: Function,
}


export default class FieldDisplay extends PureComponent<FieldProps> {

  element: HTMLElement

  render () {
    const FieldClass = getComponentType(this.props.block)

    const assignReference = ref =>
      ref && (this.element = ref)

    if (!FieldClass)
      throw new ReferenceError(`Invalid field (\n`+
        `\ttype = ${toRepresentation(this.props.block.type)}, \n`+
        `\tmodel = ${toRepresentation(this.props.block.model)}\n)`
      )

    const text    = this.props.block.text
    const options = this.props.block.options

    // TODO: Own renderers for different element types?
    switch (this.props.block.model) {

      case 'Field':
        return <label
          className={ this.className }
          ref={ assignReference }>

          <header>
            <h5 className='title'>
              <span className='text'>{ text }</span>
              { this.props.block.required && <Required /> }
            </h5>
            <div className='description'>
              { this.props.block.instruction_text }
            </div>
          </header>

          <FieldClass
            name={ text }
            value={ this.props.value }
            disabled={ this.props.disabled }
            choices={ this.props.block.choices }
            options={ options }
            onChange={ this.props.onChange }
          />

        </label>

      default:
        return <article
          className={ this.className }
          ref={ assignReference }>

          <FieldClass options={ options }>
            { text }
          </FieldClass>

        </article>
    }

  }

  get className (): string {
    let model = this.props.block.model ? this.props.block.model.toLowerCase() : 'none'
    let suffix = ''

    if (model === 'field')
      suffix = ' full-width'
    return 'field model-' + model + suffix
  }

}
