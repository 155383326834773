// @flow
import React from 'react'

import { __ } from 'utils/gettext'
import { useSelector } from 'react-redux'

import { DEFAULT_PRIMARY_COLOR } from 'constants'

import type { Node } from 'react'

export type OptionObject = {
  key: any,
  name: string,
}

type OptionProps = {
  id: any,
  name: string,
  selectedOption: any,
  setSelectedOption: Function,
  translate: boolean,
}

type Props = {
  options: OptionObject[],
  selectedOption: any, // key
  setSelectedOption: Function,
  translateOptions?: boolean,
}

const Switch = (props: Props): Node => {
  const {
    options,
    selectedOption,
    setSelectedOption,
    translateOptions = false,
  } = props

  return (
    <div className='switch-container'>
      {options.map((option) => (
        <Option
          key={option.key}
          id={option.key}
          name={option.name}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          translate={translateOptions}
        />
      ))}
    </div>
  )
}

const Option = (props: OptionProps): Node => {
  const handleSelect = () => {
    props.setSelectedOption(props.id)
  }

  const tenantSettings = useSelector((state) => state.tenantSettings)

  const isSelected = props.id === props.selectedOption
  const name = props.translate ? __(props.name) : props.name

  const backgroundColor = Boolean(tenantSettings.color)
    ? tenantSettings.color
    : DEFAULT_PRIMARY_COLOR

  return (
    <div className='switch-option' onClick={handleSelect}>
      <span
        className={`switch-option-text ${isSelected ? 'selected' : ''}`}
        style={{
          background: isSelected ? backgroundColor : '',
        }}
      >
        {name}
      </span>
    </div>
  )
}

export default Switch
