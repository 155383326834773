/**
 * @module MultipleChoiceModalField
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent } from 'react'
import { Overlay } from 'components/generic'
import { RegularButton } from 'components/buttons'
import MultipleChoiceField, { type ChoiceFieldProps, type ChoiceType } from './MultipleChoiceField'
import { Link } from 'components/forms/Links'


type Props = ChoiceFieldProps & {
  label?: string,
  asLinks?: boolean,
  urlConstructor?: Function,
}


export default class MultipleChoiceModalField extends PureComponent<Props, { open: boolean }> {

  static defaultProps = {
    value:   [],
    choices: [],
  }

  state = {
    open: false,
  }

  field: MultipleChoiceField | null = null

  open = () =>
    this.setState({ open: true })

  close = () =>
    this.setState({ open: false })

  toggle = () =>
    this.setState({ open: !this.state.open })

  render () {

    const assignFieldReference = (ref) => {
      if (!ref)
        return
      const shouldUpdate = this.field === null
      this.field = ref
      if (shouldUpdate)
        this.forceUpdate()
    }

    return <div className='collapsed accordion'>

      <div className='text small'>
        { this.displayValue }
      </div>

      <RegularButton onClick={ this.toggle } notranslate>
        { this.props.label }
      </RegularButton>

      <Overlay
        modal
        onClose={ this.close }
        visible={ this.state.open }>
        <MultipleChoiceField
          ref={ assignFieldReference }
          { ...this.props }
        />
      </Overlay>
    </div>
  }

  get displayValue (): string | Array<*> {
    return this.props.asLinks
      ? this.selectedChoices
        .map( choice => <Link
          description={choice.display_name}
          url={this.props.urlConstructor(choice.value)} />
        )
      : this.selectedChoices
        .map(choice => choice.display_name)
        .join(', ')
  }

  get selectedChoices (): Array<ChoiceType> {
    if (!this.field)
      return []
    return this.field.selectedChoices
  }
}
