

// The value for the field's max_length attribute after which
// the field will be rendered as a RichTextField instead
// of TextField or StringField
export const RICH_TEXT_LENGTH_BOUNDARY = 512


export const isTextual = (field) =>
  [ 'string', 'text' ].indexOf(field.type) > -1


export const isComplexType = type =>
  [ 'function', 'object' ].indexOf(typeof type) > -1
