/**
 * @flow
 * @class TextInputField
 */

import React from 'react'
import BaseInputComponent, { getDisabledAttributeValue } from './base'
import MultilineTextInputField from '../components/DynamicHeightInput'


export type ValueType = string | null

export type OptionsType = {
  multiline: boolean,
}


export default class TextInputField extends BaseInputComponent<ValueType, OptionsType> {

  static defaultValue = ''

  render () {
    if (this.getOptionValue('multiline'))
      return this.renderMultilineInput()
    return this.renderSingleLineInput()
  }

  renderMultilineInput () {
    return <MultilineTextInputField
      value={ this.state.value }
      disabled={ getDisabledAttributeValue(this.state) }
      onChange={ this.update }
      className='full-width'
    />
  }

  renderSingleLineInput () {
    const handleChange = (event: SyntheticKeyboardEvent<*>) =>
      this.update(event.target.value)

    return <input
      type='text'
      value={ this.state.value }
      disabled={ getDisabledAttributeValue(this.state) }
      onChange={ handleChange }
      className='full-width'
    />
  }
}
