/**
 * @flow
 * @class ListView
 */

import React, { PureComponent, Component, createRef } from 'react'
import self from 'autobind-decorator'
import classNames from 'classnames'
import { CompositeDisposable } from 'event-kit'
import { observeWindowSizeChanges } from 'utils/dom'

import type { Node } from 'react'


export type ColumnPropertiesType = {
  className?: string,
  breakpoint?: string,
}
export type ColumnType = [ number, Node, ColumnPropertiesType ]
export type ColumnsType = Array<ColumnType>
export type RowProps = {
  id: string | number,
  url: string,
  onClick: Function,
  columns: ColumnsType,
}
type ListRowProps = {|
  refreshList: Function,
  mapItemToRow: Function,
  onClick: Function,
  fetchData?: Function,
  mapUpdatedItemsToRow?: Function,
  breakpoint?: number,
  item: *,
|}
type RowState = {
  loaded: boolean,
  data?: *,
}


export default class ListItem extends PureComponent<ListRowProps, RowState> {

  _isMounted: boolean

  constructor(props: ListRowProps) {
    super(props)
    this.state = {
      loaded: false
    }
    this._isMounted = false
  }

  static TOTAL_COLUMN_COUNT: number = 12

  elementRef: { current: HTMLElement } = createRef()

  get element (): HTMLElement {
    return this.elementRef.current
  }

  componentDidMount () {
    this._isMounted = true
    this.getData()
  }

  async getData () {
    if (this.props.fetchData && this._isMounted) {
      const response = await this.props.fetchData(this.props.item, this)
      this.setState({
        loaded: true,
        data: response.data
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render (): React$Element<*> {

    const { url, ariaLabel, columns } = this.state.loaded && this.props.mapUpdatedItemsToRow
      ? this.props.mapUpdatedItemsToRow(this.props.item, this.state.data)
      : this.props.mapItemToRow(this.props.item, this)

    const onClick = () =>
      this.props.onClick(url)

    const className = classNames({
      'row':        true,
      'no-hover':   url === null,
      'list-item':  true,
      'focusable-with-margin':  true })

    const renderColumn = (column, key) =>
      <ListItemColumn
        key={ key }
        width={ column[0] }
        content={ column[1] }
        data={ column[2] }
      />

    // flow-ignore
    return <article
      role='link'
      ref={ this.elementRef }
      aria-label={ ariaLabel }
      className={ className }
      onClick={ onClick }
      onKeyPress={ onClick }
      tabIndex={ 0 }>
      { columns.map(renderColumn) }
    </article>
  }

}

const COLUMN_CLASS_NAME = [
  'hidden',
  'one column',
  'two columns',
  'three columns',
  'four columns',
  'five columns',
  'six columns',
  'seven columns',
  'eight columns',
  'nine columns',
  'ten columns',
  'eleven columns',
  'twelve columns',
]


const getColumnClassName = (width: number, data: { className?: string } = {}) => {
  const index          = Math.min(ListItem.TOTAL_COLUMN_COUNT, Math.max(0, width))
  const widthClassName = COLUMN_CLASS_NAME[index]
  return classNames(widthClassName, data.className)
}


class ListItemColumn extends Component <{ width: number, content: Node, data: ColumnPropertiesType }, { visible: boolean }> {

  subscriptions: CompositeDisposable
  state = {
    visible: true
  }

  componentDidMount () {
    this.onWindowResize()
    this.subscriptions = new CompositeDisposable()
    this.subscriptions.add(
      observeWindowSizeChanges(this.onWindowResize))
  }

  componentWillUnmount () {
    this.subscriptions.dispose()
  }

  @self
  onWindowResize () {
    this.setState({
      visible:  this.props.data && this.props.data.breakpoint ? window.innerWidth > this.props.data.breakpoint : true
    })
  }

  render () {
    return !this.state.visible
      ? null
      : <div className={ getColumnClassName(this.props.width, this.props.data) }>
        { str(this.props.content) }
      </div>
  }
}


function str (node: *): Node {
  if (typeof node === 'undefined')
    throw new TypeError('Invalid node in ListView')
  if (typeof node === 'function')
    return node()
  if (node === null)
    return null
  if ([ 'symbol', 'function', 'string' ].indexOf(typeof node.type) > -1)
    return node
  if (typeof node.toString === 'function')
    return node.toString()
  return node
}
