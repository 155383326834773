/**
 * @flow
 * @class RequiredFieldIndicator
 */
import React, { PureComponent } from 'react'

type FieldErrorProps = {}


export default class FieldError extends PureComponent<FieldErrorProps> {

  static style = {
    alignSelf:  'left',
    display:    'inline-block',
    fontSize:   '0.7em',
    color:      '#c82954',
  }

  render () {
    return (
      <span
        style={this.constructor.style}>
        { this.props.children }
      </span>
    )
  }
}
