import api from 'api'
import { PUBLIC_TENANT, STORAGE_LANGUAGE } from 'constants'
import context from 'context'
import I18n from 'i18n-s'
import englishTranslations from 'lang/en.json'
import finnishTranslations from 'lang/fi.json'
import norwegianTranslations from 'lang/no.json'
import swedishTranslations from 'lang/sv.json'
import moment from 'moment'
import { resolveSubdomain } from 'utils/resolve'

const DEFAULT_LOCALE: string = 'fi'

const supportedLocales: Set<string> = new Set(['fi', 'sv', 'en', 'no'])
const i18n = new I18n()

function isSupported(lang: string): boolean {
  return supportedLocales.has(lang)
}

export default __

export function initI18n() {
  i18n.setLocaleData('fi', finnishTranslations)
  i18n.setLocaleData('sv', swedishTranslations)
  i18n.setLocaleData('en', englishTranslations)
  i18n.setLocaleData('no', norwegianTranslations)

  // set default language for the public tenant app.
  if (resolveSubdomain() === PUBLIC_TENANT) setLocale('fi')
  else {
    const lang = resolveFromNavigator()
    setLocale(lang)
    setLocaleHeader()
  }
}

export function __(message: string, params?: *): string {
  if (!isSupported(i18n._locale) || !message) return message

  if (!params) return i18n.__(message) || message

  return i18n.__(message, params) || message
}

export function getLocale(): string {
  return context.currentLocale
}

export function setLocale(
  locale?: string = localStorage.getItem(STORAGE_LANGUAGE)
): void {
  // Only set locale if it's defined
  if (!locale) return
  i18n.setLocale(locale)
  moment.locale(locale)
  setLocaleHeader(locale)
}

// eslint-disable-next-line complexity
function resolveFromNavigator() {
  if (localStorage.getItem(STORAGE_LANGUAGE))
    return localStorage.getItem(STORAGE_LANGUAGE)
  if (typeof window !== 'object' || !window.navigator) return DEFAULT_LOCALE

  let lang: string = DEFAULT_LOCALE
  if (navigator.language) lang = navigator.language.split('-')[0]
  else if (navigator.languages) lang = navigator.languages[0].split('-')[0]

  if (isSupported(lang)) return lang
  return DEFAULT_LOCALE
}

export function setLocaleHeader(locale?: string) {
  const lang: string =
    locale || localStorage.getItem(STORAGE_LANGUAGE) || DEFAULT_LOCALE
  api.setHeader('accept-language', lang)
}
