/**
 * @module SearchChoiceField
 * @author mikakattainen<mika.kattainen@fns.fi>
 * @flow
 */

import self from 'autobind-decorator'
import { Badge } from 'components/generic'
import React, { Component, PureComponent } from 'react'
import { styles } from 'react-drf/fields/SelectField'
import Select from 'react-select'
import { isEqual } from 'utils/resolve'

import { PrimaryButton } from 'components/buttons'
import { StringField } from 'components/fields'
import Icon from 'components/icons'

export type ChoiceType = {|
  value: number | string | null,
  label: string,
|}

type ValueType = number | string | null

type FieldPropsType = {
  value: ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  placeholder?: string,
  constructUrl?: Function,
  renderAsField?: boolean,
  style?: { [string]: string },
  stringValue?: boolean,
}

export default class SearchChoiceField extends PureComponent<FieldPropsType> {
  static defaultProps = {
    choices: [],
  }

  @self
  onRemoveSelection() {
    this.props.onChange(null)
  }

  get displayName(): string | number | null {
    const selection = this.props.choices.find(
      (choice) => choice.value === this.props.value
    )
    if (!this.props.value || !selection) return null
    return selection.label
  }

  render() {
    return (
      <div style={this.props.style}>
        {!this.props.value ? this.renderSearch() : this.renderSelection()}
      </div>
    )
  }

  renderSearch() {
    return (
      <div className='form-search left-side'>
        <PrimaryButton onClick={() => {}} icon={Icon.Search} />
        <SearchField
          choices={this.props.choices}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          stringValue={this.props.stringValue}
        />
      </div>
    )
  }

  @self
  renderSelection() {
    const onRemove = (event) => {
      event.stopPropagation()
      this.onRemoveSelection()
    }

    if (this.props.renderAsField)
      return (
        <StringField
          disabled
          value={this.displayName}
          style={{ marginBottom: 0 }}
        />
      )

    if (!this.props.constructUrl)
      return <Badge onRemove={onRemove}>{this.displayName}</Badge>

    return (
      <Badge onRemove={onRemove}>
        <a
          href={this.props.constructUrl(this.props.value)}
          target='_blank'
          rel='noopener noreferrer'
        >
          {this.displayName}
        </a>
      </Badge>
    )
  }
}

type ChoiceFieldProps = {
  value: ValueType,
  choices: Array<ChoiceType>,
  onChange: Function,
  placeholder?: string,
  stringValue?: boolean,
}

type ChoiceFieldState = {
  options: Array<ChoiceType>,
}

class SearchField extends Component<ChoiceFieldProps, ChoiceFieldState> {
  static defaultProps = {
    value: null,
    choices: [],
  }

  state = {
    options: [],
  }

  componentDidMount() {
    this.setState({ options: this.sortedChoices })
  }

  componentDidUpdate(oldProps: ChoiceFieldProps) {
    if (!isEqual(this.props.choices, oldProps.choices))
      this.setState({ options: this.sortedChoices })
  }

  get sortedChoices(): Array<ChoiceType> {
    return this.props.choices.sort((choiceA, choiceB) => {
      if (choiceA.label > choiceB.label) return 1
      if (choiceB.label > choiceA.label) return -1
      return 0
    })
  }

  render() {
    const onChange = (option) => {
      this.props.onChange(
        this.props.stringValue ? option.value : Number(option.value)
      )
    }

    return (
      <Select
        value={this.props.value}
        options={this.state.options}
        onChange={onChange}
        searchable={true}
        clearable={true}
        styles={styles}
        className='Select'
        classNamePrefix='Select'
        components={{ IndicatorSeparator: () => null }}
        placeholder={this.props.placeholder || ''}
      />
    )
  }
}
