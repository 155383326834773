// @flow

import self from 'autobind-decorator'
import { PureComponent, type Node } from 'react'


export opaque type BaseInputProps = {
  disabled?: 'disabled' | boolean,
}

export opaque type BaseInputState = {
  disabled: boolean,
}

type MatchKey = <V>(v: () => V) => V

declare type PropertyMappedMethod<Properties, Keys: $Keys<Properties>> = (
  key: $Keys<Properties>,
  fallbackValue: null
) => $ObjMap<Properties, MatchKey>


export interface BaseFieldInterface<V, O> {

  +defaultValue: V;

  +options: Map<O>;

  getOptionValue: PropertyMappedMethod<O>;
  update(value: V): void;
  render(): Node;
}


export default class BaseInputComponent<Value, Options = {},

    Props: BaseInputProps & {|
      value?: Value,
      onChange?: Value => void,
      options: Options,
    |} = {},

    State: BaseInputState & {|
      value: Value
    |} = {}
    >

  extends PureComponent<Props, State>
  implements BaseFieldInterface<Value, Options> {

  +props: Props
  +state: State

  static defaultValue = ''


  constructor (props: Props) {
    super(props)

    // TODO: Deprecate state; the same functionality already
    //       exists with the onChange/value props
    this.state = {
      value:    this.constructor.defaultValue,
      disabled: false,
    }
  }

  @self
  update (value: Value) {
    this.setState({ value }, () => {
      if (typeof this.props.onChange === 'function')
        this.props.onChange(value)
    })
  }

  get options () {
    if (typeof this.props.options === 'object' && this.props.options !== null)
      return new Map(Object.entries(this.props.options))
    return new Map()
  }

  @self
  getOptionValue (key: $Keys<Options>, fallbackValue = null): $Values<Options> {
    const options = this.options
    return options.has(key)
      ? options.get(key)
      : fallbackValue
  }

  componentDidMount () {
    this.updateState(this.props)
  }

  UNSAFE_componentWillReceiveProps (props: Props) {
    this.updateState(props)
  }

  updateState (props: Props) {
    let updates = {}

    if ('value' in props)
      if (props.value === null)
        updates.value = this.constructor.defaultValue
      else
        updates.value = props.value
    if ('disabled' in props)
      updates.disabled = props.disabled ? true : false
    this.setState(updates)
  }
}


export const getDisabledAttributeValue = (state: BaseInputState): 'disabled' | false =>
  state.disabled
    ? 'disabled'
    : false
