// @flow
import React, { useEffect } from 'react'
// $FlowIgnore
import { useDispatch, useSelector } from 'react-redux'
// $FlowIgnore
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import PageNotFound from 'views/Error/PageNotFound'
import BookingFrontPage from './views/BookingFrontPage'
import ConfirmationView from './views/ConfirmationView'
import SelectAvailableDatesView from './views/SelectAvailableDateView'
import SelectLocationView from './views/SelectLocationsView'
import SelectServiceView from './views/SelectServiceView'
import SelectTimeslotView from './views/SelectTimeslotView'
import TimeoutView from './views/TimeoutView'

import ErrorBoundary from './extra/ErrorBoundary'
import withOnlineBookingModal from './extra/OnlineBookingModal'

import { decreaseStep } from 'actions/onlineBooking'

import navigator from 'routes/base'

import PersonalDetailsView from './views/PersonalDetailsView'
import SelectEmployeeView from './views/SelectEmployeeView'
import SuccessfulBooking from './views/SuccessfulBooking'

import type { Node } from 'react'
import type { StateType } from 'store/initialState'

const BASE_API_URL = '/online-booking'

export const ENDPOINT_URLS = {
  INITIAL_DATA: `${BASE_API_URL}/initial-data/`,
  SERVICES: `${BASE_API_URL}/services/`,
  LOCATIONS: `${BASE_API_URL}/locations/`,
  AVAILABLE_DATES: `${BASE_API_URL}/available-dates/`,
  EMPLOYEES: `${BASE_API_URL}/employees/`,
  TIME_SLOTS: `${BASE_API_URL}/time-slots/`,
  CONFIRM: `${BASE_API_URL}/confirm-booking/`,
  CACHE_BOOKING: `${BASE_API_URL}/cache-booking/`,
}

export const BOOKING_STEPS_URLS = {
  FRONTPAGE: '/online-booking',
  SERVICES: '/online-booking/service',
  LOCATIONS: '/online-booking/location',
  AVAILABLE_DATES: '/online-booking/date',
  EMPLOYEES: '/online-booking/employee',
  TIME_SLOTS: '/online-booking/time-slot',
  CONFIRM: '/online-booking/booking-details',
  PERSONAL_DETAILS: '/online-booking/personal-details',
  SUCCESSFUL_BOOKING: '/online-booking/booking-confirmed',
  TIMEOUT: '/online-booking/timeout',
}

// Additional mapping for urls so we can internally navigate using the steps
// but not show the steps in the url
// $FlowIgnore
export const BOOKING_STEPS_MAP = new Map([
  [1, BOOKING_STEPS_URLS.SERVICES],
  [2, BOOKING_STEPS_URLS.LOCATIONS],
  [3, BOOKING_STEPS_URLS.AVAILABLE_DATES],
  [4, BOOKING_STEPS_URLS.EMPLOYEES],
  [5, BOOKING_STEPS_URLS.TIME_SLOTS],
  [6, BOOKING_STEPS_URLS.CONFIRM],
  [7, BOOKING_STEPS_URLS.PERSONAL_DETAILS],
  [8, BOOKING_STEPS_URLS.SUCCESSFUL_BOOKING],
])

const OnlineBooking = (): Node => {
  const useOnlineBooking = useSelector(
    (state: StateType) => state.tenantSettings.use_online_booking
  )
  const user = useSelector((state: StateType) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        // We can pop to personal details view only from confirmed booking
        // Thus we call redirect to timeout page
        if (location.pathname === BOOKING_STEPS_URLS.PERSONAL_DETAILS) {
          return navigator.navigate(BOOKING_STEPS_URLS.TIMEOUT)
        }
        dispatch(decreaseStep())
      }
    })
  }, [])

  // TODO: this is probably temporary solution and will require better handling
  if (!useOnlineBooking || user.is_staff) return <PageNotFound />

  return (
    <ErrorBoundary key={location.pathname}>
      <Switch>
        <Route
          path={BOOKING_STEPS_URLS.SERVICES}
          component={SelectServiceView}
        />
        <Route
          path={BOOKING_STEPS_URLS.LOCATIONS}
          component={SelectLocationView}
        />
        <Route
          path={BOOKING_STEPS_URLS.AVAILABLE_DATES}
          component={SelectAvailableDatesView}
        />
        <Route
          path={BOOKING_STEPS_URLS.EMPLOYEES}
          component={SelectEmployeeView}
        />
        <Route
          path={BOOKING_STEPS_URLS.TIME_SLOTS}
          component={withOnlineBookingModal(SelectTimeslotView)}
        />
        <Route path={BOOKING_STEPS_URLS.CONFIRM} component={ConfirmationView} />
        <Route
          path={BOOKING_STEPS_URLS.PERSONAL_DETAILS}
          component={PersonalDetailsView}
        />
        <Route
          path={BOOKING_STEPS_URLS.SUCCESSFUL_BOOKING}
          component={SuccessfulBooking}
        />
        <Route path={BOOKING_STEPS_URLS.TIMEOUT} component={TimeoutView} />
        <Route
          path={BOOKING_STEPS_URLS.FRONTPAGE}
          component={BookingFrontPage}
        />
      </Switch>
    </ErrorBoundary>
  )
}

export default OnlineBooking
