import React, { PureComponent } from 'react'
import self from 'autobind-decorator'
import Text from 'components/text'
import { SelectField } from 'react-drf/fields'

type ChoiceType = {
  value: number,
  label: string
}

type PropsType = {
  choices: Array<ChoiceType>,
  onDidUpdate: Function,
  minQueryLength?: number
}

type StateType = {
  value: Array<ChoiceType> | null,
  query: string,
  filteredChoices: Array<ChoiceType>,
  noResultsText: React$Element<*>
}

const DEFAULT_MIN_QUERY_LENGTH = 3
const MAX_RESULTS_SHOWN = 10

const DEFAULT_NO_RESULTS_TEXT = <Text>No results</Text>

// TODO: Merge this and UserSelectionField in the header into a more generic component


export default class UserSelectField extends PureComponent<PropsType, StateType> {

  state = {
    query: "",
    noResultsText: DEFAULT_NO_RESULTS_TEXT,
    filteredChoices: []
  }

  componentDidMount () {
    this.setNoResultsText('')
  }

  @self
  filterChoices (query: string) {
    const loweredQuery = query.toLowerCase().trim()
    const filteredChoices = this.props.choices.filter(choice => {
      const [ firstName, lastName ] = choice.label.split(' ')
      return firstName.toLowerCase().startsWith(loweredQuery)
              || lastName.toLowerCase().startsWith(loweredQuery)
              || choice.label.toLowerCase().startsWith(loweredQuery)
    }).slice(0, MAX_RESULTS_SHOWN)
    this.setState({ filteredChoices })
  }

  @self
  setNoResultsText (query: string) {
    if (isValidQuery(query))
      return this.setState({ noResultsText: DEFAULT_NO_RESULTS_TEXT })
    
    const length = query ? DEFAULT_MIN_QUERY_LENGTH - query.length : DEFAULT_MIN_QUERY_LENGTH
    this.setState({ noResultsText: <Text params={{ length }}>{ 'Type ${length} more character(s)' }</Text> })
  }

  @self
  onKeyDown (query: string) {
    this.setState({ query })
    this.setNoResultsText(query)
    if (isValidQuery(query)) {
      this.filterChoices(query)
    } else {
      this.setState({ filteredChoices: [] })
    }
  }


  render () {
    return <SelectField { ...this.props }
      value={ this.props.value }
      onKeyDown={ this.onKeyDown }
      onDidUpdate={ this.props.onDidUpdate }
      noResultsText={ this.state.noResultsText }
      placeholder={ this.props.placeholder || '' }
      options={ this.state.filteredChoices }
      multiple nolabel />
  }
}

function isValidQuery (query: string) {
  return query && query.length >= DEFAULT_MIN_QUERY_LENGTH
}
