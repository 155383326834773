// @flow

import React, { Component } from 'react'
import ImageCrop from 'react-easy-crop'
import self from 'autobind-decorator'

import { rotateImage, getCroppedImg } from 'utils/image'

import Modal from 'views/Page/Modal'
import Icon from 'components/icons'
import { Toolbar } from 'components/generic'
import Button, { PrimaryButton, SelectButtonGroup, RegularButton } from 'components/buttons'


type Area = {
  x: number,
  y: number,
  width: number,
  height: number,
}

type CropType = { x: number, y: number }

type CropperPropsType = {
  initial?: File,
  onChange: Function,
  onCancel: Function,
  edit: boolean,
  pictureType?: 'rect' | 'round',
}

type CropperStateType = {
  scale: number,
  image: string | ArrayBuffer,
  crop: CropType,
  aspect: number,
}

export const PICTURE_TYPES = {
  rect: 'rect',
  round: 'round'
}


export default class ImageCropper extends Component <CropperPropsType, CropperStateType> {

  static defaultProps = {
    pictureType: PICTURE_TYPES.rect
  }

  fileName: string = ''
  croppedArea: Area | null = null
  state = {
    scale: 1.0,
    image: '',
    crop: { x: 0, y: 0 },
    aspect: 1,
  }

  componentDidMount () {
    if (this.props.initial)
      this.imageFiletoUri(this.props.initial)
  }

  @self
  imageFiletoUri (file: File) {
    this.fileName = file.name
    const reader  = new FileReader()
    reader.addEventListener('load', () => this.setState({ image: reader.result }))

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  @self
  onZoom (scale: number) {
    this.setState({ scale })
  }

  @self
  async save () {
    const data: { file: File, url: * } = await getCroppedImg(this.state.image, this.croppedArea, this.fileName, 0)
    if (this.props.onChange) {
      this.props.onChange(data)
    }
  }

  @self
  onCropChange (crop: CropType) {
    this.setState({ crop })
  }

  @self
  onCropComplete (croppedArea: Area, croppedAreaPixels: Area) {
    this.croppedArea = croppedAreaPixels
  }

  @self
  onZoomChange (scale: number) {
    this.setState({ scale })
  }

  @self
  onAspectRatioChange (choice: number) {
    switch (choice) {
      case 1:
        this.setState({ aspect: 1 })
        break
      case 2:
        this.setState({ aspect: 3/4 })
        break
      case 3:
        this.setState({ aspect: 4/3 })
        break
      case 4:
        this.setState({ aspect: 16/9 })
        break
    }
  }

  @self
  //eslint-disable-next-line max-statements
  rotate (angle: number) {
    this.setState({ image: rotateImage(angle, this.state.image).toDataURL() })
  }

  render () {
    const height = this.props.pictureType === PICTURE_TYPES.rect ? '475' : '415'
    return <Modal
      visible={ this.props.edit }
      width='360'
      height={ height }
      effect='fadeInUp'
      onClickAway={() => this.props.onCancel() }>
      <div className='edit-modal'>
        <div className='cropper'>
          <ImageCrop
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.scale}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            cropShape={ this.props.pictureType }/>
        </div>
        <div className='tools'>
          <div style={{ display: 'flex' }}>
            <Button notranslate icon={ Icon.RotateRight } onClick={ () => this.rotate(Math.PI/2) } />
            <ZoomSlider onZoom={ this.onZoom } value={ this.state.scale }/>
            <Button notranslate icon={ Icon.RotateLeft } onClick={ () => this.rotate(-Math.PI/2)} />
          </div>

          { this.props.pictureType === PICTURE_TYPES.rect
              ? <div style={{ paddingBottom: '10px' }}>
                <SelectButtonGroup
                  buttonComponent={ RegularButton }
                  buttons={[
                    {
                      label: <span>1:1</span>,
                      onClick: () => this.onAspectRatioChange(1)
                    },
                    {
                      label: <span>3:4</span>,
                      onClick: () => this.onAspectRatioChange(2)
                    },
                    {
                      label: <span>4:3</span>,
                      onClick: () => this.onAspectRatioChange(3)
                    },
                    {
                      label: <span>16:9</span>,
                      onClick: () => this.onAspectRatioChange(4)
                    },
                  ]} />
              </div>
              : null }
          <Toolbar>
            <PrimaryButton onClick={ this.save }>Save</PrimaryButton>
            <Button onClick={ this.props.onCancel }>Cancel</Button>
          </Toolbar>
        </div>
      </div>
    </Modal>
  }
}


class ZoomSlider extends Component<{ onZoom: Function, value: number }> {

  min: number = 1
  max: number = 3
  interval: number = 0.01

  @self
  onChange (event: *) {
    const value = event.target.value || 1
    this.props.onZoom(value)
  }

  render () {
    return <div className='slider'>
      <div className='slider-container'>
        <input
          min={ this.min }
          max={ this.max }
          step={ this.interval }
          value={ this.props.value }
          onChange={ this.onChange }
          type='range'
        />
      </div>
    </div>
  }
}
