/**
 * @module StringField
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import React, { PureComponent } from 'react'
import self from 'autobind-decorator'

import { Bold, SmallText } from 'components/text'
import { Fragment } from 'react'


type ValueType = string

type StringFieldProps = {
  value: ValueType,
  onChange: Function,
  disabled?: boolean,
  id?: string,
  uneditable?: string,
  type?: string,
  fullwidth?: boolean,
  required?: boolean,
  maxLength?: string,
  renderValueOnly?: boolean,
  autoComplete?: boolean,
  inputRef?: Function
}


export default class StringField extends PureComponent<StringFieldProps> {

  static defaultProps: Object = {
    value: '',
  }

  element: HTMLInputElement

  @self
  //eslint-disable-next-line complexity
  handleCursor () {
    if (!this.props.uneditable || !this.element || !this.element.setSelectionRange) return

    let startPosition = this.element.selectionStart
    let endPosition   = this.element.selectionEnd
    let maxAllowed    = this.props.value ? this.props.value.length : 0

    if (startPosition > maxAllowed)
      startPosition = maxAllowed
    if (endPosition > maxAllowed)
      endPosition = maxAllowed
    this.element.setSelectionRange(startPosition, endPosition)
  }

  @self
  onChange (event: *) {
    const index = event.target.value.indexOf(this.props.uneditable)
    const value = event.target.value.slice(0, Math.max(index, 0))
    this.props.onChange(value)
  }

  @self
  //eslint-disable-next-line complexity
  onAction (event: *) {
    if (!this.props.uneditable || !this.element) return

    let startPosition = this.element.selectionStart
    let endPosition   = this.element.selectionEnd
    let maxAllowed    = this.props.value ? this.props.value.length : 0

    if (event && event.keyCode === 39 && (startPosition >= maxAllowed || endPosition >= maxAllowed)) {
      event.preventDefault()
    } else if (event && event.keyCode === 37 && (startPosition > maxAllowed || endPosition > maxAllowed)) {
      event.preventDefault()
    }
    this.handleCursor()
  }

  get value (): string {
    if (this.props.uneditable) {
      this.handleCursor()
      return this.props.value + (this.props.uneditable || '')
    }
    return this.props.value
  }

  renderValue (): React$Element<*> {
    return <SmallText><Bold notranslate>{ this.props.value || '-' }</Bold></SmallText>
  }

  @self
  handleRef(ref: HTMLInputElement) {
    this.element = ref
    if (this.props.inputRef) this.props.inputRef(ref)
  }

  renderInput (): React$Element<*> {
    const onChange = event => {
      if (this.props.uneditable)
        this.onChange(event)
      else
        this.props.onChange(event.target.value)
    }

    return <input
      aria-required={ this.props.required || null }
      id={ this.props.id }
      ref={ ref => { if (ref) this.handleRef(ref) }}
      type={ resolveType(this.props.type) }
      value={ this.value }
      onChange={ onChange }
      onClick={ this.onAction }
      onKeyDown={ this.onAction }
      disabled={ this.props.disabled }
      style={ this.props.fullwidth ? { width: '100%' } : {} }
      required={ this.props.required }
      maxLength={ this.props.maxLength }
      autoComplete= { !this.props.autoComplete ? "off" : "on"}
    />

  }

  render (): React$Element<*> {
    if (this.props.renderValueOnly)
      return <Fragment>{ this.renderValue() }</Fragment>
    else
      return <Fragment>{ this.renderInput() }</Fragment>
  }
}


function resolveType (type?: string) {
  const default_type = 'text'
  if (!type)
    return default_type
  if (type === 'string')
    return default_type
  return type
}
