// @flow
import { CompositeDisposable } from 'event-kit'
import Tooltip from './Tooltip'


export default class TooltipManager {
  subscriptions: CompositeDisposable = new CompositeDisposable()
  contents: WeakMap<HTMLElement, string> = new WeakMap()
  tooltips: Map<HTMLElement, Tooltip> = new Map()

  add (element: HTMLElement, text: string) {
    this.removeTooltipsFromElement(element)

    let tooltip = new Tooltip(this, element)
    this.contents.set(element, text)
    this.tooltips.set(element, tooltip)
    this.subscriptions.add(tooltip)
  }

  hideAll () {
    Array.from(this.tooltips.values()).forEach(tooltip => tooltip.hide(true))
  }

  remove (element: HTMLElement) {
    this.removeTooltipsFromElement(element)
  }

  removeTooltipsFromElement (element: HTMLElement) {
    if (!this.tooltips.has(element))
      return
    this.tooltips
      .get(element)
      .dispose()
  }

  getContentFor (el: HTMLElement): string {
    let tooltup = this.contents.get(el)
    if (tooltup)
      return tooltup
    return ''
  }

  destroy () {
    this.subscriptions.dispose()
  }

  dispose () {
    this.destroy()
  }
}
