// @flow
// For integration to other apps. A route and view for accessing navsiec health from
// external apps without having to go through the login page

/*eslint-disable no-console*/
import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { create } from 'apisauce'

import api from 'api'
import auth from 'api/auth'
import navigator from '../base/navigator'
import qs from 'querystring'


const descriptors = {
  auth: (id: ID) => `/external-auth/${id}`,
}


export class AuthView extends Component<*> {

  authApi = null

  get navigateTo (): string {
    const search = qs.parse(this.props.location.search)['?next']
    if (search && search.split('?').length)
      return search.split('?')[0]
    return search || ''
  }

  get queryParams (): Object {
    const search = qs.parse(this.props.location.search)['?next']
    if (search && search.split('?').length)
      return qs.parse(search.split('?')[1])
    return {}
  }

  //eslint-disable-next-line max-statements
  async componentDidMount () {
    const id = this.props.match.params.id
    navigator.navigate(this.navigateTo, this.queryParams)

    // get the url to post to from the api/crential/{id]}
    const credentialsResponse = await api.get(`/credentials/${id}/`)

    if (credentialsResponse.problem) {
      console.error('GET to credentials/id failed:', credentialsResponse.problem)
      navigator.navigate('/')
      return
    }

    // Create an authApi with the right base url so we can do a post
    if (!this.authApi)
      this.authApi = create({
        baseURL: credentialsResponse.data.auth_url,
        withCredentials: true,
      })
    const response = await this.authApi.post(`/`, {})

    if (!response.problem && response.data.token) {

      // The post was succesful. Lets use the single use token recieved to
      // to fetch actual tokens
      auth.getTokens(response.data.token).then(() => {
        navigator.navigate(this.navigateTo, this.queryParams)
      })
    } else {
      response.problem
        ? console.error('POST to auth_url failed:', response.problem)
        : console.error('The response from the external app was not of type:', { token: 'string' })

      // Something went wrong. Navigate to login
      navigator.navigate('/')
    }
  }

  render = () => null
}

export default <Route exact key='external-auth' path={ descriptors.auth(':id') } component={ AuthView } />
