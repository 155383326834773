// @flow
import self from 'autobind-decorator'

import React, { Component } from 'react'
import { Editor, EditorState, RichUtils, DefaultDraftBlockRenderMap } from 'draft-js'
import type { Block, ContentState } from 'draft-js'
import { Emitter } from 'event-kit'

import Overlay from 'components/generic/Overlay'

import Controls from './controls'
import fullscreen from './fullscreen'
import decorator from './suggestions/decorator'
import Suggestions from './suggestions/SuggestionListComponent'
import { getEditorStateWithSuggestionsReplaced } from './suggestions/modifiers'

import { render, getBlockStyle, htmlToEditorState } from './renderer'
import { STYLES, BLOCK_TYPES_MAP, HTML_DEFINITIONS } from './definitions'

// flow-ignore
import './RichTextEditor.less'

type CallbackParametersType = { target: React$Element<*>, value: string }
export type RichTextEditorProps = {|
  label: string,
  value: string,
  identifier: any,
  onChange?: CallbackParametersType => *,
  onUpdate?: CallbackParametersType => *,
  onFocus?: Function,
  onBlur?: Function,
  toolbar?: true,
  suggestions?: Array<string>,
  format?: 'html' | 'text',
  nolabels?: boolean,
|}

type RichTextEditorState = {|
  editorState: EditorState,
  fullscreen: boolean,
  active: boolean,
  value: string,
|}


export default class RichEditor extends Component<RichTextEditorProps, RichTextEditorState> {

  editor: Editor
  container: HTMLElement

  signal = new Emitter()

  static defaultProps = {
    format: 'html',
  }

  componentDidMount () {

    if ('value' in this.props) {
      this.setState({
        editorState: createEditorState(this.props.value, this.props.suggestions, this.props.nolabels)
      })
    }
  }

  constructor (props: RichTextEditorProps) {
    super(props)

    this.state = {
      editorState: createEditorState(props.value, props.suggestions, props.nolabels),
      fullscreen:  false,
      active:      false,
      value:       '',
    }

    window.ed = this
  }

  @self
  clear (): Promise<EditorState> {
    return this.assignState(createEditorState())
  }

  @self
  focus () {
    this.editor.focus()
  }

  @self
  toggleFullscreen () {
    if (this.state.fullscreen)
      fullscreen.exit()
    else
      fullscreen.open(this.container)
    this.setState({ fullscreen: !this.state.fullscreen })
  }

  @self
  handleKeyCommand (command: any) {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.assignState(newState)
      return true
    }
    return false
  }

  @self
  onTab (e: SyntheticKeyboardEvent<*>) {
    const maxDepth = 4
    this.assignState(RichUtils.onTab(e, this.state.editorState, maxDepth))
  }

  @self
  onFocus (e: SyntheticKeyboardEvent<*>) {
    this.setState(
      { active: true },
      () => callIfDefined(this.props.onFocus, e)
    )
  }

  @self
  onBlur (e: SyntheticKeyboardEvent<*>) {
    this.saveEditorContent()
    this.setState(
      { active: false },
      () => callIfDefined(this.props.onBlur, e)
    )
  }

  @self
  toggleBlockType (blockType: string) {
    this.assignState(RichUtils.toggleBlockType(this.state.editorState, blockType))
  }

  @self
  toggleInlineStyle (inlineStyle: string) {
    this.assignState(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle))
  }

  @self
  // eslint-disable-next-line class-methods-use-this
  customBlockFn (element: HTMLElement) {
    if (element.parentElement.tagName.toLowerCase() !== HTML_DEFINITIONS.rootTag)
      return

    const classNames = element.parentElement.className.split(' ')
    if (classNames.length !== 2 || classNames[0] !== HTML_DEFINITIONS.sectionCls)
      return

    const type = classNames[1].replace(HTML_DEFINITIONS.textBlockClsPrefix, '')
    if (typeof BLOCK_TYPES_MAP.get(type) === 'object')
      return {
        type,
        data: {}
      }
  }

  render () {
    const { editorState } = this.state

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'rte-editor'
    const contentState = this.contentState

    if (!contentState.hasText())
      if (contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled')
        className += ' rte-placeholder'

    const blockTypes = DefaultDraftBlockRenderMap.merge(BLOCK_TYPES_MAP)
    const fullscreen = this.state.fullscreen ? 'fullscreen' : 'inline-editor'
    const active = this.state.active ? 'active focus' : 'blur'

    return (
      <article ref={ref => ref && (this.container = ref)} className={`rte-root ${fullscreen} ${active}`}>
        <Controls
          visibilityState={this.props.toolbar ? true : false}
          editor={ this }
          editorState={editorState}
          onBlockTypeChanged={this.toggleBlockType}
          onInlineStyleToggled={this.toggleInlineStyle}
        />

        <div className={className}>
          <Editor
            ref={ref => ref && (this.editor = ref)}
            placeholder=''
            editorState={editorState}
            blockStyleFn={getBlockStyle}
            blockRenderMap={blockTypes}
            customStyleMap={STYLES}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.assignState}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onTab={this.onTab}
          />

        </div>

        <Overlay className='rte-overlay'>
          <Suggestions
            onDidUpdate={ this.onDidUpdate }
            suggestions={ this.props.suggestions }
            editorState={ this.state.editorState }
            updateEditorState={ this.assignState }
            nolabels={ this.props.nolabels }
            focus={ this.focus }
          />
        </Overlay>

      </article>
    )
  }

  @self
  onDidUpdate (fn: Function) {
    return this.signal.on('did-update', fn)
  }

  @self
  assignState (editorState: EditorState): Promise<EditorState> {
    return new Promise(resolve => this.setState({ editorState }, () => {
      this.signal.emit('did-update', editorState)
      callIfDefined(this.props.onUpdate, this.toString())
      resolve(editorState)
    }))
  }

  @self
  assignStateFromHTML (html: string | null) {
    const editorState = createEditorState(html || '', [], this.props.nolabels)
    return this.assignState(editorState)
  }

  @self
  async flattenEditorContent (html: boolean): Promise<string> {
    if (html)
      return await this.toHTML()
    return this.toString(false)
  }

  @self
  async saveEditorContent (): Promise<string> {

    // TODO: Change the second argument to CustomEvent
    const value = await this.flattenEditorContent(this.props.format === 'html')
    return await new Promise(resolve => this.setState({ value }, () => {
      this.signal.emit('did-update-content', this)
      callIfDefined(this.props.onChange, { target: this, value })
      resolve(value)
    }))
  }

  get value (): string {
    return this.state.value
  }

  get contentState (): ContentState {
    let { editorState } = this.state
    let contentState = editorState.getCurrentContent()
    return contentState
  }

  getEntities (key: string) {
    return getEntities(this.state.editorState, key)
  }

  @self
  async toHTML (): Promise<string> {
    return await flattenContent(this.contentState)
  }

  @self
  toString (replaceEntities: boolean = true): string {

    const contentState = this.contentState
    if (!replaceEntities)
      return toPlainText(contentState)

    const output = []
    const blocks = contentState.getBlocksAsArray()
    for (let block of blocks)
      output.push(replaceEntitiesInBlock(contentState, block))
    return output.join('\n')
  }

}

function replaceEntitiesInBlock (contentState: ContentState, block: Block) {
  const entities = []

  const getEntity = (key: any) => {
    if (!key)
      return null
    return contentState.getEntity(key.toString())
  }

  const appendEntity = (start, end) => {
    const entity = getEntity(block.getEntityAt(start))

    if (entity)
      entities.unshift({
        start,
        end,
        data: entity.getData()
      })
  }

  block.findEntityRanges(
    char => char.get('entity') ? true : false,
    appendEntity
  )

  let output = block.getText()
  entities.forEach(({ start, end, data }) => {
    if (data.value)
      output = output.substr(0, start)
        + data.value
        + output.substr(end, output.length)
  })

  return output
}

function getEntities (editorState: EditorState, entityType: string) {

  const entities = []
  const contentState = editorState.getCurrentContent()
  const blocks       = contentState.getBlocksAsArray()

  const getEntity = (key: any) => {
    if (!key)
      return null
    return contentState.getEntity(key.toString())
  }

  const matchEntity  = value => {
    const key = value.get('entity')
    const entity = getEntity(key)
    return entity ? entity.getType() === entityType : false
  }

  blocks.forEach(block => {
    const appendEntity = (start, end) => {
      const text = block.get('text').slice(start, end)
      const entity = getEntity(block.getEntityAt(start))

      entities.push({
        entity,
        block,
        start,
        end,
        text,
      })
    }

    block.findEntityRanges(matchEntity, appendEntity)
  })
  return entities
}

const createEditorState = (html?: string, suggestions?: Array<*>, nolabels?: boolean) =>
  getEditorStateWithSuggestionsReplaced(htmlToEditorState(html, decorator), suggestions, nolabels)

const callIfDefined = (fn: Function | void, ...args: Array<*>) =>
  typeof fn === 'function' && fn(...args)

const flattenContent = async (contentState: ContentState): Promise<string> =>
  await render(...contentState.getBlocksAsArray())

const toPlainText = (contentState: ContentState, delimiter: string = '\n'): string =>
  contentState
    .getBlocksAsArray()
    .map(block => block.getText())
    .join(delimiter)
