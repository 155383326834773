import api from 'api'
import { CLIENT_HEADER_NAME } from 'constants'

export const SELECT_CLIENT = 'SELECT_CLIENT'


export const selectClient = (user: *) => async (dispatch: Action => *) => {
  api.setHeader(CLIENT_HEADER_NAME, user.id)
  return await dispatch(updateClientSelection(user))
}


export const deselectClient = () => async (dispatch: Action => *) => {
  api.deleteHeader(CLIENT_HEADER_NAME)
  return await dispatch(clearClientSelection())
}


const updateClientSelection = (payload) => ({
  type: SELECT_CLIENT,
  payload,
})


const clearClientSelection = () => ({
  type: SELECT_CLIENT,
  payload: null,
})
