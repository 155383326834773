// @flow
import { Button as DesignButton } from '@nordhealth/react'
import React from 'react'
import __ from 'utils/gettext'
import type { Node } from 'react'

type Props = {
  notranslate?: boolean,
  variant?: 'default' | 'primary' | 'dashed' | 'plain' | 'danger',
  type?: 'button' | 'submit' | 'reset',
  size?: 's' | 'm' | 'l',
  href?: string,
  download?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',
  expand?: boolean,
  disabled?: boolean,
  name?: string,
  value?: string,
  children: string,
  onClick?: Function,
  slot?: string,
}

const Button = ({
  notranslate = false,
  variant = 'default',
  type = 'submit',
  size = 'm',
  href,
  download = false,
  target = '_self',
  expand = false,
  disabled = false,
  name,
  value = '',
  children,
  onClick,
  slot,
}: Props): Node => {
  const text = notranslate ? children : __(children)
  return (
    <DesignButton
      type={type}
      variant={variant}
      size={size}
      href={href}
      download={download}
      target={target}
      expand={expand}
      disabled={disabled}
      name={name}
      value={value}
      onClick={onClick}
      slot={slot}
    >
      {text}
    </DesignButton>
  )
}

export default Button
