// @flow
import React from 'react'
import { EmptyState as NordEmptyState } from '@nordhealth/react'

import type { Node } from 'react'

type Props = {
  title?: string,
  additionalInfo?: string,
}

const EmptyState = ({ title, additionalInfo }: Props): Node => {
  return (
    <NordEmptyState>
      {title && <h1>{title}</h1>}
      {additionalInfo && <span>{additionalInfo}</span>}
    </NordEmptyState>
  )
}

export default EmptyState
