
import React, { Fragment } from 'react'

import routes from 'routes/public/courses'


const PublicCoursesContainer = () =>
  <Fragment>
    { routes }
  </Fragment>


export default PublicCoursesContainer
