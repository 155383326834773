
import React, { Fragment } from 'react'

import routes from 'routes/public/forms'


const PublicFormsContainer = () =>
  <Fragment>
    { routes }
  </Fragment>


export default PublicFormsContainer
