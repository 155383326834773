// @flow
import React, { Component } from 'react'
import tooltips from 'tooltips'


type ButtonProps = {
  onClick?: Function,
  onMouseDown?: Function,
  text: string,
  icon: typeof Component,
  className?: string,
  attributes?: { [string]: string }
}


export default class WithTooltip extends Component<{ text: string, children: React$Element<*> }> {
  element: HTMLElement

  componentDidMount = () => this.addTooltip()
  componentWillUnmount = () => this.clearTooltip()
  setReference = (element: HTMLElement) => this.element = element
  addTooltip = () => tooltips.add(this.element, this.props.text)
  clearTooltip = () => tooltips.hideAll()

  render () {
    const ComponentType = this.props.children.type
    const props = this.props.children.props
    return <ComponentType
      {...props}
      ref={ ref => ref && this.setReference(ref) }
    />
  }
}

export const ButtonWithTooltip = (props: ButtonProps) => {

  const Icon = props.icon
  const attributes = typeof props.attributes === 'object'
    ? Object
      .keys(props.attributes)
      .reduce((attrs: Object, attr: string) =>
        Object.assign(attrs, { ['data-' + attr]: props.attributes[attr] }), {})
    : {}

  return <WithTooltip text={ props.text }>
    <button
      onClick={ props.onClick }
      onMouseDown={ props.onMouseDown }
      className={ props.className }
      {...attributes}>
      <Icon />
      <span className='label'>{ props.text }</span>
    </button>
  </WithTooltip>
}
