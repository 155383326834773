/**
 * @name   Form
 * @author tuomashatakka<tuomas.hatakka@gmail.com>
 * @flow
 */

import self from 'autobind-decorator'
import equal from 'shallowequal'
import React, { Component } from 'react'

// FIXME: Remove or embed !!!1
import { PrimaryButton } from 'components/buttons'
import api from 'api'

import constructFieldsetFromApiResponse from '../base/constructFromApiResponse'



type FormProps = {
  url?: string,
  optionsUrl?: string,
  onSubmit?: Function,
  onSuccess?: Function,
  onError?: Function,
  method: string,
  value?: Object,
  errors?: Object,
}

function assertUrlIsSet (props) {
  if (!props.url && !props.optionsUrl)
    throw new ReferenceError(`Form must have either \`url\` or \`optionsUrl\` prop defined.`)
}


export default class Form extends Component<FormProps> {

  static defaultProps = {
    method: 'POST',
  }

  state = {
    value: null,
    errors: null,
    available: false,
    fieldset: () => null,
  }

  constructor (props) {
    super(props)
    if (__DEV__)
      assertUrlIsSet(props)
  }

  static getDerivedStateFromProps (props: PropTypes, state: StateType) {
    const updates = {}

    if (props.errors)
      updates.errors = props.errors
    else if (state.errors)
      updates.errors = null

    if (props.value && !equal(props.value, state.value))
      updates.value = props.value

    if (Object.keys(updates).length)
      return updates
    return null
  }

  async generateFieldset () {
    const url      = this.props.optionsUrl || this.props.url
    const fieldset = await constructFieldsetFromApiResponse(url, this.props.method)
    this.setState({
      available: true,
      fieldset,
    })
  }

  componentDidMount () {
    this.generateFieldset()
  }

  @self
  onSubmit (event: SyntheticEvent<*>) {

    const preventDefault = [ 'onSubmit', 'onSuccess', 'onError' ]
      .map(fn => typeof this.props[fn])
      .includes('function')

    if (preventDefault)
      event.preventDefault()


    this.setState({ available: false }, async () => {
      const data = this.toJSON()
      let errors = null
      let response

      try {
        if (typeof this.props.onSubmit === 'function')
          response = await this.props.onSubmit(data, this.props.url, this.props.method)

        if (!response)
          response = await api[this.props.method](this.props.url, data)

        if (!response.valid)
          throw response.errors

        this.onSuccess(response)
      }

      catch (error) {
        this.onError(error)
        errors = error
      }
      finally {
        this.setState({
          available: true,
          errors,
        })
      }
    })
  }

  @self
  async onSuccess (response) {
    if (typeof this.props.onSuccess === 'function')
      await this.props.onSuccess(response)
  }

  @self
  async onError (errors) {
    if (typeof this.props.onError === 'function')
      await this.props.onError(errors)
    // else
    //   throw error
  }

  @self
  onChange (value) {
    this.setState({ value })
  }

  render () {
    return <form
      onSubmit={ this.onSubmit }
      action={ this.props.url }
      method={ this.props.method }>

      { this.renderFields() }

      <PrimaryButton disabled={ !this.state.available }>
        Submit
      </PrimaryButton>
    </form>
  }

  renderFields (params = {}) {
    const value     = params.value || this.state.value
    const errors    = params.errors || this.state.errors
    const Fields    = params.fieldset || this.state.fieldset
    const onChange  = params.onChange || this.onChange

    return <Fields
      value={ value }
      errors={ errors }
      onChange={ onChange } />
  }


  toJSON () {
    return { ...this.state.value }
  }
}
