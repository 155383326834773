import React from 'react'
import { Route, Switch } from 'react-router-dom'
import load from 'components/LoadableComponent'
import { RouteNotFound } from 'views/Error/PageNotFound'


const BaseView = load(() =>
  import('views/Public/Library/BaseView'))



export const descriptors = {
  library:            `/library/`,
}


export default <Switch>
  <Route exact path={ descriptors.library } component={ BaseView } />
  <RouteNotFound />
</Switch>
