//@flow
import React from 'react'
// $FlowIgnore
import { useDispatch } from 'react-redux'
import { increaseStep } from 'actions/onlineBooking'
import { Button } from 'components/designSystemComponents'

import type { Node } from 'react'

type Props = {
  onConfirm?: Function,
  label?: string,
  position?: string,
}

const ConfirmationFooter = (props: Props): Node => {
  const dispatch = useDispatch()

  const position = props.position || 'static'

  const onClick = () => {
    if (props.onConfirm) {
      return props.onConfirm()
    }
    dispatch(increaseStep())
  }

  return (
    <div className='confirmation-footer' style={{ position }}>
      <Button expand variant='primary' onClick={onClick}>
        {props.label || 'Confirm'}
      </Button>
    </div>
  )
}

export default ConfirmationFooter
