import qs from 'querystring'
import { createBrowserHistory } from 'history'
import { CompositeDisposable, Disposable } from 'event-kit'

const history                     = createBrowserHistory()
export const subscriptions        = new CompositeDisposable()
export const onDidChangeLocation  = (fn) => {
  const handler    = decorateLocationCallback(fn)
  const disposable = new Disposable(history.listen(handler))
  subscriptions.add(disposable)
  return disposable
}

export default Object.defineProperties(history, {
  query: { get: () => getQuery() },
  currentLocation: { get: () => getLocationData() },
})

function getLocationData (key = null) {
  const location = parseLocation(history.location)

  if (key === null)
    return location
  return location[key]
}

function getQuery () {
  return getLocationData('query')
}


const parseLocation = (location) => {

  const search = (location.search || ' ').substring(1)
  const state  = location.state || null
  const query  = qs.parse(search)

  return {
    key:   location.key,
    url:   location.pathname,
    hash:  location.hash,
    query,
    search,
    state,

    // TODO: Deprecate
    pathname: location.pathname,
  }
}

const decorateLocationCallback = (fn) => location => fn(parseLocation(location))
