// @flow
import type { Action } from '../reducers'

export const FILTER_COURSES = 'FILTER_COURSES'

export const clearFilters = (): Action => ({
  type: FILTER_COURSES,
  payload: [{
    value: [],
    property: 'tags',
  }, {
    value: null,
    property: 'search',
  }]
})

export const filterCoursesByTag = (tags: Array<*>): Action => ({
  type: FILTER_COURSES,
  payload: {
    value: tags,
    property: 'tags',
  }
})

export const filterCoursesBySearch = (search: string): Action => ({
  type: FILTER_COURSES,
  payload: {
    value: search,
    property: 'search'
  }
})