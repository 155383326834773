/**
 * @flow
 * ListSubmissions
*/
import self from 'autobind-decorator'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import api from 'api'

import Text from 'components/text'
import SearchInput from 'components/SearchInput'
import { SelectField } from 'react-drf/fields'
import { SliderToggleField } from 'components/fields'
import Filters, { FilterFieldContainer } from './extra/Filters'

import { CancelablePromise } from 'utils/promise'

import __ from 'utils/gettext'

const FILTER_CHOICES = {
  BOOLEAN_FILTER: 'BooleanFilter',
  SEARCH_FILTER: 'CharFilter',
  MODEL_MULTIPLE_CHOICE_FILTER: 'ModelMultipleChoiceFilter',
  MULTIPLE_CHOICE_FILTER: 'MultipleChoiceFilter',
  FULL_NAME_USER_SEARCH: 'FullnameUserFilter',
  SELECT_FIELD_FILTER: 'TypedChoiceFilter',
  PARTICIPANTS: 'ParticipantChoiceFilter'
}

type ApiFilter = {
  class: string,
  field_name: string,
  label: string,
  name: string
}

type PropsFilters = {
  name: string,
  value: any,
  placeholderText?: string,
  displayName?: string,
  dispatch: Function,
  dispatchClearValue: any
}

type Props = {
  filters: Array<PropsFilters>,
  initOptionsUrl: string,
  className?: string,
  dispatch: Function
}

type State = {
  toggleFilters: Array<any>,
  searchFilters: Array<any>,
  multipleChoiceFilters: Array<any>,
  singleChoiceFilters: Array<any>
}

class ListViewFilters extends Component<Props, State> {

  _cancelableOptionsInitPromise: CancelablePromise<*>
  _initOptionsPromise: Promise<*> | null

  state: State = {
    toggleFilters: [],
    searchFilters: [],
    multipleChoiceFilters: [],
    singleChoiceFilters: [],
  }

  componentDidMount() {
    this.initFilterChoices()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props)
      this.initFilterChoices()
  }

  componentWillUnmount () {
    if (this._cancelableOptionsInitPromise)
      this._cancelableOptionsInitPromise.cancel()
  }

  initFilterChoices () {
    let filters = []
    this._initOptionsPromise = this.initOptions()
    if (this._initOptionsPromise) {
      this._cancelableOptionsInitPromise = new CancelablePromise(this._initOptionsPromise)
      this._cancelableOptionsInitPromise.then(resp => {
        if (resp.problem || !resp.data.filters)
          return
        else {
          filters = resp.data.filters
          this.mapFilters(filters)
        }
      })
    }
  }

  mapFilters = (filters: Array<*>) => {
    const allMultipleChoiceFilters = filters
      .filter(filter => filter.class === FILTER_CHOICES.MODEL_MULTIPLE_CHOICE_FILTER || filter.class === FILTER_CHOICES.MULTIPLE_CHOICE_FILTER)
      .map(this.mapChoicesToFilter)

    const allToggleFilters = filters.filter(filter => filter.class === FILTER_CHOICES.BOOLEAN_FILTER)
    const allSearchFilters = filters.filter(filter => filter.class === FILTER_CHOICES.SEARCH_FILTER)

    const allSingleChoiceFilters = filters
      .filter(filter => filter.class === FILTER_CHOICES.FULL_NAME_USER_SEARCH
        || filter.class === FILTER_CHOICES.SELECT_FIELD_FILTER
        || filter.class === FILTER_CHOICES.PARTICIPANTS)
      .map(this.mapChoicesToFilter)

    this.mapFiltersToState(allToggleFilters, 'toggleFilters')
    this.mapFiltersToState(allSearchFilters, 'searchFilters')
    this.mapFiltersToState(allMultipleChoiceFilters, 'multipleChoiceFilters')
    this.mapFiltersToState(allSingleChoiceFilters, 'singleChoiceFilters')
  }

  initOptions () {
    return api.options(this.props.initOptionsUrl)
  }

  render(): React$Element<*> {
    return (
      <>
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Filters
            filters={ this.props.filters.map(filterToStr) }
            onRemove={ this.clearFilters }
          >
            <div className={this.props.className || ''}>
              <div className='toggle-filters'>
                {this.renderToggleFilters()}
              </div>
              <div>
                {this.renderSearchFields()}
              </div>
              <div>
                {this.renderSignleChoiceSelect()}
              </div>
              <div>
                {this.renderMultipleChoiceSelect()}
              </div>
            </div>
          </Filters>
          <FilterFieldContainer />
        </div>
      </>
    )
  }

  @self
  clearFilters (filter: {name: string, value: *, id: string, onClear: Function, clearValue: any}): Function {
    const { onClear, clearValue } = filter
    this.props.dispatch(onClear(clearValue))
  }

  renderToggleFilters(): React$Element<*> {
    return (
      <>
        {this.state.toggleFilters.map(toggler => {
          return (
            <span key={toggler.label}>
              <SliderToggleField
                value={ toggler.value }
                label={ toggler.label }
                onChange={toggler.onChange}
              />
            </span>
          )
        })}
      </>
    )
  }

  renderSearchFields(): React$Element<*> {
    return (
      <div>
        {this.state.searchFilters.map(filter => {
          return (
            <span key={filter.name}>
              <SearchInput
                search={ filter.value || '' }
                placeholder={filter.placeholderText}
                onChange={ filter.onChange }
              />
            </span>
          )
        })}
      </div>
    )
  }

  renderMultipleChoiceSelect(): React$Element<*> {
    return (
      <div className='tags-filter'>
        {this.state.multipleChoiceFilters.map(filter => {
          return (
            <span key={filter.label}>
              <SelectField
                value={filter.value}
                options={filter.choices}
                onDidUpdate={filter.onChange}
                multiple
                placeholder={<Text>{filter.placeholderText}</Text>}
              />
            </span>
          )
        })}
      </div>
    )
  }

  renderSignleChoiceSelect(): React$Element<*> {
    return (
      <div style={{ width: 320 }}>
        {this.state.singleChoiceFilters.map(filter => {
          return (
            <span key={filter.name}>
              <SelectField
                value={filter.value || []}
                onDidUpdate={filter.onChange}
                placeholder={<Text>{filter.placeholderText}</Text>}
                options={filter.choices}
                multiple={false}
              />
            </span>
          )
        })}
      </div>
    )
  }

  // Merges 2 different filters with its properties: filters passed as props
  // and filters received from the backend
  mapFiltersToState(apiFilters: Array<ApiFilter>, stateKey: string): void {
    const intersection = apiFilters.map(apiFilter => {
      let toReturn = {}
      this.props.filters.forEach(propFilter => {
        if (propFilter.name === apiFilter.name) {
          toReturn = {
            ...apiFilter,
            value: propFilter.value,
            onChange: (value = null) => this.props.dispatch(propFilter.dispatch(value)),
            placeholderText: propFilter.placeholderText || '',
          }
        }
      })
      if (!Object.keys(toReturn).length) return
      return toReturn
    })
    const resultingArray = intersection.filter(intersection => intersection !== undefined)
    this.setState({ [stateKey]: resultingArray })
  }

  mapChoicesToFilter(filter: Object): Object {
    // Remove empty choices
    const choices = filter.choices.filter(choice => choice.value)

    return {
      ...filter,
      choices: mapOptionsToChoices(choices)
    }
  }

}

const mapOptionsToChoices = (data: { value: *, display_name: string }): Tag =>
  data.map(choice => ({
    value: choice.value,
    label: choice.display_name
  }))

export default connect()(ListViewFilters)

// Util functions to be used with ListView
export const filtersForListView = (filters: Array<PropsFilters>): Object => {
  let output: Object = {}
  filters.forEach(filter => {
    if (Array.isArray(filter.value) && filter.value.length > 0) {
      const array = filter.value.map(choice => choice.value)
      output[filter.name] = array
    }
    if (!Array.isArray(filter.value) && filter.value) {
      output[filter.name] = filter.value
    }
    if (!Array.isArray(filter.value) && typeof filter.value === 'boolean') {
      output[filter.name] = filter.value
    }
    // In case in select field chosen field is an object of type { label, value }
    if (!Array.isArray(filter.value) && filter.value && filter.value.hasOwnProperty('value')) {
      output[filter.name] = filter.value.value
    }
  })
  return output
}

// Util function that is in conjunction with Filter and will
// display selected filters in the toolbar
export const filterToStr = (filter: *): Object => {
  const onClear = filter.dispatch
  const clearValue = filter.dispatchClearValue
  if (Array.isArray(filter.value)){
    return arrayFilterToStr(filter)
  }
  else if (is.string(filter.value)){
    return { displayName: filter.value, name: __(capitalize(filter.name)), onClear , clearValue }
  }
  else if (filter.value && filter.displayName && filter.value.hasOwnProperty('label')) {
    return { displayName: filter.value.label, name: __(capitalize(filter.name)), id: filter.name, onClear, clearValue }
  }
  else if (filter.value && filter.displayName) {
    return { displayName: __(filter.displayName), name: __(capitalize(filter.name)), onClear, clearValue }
  }
  return { displayName: '', name: filter.name, onClear, clearValue }
}

const arrayFilterToStr = (filter: Array<*>) => {
  if (!filter.value.length)
    return { displayName: '', name: '' }

  const onClear = filter.dispatch
  const clearValue = filter.dispatchClearValue

  switch (filter.name) {
    case 'tags':
      return {
        displayName: filter.value.map(value => value.label).join(', '),
        name: __('Tags'),
        id: 'tags',
        onClear,
        clearValue
      }
  }
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}