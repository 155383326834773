// @flow
import React from 'react'
import parseTags from 'html-react-parser'
import Linkify from 'react-linkify'
import dayDiff from 'date-fns/differenceInDays'
import { fi, enGB, sv } from 'date-fns/locale'
import { getLocale, __ } from 'utils/gettext'
import moment from 'moment'

import { DATETIME_FORMAT, DATE_FORMAT } from '../constants'

const DEFAULT_DATE_LOCALE = fi
const DATE_LOCALES = {
  en: enGB,
  fi: fi,
  sv: sv,
}

const getDateLocale = () => {
  const locale = getLocale()
  return DATE_LOCALES[locale] || DEFAULT_DATE_LOCALE
}

const monthNames = [
  __('January'),
  __('February'),
  __('March'),
  __('April'),
  __('May'),
  __('June'),
  __('July'),
  __('August'),
  __('September'),
  __('October'),
  __('November'),
  __('December'),
]

const differenceNames = [__('today'), __('yesterday'), __('the day before at')]

const monthNameSuffixes = { fi: 'kuuta', sv: '', en: '' }

const monthNameSuffix = monthNameSuffixes[getLocale() || 'fi']

const getMonthName = (char, long = false) => {
  const index = parseInt(char) - 1
  return (
    __(monthNames[index]) + (long ? monthNameSuffixes[getLocale() || 'fi'] : '')
  )
}

const matchDateFormat = /(([YMDHms])(?:\2{0,4}))/g

const daysPassedFrom = (date: Date): number => {
  const now = new Date()
  return date < now ? -dayDiff(date, now) : 0
}

export function formatLiterateDate(value: string | Date): string | null {
  const date = new Date(value)
  const index = daysPassedFrom(date)

  if (index < differenceNames.length) return differenceNames[index]
  return null
}

/**
 * Get a day-aware representation for the given date.
 *
 * @method  formatRelativeDate
 * @param   value
 * @param   ignoreMobile Set true to ignore the mobile device check
 * @return  For dates that are within present day's timespan return only the time,
 *          for other dates return both the date and the time.
 */

export function formatRelativeDate(
  value: string | Date,
  ignoreMobile?: boolean
): string {
  const date = moment(value)

  // Only show relative date if it's within a week from now and user is not on a mobile device

  if (moment().diff(date, 'days') <= 2)
    if (window.innerWidth > global.breakpoint.mobile || ignoreMobile)
      return date.fromNow()

  // else show the date
  return formatDate(date, DATE_FORMAT)

  // TODO: Uncomment when the date-fns has support for finnish
  // const locale = getDateLocale()
  // return formatRelative(value, new Date(), { locale })
}

/**
 * Get a textual representation for the given date
 *
 * @method  formatDate
 * @param   value
 * @param   [format=DATETIME_FORMAT] Format for the returned string.
 *                                   MMM  prints a short representation of a month name,
 *                                   MMMM prints the full month name.
 * @example
 *
 * const dateString = formatDate(new Date(), 'D. MMMM HH:mm:ss')
 *
 * // The following prints something like
 * // `3. helmikuuta 07:15:51`
 * console.log(dateString)
 */

export function formatDate(
  value: string | Date,
  format: string = DATETIME_FORMAT
): string {
  let pad = (c) => '0' + c.toString()
  let date = new Date(value)
  let parts = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    D: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  }

  const replace = (c, match) => {
    let n = match.length
    let l = c[0]
    let char = parts[l]

    if (isNaN(char)) return new Array(n).fill('0').join('')

    char = char.toString()
    while (n === 2 && char.length < n) return pad(char)

    if (match.startsWith('MMM')) return getMonthName(char, n > 3)

    return char
  }

  return format.replace(matchDateFormat, replace)
}

/**
 * Resolves a textual representation for the
 * given instance or object.
 *
 * @method  displayName
 * @param   {any}    instance  The payload for which to resolve the representation
 * @return  {string}           String describing the given payload
 */

export function displayName(instance: any): string {
  if (instance === null) return 'null'
  if (typeof instance === 'string') return 'string'
  if (typeof instance === 'number') return 'number'

  if (typeof instance.constructor === 'function')
    return getFunctionName(instance)
  if (typeof instance.name !== 'undefined') return instance.name.toString()
  return typeof instance
}

export function toRepresentation(text: any): string {
  // eslint-disable-line complexity
  if (typeof text === 'undefined') return 'undefined'
  if (typeof text === 'string') return text
  if (typeof text === 'number') return text.toString()
  if (text.toJSON) text = text.toJSON()
  if (typeof text === 'object') return JSON.stringify(text)
  if (typeof text === 'function') return getFunctionName(text)
  if (text.toString && !text.toString().startsWith('[')) return text.toString()
  return 'unresolvable'
}

const getFunctionName = (
  instance // eslint-disable-line complexity
) =>
  instance.displayName ||
  (instance.constructor &&
    (instance.constructor.name || instance.constructor.displayName)) ||
  (instance.constructor.prototype && instance.constructor.prototype.name) ||
  instance.name ||
  'function'

export const parseTagsAndLinks = (content: string): React$Element<*> => {
  parseTags(content)
  const result = (
    <Linkify properties={{ target: '_blank' }}>{parseTags(content)}</Linkify>
  )
  return result
}
