// @flow
import type { Action } from '../reducers'


export const FILTER_BY_USERNAME = 'FILTER_BY_USERNAME'
export const FILTER_BY_GROUP    = 'FILTER_BY_GROUP'
export const FILTER_BY_TYPE     = 'FILTER_BY_TYPE'

export const setUsername = (value: string): Action => {
    return {
        type: FILTER_BY_USERNAME,
        payload: value
    }
}

export const setType = (value: string): Action => {
    return {
        type: FILTER_BY_TYPE,
        payload: value
    }
}

export const setGroup = (value: string): Action => {
    return {
        type: FILTER_BY_GROUP,
        payload: value
    }
}

