/**
 * @module Loader
 * @flow
 */

import React from 'react'
import PreviewLoader from 'react-content-loader'
import Asynchronous from 'react-promise'
import { ErrorComponent } from './ErrorView'


type PropTypes = {
  children?: React$Element<*>,
  value?: number,
  error?: *
}

type ContentLoaderPropTypes = {
  loading?: boolean,
  type?: 'bullet-list' | 'list' | 'facebook' | 'instagram',
}


const renderLoader = () =>
  <Loader/>


const Loader = (props: PropTypes) => {
  let properties = {}

  if (!props.value)
    properties.indeterminate

  if (props.error) {
    if (__DEV__)
      return <ErrorComponent error={ props.error } />

    return <div className='loading color-error'>
      { props.error.toString() }
    </div>
  }

  return (
    <div className='loading'>
      <progress {...properties} />
      {props.children}
    </div>
  )
}

export const ContentLoader = (props: ContentLoaderPropTypes) =>
  <div className='loading'>
    <PreviewLoader
      className={'content-loader' + (props.loading ? ' loading' : ' hidden')}
      type={props.type || 'bullet-list'}
    />
  </div>


export const Async = (props: *) =>
  <Asynchronous
    { ...props }
    pending={ renderLoader }
    before={ renderLoader }
  />


export default Loader
