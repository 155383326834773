// @flow
import React from 'react'
import style from '../style'
import BaseToggleComponent from './Base'
import { RADIO_PATH } from '../constants'

import connect from 'bound-connect'
import type { StateType } from 'store/initialState'


const RadioActiveMarker = ({ fill }) =>
  <circle cx='7' cy='6.625' r='3.2' style={{ transition: 'inherit', fill }} />


@connect
export default class Radio extends BaseToggleComponent {

  static PATH          = RADIO_PATH
  static style         = style.radio
  static displayName   = 'Radio Select Field Entry'
  static typeAttribute = 'radio'

  static actions = []
  static properties = (state: StateType) => ({
    primaryColor:  state.tenantSettings.color,
  })

  // eslint-disable-next-line class-methods-use-this
  renderMarkerComponent () {
    return <RadioActiveMarker
      fill={ this.activeColor }
    />
  }

}
