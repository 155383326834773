// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import { ToolbarContainer, Toolbar } from 'components/generic'
import { NavigateButton } from 'components/buttons'
import ErrorPage from './ErrorPage'
import { __ } from 'utils/gettext'
import navigator from 'routes/base'
import { isPublicApp } from 'routes/index'
import { Paragraph } from 'components/text'
import { SimpleLink } from 'components/generic'
import { DEFAULT_REDIRECT_URL, DEFAULT_PUBLIC_REDIRECT_URL } from 'constants'


export default function PageNotFound () {
  return <ErrorPage
    errorCode={ 404 }
    errorName={ __('Page not found') }
    footer={ renderFooter() }>
    { renderDescription() }
  </ErrorPage>
}

export function ApiUrlNotFound () {
  return <ErrorPage
    errorCode={ 404 }
    errorName={ __('Page not found') }>
    <p>{ __('This web page does not exist.') }</p>
  </ErrorPage>
}

function goBack () {
  let useFallback = true
  window.onbeforeunload = () => {
    useFallback = false
  }
  navigator.previous()

  setTimeout(() => {
    if (useFallback){ navigator.navigate('/') }
  }, 333)
}

function renderDescription () {
  return <section>
    <Paragraph>Sorry! It seems you have found something you were not supposed to.</Paragraph>
    <Paragraph
      params={{
        here: '<span id="replace" name="here"></span>'
      }}
      parseParameters={{
        here: {
          component: SimpleLink,
          text: 'here',
          props: { strong: true, onClick: goBack },
        }
      }}>
      { 'You can get back to where you came from by clicking ${here}.' }
    </Paragraph>
    <Paragraph>Or you can navigate to one of the pages below:</Paragraph>
  </section>
}


function renderFooter () {
  return <ToolbarContainer className='footer'>
    <Toolbar left>
      { isPublicApp() ? <ToTenantsButton /> : <ToThreadsButton /> }
      <ToFormsButton />
    </Toolbar>
  </ToolbarContainer>
}


const THREADS_URL = '/threads/'
const FORMS_URL = '/forms/'
const TENANTS_URL = '/tenants/'


const ToFormsButton   = () => <NavigateButton url={ FORMS_URL }>Forms</NavigateButton>
const ToThreadsButton = () => <NavigateButton url={ THREADS_URL }>Threads</NavigateButton>
const ToTenantsButton = () => <NavigateButton url={ TENANTS_URL }>Tenants</NavigateButton>

export const RouteNotFound = () =>
  <Redirect to={ { state: { pageNotFound: true }} } />


export const CapturePageNotFound = withRouter(({ children, location }) => {
  if (location.pathname === DEFAULT_REDIRECT_URL
    || location.pathname === DEFAULT_PUBLIC_REDIRECT_URL)
    return children
  return location && location.state && location.state.pageNotFound ? <PageNotFound /> : children
})
