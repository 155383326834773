// @flow
import React from 'react'
import qs from 'querystring'
import is from '@sindresorhus/is'

import { getDomainUrl, mediaURL } from 'api/constants'
import { SUBSCRIPTION_GROUP_USER, SUBSCRIPTION_TYPE_PRO } from 'constants'

export type StateType = 'info' | 'primary' | 'success' | 'warning' | 'error'
export type SizeType = 'tiny' | 'small' | 'large' | 'huge' | 'regular'
export type VariantType = 'active' | 'filled'

export const SIZES: Array<SizeType> = [
  'tiny',
  'small',
  'regular',
  'large',
  'huge',
]

const MISC = [
  'active',
  'filled',
  'icon-only',
  'borderless',
  'circle',
]

export const STATES: Array<string> = [
  ...SIZES,
  ...MISC,
  'info',
  'primary',
  'success',
  'warning',
  'error',
]

export const LAYOUT: Array<string> = [
  'tight',
]

const PADDING_PREFIX = 'padding-'

export const PADDING: Array<string> = [
  'nan',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxx',
]

export const SIZE_CLASS_NAME_MAP = {
  xxx: 'ye-cake-issa-lie',
  nan: 'none',
  xs:  'tiny',
  sm:  'small',
  md:  'regular',
  lg:  'large',
  xl:  'huge',
}

export function resolveClass (props: { [string]: any }, baseClass: string = '') {
  let keys = Object.keys(props)

  if (typeof baseClass === 'string')
    baseClass = [ baseClass ]
  else if (!is.array(baseClass))
    baseClass = []

  let concatenate = (cls: string, state: string) => {
    let index = keys.indexOf(state)

    if (index === -1)
      return cls
    if (props[keys[index]] === true)
      cls += ' ' + state
    return cls
  }

  if ('padding' in props)
    baseClass = baseClass.concat([
      props.level,
      resolvePaddingClassName(props),
    ])

  if ('className' in props)
    baseClass.push(props.className)

  return STATES.reduce(concatenate, baseClass.filter(o => o).join(' ')).trim()
}

export function resolvePaddingClassName (props: { [string]: any }) {
  if (props.padding in SIZE_CLASS_NAME_MAP)
    return PADDING_PREFIX + SIZE_CLASS_NAME_MAP[props.padding]
  return null
}


function assertDefined (displayName, props, ...descriptor) {
  let key
  let iteratee = { ...props }
  const fragments = [ ...descriptor ]

  while (key = fragments.shift()) {
    if (iteratee[key])
      iteratee = iteratee[key]
    else
      throw new ReferenceError(
        `Props passed to the \`${displayName}\` function must include the ` +
        `${descriptor.join('.')} property. The ${key} fragment is invalid.`)
  }
  return iteratee
}


export const getPreviousLocationAction = (props: RouteProps & *) =>
  assertDefined('getPreviousLocationAction', props, 'history', 'goBack')

export const getNextLocationAction = (props: RouteProps & *) =>
  assertDefined('getNextLocationAction', props, 'history', 'goForward')

export const getPushLocationAction = (props: RouteProps & *, url: string) =>
  () => assertDefined('getPushLocationAction', props, 'history', 'push')(url)

export const getReplaceLocationAction = (props: RouteProps & *, url: string) =>
  () => assertDefined('getReplaceLocationAction', props, 'history', 'replace')(url)


export function asIntegerOrString (num: string | number | null | undefined) {
  const int = parseInt(num)
  if (!is.nan(int))
    return int
  return num
}


/**
 * Find a search parameter from the location
 * @method resolveSearchParam
 * @param  {object} location       Location object (e.g. props.history.location)
 * @param  {string} paramName      The name of the search param to search for
 * @return {string|number|null}    Returns the value of the parameter if it is found
 */
export function resolveSearchParam (location: RouteLocationType, paramName: string) {

  if (!(location && is.string(location.search) && !is.emptyString(location.search)))
    return null

  const params = qs.parse(location.search.substring(1))
  if (!(paramName in params))
    return null
  const value = params[paramName]
  return asIntegerOrString(value)
}


/**
 * Resolve the subdomain from the url
 * @return {string|null} The subdomain for the current tenant
 */
export function resolveSubdomain (): string | null {
  const hostname  = getDomainUrl()
  const subdomain = hostname.split('.').shift()
  return subdomain
}


/**
 * Resolve the errors of a failed post/put
 * @param  {object} errors The error object to resolve
 * @return {string}        The error: e.g "This field may not be blank."
 */
export function resolveFieldErrors (errors: Object | null, statusCode: number): React$Element<*> | string {
  let errorArray: Array<string | null> = []
  if (typeof errors === 'object') {
    errorArray = Object.entries(errors).map(resolveFieldError)
  }
  return errorArray.length > 0
    ? <Errors errors={ errorArray } />
    : resolveError(statusCode)
}

//eslint-disable-next-line complexity
function resolveFieldError (error: Array<*> | Object): string | null {
  error = error[1]
  if (Array.isArray(error)) {
    if (typeof error[0] === 'string')
      return error[0]
    if (typeof error[0] === 'object' && typeof error[0].message === 'string')
      return error[0].message
    return null
  } else if (typeof error === 'object' && !isEmptyObject(error)) {
    return resolveFieldError(Object.entries(error)[0])
  }
  return null
}


/**
 * Resolve an error text based on the status code of the http request
 * @param  {number|string}  statusCode The http requests status code
 * @param  {string|void}    method     The name of the method that was called: defaults to 'Save'
 * @return {string}                    The error message based onthe status code and the method
 */
//eslint-disable-next-line complexity
export function resolveError (statusCode?: number | string | null, method?: string = 'Save') {

  switch (statusCode) {
    case 400:
      return `${method} failed due to field errors. Make sure all the fields are filled correctly and try again.`
    case 401:
      return 'Error: You are not logged in.'
    case 403:
      return 'Error: You do not have required permissions.'
    case 404:
      return `Error: The object you were trying to ${method.toLowerCase()} could not be found.`
    case 500:
      return `${method} failed due to a server error. Please try again later.`
    case undefined:
      return `${method} failed due to a server error. Please try again later.`
    default:
      return `${method} failed for unknown reasons`
  }
}

function Errors (props: { errors: Array<string | null> }) {
  return <span style={{ display: 'flex', flexDirection: 'column' }}>
    <span>The following fields have erros:</span>
    { props.errors.map((error, key) => error ? <span key={ key }>{ error }</span> : null) }
  </span>
}

/**
 * Resolve the URL for media (attachments, etc.)
 * @return {string} The url for the media
 */
export function resolveMediaURL (url: string): string {
  return __DEV__
    ? mediaURL + url
    : url
}


/**
 * Check to see if an object has any properties
 * @param  {object}  obj The object to check
 * @return {Boolean}     true if the object is empty, false otherwise
 */
export function isEmptyObject (obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false
  }
  return true
}


/**
 * Compare two values to see if they are the same
 * @param  {any}  value  First value
 * @param  {any}  value2 Second value
 * @return {Boolean}     true if the values are the same, false otherwise
 */
// eslint-disable-next-line
export function isEqual (value: *, value2: *) {

  if (value === undefined && value2 === undefined)
    return true
  if (value === null && value2 === null)
    return true

  // Get the value type
  let type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(value2)) return false

  // If items are not an object or array, return false
  if ([ '[object Array]', '[object Object]' ].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
  var otherLen = type === '[object Array]' ? value2.length : Object.keys(value2).length
  if (valueLen !== otherLen) return false

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], value2[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)
          && compare(value[key], value2[key]) === false)
        return false
    }
  }

  // If nothing failed, return true
  return true

}
// eslint-disable-next-line
function compare (item1, item2) {

  // Get the object type
  var itemType = Object.prototype.toString.call(item1)

  // If an object or array, compare recursively
  if ([ '[object Array]', '[object Object]' ].indexOf(itemType) >= 0) {
    if (!isEqual(item1, item2)) return false
  }

  // Otherwise, do a simple comparison
  else {

    // If the two items are not the same type, return false
    if (itemType !== Object.prototype.toString.call(item2)) return false

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (itemType === '[object Function]') {
      if (item1.toString() !== item2.toString()) return false
    } else {
      if (item1 !== item2) return false
    }

  }
}

export function isProUser (user: *) {
  if (!user.profile.active_subscription) return false
  const { type, group } = user.profile.active_subscription
  return group === SUBSCRIPTION_GROUP_USER && type === SUBSCRIPTION_TYPE_PRO
}
