/**
 * @flow
 * @class Link
 */

import self from 'autobind-decorator'
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Text, { Label, Strong } from 'components/text'

import type { Node } from 'react'

type LinkProps = {
  action: string | string => *,
  children: Node,
  className?: string,
} & RouteProps

type LinkState = {
  disabled: boolean,
}

type Properties = {
  action: ButtonActionType,
} & RouteProps

type ButtonActionType = string | Function


//eslint-disable-next-line complexity
export function resolveHandler (props: Properties) {
  const pushRoute = (url: ButtonActionType) => props.history.push(url)

  // Enum (previous|next|submit)
  if (props.action === 'previous')
    return props.history.goBack
  if (props.action === 'next')
    return props.history.goForward
  if (props.action === 'submit')
    return () => {}

  // Local url addresses
  if (typeof props.action === 'string')
    return () => pushRoute(props.action)

  // Plain functions
  if (typeof props.action === 'function')
    return props.action

  throw new TypeError('Invalid action ' + (props.action || '').toString())
}


export function SimpleLink (props: *) {

  const link = (Wrapper) => <a onClick={ props.onClick }>
    <Wrapper>{ props.children }</Wrapper>
  </a>

  let Wrapper = Fragment

  if (props.translate)
    Wrapper = Text
  if (props.strong)
    Wrapper = Strong
  return link(Wrapper)
}


@withRouter
export default class Link extends Component<LinkProps, LinkState> {

  state = {
    disabled: false,
  }

  componentDidMount () {
    this.update(this.props)
  }

  UNSAFE_componentWillReceiveProps (props: LinkProps) {
    this.update(props)
  }

  render () {
    let { children: child, className } = this.props

    if (child instanceof Array)
      child = child[0]

    if (typeof child === 'string')
      child = <Label className='link'>{ child }</Label>

    const { type: ChildComponent, props } = child

    let classNames = [
      className,
      props.className
    ]
    if (this.state.disabled)
      classNames.push('disabled')

    const flattenedClassName = classNames
      .filter(entry => entry)
      .join(' ')

    return <ChildComponent
      { ...props }
      className={ flattenedClassName }
      disabled={ this.state.disabled }
      onClick={ this.handleClick }
    />
  }

  @self
  handleClick () {
    if (!this.state.disabled)
      return resolveHandler(this.props)()
  }

  update (props: LinkProps) {
    const updates = {}
    if ('disabled' in props)
      updates.disabled = props.disabled
    if (Object.keys(updates).length)
      this.setState(updates)
  }
}
