// @flow
import self from 'autobind-decorator'
import decode from 'jwt-decode'
import Symbol from 'es6-symbol'
import { Emitter } from 'event-kit'
import * as Sentry from '@sentry/react'

import api from 'api'


type TokenPair = {
  access: string,
  refresh: string,
}

const SESSION_EXPIRED_MESSAGE = 'You were logged out due to 30 minutes of inactivity. Please sign in again.'
const TOKEN_ERROR_MESSAGE = 'An error occurred in authentication. Please re-login.'
const MINUTE = 60000


class Token {
  static INVALIDATION_DURATION: number = 4 * MINUTE
  static IDLE_TIMEOUT: number = 30 * MINUTE

  constructor () {
    this[signal]       = new Emitter()
    this[payload]      = {}
    this[polling]      = null
    this[accessToken]  = null
    this[refreshToken] = null

    this.set(getStoredTokens())

    if (this[accessToken]) {
      api.verifyToken(this[accessToken]).then(response => {
        if (response.ok)
          this.refresh()
        else
          this.handleExpiredToken()
      })
    }
  }

  startInterval () {
    if (!this[polling]) {
      addSentryBreadcrumb('Starting token refresh interval.')
      this[polling] = setInterval(this.refresh, Token.INVALIDATION_DURATION)
    }
  }

  clearInterval () {
    if (this[polling]) {
      addSentryBreadcrumb('Clearing token refresh interval.')
      clearInterval(this[polling])
    }
    this[polling] = null
  }

  get token (): string | null {
    return this[accessToken]
  }

  get refreshToken (): string | null {
    return this[refreshToken]
  }

  get data (): Object {
    return this[payload]
  }

  @self
  set (value: TokenPair | null) {
    if (!value) {
      addSentryBreadcrumb('Token pair is falsy.', {response: value}, Sentry.Severity.Warning)
      return
    }

    if (value.access && value.refresh) {
      addSentryBreadcrumb('Setting access and refresh tokens.')

      this[accessToken] = value.access
      this[refreshToken] = value.refresh

      try {
        this[payload] = decode(value.access)
      } catch (error) {
        addSentryBreadcrumb('Could not decode access token.', {accessToken: value.access}, Sentry.Severity.Error)
        throw error
      }

      this.startInterval()
      storeTokens(value)
      this[signal].emit('did-update', value)
      this.updateApiHeaders()
    } else {
      addSentryBreadcrumb(
        'One or both of the token pair tokens are falsy.',
        {accessToken: value.access, refreshToken: value.refresh},
        Sentry.Severity.Warning,
      )
    }
  }

  updateApiHeaders () {
    api.setHeaders({Authorization: `Bearer ${this[accessToken]}`})
  }

  @self
  clear (sendSignal?: boolean = true) {
    addSentryBreadcrumb('Clearing tokens.', { sendSignal })
    this[accessToken] = null
    this[refreshToken] = null
    this.clearInterval()
    clearStoredTokens()
    if (sendSignal)
      this[signal].emit('did-update', null)
    api.deleteHeader('Authorization')
  }

  @self
  handleExpiredToken () {
    addSentryBreadcrumb('Token expired.')
    this.clear()
    require('../../src/utils/alerts').addNewAlert('info', SESSION_EXPIRED_MESSAGE, 2)
  }

  @self
  tokenError (message: string, error: any = null) {
    addSentryBreadcrumb(message, Sentry.Severity.Error)

    if (error)
      Sentry.captureException(error)

    this.clear()
    require('../../src/utils/alerts').addNewAlert('error', TOKEN_ERROR_MESSAGE, 2)
  }

  @self
  async refresh () {
    if (!this[refreshToken]) {
      this.tokenError('Unable to refresh access token. Refresh token is falsy.')
      return
    }

    addSentryBreadcrumb('Refreshing token.')

    try {
      let response = await requestTokenRefreshal(this[refreshToken])
      if (response && response.problem)
        this.handleExpiredToken()
      else if (response.access && this.refreshToken)
        this.set({ access: response.access, refresh: this.refreshToken })
    } catch (error) {
      this.tokenError('Token refresh failed.', error)
    }
  }

  @self
  async obtain (email: string, password: string) {
    try {
      let response = await requestTokenForCredentials(email, password)
      this.set(response)
    } catch (error) {
      this.tokenError('Error happened when obtaining token.', error)
    }
  }

  @self
  async getTokens (singleUseToken: string) {
    try {
      let response = await requestTokenWithSingleUseToken(singleUseToken)
      localStorage.setItem('onLogin', '1')
      this.set(response)
    } catch (error) {
      this.tokenError('Get tokens with single use token failed.', error)
    }
  }

  @self
  observe (fn: (string | null) => *) {
    fn(this.token)
    return this[signal].on('did-update', fn)
  }

}


const accessToken  = Symbol('Current token')
const refreshToken = Symbol('Refresh token')
const signal       = Symbol('Event dispatcher')
const payload      = Symbol('Decoded payload for the current token')
const polling      = Symbol('Token refresh polling interval')

const requestTokenRefreshal = (tok): Promise<* | null> =>
  api.refreshToken(tok)

const requestTokenForCredentials = (email, password): Promise<TokenPair | null> =>
  api.obtainToken(email, password)

const requestTokenWithSingleUseToken = (token): Promise<TokenPair | null> =>
  api.getTokens(token)

const getStoredTokens = (): TokenPair | null => {
  const access = localStorage.getItem('accessToken') || null
  const refresh = localStorage.getItem('refreshToken') || null
  return access && refresh ? { access, refresh } : null
}

const clearStoredTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

const storeTokens = (value: TokenPair) => {
  localStorage.setItem('accessToken', value.access)
  localStorage.setItem('refreshToken', value.refresh)
}

function addSentryBreadcrumb (message: string, data: {} = {}, level: Sentry.Severity = Sentry.Severity.Info) {
  Sentry.addBreadcrumb({ category: 'auth', message, data, level })
}

export default (new Token(): Token)
export const IDLE_TIMEOUT = Token.IDLE_TIMEOUT
