/**
 * @flow
 * @class FloatingActionButton
 */
import React, { Component } from 'react'
import self from 'autobind-decorator'
import navigator from 'routes/base'
import Icon from 'components/icons'

type FABPropsType = {
  icon?: React$Element<*>,
  action: Function | string,
}


export default class FAB extends Component<FABPropsType> {

  get icon (): React$Element<*> {
    const IconElement = this.props.icon || Icon.Add
    return <IconElement />
  }

  @self
  action () {
    if (is.string(this.props.action))
      navigator.navigate(this.props.action)
    else
      this.props.action()
  }

  render (): React$Element<*> {
    return <button className='floating-action-button primary filled' onClick={ this.action } >
      { this.icon }
    </button>
  }
}
